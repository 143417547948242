import React, { useEffect } from 'react';
import './style.scss';
import './PelWidget.scss';
import t from '@/lib/i18n';
import loadScript from '@/lib/utils/loadScript';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import getConfigValue from '@/lib/config';
import Layout from '@/components/organisms/layout';
import { useSelector, shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';
import { StateType as CartStateType } from '@/store/cart/types';
import CartHelp from '@/components/organisms/checkout/help';
import Header from '../header';

export type CartProps = {
  isApp: boolean;
  footer: ContentfulContent;
};

declare global {
  interface Window {
    customAction: any;
    cardClick: any;
    finalState: any;
  }
}

const PaymentWidget = ({ isApp, footer }: CartProps) => {
  // TODO : const cartStore = useShalloEqualSelector(useCart);
  const { cartStore } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      cartStore: cart
    };
  }, shallowEqual);

  const router = useRouter();
  const { token: transactionID, formContext } = router.query;

  const HOST = getConfigValue('HOST').toString();
  const SIBS_FORM_CONFIG = {
    paymentMethodList: [],
    amount: { value: 2, currency: 'EUR' },
    language: 'pt',
    redirectUrl: isApp
      ? `${HOST}/home/orders/payment/validation/redirection?paylinetoken=${transactionID}`
      : `${HOST}/home/orders/payment/validation?paylinetoken=${transactionID}`,
    customerData: null
  };

  const SIBS_FORM_STYLE = {
    theme: 'default',
    color: {
      primary: '#e2001a',
      secondary: '#e2001a'
    }
  };

  const sibsBaseUrl = getConfigValue('SIBS_SCRIPT_URL').toString();
  const src = `${sibsBaseUrl}?id=${transactionID}`;

  const headerPart = <Header cart={cartStore} step="payment" />;
  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  useEffect(() => {
    loadScript(src);
  }, [src]);

  const content = (
    <>
      <div className="checkoutPaymentWidget container container--md">
        <div className="checkoutPaymentWidget__title">
          {t('cart.payment.online.title')}
        </div>
        <div className="checkoutPaymentWidget__content">
          <form
            className="paymentSPG"
            spg-context={formContext}
            spg-config={JSON.stringify(SIBS_FORM_CONFIG, null, 4)}
            spg-style={JSON.stringify(SIBS_FORM_STYLE, null, 4)}
          />
        </div>
        <div className="checkoutPaymentWidget__increase">
          {t('cart.payment.online.increase.text')}
        </div>
        {!isApp && <CartHelp />}
      </div>
    </>
  );

  return (
    <Layout
      header={!isApp ? headerPart : null}
      content={content}
      footer={!isApp ? footerPart : null}
      pageView={!isApp ? 'onlinePayment' : 'onlinePaymentApp'}
    />
  );
};

export default PaymentWidget;
