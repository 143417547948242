import React, { useState } from 'react';
import CategoryModel from '@/lib/model/category';
import Paragraph from '@/components/atoms/paragraph';
import Link from '@/components/atoms/link';
import { useIsomorphicLayoutEffect } from 'react-use';
import { redirectPlp } from '@/components/organisms/seoStoreLoc';
import usePdv from '@/lib/hooks/usePdv';
import './style.scss';
import { VIRTUAL_PDV_REF } from '@/lib/utils/pdv';

export type CategoryProps = {
  category: CategoryModel;
};

const Category = ({ category }: CategoryProps) => {
  const [pdvRef, setPdvRef] = useState('');
  useIsomorphicLayoutEffect(() => {
    const { ref } = usePdv();
    setPdvRef(ref);
  }, []);
  const virtualPdvRef = VIRTUAL_PDV_REF;

  return (
    <li className="category">
      <Paragraph label={category.title} className="category__title" />
      <ul className="category__linkContainer">
        {category.children.map((categoryChild: CategoryModel) => {
          return (
            <li
              className="category__linkContainer__linkList"
              key={categoryChild.id}
            >
              <Link
                href={categoryChild.link}
                disabled
                onClick={() => {
                  if (pdvRef.toString() !== virtualPdvRef) {
                    redirectPlp(categoryChild.id, pdvRef.toString());
                    return;
                  }
                  window.location.href = categoryChild.link;
                }}
                label={categoryChild.title}
                rel="follow"
                className="category__linkContainer__linkList__link"
              />
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default Category;
