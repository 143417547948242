import React, { useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import { Informations as UserInfo } from '@/lib/api/userInformation/types';
import userInformationApi from '@/lib/api/userInformation';
import { emailValidationRegExp } from '@/lib/utils/stringUtils';
import AccountApi from '@/lib/api/account';
import FormInput from '@/components/atoms/formInput';
import { useForm } from 'react-hook-form';
import Button from '@/components/atoms/button';
import Modal from '@/components/organisms/modal';
import useEvent from '@/lib/hooks/useEvent';

type ChangeEmailProps = {
  userInfo: UserInfo;
};

type StateType = {
  edit: boolean;
  updating: boolean;
  updated: boolean;
};

type FormType = {
  email: string;
  emailConfirmation: string;
};

const ChangeEmail = ({ userInfo }: ChangeEmailProps) => {
  const [state, setState] = useState<StateType>({
    edit: false,
    updating: false,
    updated: false
  });

  const event = useEvent();

  const {
    register,
    errors,
    handleSubmit,
    getValues,
    setError
  } = useForm<FormType>({
    shouldUnregister: false,
    defaultValues: {
      email: undefined,
      emailConfirmation: undefined
    }
  });

  const onSubmit = async (data: FormType) => {
    setState({
      ...state,
      updating: true
    });

    let updated = false;

    const isValidEmail = await AccountApi.emailAlreadyExist(data.email);

    if (isValidEmail) {
      try {
        await userInformationApi.changeEmail(userInfo.id, data.email);
        event.send('api', {
          name: 'changeEmail',
          type: 'success'
        });
        updated = true;
      } catch (error: any) {
        event.send('api', {
          name: 'changeEmail',
          type: 'error'
        });
        setError('email', { message: t('notification.erreur') });
        setError('emailConfirmation', { message: ' ' });
      }
    } else {
      setError('email', { message: t('register.form.emailAlreadyExist') });
      setError('emailConfirmation', { message: ' ' });
    }

    setState({
      ...state,
      updating: false,
      updated
    });
  };

  return (
    <div className="changeEmail">
      {!state.edit && (
        <>
          <FormInput
            id="email"
            name="email"
            label={t('myAccount.myDetails.label.email')}
            className="changeEmail__input"
            value={userInfo.email}
            disabled={true}
          />
          <div
            className="changeEmail__cta"
            onClick={() => {
              setState({
                ...state,
                edit: true
              });
            }}
          >
            {t('myAccount.myDetails.changeEmail')}
          </div>
        </>
      )}
      {state.edit && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            id="email"
            name="email"
            label={`${t('myAccount.myDetails.label.email')} *`}
            className="changeEmail__input"
            ref={register({
              required: t('form.error.required'),
              pattern: {
                value: emailValidationRegExp,
                message: t('register.form.email.error')
              }
            })}
            errorMessage={errors.email ? errors.email.message : ''}
          />
          <FormInput
            id="emailConfirmation"
            name="emailConfirmation"
            label={`${t('myAccount.myDetails.label.emailConfirmation')} *`}
            className="changeEmail__input"
            ref={register({
              required: t('form.error.required'),
              pattern: {
                value: emailValidationRegExp,
                message: t('register.form.email.error')
              },
              validate: {
                sameEmail: (value) => {
                  return getValues('email') === value;
                },
                notSameAsOldEmail: (value) => {
                  return value !== userInfo.email;
                }
              }
            })}
            errorMessage={
              errors.emailConfirmation ? errors.emailConfirmation.message : ''
            }
          />
          <div className="changeEmail__buttons">
            <Button
              className="changeEmail__reset"
              type="button"
              color="secondary"
              onClick={() =>
                setState({
                  ...state,
                  edit: false
                })
              }
            >
              {t('myAccount.myDetails.cancel')}
            </Button>
            <Button
              className="changeEmail__submit"
              type="submit"
              loading={state.updating}
            >
              {t('myAccount.myDetails.validate')}
            </Button>
          </div>
        </form>
      )}
      <Modal
        className="changeEmail__modal"
        open={state.updated}
        onClose={() => {
          setState({
            ...state,
            edit: false,
            updated: false
          });
        }}
        confirmBtn={{
          label: t('myAccount.myDetails.changeEmail.modal.button'),
          onClick: () => {
            setState({
              ...state,
              edit: false,
              updated: false
            });
          }
        }}
      >
        <h2 className="changeEmail__modal_title">
          {t('myAccount.myDetails.changeEmail.modal.title')}
        </h2>
        <p className="changeEmail__modal_message">
          {t('myAccount.myDetails.changeEmail.modal.message')}
        </p>
      </Modal>
    </div>
  );
};

export default ChangeEmail;
