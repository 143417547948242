import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import classNames from 'classnames';
import FaqSprite from '@/assets/images/sprites/common.svg';
import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import './style.scss';

export type AccordionProps = {
  itemsList?: Array<AccordionItemProps>;
  openIndex?: string;
  directionalArrow?: boolean;
  arrowText?: string;
  className?: string;
  clickArrowToToogle?: boolean;
  customArrowActive?: JSX.Element;
  customArrowInactive?: JSX.Element;
  updateIndexOnChange?: boolean;
};

export type AccordionItemProps = {
  title: string | JSX.Element;
  body?: Array<Component>;
  htmlBody?: JSX.Element;
  id: string;
  classNameItem?: string;
  classNameTitle?: string;
};

const Accordion = ({
  itemsList,
  openIndex,
  directionalArrow,
  arrowText,
  className,
  clickArrowToToogle = false,
  customArrowActive,
  customArrowInactive,
  updateIndexOnChange = false
}: AccordionProps) => {
  const [collapseIndex, setCollapseIndex] = useState(openIndex);

  const toogleElement = (id: string) => {
    if (collapseIndex === id) {
      setCollapseIndex('');
    } else {
      setCollapseIndex(id);
    }
  };

  useEffect(() => {
    if (updateIndexOnChange) {
      setCollapseIndex(openIndex);
    }
  }, [openIndex, updateIndexOnChange]);

  return (
    <div
      className={classNames('accordion', className, {
        'accordion--pointer': !clickArrowToToogle
      })}
    >
      {itemsList &&
        itemsList.map(
          ({
            title,
            body,
            id,
            htmlBody = null,
            classNameItem,
            classNameTitle
          }) => {
            return (
              <div
                className={classNames('accordion__item', classNameItem)}
                onClick={() => {
                  if (!clickArrowToToogle) toogleElement(id);
                }}
                key={id}
              >
                <div className="accordion__header">
                  <span
                    className={classNames(
                      'accordion__header-title',
                      classNameTitle
                    )}
                  >
                    {title}
                  </span>
                  <div className="accordion__header-arrowContainer">
                    {arrowText && (
                      <span className="accordion__arrow-text">{arrowText}</span>
                    )}
                    {customArrowInactive && customArrowActive && (
                      <div
                        onClick={() => {
                          if (clickArrowToToogle) toogleElement(id);
                        }}
                      >
                        {collapseIndex === id && customArrowActive}
                        {collapseIndex !== id && customArrowInactive}
                      </div>
                    )}
                    {!customArrowActive && !customArrowInactive && (
                      <div
                        className={classNames('accordion__arrow', {
                          'accordion__arrow--active': collapseIndex === id,
                          'accordion__arrow-directional': directionalArrow,
                          'accordion__arrow-directional--active':
                            directionalArrow && collapseIndex === id
                        })}
                      >
                        <svg
                          width="18px"
                          height="18px"
                          onClick={() => {
                            if (clickArrowToToogle) toogleElement(id);
                          }}
                        >
                          <use xlinkHref={`${FaqSprite}#arrow-right`} />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                <Collapse isOpened={id === collapseIndex}>
                  {body && (
                    <div className="accordion__body">
                      <ContentfulRenderComponents components={body} />
                    </div>
                  )}
                  {htmlBody}
                </Collapse>
              </div>
            );
          }
        )}
    </div>
  );
};

export default Accordion;
