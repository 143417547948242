import React from 'react';
import './style.scss';
import Carousel from 'react-multi-carousel';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { SliderPropsType, ArrowType } from './types';

export const SampleArrow = ({ onClick, className, children }: ArrowType) => {
  return (
    <div
      className={className}
      onClick={(event) => (onClick ? onClick(event) : null)}
    >
      {children}
    </div>
  );
};

const isShowDots = (numberOfChildren: number, responsive: any) => {
  if (typeof window !== 'undefined') {
    if (window?.innerWidth > 600)
      return numberOfChildren > responsive.desktop?.slidesToSlide;
    if (window?.innerWidth < 400)
      return numberOfChildren > responsive.mobile.slidesToSlide;
    return numberOfChildren > responsive.tablet.slidesToSlide;
  }
  return true;
};

const Slider = React.forwardRef((args: SliderPropsType, ref: any) => {
  const type = args.type || 'primary';
  const filteredChildren = args.children.filter((el: any) => el);
  const children = args.firstSlid
    ? [args.firstSlid, ...filteredChildren]
    : filteredChildren;
  const numberOfChildren = children.length;
  const slidesToSlide = args.slidesToSlide || (type === 'primary' ? 1 : 4);
  const responsive = args.responsive || {
    desktop: {
      breakpoint: { max: 4096, min: 600 },
      items: type === 'secondary' ? 4 : 1,
      slidesToSlide: type === 'primary' ? 1 : 4
    },
    tablet: {
      breakpoint: { max: 600, min: 480 },
      items: type === 'secondary' ? 2 : 1,
      slidesToSlide: type === 'primary' ? 1 : 2
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };
  let settings = null;
  settings = {
    ...args,
    className: classnames({
      [`novaSlider novaSlider--${type}`]: true,
      [`${args.className}`]: !!args.className
    }),
    dotListClass: classnames({
      'react-multi-carousel-dot-list': true,
      novaSlider__dots: true,
      [`${args.dotsClass}`]: !!args.dotsClass
    }),
    sliderClass: 'novaSlider__track',
    customLeftArrow: (
      <SampleArrow className="novaSlider__arrowIcon novaSlider__arrowIcon--left">
        <svg className="fill--red">
          <use xlinkHref={`${CommonSprite}#arrow-left`} />
        </svg>
      </SampleArrow>
    ),
    customRightArrow: (
      <SampleArrow className="novaSlider__arrowIcon novaSlider__arrowIcon--right">
        <svg className="fill--red">
          <use xlinkHref={`${CommonSprite}#arrow-right`} />
        </svg>
      </SampleArrow>
    ),
    autoPlay: args.autoPlay ?? false,
    autoPlaySpeed: args.autoPlaySpeed ?? 5000,
    swipeable: args.swipeable ?? true,
    draggable: args.draggable ?? true,
    ssr: args.ssr ?? true,
    deviceType: 'desktop',
    arrows: args.arrows || false,
    slidesToSlide,
    showDots:
      (args.showDots !== undefined ? args.showDots : true) &&
      isShowDots(numberOfChildren, responsive),
    transitionDuration: 500,
    infinite: args.infinite !== undefined ? args.infinite : false,
    renderDotsOutside:
      args.renderDotsOutside !== undefined ? args.renderDotsOutside : false,
    responsive: args.responsive || {
      desktop: {
        breakpoint: { max: 4096, min: 600 },
        items: type === 'secondary' ? 4 : 1,
        slidesToSlide: type === 'primary' ? 1 : 4
      },
      tablet: {
        breakpoint: { max: 600, min: 480 },
        items: type === 'secondary' ? 2 : 1,
        slidesToSlide: type === 'primary' ? 1 : 2
      },
      mobile: {
        breakpoint: { max: 480, min: 0 },
        items: 1,
        slidesToSlide: 1
      }
    },
    type
  };
  return (
    <Carousel ref={ref} {...settings}>
      {children}
    </Carousel>
  );
});

export default Slider;
