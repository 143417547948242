import Button from '@/components/atoms/button';
import t from '@/lib/i18n';
import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Filter as FilterModel } from '@/lib/model/productList';
import './style.scss';

type StickyProps = {
  clearFilters: () => void;
  close: () => void;
  data: Array<FilterModel>;
  totalNbProducts: number;
};

const Sticky = ({
  clearFilters,
  close,
  data,
  totalNbProducts
}: StickyProps) => {
  const canClear = useCallback(() => {
    let enable = false;
    data.forEach((item) => {
      if (item?.values?.length && !enable) {
        enable = item.values.some((s) => !!s.disabled || !!s.active) || enable;
      }
    });
    return enable;
  }, [data]);

  return (
    <div className="sticky-filters">
      <a
        className={classnames('sticky-filters__clear', {
          'sticky-filters--active': canClear()
        })}
        onClick={() => {
          canClear() && clearFilters();
        }}
      >
        {t('productList.filters.deleteAll')}
      </a>
      <Button
        disabled={totalNbProducts <= 0}
        className="sticky-filters__apply"
        onClick={close}
      >
        {t('productList.count', { '%count%': totalNbProducts })}
      </Button>
    </div>
  );
};

export default Sticky;
