import {
  LinksSummaryProps,
  SummaryProps
} from '@/components/molecules/summary';
import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): ComponentSummaryProps => {
  const { components = [] } = data;

  const links: Array<LinksSummaryProps> = components
    .filter(
      (component: Component) =>
        component.name === 'contentSection' &&
        component.props.id &&
        component.props.title
    )
    .map((component: Component) => ({
      id: component.props.id,
      label: component.props.title
    }));

  return {
    type: 'molecules',
    name: 'summary',
    props: {
      links
    }
  };
};

export default normalize;

export interface ComponentSummaryProps extends Component {
  props: SummaryProps;
}
