import React, { useEffect } from 'react';
import CgvFaqSprite from '@/assets/images/sprites/sav.svg';
import Title from '@/components/atoms/title';
import Paragraph from '@/components/atoms/paragraph';
import './style.scss';
import usePageView from '@/lib/hooks/usePageView';

const savPopinBlockCourrier = () => {
  const pageView = usePageView();

  useEffect(() => {
    pageView.send('contactLetter');
  }, [pageView]);

  return (
    <div className="savPopinBlockCourrier">
      <svg width="120" height="120">
        <use xlinkHref={`${CgvFaqSprite}#courrier`} />
      </svg>
      <Title
        tag="h5"
        className="savPopinBlockCourrier__title"
        label="Service client"
      />
      <Paragraph className="savPopinBlockCourrier__text" label="CS61704" />
      <Paragraph
        className="savPopinBlockCourrier__text"
        label="35517 Cesson Sévigné cedex."
      />
    </div>
  );
};

export default savPopinBlockCourrier;
