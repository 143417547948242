import React, { useEffect } from 'react';
import CartSprite from '@/assets/images/sprites/cart.svg';
import { Dispatch } from 'redux';
import t from '@/lib/i18n';
import {
  SlotType,
  CartItemType,
  CartType,
  SubCartType
} from '@/store/cart/types';
import Product from '@/components/organisms/product';
import {
  updateCartQuantity,
  UpdateCartQtyElt
} from '@/store/cart/actions/cart';
import dayjs from 'dayjs';
import slotsApi from '@/lib/api/slots';
import { ADD_SLOTS } from '@/store/cart/cartActions';
import { getPdvRef } from '@/lib/hooks/usePdv';
import { ReactComponent as Spinner } from '@/assets/images/icons/spinner.svg';
import './style.scss';
import useEvent from '@/lib/hooks/useEvent';

export type ClosedShelvesPopinProps = {
  selectedSlot: SlotType | null;
  productsShelfClosed: Array<CartItemType>;
  isLoading: boolean;
};

export const getProductsShelfClosed = (
  selectedSlotTemp: SlotType | null,
  cart: CartType
) => {
  if (!selectedSlotTemp?.closedEans.length) {
    return [];
  }
  const itmCArt: SubCartType | undefined = cart.subCarts.find(
    (subCart: SubCartType) => subCart.seller.sellerId === 'ITM'
  );
  return Object.keys(itmCArt?.items || {}).reduce(
    (acc: Array<CartItemType>, key: string) => {
      if (
        itmCArt?.items[key] &&
        selectedSlotTemp?.closedEans.includes(itmCArt?.items[key].product.ean)
      ) {
        acc.push(itmCArt.items[key]);
      }
      return acc;
    },
    []
  );
};

export const deleteAllClosedEans = async ({
  productsShelfClosed,
  dispatch,
  userId,
  updateSlot = true
}: {
  productsShelfClosed: Array<CartItemType> | null;
  dispatch: Dispatch;
  userId: string;
  updateSlot?: boolean;
}) => {
  const items: Array<UpdateCartQtyElt> =
    productsShelfClosed?.map(
      (product: CartItemType): UpdateCartQtyElt => {
        return { product: product.product, quantity: 0, sellerId: 'ITM' };
      }
    ) || [];

  if (!items.length) return;

  try {
    const pdvRef = getPdvRef();
    const newCart: CartType = await dispatch(updateCartQuantity(items) as any);
    const {
      data,
      vouchersCodeActive,
      discountCodeActive,
      mkpPackages,
      defaultPayments
    } = await slotsApi.getAll(newCart, pdvRef, userId);
    updateSlot &&
      dispatch({
        type: ADD_SLOTS,
        payload: {
          data,
          vouchersCodeActive,
          discountCodeActive,
          mkpPackages,
          defaultPayments
        }
      });
  } catch (error: any) {
    // Todo: handle error in api call getAll or updateCartQuantity
    window.location.href = '/orders/cart';
  }
};

const ClosedShelvesModal = ({
  selectedSlot,
  productsShelfClosed,
  isLoading
}: ClosedShelvesPopinProps) => {
  const event = useEvent();
  const textShelf =
    selectedSlot?.closedShelvesNames?.length === 1
      ? t('modal.closedShelves.ofShelf', {
          '%shelf%': `'${selectedSlot?.closedShelvesNames[0]}'`
        })
      : t('modal.closedShelves.ofShelves', {
          '%shelves%': selectedSlot?.closedShelvesNames
            ?.map((name) => `'${name}'`)
            .join(', ')
        });

  useEffect(() => {
    event.send('checkout', { type: 'closedShelves' });
  }, [event]);

  return (
    <div className="closedShelves">
      <svg className="unavailableModal__picto" width={120} height={150}>
        <use xlinkHref={`${CartSprite}#unavailable`} />
      </svg>
      <h1 className="closedShelves__title">{t('modal.closedShelves.title')}</h1>
      <p className="closedShelves__text">
        {t('modal.closedShelves.productUndeliverable', {
          '%shelf%': textShelf,
          '%date%': dayjs(selectedSlot?.day).format('dddd D MMMM')
        })}
      </p>
      <p className="closedShelves__text">
        {t('modal.closedShelves.chooseOptions')}
      </p>
      <div className="closedShelves__products">
        {isLoading ? (
          <Spinner className="closedShelves__loader" />
        ) : (
          productsShelfClosed.map((item: CartItemType) => {
            const key = `${item.product.ean}-shelfClosed`;
            return (
              <Product
                key={key}
                data={item.product}
                isCheckout={true}
                isSmall={true}
                disableMode
                redirectOnProductClick={false}
                className="closedShelves__article"
                imgClassName="closedShelves__imgProduct"
                textClassName="closedShelves__textProduct"
                productClassName="closedShelves__product"
                footerClassName="closedShelves__footer"
                showPriceDisableMode
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default ClosedShelvesModal;
