import React from 'react';
import t from '@/lib/i18n';
import Product from '@/components/organisms/product';
import CartSprite from '@/assets/images/sprites/cart.svg';
import { CartItemType, StateType as CartStateType } from '@/store/cart/types';
import AddToCart from '@/components/molecules/addCartButton';
import Image from '@/components/atoms/image';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

const UnavailablesSlide = ({
  list,
  type
}: {
  list: Array<CartItemType>;
  type: string;
}) => {
  // TODO : const cartStore = useShalloEqualSelector(useCart);
  const { cartStore } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      cartStore: cart
    };
  });

  return (
    <div className="cartModal__content unavailableModal">
      <svg className="unavailableModal__picto" width={120} height={150}>
        <use xlinkHref={`${CartSprite}#unavailable`} />
      </svg>
      <strong className="unavailableModal__title">
        {t(`cart.unavailables.${type}`)}
      </strong>
      <p className="unavailableModal__text">
        {t(`cart.unavailables.${type}.description`)}
      </p>
      <div className="unavailableModal__products">
        {list?.map((item) => {
          const sellerId = cartStore.subCarts.find(
            (subCart) => subCart.type === item.product.type
          )?.seller.sellerId;
          const key = `${item.product.ean}-unavailable`;
          const { product } = item;
          return (
            <React.Fragment key={key}>
              {type !== 'stock' && (
                <Product
                  data={item.product}
                  isCheckout={true}
                  isSmall={true}
                  redirectOnProductClick={false}
                  disableMode
                  className="unavailableModal__article"
                  imgClassName="unavailableModal__article__imgProduct"
                  textClassName="unavailableModal__article__textProduct"
                  productClassName="unavailableModal__article__product"
                  footerClassName="unavailableModal__article__footer"
                  isToReplace={true}
                />
              )}
              {type === 'stock' && (
                <div className="unavailableModal__product">
                  <div className="unavailableModal__product__section">
                    {product.informations.image &&
                      product.informations.image.src && (
                        <Image {...product.informations.image} width={45} />
                      )}
                    <div className="brand">
                      <span className="unavailableModal__product__brand">
                        {product.informations.brand}
                      </span>{' '}
                      - {product.informations.title}
                    </div>
                  </div>
                  <div className="unavailableModal__product__section">
                    <div className="unavailableModal__product__prices">
                      {product.prices.crossedOutPrice && (
                        <span className="unavailableModal__product__crossedOutPrice">
                          {product.prices.crossedOutPrice?.integer},
                          {product.prices.crossedOutPrice?.decimal}
                          {product.prices.crossedOutPrice?.currency}
                        </span>
                      )}
                      {product.prices.productPrice && (
                        <span
                          className={classnames(
                            'unavailableModal__product__productPrice',
                            {
                              hasPromo:
                                product.promotions.length ||
                                product.informations.highlight
                            }
                          )}
                        >
                          {product.prices.productPrice.integer},
                          {product.prices.productPrice.decimal}{' '}
                          {product.prices.productPrice.currency}
                        </span>
                      )}
                      {product.prices.unitPrice && (
                        <>
                          {product.prices.unitPrice.integer},
                          {product.prices.unitPrice.decimal}
                          {product.prices.unitPrice.currency}
                        </>
                      )}
                    </div>
                    <AddToCart
                      sellerId={sellerId}
                      disabled={true}
                      fullSync={true}
                      product={product}
                    />
                    {product.prices.unitPrice && (
                      <span className="unavailableModal__product__totalPrice">
                        {t('product.total', {
                          '%price%': String(item.total.toFixed(2)).replace(
                            '.',
                            ','
                          )
                        })}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default UnavailablesSlide;
