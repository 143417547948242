import React from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Layout from '@/components/organisms/layout';
import SummaryOrganism from '@/components/organisms/checkout/summary';
import { shallowEqual, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { StateType as CartStateType } from '@/store/cart/types';
import Header from '../header';

export type CartProps = {
  footer: ContentfulContent;
  pageView?: string;
  referer?: string;
};

const SummaryTemplate = ({ footer, pageView, referer }: CartProps) => {
  const { cartStore, user } = useSelector(
    ({ cart, auth }: { cart: CartStateType; auth: AuthStateType }) => {
      return {
        cartStore: cart,
        user: auth.user
      };
    },
    shallowEqual
  );

  useIsomorphicLayoutEffect(() => {
    if (!user) {
      window.location.href = '/home';
    }
  }, [user]);
  const headerPart = <Header cart={cartStore} step="payment" />;
  const content = (
    <div className="container">
      <SummaryOrganism cartStore={cartStore} userId={user?.id} />
    </div>
  );

  return (
    <Layout
      header={headerPart}
      content={content}
      pageView={pageView}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
      referer={referer}
    />
  );
};

export default SummaryTemplate;
