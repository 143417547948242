import React from 'react';
import Layout from '@/components/organisms/layout';
import SavBanner from '@/components/molecules/savBanner';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Header from '@/components/organisms/header';
import SavTilesGrid, { ThemesProps } from '@/components/organisms/savTilesGrid';

export type SavMailServicesProps = {
  footer: ContentfulContent;
  themes: Array<ThemesProps>;
};

const SavMailServices = ({ footer, themes }: SavMailServicesProps) => {
  const headerPart = <Header />;

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  const servicesTheme = themes.find((theme) => theme.nom === 'Services');
  const servicesSousTheme = servicesTheme?.sousTheme || [];

  const content = (
    <div>
      <SavBanner
        withSubBanner
        subBanner={{ href: '/help-and-contact/vas/mail' }}
      />
      {servicesSousTheme?.length > 0 && (
        <SavTilesGrid themes={servicesSousTheme} />
      )}
    </div>
  );

  return <Layout header={headerPart} footer={footerPart} content={content} />;
};

export default SavMailServices;
