import React, { useEffect, useState } from 'react';
import './style.scss';
import { Content as ContentfulContent } from '@/lib/contentful';
import { AuthStateType } from '@/store/auth/authReducer';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import { useSelector } from 'react-redux';
import userInformation from '@/lib/api/userInformation';
import { Informations } from '@/lib/api/userInformation/types';
import getConfigValue from '@/lib/config';
import Welcome from './welcome';
import ShoppingList from './shoppingList';
import Order from './order';
import Fidelity from './fidelity';
import Store from './store';
import Voucher from './voucher';

const IS_FIDELITY_ENABLED = getConfigValue(
  'IS_FIDELITY_ENABLED',
  false
).toBoolean();

export type DashboardProps = {
  footer: ContentfulContent;
  pageView: string;
};

type StateType = {
  userInfo?: Informations;
};

const Dashboard = ({ footer, pageView }: DashboardProps) => {
  const userId = useSelector(({ auth }: { auth: AuthStateType }) => {
    return auth.user?.id;
  });
  const [state, setState] = useState<StateType>({
    userInfo: undefined
  });

  useEffect(() => {
    (async () => {
      try {
        let userInfo: Informations;

        if (userId) {
          userInfo = await userInformation.getInformations(userId);
        }

        setState((oldState) => {
          return {
            ...oldState,
            userInfo
          };
        });
      } catch (error: any) {
        // Todo: handle user error display if unable to fetch user informations
      }
    })();
  }, [userId]);

  const contentPart = (
    <div className="myAccountDashboard">
      <Welcome userInfo={state.userInfo} />
      <div className="myAccountDashboard__containerGrid">
        <Order />
        <div className="myAccountDashboard__right">
          {IS_FIDELITY_ENABLED && !state.userInfo?.isPro && (
            <Fidelity userInfo={state.userInfo} />
          )}
          <Store userInfo={state.userInfo} />
        </div>
      </div>
      <div className="myAccountDashboard__containerGrid">
        <Voucher />
        <ShoppingList />
      </div>
    </div>
  );

  return (
    <MyAccountLayout
      enabledId="dashboard"
      content={contentPart}
      footer={footer}
      pageView={pageView}
    />
  );
};

export default Dashboard;
