import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import classnames from 'classnames';
import useClickOut from '@/lib/hooks/useClickOut';
import useEvent from '@/lib/hooks/useEvent';
import t from '@/lib/i18n';
import ProductApi from '@/lib/api/product';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { ReactComponent as CrossIcon } from '@/assets/images/icons/cross.svg';
import _debounce from 'lodash/debounce';
import usePdv from '@/lib/hooks/usePdv';
import { useSelector } from 'react-redux';
import { CommonStateType } from '@/store/common/commonReducer';
import { SearchResultsType } from './searchModal/type';
import SearchModal from './searchModal';

export type SearchProps = {
  id?: string;
  className?: string;
};

const Search = ({ id, className }: SearchProps) => {
  const event = useEvent();
  const [clickOutRef, clickOutHandler] = useClickOut();
  const [keyword, setKeyword] = useState('');
  const [searchResults, setSearchResults] = useState({
    families: [],
    products: [],
    suggestions: []
  } as SearchResultsType);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [resultRetrieved, setResultRetrieved] = useState<boolean>(false);
  const { recommendationsPopinDisplayed } = useSelector(
    ({ common }: { common: CommonStateType }) => ({
      recommendationsPopinDisplayed: common.recommendationsPopinDisplayed
    })
  );

  const redirectToResults = () => {
    if (!keyword && !searchResults.suggestions.length) return;

    event.send('search', {
      type: 'search',
      keyword
    });

    window.location.href = `/search/${encodeURI(keyword)}`;
  };

  const clearKeyword = () => {
    setKeyword('');
  };

  const reset = () => {
    clearKeyword();
    setSearchResults({
      families: [],
      products: [],
      suggestions: []
    });
    setResultRetrieved(false);
  };

  const handleSearch = (handleEvent: any) => {
    if (!handleEvent.target.value) {
      reset();
    } else {
      setKeyword(handleEvent.target.value);
    }
  };

  const debounceSearch = useMemo(
    () =>
      _debounce(
        () => {
          const { ref } = usePdv();

          if (!keyword || !ref) {
            return;
          }

          ProductApi.productSuggestions(ref, keyword).then((data) => {
            setSearchResults({
              families: data.families ?? [],
              products: data.products ?? [],
              suggestions: data.suggestions ?? []
            });
            setResultRetrieved(true);
          });
        },
        300,
        { maxWait: 1000 }
      ),
    [keyword]
  );

  useEffect(() => {
    if (keyword.length > 2) {
      debounceSearch();
    }
    return debounceSearch.cancel;
  }, [keyword, debounceSearch]);

  clickOutHandler(() => {
    if (!recommendationsPopinDisplayed) {
      reset();
      setIsOpen(false);
    }
  });

  useEffect(() => {
    document.documentElement.style.overflow = isOpen ? 'hidden' : 'visible';
  }, [isOpen]);

  return (
    <>
      <div
        id={id}
        className={classnames('search', className)}
        ref={clickOutRef}
      >
        <svg
          onClick={redirectToResults}
          className="fill--black"
          height="21"
          width="21"
        >
          <use xlinkHref={`${CommonSprite}#loupe`} />
        </svg>

        <input
          type="text"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              redirectToResults();
            }
          }}
          placeholder={t('header.search.placeholder')}
          aria-label={t('header.search.placeholder')}
          onChange={handleSearch}
          onFocus={() => setIsOpen(true)}
          value={keyword}
        />

        {keyword && (
          <CrossIcon
            className="search__close fill--black"
            height="21"
            width="21"
            onClick={reset}
          />
        )}
      </div>

      {isOpen && (
        <SearchModal
          id={id}
          className={classnames('search', className)}
          clickOutRef={clickOutRef}
          searchResults={searchResults}
          event={event}
          redirectToResults={redirectToResults}
          handleSearch={handleSearch}
          keyword={keyword}
          clearKeyword={clearKeyword}
          setIsOpen={setIsOpen}
          resultRetrieved={resultRetrieved}
        />
      )}
    </>
  );
};

export default Search;
