import React, { useState } from 'react';
import t from '@/lib/i18n';
import { notification } from '@/lib/notification';
import { Informations } from '@/lib/api/userInformation/types';
import { BillingAddress, DeliveryAddress } from '@/lib/api/address/types';
import AddressApi from '@/lib/api/address';
import slotsApi from '@/lib/api/slots';
import { useDispatch, useSelector } from 'react-redux';
import { StateType as CartStateType } from '@/store/cart/types';
import { ADD_SLOTS } from '@/store/cart/cartActions';
import usePdv from '@/lib/hooks/usePdv';
import Form from '../form';

type CreateProps = {
  userInfo: Informations;
  onCancel: () => void | Promise<void>;
  pageView?: string;
  isMKPDelivery?: boolean;
  type?: 'billing' | 'delivery' | null;
};

const Create = ({
  userInfo,
  onCancel,
  pageView,
  isMKPDelivery,
  type
}: CreateProps) => {
  const dispatch = useDispatch();
  // TODO : const cartStore = useShalloEqualSelector(useCart);
  const { cartStore } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      cartStore: cart
    };
  });
  const { ref } = usePdv();
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (address: BillingAddress | DeliveryAddress) => {
    try {
      setSubmitting(true);
      if (address.type === 'billing') {
        await AddressApi.createProfile(userInfo.id, userInfo.civility, {
          ...address,
          country: 'PRT'
        });
      } else {
        await AddressApi.create(userInfo.id, {
          ...address,
          country: 'PRT'
        });
      }
      const isCheckout = window.location.pathname.includes('/orders/');
      if (isCheckout && !isMKPDelivery) {
        try {
          const {
            data,
            vouchersCodeActive,
            discountCodeActive,
            defaultPayments
          } = await slotsApi.getAll(cartStore, ref, userInfo.id);
          dispatch({
            type: ADD_SLOTS,
            payload: {
              data,
              vouchersCodeActive,
              discountCodeActive,
              defaultPayments
            }
          });
        } catch (error: any) {
          window.location.href = '/orders/cart';
        }
      }
      await onCancel();
      notification.success(t('myAccount.myAddresses.form.success'));
    } catch (error: any) {
      notification.error(t('notification.erreur'));
    }

    setSubmitting(false);
  };

  let address = null;

  if (!type || type === 'delivery') {
    address = {
      type,
      name: '',
      isDefault: false,
      streetName: '',
      zipCode: '',
      city: '',
      country: 'PRT',
      isApartment: true,
      elevator: false,
      lastName: userInfo.lastName,
      firstName: userInfo.firstName,
      mobilePhone: userInfo.mobilePhone,
      phone: userInfo.phone
    } as DeliveryAddress;
  } else {
    address = {
      type,
      name: '',
      isDefault: false,
      streetName: '',
      additionalStreetName: '',
      zipCode: '',
      city: '',
      country: 'PRT',
      lastName: userInfo.lastName,
      firstName: userInfo.firstName,
      mobilePhone: userInfo.mobilePhone,
      phone: userInfo.phone
    } as BillingAddress;
  }

  return (
    <Form
      submitting={submitting}
      address={address}
      onSubmit={onSubmit}
      onCancel={onCancel}
      pageView={pageView}
    />
  );
};

export default Create;
