import FormRadio, { Option } from '@/components/atoms/formRadio';
import t from '@/lib/i18n';
import {
  UpdateSurveyAction,
  UPDATE_SURVEY_ANSWER
} from '@/store/survey/actions';
import _get from 'lodash/get';
import React from 'react';
import StepHeader from '../stepHeader';
import { SharedProps } from '../types';
import './style.scss';

const RadioForm: React.FC<SharedProps> = (props) => {
  const { dispatch, suggestions, questionId, currentResponse } = props;

  const setSuggestionInStore = (suggestionId: string | number) => {
    dispatch({
      type: UPDATE_SURVEY_ANSWER,
      payload: {
        response: {
          questionId,
          selectedChoices: [{ suggestionId: Number(suggestionId) }]
        }
      }
    } as UpdateSurveyAction);
  };
  const selectedSuggestionId: number = _get(
    currentResponse,
    `selectedChoices[0].suggestionId`,
    -1
  );

  const options: Array<Option> = suggestions.map((suggestion, index) => {
    return {
      id: index.toString(),
      label: t(
        `smartconsoForm.question${questionId}.suggestion${suggestion.suggestionId}`
      ),
      value: suggestion.suggestionId
    };
  });

  const onChange = (value: string | number) => {
    setSuggestionInStore(value);
  };
  return (
    <form className="smartconso-radio">
      <StepHeader {...props} />
      <FormRadio
        className="smartconso-radio__input"
        options={options}
        itemsType="block"
        onChange={onChange}
        value={selectedSuggestionId}
      />
    </form>
  );
};

export default RadioForm;
