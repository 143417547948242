import React from 'react';
import Modal from '@/components/organisms/modal';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import classNames from 'classnames';
import t from '@/lib/i18n';
import AccountApi from '@/lib/api/account';
import { RESET_USER_REGISTER } from '@/store/register/registerActions';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { NewUserLocal } from '@/store/register/types';
import useEvent from '@/lib/hooks/useEvent';
import Title from '@/components/atoms/title';

export type CguModalStateProps = {
  isLoading: boolean;
  modalIsOpen: boolean;
  submitError: any[];
};

type CguModalProps = {
  cgu: ContentfulContent;
  user: NewUserLocal;
  state: CguModalStateProps;
  setState: (state: any) => void;
};

const CguModal = ({ cgu, user, state, setState }: CguModalProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const event = useEvent();

  const submitAccountWithCgu = async (cguFid: boolean) => {
    setState((prevState: CguModalStateProps) => ({
      ...prevState,
      isLoading: true
    }));

    const isCreated = await AccountApi.createAccount({
      ...user,
      cguValided: cguFid
    });

    switch (isCreated.status) {
      case 'success':
        dispatch({
          type: RESET_USER_REGISTER
        });
        event.send('api', {
          type: 'success',
          name: 'signUp'
        });
        router.push('/my-account/create-my-account/validation');
        break;
      default:
        event.send('api', {
          type: 'error',
          name: 'signUp'
        });
        setState((prevState: CguModalStateProps) => ({
          ...prevState,
          submitError: isCreated.data,
          isLoading: false,
          modalIsOpen: false
        }));
        break;
    }
  };

  return (
    <Modal
      className="cguModal"
      pageView="CGUFidelityCreation"
      open={state.modalIsOpen}
      scrollable={true}
      closable={true}
      confirmBtn={{
        label: t('register.cgu.btnConfirm'),
        onClick: () => submitAccountWithCgu(true),
        loading: state.isLoading,
        id: 'acceptCgu'
      }}
      cancelBtn={{
        disabled: state.isLoading,
        label: t('register.cgu.btnCancel'),
        id: 'declineCgu',
        onClick: () => {
          setState((prevState: CguModalStateProps) => ({
            ...prevState,
            modalIsOpen: false
          }));
        }
      }}
      onClose={() =>
        setState((prevState: CguModalStateProps) => ({
          ...prevState,
          modalIsOpen: false
        }))
      }
    >
      <div className={classNames('cguWrapper')}>
        <Title tag="h1" label={t('common.cguFid.popinTitle')} />
        <ContentfulRenderComponents components={cgu.components} />
      </div>
    </Modal>
  );
};

export default CguModal;
