import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import ProductModel from '@/lib/model/product';
import queryString from 'query-string';

class FrequentShoppingListApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  list = async (userId: string): Promise<Array<ProductModel>> => {
    let response;

    try {
      response = await this.axios.get(
        `produits/v2/consommateurs/${userId}/produits-favoris`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch frequent list',
        error
      });
    }

    if (!response) {
      return [];
    }

    const products: Array<ProductModel> = [];
    const data = response.data ?? [];

    data.forEach((elt: any) => {
      products.push(new ProductModel(elt));
    });

    return products;
  };

  deleteProducts = async (
    userId: string,
    products: Array<ProductModel>
  ): Promise<void> => {
    const eans = products.map((product) => product.ean);
    const qs = queryString.stringify({ eans });

    try {
      await this.axios.delete(
        `produits/v2/consommateurs/${userId}/produits-favoris?${qs}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to delete products from frequent list',
        error
      });
    }
  };
}

export default new FrequentShoppingListApi();
