import React from 'react';
import classnames from 'classnames';
import { PartenersDatasProps } from '@/lib/thirdPartyApi';
import './style.scss';

export type CriteoData = {
  partnersDatas: PartenersDatasProps;
  productGridCount: number;
};

const HighCoTile = ({ partnersDatas, productGridCount }: CriteoData) => {
  if (!partnersDatas.isHighcoExist) return null;
  if (productGridCount < 3) return null;
  return (
    <>
      <div
        ref={partnersDatas.tileHighCo}
        data-regie-id="highCo-custom-tile"
        className={classnames('productList__grid__item', 'highCoTile')}
      />
    </>
  );
};

export default HighCoTile;
