import React, { useEffect, useState } from 'react';
import { notification } from '@/lib/notification';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import t from '@/lib/i18n';
import { AuthStateType } from '@/store/auth/authReducer';
import { useForm, Controller } from 'react-hook-form';
import Button from '@/components/atoms/button';
import FormRadio from '@/components/atoms/formRadio';
import Checkbox from '@/components/atoms/formCheckbox';
import CommonSprite from '@/assets/images/sprites/common.svg';
import userInformationApi from '@/lib/api/userInformation';
import { OptIn, OptOut } from '@/lib/api/userInformation/types';
import LoadableContent from '@/components/atoms/loadableContent';
import useEvent from '@/lib/hooks/useEvent';

type FormData = {
  optIn: OptIn;
  optOut: OptOut;
};

const booleanChoices = [
  {
    id: 'yes',
    label: t('yes'),
    value: 1
  },
  {
    id: 'no',
    label: t('no'),
    value: 0
  }
];

const Form = () => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );

  const [state, setState] = useState({
    isLoading: true,
    isUpdating: false,
    openedTabs: {
      personalCommunication: false,
      personalNavigation: false,
      usePersonalDataForAds: false
    }
  });

  const event = useEvent();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control
  } = useForm<FormData>({
    shouldUnregister: false,
    defaultValues: {
      optIn: {
        itmEmail: false,
        itmSms: false,
        itmLetter: false,
        partnerEmail: false,
        partnerSms: false
      },
      optOut: {
        personalCommunication: true,
        personalNavigation: true,
        usePersonalDataForAds: true
      }
    }
  });

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }

      const informations = await userInformationApi.getInformations(userId);

      reset({
        optIn: {
          ...informations.optIn
        },
        optOut: {
          ...informations.optOut
        }
      });

      setState((oldState) => {
        return {
          ...oldState,
          isLoading: false
        };
      });
    })();
  }, [userId, reset]);

  const onSubmit = async (data: any) => {
    if (!userId) {
      return;
    }

    setState({
      ...state,
      isUpdating: true
    });

    try {
      await userInformationApi.updatePersonalData(userId, data);
      notification.success(t('myAccount.myPersonalData.notification.success'));
    } catch (error: any) {
      notification.error(t('notification.erreur'));
    }

    setState({
      ...state,
      isUpdating: false
    });
  };

  const onChangeOptin = (
    name: string,
    onChange: (value: string) => void,
    newValue: any
  ) => {
    event.send('accountOpt', {
      name,
      type: newValue ? 'yes' : 'no'
    });
    return onChange(newValue);
  };

  const onChangeOptout = (name: string, value: any) => {
    event.send('accountOpt', {
      name,
      type: value ? 'optout' : 'optin'
    });
    return setValue(name, value);
  };

  return (
    <LoadableContent className="myPersonalData__form" loading={state.isLoading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="myPersonalData__title">
          {t('myAccount.myPersonalData.form.offers-news.title')}
        </h2>
        <section className="myPersonalData__section">
          <div className="myPersonalData__subTitle">
            {t('myAccount.myPersonalData.form.offers-advices')}
          </div>
          <table className="myPersonalData__table">
            <tbody>
              <tr>
                <th className="myPersonalData__table_label">
                  {t('myAccount.myPersonalData.optIn.by-email')}&nbsp;* :
                </th>
                <td>
                  <Controller
                    control={control}
                    name="optIn.itmEmail"
                    render={({ onChange, value, name, ref }) => (
                      <FormRadio
                        name={name}
                        options={booleanChoices}
                        ref={ref}
                        value={Number(value)}
                        onChange={(newValue) =>
                          onChangeOptin(name, onChange, newValue)
                        }
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th className="myPersonalData__table_label">
                  {t('myAccount.myPersonalData.optIn.by-sms')}&nbsp;* :
                </th>
                <td>
                  <Controller
                    control={control}
                    name="optIn.itmSms"
                    render={({ onChange, value, name, ref }) => (
                      <FormRadio
                        name={name}
                        options={booleanChoices}
                        ref={ref}
                        value={Number(value)}
                        onChange={(newValue) =>
                          onChangeOptin(name, onChange, newValue)
                        }
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  {t('myAccount.myPersonalData.optIn.by-post-box')}&nbsp;* :
                </th>
                <td className="myPersonalData__table_label">
                  <Controller
                    control={control}
                    name="optIn.itmLetter"
                    render={({ onChange, value, name, ref }) => (
                      <FormRadio
                        name={name}
                        options={booleanChoices}
                        ref={ref}
                        value={Number(value)}
                        onChange={(newValue) =>
                          onChangeOptin(name, onChange, newValue)
                        }
                      />
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="myPersonalData__section">
          <div
            className="myPersonalData__subTitle"
            dangerouslySetInnerHTML={{
              __html: t('myAccount.myPersonalData.form.partner-offers-news', {
                '%link%': `<a class="link" href="/legal-informations/personal-data-and-cookies" target="_blank">${t(
                  'myAccount.myPersonalData.form.partner-offers-news.link'
                )}</a>`
              })
            }}
          />
          {/*    <table className="myPersonalData__table">
            <tbody>
              <tr>
                <th className="myPersonalData__table_label">
                  {t('myAccount.myPersonalData.optIn.by-email')}&nbsp;* :
                </th>
                <td>
                  <Controller
                    control={control}
                    name="optIn.partnerEmail"
                    render={({ onChange, value, name, ref }) => (
                      <FormRadio
                        name={name}
                        options={booleanChoices}
                        ref={ref}
                        value={Number(value)}
                        onChange={(newValue) =>
                          onChangeOptin(name, onChange, newValue)
                        }
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <th className="myPersonalData__table_label">
                  {t('myAccount.myPersonalData.optIn.by-sms')}&nbsp;* :
                </th>
                <td>
                  <Controller
                    control={control}
                    name="optIn.partnerSms"
                    render={({ onChange, value, name, ref }) => (
                      <FormRadio
                        name={name}
                        options={booleanChoices}
                        ref={ref}
                        value={Number(value)}
                        onChange={(newValue) =>
                          onChangeOptin(name, onChange, newValue)
                        }
                      />
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table> */}
          <div className="myPersonalData__subTitle">
            {t('myAccount.myPersonalData.form.perso-offers')}
          </div>
          <div
            className={classnames('myPersonalData__allowPersoItem', {
              'myPersonalData__allowPersoItem--active':
                state.openedTabs.personalCommunication
            })}
          >
            <div className="myPersonalData__allowPersoItem_title">
              <Checkbox
                name="optOut.personalCommunication"
                id="personalCommunication"
                label={t(
                  'myAccount.myPersonalData.optOut.personalCommunication.title'
                )}
                ref={register}
                checked={getValues('optOut.personalCommunication')}
                onChange={(value) =>
                  onChangeOptout('optOut.personalCommunication', value)
                }
              />
              <svg
                className="fill--red myPersonalData__allowPersoItem_picto"
                height="16"
                width="16"
                onClick={() => {
                  setState({
                    ...state,
                    openedTabs: {
                      ...state.openedTabs,
                      personalCommunication: !state.openedTabs
                        .personalCommunication
                    }
                  });
                }}
              >
                <use xlinkHref={`${CommonSprite}#arrow-up`} />
              </svg>
            </div>
            <div className="myPersonalData__allowPersoItem_content">
              {t(
                'myAccount.myPersonalData.optOut.personalCommunication.content'
              )}
            </div>
          </div>
          <div
            className={classnames('myPersonalData__allowPersoItem', {
              'myPersonalData__allowPersoItem--active':
                state.openedTabs.personalNavigation
            })}
          >
            <div className="myPersonalData__allowPersoItem_title">
              <Checkbox
                name="optOut.personalNavigation"
                id="personalNavigation"
                label={t(
                  'myAccount.myPersonalData.optOut.personalNavigation.title'
                )}
                ref={register}
                checked={getValues('optOut.personalNavigation')}
                onChange={(value) =>
                  onChangeOptout('optOut.personalNavigation', value)
                }
              />
              <svg
                className="fill--red myPersonalData__allowPersoItem_picto"
                height="16"
                width="16"
                onClick={() => {
                  setState({
                    ...state,
                    openedTabs: {
                      ...state.openedTabs,
                      personalNavigation: !state.openedTabs.personalNavigation
                    }
                  });
                }}
              >
                <use xlinkHref={`${CommonSprite}#arrow-up`} />
              </svg>
            </div>
            <div className="myPersonalData__allowPersoItem_content">
              {t('myAccount.myPersonalData.optOut.personalNavigation.content')}
            </div>
          </div>
          <div
            className={classnames('myPersonalData__allowPersoItem', {
              'myPersonalData__allowPersoItem--active':
                state.openedTabs.usePersonalDataForAds
            })}
          >
            <div className="myPersonalData__allowPersoItem_title">
              <Checkbox
                name="optOut.usePersonalDataForAds"
                id="usePersonalDataForAds"
                label={t(
                  'myAccount.myPersonalData.optOut.usePersonalDataForAds.title'
                )}
                ref={register}
                checked={getValues('optOut.usePersonalDataForAds')}
                onChange={(value) =>
                  onChangeOptout('optOut.usePersonalDataForAds', value)
                }
              />
              <svg
                className="fill--red myPersonalData__allowPersoItem_picto"
                height="16"
                width="16"
                onClick={() => {
                  setState({
                    ...state,
                    openedTabs: {
                      ...state.openedTabs,
                      usePersonalDataForAds: !state.openedTabs
                        .usePersonalDataForAds
                    }
                  });
                }}
              >
                <use xlinkHref={`${CommonSprite}#arrow-up`} />
              </svg>
            </div>
            <div className="myPersonalData__allowPersoItem_content">
              {t(
                'myAccount.myPersonalData.optOut.usePersonalDataForAds.content'
              )}
            </div>
          </div>
        </section>
        <div className="myPersonalData__formValidate">
          <Button type="submit" loading={state.isUpdating}>
            {t('myAccount.myPersonalData.form.submit')}
          </Button>
        </div>
      </form>
    </LoadableContent>
  );
};

export default Form;
