import React from 'react';
import Title from '@/components/atoms/title';
import Link from '@/components/atoms/link';
import './style.scss';

type ProspectusInfoProps = {
  storeProspectus: {
    title: string;
    subTitle: string;
    cta: any;
    image: any;
  };
};

function ProspectusInfo({ storeProspectus }: ProspectusInfoProps) {
  const { title, subTitle, cta, image } = storeProspectus;
  return (
    <div className="prospectusInfo">
      <div className="prospectusInfo__imageWrapper">
        <img
          className="prospectusInfo__imageWrapper__image"
          alt={image.fileName}
          src={image.url}
        />
      </div>
      <div className="prospectusInfo__content">
        <Title label={title} tag="h2" className="prospectusInfo__title" />
        <h3
          className="prospectusInfo__subtitle"
          dangerouslySetInnerHTML={{
            __html: subTitle
          }}
        />
        <div>
          <Link href="/sign/brands/catalog-page" label={cta.text} button />
        </div>
      </div>
    </div>
  );
}

export default React.memo(ProspectusInfo);
