import { TitleSectionProps } from '@/components/atoms/titleSection';
import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): ComponentClaimProps => {
  const { text } = data;
  return {
    type: 'atoms',
    name: 'titleSection',
    props: {
      text
    }
  };
};

export default normalize;

interface ComponentClaimProps extends Component {
  props: TitleSectionProps;
}
