import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useClickOut from '@/lib/hooks/useClickOut';
import { StoreInfo } from '@/lib/api/pdv/types';
import PdvApi from '@/lib/api/pdv';
import { getPdvRef } from '@/lib/hooks/getPdv';
import _debounce from 'lodash/debounce';
import t from '@/lib/i18n';
import FormInput from '@/components/atoms/formInput';
import { FieldErrors } from 'react-hook-form';
import { FieldFormProps } from '../../formsTemplate';
import SelectedPdv from './selectedPdv';
import StoresSuggestionList from './storesSuggestionList';
import './style.scss';

type StoreAutocompleteInputProps = {
  field: FieldFormProps;
  register: any;
  setValue: any;
  errors: FieldErrors;
  isMandatoryField: boolean;
};

function checkValues(storeInfo: StoreInfo) {
  const definedValues = Object.values(storeInfo).filter(
    (value) => value !== undefined
  );
  return definedValues.length > 1;
}

const StoreAutocompleteInput = ({
  field,
  register,
  setValue,
  errors,
  isMandatoryField
}: StoreAutocompleteInputProps) => {
  const [retrievedResults, setRetrievedResults] = useState<StoreInfo[]>([]);
  const [selectedPdv, setSelectedPdv] = useState<StoreInfo | null>(null);
  const [clickOutRef, clickOutHandler] = useClickOut();

  useEffect(() => {
    register('storeId');
    register('city');

    const defaultPdvId = getPdvRef();

    if (defaultPdvId) {
      setValue('storeId', defaultPdvId);
      (async () => {
        const defaultPdv = await PdvApi.getPdvInfo(defaultPdvId);
        setValue('city', defaultPdv?.address?.city);
        setSelectedPdv({
          storeId: defaultPdvId,
          address: defaultPdv?.address?.address1,
          name: defaultPdv?.format,
          townLabel: defaultPdv?.address?.city,
          zipCode: defaultPdv?.address?.zipCode
        });
      })();
    }
  }, [register, setValue]);

  const deboundSearch = useMemo(
    () =>
      _debounce(
        (keyword) => {
          PdvApi.getStoresInfo('AUTOCOMPLETE', keyword).then((data) => {
            setRetrievedResults(data?.storeInfoList ?? []);
          });
        },
        300,
        { maxWait: 1000 }
      ),
    []
  );

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 1) {
      deboundSearch(e.target.value);
    } else {
      setRetrievedResults([]);
    }
  };

  clickOutHandler(() => setRetrievedResults([]));

  return (
    <div className="storeAutocompleteInput">
      <label htmlFor={field.name} className="storeAutocompleteInput__label">
        {`${field.label && t(field.label)}${isMandatoryField ? '*' : ''}`}
      </label>
      <FormInput
        className="renderField__inputWrapper"
        name={field.name}
        id={field.name}
        type="text"
        errorMessage={t(errors[field.name]?.message as string)}
        maxLength={field.maxLength}
        onChange={changeHandler}
        autoComplete="disable"
      />
      {selectedPdv && checkValues(selectedPdv) && (
        <SelectedPdv
          store={selectedPdv}
          onDelete={() => {
            setValue('storeId', null);
            setSelectedPdv(null);
          }}
        />
      )}
      {retrievedResults.length > 0 && (
        <StoresSuggestionList
          stores={retrievedResults}
          onStoreClick={(store) => {
            setValue(field.name, store.townLabel);
            setValue('storeId', store.storeId);
            setSelectedPdv({
              storeId: store.storeId,
              address: store.address,
              name: store.name,
              townLabel: store.townLabel,
              zipCode: store.zipCode
            });
            setRetrievedResults([]);
          }}
          ref={clickOutRef}
        />
      )}
    </div>
  );
};

export default StoreAutocompleteInput;
