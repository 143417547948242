import React from 'react';
import './style.scss';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import { withESI, ESIOptions } from '@/lib/esi';
import Layout from '@/components/organisms/layout';
import Header from '@/components/organisms/header';
import { ContentProps } from './content';
import Content from './contentESI';
import Slots from './slotsESI';

export type HomepageProps = {
  content?: ContentProps;
  footer: ContentfulContent;
  pageView: string;
};

const Homepage = ({ content, footer, pageView }: HomepageProps) => {
  const headerPart = <Header />;

  const ContentESI = withESI(Content, {
    id: 'home-content',
    type: 'templates',
    name: 'homepage/content'
  });

  const SlotsBannerESI = withESI(Slots, {
    id: 'home-slots',
    type: 'templates',
    name: 'homepage/slots'
  });

  const SlotsBanner = <SlotsBannerESI esi={ESIOptions()} />;

  const contentPart = <ContentESI esi={ESIOptions()} {...content} />;

  const footerPart = footer && (
    <>
      <ContentfulRenderComponents components={footer.components} />
      {SlotsBanner && SlotsBanner}
    </>
  );

  return (
    <Layout
      className="home"
      header={headerPart}
      content={contentPart}
      footer={footerPart}
      pageView={pageView}
    />
  );
};

export default Homepage;
