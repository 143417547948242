import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import countries from '@/lib/utils/countries';
import { useForm, Controller } from 'react-hook-form';
import { BillingAddress, DeliveryAddress } from '@/lib/api/address/types';
import Button from '@/components/atoms/button';
import FormInput from '@/components/atoms/formInput';
import FormRadio from '@/components/atoms/formRadio';
import FormTextArea from '@/components/atoms/formTextArea';
import FormSelect from '@/components/atoms/formSelect';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import { isValidAddress } from '@/lib/utils/stringUtils';
import usePageView from '@/lib/hooks/usePageView';
import usePdv from '@/lib/hooks/usePdv';
import CityWithZipCode from '@/components/organisms/cityWithZipCode';

type AddressProps = {
  address: BillingAddress | DeliveryAddress;
  submitting: boolean;
  onCancel: () => void;
  onSubmit: (address: BillingAddress | DeliveryAddress) => void;
  pageView?: string;
};

const isDelivery = (
  address: BillingAddress | DeliveryAddress
): address is DeliveryAddress => {
  return (address as DeliveryAddress).type === 'delivery';
};

type StateType = {
  submitting: boolean;
  showApartmentInfo: boolean;
  cities: Array<{ label: string; value: string }>;
};

const getFloorOptions = () => {
  const options = [];

  for (let i = 0; i <= 10; i++) {
    options.push({
      label: t(`address.floor.${i}`),
      value: i
    });
  }

  return options;
};

const Form = ({
  address,
  submitting,
  onSubmit,
  onCancel,
  pageView
}: AddressProps) => {
  const [state, setState] = useState<StateType>({
    submitting,
    showApartmentInfo: isDelivery(address) ? address?.isApartment : false,
    cities: []
  });

  const pageViewer = usePageView();

  const {
    register,
    errors,
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger
  } = useForm<any>({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: address
  });

  useEffect(() => {
    setState((oldState) => {
      return {
        ...oldState,
        submitting
      };
    });
  }, [submitting]);

  useEffect(() => {
    if (pageView) {
      const { ref } = usePdv();
      pageViewer.send(pageView, {
        pdvRef: ref
      });
    }
  }, [pageViewer, pageView]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="myAddressForm">
      <div className="myAddressForm__title">
        {address
          ? t(`address.form.${address.type}.title`)
          : t('address.form.new.billing.title')}
      </div>
      {address.type === 'delivery' && (
        <>
          <div className="myAddressForm__inputGroup">
            {address.isDefault && (
              <div className="myAddresses__address_heading">
                <svg width="16" height="16">
                  <use xlinkHref={`${MyAccountSprite}#radio-on`} />
                </svg>
                <div className="myAddresses__address_label myAddresses__address_label--default">
                  {t('address.label.isDefault')}
                </div>
              </div>
            )}
            {!address.isDefault && (
              <Controller
                className="myAddressForm__radio"
                control={control}
                name="isDefault"
                render={({ onChange, value, name, ref }) => (
                  <FormRadio
                    name={name}
                    ref={ref}
                    label={t('address.isDefault')}
                    options={[
                      {
                        id: 'yes',
                        label: t('yes'),
                        value: 1
                      },
                      {
                        id: 'no',
                        label: t('no'),
                        value: 0
                      }
                    ]}
                    onChange={(newValue) => onChange(Boolean(newValue))}
                    value={Number(value)}
                  />
                )}
              />
            )}
          </div>
          <div className="myAddressForm__inputGroup">
            <FormInput
              id="name"
              name="name"
              label={`${t('address.label.name')} *`}
              className="myAddressForm__input"
              maxLength={38}
              autoComplete="organization"
              ref={register({
                required: true,
                maxLength: 38
              })}
              errorMessage={
                errors.name ? t(`form.error.${errors.name.type}`) : ''
              }
            />
          </div>
        </>
      )}
      {address.type === 'billing' && (
        <div className="myAddressForm__inputGroup">
          <FormInput
            id="name"
            name="name"
            label={`${t('address.label.name')} *`}
            className="myAddressForm__input"
            maxLength={38}
            autoComplete="organization"
            ref={register({
              required: true,
              maxLength: 38
            })}
            errorMessage={
              errors.name ? t(`form.error.${errors.name.type}`) : ''
            }
          />
        </div>
      )}
      <div className="myAddressForm__inputGroup">
        <FormInput
          id="streetName"
          name="streetName"
          label={`${t('address.label.streetName')} *`}
          className="myAddressForm__input"
          autoComplete="address-line1"
          message={t('form.error.isValidAddressLine')}
          maxLength={38}
          ref={register({
            required: true,
            validate: {
              isValidAddressLine: isValidAddress
            }
          })}
          errorMessage={
            errors.streetName ? t(`form.error.${errors.streetName.type}`) : ''
          }
        />
      </div>
      <div className="myAddressForm__inputGroup">
        <FormInput
          id="additionalStreetName"
          name="additionalStreetName"
          label={t('address.label.additionalStreetName')}
          className="myAddressForm__input"
          autoComplete="address-line2"
          message={t('form.error.isValidAddressLine')}
          maxLength={38}
          ref={register({
            validate: {
              isValidAddressLine: isValidAddress
            }
          })}
          errorMessage={
            errors.additionalStreetName
              ? t(`form.error.${errors.additionalStreetName.type}`)
              : ''
          }
        />
      </div>
      {address.type === 'billing' && (
        <div className="myAddressForm__inputGroup">
          <FormInput
            id="additionalInformation"
            name="additionalInformation"
            label={t('address.label.additionalInformation')}
            className="myAddressForm__input"
            maxLength={38}
            message={t('form.error.isValidAddressLine')}
            ref={register({
              validate: {
                isValidAddressLine: isValidAddress
              }
            })}
            errorMessage={
              errors.additionalInformation
                ? t(`form.error.${errors.additionalInformation.type}`)
                : ''
            }
          />
        </div>
      )}

      <div className="myAddressForm__rowGroup">
        <CityWithZipCode
          oldCity={address.city}
          zipCodeClassName="myAddressForm__input"
          getValues={getValues}
          onCitiesLoaded={(newValue) => setValue('city', newValue)}
          errors={errors}
          control={control}
          cityClassName="myAddressForm__select"
          register={register}
          trigger={trigger}
        />
      </div>
      <div className="myAddressForm__inputGroup">
        <Controller
          control={control}
          name="country"
          rules={{
            required: address.type !== 'delivery'
          }}
          render={({ onChange, value, name, ref }) => (
            <FormSelect
              id={name}
              name={name}
              label={`${t('address.label.country')} *`}
              disabled={address.type === 'delivery'}
              className="myAddressForm__select"
              ref={ref}
              value={value}
              autoComplete="country"
              onChange={(newValue) => {
                setValue('city', '', {
                  shouldValidate: true
                });
                setValue('zipCode', '', {
                  shouldValidate: true
                });
                onChange(newValue);
              }}
              options={countries.map((country) => {
                return {
                  label: country.label,
                  value: country.code
                };
              })}
              errorMessage={
                errors.country ? t(`form.error.${errors.country.type}`) : ''
              }
            />
          )}
        />
      </div>
      {address.type === 'delivery' && (
        <>
          <div className="myAddressForm__inputGroup">
            <Controller
              control={control}
              name="isApartment"
              render={({ onChange, value, name, ref }) => (
                <FormRadio
                  className="myAddressForm__radio"
                  name={name}
                  label={`${t('address.label.accommodation')} *`}
                  options={[
                    {
                      id: 'yes',
                      label: t('address.apartment'),
                      value: 1
                    },
                    {
                      id: 'no',
                      label: t('address.house'),
                      value: 0
                    }
                  ]}
                  ref={ref}
                  value={Number(value)}
                  onChange={(newValue) => {
                    onChange(newValue);
                    setState({
                      ...state,
                      showApartmentInfo: Boolean(newValue)
                    });
                  }}
                />
              )}
            />
          </div>
          {state.showApartmentInfo && (
            <>
              <div className="myAddressForm__rowGroup">
                <div className="myAddressForm__inputGroup">
                  <FormInput
                    id="buildingNumber"
                    name="buildingNumber"
                    label={t('address.label.buildingNumber')}
                    className="myAddressForm__input"
                    ref={register({
                      validate: {
                        isValidAddressLine: isValidAddress
                      }
                    })}
                    maxLength={38}
                    errorMessage={
                      errors.buildingNumber
                        ? t(`form.error.${errors.buildingNumber.type}`)
                        : ''
                    }
                  />
                </div>
                <div className="myAddressForm__inputGroup">
                  <FormSelect
                    id="floor"
                    name="floor"
                    label={t('address.label.floor')}
                    className="myAddressForm__select"
                    ref={register()}
                    options={getFloorOptions()}
                    errorMessage={
                      errors.floor ? t(`form.error.${errors.floor.type}`) : ''
                    }
                  />
                </div>
                <div className="myAddressForm__inputGroup">
                  <FormInput
                    id="digicode"
                    name="digicode"
                    label={t('address.label.digicode')}
                    className="myAddressForm__input"
                    ref={register()}
                    maxLength={10}
                  />
                </div>
              </div>
              <div className="myAddressForm__inputGroup">
                <Controller
                  control={control}
                  name="elevator"
                  render={({ onChange, value, name, ref }) => (
                    <FormRadio
                      className="myAddressForm__radio"
                      name={name}
                      label={t('address.label.elevator')}
                      options={[
                        {
                          id: 'yes',
                          label: t('yes'),
                          value: 1
                        },
                        {
                          id: 'no',
                          label: t('no'),
                          value: 0
                        }
                      ]}
                      ref={ref}
                      value={Number(value)}
                      onChange={(newValue) => onChange(newValue)}
                    />
                  )}
                />
              </div>
            </>
          )}
          <div className="myAddressForm__inputGroup">
            <FormTextArea
              id="deliverymanInstructions"
              name="deliverymanInstructions"
              className="myAddressForm__input"
              label={t('address.label.deliverymanInstructions')}
              ref={register}
              errorMessage={
                errors.deliverymanInstructions
                  ? t(`form.error.${errors.deliverymanInstructions.type}`)
                  : ''
              }
            />
          </div>
        </>
      )}
      <div className="myAddressForm__actions">
        <Button
          color="secondary"
          onClick={async (e) => {
            e.preventDefault();

            onCancel();
          }}
        >
          {t('address.form.button.cancel')}
        </Button>
        <Button type="submit" disabled={false} loading={state.submitting}>
          {t('address.form.button.validate')}
        </Button>
      </div>
    </form>
  );
};

export default Form;
