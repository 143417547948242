import React, { IframeHTMLAttributes } from 'react';
import classnames from 'classnames';
import './style.scss';

export type IframeProps = {
  attributes: IframeHTMLAttributes<HTMLIFrameElement>;
  className?: string;
};

const Iframe = ({ attributes, className }: IframeProps) => (
  <iframe
    title={attributes.name}
    name={attributes.name}
    src={attributes.src}
    width={attributes.width}
    height={attributes.height}
    className={classnames('iframe', className)}
  />
);

export default Iframe;
