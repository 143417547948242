import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import { CartType, PaymentStateType } from '@/store/cart/types';
import { DELIVERY_TYPE_MAPPING_INVERSE } from '@/lib/model/slot';
import DiscountCode, {
  ApiDiscountCodeErrorType
} from '@/lib/model/discountCode';
import t from '@/lib/i18n';
import { AuthUserStateType } from '@/store/auth/authReducer';
import { NormalizerInterface } from './normalizer';
import NormalizerLegacyVersion from './normalizer/normalizerLegacyVersion';
import NormalizerNewVersion from './normalizer/normalizerNewVersion';

const normalizers: { [key: string]: NormalizerInterface } = {
  legacyVersion: NormalizerLegacyVersion,
  newVersion: NormalizerNewVersion
};

class SlotsApi {
  axios: AxiosInstance;

  apiVersion: string;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);

    this.apiVersion = 'newVersion';
  }

  async sendDiscount(
    userId: string,
    type: string,
    voucher: string,
    totalCart: number
  ): Promise<DiscountCode | null> {
    let response = null;
    let apiErrorsHandled: ApiDiscountCodeErrorType | null = null;
    const deliveryTpe = DELIVERY_TYPE_MAPPING_INVERSE[type];

    try {
      response = await this.axios.get(
        `/codereduction/v1/consommateurs/${userId}/code-reductions/${voucher}?typeLivraison=${deliveryTpe}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to send discount',
        error
      });
      if (error.response?.status === 400 || error.response?.status === 404) {
        apiErrorsHandled = {
          message: t(`discountCode.errorApi.${error.response?.data?.Code}`),
          code: error.response?.data?.Code
        };
      } else {
        logger.error({
          message: 'Unable to send discount',
          error
        });
        // TODO: here witch message send to user if api error
        throw error;
      }
    }

    return response?.data || apiErrorsHandled
      ? new DiscountCode({
          apiData: response?.data,
          apiErrorsHandled,
          totalCart
        })
      : null;
  }

  async validation(
    token: string,
    userId?: string
  ): Promise<{ orderId: string }> {
    let response;
    try {
      response = await this.axios.post(
        `tunnelachat/v1/tunnels/${userId}/validate`,
        {
          token
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to validate payment',
        error
      });
      throw error;
    }
    const orderId = response.data?.id;
    return { orderId };
  }

  async send(
    cart: CartType,
    pdv: string,
    userInformations: AuthUserStateType,
    paymentState: PaymentStateType,
    rcId?: string
  ): Promise<{ url?: string | null; numeroCommande?: string | null }> {
    let data = {};

    try {
      const body: any = normalizers[this.apiVersion].normalize(
        cart,
        pdv,
        userInformations,
        paymentState
      );
      const response = await this.axios.post(
        `/tunnelachat/v1/tunnels/${rcId}/order?fallback=${paymentState.paymentFallback.warning}`,
        body
      );

      data = {
        ...data,
        url: response.data.url,
        numeroCommande: response.data.id
      };
    } catch (error: any) {
      logger.error({
        message: 'Unable to initiate payment',
        error
      });

      throw error;
    }

    return data;
  }
}

export default new SlotsApi();
