import React from 'react';
import './style.scss';
import classNames from 'classnames';
import SmartConsoSprite from '@/assets/images/sprites/smartconso.svg';

type ScoreInitProps = {
  scoreInit?: number;
  rank: string;
  ratio?: boolean;
};

const AvoidScore = () => (
  <div className="scoreInit__note scoreInit__avoid">
    <svg className="rank--avoid" width="100%" height="30px">
      <use xlinkHref={`${SmartConsoSprite}#avoid`} />
    </svg>
  </div>
);

const ScoreInit = ({ scoreInit, rank, ratio }: ScoreInitProps) => {
  return (
    <>
      {!ratio && (
        <div className="scoreInit">
          {rank !== 'avoid' && (
            <div className={classNames('scoreInit__note', `rank--${rank}`)}>
              {scoreInit}
            </div>
          )}
          {rank === 'avoid' && <AvoidScore />}
          <div className="scoreInit__name">
            <svg width="100%" height="100%">
              <use xlinkHref={`${SmartConsoSprite}#scoreInit`} />
            </svg>
          </div>
        </div>
      )}
      {ratio && (
        <div className="scoreInitWithRatio">
          {rank !== 'avoid' && (
            <div
              className={classNames(
                'scoreInitWithRatio__note',
                `rank--${rank}`
              )}
            >
              <div>{scoreInit}</div>
              <div className="scoreInitWithRatio__slash" />
              <div>100</div>
            </div>
          )}
          {rank === 'avoid' && <AvoidScore />}
          <div className="scoreInitWithRatio__name">
            <svg width="100%" height="100%">
              <use xlinkHref={`${SmartConsoSprite}#scoreInit`} />
            </svg>
          </div>
        </div>
      )}
    </>
  );
};

export default ScoreInit;
