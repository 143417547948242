import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';

export class CitiesApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi']);
  }

  async getCities(zipCode: string): Promise<Array<string>> {
    let response;

    try {
      response = await this.axios.get(
        `/geolocalisation/v1/communes?code_postal=${zipCode}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch cities for zip code',
        context: {
          zipCode
        },
        error
      });
    }

    return (
      response?.data.map((elt: any) => {
        return elt.nom;
      }) ?? []
    );
  }
}

export default new CitiesApi();
