import React from 'react';
import './styles.scss';

type MyAccountTilesProps = {
  children: JSX.Element | JSX.Element[];
};

const MyAccountTiles = ({ children }: MyAccountTilesProps) => {
  return <div className="myAccountTiles">{children}</div>;
};

export default MyAccountTiles;
