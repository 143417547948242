import React from 'react';
import classnames from 'classnames';
import './style.scss';
import t from '@/lib/i18n';
import countries, { Country } from '@/lib/utils/countries';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { BillingAddress, DeliveryAddress } from '@/lib/api/address/types';

type AddressProps = {
  onDelete?: () => void;
  onSelect?: () => void;
  className?: string;
  address: BillingAddress | DeliveryAddress;
  edit: (address: BillingAddress | DeliveryAddress) => void;
};

const Address = ({
  onDelete,
  onSelect,
  address,
  edit,
  className
}: AddressProps) => {
  const country: Country | undefined = countries.find((value) => {
    return value.code === address.country;
  });

  return (
    <div className={classnames('myAddresses__address', className)}>
      {onDelete && !address.isDefault && (
        <button
          className="myAddresses__address__trash"
          onClick={() => onDelete()}
        >
          <svg width="25" height="25">
            <use xlinkHref={`${CommonSprite}#trash`} />
          </svg>
        </button>
      )}
      <div
        onClick={() => {
          if (!onSelect) return;
          onSelect();
        }}
      >
        {address.type === 'delivery' && (
          <div className="myAddresses__address_heading">
            {address.isDefault && (
              <svg width="16" height="16">
                <use xlinkHref={`${MyAccountSprite}#radio-on`} />
              </svg>
            )}
            {!address.isDefault && (
              <svg width="16" height="16">
                <use xlinkHref={`${MyAccountSprite}#radio-off`} />
              </svg>
            )}
            <div
              className={classnames('myAddresses__address_label', {
                'myAddresses__address_label--default': address.isDefault
              })}
            >
              {address.name}
            </div>
          </div>
        )}
        <div className="myAddresses__address_name">
          {t('address.label.address')}:
        </div>
        <div className="myAddresses__address_value">
          {address.streetName} <br />
          {address.additionalStreetName && (
            <>
              {address.additionalStreetName} <br />
            </>
          )}
          {address.zipCode} {address.city}
          {address.type === 'delivery' && (
            <p>{t(`address.floor.${address.floor}`)}</p>
          )}
        </div>
        <div className="myAddresses__address_name">
          {t('address.label.country')}:
        </div>
        <div className="myAddresses__address_value">{country?.label}</div>
        {address.type === 'delivery' && (
          <>
            <div className="myAddresses__address_name">
              {t('address.label.accommodation')}:
            </div>
            <div className="myAddresses__address_value">
              {t(address.isApartment ? 'address.apartment' : 'address.house')}
            </div>
            {address.deliverymanInstructions && (
              <>
                <div className="myAddresses__address_name">
                  {t('address.label.deliverymanInstructions')}:
                </div>
                <div className="myAddresses__address_value">
                  {address.deliverymanInstructions}
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div
        className="myAddresses__address_button"
        onClick={() => {
          edit(address);
        }}
      >
        {t('address.edit')}
      </div>
    </div>
  );
};

export default Address;
