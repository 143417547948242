import React from 'react';
import Modal from '@/components/organisms/modal';
import t from '@/lib/i18n';
import './style.scss';

type ModalConsentSesProps = {
  validate: () => void;
  closeModal: () => void;
  open: boolean;
};

export const ModalRefusePerso = ({
  open,
  closeModal,
  validate
}: ModalConsentSesProps) => (
  <Modal
    open={open}
    closable={false}
    pageView="ModalRefusePerso"
    className="resetModal"
    scrollable={false}
    confirmBtn={{
      label: t('myAccount.smartConso.modalRefuse.confirm'),
      onClick: validate,
      isLink: true
    }}
    cancelBtn={{
      label: t('myAccount.smartConso.modalRefuse.cancel'),
      onClick: closeModal,
      isLink: true
    }}
  >
    <p className="resetModal__content resetModal__title">
      {t('myAccount.smartConso.modalRefuse.p1')}
    </p>
    <p className="resetModal__content">
      {t('myAccount.smartConso.modalRefuse.p2')}
    </p>
  </Modal>
);

export default ModalRefusePerso;
