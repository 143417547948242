import React from 'react';
import { Provider } from 'react-redux';
import './style.scss';
import { store } from '@/store';
import CategoriesModel from '@/lib/model/categories';
import CategoriesContainer from './CategoriesContainer';

export type CategoriesProps = {
  categories?: CategoriesModel;
};

const Categories = ({ categories }: CategoriesProps) => {
  return (
    <Provider store={store}>
      <CategoriesContainer categories={categories} />
    </Provider>
  );
};

export default Categories;
