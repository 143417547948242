import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { CLOSE_NAV, TOGGLE_NAV_ACTIVE } from '@/store/nav/navActions';
import { NavStateType } from '@/store/nav/navReducer';
import useClickOutWithExcludedElement from '@/lib/hooks/useClickOutWithExcludedElement';
import './style.scss';
import classnames from 'classnames';
import CategoriesModel from '@/lib/model/categories';
import NavPromotion from '@/lib/model/navPromotion';
import usePdv from '@/lib/hooks/usePdv';
import CategoriesDisplay from './CategoriesDisplay';

type CategoriesContainerProps = {
  categories?: CategoriesModel;
};

const CategoriesContainer = ({ categories }: CategoriesContainerProps) => {
  const dispatch = useDispatch();
  const [isLargeList, setIsLargeList] = useState(false);
  const navPromotions = useSelector(
    ({ nav }: { nav: NavStateType }) => nav.promotions,
    shallowEqual
  );
  const [pdvNavPromotions, setPdvNavPromotions] = useState<Array<NavPromotion>>(
    []
  );

  useEffect(() => {
    const { ref: pdvRef } = usePdv();
    const navPromotionsForPdv = navPromotions[pdvRef]?.data;
    setPdvNavPromotions(navPromotionsForPdv);
  }, [navPromotions]);

  const [clickOutRef, clickOutHandler] = useClickOutWithExcludedElement(
    '#toggleNavigationButton'
  );
  const isActive = useSelector(
    ({ nav }: { nav: NavStateType }) => nav.isActive
  );

  const toggleActive = () => {
    dispatch({ type: TOGGLE_NAV_ACTIVE });
  };

  useEffect(() => {
    document.documentElement.style.overflow = isActive ? 'hidden' : 'visible';
  }, [isActive]);

  clickOutHandler(() => {
    if (!isActive) return;
    dispatch({ type: CLOSE_NAV });
  });

  useEffect(() => {
    categories && setIsLargeList(categories?.tree.length > 20);
  }, [categories]);

  return (
    <div
      ref={clickOutRef}
      className={classnames({
        categories: true,
        'categories--isActive': isActive
      })}
      id="vertical-navigation"
    >
      <div
        className={classnames({
          categories__content: true,
          'categories__content--isLargeList': isLargeList
        })}
      >
        {categories && (
          <CategoriesDisplay
            tree={categories.tree}
            promotions={pdvNavPromotions}
            active={isActive}
            toggleActive={toggleActive}
          />
        )}
      </div>
    </div>
  );
};

export default CategoriesContainer;
