import { NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams) => {
  return {
    type: 'molecules',
    name: 'carouselWideEntry',
    props: {
      title: data.title || null,
      caption: data.caption || null,
      image: data.image || null
    }
  };
};

export default normalize;
