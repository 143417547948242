import React, { MouseEvent } from 'react';
import Paragraph from '@/components/atoms/paragraph';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Link from '@/components/atoms/link';
import classnames from 'classnames';
import './style.scss';

export type SocialSharingProps = {
  title: string;
  uriToShare: string;
  className?: string;
};

const SocialSharing = ({
  title,
  uriToShare,
  className
}: SocialSharingProps) => {
  const openWindow = (event: MouseEvent<any>) => {
    event.preventDefault();

    const target = event.currentTarget;
    window.open(
      `${target.href}${uriToShare}`,
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
    );
  };
  return (
    <div className={classnames('socialSharing', className)}>
      <Paragraph label={title} className="socialSharing__title" />
      <div className="socialSharing__wrapper">
        <Link
          className="socialSharing__wrapper__item"
          href="mailto:?body="
          onClick={(e) => openWindow(e)}
        >
          <svg height="22" width="22">
            <use xlinkHref={`${CommonSprite}#mail`} />
          </svg>
        </Link>
        <Link
          className="socialSharing__wrapper__item"
          href="https://www.facebook.com/sharer/sharer.php?u="
          onClick={(e) => openWindow(e)}
        >
          <svg height="22" width="22">
            <use xlinkHref={`${CommonSprite}#facebook`} />
          </svg>
        </Link>
        <Link
          className="socialSharing__wrapper__item"
          href="http://twitter.com/share?url="
          onClick={(e) => openWindow(e)}
        >
          <svg height="22" width="22">
            <use xlinkHref={`${CommonSprite}#twitter`} />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default SocialSharing;
