import Image from '@/components/atoms/image';
import Link from '@/components/atoms/link';
import Slider from '@/components/molecules/slider';
import { Period } from '@/lib/contentful/normalizers/periode';
import useEvent from '@/lib/hooks/useEvent';
import t from '@/lib/i18n';
import Date from '@/lib/utils/date';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import './styles.scss';

export type Slide = {
  campaign: string;
  title?: string;
  shortTitle?: string;
  description?: string;
  image: string;
  imageSmall?: string;
  dates?: Period | null;
  badge: string | null;
  link?: {
    label: string;
    url: string;
  } | null;
};

export type CampaignSliderProps = {
  slides: Array<Slide>;
};

const CampaignSlide = ({
  slide,
  position
}: {
  slide: Slide;
  position: number;
}) => {
  let begin: string | null = null;
  let end: string | null = null;
  const event = useEvent();
  const router = useRouter();

  if (slide.dates) {
    begin = Date(slide.dates.begin).format('DD MMMM');
    end = Date(slide.dates.end).format('DD MMMM YYYY');
  }

  return (
    <div className="sliderCampaign__content">
      <Image
        className="sliderCampaign__media desktop"
        src={slide.image || ''}
        alt={slide.title || ''}
      />
      {slide.imageSmall && (
        <Image
          className="sliderCampaign__media responsive"
          src={slide.imageSmall}
          alt={slide.title || ''}
        />
      )}

      <div className="sliderCampaign__informations">
        <div className="sliderCampaign__intro">
          {slide.badge && (
            <span className="sliderCampaign__badge">{slide.badge}</span>
          )}
          {begin && end && (
            <>{t('home.slider.date', { '%start%': begin, '%end%': end })}</>
          )}
        </div>

        <h1 className="sliderCampaign__title">
          {slide.shortTitle && (
            <span
              className="sliderCampaign__title-mobile"
              dangerouslySetInnerHTML={{ __html: slide.shortTitle }}
            />
          )}
          {slide.title && (
            <span
              className="sliderCampaign__title-desktop"
              dangerouslySetInnerHTML={{ __html: slide.title }}
            />
          )}
        </h1>
        {slide.description && (
          <h2
            className="sliderCampaign__subtitle"
            dangerouslySetInnerHTML={{ __html: slide.description }}
          />
        )}
        {slide.link && (
          <Link
            className="sliderCampaign__btn"
            href={slide.link.url}
            onClick={() => {
              event.send('autopromoClick', {
                autopromo: slide,
                path: router.asPath,
                type: 'slider',
                position
              });
            }}
            button
            label={slide.link.label}
          />
        )}
      </div>
    </div>
  );
};

const CampaignSlider = ({ slides }: CampaignSliderProps) => {
  const event = useEvent();
  const router = useRouter();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    event.send('autopromoImpr', {
      autopromo: slides,
      path: router.asPath,
      type: 'slider'
    });
  }, [slides, event, router]);
  return (
    <Slider
      className="sliderCampaign"
      type="primary"
      arrows={!isMobile}
      autoPlay={true}
      infinite
      showDots={isMobile}
      renderDotsOutside={isMobile}
    >
      {slides.map((slide: Slide, index) => {
        const key = `slide-${index}`;
        return <CampaignSlide key={key} slide={slide} position={index} />;
      })}
    </Slider>
  );
};

export default CampaignSlider;
