import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { useSelector } from 'react-redux';
import { CommonStateType } from '@/store/common/commonReducer';
import MyAccount from '@/assets/images/sprites/myAccount.svg';
import Link from '@/components/atoms/link';

type SearchLayerProps = {
  id?: string;
  deleteHistory: (id: number) => void;
  cleanHistory: () => void;
};

const SearchHistoryModal = ({
  id,
  deleteHistory,
  cleanHistory
}: SearchLayerProps) => {
  const searchHistory = useSelector(
    ({ common }: { common: CommonStateType }) => common.searchHistory
  );

  return (
    <div className="search-modal__results__history" id={id}>
      {!!searchHistory.length && (
        <div className="search-modal__results__history-header">
          <span className="search-modal__results__history-header-title">
            {t('search.history.title')}
          </span>
          <span
            className="search-modal__results__history-header-delete search-modal__results__history-action"
            onClick={cleanHistory}
          >
            {t('search.history.deleteAll')}
          </span>
        </div>
      )}
      {searchHistory.map((history, index) => {
        const key = `${history}_${index}`;
        return (
          <div key={key} className="search-modal__results__history-line">
            <Link
              href={`/search/${encodeURIComponent(history)}`}
              className="search-modal__results__history-link"
            >
              <div>
                <svg
                  className="search-modal__results__history-clock"
                  width="20"
                  height="20"
                >
                  <use xlinkHref={`${MyAccount}#clock`} />
                </svg>
              </div>
              <div>{history}</div>
            </Link>
            <svg
              className="search-modal__results__history-action"
              width="16"
              height="16"
              onClick={() => deleteHistory(index)}
            >
              <use xlinkHref={`${CommonSprite}#trash`} />
            </svg>
          </div>
        );
      })}
    </div>
  );
};

export default SearchHistoryModal;
