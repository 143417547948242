import React from 'react';
import Tile from '@/components/atoms/tile';
import Paragraph from '@/components/atoms/paragraph';
import CgvFaqSprite from '@/assets/images/sprites/sav.svg';
import './style.scss';

export type SavTilesGridProps = {
  themes: Array<ThemesProps>;
};

export type ThemesProps = {
  nom?: string;
  nomPictogramme?: string;
  TypeDeDemande?: Array<DemandesProps>;
  sousTheme?: Array<SousThemeProps>;
  url?: string;
  type?: string;
};

export type DemandesProps = {
  nom?: string;
  Demande?: Array<DemandeProps>;
};

export type DemandeProps = {
  nom?: string;
  type?: number;
};

export type SousThemeProps = {
  nom?: string;
  type?: string;
  nomPictogramme?: string;
  TypeDeDemande?: Array<DemandesProps>;
  url?: string;
};

// TO DO
// ask service to withdraw special char like é in services names.
// in api /consommateur/v1/consommateurs/formulaires_contact
// next we can drop code .replace(/é/g, 'e')}`,

const SavTilesGrid = ({ themes }: SavTilesGridProps) => (
  <div className="savTilesGrid__grid">
    {themes.map((theme: ThemesProps) => {
      let href;
      if (theme.type === 'url') {
        href = theme.url;
      } else {
        href = theme.sousTheme
          ? `/help-and-contact/vas/mail/${(theme.nom || '').toLowerCase()}`
          : `/help-and-contact/vas/${theme.nom
              ?.trim()
              .replace(/ /g, '-')
              .replace(/é/g, 'e')}`;
      }
      return (
        <Tile
          className="savTilesGrid__tile"
          imageContent={
            <svg width="40" height="40" className="savTilesGrid__tile__svg">
              <use xlinkHref={`${CgvFaqSprite}#${theme.nomPictogramme}`} />
            </svg>
          }
          textContent={
            <Paragraph label={theme.nom} className="savTilesGrid__tile__text" />
          }
          key={`key-${theme.nom}`}
          linkAttributes={{
            href,
            className: 'savTilesGrid__tile__link'
          }}
        />
      );
    })}
  </div>
);

export default SavTilesGrid;
