import React from 'react';
import ProductApi from '@/lib/api/product';
import ProductModel from '@/lib/model/product';
import { Request, Response } from 'express';
import usePdv from '@/lib/hooks/usePdv';
import { Provider } from 'react-redux';
import { store } from '@/store';
import ProductSlider, { ProductSliderProps } from '../productSlider';

type InitialProps = {
  props: ProductSliderProps;
  req: Request;
  res: Response;
};

const ProductSliderESI = (props: ProductSliderProps) => {
  return (
    <Provider store={store}>
      <ProductSlider {...props} />;
    </Provider>
  );
};

ProductSliderESI.getInitialProps = async ({
  props,
  req,
  res
}: InitialProps) => {
  const { ref } = usePdv(req);

  let products: Array<ProductModel> = [];

  if (ref && props.shopId) {
    products = await ProductApi.suggestionForShop(ref, props.shopId, 16);
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=300, max-age=300');
    }

    resolve({
      ...props,
      products
    });
  });
};

export default ProductSliderESI;
