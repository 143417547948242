import React, { useCallback } from 'react';
import { SubCartType, StateType } from '@/store/cart/types';
import Dropdown from '@/components/atoms/dropdown';
import CartSprite from '@/assets/images/sprites/cart.svg';
import { useDispatch } from 'react-redux';
import t from '@/lib/i18n';
import { deleteCart } from '@/store/cart/actions/cart';
import './style.scss';
import useEvent from '@/lib/hooks/useEvent';
import { useRouter } from 'next/router';

export type DeliveryHeaderProps = {
  subCart?: SubCartType;
  cart: StateType;
};

const DeliveryHeader = ({ subCart, cart }: DeliveryHeaderProps) => {
  if (!subCart) return null;
  const dispatch = useDispatch();
  const event = useEvent();
  const router = useRouter();
  const isItmCart = subCart.seller.sellerId === 'ITM';

  const totalSteps = cart.subCarts.length;

  const step = cart.subCarts.indexOf(subCart) + 1;

  const getBasketTrad = useCallback(() => {
    if (isItmCart)
      return t(
        subCart && subCart?.qty > 1
          ? 'cart.info.products'
          : 'cart.info.product',
        { '%number%': subCart.qty }
      );

    return totalSteps > 1
      ? `${t('common.cart')} : ${step} / ${totalSteps}`
      : '';
  }, [isItmCart, step, subCart, totalSteps]);
  return (
    <>
      <h1 className="cart__title">
        {isItmCart ? t('cart.delivery.title') : t('cart.deliveryPartner.title')}
      </h1>
      {!isItmCart && (
        <p className="partner__description">{t('cart.deliveryPartner.text')}</p>
      )}
      <div className="cart__heading">
        {!isItmCart && (
          <div className="cart__info__textRight">
            <Dropdown
              list={[
                {
                  label: t('cart.delete'),
                  id: 'delete',
                  action: async () => {
                    const products = Object.values(subCart?.items || {})?.map(
                      (item) => item
                    );

                    event.send('deleteCart', {
                      type: 'single',
                      sellerName: subCart.seller.sellerName,
                      products,
                      route: router.asPath
                    });
                    await dispatch(deleteCart(subCart.seller.sellerId));
                  }
                }
              ]}
              className="cart__more"
              size="140px"
            >
              <svg
                className="cart__more"
                width={20}
                height={20}
                onClick={() => {
                  event.send('checkout', {
                    type: 'openChoiceDeleteCart',
                    subCartType: 'MKP'
                  });
                }}
              >
                <use xlinkHref={`${CartSprite}#more`} />
              </svg>
            </Dropdown>
          </div>
        )}
        <div className="cart__infos">
          <div className="cart__infos__infoCart">
            <strong className="cart__infos__title">
              {t('cart.info.any', {
                '%sellerName%': subCart.seller.sellerName
              })}
            </strong>
            <span className="cart__infos__price">
              {t('cart.info.price', {
                '%price%': String(subCart.total.toFixed(2)).replace('.', ',')
              })}
            </span>
          </div>
          <span className="cart__infos__products">{getBasketTrad()}</span>
        </div>
        <div className="cart__info__textRight">
          {!isItmCart && (
            <span>
              {t(
                subCart && subCart?.qty > 1
                  ? 'cart.info.products'
                  : 'cart.info.product',
                { '%number%': subCart.qty }
              )}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default DeliveryHeader;
