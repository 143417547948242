import { NormalizeParams } from '..';

const normalize = ({ data, includes }: NormalizeParams) => {
  const image = includes[data.image.sys.id] || null;

  return {
    type: 'molecules',
    name: 'pdvMessage',
    esi: {
      enabled: true,
      id: 'bannerInfoStore'
    },
    props: {
      title: data.title,
      image: image.fields.file.url
    }
  };
};

export default normalize;
