import { Slide } from '@/components/templates/homepage/campaignSlider';
import date from '@/lib/utils/date';
import { normalize as BaseNormalize, NormalizeParams } from '..';

const normalize = ({ data, includes }: NormalizeParams): Slide | null => {
  const slide: Slide = {
    campaign: data.CFName || null,
    title: data.titre || null,
    shortTitle: data.shortTitle || null,
    description: data.description || null,
    image: '',
    imageSmall: '',
    dates: null,
    link: null,
    badge: data.badge || null
  };

  const image = includes[data.image.sys.id] || null;
  const imageSmall = includes[data.imageSmall?.sys?.id] || null;

  if (!image) {
    return null;
  }

  slide.image = image.fields.file.url;
  slide.imageSmall = imageSmall?.fields?.file?.url
    ? imageSmall?.fields?.file?.url
    : '';

  if (['image/jpg', 'image/jpeg'].includes(image.fields.file.contentType)) {
    slide.image += '?fm=jpg&fl=progressive';
  }

  if (imageSmall) {
    if (
      ['image/jpg', 'image/jpeg'].includes(imageSmall.fields?.file?.contentType)
    ) {
      slide.imageSmall += '?fm=jpg&fl=progressive';
    }
  }

  if (data.datesCampagne) {
    const datesCampagneData = includes[data.datesCampagne.sys.id] || null;
    if (datesCampagneData) {
      slide.dates = BaseNormalize({ data: datesCampagneData, includes });
    }
  }

  if (data.datesPublication) {
    const datesPublicationData = includes[data.datesPublication.sys.id] || null;
    if (datesPublicationData) {
      const publishedDates = BaseNormalize({
        data: datesPublicationData,
        includes
      });
      const now = date();

      if (
        date(publishedDates['begin']) > now ||
        date(publishedDates['end']) < now
      ) {
        return null;
      }
    }
  }

  if (data.cta) {
    const ctaData = includes[data.cta.sys.id] || null;

    if (ctaData.fields.href && ctaData.fields.text) {
      slide.link = {
        label: ctaData.fields.text || '',
        url: ctaData.fields.href || ''
      };
    } else if (ctaData.fields.noPdv) {
      const link = includes[ctaData.fields.noPdv.sys.id];

      slide.link = {
        label: link.fields.text || '',
        url: link.fields.href || ''
      };
    }
  }
  return slide;
};

export default normalize;
