import { Content, normalize as BaseNormalize, NormalizeParams } from '..';

const normalize = ({ data, includes }: NormalizeParams): ServiceList => {
  const components: any[] = [];
  data.slist?.forEach((content: any) => {
    const itemData = includes[content.sys.id] || null;
    const element = BaseNormalize({ data: itemData, includes });
    if (element) {
      components.push(element);
    }
  });

  return {
    components
  };
};

export default normalize;

type ServiceList = Content;
