import { Component } from 'react';
import { ServiceProps } from '@/components/molecules/service';
import {
  NormalizeParams,
  normalize as BaseNormalize,
  Component as ComponentType
} from '..';

const normalize = ({ data, includes, extras }: NormalizeParams): Service => {
  const getProspectusBlock = includes[data.blocProspectus.sys.id].fields;
  const getProspectusImageId = getProspectusBlock.content[0].sys.id;
  const getProspectusImageUrl =
    includes[getProspectusImageId].fields.image.sys.id;

  const storeBlockCurrently = includes[data.blocEnCeMoment?.sys?.id];
  const storeCurrently = BaseNormalize({
    data: storeBlockCurrently,
    includes,
    extras
  });

  const storeProspectus = {
    title: getProspectusBlock.title,
    subTitle: getProspectusBlock.titleSecondary,
    cta: includes[getProspectusBlock.cta.sys.id].fields,
    image: includes[getProspectusImageUrl].fields.file
  };

  const storeMagasin = {
    hyper: {
      image: includes[data.backgroundHeadingHyper.sys.id].fields,
      content: data.descriptionVocationHyper
    },
    super: {
      image: includes[data.backgroundHeadingSuperAlimentaire.sys.id].fields,
      content: data.descriptionVocationSuperAlimentaire
    },
    express: {
      image: includes[data.backgroundHeadingExpress.sys.id].fields,
      content: data.descriptionVocationExpress
    },
    contact: {
      image: includes[data.backgroundHeadingContact.sys.id].fields,
      content: data.descriptionVocationContact
    }
  };
  return {
    type: 'molecules',
    name: 'magasin',
    props: {
      storeCurrently,
      storeMagasin,
      storeProspectus,
      className: `cmsStoreInfos`
    }
  };
};

export default normalize;

export interface ComponentServiceProps extends Component {
  props: ServiceProps;
}

type Service = {
  type: string;
  name: string;
  props: any;
};

type StoreProspectusProps = {
  title: string;
  subTitle: string;
  cta: string;
  image: string;
};

export type StoreMagasinProps = {
  hyper: {
    image: string;
    content: string;
  };
  super: {
    image: string;
    content: string;
  };
  express: {
    image: string;
    content: string;
  };
  contact: {
    image: string;
    content: string;
  };
};

export type ContentfulStoreProps = {
  props: {
    storeCurrently: ComponentType;
    storeMagasin: StoreMagasinProps;
    storeProspectus: StoreProspectusProps;
  };
};
