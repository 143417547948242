import React from 'react';
import './style.scss';
import { withESI, ESIOptions } from '@/lib/esi';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Link from '@/components/atoms/link';
import useEvent from '@/lib/hooks/useEvent';
import Content from './contentESI';

export type StoreInfoProps = {
  className?: string;
};

const StoreInfo = ({ className }: StoreInfoProps) => {
  const event = useEvent();
  const ContentESI = withESI(Content, {
    id: 'header-store-info-content',
    type: 'organisms',
    name: 'header/storeInfo/content'
  });

  return (
    <div className={classnames('store', className)}>
      <Link
        id="logo-navbar"
        href="/home"
        className="store__logo"
        title="Intermarché"
        onClick={() => event.send('header', { type: 'home' })}
      >
        <svg height="28" width="140" className="fill--white">
          <use xlinkHref={`${CommonSprite}#logo`} />
        </svg>
      </Link>
      <ContentESI esi={ESIOptions()} />
    </div>
  );
};

export default StoreInfo;
