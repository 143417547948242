import React from 'react';
import ScoreBar from '@/components/atoms/scoreBar';
import SmartConsoSprite from '@/assets/images/sprites/smartconso.svg';
import classnames from 'classnames';
import './style.scss';
import ScoreBadge from '@/components/atoms/scoreBadge';
import Additives from '@/components/atoms/scoreAdditives';
import Scorelabels from '@/components/atoms/scoreLabels';
import Link from '@/components/atoms/link';
import getConfigValue from '@/lib/config';
import { SmartConso } from '@/lib/model/productTypes';
import CustomScoreLabels from '@/components/atoms/scoreLabels/custom';
import LabelsAvoid from '@/components/atoms/scoreLabels/avoid';
import { useMediaQuery } from 'react-responsive';
import QuestionMark from '@/components/atoms/scoreBar/questionMark';

export type NutritionProps = {
  score: SmartConso | undefined;
  getBtnNutritionRedirection: () => null;
  getBtnNutritionLabel: () => string;
  onClickEvent?: () => void;
};

const Nutritions = ({
  score,
  getBtnNutritionRedirection,
  getBtnNutritionLabel,
  onClickEvent
}: NutritionProps) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const IS_SMART_CONSO_LOT2 = getConfigValue(
    'IS_SMART_CONSO_LOT2',
    false
  ).toBoolean();
  return (
    <section
      id="nutrition"
      className="productDetail__section productDetail__composition"
    >
      <div className="container container--md scoreInitContainer">
        <div className="productDetail__content__section">
          <div className="nutritionSection">
            <div
              className={classnames('nutritionSection__heading', {
                nutritionSection__withDivider:
                  score?.isCustom && score.rating?.key === 'avoid'
              })}
            >
              <ScoreBadge {...score} />
              <div className="nutritionSection__logo">
                <svg width="110" height="20">
                  <use xlinkHref={`${SmartConsoSprite}#scoreInnitFull`} />
                </svg>
                {score?.isCustom && score.rating?.key === 'avoid' && (
                  <QuestionMark />
                )}
              </div>
            </div>
            {!(score?.isCustom && score.rating?.key === 'avoid') && (
              <ScoreBar {...score} />
            )}
            {score?.isCustom && !!score.avoidables?.length && (
              <LabelsAvoid flags={score.avoidables} />
            )}
            {isTabletOrMobile && (
              <div className="nutritionSection__content__item">
                {score?.additives && <Additives {...score} />}
              </div>
            )}
            <div className="nutritionSection__content">
              {!!score?.qualifications?.length && !score.isCustom && (
                <div className="nutritionSection__content__item">
                  <Scorelabels flags={score.qualifications} />
                </div>
              )}
              {!!score?.qualifications?.length && score.isCustom && (
                <div className="nutritionSection__content__item">
                  <CustomScoreLabels flags={score.qualifications} />
                </div>
              )}
              {!isTabletOrMobile && (
                <div className="nutritionSection__content__item">
                  {score?.additives && <Additives {...score} />}
                </div>
              )}
            </div>
          </div>
        </div>
        {IS_SMART_CONSO_LOT2 && (
          <div className="nutritionSection__content__btn">
            <Link
              className="btn__preferences"
              button={false}
              onClick={() => {
                onClickEvent && onClickEvent();
                getBtnNutritionRedirection();
              }}
              disabled
            >
              {getBtnNutritionLabel()}
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default Nutritions;
