import React, { useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Link from '@/components/atoms/link';
import { CartType } from '@/store/cart/types';
import useEvent from '@/lib/hooks/useEvent';
import t from '@/lib/i18n';
import PdvName from './pdvName';

export type StepperProps = {
  steps: Array<{
    label: string;
    value: number;
    href: string;
  }>;
  current: number;
};

const Stepper = ({ steps, current }: StepperProps) => {
  const event = useEvent();
  return (
    <div className="cartStepper">
      {steps.map((step, index) => {
        return (
          <a
            href={
              window.location.pathname !== '/orders/confirmation'
                ? step.href
                : undefined
            }
            onClick={(e) => {
              if (step.value >= current) {
                e.preventDefault();
              } else {
                event.send('checkout', { type: 'stepperClick', step });
              }
            }}
            key={step.label}
            className={classnames('cartStepper__step', {
              'cartStepper__step--current': step.value === current
            })}
          >
            <span
              className={classnames('cartStepper__step__number', {
                'cartStepper__step--validated': step.value < current
              })}
            >
              {step.value >= current ? (
                index + 1
              ) : (
                <svg width="15" className="fill--green">
                  <use xlinkHref={`${CommonSprite}#check`} />
                </svg>
              )}
            </span>
            <span
              className={classnames('cartStepperLabel', {
                'cartStepperLabel--current': step.value === current
              })}
            >
              {step.label}
            </span>
          </a>
        );
      })}
    </div>
  );
};

const getStep = (id: string, label: string, value: number, href: string) => ({
  id,
  label,
  value,
  href
});

const Header = ({ step, cart }: { step: string; cart?: CartType }) => {
  const [steps, setSteps] = useState<Array<{
    id: string;
    label: string;
    value: number;
    href: string;
  }> | null>(null);

  useEffect(() => {
    const isOnlyMkp = cart?.subCarts.every((subCart) => subCart.type === 'MKP');
    const isOneMkp = cart?.subCarts.some((subCart) => subCart.type === 'MKP');
    const stepsList = [getStep('cart', t('checkout.step1'), 1, '/orders/cart')];
    if (!isOnlyMkp) {
      stepsList.push(getStep('slot', t('checkout.step2'), 2, '/orders/slots'));
      if (
        (cart &&
          cart.slots.selectedSlot.slot?.providers &&
          cart.slots.selectedSlot.slot?.providers.length > 1) ||
        isOneMkp
      ) {
        stepsList.push(
          getStep('delivery', t('checkout.step3'), 3, '/orders/delivery'),
          getStep('payment', t('checkout.step4'), 4, '')
        );
      } else {
        stepsList.push(getStep('payment', t('checkout.step4'), 3, ''));
      }
    } else {
      stepsList.push(
        getStep('delivery', t('checkout.step3'), 2, '/orders/delivery'),
        getStep('payment', t('checkout.step4'), 3, '')
      );
    }
    setSteps(stepsList);
  }, [cart]);

  const current = steps?.find((s) => s.id === step)?.value;
  return (
    <div className="cartHeader">
      <div className="container cartHeader__content">
        <Link className="cartHeader__logo" href="/home" title="Intermarché">
          <svg height="28" width="140" className="fill--white">
            <use xlinkHref={`${CommonSprite}#logo`} />
          </svg>
          <PdvName />
        </Link>
        {current && steps && <Stepper steps={steps} current={current} />}
      </div>
    </div>
  );
};

export default Header;
