import React from 'react';

import { Address } from '@/lib/model/pdv';
import getConfigValue from '@/lib/config';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Link from '@/components/atoms/link';
import t from '@/lib/i18n';

const gmapKey = getConfigValue('GOOGLE_MAPS_KEY', '').toString();

type MapProp = {
  address: Address;
  name: string;
};

const Map = ({ address, name }: MapProp) => {
  const { address1, address2, zipCode, city, latitude, longitude } = address;

  const itineraryLink = `https://www.google.com/maps/dir//${encodeURIComponent(
    `${name} ${address1} ${address2} ${zipCode} ${city}`
  )}`;

  const center = {
    lat: latitude,
    lng: longitude
  };

  return (
    <LoadScript googleMapsApiKey={gmapKey}>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%'
        }}
        center={center}
        zoom={17}
        options={{
          fullscreenControl: true,
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          maxZoom: 17,
          disableDefaultUI: true,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off'
                }
              ]
            }
          ]
        }}
      >
        <Marker
          position={{
            lat: latitude,
            lng: longitude
          }}
        />
        <div className="placeCard">
          <div className="placeCard__content">
            <div>
              <div className="placeName">{`${t('common.name')} ${name}`}</div>
              <div className="address">
                {`${address1} ${address2} ${zipCode} ${city}`}
              </div>
            </div>
            <div className="link">
              <Link
                href={itineraryLink}
                target="_blank"
                label={`${t('pdv.openHours.itinerary')}`}
              />
            </div>
          </div>
        </div>
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
