import Slider from '@/components/molecules/slider';
import Product from '@/components/organisms/product';
import ErrorTemplate from '@/components/templates/error';
import getConfigValue from '@/lib/config';
import useEvent from '@/lib/hooks/useEvent';
import usePageView from '@/lib/hooks/usePageView';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import ProductModel from '@/lib/model/product';
import { Offer } from '@/lib/model/productTypes';
import { AuthStateType } from '@/store/auth/authReducer';

import ProductApi from '@/lib/api/product';
import logger from '@/lib/logger/base';
import {
  selectHasFilledMandatoriesQuestions,
  selectHasStartedSurvey,
  selectSurveyTranslationLabel,
  selectHasCompletedSurvey
} from '@/store/survey/surveySelectors';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CommonStateType } from '@/store/common/commonReducer';
import { setSelectedProduct } from '@/store/common/actions/common';
import Description from './description';
import Detail from './detail';
import IngredientsAllergens from './ingredientsAllergens';
import Labels from './labels';
import Nutritions from './nutrition';
import './style.scss';
import Tabs from './tabs';
import LegalLabels from './toKnowMore';

export type ProductDetailProps = {
  ean: string;
  data?: ProductModel;
  currentOffer?: Offer;
};

const ProductDetail = ({ data, currentOffer }: ProductDetailProps) => {
  const pageView = usePageView();
  const dispatch = useDispatch();
  const event = useEvent();
  const router = useRouter();
  const [offer, setOffer] = useState<Offer | undefined>(currentOffer);
  const IS_SMART_CONSO_LOT2 = getConfigValue(
    'IS_SMART_CONSO_LOT2',
    false
  ).toBoolean();
  const IS_EMERCH_ENABLED = getConfigValue(
    'IS_EMERCH_ENABLED',
    false
  ).toBoolean();
  const hasStartedSurvey = useSelector(selectHasStartedSurvey);
  const surveyTranslationLabel = useSelector(selectSurveyTranslationLabel);

  const hasFilledMandatoriesQuestions = useSelector(
    selectHasFilledMandatoriesQuestions
  );
  const hasCompletedSurvey = useSelector(selectHasCompletedSurvey);

  const { userId, selectedProduct, commonIsHydrated } = useSelector(
    ({ auth, common }: { auth: AuthStateType; common: CommonStateType }) => {
      return {
        userId: auth.user?.id,
        selectedProduct: common?.selectedProduct,
        commonIsHydrated: common.isHydrated
      };
    },
    shallowEqual
  );
  const [productData, setProductData] = useState<ProductModel | undefined>(
    data
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ref } = usePdv();
        if (data?.ean) {
          let tracking;
          if (selectedProduct?.ean === data?.ean) {
            tracking = selectedProduct.tracking;
          }
          const product = await ProductApi.findByEan(
            ref,
            data?.ean,
            true,
            tracking
          );
          setProductData(product);
        }
      } catch (error: any) {
        logger.error({
          message: 'Unable to get product',
          error,
          context: {
            ean: data?.ean
          }
        });
      }
    };
    if (!commonIsHydrated) {
      return;
    }
    if (IS_SMART_CONSO_LOT2 || IS_EMERCH_ENABLED) {
      fetchData();
    } else {
      setProductData(data);
    }
  }, [
    userId,
    data,
    IS_SMART_CONSO_LOT2,
    IS_EMERCH_ENABLED,
    selectedProduct?.ean,
    selectedProduct?.tracking,
    commonIsHydrated
  ]);

  useEffect(() => {
    if (selectedProduct && data?.ean !== selectedProduct.ean) {
      dispatch(setSelectedProduct());
    }
  }, []); // eslint-disable-line

  const getBtnNutritionRedirection = () => {
    if (!userId) {
      window.location.href =
        '/connection?redirect=/account-management/smart-conso';
    } else {
      window.location.href = '/account-management/smart-conso';
    }
    return null;
  };

  const [smartconsoFormStarted, setSmartconsoFormStarted] = useState(false);
  const [smartconsoStartLabel, setSmartconsoStartLabel] = useState(
    'smartconso.landing.button.start'
  );

  const isNotIdentified = !userId || !smartconsoFormStarted;
  const getBtnNutritionLabel = (): string => {
    if (isNotIdentified) return t('pdp.alternativeProducts.btn.formNotStarted');
    return hasCompletedSurvey
      ? t('pdp.alternativeProducts.btn.modifyPrefs')
      : t('pdp.alternativeProducts.btn.formStarted');
  };

  useEffect(() => {
    if (hasStartedSurvey) {
      setSmartconsoFormStarted(true);
      setSmartconsoStartLabel(surveyTranslationLabel);
    }
  }, [hasStartedSurvey, surveyTranslationLabel]);

  const onClickEventSmartconsoCta = () => {
    event.send('smartconso', {
      type: 'formAccessFromPdp',
      label: t(smartconsoStartLabel)
    });
  };

  useEffect(() => {
    pageView.send('pdp', { product: data });
  }, [pageView, data]);

  useEffect(() => {
    event.send('productDetail', {
      product: data,
      path: router.asPath,
      offer
    });
  }, [event, data, router, offer]);

  const [anchorLegalLabel, setAnchorLegalLabel] = useState<string | undefined>(
    undefined
  );

  const tabs = [];

  if (!productData) {
    return <ErrorTemplate statusCode={404} />;
  }

  const { informations, lot, repairIndex, energyClass } = productData;

  const {
    fabricant,
    emballage,
    ingredients,
    allergens,
    nutritionalValues,
    legalInformation,
    originPdp,
    originPlp,
    countryTransformation,
    variety,
    category,
    alcoholRate,
    caliber,
    chemicalTreatment,
    slaughterCountry,
    species,
    animalCountryOrigin,
    animalCountryGrowing,
    fishingArea,
    subFishingArea,
    methodeCapture,
    advantages,
    conservation,
    practicalInformation,
    extension,
    features
  } = informations;

  const getInnitScore = () => {
    if (hasFilledMandatoriesQuestions && userId) {
      return productData?.customScore;
    }
    return productData?.score;
  };

  const innitScore = getInnitScore();
  const withScoreInit = innitScore;

  const hasCaracteristic = Boolean(
    originPdp ||
      originPlp ||
      countryTransformation ||
      variety ||
      category ||
      alcoholRate ||
      caliber ||
      chemicalTreatment ||
      slaughterCountry ||
      species ||
      animalCountryOrigin ||
      animalCountryGrowing ||
      fishingArea ||
      subFishingArea ||
      methodeCapture
  );

  const withCaracteristics =
    features?.sections && Object.keys(features?.sections)?.length;

  const withDescription =
    hasCaracteristic ||
    advantages ||
    conservation ||
    practicalInformation ||
    legalInformation ||
    ingredients ||
    allergens ||
    !!extension ||
    withCaracteristics ||
    advantages ||
    conservation ||
    practicalInformation ||
    fabricant ||
    emballage;

  const withDescriptionTab =
    withDescription && lot?.type !== 'PICNIC' && lot?.type !== 'VIRTUEL';

  const withLabels = !!innitScore?.qualifications?.length;
  const withToKnowMore = !!repairIndex || !!energyClass;

  if (withDescriptionTab) {
    tabs.push({
      label: t('pdp.informations.description.tab_label'),
      anchor: 'description'
    });
  }

  if (withCaracteristics) {
    tabs.push({
      label: t('pdp.informations.details'),
      anchor: 'caracteristics'
    });
  }

  if (nutritionalValues) {
    tabs.push({
      label: t('pdp.informations.ingredients_allergens.title'),
      anchor: 'ingredients-allergens'
    });
  }

  if (productData?.alternativeProducts?.length) {
    tabs.push({
      label: t('pdp.informations.alternativeProducts.title'),
      anchor: 'alternativeProducts'
    });
  }

  if (withLabels) {
    tabs.push({
      label: t('pdp.informations.labels.title'),
      anchor: 'labels'
    });
  }

  if (withToKnowMore) {
    tabs.push({
      label: t('pdp.informations.toKnowMore.title'),
      anchor: 'toKnowMore'
    });
  }

  const firstSlid = !!productData?.alternativeProducts?.length && (
    <div className="productDetail__alternativeProducts__GoToSes">
      <div className="productDetail__alternativeProducts__GoToSes__text">
        {t('pdp.alternativeProducts.text')}
      </div>
      {IS_SMART_CONSO_LOT2 && (
        <div
          className="productDetail__alternativeProducts__GoToSes__btn"
          onClick={() => {
            onClickEventSmartconsoCta();
            getBtnNutritionRedirection();
          }}
        >
          {getBtnNutritionLabel()}
        </div>
      )}
    </div>
  );

  return (
    <div className="productDetail">
      <Detail
        data={productData}
        innitScore={innitScore}
        currentOffer={offer}
        anchorLabel={(label: string) => setAnchorLegalLabel(label)}
      />
      {!!tabs.length && (
        <div className="productDetail__tabs_container">
          <div className="container">
            <Tabs className="productDetail__tabs" tabs={tabs} />
          </div>
        </div>
      )}
      {withDescription && (
        <Description
          informations={informations}
          currentOffer={offer}
          setOffer={setOffer}
          product={data}
          hasCaracteristic={hasCaracteristic}
          afterTitle={
            withScoreInit && (
              <Nutritions
                score={innitScore}
                getBtnNutritionRedirection={getBtnNutritionRedirection}
                getBtnNutritionLabel={getBtnNutritionLabel}
                onClickEvent={onClickEventSmartconsoCta}
              />
            )
          }
        />
      )}
      {nutritionalValues && (
        <IngredientsAllergens informations={informations} />
      )}
      {!!productData?.alternativeProducts?.length && (
        <div
          className="productDetail__alternativeProducts container"
          id="alternativeProducts"
        >
          <div className="productDetail__alternativeProducts__title">
            {t('pdp.alternativeProducts.title')}
          </div>
          <Slider
            className="productSlider__products"
            slidesToSlide={4}
            renderDotsOutside={true}
            type="secondary"
            arrows={true}
            firstSlid={firstSlid}
          >
            {productData?.alternativeProducts?.map((product, index) => {
              const key = `slider-product-${index}`;
              return <Product key={key} data={product} listPosition={index} />;
            })}
          </Slider>
        </div>
      )}
      {withLabels && <Labels score={innitScore} />}
      {withToKnowMore && (
        <LegalLabels
          repairIndex={repairIndex}
          energyClass={energyClass}
          anchorLegalLabel={anchorLegalLabel}
        />
      )}
    </div>
  );
};

export default ProductDetail;
