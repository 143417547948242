import React, { memo, useEffect, useState } from 'react';
import axios from 'axios';
import Lottie from 'react-lottie';

export type BodyMovinProps = {
  jsonUrl: string;
};

const BodyMovin = ({ jsonUrl }: BodyMovinProps) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(jsonUrl);
        setAnimationData(response?.data);
      } catch (error: any) {
        // Todo: handle user error display if unable to create animation
      }
    })();
  }, [jsonUrl]);

  if (!animationData) {
    return null;
  }
  return (
    <div className="bodyMovin">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData
        }}
        height={300}
        width={300}
      />
    </div>
  );
};

export default memo(BodyMovin);
