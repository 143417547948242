import React, { Key, useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import Image from '@/components/atoms/image';
import Link, { LinkProps } from '@/components/atoms/link';
import ChooseStore, {
  ChooseStoreProps
} from '@/components/organisms/chooseStore';
import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import { useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import dayjs from 'dayjs';
import { useIsomorphicLayoutEffect } from 'react-use';
import usePdv from '@/lib/hooks/usePdv';
import { VIRTUAL_PDV_REF } from '@/lib/utils/pdv';

enum EShopConditionalType {
  NONE = 'Default',
  IS_E_SHOP = 'PDV de référence type e-commerce',
  IS_NOT_E_SHOP = 'PDV de référence type non e-commerce'
}

export type BlockProps = {
  type: string;
  title?: string | null;
  titleSecondary?: string | null;
  description?: string | null;
  content?: ContentProps | null;
  background?: BackgroundProps | null;
  horizontalAlign?: string | null;
  verticalAlign?: string | null;
  minHeight?: string | null;
  maxHeight?: string | null;
  maxWidth?: string | null;
  cta?: CtaProps | null;
  conditionedCta?: ConditionedCtaType | null;
  ctaComponent?: Component;
  cmsComponents?: Array<Component>;
  loginRequired?: boolean;
  startDateTime?: string | null;
  endDateTime?: string | null;
  eShopConditional?: EShopConditionalType | null;
};

export type ConditionedCtaType = {
  pdvNoDrive?: CtaProps | null;
  pdvDrive?: CtaProps | null;
  noPdv?: CtaProps | null;
};

export type BackgroundProps = {
  repeat?: boolean | null;
  size?: string | null;
  color?: string | null;
  image?: string | null;
  position?: string | null;
};

export type ContentProps = {
  type: string;
  images: Array<ContentImageProps>;
};

export type ContentImageProps = {
  title?: string | null;
  titleSecondary?: string | null;
  url: string;
  width?: string | null;
  maxHeight?: string | null;
  link?: LinkProps | null;
};

export type CtaProps = {
  type: string;
  props: LinkProps | ChooseStoreProps;
};

const getBackgroundStyle = (
  background?: BackgroundProps | null,
  minHeight?: string | null,
  maxHeight?: string | number | null
) => {
  let image = {};
  const common = {
    minHeight,
    maxHeight
  };

  if (background) {
    image = {
      backgroundImage: background.image ? `url(${background.image})` : null,
      backgroundColor: background.color ? `${background.color}` : null,
      backgroundRepeat:
        background.repeat && background.repeat ? 'repeat' : 'no-repeat',
      backgroundSize: background.size || null,
      backgroundPosition: background.position || null,
      color: background.color === '#000000' ? '#FFF' : null
    };
  }

  const style: { [key: string]: string | number | undefined | null } = {
    ...common,
    ...image
  };

  Object.keys(style).forEach(
    (key: string) => style[key] == null && delete style[key]
  );

  return style;
};

interface PdvState {
  isEcommerce: boolean;
  ref: string;
  isHidden: boolean;
}

const Block = ({
  type,
  title,
  titleSecondary,
  description,
  content,
  background,
  minHeight,
  maxHeight,
  horizontalAlign,
  verticalAlign,
  cta,
  conditionedCta,
  ctaComponent,
  cmsComponents,
  eShopConditional = EShopConditionalType.NONE,
  loginRequired = false,
  startDateTime = null,
  endDateTime = null
}: BlockProps) => {
  let contentElement = null;
  let ctaElement = null;
  const [isActive, setIsActive] = useState(!loginRequired);
  const [isPeriod, setIsPeriod] = useState(true);

  const user = useSelector(({ auth }: { auth: AuthStateType }) => auth.user);
  const virtualPdvRef = VIRTUAL_PDV_REF;

  const [pdv, setPdv] = useState<PdvState>({
    isEcommerce: false,
    ref: '',
    isHidden: false
  });
  useIsomorphicLayoutEffect(() => {
    const { ref, isEcommerce } = usePdv();
    setPdv({
      ...pdv,
      isEcommerce,
      ref,
      isHidden:
        (isEcommerce &&
          eShopConditional === EShopConditionalType.IS_NOT_E_SHOP) ||
        (!isEcommerce && eShopConditional === EShopConditionalType.IS_E_SHOP)
    });
  }, [eShopConditional]);

  useEffect(() => {
    if (loginRequired) {
      setIsActive(!!user);
    }
  }, [loginRequired, user]);

  useEffect(() => {
    const startDate = startDateTime && dayjs(startDateTime);
    const endDate = endDateTime && dayjs(endDateTime);
    const today = dayjs();
    const diffEndDate = endDate && endDate.diff(today, 'days');
    const diffStartDate = startDate && startDate.diff(today, 'days');

    if (
      (diffStartDate && diffStartDate >= 0) ||
      (diffEndDate && diffEndDate < 0)
    ) {
      setIsPeriod(false);
    }
    if (diffStartDate && diffStartDate <= 0 && diffEndDate && diffEndDate > 0) {
      setIsPeriod(true);
    }
  }, [setIsPeriod, startDateTime, endDateTime]);

  if (pdv.isHidden) {
    return null;
  }

  if (content) {
    contentElement = (
      <div
        className={classnames(
          'block__content',
          `block__content--${content.type}`
        )}
      >
        {content.images.map((image: ContentImageProps, index: Key) => {
          const key = `content-image-${index}`;
          if (image.link && image.url) {
            return (
              <Link {...image.link} key={key}>
                <>
                  <Image src={image.url} alt="" height={image.maxHeight} />
                  {image.title && (
                    <div
                      className="block__image_heading_title"
                      dangerouslySetInnerHTML={{
                        __html: image.title
                      }}
                    />
                  )}
                </>
              </Link>
            );
          }

          let style = {};

          if (content.type === 'keyNumber' && image.url) {
            style = {
              ...style,
              backgroundImage: `url(${image.url})`
            };
          }

          return (
            <div className="block__image" key={key} style={style}>
              {(image.title || image.titleSecondary) && (
                <div className="block__image_heading">
                  {image.title && (
                    <div
                      className="block__image_heading_title"
                      dangerouslySetInnerHTML={{
                        __html: image.title
                      }}
                    />
                  )}
                  {image.titleSecondary && (
                    <div
                      className="block__image_heading_sub_title"
                      dangerouslySetInnerHTML={{
                        __html: image.titleSecondary
                      }}
                    />
                  )}
                </div>
              )}
              {image.url && content.type !== 'keyNumber' && (
                <Image
                  src={image.url}
                  alt=""
                  height={image.maxHeight}
                  width={image.width}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  if (cta || ctaComponent || conditionedCta) {
    const ctaLinkProps = cta?.props as LinkProps;
    const ctaChooseStoreProps = cta?.props as ChooseStoreProps;
    const noPdvLinkProps = conditionedCta?.noPdv?.props as LinkProps;
    const pdvDriveLinkProps = conditionedCta?.pdvDrive?.props as LinkProps;
    const pdvNoDriveLinkProps = conditionedCta?.pdvNoDrive?.props as LinkProps;
    if (cta || ctaComponent) {
      ctaElement = (
        <div className="block__cta">
          {cta?.type === 'link' && <Link {...ctaLinkProps} />}
          {cta?.type === 'chooseStore' && (
            <ChooseStore {...ctaChooseStoreProps} />
          )}
          {ctaComponent && (
            <ContentfulRenderComponents components={[ctaComponent]} />
          )}
        </div>
      );
    } else if (conditionedCta) {
      if (conditionedCta.noPdv && pdv.ref.toString() === virtualPdvRef) {
        ctaElement = (
          <div className="block__cta">
            {conditionedCta.noPdv?.type === 'link' && (
              <Link {...noPdvLinkProps} />
            )}
          </div>
        );
      } else if (pdv.ref.toString() !== virtualPdvRef && pdv.isEcommerce) {
        ctaElement = (
          <div className="block__cta">
            {conditionedCta.pdvDrive?.type === 'link' && (
              <Link {...pdvDriveLinkProps} />
            )}
          </div>
        );
      } else if (pdv.ref.toString() !== virtualPdvRef && !pdv.isEcommerce) {
        ctaElement = (
          <div className="block__cta">
            {conditionedCta.pdvNoDrive?.type === 'link' && (
              <Link {...pdvNoDriveLinkProps} />
            )}
          </div>
        );
      }
    }
  }

  return (
    isActive && (
      <>
        {isPeriod && (
          <div
            className={classnames('block', `block--${type}`, {
              [`block--horizontal-align-${horizontalAlign}`]: horizontalAlign,
              [`block--vertical-align-${verticalAlign}`]: verticalAlign
            })}
            style={getBackgroundStyle(background, minHeight, maxHeight)}
          >
            <div className="container">
              {type === 'right' && contentElement}
              <div className="block__heading">
                {title && (
                  <h2
                    className="block__title"
                    dangerouslySetInnerHTML={{
                      __html: title
                    }}
                  />
                )}
                {titleSecondary && (
                  <h3
                    className="block__sub_title"
                    dangerouslySetInnerHTML={{
                      __html: titleSecondary
                    }}
                  />
                )}
                {description && (
                  <div
                    className="block__description"
                    dangerouslySetInnerHTML={{
                      __html: description
                    }}
                  />
                )}
                {cmsComponents && (
                  <ContentfulRenderComponents components={cmsComponents} />
                )}
                {type !== 'basic' &&
                  (cta || ctaComponent || conditionedCta) &&
                  ctaElement}
              </div>
              {type === 'left' && contentElement}

              {type === 'basic' && contentElement}
              {type === 'basic' &&
                (cta || ctaComponent || conditionedCta) &&
                ctaElement}
            </div>
          </div>
        )}
      </>
    )
  );
};

export default Block;
