import React, { useEffect, useState, useMemo, MouseEvent, useRef } from 'react';
import './style.scss';
import classnames from 'classnames';
import t from '@/lib/i18n';
import { BreadcrumbItem } from '@/components/molecules/breadcrumb';
import CommonSprite from '@/assets/images/sprites/common.svg';
import CategoryModel from '@/lib/model/category';
import NavPromotion from '@/lib/model/navPromotion';
import NavigationListCategories from '@/components/molecules/navigationCategoriesList';
import last from 'lodash/last';
import CategoryHasAlcoholBanner from './CategoryHasAlcoholBanner';
import ListNavPromotions from './ListNavPromotions';

type CategoriesDisplayProps = {
  tree: Array<CategoryModel>;
  promotions: Array<NavPromotion>;
  active: boolean;
  toggleActive: () => void;
};

type DataType = {
  breadcrumbItems: Array<BreadcrumbItem>;
  hasAlcohol?: boolean | null;
  selectedCategoryLevel1: CategoryModel | null;
  selectedCategoryLevel2: CategoryModel | null;
};

const CategoriesDisplay = React.memo(
  ({
    tree = [],
    promotions = [],
    active = false,
    toggleActive
  }: CategoriesDisplayProps) => {
    const treeWithoutPromotions = tree.filter(({ id }) => id !== 'promo');
    const treeWithPromotionsMaybe =
      promotions.length > 0 ? [...tree] : [...treeWithoutPromotions];
    const allCategories = useMemo(() => {
      return {
        label: t('header.categories.categories'),
        link: '/shelves',
        onClick: (event: MouseEvent<any>) => {
          event.preventDefault();
          setData({
            breadcrumbItems: [allCategories],
            selectedCategoryLevel1: null,
            selectedCategoryLevel2: null
          });
        }
      };
    }, []);

    const defaultData = useMemo(
      () => ({
        breadcrumbItems: [allCategories],
        hasAlcohol: false,
        selectedCategoryLevel1: null,
        selectedCategoryLevel2: null
      }),
      [allCategories]
    );

    const [data, setData] = useState<DataType>(defaultData);

    const categoriesDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!active) {
        setData(defaultData);
        if (categoriesDivRef.current) categoriesDivRef.current.scrollTop = 0;
      }
    }, [active, defaultData]);

    const goto = (event: MouseEvent<any>, category: CategoryModel) => {
      if (category.children.length === 0 && !category.isPromo) {
        return toggleActive();
      }
      event.preventDefault();
      const newData: DataType = {
        ...data,
        breadcrumbItems: [...data.breadcrumbItems, { label: category.title }],
        hasAlcohol: category.hasAlcohol
      };
      if (category.level === 1) {
        newData.selectedCategoryLevel1 = category;
        newData.selectedCategoryLevel2 = null;
      }
      if (category.level === 2) {
        newData.selectedCategoryLevel2 = category;
      }
      return setData(newData);
    };

    return (
      <nav
        className="categories__navigation"
        onClick={(event: MouseEvent) => {
          if (!event) return;
          const clickedElement = event.target as HTMLElement;
          const isOverlayClicked = clickedElement?.classList.contains(
            'categories__columns'
          );
          if (isOverlayClicked) {
            toggleActive();
          }
        }}
        role="presentation"
      >
        <header className="categories__header">
          {data.selectedCategoryLevel1 ? (
            <button
              className="categories__backButton"
              onClick={() => setData(defaultData)}
            >
              <svg width="16px" height="16px">
                <use xlinkHref={`${CommonSprite}#arrow-left`} />
              </svg>
            </button>
          ) : (
            <span className="categories__backButton categories__backButton--placeholder" />
          )}
          <div className="categories__title">
            {last(data.breadcrumbItems)?.label}
          </div>
          <button
            className="categories__closeButton"
            onClick={() => {
              toggleActive();
            }}
          >
            <svg width="16px" height="16px">
              <use xlinkHref={`${CommonSprite}#cross-bold`} />
            </svg>
          </button>
        </header>
        <div className="categories__columns">
          {/* Column 1 */}
          <div
            ref={categoriesDivRef}
            id="category_column_1"
            className={classnames(
              ['categories__column categories__column--level1'],
              { 'categories__column--isActive': !data.selectedCategoryLevel1 }
            )}
          >
            <NavigationListCategories
              data={treeWithPromotionsMaybe}
              goto={goto}
              selectedCategory={data.selectedCategoryLevel1}
            />
          </div>
          {/* Column 2 */}
          {data.selectedCategoryLevel1?.children && (
            <div
              className="categories__column categories__column--level2"
              id="category_column_2"
            >
              {data.selectedCategoryLevel1?.id === 'promo' ? (
                <ListNavPromotions navPromotions={promotions} />
              ) : (
                <>
                  <NavigationListCategories
                    data={data.selectedCategoryLevel1?.children}
                    goto={goto}
                    selectedCategory={data.selectedCategoryLevel2}
                    parentCategory={data.selectedCategoryLevel1}
                  />
                  <CategoryHasAlcoholBanner
                    category={data.selectedCategoryLevel1}
                    className="NavigationCategoriesListBanner"
                  />
                </>
              )}
            </div>
          )}
          {/* Column 3 */}
          {data.selectedCategoryLevel2?.children && (
            <div
              className="categories__column categories__column--level3"
              id="category_column_3"
            >
              <NavigationListCategories
                data={data.selectedCategoryLevel2?.children.filter(
                  ({ slug }) => slug !== 'voir-tout'
                )}
                goto={goto}
                viewAllEnabled={true}
                parentCategory={data.selectedCategoryLevel2}
              />
            </div>
          )}
        </div>
      </nav>
    );
  }
);

export default CategoriesDisplay;
