import React from 'react';
import classnames from 'classnames';
import './style.scss';

export type TitleProps = {
  label?: string;
  children?: JSX.Element;
  tag: keyof JSX.IntrinsicElements;
  className?: string;
};

const isHtmlCmsTitle = /^</;

const Title = ({ label, tag: Tag = 'h1', children, className }: TitleProps) => {
  return !label?.match(isHtmlCmsTitle)?.length ? (
    <Tag className={classnames(`title__${Tag}`, className)}>
      {label || children}
    </Tag>
  ) : (
    <div
      className={classnames(`title__${Tag}`, className)}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  );
};

export default Title;
