import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import OrderApi from '@/lib/api/order';
import { Order as OrderType } from '@/lib/api/order/types';
import LoadableContent from '@/components/atoms/loadableContent';
import OrderStepper from '@/components/organisms/myAccount/orderStepper';
import Link from '@/components/atoms/link';

type StateType = {
  isLoading: boolean;
  order: OrderType | null;
};

const Order = () => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [state, setState] = useState<StateType>({
    isLoading: true,
    order: null
  });

  useEffect(() => {
    (async () => {
      if (!userId) return;

      let orders: Array<OrderType>;

      try {
        orders = await OrderApi.list(userId, {
          inProgress: true,
          passed: false,
          count: 10
        });
      } catch (error: any) {
        orders = [];
      }

      orders.sort((a, b) => {
        return a.createdAt > b.createdAt ? -1 : 1;
      });

      setState((oldState) => ({
        ...oldState,
        order: orders.shift() ?? null,
        isLoading: false
      }));
    })();
  }, [userId]);

  return (
    <LoadableContent
      loading={state.isLoading}
      className={classnames(
        'myAccountDashboard__container',
        'myAccountDashboard__order',
        {
          'myAccountDashboard__order--empty': !state.order
        }
      )}
    >
      <>
        {!state.order && (
          <>
            <div className="myAccountDashboard__order_title">
              {t('myAccount.dashboard.orders.empty.title')}
            </div>
            <div className="myAccountDashboard__order_message">
              {t('myAccount.dashboard.orders.empty.message')}
            </div>
          </>
        )}
        {!!state.order && (
          <>
            <div className="myAccountDashboard__order_row">
              <div className="myAccountDashboard__order_info">
                <div className="myAccountDashboard__order_number">
                  {t('myAccount.dashboard.order.number', {
                    '%id%': state.order.id
                  })}
                </div>
                <div className="myAccountDashboard__order_createdAt">
                  {t('myAccount.dashboard.order.createdAt', {
                    '%date%': state.order.createdAt.format('DD/MM/YYYY')
                  })}
                </div>
                {state.order.deliveryType !== 'delivery' && (
                  <div className="myAccountDashboard__order_withdrawalAt">
                    {t('myAccount.dashboard.order.drive')} -{' '}
                    {t('myAccount.dashboard.order.withdrawalAt', {
                      '%date%': state.order.slot.begin.format('DD/MM/YYYY'),
                      '%hours%': state.order.slot.begin.format('HH'),
                      '%minutes%': state.order.slot.begin.format('mm')
                    })}
                  </div>
                )}
              </div>
              <div className="myAccountDashboard__order_price">
                {state.order.total.value
                  .toFixed(2)
                  .toString()
                  .replace('.', ',')}
                &euro;
              </div>
            </div>
            <OrderStepper
              className="myAccountDashboard__order_stepper"
              order={state.order}
            />
            <Link
              id="ordersLink"
              className="myAccountDashboard__order_link"
              button={true}
              href={`/account-management/my-groceries/detail/${state.order.pdv.ref}/${state.order.id}`}
            >
              {t('myAccount.dashboard.order.see')}
            </Link>
          </>
        )}
      </>
    </LoadableContent>
  );
};

export default Order;
