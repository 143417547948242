import { ReactComponent as ListError } from '@/assets/images/icons/list-error.svg';
import Link from '@/components/atoms/link';
import LoadableContent from '@/components/atoms/loadableContent';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import ShoppingList from '@/components/organisms/myAccount/shoppingList';
import frequentShoppingListApi from '@/lib/api/frequentShoppingList';
import { Content as ContentfulContent } from '@/lib/contentful';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import ProductModel from '@/lib/model/product';
import { notification } from '@/lib/notification';
import { AuthStateType } from '@/store/auth/authReducer';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import NotEcommerce from '../notEcommerce';
import './style.scss';

export type MyFrequentShoppingListProps = {
  footer: ContentfulContent;
  pageView: string;
};

type StateType = {
  isLoading: boolean;
  isEcommerce: boolean;
  products: Array<ProductModel>;
};

const MyFrequentShoppingList = ({
  footer,
  pageView
}: MyFrequentShoppingListProps) => {
  const { userId } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      userId: auth.user?.id
    };
  }, shallowEqual);

  const [state, setState] = useState<StateType>({
    isLoading: true,
    isEcommerce: false,
    products: []
  });

  useEffect(() => {
    (async () => {
      const { isEcommerce } = usePdv();

      setState((oldState) => ({
        ...oldState,
        isEcommerce,
        isLoading: isEcommerce
      }));

      if (!userId || !isEcommerce) return;

      let products: Array<ProductModel> = [];

      try {
        products = await frequentShoppingListApi.list(userId);
      } catch (error: any) {
        notification.error(t('notification.erreur'));
      }

      setState((oldState) => ({
        ...oldState,
        isLoading: false,
        products
      }));
    })();
  }, [userId]);

  const deleteProducts = async (products: Array<ProductModel>) => {
    if (!userId) return;

    setState((oldState) => ({
      ...oldState,
      isLoading: true
    }));

    let productsInList = [...state.products];

    try {
      await frequentShoppingListApi.deleteProducts(userId, products);
      productsInList = await frequentShoppingListApi.list(userId);
    } catch (error: any) {
      notification.error(t('notification.erreur'));
    }

    setState((oldState) => ({
      ...oldState,
      isLoading: false,
      products: productsInList
    }));
  };

  const contentPart = (
    <LoadableContent
      className={classnames('myFrequentShoppingList', {
        'myFrequentShoppingList--empty': !state.products.length
      })}
      loading={state.isLoading}
    >
      <>
        {!state.isEcommerce && <NotEcommerce />}
        {state.isEcommerce && !!state.products.length && (
          <ShoppingList
            products={state.products}
            onDelete={(products) => deleteProducts(products)}
          />
        )}
        {state.isEcommerce && !state.products.length && (
          <div className="myFrequentShoppingList__empty_container">
            <ListError width={95} height={95} />
            <div className="myFrequentShoppingList__empty_title">
              {t('myAccount.lists.frequentShoppingList.empty.title')}
            </div>
            <div className="myFrequentShoppingList__empty_message">
              {t('myAccount.lists.frequentShoppingList.empty.message')}
            </div>
            <Link
              button
              className="myFrequentShoppingList__empty_button"
              href="/home"
            >
              {t('myAccount.lists.frequentShoppingList.empty.button')}
            </Link>
          </div>
        )}
      </>
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      enabledId="lists"
      title={t('myAccount.menu.lists.frequentList')}
      content={contentPart}
      pageView={pageView}
      footer={footer}
      backTo={{
        label: t('myAccount.menu.lists'),
        href: '/account-management/my-lists'
      }}
    />
  );
};

export default MyFrequentShoppingList;
