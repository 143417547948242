import React from 'react';

export type ContentProps = {
  city?: string;
};

const Content = ({ city }: ContentProps) => {
  return <div className="pdvName">{city}</div>;
};

export default Content;
