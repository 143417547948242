import { ReactComponent as CrossIcon } from '@/assets/images/icons/cross.svg';
import CartSprite from '@/assets/images/sprites/cart.svg';
import Button from '@/components/atoms/button';
// eslint-disable-next-line import/no-cycle
import ProductTile from '@/components/organisms/product';
import useClickOut from '@/lib/hooks/useClickOut';
import { useDidomi } from '@/lib/hooks/useDidomi';
import useEvent from '@/lib/hooks/useEvent';
import usePageView from '@/lib/hooks/usePageView';
import t from '@/lib/i18n';
import { StateType as CartStateType } from '@/store/cart/types';
import { SET_RECOMMENDATIONS_POPIN_DISPLAYED } from '@/store/common/commonActions';
import classnames from 'classnames';
import _isEqual from 'lodash/isEqual';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import './style.scss';
import { BottomProductsLayerProps } from './types';

type StateType = {
  cartHasChanged?: boolean;
};
const BottomProductsLayer = ({
  classname,
  title,
  productModel,
  productList = [],
  information,
  setIsOpen = () => {},
  hasFooter,
  onCancel,
  onValidate
}: BottomProductsLayerProps) => {
  const [clickOutRef, clickOutHandler] = useClickOut();
  const dispatch = useDispatch();
  // TODO :   const {subCarts} = useShallowEqualSelector(selectCart);
  const { subCarts } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      subCarts: cart.subCarts
    };
  });
  const [state, setState] = useState<StateType>({});

  const oldCarts = useRef(subCarts);
  const event = useEvent();

  const { data: isCreateContentProfile } = useDidomi('create_content_profile');

  clickOutHandler(() => {
    close();
  });

  const close = () => {
    dispatch({
      type: SET_RECOMMENDATIONS_POPIN_DISPLAYED,
      payload: { value: false }
    });
    setIsOpen(false);
    onCancel && onCancel();
  };

  useDeepCompareEffect(() => {
    let changed = false;
    for (let index = 0; index < subCarts.length; index++) {
      const sub = subCarts[index];

      if (
        !oldCarts.current?.[index] ||
        (oldCarts.current?.[index] &&
          sub.qty !== oldCarts.current?.[index]?.qty &&
          !_isEqual(
            Object.keys(sub.items),
            Object.keys(oldCarts.current?.[index]?.items)
          ))
      ) {
        changed = true;
        break;
      }
    }
    setState((s) => ({ ...s, cartHasChanged: changed }));
  }, [subCarts]);

  const onFooterClick = () => {
    if (state.cartHasChanged) {
      if (onValidate) {
        onValidate();
        event.send('emerch', { type: 'onValidate' });
      }
    } else {
      onCancel && onCancel();
      event.send('emerch', { type: 'onCancel' });
    }
    setIsOpen(false);
    dispatch({
      type: SET_RECOMMENDATIONS_POPIN_DISPLAYED,
      payload: { value: false }
    });
  };

  const getSecondTitle = (): string => {
    return productList.length > 1
      ? t('product.similar')
      : t('product.similar.one');
  };

  const pageView = usePageView();

  useEffect(() => {
    if (productModel) {
      pageView.send('overlaySimilarProducts', {
        productId: productModel.id
      });
    } else {
      pageView.send('OverlayForgottenProducts');
    }
  }, [pageView, productModel]);

  useEffect(() => {
    dispatch({
      type: SET_RECOMMENDATIONS_POPIN_DISPLAYED,
      payload: { value: true }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classnames('bottomProductsLayer', classname)}>
      <div className="bottomProductsLayer__container" ref={clickOutRef}>
        <div className="bottomProductsLayer__header">
          <div className="bottomProductsLayer__title">{title}</div>
          <div className="bottomProductsLayer__secondTitle desktop">
            <div className="bottomProductsLayer__secondTitle__content">
              <span className="bottomProductsLayer__secondTitle__text">
                {productModel && getSecondTitle()}
              </span>
              {productModel && (
                <CrossIcon
                  className="bottomProductsLayer__close fill--black"
                  height="16"
                  width="16"
                  onClick={close}
                />
              )}
            </div>
          </div>
          {productModel && (
            <CrossIcon
              className="bottomProductsLayer__close fill--black responsive"
              height="16"
              width="16"
              onClick={close}
            />
          )}
        </div>
        {information && (
          <div className="bottomProductsLayer__information">
            <svg width={16} height={16}>
              <use xlinkHref={`${CartSprite}#info`} />
            </svg>
            <span className="bottomProductsLayer__information-text">
              {information}
            </span>
          </div>
        )}
        <div
          className={classnames('bottomProductsLayer__content', {
            bottomProductsLayer__hasFooter: hasFooter
          })}
        >
          {productModel && (
            <div className="bottomProductsLayer__productToReplace">
              <ProductTile
                className="productToReplace"
                data={productModel}
                isToReplace={true}
              />
            </div>
          )}
          {!!productList.length && (
            <div className="bottomProductsLayer__productList">
              {productModel && (
                <div className="bottomProductsLayer__header border responsive ">
                  <span className="bottomProductsLayer__secondTitle">
                    {getSecondTitle()}
                  </span>
                </div>
              )}
              <div className="bottomProductsLayer__productList__results">
                {productList.map((p) => (
                  <ProductTile
                    key={`product_${p.ean}`}
                    className="bottomProductsLayer__productList__item"
                    data={p}
                    isCreateContentProfile={isCreateContentProfile}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        {hasFooter && (
          <div className="bottomProductsLayer__footer">
            <Button
              onClick={onFooterClick}
              className="bottomProductsLayer__footer-action"
            >
              {state.cartHasChanged
                ? t('bottomProductsLayer.validate')
                : t('bottomProductsLayer.noTY')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BottomProductsLayer;
