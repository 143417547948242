import React, { memo, useEffect, useState } from 'react';
import { STATUS } from '@/store/cardCreation/reducer';
import FidelityApi from '@/lib/api/fidelity';
import UserInfoApi from '@/lib/api/userInformation';
import { useSelector, useDispatch } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { getPdvRef } from '@/lib/hooks/usePdv';
import useEvent from '@/lib/hooks/useEvent';

import { SET_STATUS } from '@/store/cardCreation/actions';

import { TimeOutException } from '@/lib/api/fidelity/exceptions';
import { useInterval } from 'react-use';
import Step from './step';

const SETTINGS = {
  INTERVAL: 2000,
  NB_MAX_RETRY: 15
};

const InProgressStep = () => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [nbRetry, setNbRetry] = useState(1);
  const event = useEvent();

  const dispatch = useDispatch();

  const getStatus = async (id: string) => {
    const resultUserInfos = await UserInfoApi.getInformations(id);
    let status = STATUS.ERROR;
    switch (resultUserInfos.fidelityCardStatus) {
      case '1':
      case '3':
        status = STATUS.TIMEOUT;
        break;
      case '2':
        status = STATUS.SUCCESS;
        break;
      case '10':
        status = STATUS.ERROR;
        break;
      default:
        break;
    }
    return status;
  };

  useInterval(
    async () => {
      if (!userId) {
        return;
      }
      const status = await getStatus(userId);
      if (status === STATUS.TIMEOUT) {
        setNbRetry(nbRetry + 1);
        if (nbRetry + 1 >= SETTINGS.NB_MAX_RETRY) {
          dispatch({ type: SET_STATUS, payload: { status } });
        }
      } else {
        setNbRetry(SETTINGS.NB_MAX_RETRY);
        dispatch({ type: SET_STATUS, payload: { status } });
      }
    },
    nbRetry < SETTINGS.NB_MAX_RETRY ? SETTINGS.INTERVAL : null
  );

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }

      try {
        const pdvRef = getPdvRef();
        await FidelityApi.createFidelityCard(userId, pdvRef);
        event.send('api', {
          name: 'createFid',
          type: 'success'
        });
      } catch (error: any) {
        event.send('api', {
          name: 'createFid',
          type: 'error'
        });
        setNbRetry(SETTINGS.NB_MAX_RETRY);
        dispatch({
          type: SET_STATUS,
          payload: {
            status:
              error instanceof TimeOutException ? STATUS.TIMEOUT : STATUS.ERROR
          }
        });
      }
    })();
  }, [userId, dispatch, event]);

  return <Step screenId="ecranCreationEnCours" loading />;
};

export default memo(InProgressStep);
