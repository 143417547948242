import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import t from '@/lib/i18n';
import { AuthStateType } from '@/store/auth/authReducer';
import userInformationApi from '@/lib/api/userInformation';
import newsletterApi from '@/lib/api/newsletter';
import FormRadio from '@/components/atoms/formRadio';
import Modal from '@/components/organisms/modal';
import { useForm, Controller } from 'react-hook-form';
import Button from '@/components/atoms/button';

type EmailOptinModalProps = {
  open: boolean;
  onSubscribed: () => void;
  onClose: () => void;
};

type FormData = {
  optIn: {
    itmEmail: boolean;
  };
};

const EmailOptinModal = ({
  open,
  onSubscribed,
  onClose
}: EmailOptinModalProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      optIn: {
        itmEmail: false
      }
    }
  });

  const onSubmit = async (data: FormData) => {
    if (!userId) {
      return;
    }

    setIsUpdating(true);
    await userInformationApi.updatePersonalData(userId, data);
    await newsletterApi.disableAll(userId);
    setIsUpdating(false);

    onSubscribed();
  };

  return (
    <Modal
      className="myNewsletters__modal"
      pageView="SubmitToNewsletters"
      open={open}
      onClose={() => onClose()}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="myNewsletters__modal_title">
          {t('myAccount.myNewsletters.emailOptinModal.title')}
        </h2>
        <div className="myNewsletters__modal_content">
          {t('myAccount.myNewsletters.emailOptinModal.content')}
        </div>
        <div className="myNewsletters__modal_label">
          {t('myAccount.myNewsletters.emailOptinModal.label')}
        </div>
        <div className="myNewsletters__modal_form">
          <Controller
            control={control}
            name="optIn.itmEmail"
            render={({ onChange, value, name, ref }) => (
              <FormRadio
                name={name}
                options={[
                  {
                    id: 'yes',
                    label: t('yes'),
                    value: 1
                  },
                  {
                    id: 'no',
                    label: t('no'),
                    value: 0
                  }
                ]}
                ref={ref}
                value={Number(value)}
                onChange={(newValue) => {
                  onChange(newValue);
                  setDisabled(!newValue);
                }}
              />
            )}
          />
        </div>
        <div className="myNewsletters__modal_submit">
          <Button type="submit" loading={isUpdating} disabled={disabled}>
            {t('myAccount.myNewsletters.emailOptinModal.form.submit')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EmailOptinModal;
