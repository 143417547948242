import React, { FormEvent, ChangeEvent } from 'react';
import FormInput from '@/components/atoms/formInput';
import Paragraph from '@/components/atoms/paragraph';
import Title from '@/components/atoms/title';
import Button from '@/components/atoms/button';
import Link from '@/components/atoms/link';
import t from '@/lib/i18n';
import './style.scss';

export type FormPasswordForgottenProps = {
  emailValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  submitActive: boolean;
  isEmailError: boolean;
  loading: boolean;
};

const FormPasswordForgotten = ({
  emailValue,
  onChange,
  handleSubmit,
  submitActive,
  isEmailError,
  loading
}: FormPasswordForgottenProps) => {
  return (
    <div className="formPassForgot connexion">
      <Title
        tag="h2"
        label={t('forgottenPassword.form.title')}
        className="formPassForgot__title"
      />
      <Paragraph className="formPassForgot__info">
        {t('forgottenPassword.form.info')}
      </Paragraph>
      <form onSubmit={handleSubmit}>
        <div className="formPassForgot__label">
          <label htmlFor="email">
            {t('forgottenPassword.form.emailLabel')}
            <span className="formPassForgot__label__star">*</span>
          </label>
        </div>
        <div>
          <FormInput
            value={emailValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            name="email"
            id="email"
            className="formPassForgot__input"
            errorMessage={
              isEmailError ? t('forgottenPassword.form.emailError') : ''
            }
          />
        </div>
        <div className="formPassForgot__mandatory">
          * {t('form.error.required')}
        </div>
        <Button
          loading={loading}
          type="submit"
          className="formPassForgot__button--submit"
          label={t('form.button.validate')}
          disabled={!submitActive}
        />
      </form>
      <div className="formPassForgot__footer">
        <h2 className="formPassForgot__title noAccount">
          {t('forgottenPassword.footer.label')}
        </h2>
        <Link
          href="/my-account/create-my-account"
          className="formPassForgot__footer__link"
        >
          {t('forgottenPassword.footer.link')}
        </Link>
      </div>
    </div>
  );
};

export default FormPasswordForgotten;
