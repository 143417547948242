import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import Link from '@/components/atoms/link';
import LoadableContent from '@/components/atoms/loadableContent';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import orderApi from '@/lib/api/order';
import { MkpOrder } from '@/lib/api/order/types';
import { Content as ContentfulContent } from '@/lib/contentful';
import useEvent from '@/lib/hooks/useEvent';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import { notification } from '@/lib/notification';
import useShallowEqualSelector from '@/lib/utils/useShallowEqualSelector';
import { selectUserId } from '@/store/auth/authSelectors';
import React, { useCallback, useEffect, useState } from 'react';
import CancelOrder from '../cancel';
import '../detail/style.scss';
import Item from './item';

export type OrderDetailProps = {
  orderId: string;
  footer: ContentfulContent;
};

type StateType = {
  isLoading: boolean;
  order: MkpOrder | null;
  isOpen: boolean;
};

const MkpOrderDetail = ({ orderId, footer }: OrderDetailProps) => {
  const event = useEvent();
  const userId = useShallowEqualSelector(selectUserId);
  const [state, setState] = useState<StateType>({
    isLoading: true,
    order: null,
    isOpen: false
  });

  const load = useCallback(async () => {
    setState((oldState) => ({
      ...oldState,
      isLoading: true
    }));

    const { ref } = usePdv();

    if (!userId || !ref) return;

    let order: MkpOrder | null = null;

    try {
      order = await orderApi.readMkp(userId, orderId);
    } catch (error: any) {
      notification.error(t('notification.erreur'));
    }

    setState((oldState) => ({
      ...oldState,
      isLoading: false,
      order
    }));
  }, [userId, orderId]);

  useEffect(() => {
    (async () => {
      await load();
    })();
  }, [load]);

  const contentPart = (
    <LoadableContent className="myAccountOrder" loading={state.isLoading}>
      <>
        {state.order && (
          <>
            <div className="myAccountOrder__row">
              <div className="myAccountOrder__address">
                <svg height="34" width="34">
                  <use xlinkHref={`${MyAccountSprite}#store`} />
                </svg>
                {state.order.seller && (
                  <>
                    <div className="myAccountOrder__title">
                      {t('myAccount.order.seller')}
                    </div>
                    <a
                      className="myAccountOrder__seller"
                      target="_blank"
                      href={`/partners-sellers/${state.order.seller.name}-${state.order.seller.id}`}
                      rel="noopener noreferrer"
                      onClick={() => {
                        event.send('sellerClick', {
                          sellerName: state.order?.seller.name
                        });
                      }}
                    >
                      {t('myAccount.order.soldBy')}
                      <b>{state.order.seller.name}</b>
                    </a>
                  </>
                )}
              </div>
              <div className="myAccountOrder__mode">
                <svg height="34" width="34">
                  <use xlinkHref={`${MyAccountSprite}#logistic`} />
                </svg>

                <div className="myAccountOrder__title">
                  {t(`myAccount.order.withdrawalMode`)}
                </div>
                <div
                  className="myAccountOrder__deliveryMode"
                  dangerouslySetInnerHTML={{
                    __html: t('myAccount.mkpOrder.delivery', {
                      '%days%': state.order.shippingDetails?.totalDeliveryTime,
                      '%type%':
                        state.order.shippingDetails?.selectedShippingTypeName
                    })
                  }}
                />
              </div>
              <div className="myAccountOrder__address">
                <svg height="34" width="34">
                  <use xlinkHref={`${MyAccountSprite}#pin`} />
                </svg>
                {state.order.shippingAddress && (
                  <>
                    <div className="myAccountOrder__title">
                      {t('myAccount.order.deliveryMode')}
                    </div>
                    <div>
                      {`${state.order.shippingAddress.firstname} ${state.order.shippingAddress.lastname}`}
                    </div>
                    <div>
                      {state.order.shippingAddress.street1} <br />
                      {state.order.shippingAddress.street2 && (
                        <>
                          {state.order.shippingAddress.street2} <br />
                        </>
                      )}
                      {state.order.shippingAddress.zipCode}{' '}
                      {state.order.shippingAddress.city}
                    </div>
                  </>
                )}
              </div>
              <div className="myAccountOrder__billing">
                <svg height="34" width="34">
                  <use xlinkHref={`${MyAccountSprite}#euro`} />
                </svg>
                <div className="myAccountOrder__title">
                  {t('myAccount.order.paymentMode')}
                </div>
                <div>{t('myAccount.order.paymentType.online')}</div>
              </div>
              <div className="myAccountOrder__needHelp">
                <svg height="38" width="38">
                  <use xlinkHref={`${MyAccountSprite}#question`} />
                </svg>
                <div className="myAccountOrder__title">
                  {t('myAccount.order.needHelp.title')}
                </div>
                <div className="myAccountOrder__needHelpLink">
                  <Link
                    href="/help-and-contact/vas"
                    target="_blank"
                    onClick={() =>
                      event.send('savClick', { orderID: state.order?.id })
                    }
                  >
                    {t('myAccount.order.needHelp.text')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="myAccountOrder__content">
              <div className="myAccountOrder__items">
                <div className="myAccountOrder__category_name">
                  {t(
                    `myAccount.lists.numberOfArticle${
                      state.order.items.length > 1 ? 's' : ''
                    }`,
                    {
                      '%qty%': state.order.items.length
                    }
                  )}
                </div>
                {state.order.items.map((item: any) => {
                  const productKey = `order-item-${item.id}`;

                  return (
                    <div key={productKey} className="myAccountOrder__item">
                      <Item {...item} />
                    </div>
                  );
                })}
              </div>
              <div className="myAccountOrder__recap">
                <div className="myAccountOrder__total">
                  <div className="myAccountOrder__total_label">
                    {t('myAccount.order.total')}
                  </div>
                  <div className="myAccountOrder__total_amount">
                    {String(state.order.amount.toFixed(2)).replace('.', ',')}
                    &euro;
                  </div>
                </div>
                {!!state.order.totalDiscount && (
                  <div className="myAccountOrder__discount">
                    <div className="myAccountOrder__discount_label">
                      {t('myAccount.order.discount')}
                    </div>
                    <div className="myAccountOrder__discount_amount">
                      {String(state.order.totalDiscount.toFixed(2)).replace(
                        '.',
                        ','
                      )}
                      &euro;
                    </div>
                  </div>
                )}
                {!!state.order.shippingDetails?.selectedShippingTypePrice && (
                  <div className="myAccountOrder__fees">
                    <div className="myAccountOrder__fees_label">
                      {t('myAccount.order.articles', {
                        '%articles%': state.order.items.length
                      })}
                    </div>
                    <div className="myAccountOrder__fees_dots" />
                    <div>
                      {String(
                        (
                          state.order.amount -
                          state.order.shippingDetails?.selectedShippingTypePrice
                        ).toFixed(2)
                      ).replace('.', ',')}
                      &euro;
                    </div>
                  </div>
                )}
                {!!state.order.shippingDetails?.selectedShippingTypePrice && (
                  <div className="myAccountOrder__fees">
                    <div className="myAccountOrder__fees_label">
                      {t('myAccount.order.fees')}
                    </div>
                    <div className="myAccountOrder__fees_dots" />
                    <div>
                      +{' '}
                      {String(
                        state.order.shippingDetails?.selectedShippingTypePrice.toFixed(
                          2
                        )
                      ).replace('.', ',')}
                      &euro;
                    </div>
                  </div>
                )}
                {state.order?.mkpStatus === 'PENDING' && userId && (
                  <CancelOrder
                    isMkp
                    className="myAccountOrder__cancel"
                    orderId={state.order.id}
                    onDeleted={() => load()}
                    deleteOrder={async () =>
                      orderApi.cancelMkp(
                        userId,
                        orderId,
                        state.order?.items.map(({ id, quantity, price }) => ({
                          id,
                          quantity,
                          price
                        })) || []
                      )
                    }
                  />
                )}
              </div>
            </div>
          </>
        )}
      </>
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      title={
        state.order
          ? t('myAccount.order.title', { '%orderId%': state.order.id })
          : ''
      }
      enabledId="orders"
      content={contentPart}
      footer={footer}
      backTo={{
        label: t('myAccount.order.backToCommands'),
        href: '/account-management/my-groceries'
      }}
    />
  );
};

export default MkpOrderDetail;
