import React from 'react';
import Modal from '@/components/organisms/modal';
import './style.scss';

type GenericModalProps = {
  pageView?: string;
  validate: () => void;
  validateLabel: string;
  closeModal: () => void;
  closeLabel: string;
  title: string;
  body: JSX.Element;
  open: boolean;
  validateLoading: boolean;
};

export const GenericModal = ({
  open,
  closeModal,
  closeLabel,
  body,
  validate,
  validateLabel,
  validateLoading = false,
  pageView,
  title
}: GenericModalProps) => {
  return (
    <Modal
      open={open}
      closable={false}
      pageView={pageView}
      className="genericModal"
      scrollable={false}
      confirmBtn={{
        label: validateLabel,
        onClick: validate,
        isLink: true,
        loading: validateLoading,
        disabled: validateLoading
      }}
      cancelBtn={{
        label: closeLabel,
        onClick: closeModal,
        isLink: true
      }}
    >
      <p className="genericModal__content genericModal__title">{title}</p>
      {body}
    </Modal>
  );
};

export default GenericModal;
