import React, { useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import t from '@/lib/i18n';
import BaseLayout from '@/components/organisms/layout';
import Header from '@/components/organisms/header';
import Link from '@/components/atoms/link';
import CommonSprite from '@/assets/images/sprites/common.svg';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
// import ChatBotMessenger from '@/components/molecules/chatbotMessenger';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DO_LOGOUT } from '@/store/auth/authActions';
import { CommonStateType } from '@/store/common/commonReducer';
import { AuthStateType } from '@/store/auth/authReducer';
import DATA from './data';

export type MyAccountLayoutProps = {
  title?: string;
  content: JSX.Element;
  enabledId: string;
  footer: ContentfulContent;
  pageView?: string;
  backTo?: {
    label: string;
    href: string;
  };
};

const MyAccountLayout = ({
  title,
  content,
  footer,
  enabledId,
  backTo,
  pageView
}: MyAccountLayoutProps) => {
  const user = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user,
    shallowEqual
  );
  const [isMounted, setIsMounted] = useState(false);
  const [smartConsoDisplayedState, setSmartConsoDisplayedState] = useState(
    true
  );
  const [isColumnOpened, setIsColumnOpened] = useState(false);
  const dispatch = useDispatch();
  const { smartConsoDisplayed, isHydrated } = useSelector(
    ({ common }: { common: CommonStateType }) => ({
      smartConsoDisplayed:
        typeof common.smartConsoDisplayed === 'boolean'
          ? []
          : common.smartConsoDisplayed,
      isHydrated: common.isHydrated
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isMounted || !isHydrated) {
      setIsMounted(true);
      return;
    }

    if (user?.id && smartConsoDisplayed) {
      setSmartConsoDisplayedState(smartConsoDisplayed.includes(user.id));
    }

    if (!isColumnOpened) {
      document.documentElement.style.overflow = 'visible';
    } else {
      document.documentElement.style.overflow = 'hidden';
    }
  }, [isColumnOpened, isMounted, isHydrated, smartConsoDisplayed, user?.id]);

  const headerPart = <Header />;

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  const contentPart = (
    <div className="myAccount container container--xl">
      <aside
        className={classnames('myAccount__column', {
          'myAccount__column--active': isColumnOpened
        })}
      >
        <svg
          className="myAccount__columnClose"
          onClick={() => setIsColumnOpened(false)}
          width="20px"
          height="20px"
        >
          <use xlinkHref={`${CommonSprite}#cross-bold`} />
        </svg>
        <nav role="navigation" aria-label="Menu secondaire">
          <ul className="myAccount__nav">
            {DATA.map((item, itemIndex) => {
              const key = `item-${itemIndex}`;
              if (!item) {
                return null;
              }
              return (
                <li
                  key={key}
                  className={classnames('myAccount__navItem', {
                    'myAccount__navItem--active': enabledId === item.id,
                    'myAccount__navItem--new':
                      item.id === 'smartConso' && !smartConsoDisplayedState
                  })}
                  data-after-content={t('myAccount.menu.newItem.label')}
                >
                  <Link
                    id={item.id}
                    href={item.link}
                    target="_self"
                    // target={item.id === 'contact' ? '_blank' : '_self'}
                  >
                    <>
                      <svg height="25" width="25">
                        <use xlinkHref={`${MyAccountSprite}#${item.icon}`} />
                      </svg>
                      <label className="myAccount__nav--label">
                        {item.label}
                      </label>
                      <svg className="myAccount__caret" height="15" width="15">
                        <use xlinkHref={`${MyAccountSprite}#caret`} />
                      </svg>
                    </>
                  </Link>
                  {item.children && item.children.length > 0 && (
                    <ul
                      className={classnames('myAccount__nav', {
                        'myAccount__nav--active': enabledId === item.id
                      })}
                    >
                      {item.children.map((subItem, subItemIndex) => {
                        const subKey = `sub-item-${subItemIndex}`;

                        return (
                          <li key={subKey} className="myAccount__navItem">
                            <Link id={subItem.id} href={subItem.link}>
                              <>
                                <svg height="11" width="11">
                                  <use
                                    xlinkHref={`${MyAccountSprite}#arrow-right`}
                                  />
                                </svg>
                                {subItem.label}
                              </>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="myAccount__columnFooter">
            <div className="myAccount__columnFooter__contact">
              {t('myAccount.footer.contact')}
              <div className="myAccount__columnFooter__socialLink">
                <Link
                  href="https://www.facebook.com/intermarcheportugal"
                  target="_blank"
                  className="myAccount__columnFooter__picto"
                >
                  <svg height="15" width="15">
                    <use xlinkHref={`${CommonSprite}#facebook`} />
                  </svg>
                </Link>
                {/*                 <Link
                  href="https://twitter.com/intermarche"
                  target="_blank"
                  className="myAccount__columnFooter__picto"
                >
                  <svg height="15" width="15">
                    <use xlinkHref={`${CommonSprite}#twitter`} />
                  </svg>
                </Link> */}
                <Link
                  href="https://www.instagram.com/intermarchept"
                  target="_blank"
                  className="myAccount__columnFooter__picto"
                >
                  <svg height="15" width="15">
                    <use xlinkHref={`${CommonSprite}#instagram`} />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="myAccount__logout">
              <div className="myAccount__logout__line" />
              <div
                className="myAccount__logout__link"
                onClick={() => {
                  dispatch({
                    type: DO_LOGOUT
                  });
                }}
              >
                {t('myAccount.footer.logout')}
              </div>
            </div>
          </div>
        </nav>
      </aside>
      <div className="myAccount__content">
        {!!backTo && (
          <div
            className="myAccount__backTo"
            onClick={() => {
              window.location.href = backTo.href;
            }}
          >
            <svg height="15" width="15">
              <use xlinkHref={`${CommonSprite}#big-arrow-left`} />
            </svg>
            <span className="myAccount__backTo__label">{backTo.label}</span>
          </div>
        )}
        {!backTo && (
          <div
            className="myAccount__menuLink"
            onClick={() => setIsColumnOpened(!isColumnOpened)}
          >
            <svg className="myAccount__menuLinkIcon" height="15" width="15">
              <use xlinkHref={`${MyAccountSprite}#arrow-right`} />
            </svg>
            {t('myAccount.content.showMenu')}
          </div>
        )}
        {title && <h1 className="myAccount__title">{title}</h1>}
        {content}
      </div>
    </div>
  );

  return (
    <>
      <BaseLayout
        header={headerPart}
        content={contentPart}
        footer={footerPart}
        pageView={pageView}
      />
      {/*   <ChatBotMessenger /> */}
    </>
  );
};

export default MyAccountLayout;
