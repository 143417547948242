import React from 'react';
import Button from '@/components/atoms/button';
import classnames from 'classnames';
import './style.scss';

export type CookiesPreferencesButtonProps = {
  label?: string;
  className?: string;
};

// TODO here add didomi when added to the project

const CookiesPreferencesButton = ({
  label,
  className
}: CookiesPreferencesButtonProps) => (
  <div className={classnames('cookiesPreferencesButton', className)}>
    <Button
      className="cookiesPreferencesButton__button"
      color="primary"
      label={label}
      type="button"
      onClick={() => null /* Didomi?.preferences?.show() */}
    />
  </div>
);

export default CookiesPreferencesButton;
