import React from 'react';
import getConfigValue from '@/lib/config';
import { Request, Response } from 'express';
import Iframe from '@/components/atoms/iframe';
import usePdv from '@/lib/hooks/usePdv';

type InitialProps = {
  props: ContentProps;
  req: Request;
  res: Response;
};

export type ContentProps = {
  pdv?: string;
};

const Content = ({ pdv }: ContentProps) => {
  if (!pdv) {
    return null;
  }

  const wsmcommerceHost = getConfigValue(
    'WSMCOMMERCE_CONTENT_HOST',
    'https://wsmcommerce-pt.intermarche.com/'
  );

  return (
    <Iframe
      attributes={{
        name: 'cgv',
        src: `${wsmcommerceHost}/cgv/Pdv/${pdv}`,
        height: '417',
        width: '100%'
      }}
      className="cgv__iframe"
    />
  );
};

Content.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref } = usePdv(req);

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=3600, max-age=3600');
    }

    resolve({
      ...props,
      pdv: ref
    });
  });
};

export default Content;
