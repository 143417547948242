import Link from '@/components/atoms/link';
import React, { memo } from 'react';
import { ReactComponent as Arrow } from '@/assets/images/icons/arrow-right.svg';
import t from '@/lib/i18n';

import './style.scss';
import ModalLinkCard from '../modalLinkCard';

export type ActivateCardBlocProps = {
  showFooter?: boolean;
  userInfo: any;
  isAuthenticated: boolean;
  labels: {
    labelHasNoFid: string;
    labelHasFid: string;
    labelNotConnected: string;
  };
  lsCardRetry?: JSX.Element | boolean;
};

const ActivateCardBloc = ({
  showFooter = true,
  userInfo,
  isAuthenticated,
  labels,
  lsCardRetry
}: ActivateCardBlocProps) => {
  if (userInfo?.isPro) {
    return null;
  }

  return (
    <div className="activateCardBloc">
      {lsCardRetry}
      <div className="activateCardBloc__linksContainer">
        <div className="activateCardBloc__link">
          <Link
            href={
              isAuthenticated
                ? '/fidelity-advantages/card-creation'
                : '/connection'
            }
            className="activateCardBloc__link--button"
            label={
              isAuthenticated ? labels.labelHasNoFid : labels.labelNotConnected
            }
            button
          />
        </div>
        <div className="activateCardBloc__link activateCardBloc__link--withIcon">
          <Arrow width="11" />
          <ModalLinkCard
            userInfo={userInfo}
            isActiveCardContext={true}
            label={labels.labelHasFid}
          />
        </div>
      </div>
      {showFooter && (
        <div className="activateCardBloc__text activateCardBloc__text--small">
          {t('fidelityCard.activateCardBloc.footerText')}{' '}
          <Link
            href="/connection"
            className="activateCardBloc__text--link"
            label={t('fidelityCard.activateCardBloc.connectMe')}
          />
        </div>
      )}
    </div>
  );
};

export default memo(ActivateCardBloc);
