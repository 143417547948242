import { Content, normalize as BaseNormalize, NormalizeParams } from '..';

const normalize = ({ data, includes }: NormalizeParams) => {
  const content: Content = {
    components: []
  };

  if (data.meta) {
    const { id } = data.meta.sys;
    const metaData = includes[id];

    content.meta = {
      title: metaData.fields.pageTitle,
      description: metaData.fields.pageDescription
    };
  }

  if (data.content) {
    data.content.forEach((item: any) => {
      const itemData = includes[item.sys.id] || null;

      if (itemData) {
        const elt = BaseNormalize({ data: itemData, includes });

        if (elt) {
          content.components.push(elt);
        }
      }
    });
  }

  return content;
};

export default normalize;
