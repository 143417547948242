// https://developers.google.com/web/fundamentals/web-components/shadowdom#polyfill
const loadScript = (url: string) => {
  return new Promise((resolve, reject) => {
    // #1 programmatically add the <script> tag
    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;

    // #2 add the <script> tag to the header of the page
    document.head.appendChild(script);
  });
};

export default loadScript;
