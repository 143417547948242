import { NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams) => {
  return {
    type: 'atoms',
    name: 'timelineEntry',
    props: {
      date: data.date || '',
      text: data.text || ''
    }
  };
};

export default normalize;
