import React, { useState } from 'react';
import t from '@/lib/i18n';
import { notification } from '@/lib/notification';
import { BillingAddress, DeliveryAddress } from '@/lib/api/address/types';
import AddressApi from '@/lib/api/address';
import InformationApi from '@/lib/api/userInformation';
import Form from '../form';

type EditProps = {
  userId: string;
  address: BillingAddress | DeliveryAddress;
  onCancel: () => void | Promise<void>;
  callback?: (
    newAddress: BillingAddress | DeliveryAddress
  ) => void | Promise<void>;
  pageView?: string;
};

const Edit = ({ userId, address, onCancel, callback, pageView }: EditProps) => {
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (newAddress: BillingAddress | DeliveryAddress) => {
    try {
      setSubmitting(true);
      const finalAddress = {
        ...newAddress,
        country:
          address.type === 'billing' ? newAddress.country : address.country
      };
      if (finalAddress.country === 'PRT') {
        await AddressApi.update(userId, finalAddress);
        if (address.type === 'billing') {
          await InformationApi.updateAddress(
            userId,
            newAddress as BillingAddress
          );
        }
      } else {
        await InformationApi.updateAddress(
          userId,
          finalAddress as BillingAddress
        );
      }

      if (callback) {
        await callback(finalAddress);
      } else {
        await onCancel();
      }
      notification.success(t('myAccount.myAddresses.form.success'));
    } catch (error: any) {
      notification.error(t('notification.erreur'));
    }

    setSubmitting(false);
  };

  return (
    <Form
      submitting={submitting}
      address={address}
      onSubmit={onSubmit}
      onCancel={onCancel}
      pageView={pageView}
    />
  );
};

export default Edit;
