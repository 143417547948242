import t from '@/lib/i18n';
import React from 'react';
import './style.scss';

const ScoreLabels = ({ flags }: any) => {
  return (
    <div className="scoreLabels">
      {flags.length > 0 && (
        <>
          <div className="scoreLabels__title">
            {t('pdp.nutrition.label.title')}
          </div>
          <div className="">
            {flags.map((flag: any) => {
              const key = `flag-${flag.key}}`;
              const src = `https://cdn.intermarche.pt/mobileAppAssets/${flag.key}.png`;
              return (
                <div className="scoreLabels__item" key={key}>
                  <img src={src} alt={flag.key} height="auto" width={30} />
                  {flag.key}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ScoreLabels;
