import Link from '@/components/atoms/link';
import Modal from '@/components/organisms/modal';
import useEvent from '@/lib/hooks/useEvent';
import t from '@/lib/i18n';
import { notification } from '@/lib/notification';
import classnames from 'classnames';
import React, { useState } from 'react';
import './style.scss';

export type CancelOrderProps = {
  className?: string;
  orderId: string;
  onDeleted: () => void;
  deleteOrder: () => Promise<void>;
  isMkp?: boolean;
};

type ConfirmationProps = {
  title: string;
  description?: string;
};

type StateType = {
  showValidation: boolean;
  confirmation?: ConfirmationProps;
  deleting: boolean;
};

const CancelOrder = ({
  className,
  orderId,
  onDeleted,
  deleteOrder,
  isMkp
}: CancelOrderProps) => {
  const [state, setState] = useState<StateType>({
    showValidation: false,
    confirmation: undefined,
    deleting: false
  });

  const event = useEvent();

  const closeModal = () => {
    setState((oldState) => ({
      ...oldState,
      showValidation: false,
      confirmation: undefined,
      deleting: false
    }));
  };
  const doDelete = async () => {
    setState((oldState) => ({
      ...oldState,
      deleting: true
    }));

    try {
      await deleteOrder();
      event.send('account', {
        type: 'cancelOrder',
        orderId
      });

      setState((oldState) => ({
        ...oldState,
        showValidation: false,
        confirmation: {
          title: isMkp
            ? t('myAccount.order.cancel.modal.mkpConfirmation.title')
            : t('myAccount.order.cancel.modal.confirmation.title'),
          description: isMkp
            ? t('myAccount.order.cancel.modal.mkpConfirmation.description')
            : undefined
        }
      }));
    } catch {
      notification.error(t('myAccount.order.cancel.modal.error.description'));
      closeModal();
    }
  };

  return (
    <div className={classnames('cancelOrder', className)}>
      <Link
        className="cancelOrder__cta"
        onClick={(e) => {
          e.preventDefault();
          setState((oldState) => ({
            ...oldState,
            showValidation: true
          }));
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: t('myAccount.order.cancel.link')
          }}
        />
      </Link>
      <Modal
        onClose={closeModal}
        className="cancelOrder__modal"
        pageView="CancelOrder"
        open={state.showValidation}
        confirmBtn={{
          label: t('myAccount.order.cancel.modal.cancel'),
          color: 'primary',
          onClick: closeModal
        }}
        cancelBtn={{
          loading: state.deleting,
          label: t('myAccount.order.cancel.modal.confirm'),
          color: 'secondary',
          onClick: () => doDelete()
        }}
      >
        <h3 className="cancelOrder__modal__title">
          {t('myAccount.order.cancel.modal.title')}
        </h3>
      </Modal>
      <Modal
        className="cancelOrder__modal cancelOrder__modal__confirmation"
        open={Boolean(state.confirmation)}
        onClose={() => onDeleted()}
      >
        <>
          <h3 className="cancelOrder__modal__title">
            {state.confirmation?.title}
          </h3>

          {state.confirmation?.description && (
            <p className="cancelOrder__modal__description">
              {state.confirmation?.description}
            </p>
          )}
        </>
      </Modal>
    </div>
  );
};

export default CancelOrder;
