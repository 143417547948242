import React, { memo, useEffect, useState } from 'react';
import { Operation as OperationItemProps } from '@/lib/api/stickers/types';
import FidelitySprite from '@/assets/images/sprites/carteFidelite.svg';
import t from '@/lib/i18n';
import FidelityApi from '@/lib/api/fidelity';
import UserInformationApi from '@/lib/api/userInformation';
import { FidelityInfo } from '@/lib/api/fidelity/types';
import { Informations } from '@/lib/api/userInformation/types';
import { useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import LoadableContent from '@/components/atoms/loadableContent';
import usePageView from '@/lib/hooks/usePageView';
import usePdv from '@/lib/hooks/usePdv';
import Message from '@/components/molecules/message';
import Operation from './operation';

import './style.scss';

export type StickersOperationsProps = {
  operations: Array<OperationItemProps> | null;
  pdvIsActivated: boolean;
};

type StateType = {
  userInfo?: Informations;
  fidelityInfos?: FidelityInfo;
  isLoading: boolean;
};
const StickersOperations = ({
  operations,
  pdvIsActivated
}: StickersOperationsProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [state, setState] = useState<StateType>({
    userInfo: undefined,
    fidelityInfos: undefined,
    isLoading: true
  });
  const pageView = usePageView();

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }

      const userInfo = await UserInformationApi.getInformations(userId);

      setState((oldState) => {
        return {
          ...oldState,
          userInfo
        };
      });
    })();
  }, [userId]);

  useEffect(() => {
    (async () => {
      if (!state.userInfo || !state.userInfo.fidelityCardNumber) {
        return;
      }
      let fidelityInfos: FidelityInfo | undefined;
      try {
        fidelityInfos = await FidelityApi.getInfo({
          lastName: state.userInfo.lastName,
          firstName: state.userInfo.firstName,
          birthdayDate: state.userInfo.birthdayDate,
          fidelityCardNumber: state.userInfo.fidelityCardNumber
        });
      } catch (error: any) {
        fidelityInfos = undefined;
      }

      setState((oldState) => {
        return {
          ...oldState,
          isLoading: false,
          fidelityInfos
        };
      });
    })();
  }, [state.userInfo]);

  useEffect(() => {
    if ((operations && !operations.length) || !operations) {
      const { ref } = usePdv();
      pageView.send('MyStickersError', {
        pdvRef: ref
      });
    }
  }, [pageView, operations]);

  return (
    <div>
      {operations && (
        <LoadableContent
          loading={state.isLoading}
          className="stickersOperations"
        >
          {operations?.map((operation) => (
            <Operation
              operation={operation}
              operationBalance={
                state.fidelityInfos?.operationsBalances?.[operation.code]
              }
              cardNumber={state.fidelityInfos?.cardNumber ?? ''}
              key={operation.code}
              pdvIsActivated={pdvIsActivated}
            />
          ))}
        </LoadableContent>
      )}
      {operations && !operations.length && (
        <Message
          image={`${FidelitySprite}#icons-fid-no-operation`}
          title={t(
            'myAccount.fidelity.myStickers.operations.errors.noOperation.title'
          )}
          desc={t(
            'myAccount.fidelity.myStickers.operations.errors.noOperation.desc'
          )}
        />
      )}
      {!operations && (
        <Message
          image={`${FidelitySprite}#icons-fid-no-operation`}
          title={t(
            'myAccount.fidelity.myStickers.operations.errors.technicalIssue.title'
          )}
          desc={t(
            'myAccount.fidelity.myStickers.operations.errors.technicalIssue.desc'
          )}
        />
      )}
    </div>
  );
};

export default memo(StickersOperations);
