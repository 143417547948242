import { ImageProps } from '@/components/atoms/image';
import { Component, NormalizeParams } from '..';

const normalize = ({
  data,
  includes
}: NormalizeParams): ComponentImageProps => {
  let optimizer = '?fm=jpg&fl=progressive';

  const imageData = includes[data.image?.sys?.id];

  if (imageData?.fields.file?.contentType === 'image/png') {
    optimizer = '?fm=webp';
  }

  return {
    type: 'atoms',
    name: 'image',
    props: {
      alt: data.title || 'cmsImage',
      src: imageData?.fields?.file?.url + optimizer,
      className: 'cmsImage',
      width: Math.min(...[data.imgWidth, data.maxWidth].filter(Boolean))
    }
  };
};

export default normalize;

export interface ComponentImageProps extends Component {
  props: ImageProps;
}
