import React from 'react';
import './style.scss';
import gift from '@/assets/images/icons/gift.svg';
import { useForm } from 'react-hook-form';
import paymentApi from '@/lib/api/payment';
import FormInput from '@/components/atoms/formInput';
import { PaymentStateType, StateType } from '@/store/cart/types';
import t from '@/lib/i18n';
import DiscountCodeModel from '@/lib/model/discountCode';
import { isValidDiscount } from '@/lib/utils/cart';
import useEvent from '@/lib/hooks/useEvent';

type DiscountCodeProps = {
  setPaymentState: any;
  paymentState: PaymentStateType;
  cart: StateType;
  userId: string;
};

const DiscountCode = ({
  setPaymentState,
  paymentState,
  cart,
  userId
}: DiscountCodeProps) => {
  const discountForm = useForm<any>({
    defaultValues: {
      discount: null
    }
  });
  const event = useEvent();

  const handleSubmitDiscountForm = async ({
    discount
  }: {
    discount: string;
  }) => {
    if (discount === '') return;
    event.send('checkout', { type: 'discount', discount });
    setPaymentState((p: PaymentStateType) => ({
      ...p,
      discountIsLoading: true
    }));
    const resetDiscountFrom = () => {
      discountForm.setValue('discount', '');
      return null;
    };
    let discountData: DiscountCodeModel | null;
    try {
      discountData = paymentState.discountCode
        ? resetDiscountFrom()
        : (await paymentApi.sendDiscount(
            userId,
            cart.slots.selectedSlot.deliveryType,
            discount,
            cart.total
          )) || null;
      if (discountData && !isValidDiscount(cart, discountData)) {
        discountData = {
          errorMessage: t('form.error.toomuch')
        };
      }
    } catch (error: any) {
      discountData = {
        errorMessage: t('discountCode.errorApi.retryApiCall')
      };
    }
    setPaymentState((p: PaymentStateType) => ({
      ...p,
      discountCode: discountData,
      discountIsLoading: false
    }));
  };

  return (
    <section className="payment__section payment__discount">
      <form onSubmit={discountForm.handleSubmit(handleSubmitDiscountForm)}>
        <FormInput
          validationItem={!!paymentState.discountCode}
          itemIsSentByParent
          buttonLabel={t('common.add')}
          pictoSize="25"
          picto={gift}
          isLoading={paymentState.discountIsLoading}
          placeholder={t('cart.payment.codes.discount.placeholder')}
          name="discount"
          id="discount"
          label={t('cart.payment.codes.discount')}
          ref={discountForm.register()}
          errorMessage={paymentState.discountCode?.errorMessage || ''}
          onChange={() => {
            if (paymentState.discountCode) {
              setPaymentState((p: PaymentStateType) => ({
                ...p,
                discountCode: null
              }));
            }
          }}
          autoComplete="off"
        />
        {paymentState.discountCode?.validationMessage && (
          <p className="discount__validation">
            {paymentState.discountCode?.validationMessage}
          </p>
        )}
      </form>
    </section>
  );
};

export default DiscountCode;
