import React, { useState, useRef, useEffect } from 'react';
import './index.scss';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { ReactComponent as Spinner } from '@/assets/images/icons/spinner.svg';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { StateType as CartStateType, SubCartType } from '@/store/cart/types';
import Product from '@/lib/model/product';
import useClickOut from '@/lib/hooks/useClickOut';
import t from '@/lib/i18n';
import Modal from '@/components/organisms/modal';
import Lot from '@/components/organisms/lot';
import useEvent from '@/lib/hooks/useEvent';
import { useRouter } from 'next/router';
import { addToCartBasic } from '@/lib/thirdPartyApi/cart/addToCart';

type LayerProps = {
  product: Product;
  selectedNumber: number;
  max: number;
  selectedItem: (selectedItem: any) => void;
  isCreateContentProfile?: boolean;
  close: () => void;
  isSearch?: boolean;
  keyword?: string;
  position?: number;
};

type AddCartButtonProps = {
  product: Product;
  isPdp?: boolean;
  isCreateContentProfile?: boolean;
  label?: string;
  fullSync?: boolean;
  disabled?: boolean;
  sellerId?: string;
  itemParentId?: string;
  listPosition?: number;
  currentOfferItemId?: string;
  isSmall?: boolean;
  isSearch?: boolean;
  keyword?: string;
  position?: number;
};

type ListItem = {
  selected: boolean;
  label: number;
};

const weightCalculate = (gram: number) => {
  if (gram >= 1000) {
    const kg = String((gram / 1000).toFixed(2)).replace('.', ',');
    return t('common.kg', { '%number%': kg });
  }

  return t('common.gram', { '%number%': gram });
};

const didomiDispatchEvent = (
  key: string,
  payload: any,
  isCreateContentProfile: boolean,
  event: any
) => {
  if (isCreateContentProfile) {
    event.send(key, payload);
  }
};

const Layer = ({
  selectedNumber,
  max,
  selectedItem,
  close,
  product,
  isCreateContentProfile = true,
  isSearch = false,
  keyword = undefined,
  position = 0
}: LayerProps) => {
  const selectedElement = useRef(null);
  const [list, setList] = useState(
    Array.from(Array(max).keys()).map((i) => ({
      selected: selectedNumber === i + 1,
      label: i + 1
    }))
  );
  const [clickOutRef, clickOutHandler] = useClickOut();
  const event = useEvent();
  const router = useRouter();

  enum TypeRemove {
    AUTO_COMPLETE = 'product autocompletion',
    CLASSIC = 'product'
  }

  const typeRemove = isSearch ? TypeRemove.AUTO_COMPLETE : TypeRemove.CLASSIC;

  const selected = (item: ListItem, index: number) => {
    setList((i) => {
      return i.map((l, id) => {
        const result = l;
        result.selected = id === index;
        return result;
      });
    });
    selectedItem(item.label);
  };

  const trashHandler = () => {
    didomiDispatchEvent(
      'removeFromCart',
      { product, path: router.asPath, typeRemove, keyword, position },
      isCreateContentProfile,
      event
    );
    setList((item) => item.map((i) => ({ ...i, selected: false })));
    selectedItem(0);
  };

  clickOutHandler(() => {
    close();
  });

  useEffect(() => {
    let height;
    if (selectedElement.current) {
      height =
        ((selectedElement.current as unknown) as HTMLElement)?.getBoundingClientRect()
          .height || null;
      height &&
        document
          .getElementById('addCartLayer')
          ?.scrollTo(0, height * (selectedNumber - 1));
    }
  }, [selectedNumber]);

  return (
    <ul ref={clickOutRef} className="addCart__layer" id="addCartLayer">
      <li className="addCart__item">
        <button onClick={trashHandler} className="addCart__btn addCart__trash">
          <svg className="fill--black" width="20" height="20">
            <use xlinkHref={`${CommonSprite}#trash`} />
          </svg>
        </button>
      </li>
      {list.map((d: ListItem, i: number) => (
        <li
          ref={d.selected ? selectedElement : null}
          className={'addCart__item '.concat(
            d.selected ? 'addCart__item--selected' : ''
          )}
          key={d.label}
        >
          <button className="addCart__btn" onClick={() => selected(d, i)}>
            {product.informations.pvi && (
              <>
                <div>
                  {weightCalculate(
                    (product.informations.poidsMinimum ?? 0) +
                      product.informations.pvi * i
                  )}
                </div>
                (
                {t(
                  `cart.summary.${
                    Math.round(
                      ((product.informations.poidsMinimum ?? 0) +
                        product.informations.pvi * i) /
                        product.informations.pvi
                    ) > 1
                      ? 'pieces'
                      : 'piece'
                  }`,
                  {
                    '%qty%': Math.round(
                      ((product.informations.poidsMinimum ?? 0) +
                        product.informations.pvi * i) /
                        product.informations.pvi
                    )
                  }
                )}
                )
              </>
            )}
            {!product.informations.pvi && d.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

const AddCartButton = ({
  product,
  label,
  fullSync = false,
  isPdp = false,
  isCreateContentProfile = true,
  disabled,
  sellerId,
  itemParentId,
  listPosition,
  currentOfferItemId,
  isSmall = false,
  isSearch = false,
  keyword = undefined,
  position = 0
}: AddCartButtonProps) => {
  const id = currentOfferItemId ?? product.id;

  // TODO :   const cartStore = useShallowEqualSelector(selectCart);
  const { cartStore } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      cartStore: cart
    };
  });
  const dispatch = useDispatch();
  const event = useEvent();
  const router = useRouter();

  const cartItem = cartStore.subCarts.find((sub: SubCartType) => {
    return sub.seller.sellerId === sellerId;
  })?.items[id];

  const [addCartState, setAddCartState] = useState({
    layerIsVisible: false,
    loading: false
  });

  const [IsInitLabelVisible, setInitLabelVisible] = useState(false);
  const [modalLotIsVisible, setModalLotIsVisible] = useState(false);

  const isMounted = useRef(true);

  enum TypeAdd {
    AUTO_COMPLETE = 'product autocompletion',
    CLASSIC = 'classic'
  }

  const typeAdd = isSearch ? TypeAdd.AUTO_COMPLETE : TypeAdd.CLASSIC;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setInitLabelVisible(!cartItem?.qty && !addCartState.loading);
  }, [cartItem, addCartState]);

  const spinnerColor = () => {
    if (addCartState.loading && cartItem?.qty) {
      return 'addCart__loading addCart__loading--primary';
    }
    return 'addCart__loading';
  };

  const selectedItem = async (item: number) => {
    if (item !== 0) {
      didomiDispatchEvent(
        'addToCart',
        {
          product,
          path: router.asPath,
          index: listPosition,
          qty: item,
          typeAdd,
          keyword,
          position
        },
        isCreateContentProfile,
        event
      );
    }
    setAddCartState({
      ...addCartState,
      layerIsVisible: false,
      loading: true
    });

    try {
      await addToCartBasic(
        dispatch,
        [
          {
            product,
            quantity: item ?? 1,
            sellerId,
            itemParentId,
            currentOfferItemId: id
          }
        ],
        fullSync
      );
    } catch (error: any) {
      throw new Error(error);
    } finally {
      if (isMounted.current) {
        setAddCartState({
          layerIsVisible: false,
          loading: false
        });
      }
    }
  };

  const addToCart = async () => {
    setAddCartState((item) => ({ ...item, loading: true }));
    try {
      await addToCartBasic(
        dispatch,
        [
          {
            product,
            quantity: 1,
            sellerId,
            itemParentId,
            currentOfferItemId: id
          }
        ],
        fullSync
      );
    } catch (error: any) {
      throw new Error(error);
    } finally {
      setAddCartState((item) => ({ ...item, loading: false }));
    }
  };

  return (
    <div
      className={classnames({
        addCart: true,
        'addCart--withLabel': !!label,
        'addCart--disabled': disabled,
        'addCart--selectedNumber': !!cartItem,
        'addCart--inCart': !!isSmall
      })}
      id="btn-addCart"
    >
      {product.lot && product.lot.products.length > 0 && modalLotIsVisible && (
        <Modal
          className="addCart__lotModal"
          closable={true}
          onClose={() => {
            setModalLotIsVisible((l) => !l);
          }}
          confirmBtn={{
            label: t('common.add'),
            loading: addCartState.loading,
            onClick: async () => {
              await addToCart();
              setModalLotIsVisible((l) => !l);
            }
          }}
          cancelBtn={{
            label: t('common.cancel'),
            onClick: () => {
              setModalLotIsVisible(false);
            }
          }}
          open={modalLotIsVisible}
        >
          <strong className="addCart__lotTitle">
            {t('cart.lot.title', { '%qty%': product.lot.products.length })}
          </strong>
          <Lot productEan={product.ean} />
        </Modal>
      )}
      {IsInitLabelVisible && (
        <span
          className="addCart__initLabel"
          onClick={() => {
            if (!isPdp && product.lot) {
              setModalLotIsVisible((l) => !l);
              return;
            }
            if (!product.maxQty) {
              return;
            }
            didomiDispatchEvent(
              'addToCart',
              {
                product,
                path: router.asPath,
                index: listPosition,
                typeAdd,
                keyword,
                position
              },
              isCreateContentProfile,
              event
            );
            addToCart();
          }}
        >
          <svg className="fill--white" width="38" height="38">
            <use xlinkHref={`${CommonSprite}#add-to-cart`} />
          </svg>
          {label}
        </span>
      )}
      {addCartState.loading && <Spinner className={spinnerColor()} />}
      {parseInt(JSON.parse(String(cartItem?.qty || 0)), 10) > 0 && (
        <span
          className={classnames('addCart__number', {
            'addCart__number--visible': !addCartState.loading
          })}
          onClick={() => {
            if (disabled) return;
            setAddCartState({
              ...addCartState,
              layerIsVisible: true
            });
          }}
        >
          {product.informations.pvi
            ? weightCalculate(
                (product.informations.poidsMinimum ?? 0) +
                  product.informations.pvi * ((cartItem?.qty || 1) - 1)
              )
            : cartItem?.qty}
        </span>
      )}
      {addCartState.layerIsVisible && (
        <Layer
          close={() => {
            setAddCartState((st) => ({
              ...st,
              layerIsVisible: false
            }));
          }}
          product={product}
          selectedNumber={cartItem?.qty || 0}
          max={cartItem?.product.maxQty || 0}
          selectedItem={selectedItem}
          isCreateContentProfile={isCreateContentProfile}
          isSearch={isSearch}
          keyword={keyword}
          position={position}
        />
      )}
    </div>
  );
};

export default AddCartButton;
