import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import { Informations } from '@/lib/api/userInformation/types';
import LoadableContent from '@/components/atoms/loadableContent';
import Date, { getHoursColor } from '@/lib/utils/date';
import Link from '@/components/atoms/link';
import pdvApi from '@/lib/api/pdv';
import PdvModel, { Address } from '@/lib/model/pdv';
import classnames from 'classnames';

type StoreProps = {
  userInfo?: Informations;
};

type StateType = {
  isLoading: boolean;
  pdv?: PdvModel | null;
  toolTipIsLoaded: boolean;
};

const getMessage = (pdv: PdvModel) => {
  let openingMessage = '';
  const isOpen = pdv.isOpen();
  const nextClosingDate = pdv.getNextClosingDate();
  const nextOpeningDate = pdv.getNextOpeningDate();

  if (isOpen && nextClosingDate) {
    openingMessage = t('header.storeInfo.openingMessage.todayUntil', {
      '%hours%': nextClosingDate.format('HH'),
      '%minutes%': nextClosingDate.format('mm')
    });
  } else if (!isOpen && nextOpeningDate) {
    const today = Date().hour(0).minute(0);
    const tomorrow = today.add(1, 'day');

    if (nextOpeningDate > today && nextOpeningDate < tomorrow) {
      openingMessage = t('header.storeInfo.openingMessage.today', {
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    } else if (nextOpeningDate > tomorrow) {
      openingMessage = t('header.storeInfo.openingMessage.tomorrow', {
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    } else {
      openingMessage = t('header.storeInfo.openingMessage', {
        '%date%': nextOpeningDate.format('dddd'),
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    }
  }

  return openingMessage;
};

const getFormatedName = (
  format: string,
  address: Address | undefined
): string => {
  const store = t('pdv.name.store');
  return [format, store, address?.city].join(' ');
};

const Store = ({ userInfo }: StoreProps) => {
  const [state, setState] = useState<StateType>({
    isLoading: true,
    pdv: undefined,
    toolTipIsLoaded: false
  });

  useEffect(() => {
    setState((oldState) => {
      return {
        ...oldState,
        toolTipIsLoaded: true
      };
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (!userInfo || !userInfo?.lastVisitedPdv) {
        return;
      }

      const pdv = await pdvApi.getPdvInfo(userInfo.lastVisitedPdv);

      setState((oldState) => {
        return {
          ...oldState,
          isLoading: false,
          pdv
        };
      });
    })();
  }, [userInfo]);

  return (
    <LoadableContent
      loading={state.isLoading}
      loaderSize="small"
      className="myAccountDashboard__container myAccountDashboard__store"
    >
      <>
        {!!state.pdv && (
          <Link
            className="myAccountDashboard__store_link"
            href="/account-management/my-info/my-stores"
          >
            <>
              <div className="myAccountDashboard__store_content">
                <div className="myAccountDashboard__store_title">
                  {t('myAccount.dashboard.store.title')}
                </div>
                <div className="myAccountDashboard__store_name">
                  {getFormatedName(state.pdv.format, state.pdv.address)}
                </div>
                <div className="myAccountDashboard__store_info">
                  {!state.pdv.isOpen() && (
                    <>
                      <span className="myAccountDashboard__store_highlight myAccountDashboard__store_highlight--closed">
                        {t('header.storeInfo.closed')}
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getMessage(state.pdv)
                        }}
                      />
                    </>
                  )}
                  {state.pdv.isOpen() && (
                    <div
                      className={classnames(
                        'myAccountDashboard__store_highlight',
                        `myAccountDashboard__store_highlight--${getHoursColor(
                          state.pdv.getNextClosingDate()
                        )}`
                      )}
                    >
                      {getMessage(state.pdv)}
                    </div>
                  )}
                </div>
              </div>
              <svg
                className="myAccountDashboard__store_caret"
                height="10"
                width="10"
              >
                <use xlinkHref={`${MyAccountSprite}#caret`} />
              </svg>
            </>
          </Link>
        )}
      </>
    </LoadableContent>
  );
};

export default Store;
