import React, { useRef, useEffect, useState } from 'react';
import { lazyAddShowCase } from '@/lib/thirdPartyApi/home/criteo';
import ProductTile from '@/components/organisms/product';
import ProductModel from '@/lib/model/product';
import './style.scss';

export type ShowcaseProps = {
  pdvRef: string;
};

const Showcase = ({ pdvRef }: ShowcaseProps) => {
  const [showcaseProducts, setShowcaseProducts] = useState([]);
  const coverRef = useRef(null);
  useEffect(() => {
    if (window) {
      window.exposedApi = {
        ...window.exposedApi,
        addShowCase: lazyAddShowCase(setShowcaseProducts, coverRef)
      };
    }
    return () => {
      if (window?.exposedApi?.addShowCase) {
        delete window.exposedApi.addShowCase;
      }
    };
  }, [pdvRef]);

  return (
    <div className="container">
      <div id="regie-homePdv-middle">
        <div className="showcase" data-testid="showcase-container">
          <div
            ref={coverRef}
            data-regie-id="showcase-homepdv-cover"
            data-testid="showcase-cell-cover"
          />
          {showcaseProducts.map((product: ProductModel) => (
            <div
              key={product.id}
              data-id={product.ean}
              data-nfproductid={product.id}
              data-name={`${product.informations.brand} - ${product.informations.title}`}
              data-price={product.prices.productPrice?.value}
              data-testid="showcase-cell-product"
            >
              <ProductTile
                key={product.ean}
                data={product}
                sellerId={product.bestOffer?.seller.id}
                isShowCase
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Showcase;
