import React, { useEffect } from 'react';
import Title from '@/components/atoms/title';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { ReactComponent as Spinner } from '@/assets/images/icons/spinner.svg';
import './style.scss';

type LoadingScreenProps = {
  title?: string;
  text?: string;
};

const LoadingScreen = ({ title, text }: LoadingScreenProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <div className="loadingScreen">
      <svg width="200" className="fill--white">
        <use xlinkHref={`${CommonSprite}#logo`} />
      </svg>
      <Spinner className="loadingScreen__loader" />
      {title && (
        <Title tag="h3" label={title} className="loadingScreen__title" />
      )}
      {text && <p className="loadingScreen__text">{text}</p>}
    </div>
  );
};

export default LoadingScreen;
