import {
  AccordionProps,
  AccordionItemProps
} from '@/components/molecules/accordion';
import { Component, normalize as BaseNormalize, NormalizeParams } from '..';

const normalize = ({
  data,
  includes
}: NormalizeParams): ComponentAccordionProps => {
  const { items = [] } = data;
  const itemsList: Array<AccordionItemProps> = [];

  items.forEach((element: any) => {
    const itemData = includes[element.sys.id] || null;
    const {
      sys: { id }
    } = itemData;
    const dataToPass = {
      ...itemData,
      fields: {
        ...itemData.fields,
        id
      }
    };
    itemsList.push(
      (BaseNormalize({
        data: dataToPass,
        includes
      }) as unknown) as AccordionItemProps
    );
  });

  return {
    type: 'molecules',
    name: 'accordion',
    props: {
      itemsList
    }
  };
};

export default normalize;

interface ComponentAccordionProps extends Component {
  props: AccordionProps;
}
