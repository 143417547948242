import React from 'react';
import classNames from 'classnames';

type FrancoScoreProps = {
  score?: number;
  className?: string;
};
const FrancoScore = ({ score, className }: FrancoScoreProps) => {
  if (score === undefined) {
    return null;
  }
  return (
    <div
      className={classNames(
        'productDetail__smartConso__francoScore',
        className
      )}
    >
      <img
        src={`https://cdn.intermarche.pt/mobileAppAssets/Labels-Francoscore/FRANCOSCORE_${score}.png`}
        alt="francoScore"
        height="auto"
        width={60}
      />
    </div>
  );
};

export default FrancoScore;
