import React from 'react';
import SmartConsoSprite from '@/assets/images/sprites/smartconso.svg';
import './style.scss';
import classNames from 'classnames';
import t from '@/lib/i18n';

const ScoreBadge = ({ default: note, rating }: any) => {
  return (
    <div className="scoreBadgeContainer">
      <div className="scoreBadge">
        {rating.key === 'avoid' && (
          <div className="nutritionSection__heading__avoid">
            <svg className="rank--avoid" width="48px" height="48px">
              <use xlinkHref={`${SmartConsoSprite}#avoid`} />
            </svg>
          </div>
        )}
        {rating.key !== 'avoid' && (
          <div
            className={classNames('scoreBadge__note', `rank--${rating.key}`)}
          >
            <div>{note}</div>
            <div className="scoreBadge__slash" />
            <div>100</div>
          </div>
        )}
      </div>
      <div className="scoreBadgeTitle">
        {t(`pdp.nutrition.scoreBar.${rating.key}`)}
      </div>
    </div>
  );
};

export default ScoreBadge;
