import React from 'react';
import { CartType } from '@/store/cart/types';
import CartSprite from '@/assets/images/sprites/cart.svg';
import { formattedLeadTimeToShip } from '@/lib/utils/providerSelect';
import Link from '@/components/atoms/link';
import classnames from 'classnames';
import t from '@/lib/i18n';
import './style.scss';

type MkpInfosProps = {
  cart: CartType;
  sellerId: string;
  currentSellerId?: string;
};

const MmkpInfos = ({ cart, sellerId, currentSellerId }: MkpInfosProps) => {
  return (
    <>
      {Object.keys(cart.mkpPackages?.[sellerId].packages || {}).map(
        (packageId) => {
          const deliveryCost =
            cart.mkpPackages?.[sellerId].packages[packageId].selectedProvider
              ?.deliveryCost;
          if (
            !cart.mkpPackages?.[sellerId].packages[packageId].selectedProvider
              ?.id
          )
            return null;
          return (
            <div key={packageId}>
              <div className="cartInfo mkpInfos__element">
                <div className="cartInfo">
                  <svg className="cartInfo__picto" height="25" width="25">
                    <use xlinkHref={`${CartSprite}#delivery`} />
                  </svg>
                </div>
                <div className="cartInfo__content">
                  <div className="cartInfo__title">
                    {t(
                      `mkp.deliveryService.${cart.mkpPackages?.[sellerId].packages[packageId].selectedProvider?.id}`
                    )}
                  </div>
                  {cart.mkpPackages?.[sellerId].packages[packageId]
                    .selectedProvider?.leadTimeToShip && (
                    <div className="cartInfo__item">
                      {t('mkp.deliveryService.deliverFrom')}
                      <span className="slotDate">{` ${formattedLeadTimeToShip(
                        cart.mkpPackages?.[sellerId].packages[packageId]
                          .selectedProvider?.totalDeliveryTime as number
                      )}`}</span>
                    </div>
                  )}
                  <div className="mkpInfos__feesLine">
                    <div>{t('mkp.deliveryService.fees')}</div>
                    <div
                      className={classnames({
                        'mkpInfos__price--green': deliveryCost === 0
                      })}
                    >
                      {deliveryCost === 0
                        ? t('cart.slot.fees.offer')
                        : `${String(deliveryCost?.toFixed(2))?.replace(
                            '.',
                            ','
                          )} €`}
                    </div>
                  </div>
                </div>
              </div>
              {currentSellerId !== sellerId && (
                <div className="mkpInfos__modify">
                  <Link
                    className="mkpInfos__modify__btn"
                    href={`/orders/delivery#${sellerId}`}
                    label={t('common.modify')}
                    button={false}
                  />
                </div>
              )}
            </div>
          );
        }
      )}
    </>
  );
};

export default MmkpInfos;
