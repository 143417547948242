import React, { useEffect, useState } from 'react';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import PdvApi from '@/lib/api/pdv';
import Pdv from '@/lib/model/pdv';
import LoadingScreen from '../loadingScreen';
import SeoStoreLoc from '../seoStoreLoc';

export type StoreLocatorLayerProps = {
  redirectType: 'shop' | 'plp' | 'pdp';
  redirectId: string;
};

type State = {
  isVirtual: boolean;
  isStoreLocLoading: boolean;
  isPdvEcommerce: boolean;
  pdvName: string;
};

const StoreLocatorLayer = ({
  redirectType,
  redirectId
}: StoreLocatorLayerProps) => {
  const [state, setState] = useState<State>({
    isVirtual: false,
    isStoreLocLoading: false,
    isPdvEcommerce: true,
    pdvName: ''
  });

  useEffect(() => {
    const { isVirtual, isEcommerce, ref } = usePdv();

    let pdv: Pdv | null = null;

    const fetchPdvInfo = async () => {
      if (ref && !isVirtual) {
        try {
          pdv = await PdvApi.getPdvInfo(ref);
          setState((oldState: State) => ({
            ...oldState,
            pdvName: pdv?.name || ''
          }));
        } catch (error: any) {
          pdv = null;
        }
      }
    };
    fetchPdvInfo();
    setState((oldState: State) => ({
      ...oldState,
      isVirtual,
      isPdvEcommerce: isEcommerce
    }));
  }, []);

  if (!state.isVirtual && state.isPdvEcommerce) return null;

  return (
    <>
      {state.isStoreLocLoading && (
        <LoadingScreen
          title={t('loading.screen.title')}
          text={t('loading.screen.text')}
        />
      )}
      {!state.isStoreLocLoading && (
        <SeoStoreLoc
          {...{ redirectId, redirectType }}
          callback={() => {
            setState((oldState: State) => ({
              ...oldState,
              isStoreLocLoading: true
            }));
          }}
          title={
            !state.isPdvEcommerce && !state.isVirtual
              ? t('storeLocatore.modal.title', {
                  '%pdvName%': state.pdvName
                })
              : undefined
          }
          content={
            !state.isPdvEcommerce && !state.isVirtual
              ? t('storeLocatore.modal.content')
              : undefined
          }
        />
      )}
    </>
  );
};

export default StoreLocatorLayer;
