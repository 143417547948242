import Accordion, {
  AccordionItemProps
} from '@/components/molecules/accordion';
import t from '@/lib/i18n';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';
import { CommonStateType } from '@/store/common/commonReducer';
import {
  SET_OP_DISCLAIMER_DISPLAYED_PDV_ACTIVE,
  SET_OP_DISCLAIMER_DISPLAYED_PDV_NON_ACTIVE
} from '@/store/common/commonActions';
import LoadableContent from '@/components/atoms/loadableContent';

export type StickersPresentationContainerProps = {
  pdvIsActivated: boolean;
};

const StickersPresentationContainer = ({
  pdvIsActivated
}: StickersPresentationContainerProps) => {
  const [openIndex, setOpenIndex] = useState('');
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();
  const {
    isHydrated,
    oPdisclaimerDisplayedPdvActive,
    oPdisclaimerDisplayedPdvNonActive
  } = useSelector(({ common }: { common: CommonStateType }) => ({
    isHydrated: common.isHydrated,
    oPdisclaimerDisplayedPdvActive: common.oPdisclaimerDisplayedPdvActive,
    oPdisclaimerDisplayedPdvNonActive: common.oPdisclaimerDisplayedPdvNonActive
  }));

  const ACCORDION_ITEMS: AccordionItemProps[] = [
    {
      title: t('myAccount.fidelity.tiles.fidelity_stickers.title'),
      body: [
        {
          name: 'myAccount/fidelity/myStickers/stickersPresentation',
          type: 'templates',
          props: {
            pdvIsActivated
          }
        }
      ],
      id: '1'
    }
  ];

  useEffect(() => {
    setIsActive(false);
    if (!isHydrated) {
      return;
    }
    if (pdvIsActivated && !oPdisclaimerDisplayedPdvActive) {
      dispatch({
        type: SET_OP_DISCLAIMER_DISPLAYED_PDV_ACTIVE,
        payload: { value: true }
      });
      setOpenIndex('1');
    }
    if (!pdvIsActivated && !oPdisclaimerDisplayedPdvNonActive) {
      dispatch({
        type: SET_OP_DISCLAIMER_DISPLAYED_PDV_NON_ACTIVE,
        payload: { value: true }
      });
      setOpenIndex('1');
    }
    setIsActive(true);
  }, [
    dispatch,
    isHydrated,
    oPdisclaimerDisplayedPdvActive,
    oPdisclaimerDisplayedPdvNonActive,
    pdvIsActivated
  ]);

  return (
    <LoadableContent
      className="stickersPresentationContainer"
      loading={!isActive}
    >
      <Accordion
        openIndex={openIndex}
        itemsList={ACCORDION_ITEMS}
        directionalArrow={true}
        arrowText={t('myAccount.fidelity.myStickers.presentation.infos')}
      />
    </LoadableContent>
  );
};

export default memo(StickersPresentationContainer);
