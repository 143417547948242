import React, { useState } from 'react';
import t from '@/lib/i18n';
import SecurityLayout from '@/components/organisms/securityLayout';
import AccountApi from '@/lib/api/account';
import FormRenewForgotten from '../form';
import RenewPasswordError from '../renewPasswordError';
import RenewPassordSuccess from '../renewPasswordSuccess';

const API_RESULT = {
  initial: 'initial',
  valid: 'valid',
  unValid: 'unValid'
};

const ContentRenewPassword = () => {
  const [apiResult, setApiResult] = useState(API_RESULT.initial);

  const submitNewPassword = async (password: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') || '';
    const apiRes = await AccountApi.renewPassword(password, token);
    setApiResult(apiRes ? API_RESULT.valid : API_RESULT.unValid);
  };

  return (
    <SecurityLayout
      goBack={{
        label: t('forgottenPassword.goBack.label'),
        url: '/home'
      }}
    >
      <>
        {apiResult === API_RESULT.initial && (
          <FormRenewForgotten submitNewPassword={submitNewPassword} />
        )}
        {apiResult === API_RESULT.unValid && <RenewPasswordError />}
        {apiResult === API_RESULT.valid && <RenewPassordSuccess />}
      </>
    </SecurityLayout>
  );
};

export default ContentRenewPassword;
