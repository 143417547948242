import { TitleProps } from '@/components/atoms/title';
import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): ComponentTitleProps => {
  const { text = '', level = '1' } = data;

  return {
    type: 'atoms',
    name: 'title',
    props: {
      label: text,
      tag: `h${level}` as keyof JSX.IntrinsicElements,
      className: `cmsTitle__h${level}`
    }
  };
};

export default normalize;

export interface ComponentTitleProps extends Component {
  props: TitleProps;
}
