import React from 'react';
import Layout from '@/components/organisms/layout';
import {
  Content as ContentfulContent,
  RendererComponents as ContentfulRenderComponents
} from '@/lib/contentful';
import SignUpForm from './signUpForm';
import HeaderStepper from '../header';

export type InscriptionProps = {
  footer: ContentfulContent;
  cgu?: ContentfulContent;
  legals?: ContentfulContent;
  step: string;
  pageView?: string;
};

export type FormStepProps = {
  currentStep: number;
  setCurrentStep: (nextStep: number) => void;
};

const Inscription: React.FunctionComponent<InscriptionProps> = ({
  footer,
  cgu,
  legals,
  step,
  pageView
}) => {
  const headerPart = <HeaderStepper step={step} />;

  const contentPart = <SignUpForm step={step} cgu={cgu} legals={legals} />;

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout
      className="create-my-account"
      header={headerPart}
      content={contentPart}
      footer={footerPart}
      pageView={pageView}
    />
  );
};

export default Inscription;
