import genericPageNormalize from '../genericPage';
import { Content, NormalizeParams } from '..';

const normalize = ({
  data,
  includes,
  extras
}: NormalizeParams): TemplateContenuLibre => {
  return genericPageNormalize({
    data,
    includes,
    extras
  });
};

export default normalize;

type TemplateContenuLibre = Content;
