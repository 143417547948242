import React, { Key } from 'react';
import './style.scss';
import classnames from 'classnames';
import CategoryModel from '@/lib/model/category';
import Link from '@/components/atoms/link';
import { ReactComponent as Promotion } from '@/assets/images/icons/promotion.svg';
import Image from '@/components/atoms/image';

export type ListCategoriesProps = {
  data: Array<CategoryModel>;
  classNames?: string;
};

const ListCategories = ({ data, classNames }: ListCategoriesProps) => {
  return (
    <ul className={classnames('categories__list', classNames)}>
      {data.map((category: CategoryModel, index: Key) => {
        const key = `category-${index}`;
        return (
          <li
            key={key}
            className={classnames('categories__item', {
              'categories__item--promo': category.isPromo
            })}
          >
            {category?.level === 2 && <div className="categories__level2" />}
            <Link className="categories__link" href={category.link}>
              <>
                {category.isPromo && (
                  <Promotion
                    className="categories__picto"
                    width="40px"
                    height="40px"
                  />
                )}
                {category.picto && (
                  <Image
                    className="categories__picto"
                    src={category.picto}
                    alt={category.title}
                    width={40}
                    height={40}
                  />
                )}
                <span>{category.title}</span>
              </>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default ListCategories;
