import { ContentSectionProps } from '@/components/molecules/contentSection';
import { Component, NormalizeParams } from '..';

const normalize = ({ data, includes }: NormalizeParams): ComponentRteProps => {
  const { content = '', title = '' } = data;
  let id = '';

  Object.keys(includes).forEach((key) => {
    const {
      sys: { id: idTemp },
      fields: { title: titleTemp }
    } = includes[key];
    if (title === titleTemp) id = idTemp;
  });

  return {
    type: 'molecules',
    name: 'contentSection',
    props: {
      title,
      content,
      id
    }
  };
};

export default normalize;

export interface ComponentRteProps extends Component {
  props: ContentSectionProps;
}
