import SmartConsoSprite from '@/assets/images/sprites/smartconso.svg';
import Link from '@/components/atoms/link';
import t from '@/lib/i18n';
import React from 'react';

export const DoneForm = () => (
  <div className="result-form">
    <h3 className="result-form__header">
      {t('smartconso.survey.done.header')}
    </h3>
    <p className="result-form__description">{t('smartconso.survey.done.p1')}</p>
    <p className="result-form__description">{t('smartconso.survey.done.p2')}</p>

    <svg className="result-form__innitCode" width="195px" height="35px">
      <use xlinkHref={`${SmartConsoSprite}#innitCodes`} />
    </svg>
    <div className="result-form__footer">
      <Link
        className="result-form__button result-form__button-validate"
        href="/home"
        label={t('smartconso.survey.done.confirm')}
        button
      />
    </div>
  </div>
);
