import React from 'react';
import { Address, CalendarOpeningHour, ExceptionDate } from '@/lib/model/pdv';
import OpeningHours from './openingHours';
import Map from './map';
import './style.scss';
import ExceptionDates from './exceptionDates';

type OpenHoursAndMapProps = {
  name: string;
  address: Address;
  phone?: string;
  fax?: string;
  exceptionDates?: {
    opening?: Array<ExceptionDate>;
    closure?: Array<ExceptionDate>;
  };
  calendar: { openingHours: CalendarOpeningHour[] };
};

const OpenHoursAndMap = ({
  address,
  phone,
  fax,
  name,
  exceptionDates,
  calendar
}: OpenHoursAndMapProps) => {
  return (
    <div className="openHoursAndMap">
      <div className="openHoursAndMap__scheduleContainer">
        {((exceptionDates &&
          exceptionDates.closure &&
          exceptionDates.closure.length > 0) ||
          (exceptionDates &&
            exceptionDates.opening &&
            exceptionDates.opening.length > 0)) && (
          <ExceptionDates {...exceptionDates} />
        )}
        <OpeningHours
          address={address}
          phone={phone}
          name={name}
          fax={fax}
          calendar={calendar}
        />
      </div>
      <div className="openHoursAndMap__mapContainer">
        <Map address={address} name={name} />
      </div>
    </div>
  );
};

export default React.memo(OpenHoursAndMap);
