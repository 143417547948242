import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';

export type PostContactFormProps = {
  client: PostContactFormCustomerProps;
  informations: PostContactFormInformationsProps;
  reponseCaptcha: string;
  sujetDemande: string;
  theme: string;
  typeDemande: string;
  detailDemande: string;
};

export type PostContactFormCustomerProps = {
  email: string;
  nom: string;
  numeroTelephone: string;
  prenom: string;
};

export type PostContactFormInformationsProps = {
  codePostal: string;
  dateLimiteConsommation: string;
  identifiantProduit: string;
  nomOperation: string;
  nomProduit: string;
  numeroCarteFID: string;
  numeroCommande: string;
  numeroLot: string;
  ville: string;
};

export type SavTheme = {
  nom: string;
  type?: undefined;
  nomPictogramme?: string;
  TypeDeDemande?: SavRequestType[];
  sousTheme?: SavSubTheme[];
};
export type SavRequestType = {
  nom: string;
  Demande?: SavRequest[];
};
type SavRequest = {
  nom: string;
  type: number;
};
export type SavSubTheme = {
  nom: string;
  type: string;
  nomPictogramme: string;
  TypeDeDemande?: SavRequestType[];
  url?: string;
};

class ConsumersApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'optionalOAuth']);
  }

  getFormsContact = async (): Promise<SavTheme[] | null> => {
    let SavThemes = null;

    try {
      const response = await this.axios.get<{ Theme: SavTheme[] }>(
        '/consommateur/v1/consommateurs/formulaires_contact'
      );
      SavThemes = response?.data?.Theme;
    } catch (error) {
      logger.error({
        message: 'Unable to get contact form',
        error
      });
    }

    return SavThemes;
  };

  async postContactForm(
    PostContactFormValues: PostContactFormProps,
    files: FileList
  ): Promise<boolean> {
    let response;
    const bodyFormData = new FormData();
    const dataToSend: string = JSON.stringify(PostContactFormValues);
    bodyFormData.append('file', files?.[0]);
    bodyFormData.append(
      'demande',
      new Blob([dataToSend], { type: 'application/json' })
    );

    try {
      response = await this.axios.post(
        '/consommateur/v1/consommateurs/contact',
        bodyFormData
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to post contact form',
        error
      });
    }

    return response?.status === 204;
  }
}

export default new ConsumersApi();
