import CartSprite from '@/assets/images/sprites/cart.svg';
import Link from '@/components/atoms/link';
import Loader from '@/components/atoms/loader';
import PreferencesApi from '@/lib/api/preferences';
import userInformationApi from '@/lib/api/userInformation';
import useEvent from '@/lib/hooks/useEvent';
import t from '@/lib/i18n';
import logger from '@/lib/logger/base';
import { AuthStateType } from '@/store/auth/authReducer';
import { StartSurvey, START_SURVEY } from '@/store/survey/actions';
import { selectSurveyTranslationLabel } from '@/store/survey/surveySelectors';
import { actionCleanSurvey } from '@/store/survey/surveyReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalError from '../Modal/ModalError';
import ModalRefusePerso from '../Modal/modalRefusePerso';
import ModalConsentSes from '../Modal/modalSes';
import './style.scss';

interface LandingProps {
  moveToNextStep: () => void;
  isPostReinit: boolean;
}

const Landing = ({ moveToNextStep, isPostReinit }: LandingProps) => {
  const dispatch = useDispatch();
  const cleanSurvey = actionCleanSurvey(dispatch);
  const [openSesModal, setOpenSesModal] = useState(false);
  const [openRefusedModal, setOpenRefusedModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorTitle, setErrorTitle] = useState<string | undefined>();
  const [errorParagraph, setErrorParagraph] = useState<string | undefined>();
  const [refusedUserData, setRefusedUserData] = useState(true);
  const [isRefusedUserDataLoading, setIsRefusedUserDataLoading] = useState(
    false
  );
  const { userId } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      userId: auth.user?.id
    };
  });
  const surveyTranslationLabel = useSelector(selectSurveyTranslationLabel);
  const event = useEvent();

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }
      const userInfo = await userInformationApi.getInformations(userId);

      setRefusedUserData(!userInfo.optIn['preferences']);
    })();
  }, [userId, isPostReinit]);

  const validateSes = async (sendEvent = true) => {
    if (!userId) return;
    if (sendEvent) {
      event.send('smartconso', {
        type: 'consentementSes',
        label: t('myAccount.smartConso.modalSes.accept')
      });
    }
    try {
      await userInformationApi.updatePersonalData(userId, {
        optIn: { preferences: true }
      });
    } catch (error: any) {
      logger.error({
        message: 'Fail to update the user preferences',
        error
      });
    }
    setOpenSesModal(false);
    dispatch({ type: START_SURVEY } as StartSurvey);
    redirectToForm();
  };

  const closeModalSes = async () => {
    if (!userId) return;
    event.send('smartconso', {
      type: 'consentementSes',
      label: t('myAccount.smartConso.modalSes.refuse')
    });

    await userInformationApi.updatePersonalData(userId, {
      optIn: { preferences: false }
    });

    setOpenSesModal(false);
  };

  const validateRefusedUserData = async () => {
    if (!userId) return;
    event.send('smartconso', {
      type: 'RefuseQues',
      label: t('myAccount.smartConso.modalRefuse.confirm')
    });

    // 1 suppression des réponses aux questions
    try {
      await PreferencesApi.delete(userId);
      cleanSurvey({ withMessage: false });
    } catch (error: any) {
      // Si le profil utilisateur a déjà été supprimé il n'est pas récupérable
      if (
        error.response &&
        error.response.data?.errors &&
        error.response.data?.errors[0]?.code === 'RESOURCE_NOT_FOUND'
      ) {
        cleanSurvey({ withMessage: false });
      } else if (error.response && error.response.status === '401') {
        setOpenRefusedModal(false);
        setOpenErrorModal(true);
        setErrorTitle(t('modal.modalError.connectionLost'));
        setErrorParagraph(t('modal.modalError.retry'));
      } else {
        setOpenRefusedModal(false);
        setOpenErrorModal(true);
        setErrorParagraph(t('modal.modalError.error'));
        logger.error({
          message: 'Unable to delete the user preferences',
          error
        });
      }
    }

    // 2 MAJ du compte
    updatePrefOptIn(false);

    setOpenRefusedModal(false);
  };

  const updatePrefOptIn = async (optIn: boolean) => {
    if (!userId) return;
    setIsRefusedUserDataLoading(true);
    await userInformationApi.updatePersonalData(userId, {
      optIn: { preferences: optIn }
    });
    setIsRefusedUserDataLoading(false);
    setRefusedUserData(!optIn);
  };

  const closeRefusedUserData = () => {
    event.send('smartconso', {
      type: 'RefuseQues',
      label: t('myAccount.smartConso.modalRefuse.cancel')
    });
    setOpenRefusedModal(false);
  };

  const redirectToForm = () => {
    moveToNextStep();
  };

  return (
    <div className="form-landing">
      <div className="form-landing__info">
        <svg className="fill--black" height="20px" width="20px">
          <use xlinkHref={`${CartSprite}#info`} />
        </svg>
        <a
          target="_blank"
          href="/sign/top-deals/FAQ-intermarche#PngxgVWm1ljrexDXssAfv"
          className="link-faq"
        >
          {t('smartconso.header.info')}
        </a>
      </div>
      <h3 className="form-landing__title">{t('smartconso.landing.title')}</h3>
      <div className="form-landing__description">
        <p className="form-landing__description__text">
          {t('smartconso.landing.p1')}
        </p>
        <p className="form-landing__description__text">
          {t('smartconso.landing.p2')}
        </p>
        <p className="form-landing__description__text">
          {t('smartconso.landing.p3')}
        </p>
        <p className="form-landing__description__text">
          {t('smartconso.landing.p4')}
        </p>
        <p className="form-landing__description__text">
          {t('smartconso.landing.p5')}
          <Link
            id="smartconso-faq"
            target="_blank"
            href="/sign/top-deals/FAQ-intermarche#PngxgVWm1ljrexDXssAfv"
            label={t('smartconso.landing.faq')}
          />
        </p>
      </div>
      <div className="form-landing__consent">
        <div className="form-landing__consent__checkbox">
          {isRefusedUserDataLoading ? (
            <Loader color="greenExcellent" />
          ) : (
            <input
              type="checkbox"
              id="personal-alimentation-data"
              name="personal-alimentation-data"
              checked={!refusedUserData}
              onChange={() => {
                event.send('smartconso', {
                  type: 'optinParcours',
                  label: 'optout'
                });
                refusedUserData && updatePrefOptIn(true);
                !refusedUserData && setOpenRefusedModal(true);
              }}
            />
          )}
          <label htmlFor="personal-alimentation-data">
            {t('smartconso.landing.checkbox')}
          </label>
        </div>
      </div>
      <div className="form-landing__start">
        <Link
          id="smartconso-start-button"
          disabled={false}
          label={t(surveyTranslationLabel)}
          button={true}
          className="form-landing__start__button"
          onClick={(e) => {
            e.preventDefault();
            return refusedUserData ? setOpenSesModal(true) : validateSes(false);
          }}
        />
      </div>
      <ModalConsentSes
        open={openSesModal}
        closeModal={() => closeModalSes()}
        validate={() => validateSes()}
      />
      <ModalRefusePerso
        open={openRefusedModal}
        closeModal={() => closeRefusedUserData()}
        validate={() => validateRefusedUserData()}
      />
      <ModalError
        open={openErrorModal}
        closeModal={() => setOpenErrorModal(false)}
        validate={() => {
          closeRefusedUserData();
          setOpenErrorModal(false);
        }}
        errorTitle={errorTitle}
        errorMessage={errorParagraph}
      />
    </div>
  );
};

export default Landing;
