import React from 'react';
import {
  StateType,
  ServiceProvidersType,
  ProviderType,
  PackageType,
  SellerType
} from '@/store/cart/types';
import ProviderBloc from './providerBloc';
import ItemsHeader from './itemsHeader';

export type CarrierChooseProps = {
  cart: StateType;
  subCartId: string;
  selectProvider: (data: {
    selectedProvider: ProviderType | ServiceProvidersType | null;
    packageId?: string;
  }) => void;
  seller: SellerType | null;
};

const CarrierChoose = ({
  cart,
  selectProvider,
  seller
}: CarrierChooseProps) => {
  if (!seller) return null;

  const adaptatorCreatePackagesITM = (): { [key: string]: PackageType } => ({
    '0': {
      serviceProviders:
        cart.slots.selectedSlot.slot?.providers?.map((provider) => {
          return {
            ...(cart.slots.list.delivery.serviceProviders.find(
              (service) => service.id === provider.id
            ) as ServiceProvidersType),
            deliveryCost: provider.deliveryCost.totalCost
          };
        }) || [],
      items: []
    }
  });

  const packages: { [key: string]: PackageType } =
    seller?.sellerId === 'ITM'
      ? adaptatorCreatePackagesITM()
      : cart.mkpPackages?.[seller.sellerId]?.packages || {};

  return (
    <>
      {Object.keys(packages).map((key, index) => {
        return (
          <section className="deliveryPage" key={key}>
            {seller.sellerId !== 'ITM' && (
              <ItemsHeader
                items={packages[key].items}
                currentPackageNumber={index + 1}
                totalPackageNumber={Object.keys(packages).length}
                seller={seller}
              />
            )}
            <div className="deliveryPage__products">
              {packages[key].serviceProviders.length > 0 &&
                packages[key].serviceProviders.map(
                  (provider: ServiceProvidersType) => {
                    if (!provider) return null;
                    return (
                      <div key={provider.id}>
                        <ProviderBloc
                          provider={provider}
                          cart={cart}
                          selectProvider={selectProvider}
                          sellerId={seller.sellerId}
                          packageId={key}
                        />
                      </div>
                    );
                  }
                )}
            </div>
          </section>
        );
      })}
    </>
  );
};

export default CarrierChoose;
