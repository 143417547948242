import React from 'react';

import t from '@/lib/i18n';
import './style.scss';
import classnames from 'classnames';
import QuestionMark from './questionMark';

type ScoreBarProps = {
  rating?: any;
  default?: number;
  isCustom?: boolean;
};

const ScoreBar = ({ rating, default: note = 0, isCustom }: ScoreBarProps) => {
  return (
    <div className="scoreBarContainer">
      <div className="scoreBar">
        <div
          className={classnames('scoreLevel', `rank--${rating.key}`)}
          style={{ left: `${note}%` }}
        />
        <div className="scoreBar__bar rank--badMatch" />
        <div className="scoreBar__bar rank--weakMatch" />
        <div className="scoreBar__bar rank--okayMatch" />
        <div className="scoreBar__bar rank--goodMatch" />
        <div className="scoreBar__bar rank--excellentMatch" />
      </div>
      <div className="scoreBarNote">
        <div className="scoreBarNote__title">
          {t(
            isCustom
              ? 'pdp.nutrition.scoreBar.title.custom'
              : 'pdp.nutrition.scoreBar.title'
          )}
        </div>
        <div className="scoreBarNote__note">
          <div>
            {t(`pdp.nutrition.scoreBar.note`, {
              '%note%': note
            })}
          </div>
          <QuestionMark />
        </div>
      </div>
    </div>
  );
};

export default ScoreBar;
