import React, { memo, useCallback } from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import { useDispatch, useSelector } from 'react-redux';
import { SET_STATUS } from '@/store/cardCreation/actions';
import Button from '@/components/atoms/button';
import ContentSection from '@/components/molecules/contentSection';
import { STATUS, CardCreationStateType } from '@/store/cardCreation/reducer';
import { useRouter } from 'next/router';

export type StepProps = {
  screenId:
    | 'ecranCgu'
    | 'ecranProNonAutorise'
    | 'ecranInformationsInvalides'
    | 'ecranCreationEnCours'
    | 'ecranCarteOk'
    | 'ecranCarteErreur'
    | 'ecranCreationEnCoursTimeout';
  content?: ContentfulContent;
  nextStep?: string;
  loading?: boolean;
};

const Step = ({ screenId, nextStep, content, loading }: StepProps) => {
  const dispatch = useDispatch();

  const router = useRouter();
  const redirectUrl = router?.query['redirect'] as string;

  const { flows } = useSelector(
    ({ cardCreation }: { cardCreation: CardCreationStateType }) => {
      return {
        flows: cardCreation.flows
      };
    }
  );

  const validate = useCallback(() => {
    const status = nextStep || STATUS.CGU;
    if (screenId === ('ecranCarteErreur' || 'ecranCreationEnCoursTimeout')) {
      window.location.href = redirectUrl ?? '/help-and-contact/vas';
    } else if (status === STATUS.CGU) {
      window.location.href = redirectUrl ?? '/fidelity-advantages';
    } else {
      dispatch({ type: SET_STATUS, payload: { status } });
    }
  }, [dispatch, nextStep, redirectUrl, screenId]);

  const screen = flows?.fidelityCardCreationFlow?.props?.screens?.[screenId];

  if (!screen) {
    return null;
  }

  return (
    <div className="steps__item container">
      {screen?.imageData && (
        <ContentfulRenderComponents components={[screen.imageData]} />
      )}
      <ContentSection title={screen?.title} content={screen?.desc} />
      {content && (
        <ContentfulRenderComponents components={content.components} />
      )}
      <Button
        onClick={validate}
        className="steps__item-validationBtn"
        loading={loading}
      >
        {screen?.btnLabel}
      </Button>
    </div>
  );
};

export default memo(Step);
