import React from 'react';
import './style.scss';
// import classnames from 'classnames';
import Title from '@/components/atoms/title';
// import Paragraph from '@/components/atoms/paragraph';
import { FieldErrors } from 'react-hook-form';
import FormSelect, {
  Option as FormSelectOption
} from '@/components/atoms/formSelect';
import t from '@/lib/i18n';
import { FieldFormProps, OrderTypeProps } from '../../formsTemplate';

export type OrderTypeSelectorProps = {
  field: FieldFormProps;
  setOrderType: (orderType: OrderTypeProps) => void;
  orderTypeSelected: OrderTypeProps | null;
  errors: FieldErrors;
};

const OrderTypeSelector = React.forwardRef(
  (
    { field, setOrderType, orderTypeSelected, errors }: OrderTypeSelectorProps,
    ref: any
  ): JSX.Element | null => {
    if (!orderTypeSelected) {
      return null;
    }
    const options: Array<FormSelectOption> = [
      {
        value: '',
        label: t('formContactUs.select.options.title')
      }
    ];
    orderTypeSelected?.orders.forEach((order) => {
      options.push({
        value: t(order),
        label: t(order)
      });
    });
    return (
      <div className="orderTypeSelector">
        <Title
          tag="h4"
          label={t('formContactUs.contact.orderDetail')}
          className="formContactUs__content__title"
        />
        {/* <Paragraph
          label={t('formContactUs.contact.orderType')}
          className="orderTypeSelector__labelSelectType"
        />
        {field.ordersList?.map((orderType: OrderTypeProps) => {
          return (
            <div
              className={classnames('orderTypeSelector__selectType', {
                'orderTypeSelector__selectType--selected':
                  orderTypeSelected?.label === orderType.label
              })}
              key={orderType.label}
              onClick={() => setOrderType(orderType)}
            >
              {t(orderType.label)}
            </div>
          );
        })} */}
        <div className="orderTypeSelector__selectWrap">
          <label
            htmlFor={orderTypeSelected?.label}
            className="orderTypeSelector__selectWrap__label"
          >
            {t('formContactUs.select.label')}
          </label>
          <FormSelect
            className="orderTypeSelector__selectWrap__select"
            options={options}
            errorMessage={t(errors[field.name]?.message)}
            name={field.name}
            ref={ref}
          />
        </div>
      </div>
    );
  }
);

export default OrderTypeSelector;
