import React from 'react';
import t from '@/lib/i18n';
import dayjs, { Dayjs } from 'dayjs';
import { Price } from '@/lib/model/price';
import { PromotionType } from '@/lib/model/productTypes';

type PromoItemProps = {
  data: PromotionType;
  prices: PricesType | null;
};

type HightlighDiscountType = {
  type: string;
  endDate: Dayjs;
};

type PromoDescriptionProps = {
  data: Array<PromotionType>;
  hightlight?: HightlighDiscountType | null;
  prices: PricesType | null;
};

type PricesType = {
  unitPrice: Price | null;
  productPrice: Price | null;
  crossedOutPrice: Price | null;
};

const PromoItem = ({ data, prices }: PromoItemProps) => {
  const crossedOutPrice = prices?.crossedOutPrice?.value || null;
  const productPrice = prices?.productPrice?.value || null;
  const quantityOfferedValue = Number(data.value);

  function getQuantity() {
    if (data.type === 'offeredDiscount') {
      return data.quantity && data.quantity + quantityOfferedValue;
    }
    return data.quantity;
  }

  function getDiscount() {
    if (data.type === 'offeredDiscount') {
      return productPrice && productPrice * quantityOfferedValue;
    }
    if (data.type === 'immediateDiscount') {
      return data.discount;
    }
    if (data.unit === 'percentage') {
      return (
        productPrice &&
        (productPrice * (Number(data.value) / 100)).toFixed(2).toString()
      );
    }
    return (
      crossedOutPrice &&
      (crossedOutPrice * (Number(data.value) / 100)).toFixed(2).toString()
    );
  }

  return (
    <li className="productDetail__promotions__item">
      {t(`promotions.${data.type}.description`, {
        '%value%':
          data.unit === 'percentage' || data.type === 'offeredDiscount'
            ? getDiscount()
            : data.value,
        '%qty%': data.quantity ? getQuantity() : '',
        '%endDate%': dayjs(data.endDate).format('DD/MM/YYYY')
      })}
    </li>
  );
};

const PromoDescription = ({
  data,
  hightlight,
  prices
}: PromoDescriptionProps) => {
  return (
    <ul className="productDetail__promotions__list">
      {data.map((promotion: PromotionType) => (
        <PromoItem
          key={`promo-description-${promotion.type}`}
          data={promotion}
          prices={prices}
        />
      ))}
      {hightlight && (
        <li className="productDetail__promotions__item">
          {t(`promotions.${hightlight.type}.description`, {
            '%endDate%': dayjs(hightlight.endDate).format('DD/MM/YYYY')
          })}
        </li>
      )}
    </ul>
  );
};

export default PromoDescription;
