import React from 'react';
import CategoriesApi from '@/lib/api/categories';
import { Request, Response } from 'express';
import CategoriesModel from '@/lib/model/categories';
import usePdv from '@/lib/hooks/usePdv';
import Categories, { CategoriesProps } from '../categories';

type InitialProps = {
  props: CategoriesProps;
  req: Request;
  res: Response;
};

const CategoriesESI = (props: CategoriesProps) => {
  return <Categories {...props} />;
};

CategoriesESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref } = usePdv(req);

  let categories: CategoriesModel | null = null;

  if (ref) {
    categories = await CategoriesApi.getNavByPdv(ref, 3);
  }

  return new Promise((resolve) => {
    if (res) {
      // 3 hours of cache
      res.set('Cache-Control', 's-maxage=10800, max-age=10800');
    }

    resolve({
      ...props,
      categories
    });
  });
};

export default CategoriesESI;
