import React, { useEffect, useRef, useState, useContext } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import CommonSprite from '@/assets/images/sprites/common.svg';
import CartSprite from '@/assets/images/sprites/cart.svg';
import Discount from '@/components/organisms/checkout/discount';
import CartAddresses from '@/components/organisms/checkout/addresses';
import OrderApi from '@/lib/api/order';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ConfirmationOrder, SellerOrder } from '@/lib/api/order/types';
import { getPdvRef } from '@/lib/hooks/usePdv';
import LoadableContent from '@/components/atoms/loadableContent';
import UserInformationApi from '@/lib/api/userInformation';
import { Informations } from '@/lib/api/userInformation/types';
import { deleteCart } from '@/store/cart/actions/cart';
import { useDispatch } from 'react-redux';
import useEvent from '@/lib/hooks/useEvent';
import { criteoConfirmOrderNew } from '@/lib/thirdPartyApi/confirmOrder/criteo';
import { ReactComponent as CartValidateIcon } from '@/assets/images/icons/cartValidate.svg';
import PageContext from '@/lib/providers/pageContext';

import {
  CollectorItemType,
  ServiceProvidersType,
  StateType as CartStateType
} from '@/store/cart/types';
import OrderSummary from './orderSummary';

dayjs.extend(duration);

export type SummaryProps = {
  userId?: string;
  cartStore: CartStateType;
};

type HeaderTradsType = {
  title: string;
  info: string;
  subinfo: string;
};

const Summary = ({ userId, cartStore }: SummaryProps) => {
  const dispatch = useDispatch();
  const event = useEvent();
  const [stateSummary, setStateSummary] = useState<{
    confirmation: ConfirmationOrder | null;
    layerIsVisible: boolean;
    user: Informations | null;
    provider: ServiceProvidersType | null;
    collectors: CollectorItemType[];
    headerTrads: HeaderTradsType | null;
  }>({
    confirmation: null,
    user: null,
    layerIsVisible: false,
    provider: null,
    collectors: [],
    headerTrads: null
  });
  const isMounted = useRef(true);
  const { referer } = useContext(PageContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pdvRef = getPdvRef();
    if (isMounted.current) {
      (async () => {
        if (!userId) return;
        const provider =
          cartStore.slots.list.delivery?.serviceProviders.find(
            (service) => service.id === cartStore.slots.selectedProvider?.id
          ) || (null as ServiceProvidersType | null);
        const collectors: CollectorItemType[] = [];
        cartStore.subCarts.forEach((subCart) => {
          collectors.push(...(subCart.valorisation?.collectors || []));
        });
        const user = await UserInformationApi.getInformations(userId);
        const confirmation = await OrderApi.getConfirmationOrder({
          userId,
          orderId: urlParams.get('orderNumber') || '',
          pdvRef
        });
        setStateSummary((state) => ({
          ...state,
          confirmation,
          user,
          provider,
          collectors,
          headerTrads: getHeaderTrads(confirmation?.orders)
        }));

        confirmation.orders?.forEach((selOrder) => {
          selOrder.ordersList.forEach((order) => {
            if (selOrder.seller.id === 'ITM') {
              criteoConfirmOrderNew(order);
            }

            event.send('confirmOrder', {
              order,
              cart: cartStore,
              referer,
              collectors
            });
          });
        });
      })();
    }
    isMounted.current = false;
  }, [isMounted, userId, dispatch, cartStore, event, referer]);

  useEffect(() => {
    return () => {
      dispatch(deleteCart('ITM'));
    };
  }, [dispatch]);

  const getHeaderTrads = (orders: Array<SellerOrder>): HeaderTradsType => {
    const itmOrderKO = orders?.some(
      (order) => order.seller.id === 'ITM' && order.hasStatusKO
    );
    const mkpOrderKO = orders?.some(
      (order) => order.seller.id !== 'ITM' && order.hasStatusKO
    );

    if (itmOrderKO) {
      return {
        title: t('cart.summary.title-ko'),
        info: `${t('cart.summary.info-itmKO')}`,
        subinfo: `${t('cart.summary.subinfo-itmKO')}`
      };
    }
    if (mkpOrderKO) {
      return {
        title: t('cart.summary.title-ko'),
        info: `${t('cart.summary.info-mkpKO')}`,
        subinfo: `${t('cart.summary.subinfo-mkpKO')}`
      };
    }
    return {
      title: t('cart.summary.title'),
      info: `${t('cart.summary.info')}`,
      subinfo: `${t('cart.summary.subinfo')}`
    };
  };

  let deliveryType = stateSummary.confirmation?.isDrive ? 'drive' : '';
  deliveryType = stateSummary.confirmation?.isDelivery
    ? 'delivery'
    : deliveryType;

  const defaultDelivery = stateSummary.confirmation?.shippingAddress;
  const isThereMkp = stateSummary.confirmation?.orders?.some(
    (order) => order.seller.id !== 'ITM'
  );
  const itmCart = cartStore.subCarts.find(
    (subCart) => subCart.seller.sellerId === 'ITM'
  );

  return (
    <div className="cartSummary">
      <LoadableContent
        loading={!stateSummary.confirmation}
        className="cartSummary__infos"
      >
        <section className="cartSummary__intro">
          <CartValidateIcon
            className="cartSummary__intro__picto"
            width="180"
            height="180"
          />
          <div className="cartSummary__intro__content">
            <strong
              className="cartSummary__intro__title"
              id="cartSummaryIntroTitle"
            >
              {stateSummary.headerTrads?.title}
            </strong>
            <p className="cartSummary__intro__text">
              {stateSummary.headerTrads?.info}
            </p>
            <p className="cartSummary__intro__text">
              {stateSummary.headerTrads?.subinfo}
            </p>
          </div>
        </section>
        <div className="cartSummary__content">
          {stateSummary.confirmation?.orders.map((order) => (
            <OrderSummary
              key={order.seller.id}
              selOrder={order}
              slots={cartStore.slots}
            />
          ))}

          <section className="cartSummary__section">
            {(stateSummary.confirmation?.shippingAddress ||
              stateSummary.confirmation?.billingAddress) && (
              <CartAddresses
                addressesList={{
                  delivery: stateSummary.confirmation?.shippingAddress
                    ? [stateSummary.confirmation?.shippingAddress]
                    : [],
                  billing: stateSummary.confirmation?.billingAddress
                    ? [stateSummary.confirmation?.billingAddress]
                    : []
                }}
                deliveryType={deliveryType}
                modifyIsEnable={false}
              />
            )}
            {defaultDelivery?.deliverymanInstructions &&
              (stateSummary.confirmation?.isDelivery || isThereMkp) && (
                <div className="cartInfo cartComment">
                  <svg className="cartInfo__picto" width="25" height="25">
                    <use xlinkHref={`${CartSprite}#validComment`} />
                  </svg>
                  <div className="cartInfo__content">
                    <div className="cartInfo__title">
                      {t('address.label.deliverymanInstructions')}
                    </div>
                    <div className="cartInfo__item">
                      {defaultDelivery?.deliverymanInstructions}
                    </div>
                  </div>
                </div>
              )}
          </section>
          <section className="cartSummary__section">
            <div className="cartInfo">
              <svg height="25" width="25">
                <use xlinkHref={`${CommonSprite}#user`} />
              </svg>
              <div className="cartInfo__content">
                <div className="cartInfo__title">
                  {t('cart.summary.cumulative')}
                </div>
              </div>
            </div>
            <Discount
              deferredDiscount={
                stateSummary.confirmation?.debitFid?.value ||
                itmCart?.valorisation?.deferredDiscount ||
                0
              }
              immediateDiscount={
                stateSummary.confirmation?.creditFid?.value || 0
              }
              collectors={stateSummary.collectors}
            />
          </section>
        </div>
        <div className="cartSummary__footer">
          <a href="/home" className="cart__link link--button" id="btn-cartBack">
            {t('cart.back')}
          </a>
        </div>
        <div
          data-regie-id="confirmation-bottom"
          className="cartSummary__LuckyCart-bottom"
        />
        <div className="cartSummary__footer__text">
          {t('cart.summary.footer.text1')}
        </div>
        <div className="cartSummary__footer__text">
          {t('cart.summary.footer.text2')}
        </div>
      </LoadableContent>
      <div
        data-regie-id="confirmation-right"
        className="cartSummary__LuckyCart-right"
      />
    </div>
  );
};

export default Summary;
