import React from 'react';
import { Provider } from 'react-redux';
import t from '@/lib/i18n';
import { store } from '@/store';
import Image from '@/components/atoms/image';
import CategoriesModel from '@/lib/model/categories';
import { ReactComponent as Promotion } from '@/assets/images/icons/promotion.svg';
import Tile from '@/components/atoms/tile';
import Title from '@/components/atoms/title';
import './style.scss';

export type TopCategoriesTilesProps = {
  categories?: CategoriesModel;
};

const TopCategoriesTilesContainer = ({
  categories
}: TopCategoriesTilesProps) => (
  <div className="categoriesContainer">
    <Title
      tag="h2"
      label={t('search.categories.title')}
      className="categoriesTitle"
    />
    <div className="categoriesList">
      {categories &&
        categories.tree.map(({ title, picto, link, id, isPromo }) => (
          <Tile
            key={id}
            isTextFirst
            className="categoryItem"
            imageContent={
              <div className="categoryItem__img">
                {isPromo && <Promotion width={65} height={65} />}
                {picto && (
                  <Image
                    src={picto}
                    alt={title}
                    width={65}
                    height={65}
                    lazy={false}
                  />
                )}
              </div>
            }
            textContent={
              <strong
                className="categoryItem__title"
                dangerouslySetInnerHTML={{
                  __html: title
                }}
              />
            }
            linkAttributes={{
              href: link,
              className: 'categoryItem__link'
            }}
          />
        ))}
    </div>
  </div>
);

const TopCategoriesTiles = ({ categories }: TopCategoriesTilesProps) => {
  return (
    <Provider store={store}>
      <TopCategoriesTilesContainer categories={categories} />
    </Provider>
  );
};

export default React.memo(TopCategoriesTiles);
