import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { DO_LOGOUT } from '@/store/auth/authActions';
import './style.scss';
import t from '@/lib/i18n';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Link from '@/components/atoms/link';
import useEvent from '@/lib/hooks/useEvent';

export type UserProps = {
  className?: string;
};

const User = ({ className }: UserProps) => {
  const [isActive, setIsActive] = useState(false);
  const user = useSelector(({ auth }: { auth: AuthStateType }) => auth.user);
  const dispatch = useDispatch();
  const event = useEvent();

  useEffect(() => {
    setIsActive(!!user);
  }, [user]);

  return (
    <div className={classnames('user', className)}>
      {!isActive && (
        <Link
          id="btn-headerUserLogin"
          href="/connection"
          onClick={() => event.send('header', { type: 'login' })}
          className="user__button"
        >
          <svg className="user__userIcon fill--black" height="30" width="30">
            <use xlinkHref={`${CommonSprite}#user`} />
          </svg>
          <div className="user__title">{t('header.user.connexion')}</div>
        </Link>
      )}
      {isActive && (
        <>
          <div className="user__button">
            <div className="user__icon">
              <svg
                className="user__userIcon fill--black"
                height="30"
                width="30"
              >
                <use xlinkHref={`${CommonSprite}#user`} />
              </svg>
              <svg
                className="user__checkIcon fill--white"
                height="10"
                width="10"
              >
                <use xlinkHref={`${CommonSprite}#check`} />
              </svg>
            </div>
            <div className="user__title">{t('header.user.account')}</div>
          </div>
          <div className="user__menu">
            <ul className="user__menu_content">
              <li className="user__menu_item">
                <Link
                  id="headerUserAccount"
                  label={t('header.user.my-account')}
                  href="/account-management/dashboard"
                />
              </li>
              <li className="user__menu_item">
                <Link
                  id="btn-headerUserLogout"
                  label={t('header.user.disconnect')}
                  onClick={() => {
                    dispatch({
                      type: DO_LOGOUT
                    });
                    event.send('api', {
                      name: 'logout',
                      type: 'success'
                    });
                  }}
                />
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default User;
