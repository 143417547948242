import React, { useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { store } from '@/store';
import { AuthStateType } from '@/store/auth/authReducer';
import UserInformationApi from '@/lib/api/userInformation';
import getConfigValue from '@/lib/config';
import LoadableContent from '@/components/atoms/loadableContent';
import StickersApi from '@/lib/api/stickers';
import { Operation } from '@/lib/api/stickers/types';

import StickersPresentationContainer from './stickersPresentationContainer';
import StickersOperations from './stickersOperations';

type MyStickersProps = {
  pdvIsActivated: boolean;
};

type StateTypeProps = {
  isLoading: boolean;
  operations: Array<Operation> | null;
};

const MyStickers = ({ pdvIsActivated }: MyStickersProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );

  const [state, setState] = useState<StateTypeProps>({
    isLoading: true,
    operations: null
  });

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }
      const data = await Promise.all([
        UserInformationApi.getInformations(userId),
        StickersApi.getOperations()
      ]);

      const userInfo = data[0];
      if (!userInfo || !userInfo.fidelityCardNumber) {
        window.location.href = getConfigValue(
          'OP_VIGNETTES_URL_NO_FIDCARD',
          '/fidelity-advantages'
        ).toString();
      }

      setState({
        isLoading: false,
        operations: data[1]
      });
    })();
  }, [userId]);

  return (
    <LoadableContent loading={state.isLoading}>
      <div className="myStickers">
        <StickersPresentationContainer pdvIsActivated={pdvIsActivated} />
        <StickersOperations
          operations={state.operations}
          pdvIsActivated={pdvIsActivated}
        />
      </div>
    </LoadableContent>
  );
};

const MyStickersContainer = ({ pdvIsActivated }: MyStickersProps) => {
  return (
    <Provider store={store}>
      <MyStickers pdvIsActivated={pdvIsActivated} />
    </Provider>
  );
};
export default MyStickersContainer;
