import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import Pdv from '@/lib/model/pdv';

class UserPdvApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  async getFavorites(userId: string): Promise<Array<Pdv>> {
    let response = null;

    try {
      response = await this.axios.get(
        `/gestiondecompte/v1/moncompte/${userId}/favorite-pdvs`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to get user favorite pdv',
        error
      });

      throw error;
    }

    const data: Array<Pdv> = [];

    response?.data.forEach((elt: any) => {
      data.push(new Pdv(elt));
    });

    return data;
  }

  async delete(userId: string, pdv: Pdv): Promise<void> {
    try {
      await this.axios.delete(
        `/gestiondecompte/v1/moncompte/${userId}/pdv/${pdv.idNetfective}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to delete user favorite pdv',
        context: {
          userId,
          pdvNtfId: pdv.idNetfective
        },
        error
      });

      throw error;
    }
  }
}

export default new UserPdvApi();
