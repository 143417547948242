import React, { useEffect, useRef, useState } from 'react';
import Slider from '@/components/molecules/slider';
import t from '@/lib/i18n';
import Button from '@/components/atoms/button';
import useEvent from '@/lib/hooks/useEvent';
import Modal from '@/components/organisms/modal';
import { CartItemType } from '@/store/cart/types';
import classnames from 'classnames';
import usePageView from '@/lib/hooks/usePageView';
import Alcohol from './slides/alcohol';
import Unavailables from './slides/unavailables';

export type CartModalProps = {
  validate: () => Promise<void>;
  close: () => void;
  open: boolean;
  isLoading: boolean;
  hasAlcoholProduct: boolean;
  unavailbleProducts: Array<CartItemType> | null;
  unavailblePromotion: Array<CartItemType> | null;
  outOfStock: Array<CartItemType> | null;
};

type StateTypeProps = {
  cartModalItems: Array<JSX.Element | null>;
  wordingValidate: string;
};

const getValidateWording = (itemsNb: number, hasAlcoholProduct: boolean) => {
  if (itemsNb > 1) {
    return hasAlcoholProduct ? 'cart.modal.getIt' : 'cart.modal.next';
  }
  return 'cart.modal.order';
};

const CartModal = ({
  validate,
  close,
  open,
  isLoading,
  unavailbleProducts,
  outOfStock,
  unavailblePromotion,
  hasAlcoholProduct
}: CartModalProps) => {
  const refSlider = useRef();
  const [state, setState] = useState<StateTypeProps>({
    cartModalItems: [],
    wordingValidate: ''
  });
  const event = useEvent();
  const pageView = usePageView();
  const sliderState: any = refSlider.current;

  useEffect(() => {
    if (open) {
      unavailbleProducts?.length &&
        event.send('checkout', {
          type: 'unavailableProducts'
        });
      unavailblePromotion?.length &&
        event.send('checkout', {
          type: 'promoExpired'
        });
    }
  }, [open, unavailbleProducts, unavailblePromotion, event]);

  useEffect(() => {
    open &&
      hasAlcoholProduct &&
      (!sliderState || sliderState.state.currentSlide === 0) &&
      pageView.send('CharteAlcool');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, hasAlcoholProduct]);

  useEffect(() => {
    const items: Array<JSX.Element> = [];

    if (hasAlcoholProduct) {
      items.push(<Alcohol key="alcohol" />);
    }

    if (unavailblePromotion?.length) {
      items.push(
        <Unavailables
          key="promotions"
          list={unavailblePromotion}
          type="promotions"
        />
      );
    }

    if (outOfStock?.length) {
      items.push(<Unavailables key="stock" list={outOfStock} type="stock" />);
    }

    if (unavailbleProducts?.length) {
      items.push(
        <Unavailables
          key="products"
          list={unavailbleProducts}
          type="products"
        />
      );
    }

    setState({
      cartModalItems: items,
      wordingValidate: t(getValidateWording(items.length, hasAlcoholProduct))
    });
  }, [unavailbleProducts, unavailblePromotion, hasAlcoholProduct, outOfStock]);

  const CustomDot = ({
    active,
    index
  }: {
    active?: boolean;
    index?: number;
  }) => {
    return (
      <li
        className={classnames('react-multi-carousel-dot', {
          'react-multi-carousel-dot--active': active
        })}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
          }}
          className="cartModal__customDot"
        >
          {index}
        </button>
      </li>
    );
  };

  return (
    <Modal
      scrollable={false}
      className="cartModal"
      closable={false}
      onClose={close}
      open={open}
    >
      <div className="cartModal__slider">
        <Slider
          swipeable={false}
          draggable={false}
          customDot={<CustomDot />}
          afterChange={() => {
            const slider: any = refSlider.current;
            setState((oldState) => ({
              ...oldState,
              wordingValidate: t(
                slider.state.currentSlide + 1 === slider.state.totalItems
                  ? 'cart.modal.order'
                  : 'cart.modal.next'
              )
            }));
          }}
          ref={refSlider}
          type="primary"
          arrows={false}
        >
          {state.cartModalItems}
        </Slider>
      </div>
      <div className="cartModal__actions">
        <Button
          onClick={() => {
            event.send('enseigne', {
              type: 'goBack'
            });
            setState((oldState) => ({
              ...oldState,
              wordingValidate: t(
                getValidateWording(
                  oldState.cartModalItems.length,
                  hasAlcoholProduct
                )
              )
            }));
            close();
          }}
          color="secondary"
          id="btn-cartModalUpdate"
        >
          {t(
            hasAlcoholProduct &&
              (!sliderState || sliderState.state.currentSlide === 0)
              ? 'common.back'
              : 'cart.modal.update'
          )}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => {
            const slider: any = refSlider.current;
            hasAlcoholProduct &&
              (!sliderState || sliderState.state.currentSlide === 0) &&
              event.send('enseigne', {
                type: 'understood'
              });
            if (slider.state.currentSlide + 1 === slider.state.totalItems) {
              unavailbleProducts?.length &&
                event.send('checkout', {
                  type: 'removeUnavailableProducts',
                  productId: unavailbleProducts
                    ?.map(({ product }) => product.id)
                    .join('::')
                });
              validate();
            } else {
              slider.next();
            }
          }}
          id="btn-cartModalValidate"
        >
          {state.wordingValidate}
        </Button>
      </div>
    </Modal>
  );
};

export default CartModal;
