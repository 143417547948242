import React from 'react';
import PdvApi from '@/lib/api/pdv';
import { Response, Request } from 'express';
import Pdv from '@/lib/model/pdv';
import usePdv from '@/lib/hooks/usePdv';
import Content, { ContentProps } from '../content';

type InitialProps = {
  props: ContentProps;
  req: Request;
  res: Response;
};

const ContentESI = (props: ContentProps) => {
  return <Content {...props} />;
};

ContentESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref } = usePdv(req);

  let pdv: Pdv | null = null;

  if (ref) {
    pdv = await PdvApi.getPdvInfo(ref);
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=21600, max-age=21600');
    }

    resolve({
      ...props,
      city: pdv?.address?.city
    });
  });
};

export default ContentESI;
