import { Slide } from '@/components/templates/homepage/categorySlider';
import { NormalizeParams } from '..';

const normalize = ({ data, includes }: NormalizeParams): Slide | null => {
  const element: Slide = {
    title: data.libelle,
    type: data.typeCategorie,
    image: '',
    categoryId: null,
    allCategories: false
  };

  const image = includes[data.image.sys.id] || null;

  if (!image) {
    return null;
  }

  element.image = image.fields.file.url;

  if (['image/jpg', 'image/jpeg'].includes(image.fields.file.contentType)) {
    element.image += '?fm=jpg&fl=progressive';
  }

  if (data.typeCategorie === 'tous les rayons') {
    element.allCategories = true;
  } else {
    element.categoryId = data.idCategorie;
  }

  return element as Slide;
};

export default normalize;
