import React from 'react';
import SlotsPublicApi from '@/lib/api/slots/public';
import { Request, Response } from 'express';
import usePdv from '@/lib/hooks/usePdv';
import Slot from '@/lib/model/slot';
import SlotPdv, { SlotsProps } from '../slotPdv';

type InitialProps = {
  props: {
    pdvRef?: string;
  };
  req: Request;
  res: Response;
};

const SlotsPdvESI = (props: SlotsProps): JSX.Element | null => {
  return <SlotPdv {...props} />;
};

SlotsPdvESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  let slots: Array<Slot> = [];
  let ref = props.pdvRef;

  if (!ref) {
    ref = usePdv(req).ref;
  }

  if (ref) {
    slots = await SlotsPublicApi.getPdvSlots(ref);
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=600, max-age=600');
    }

    let slotsProps;

    if (slots) {
      slotsProps = slots.map((slot) => {
        return {
          type: slot.type,
          date: slot.date,
          time: slot.time,
          status: slot.status
        };
      });
    }

    resolve({
      ...props,
      slots: slotsProps,
      pdvRef: ref
    });
  });
};

export default SlotsPdvESI;
