import { Component, NormalizeParams, Content } from '..';

const normalize = ({ data }: NormalizeParams): Component => {
  const { text = '', href = '' } = data;

  return {
    type: 'atoms',
    name: 'link',
    props: {
      href,
      label: text
    }
  };
};

export default normalize;

export type CtaConditionProps = Content;
