import React from 'react';
import { NextSeo } from 'next-seo';
import classnames from 'classnames';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import './style.scss';
import Header from '@/components/organisms/header';
import Layout from '@/components/organisms/layout';

export type ContentSectionPagesProps = {
  page: ContentfulContent;
  footer: ContentfulContent;
  className?: string;
  pageView?: string;
};

const ContentSectionPages = ({
  page,
  footer,
  className,
  pageView
}: ContentSectionPagesProps) => {
  const headerPart = <Header />;

  const contentPart = page && (
    <div className={classnames('contentSectionPages', className)}>
      <ContentfulRenderComponents components={page.components} />
    </div>
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <>
      {page?.meta && (
        <NextSeo
          title={page.meta.title ?? ''}
          description={page.meta.description ?? ''}
        />
      )}
      <Layout
        content={contentPart}
        header={headerPart}
        footer={footerPart}
        pageView={pageView}
      />
    </>
  );
};

export default ContentSectionPages;
