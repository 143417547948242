import { ContentSectionProps } from '@/components/molecules/contentSection';
import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): ComponentProps => {
  return {
    type: 'molecules',
    name: 'contentSection',
    props: {
      content: data.label || '',
      className: 'cmsAttachment'
    }
  };
};

export default normalize;

export interface ComponentProps extends Component {
  props: ContentSectionProps;
}
