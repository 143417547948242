import React, { Key, MouseEvent } from 'react';
import classnames from 'classnames';
import CategoryModel from '@/lib/model/category';
import Link from '@/components/atoms/link';
import { ReactComponent as Promotion } from '@/assets/images/icons/promotion.svg';
import Image from '@/components/atoms/image';
import CommonSprite from '@/assets/images/sprites/common.svg';
import './style.scss';

export type NavigationCategoriesListProps = {
  data: Array<CategoryModel>;
  goto: (event: MouseEvent<any>, category: CategoryModel) => void;
  classNames?: string;
  selectedCategory?: CategoryModel | null;
  parentCategory?: CategoryModel | null;
  viewAllEnabled?: boolean;
};

const NavigationCategoriesList = ({
  data,
  goto,
  classNames,
  selectedCategory,
  parentCategory,
  viewAllEnabled = false
}: NavigationCategoriesListProps) => {
  return (
    <ul className={classnames('NavigationCategoriesList', classNames)}>
      {viewAllEnabled && parentCategory && (
        <li className="NavigationCategoriesList__item NavigationCategoriesList__item--viewAll">
          <Link
            className="NavigationCategoriesList__link"
            href={parentCategory.link}
          >
            <span className="NavigationCategoriesList__link__label">
              {parentCategory.title}
            </span>
            <span className="NavigationCategoriesList__link__viewAll">
              Ver tudo
            </span>
          </Link>
        </li>
      )}
      {data.map((category: CategoryModel, index: Key) => {
        const key = `category-${index}`;
        return (
          <li
            key={key}
            className={classnames('NavigationCategoriesList__item', {
              'NavigationCategoriesList__item--promo': category.isPromo,
              'NavigationCategoriesList__item--isActive':
                category.id === selectedCategory?.id
            })}
          >
            {category?.level === 2 && (
              <div className="NavigationCategoriesList__level2" />
            )}
            <Link
              onClick={(event: MouseEvent<any>) => {
                goto(event, category);
              }}
              className="NavigationCategoriesList__link"
              href={category.link}
            >
              <>
                {category.level === 1 && (
                  <NavigationCategoryPicto category={category} />
                )}
                <span className="NavigationCategoriesList__link__label">
                  {category.title}
                </span>
                {(!!category.children?.length || category.isPromo) && (
                  <span className="NavigationCategoriesList__link__rightArrow">
                    <svg width="16px" height="16px">
                      <use xlinkHref={`${CommonSprite}#arrow-right`} />
                    </svg>
                  </span>
                )}
              </>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default NavigationCategoriesList;

type NavigationCategoryPictoProps = {
  category: CategoryModel;
};

const NavigationCategoryPicto = ({
  category
}: NavigationCategoryPictoProps) => {
  if (!category.isPromo && !category.picto) return null;
  return (
    <span className="NavigationCategoryPicto">
      {category.isPromo && <Promotion width="40px" height="40px" />}
      {category.picto && (
        <Image
          src={category.picto}
          alt={category.title}
          width={40}
          height={40}
        />
      )}
    </span>
  );
};
