/* eslint  class-methods-use-this: off */
import {
  AvailablePaymentsType,
  SlotListType,
  SlotsType,
  SlotType,
  CartType,
  SubCartType,
  MkpSlotsType,
  PackageType
} from '@/store/cart/types';
import Date from '@/lib/utils/date';
import { SLOT_TYPE_MAPPING } from '@/lib/model/slot';
import { NormalizerInterface } from '..';
import {
  DeliveryItemsApi,
  DeliveryItemApi,
  DeliveryCartApi,
  PackagesMkpApi,
  MkpSlotTypeApi,
  MkpAllowedShippingTypesApi
} from './types';

class Normalizer implements NormalizerInterface {
  normalize(cart: CartType, pdv: string): DeliveryCartApi {
    const createProduct = (subCart: SubCartType): DeliveryItemsApi => {
      const itemsTemp: Array<DeliveryItemApi> = Object.keys(subCart.items).map(
        (id: string) => {
          const item = subCart.items[id];
          return {
            id: item.product.id,
            itemParentId: item.product.itemParentId,
            ...(item.product?.lot?.type?.toUpperCase() !== 'VIRTUEL' && {
              lotIds:
                item.product?.lot?.products?.map((product) => product.id) || []
            }),
            item: {
              idProduit: item.product.ean,
              itemId: item.product.id
            },
            modificationDate: Date().format('YYYY-MM-DDTHH:mm:ssZ'),
            quantity: item.qty,
            catalog: item.product.type,
            amount: item.product.prices.productPrice?.value,
            acceptSubstitution: item.acceptSubstitution,
            comment: item.comment
          };
        }
      );

      return {
        items: itemsTemp,
        catalog: subCart.type,
        amount: subCart.total,
        itemsNumber: subCart.qty,
        seller: {
          id: subCart.seller.sellerId,
          name: subCart.seller.sellerName
        }
      };
    };

    return {
      synchronizeDateTime: cart.synchronizedAt,
      amount: cart.total,
      itemsNumber: cart.qty,
      storeId: pdv,
      carts: cart.subCarts.map((subCart) => createProduct(subCart))
    };
  }

  denormalize(data: any, cart: CartType): SlotsType {
    const createSlotsResponse = (slot: any) => {
      const { deliveryType } = slot;
      const res: SlotListType = {
        days: slot.days?.map((day: any) => {
          return {
            slots: day.timeSlots.map((s: any) => {
              let period = 'evening';
              if (s.deliveryTimeSlotStartDateTime.split(':')[0] <= '11') {
                period = 'morning';
              }

              if (
                s.deliveryTimeSlotStartDateTime.split(':')[0] >= '12' &&
                s.deliveryTimeSlotStartDateTime.split(':')[0] < '18' &&
                s.deliveryTimeSlotEndDateTime.split(':')[0] <= '19'
              ) {
                period = 'afternoon';
              }

              const closedShelf: {
                closedEans: Array<string>;
                closedShelvesNames: Array<string>;
              } = s.saleDepartments.reduce(
                (
                  acc: {
                    closedEans: Array<string>;
                    closedShelvesNames: Array<string>;
                  },
                  saleDepartment: any
                ) => {
                  if (saleDepartment.status === 'FERME') {
                    if (
                      !acc.closedShelvesNames.includes(saleDepartment.label)
                    ) {
                      acc.closedShelvesNames.push(saleDepartment.label);
                    }
                    acc.closedEans = acc.closedEans.concat(
                      saleDepartment.items
                    );
                  }
                  return acc;
                },
                { closedEans: [], closedShelvesNames: [] }
              );

              let result: SlotType = {
                day: day.date,
                period,
                fees: s.costs,
                freeFees: s.costsOffered,
                startTime: s.deliveryTimeSlotStartDateTime,
                endTime: s.deliveryTimeSlotEndDateTime,
                idSlot: s.timeSlotId,
                status: s.status,
                periodType: s.periodType,
                minimalCost: s.minimumCosts,
                ...closedShelf
              };
              if (s.deliveryProvider) {
                result = {
                  ...result,
                  providers: s.deliveryProvider.map((provider: any) => ({
                    type: JSON.parse(provider.privateData).carrierType,
                    id: provider.deliveryProviderId,
                    privateData: provider.privateData,
                    deliveryCost: {
                      cost: provider.deliveryCost.cost,
                      volumeExtraTax: provider.deliveryCost.volumeExtraTax,
                      housingExtraTax: provider.deliveryCost.housingExtraTax,
                      totalCost: provider.deliveryCost.totalCost
                    }
                  }))
                };
              }
              return result;
            }),
            day: day.date,
            isSelected: false
          };
        }),
        serviceProviders: [],
        state: slot.serviceStatus,
        minimumOrderAmount: slot.minimumOrderAmount,
        maxLiquidVolume: slot.maxVolume,
        deliveryType: SLOT_TYPE_MAPPING[deliveryType],
        isMovingForThePlanet: slot.isMovingForThePlanet ?? false,
        availablePayments: slot.availablePaymentTypes?.reduce(
          (
            payment: AvailablePaymentsType,
            current: { paymentType: string; paymentMethod: string }
          ) => {
            return {
              ...payment,
              [current.paymentType]: payment[current.paymentType]
                ? [...payment[current.paymentType], current.paymentMethod]
                : [current.paymentMethod]
            };
          },
          {}
        ),
        volumeCosts: slot.volumeCosts,
        housingCosts: slot.housingCosts
      };

      if (slot.providerMetaData) {
        res.serviceProviders = slot.providerMetaData.map((provider: any) => ({
          descriptionDesktop: provider.desktopDescription,
          descriptionMobile: provider.mobileDescription,
          id: provider.id,
          desktopTitle: provider.desktopLabel,
          mobileTitle: provider.mobileLabel,
          logo: provider.logoUrl,
          type: provider.providerType
        }));
      }

      res.housingExtraCostAvailable =
        slot.housingExtraCostAmountEnabled ?? false;
      res.housingExtraCost = slot.housingExtraCostAmount;
      res.floorMinExtraCost = slot.floorType;
      res.VolumeExtraCostAvailable = slot.volumeExtraCostAmountEnabled;
      res.volumeExtraCost = slot.volumeExtraCostAmount;
      res.volumeMinExtraCost = slot.extraCostVolume;

      return res;
    };

    const cartItm = data.carts.find(
      (cartTemp: any) => cartTemp.seller.id === 'ITM'
    );
    const cartsMkp = data.carts.filter(
      (cartTemp: any) => cartTemp.seller.id !== 'ITM'
    );

    const mkpPackages: { [key: string]: MkpSlotsType } = {};

    cartsMkp.forEach((cartMkp: any) => {
      const packagesMkp: PackagesMkpApi = cartMkp.delivery;
      const sellerSubCart = cart.subCarts.find(
        (subCart) => subCart.seller.sellerId === cartMkp.seller.id
      );
      if (sellerSubCart) {
        const PackagesTemp: { [key: string]: PackageType } = {};
        packagesMkp.packages.forEach(
          (packageMkp: MkpSlotTypeApi, index: number) => {
            PackagesTemp[index.toString()] = {
              serviceProviders: packageMkp.allowedShippingTypes.map(
                (allowedShippingType: MkpAllowedShippingTypesApi) => ({
                  id: allowedShippingType.id,
                  name: allowedShippingType.name,
                  deliveryCost: allowedShippingType.price,
                  deliveryTime: allowedShippingType.deliveryTime,
                  totalDeliveryTime: allowedShippingType.totalDeliveryTime,
                  leadTimeToShip: packageMkp.leadTimeToShip
                })
              ),
              items: packageMkp.items.map(
                (item) => sellerSubCart.items[item.id]
              )
            };
          }
        );
        mkpPackages[cartMkp.seller.id] = {
          packages: PackagesTemp,
          seller: {
            sellerId: sellerSubCart.seller.sellerId,
            sellerName: sellerSubCart.seller.sellerName
          }
        };
      } else {
        throw new Error(`sub cart not found for seller : ${cartMkp.seller.id}`);
      }
    });

    const result: SlotsType = {
      discountCodeActive: cartItm?.valuations?.discountStatus === 'SUCCESS',
      vouchersCodeActive: cartItm?.valuations?.loyaltyStatus === 'SUCCESS',
      data: {},
      mkpPackages,
      metaData: {
        deliveryMethods: data.metaData?.invalid?.deliveryMethods,
        volume: data.metaData?.invalid?.volume
      },
      defaultPayments: data?.defaultPayments
    };

    if (cartItm?.delivery?.deliveryMethods?.drive) {
      result.data.drive = createSlotsResponse(
        cartItm?.delivery?.deliveryMethods?.drive
      );
    }
    if (cartItm?.delivery?.deliveryMethods?.drive24) {
      result.data.drive24 = createSlotsResponse(
        cartItm?.delivery?.deliveryMethods?.drive24
      );
    }
    if (cartItm?.delivery?.deliveryMethods?.homeDelivery) {
      result.data.delivery = createSlotsResponse(
        cartItm?.delivery?.deliveryMethods?.homeDelivery
      );
    }
    if (cartItm?.delivery?.deliveryMethods?.storePickUp) {
      result.data.drivepieton = createSlotsResponse(
        cartItm?.delivery?.deliveryMethods?.storePickUp
      );
    }

    return result;
  }
}

export default new Normalizer();
