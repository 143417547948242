import React from 'react';
import '../style.scss';
import Button from '@/components/atoms/button';
import FormInput from '@/components/atoms/formInput';
import { useForm } from 'react-hook-form';
import t from '@/lib/i18n';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { PaymentStateType, StateType } from '@/store/cart/types';
import Loader from '@/components/atoms/loader';
import { UPDATE_SELECTED_FID } from '@/store/cart/cartActions';
import DiscountCode from '@/lib/model/discountCode';
import { isValidFid, getItmTotalCart } from '@/lib/utils/cart';
import { isFloat } from '@/lib/utils/stringUtils';
import useEvent from '@/lib/hooks/useEvent';

export type FidelityProps = {
  cart: StateType;
  paymentState: PaymentStateType;
  setPaymentState: any;
  discountCode: DiscountCode | null;
};

const Fidelity = ({
  paymentState,
  cart,
  setPaymentState,
  discountCode = null
}: FidelityProps) => {
  const totalFid = parseFloat(
    `${paymentState.fidelity?.onlineUsableBalance?.amount.integer}.${paymentState.fidelity?.onlineUsableBalance?.amount.decimal}`
  );

  const fidForm = useForm<any>({
    defaultValues: {
      fid: null
    }
  });

  const event = useEvent();

  const dispatch = useDispatch();
  return (
    <section
      className={classNames('payment__section payment__fidelity', {
        'payment__section payment__fidelity--hide':
          !paymentState.fidelityIsLoading && !paymentState.fidelity
      })}
    >
      <div className="payment__fidelity__content">
        <strong className="payment__fidelity__title">
          {t('cart.payment.fidelity.title')}
        </strong>
        <p className="payment__fidelity__text">
          {t('cart.payment.fidelity.text')}
        </p>
      </div>
      <div className="payment__fidelity__actions">
        {cart.selectedFID > 0 && (
          <Button
            className="payment__fidelity__cancel"
            label={t('common.cancel')}
            isLink={true}
            onClick={() => {
              dispatch({
                type: UPDATE_SELECTED_FID,
                payload: 0
              });
              setPaymentState((state: PaymentStateType) => ({
                ...state,
                selectedInputFID: false
              }));
            }}
          />
        )}

        {paymentState.fidelityIsLoading && <Loader size="medium" />}
        {!paymentState.fidelityIsLoading && paymentState.fidelity && (
          <>
            <span className="payment__fidelity__price">
              {t('cart.summary.price', {
                '%price%': paymentState.fidelity?.onlineUsableBalance?.amount
                  .value
                  ? String((totalFid - cart.selectedFID).toFixed(2)).replace(
                      '.',
                      ','
                    )
                  : '0'
              })}
            </span>
            <div className="payment__fidelity__buttons">
              <Button
                disabled={totalFid === cart.selectedFID}
                onClick={() => {
                  let fidResult;
                  event.send('checkout', {
                    type: 'fidelity',
                    fidAmount: totalFid
                  });
                  if (!isValidFid(cart, discountCode, totalFid)) {
                    fidResult =
                      totalFid -
                      (totalFid - getItmTotalCart(cart, discountCode) + 1);
                  } else {
                    fidResult = totalFid;
                  }
                  dispatch({
                    type: UPDATE_SELECTED_FID,
                    payload: parseFloat(fidResult.toFixed(2))
                  });
                  setPaymentState((state: PaymentStateType) => ({
                    ...state,
                    selectedInputFID: false
                  }));
                }}
              >
                {t('cart.payment.fidelity.use')}
              </Button>
              {!paymentState.selectedInputFID && (
                <Button
                  disabled={totalFid === cart.selectedFID}
                  color="thirdary"
                  onClick={() => {
                    setPaymentState((state: PaymentStateType) => ({
                      ...state,
                      selectedInputFID: true
                    }));
                  }}
                >
                  {t('cart.payment.fidelity.indicate')}
                </Button>
              )}
              {paymentState.selectedInputFID && (
                <form
                  onSubmit={fidForm.handleSubmit(({ fidInput }) => {
                    event.send('checkout', {
                      type: 'fidelity',
                      fidAmount: fidInput
                    });
                    dispatch({
                      type: UPDATE_SELECTED_FID,
                      payload: parseFloat(fidInput.replace(',', '.')).toFixed(2)
                    });
                  })}
                >
                  <FormInput
                    buttonLabel={t('common.use')}
                    autoFocus={true}
                    id="fidInput"
                    name="fidInput"
                    placeholder="0,00"
                    disabled={totalFid === cart.selectedFID}
                    ref={fidForm.register({
                      validate: {
                        fidEmpty: (value) => !!value,
                        fidIsNumber: (value) => isFloat(value),
                        maxFid: async (value) => {
                          const valueToNumber = Number(value.replace(',', '.'));
                          return valueToNumber < totalFid;
                        },
                        toomuch: (value) => {
                          const valueToNumber = Number(value.replace(',', '.'));
                          return isValidFid(cart, discountCode, valueToNumber);
                        }
                      }
                    })}
                    errorMessage={
                      fidForm.errors.fidInput
                        ? t(`form.error.${fidForm.errors.fidInput.type}`)
                        : ''
                    }
                  />
                </form>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Fidelity;
