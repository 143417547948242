import { ReactComponent as EmptyIcon } from '@/assets/images/icons/cartValidate.svg';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import Link from '@/components/atoms/link';
import LoadableContent from '@/components/atoms/loadableContent';
import Select, { Option, Options } from '@/components/atoms/select';
import Modal from '@/components/organisms/modal';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import orderApi from '@/lib/api/order';
import { MkpOrder, Order, OrderItem } from '@/lib/api/order/types';
import { Content as ContentfulContent } from '@/lib/contentful';
import useEvent from '@/lib/hooks/useEvent';
import t from '@/lib/i18n';
import { notification } from '@/lib/notification';
import Date from '@/lib/utils/date';
import { fixDecimalCost } from '@/lib/utils/stringUtils';
import useShallowEqualSelector from '@/lib/utils/useShallowEqualSelector';
import { updateCartQuantity } from '@/store/cart/actions/cart';
import { SubCartType } from '@/store/cart/types';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './style.scss';

export type OrdersProps = {
  footer: ContentfulContent;
  pageView: string;
};

type Sort = {
  name: 'year' | 'countMonths';
  value: number;
};

type StateType = {
  sort: Sort;
  isLoading: boolean;
  ordersType: 'ITM' | 'PARTNER';
  oldOrders: Array<Order>;
  currentOrders: Array<Order>;
  mkpOrders: Array<MkpOrder>;
  paginatedOrders: Array<Order>;
  ordersPerPage: number;
  currentPage: number;
  hasNextPage: boolean;
  isOpenOrder: boolean;
  isLoadingOrder: boolean;
  availableProducts: number | null;
  orderItems: Array<OrderItem>;
  oldOrderItems: Array<OrderItem>;
  filteredOrderPrice: string | null;
  orderPrice: string | null;
};

const Orders = ({ footer, pageView }: OrdersProps) => {
  const { userId, cartStore } = useShallowEqualSelector(({ auth, cart }) => {
    return {
      userId: auth.user?.id,
      cartStore: cart
    };
  });

  const dispatch = useDispatch();
  // const [pv, setPageView] = useState(pageView);
  const event = useEvent();
  const [state, setState] = useState<StateType>({
    isLoading: true,
    sort: {
      name: 'countMonths',
      value: 3
    },
    ordersType: 'ITM',
    currentOrders: [],
    oldOrders: [],
    mkpOrders: [],
    paginatedOrders: [],
    ordersPerPage: 5,
    currentPage: 1,
    hasNextPage: false,
    oldOrderItems: [],
    orderItems: [],
    isOpenOrder: false,
    isLoadingOrder: false,
    availableProducts: null,
    filteredOrderPrice: null,
    orderPrice: null
  });

  const load = useCallback(async () => {
    if (!userId) return;

    let currentOrders: Array<Order> = [];
    let oldOrders: Array<Order> = [];
    const mkpOrders: any = [];
    let ordersType: 'ITM' | 'PARTNER' = 'ITM';

    setState((oldState) => ({
      ...oldState,
      isLoading: true
    }));

    const req = {
      inProgress: false,
      passed: true,
      [state.sort.name]: state.sort.value,
      count: 9999
    };

    if (state.sort.name === 'countMonths') {
      req.year = Number(Date().year());
    }

    try {
      oldOrders = await orderApi.list(userId, req);
      // mkpOrders = await orderApi.mkpList(userId);
      currentOrders = await orderApi.list(userId, {
        inProgress: true
      });
    } catch (error: any) {
      notification.error(t('notification.erreur'));
    }
    if (
      mkpOrders.length > 0 &&
      oldOrders.length === 0 &&
      currentOrders.length === 0
    ) {
      ordersType = 'PARTNER';
    }
    setState((oldState) => ({
      ...oldState,
      isLoading: false,
      oldOrders,
      currentOrders,
      mkpOrders,
      paginatedOrders: oldOrders.slice(0, oldState.ordersPerPage),
      hasNextPage: oldOrders.length > oldState.ordersPerPage,
      ordersType
    }));
  }, [state.sort, userId]);

  const openAddOrderModal = async (orderId: string, pdvRef: string) => {
    if (!userId) return;
    setState((oldState) => ({
      ...oldState,
      isOrderLoading: true
    }));
    const itmCart = cartStore.subCarts.find(
      (subCart: SubCartType) => subCart.seller.sellerId === 'ITM'
    );

    try {
      const selectedOrder = await orderApi.read(userId, orderId, pdvRef);
      const items = selectedOrder.items.map((item) => {
        const quantity = itmCart?.items[item.product.id]?.qty
          ? itmCart?.items[item.product.id].qty + item.quantity
          : item.quantity;
        return {
          ...item,
          sellerId: 'ITM',
          quantity
        };
      });
      const availableProducts = items.filter(
        (item) => !!item.product.maxQty && item.product.available
      );
      const filteredOrderPrice =
        availableProducts && availableProducts.length > 0
          ? availableProducts
              ?.map(({ total }) => total)
              .reduce((acc, product) => {
                return acc + product;
              })
          : 0;

      setState((oldState) => ({
        ...oldState,
        isOrderLoading: false,
        isOpenOrder: true,
        oldOrderItems: selectedOrder.items,
        orderItems: items,
        availableProducts: availableProducts?.length
          ? availableProducts.length
          : null,
        filteredOrderPrice: fixDecimalCost(filteredOrderPrice),
        orderPrice: fixDecimalCost(selectedOrder?.total?.value)
      }));
    } catch (error) {
      notification.error(t('notification.erreur'));
    }
  };

  const reorder = async () => {
    try {
      await dispatch(updateCartQuantity(state.orderItems));
    } catch (error: any) {
      // Todo: handle user error display unable to add order items to cart
    }
  };

  useEffect(() => {
    (async () => {
      if (!userId) return;

      await load();
    })();
  }, [load, userId, state.sort]);

  const handleNextPage = () => {
    const currentPage = state.currentPage + 1;

    setState((oldState) => ({
      ...oldState,
      paginatedOrders: oldState.oldOrders.slice(
        0,
        oldState.ordersPerPage * currentPage
      ),
      currentPage,
      hasNextPage: !(
        oldState.ordersPerPage * currentPage >
        oldState.oldOrders.length
      )
    }));
  };

  const years = [];
  const currentYear = Number(Date().year());
  for (let year = currentYear - 5; year < currentYear; year++) {
    years.push({
      value: year,
      label: year.toString(),
      selected: state.sort.value === year && state.sort.name === 'year',
      extra: {
        name: 'year',
        value: year
      }
    });
  }
  const reversedYears = years.reverse();

  const ordersHeader = (
    <div className="myAccountOrders__header">
      <div className="myAccountOrders__title">
        {t('myAccount.orders.title')}
      </div>
    </div>
  );

  const ordersSelectHeader = (
    <div className="myAccountOrders__selector">
      <Select
        className="myAccountOrders__filter"
        name="sort"
        options={[
          {
            value: 3,
            label: t('myAccount.orders.month', { '%count%': 3 }),
            extra: {
              name: 'countMonths',
              value: 3
            },
            selected:
              state.sort.value === 3 && state.sort.name === 'countMonths'
          },
          {
            value: 6,
            label: t('myAccount.orders.month', { '%count%': 6 }),
            extra: {
              name: 'countMonths',
              value: 6
            },
            selected:
              state.sort.value === 6 && state.sort.name === 'countMonths'
          },
          ...reversedYears
        ]}
        apply={(name: string, values: Option | Options | null) => {
          if (Array.isArray(values)) return;

          event.send('account', {
            type: 'ordersFilter',
            filterType: values?.label
          });

          setState((oldState) => ({
            ...oldState,
            sort: values?.extra as Sort
          }));
        }}
        label={t('myAccount.orders.sort')}
      />
    </div>
  );

  /*  const activateTab = (type: 'ITM' | 'PARTNER') => {
    setState((s) => ({ ...s, ordersType: type }));
    setPageView(type === 'PARTNER' ? 'OrdersPartnerHistory' : pageView);
  }; */

  const sortOrdersById = (arr: MkpOrder[]) => {
    return arr.sort((orderA, orderB) => {
      const orderADate = dayjs(orderA.createdDate);
      const orderBDate = dayjs(orderB.createdDate);
      const diff = orderBDate.diff(orderADate, 'minute');

      if (diff > 0) return 1;
      if (diff < 0) return -1;
      return orderB.id > orderA.id ? -1 : 1;
    });
  };

  const contentPart = (
    <LoadableContent className="myAccountOrders" loading={state.isLoading}>
      <>
        {/*     <div className="myAccountOrders__tabs">
          <div
            className={classnames('tab', classnames, {
              'tab--isActive': state.ordersType === 'ITM'
            })}
            onClick={() => activateTab('ITM')}
          >
            {t('myAccount.orders.type.itm')}
          </div>
          <div
            className={classnames('tab', classnames, {
              'tab--isActive': state.ordersType === 'PARTNER'
            })}
            onClick={() => activateTab('PARTNER')}
          >
            {t('myAccount.orders.type.partner')}
          </div>
        </div> */}
        {ordersSelectHeader}
        {state.ordersType === 'ITM' &&
          (!!state.currentOrders.length || !!state.oldOrders.length) && (
            <>
              <ul className="myAccountOrders__content">
                {state.currentOrders.map((order) => {
                  if (
                    ['refused', 'delivered', 'canceled', 'withdrawal'].includes(
                      order.status
                    )
                  ) {
                    return null;
                  }

                  const key = `order-${order.id}`;

                  return (
                    <li
                      key={key}
                      className={classnames(
                        'myAccountOrders__order',
                        'myAccountOrders__order--current',
                        `myAccountOrders__order--${order.status}`
                      )}
                    >
                      <div className="myAccountOrders__order_left">
                        <div className="myAccountOrders__order_info">
                          <div className="myAccountOrders__order_number">
                            {t('myAccount.orders.number', { '%id%': order.id })}
                          </div>
                          <div className="myAccountOrders__order_status">
                            {t(`myAccount.orders.status.${order.status}`)}
                          </div>
                        </div>
                        <div className="myAccountOrders__order_createdAt">
                          {t('myAccount.orders.createdAt', {
                            '%date%': Date(order.createdAt).format('DD/MM/YYYY')
                          })}
                        </div>
                        <div className="myAccountOrders__order_paymentType">
                          {t(
                            `myAccount.order.paymentType.${order.paymentType}`
                          )}
                        </div>
                        <div className="myAccountOrders__order__date">
                          {t(
                            `myAccount.orders.current.${
                              order.deliveryType === 'delivery'
                                ? 'deliveredAt'
                                : 'withdrawalAt'
                            }`,
                            {
                              '%date%': order.slot.begin.format('DD/MM/YYYY'),
                              '%hours%': order.slot.begin.format('HH'),
                              '%minutes%': order.slot.begin.format('mm')
                            }
                          )}
                        </div>
                        <div className="myAccountOrders__order_store">
                          <div className="myAccountOrders__order_storeName">
                            {order.pdv.format} {order.pdv.city}
                          </div>
                          {order.pdv.address} <br />
                          {order.pdv.zipCode} {order.pdv.city}
                        </div>
                      </div>
                      <div className="myAccountOrders__order_right">
                        <div className="myAccountOrders__order_total">
                          {fixDecimalCost(order.total.value)}
                          &euro;
                        </div>
                        <div className="myAccountOrders__order_detailContainer">
                          <Link
                            href={`/account-management/my-groceries/detail/${order.pdv.ref}/${order.id}`}
                            className="myAccountOrders__order_detail"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t('myAccount.orders.link.detail')
                              }}
                            />
                          </Link>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {ordersHeader}
              {!!state.oldOrders.length && (
                <>
                  <ul className="myAccountOrders__content">
                    {state.paginatedOrders.map((order) => {
                      if (
                        [
                          'validated',
                          'preparing',
                          'prepared',
                          'delivering'
                        ].includes(order.status)
                      ) {
                        return null;
                      }

                      const key = `order-${order.id}`;

                      return (
                        <li
                          key={key}
                          className={classnames(
                            'myAccountOrders__order',
                            'myAccountOrders__order--old',
                            `myAccountOrders__order--${order.status}`
                          )}
                        >
                          <div className="myAccountOrders__order_left">
                            <div className="myAccountOrders__order_info">
                              <div className="myAccountOrders__order_number">
                                {t('myAccount.orders.number', {
                                  '%id%': order.id
                                })}
                              </div>
                              {order.status === 'canceled' &&
                                order.canceledDate && (
                                  <div className="myAccountOrders__order_date">
                                    {t('myAccount.orders.canceldAt', {
                                      '%date%': order.canceledDate.format(
                                        'DD/MM/YYYY'
                                      )
                                    })}
                                  </div>
                                )}
                              {order.status !== 'canceled' && (
                                <div className="myAccountOrders__order_date">
                                  {t(`myAccount.orders.${order.status}At`, {
                                    '%date%': order.slot.begin.format(
                                      'DD/MM/YYYY'
                                    )
                                  })}
                                </div>
                              )}
                            </div>
                            <div className="myAccountOrders__order_status">
                              {t(`myAccount.orders.status.${order.status}`)}
                            </div>
                          </div>
                          <div className="myAccountOrders__order_right">
                            <div className="myAccountOrders__order_total">
                              {fixDecimalCost(order.total.value)}
                              &euro;
                            </div>
                            <div className="myAccountOrders__order_detailContainer">
                              <Link
                                href={`/account-management/my-groceries/detail/${order.pdv.ref}/${order.id}`}
                                className="myAccountOrders__order_detail"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: t('myAccount.orders.link.detail')
                                  }}
                                />
                              </Link>
                              {dayjs().diff(order.createdAt, 'months') <= 6 && (
                                <Link
                                  className="myAccountOrders__order_addToCart"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    openAddOrderModal(order.id, order.pdv.ref);
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: t('myAccount.orders.addToCart')
                                    }}
                                  />
                                </Link>
                              )}
                            </div>
                            <div className="myAccountOrders__order_download">
                              {!!order.billingUrl && (
                                <Link
                                  href={order.billingUrl}
                                  target="_blank"
                                  title={t('myAccount.orders.download')}
                                >
                                  <svg width="30" height="20">
                                    <use
                                      xlinkHref={`${MyAccountSprite}#download`}
                                    />
                                  </svg>
                                </Link>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>

                  {state.hasNextPage && (
                    <Link
                      className="myAccountOrders__next"
                      onClick={(clickEvent) => {
                        clickEvent.preventDefault();
                        handleNextPage();
                      }}
                      button
                      label={t('myAccount.orders.next')}
                    />
                  )}
                </>
              )}
              {!state.oldOrders.length && (
                <div className="myAccountOrders--empty">
                  <EmptyIcon className="myAccountOrders__icon" height={134} />
                  <h2 className="myAccountOrders__title">
                    {t('myAccount.orders.no-results')}
                  </h2>
                  <p className="myAccountOrders__message">
                    {t('myAccount.orders.empty.content')}
                  </p>
                </div>
              )}
            </>
          )}
        {state.ordersType === 'PARTNER' && !!state.mkpOrders.length && (
          <>
            <ul className="myAccountOrders__content">
              {sortOrdersById(state.mkpOrders).map((order) => {
                const key = `order-${order.id}`;

                return (
                  <li
                    key={key}
                    className={classnames(
                      'myAccountOrders__order',
                      'myAccountOrders__order--current',
                      `myAccountOrders__order--${order.status}`
                    )}
                  >
                    <div className="myAccountOrders__order_left">
                      <div className="myAccountOrders__order_info">
                        <div className="myAccountOrders__order_number">
                          {t('myAccount.orders.number', { '%id%': order.id })}
                        </div>
                        <div className="myAccountOrders__order_status">
                          {t(`myAccount.orders.status.${order.status}`)}
                        </div>
                      </div>
                      <div className="myAccountOrders__order_createdAt">
                        {t('myAccount.orders.createdAt', {
                          '%date%': Date(order.createdDate).format('DD/MM/YYYY')
                        })}
                      </div>
                      <div className="myAccountOrders__order_store">
                        {order?.seller?.name && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t('myAccount.mkpOrder.sellerName', {
                                '%seller%': order?.seller?.name
                              })
                            }}
                          />
                        )}
                        {order.shippingDetails?.totalDeliveryTime && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t('myAccount.mkpOrder.deliveryDate', {
                                '%days%':
                                  order.shippingDetails?.totalDeliveryTime
                              })
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {order.amount && (
                      <div className="myAccountOrders__order_right">
                        <div className="myAccountOrders__order_total">
                          {fixDecimalCost(order?.amount)}
                          &euro;
                        </div>
                        <div className="myAccountOrders__order_detailContainer">
                          <Link
                            href={`/account-management/my-groceries/oneplace/${order.storeId}/${order.id}`}
                            className="myAccountOrders__order_detail"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t('myAccount.orders.link.detail')
                              }}
                            />
                          </Link>
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {state.ordersType === 'ITM' &&
          !state.currentOrders.length &&
          !state.oldOrders.length && (
            <div className="myAccountOrders--empty">
              <EmptyIcon className="myAccountOrders__icon" height={134} />
              <h2 className="myAccountOrders__title">
                {t('myAccount.orders.empty.title')}
              </h2>
              <p className="myAccountOrders__message">
                {t('myAccount.orders.empty.content')}
              </p>
            </div>
          )}
        {state.ordersType === 'PARTNER' && !state.mkpOrders.length && (
          <div className="myAccountOrders--empty">
            <EmptyIcon className="myAccountOrders__icon" height={134} />
            <h2 className="myAccountOrders__title">
              {t('myAccount.mkpOrders.empty.title')}
            </h2>
            <p className="myAccountOrders__message">
              {t('myAccount.orders.empty.content')}
            </p>
          </div>
        )}
        <Modal
          pageView="ReOrder"
          confirmBtn={{
            label: t('common.validate'),
            loading: state.isLoadingOrder,
            onClick: async () => {
              await reorder();
              setState((s) => ({
                ...s,
                isLoadingOrder: false,
                isOpenOrder: false
              }));
            }
          }}
          cancelBtn={{
            label: t('common.cancel'),
            onClick: () => {
              setState((s) => ({
                ...s,
                isLoadingOrder: false,
                isOpenOrder: false
              }));
            }
          }}
          onClose={() =>
            setState((s) => ({
              ...s,
              isLoadingOrder: false,
              isOpenOrder: false
            }))
          }
          open={state.isOpenOrder}
        >
          <div className="reorderModalContent">
            <div
              dangerouslySetInnerHTML={{
                __html: t('myAccount.lists.shoppingList.reorder.message', {
                  '%newOrder%': state.availableProducts,
                  '%previousOrder%': state.oldOrderItems?.length,
                  '%price%':
                    state.availableProducts === state.oldOrderItems?.length
                      ? state.orderPrice
                      : state.filteredOrderPrice
                })
              }}
            />
          </div>
        </Modal>
      </>
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.orders')}
      enabledId="orders"
      content={contentPart}
      pageView={pageView}
      footer={footer}
    />
  );
};

export default Orders;
