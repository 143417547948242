import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import Link from '@/components/atoms/link';
import LoadableContent from '@/components/atoms/loadableContent';
import { Informations } from '@/lib/api/userInformation/types';

type WelcomeProps = {
  userInfo?: Informations;
};

const Welcome = ({ userInfo }: WelcomeProps) => {
  const [isLoading, setIsLoading] = useState(!userInfo);

  useEffect(() => {
    setIsLoading(!userInfo);
  }, [userInfo]);

  return (
    <LoadableContent
      loading={isLoading}
      className="myAccountDashboard__container myAccountDashboard__welcome"
    >
      <div
        className="myAccountDashboard__welcome_title"
        dangerouslySetInnerHTML={{
          __html: t('myAccount.dashboard.blockWelcome.title', {
            '%firstName%': userInfo?.firstName
              ? `<span class="myAccountDashboard__welcome_firstName">${`${userInfo.firstName} !`}</span>`
              : ''
          })
        }}
      />
      <div
        className="myAccountDashboard__welcome_content"
        dangerouslySetInnerHTML={{
          __html: t('myAccount.dashboard.blockWelcome.content')
        }}
      />
      <Link
        className="myAccountDashboard__welcome_button"
        button={true}
        label={t('myAccount.dashboard.blockWelcome.cta')}
        href="/account-management/my-info"
      />
    </LoadableContent>
  );
};

export default Welcome;
