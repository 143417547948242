import React from 'react';
import t from '@/lib/i18n';
import { Content as ContentfulContent } from '@/lib/contentful';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import MyAccountTiles from '@/components/organisms/myAccount/tiles';
import MyAccountTile from '@/components/organisms/myAccount/tile';
import MyAccountTileFid from '@/components/organisms/myAccount/tileFidelity';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
/* import FidelitySprite from '@/assets/images/sprites/carteFidelite.svg';
import useEvent from '@/lib/hooks/useEvent'; */
import getConfigValue from '@/lib/config';

const IS_FIDELITY_ENABLED = getConfigValue(
  'IS_FIDELITY_ENABLED',
  false
).toBoolean();

export type FidelityProps = {
  footer: ContentfulContent;
  pageView: string;
};

const Fidelity = ({ footer, pageView }: FidelityProps) => {
  /* const event = useEvent(); */
  const contentPart = (
    <MyAccountTiles>
      {IS_FIDELITY_ENABLED ? (
        <>
          <MyAccountTile
            icon={
              <svg width="49" height="33">
                <use xlinkHref={`${MyAccountSprite}#fildelity-program`} />
              </svg>
            }
            title={t('myAccount.fidelity.tiles.fildelity_program.title')}
            link={{
              label: t('myAccount.fidelity.tiles.fildelity_program.link'),
              href: '/account-management/my-fidelity/program-fidelity'
            }}
          />
          <MyAccountTile
            icon={
              <svg width="33" height="26">
                <use xlinkHref={`${MyAccountSprite}#fidelity-vouchers`} />
              </svg>
            }
            title={t('myAccount.fidelity.tiles.fidelity_vouchers.title')}
            link={{
              label: t('myAccount.fidelity.tiles.fidelity_vouchers.link'),
              href: '/account-management/my-fidelity/my-vouchers'
            }}
          />
          {/* <MyAccountTile
            icon={
              <svg width="40" height="34">
                <use xlinkHref={`${MyAccountSprite}#fidelity-offers`} />
              </svg>
            }
            title={t('myAccount.fidelity.tiles.fidelity_offers.title')}
            link={{
              label: t('myAccount.fidelity.tiles.fidelity_offers.link'),
              href: '/fidelity-advantages/personal-offers'
            }}
          />
          <MyAccountTile
            icon={
              <svg width="40" height="34">
                <use xlinkHref={`${FidelitySprite}#icons-fid-vignette`} />
              </svg>
            }
            title={t('myAccount.fidelity.tiles.fidelity_stickers.title')}
            onClick={() => event.send('stickers', { type: 'mySticker' })}
            link={{
              label: t('myAccount.fidelity.tiles.fidelity_stickers.link'),
              href: '/account-management/my-fidelity/my-stickers'
            }}
          /> */}
        </>
      ) : (
        <MyAccountTileFid
          icon={
            <svg width="49" height="33">
              <use xlinkHref={`${MyAccountSprite}#fildelity-program`} />
            </svg>
          }
          title={t('myAccount.fidelity.tiles.fildelity_program.titleSansFid')}
          link={{
            label: '',
            href: ''
          }}
        />
      )}
    </MyAccountTiles>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.fidelity')}
      enabledId="fidelity"
      content={contentPart}
      pageView={pageView}
      footer={footer}
    />
  );
};

export default Fidelity;
