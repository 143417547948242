import genericPageNormalize from '../genericPage';
import { Content, NormalizeParams } from '..';

const normalize = ({ data, includes, extras }: NormalizeParams): Services => {
  return genericPageNormalize({
    data,
    includes,
    extras: {
      ...extras,
      id: 'services'
    }
  });
};

export default normalize;

export type Services = Content;
