import genericPageNormalize from '../genericPage';
import { Content, NormalizeParams } from '..';

const normalize = ({ data, includes, extras }: NormalizeParams): PageProxy => {
  return genericPageNormalize({
    data,
    includes,
    extras: {
      ...extras,
      id: 'pageProxy'
    }
  });
};

export default normalize;

export type PageProxy = Content;
