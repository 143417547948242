import React, { memo, useEffect, useState } from 'react';
import { AuthStateType } from '@/store/auth/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import UserInformationApi from '@/lib/api/userInformation';
import { Informations } from '@/lib/api/userInformation/types';
import LoadableContent from '@/components/atoms/loadableContent';
import FidelityApi from '@/lib/api/fidelity';
import { FidelityInfo } from '@/lib/api/fidelity/types';
import { Component } from '@/lib/contentful';
import t from '@/lib/i18n';
// import ContentSection from '@/components/molecules/contentSection';
import { ReactComponent as TimerSVG } from '@/assets/images/icons/flashSale.svg';

import {
  SET_CARD_LS_CREATION_STATE,
  SET_FLOWS
} from '@/store/cardCreation/actions';
import { CardCreationStateType } from '@/store/cardCreation/reducer';
import { useInterval } from 'react-use';

// import ModalLinkCard from '../modalLinkCard';
import ActivateCardBloc from '../activateCardBloc';
import FidInfo from '../fidInfo';
import './style.scss';

const SETTINGS = {
  INTERVAL: 30000,
  NB_MAX_RETRY: 60
};

export type SummaryBoxProps = {
  components: {
    [key: string]: Component;
  };
};

type StateType = {
  userInfo: Informations | null;
  fidelityInfos: FidelityInfo | null;
  isLoading: boolean;
  nbRetry: number;
};

const SummaryBox = ({ components }: SummaryBoxProps) => {
  const { userId, isUserHydrated, isHydrated, lsCreation } = useSelector(
    ({
      auth,
      cardCreation
    }: {
      auth: AuthStateType;
      cardCreation: CardCreationStateType;
    }) => ({
      userId: auth.user?.id,
      isUserHydrated: auth.isHydrated,
      isHydrated: cardCreation.isHydrated,
      lsCreation: cardCreation.lsCreation
    })
  );

  const dispatch = useDispatch();
  const [state, setState] = useState<StateType>({
    userInfo: null,
    fidelityInfos: null,
    isLoading: true,
    nbRetry: SETTINGS.NB_MAX_RETRY
  });

  useInterval(
    async () => {
      if (!userId || !lsCreation?.inProgress) {
        return;
      }

      const response = await UserInformationApi.getInformations(userId);
      if (response.fidelityCardNumber === lsCreation.nextFidelityCardNumber) {
        setState((s) => ({
          ...s,
          nbRetry: SETTINGS.NB_MAX_RETRY
        }));
        dispatch({
          type: SET_CARD_LS_CREATION_STATE,
          payload: {
            inProgress: false,
            nextFidelityCardNumber: null
          }
        });
      } else {
        setState((s) => {
          if (s.nbRetry + 1 === SETTINGS.NB_MAX_RETRY) {
            dispatch({
              type: SET_CARD_LS_CREATION_STATE,
              payload: {
                inProgress: false,
                nextFidelityCardNumber: null
              }
            });
          }
          return {
            ...s,
            nbRetry: s.nbRetry + 1
          };
        });
      }
    },
    state.nbRetry < SETTINGS.NB_MAX_RETRY ? SETTINGS.INTERVAL : null
  );

  useEffect(() => {
    if (isHydrated) {
      dispatch({ type: SET_FLOWS, payload: { flows: components } });
    }
  }, [components, dispatch, isHydrated]);

  useEffect(() => {
    (async () => {
      if (!isUserHydrated) {
        return;
      }
      if (!userId) {
        setState((oldState) => {
          return {
            ...oldState,
            userInfo: null,
            isLoading: false
          };
        });
        return;
      }

      const userInfo = await UserInformationApi.getInformations(userId);
      let fidelityInfos: FidelityInfo | null = null;
      if (userInfo?.fidelityCardNumber) {
        try {
          fidelityInfos = await FidelityApi.getInfo({
            lastName: userInfo.lastName,
            firstName: userInfo.firstName,
            birthdayDate: userInfo.birthdayDate,
            fidelityCardNumber: userInfo.fidelityCardNumber
          });
        } catch (error: any) {
          fidelityInfos = null;
        }
      }

      setState((oldState) => {
        return {
          ...oldState,
          isLoading: false,
          userInfo,
          fidelityInfos,
          nbRetry: lsCreation?.inProgress ? 1 : oldState.nbRetry
        };
      });
    })();
  }, [userId, isUserHydrated, lsCreation?.inProgress]);

  const hasFid = !!(state.userInfo && state.userInfo.fidelityCardNumber);

  const lsCardRetry = lsCreation && lsCreation.inProgress && (
    <div className="summaryBox__activate-warning">
      <TimerSVG className="summaryBox__activate-warning-icon" />
      <p className="summaryBox__activate-warning-title">
        {t('fidelityCard.activateCardBloc.inProgress')}
      </p>
      <p>{t('fidelityCard.activateCardBloc.inProgress.desc')}</p>
    </div>
  );

  if (state.userInfo?.isPro) {
    return null;
  }

  return (
    <LoadableContent loading={state.isLoading}>
      {hasFid && state.fidelityInfos ? (
        <div className="summaryBox">
          <div
            className="summaryBox__info"
            style={{
              backgroundImage: `url(${components.backgroundImage.props.src})`
            }}
          >
            <FidInfo
              infos={state.fidelityInfos}
              fidelityCardImage={components.fidelityCardImage.props.src}
            />
          </div>
          {/* <div className="summaryBox__activate">
            <div className="summaryBox__wrapper">
              {lsCardRetry}
              <ContentSection
                title={t('fidelityCard.activateCardBloc.second.title')}
                content={t('fidelityCard.activateCardBloc.second.content')}
              />
              {state.userInfo && (
                <ModalLinkCard
                  userInfo={state.userInfo}
                  isActiveCardContext={false}
                  label={components.fidelityCardCreationFlow.props.labelHasFid}
                />
              )}
            </div>
          </div> */}
        </div>
      ) : (
        <ActivateCardBloc
          userInfo={state.userInfo}
          isAuthenticated={!!userId}
          labels={{
            labelHasFid: components.fidelityCardAttachmentFlow.props.content,
            labelHasNoFid:
              components.fidelityCardCreationFlow.props.labelHasNoFid,
            labelNotConnected:
              components.fidelityCardCreationFlow.props.labelNotConnected
          }}
          showFooter={!userId}
          lsCardRetry={lsCardRetry}
        />
      )}
    </LoadableContent>
  );
};

export default memo(SummaryBox);
