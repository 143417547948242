import { CardCreationLinkProps } from '@/components/templates/avantageFidelite/cardCreationLink';
import {
  normalize as BaseNormalize,
  Component,
  NormalizeParams
} from '@/lib/contentful';

const normalize = ({ data, includes }: NormalizeParams): ComponentProps => {
  const links: CardCreationLinkProps = { screens: {} };
  const list = [
    'ecranCgu',
    'ecranProNonAutorise',
    'ecranInformationsInvalides',
    'ecranCreationEnCours',
    'ecranCarteOk',
    'ecranCreationEnCoursTimeout',
    'ecranCarteErreur'
  ];

  links.labelNotConnected = data.labelBoutonNonConnecte || '';
  links.labelHasFid = data.labelBoutonEncarte || '';
  links.labelHasNoFid = data.labelBoutonNonEncarte || '';
  links.href = data.urlCible || '';

  list.forEach((element) => {
    const itemData = includes[data[element]?.sys.id] || null;
    if (itemData) {
      const dataToPass = {
        id: data[element]?.sys.id,
        title: itemData.fields?.titre || '',
        btnLabel: itemData.fields?.labelBouton || '',
        desc: itemData.fields?.description || '',
        imageData: null
      };

      if (itemData.fields?.imageOrAnimation) {
        const imageData = BaseNormalize({
          data: { ...includes[itemData.fields?.imageOrAnimation?.sys?.id] },
          includes
        });

        if (imageData) {
          dataToPass.imageData = imageData;
        }
      }
      links.screens[element] = dataToPass;
    }
  });

  return {
    type: 'templates/avantageFidelite',
    name: 'cardCreationLink',
    props: links
  };
};

export default normalize;

export interface ComponentProps extends Component {
  props: CardCreationLinkProps;
}
