import {
  CTAStoreItemProps,
  CTAStoreProps
} from '@/components/templates/avantageFidelite/ctaStore';
import {
  Component,
  normalize as BaseNormalize,
  NormalizeParams
} from '@/lib/contentful';

const normalize = ({ data, includes, extras }: NormalizeParams): CTAStore => {
  const { images = [] } = data;
  const itemsList: Array<CTAStoreItemProps> = [];

  images.forEach((element: any) => {
    const itemData = includes[element.sys.id] || null;

    const baseElement = BaseNormalize({
      data: itemData,
      includes,
      extras
    }) as Component;
    if (baseElement) {
      itemsList.push({
        image: baseElement,
        id: element.sys.id,
        link: {
          href: itemData.fields?.href || '',
          title: itemData.fields?.title || ''
        }
      });
    }
  });

  return {
    type: 'templates/avantageFidelite',
    name: 'ctaStore',
    props: {
      items: itemsList
    }
  };
};

export default normalize;

export interface CTAStore extends Component {
  props: CTAStoreProps;
}
