import ProductApi, { EmerchRequest } from '@/lib/api/product';
import { getPdvRef } from '@/lib/hooks/usePdv';

const getRecommendationProducts = async (
  userConsent = false,
  itemId?: string
) => {
  const pdv = getPdvRef();
  const req: EmerchRequest = {
    productId: itemId ?? '',
    scope: itemId ? 'PRODUCT' : 'CART',
    userConsent
  };

  return ProductApi.getRecommendations(req, pdv);
};

export default getRecommendationProducts;
