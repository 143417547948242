import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { PromotionType } from '@/lib/model/productTypes';

const QuantityDiscount = ({
  id,
  qty,
  promotion
}: {
  id: string;
  qty: number;
  promotion: PromotionType;
}) => {
  const [toolTipIsLoaded, setToolTipIsLoaded] = useState(false);
  if (!promotion.quantity) return <></>;
  const remaining = qty % promotion.quantity;
  const amount: number =
    promotion.unit !== 'percentage'
      ? Math.floor(qty / parseInt(String(promotion.quantity), 10)) *
        parseFloat(String(promotion.value).replace(',', '.'))
      : parseInt(String(promotion.value), 10);

  const remainingContent = t(
    `promotions.quantityDisount.remaining${
      promotion.quantity - remaining > 1 ? 's' : ''
    }`,
    {
      '%remaining%': promotion.quantity - remaining,
      '%value%': promotion.value,
      '%unit%': promotion.unit === 'percentage' ? '%' : '€'
    }
  );

  const intro = t('promotions.quantityDisount.intro', {
    '%qty%': promotion.quantity,
    '%value%': `${promotion.value}${
      promotion.unit === 'percentage' ? '%' : '€'
    }`
  });

  const hasPromoContent = t('promotions.quantityDisount.hasPromo', {
    '%qty%': qty,
    '%value%': `${
      promotion.unit === 'percentage'
        ? parseInt(String(promotion.value), 10)
        : String(amount.toFixed(2)).replace('.', ',')
    }${promotion.unit === 'percentage' ? '%' : '€'}`
  });

  useEffect(() => {
    setToolTipIsLoaded((o) => !o);
  }, []);

  const content = remaining !== 0 ? remainingContent : hasPromoContent;

  return (
    <>
      <div
        data-tip
        data-for={`promo-${id}`}
        className="quantityDiscount promotion promotion--quantityDiscount"
        dangerouslySetInnerHTML={{
          __html: qty === 0 ? intro : content
        }}
      />
      {toolTipIsLoaded && (
        <ReactTooltip place="top" id={`promo-${id}`} effect="float">
          <span
            className="promotion__tip"
            dangerouslySetInnerHTML={{
              __html: t(`promotions.quantityDiscount.tip`, {
                '%value%': promotion.value,
                '%quantity%': promotion.quantity,
                '%unit%': promotion.unit === 'percentage' ? '%' : '€',
                '%endDate%': dayjs(promotion.endDate).format('DD/MM/YYYY')
              })
            }}
          />
        </ReactTooltip>
      )}
    </>
  );
};

export default QuantityDiscount;
