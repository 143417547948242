import React, { useState } from 'react';
import t from '@/lib/i18n';
import Link from '@/components/atoms/link';
import Modal from '@/components/organisms/modal';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { DO_LOGOUT } from '@/store/auth/authActions';
import userInformationApi from '@/lib/api/userInformation';
import useEvent from '@/lib/hooks/useEvent';

const DeleteAccount = () => {
  // TODO :   const userId = useShallowEqualSelector(selectUserId);
  const { userId } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      userId: auth.user?.id
    };
  });
  const dispatch = useDispatch();
  const event = useEvent();
  const [isOpen, setIsOpen] = useState(false);

  const removeAccount = async () => {
    if (!userId) return;

    try {
      await userInformationApi.removeAccount(userId);

      dispatch({
        type: DO_LOGOUT
      });

      event.send('api', {
        type: 'success',
        name: 'delete'
      });
    } catch (error: any) {
      event.send('api', {
        type: 'error',
        name: 'delete'
      });
    }
  };

  return (
    <>
      <section className="myPersonalData__manageAccount">
        <div className="myPersonalData__title">
          {t('myAccount.myPersonalData.manageAccount.title')}
        </div>
        <Link
          className="myPersonalData__deleteAccount"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          {t('myAccount.myPersonalData.manageAccount.deleteAccount')}
        </Link>
      </section>
      <Modal
        className="myPersonalData__deletePopin"
        pageView="DeleteMyAccount"
        open={isOpen}
        confirmBtn={{
          label: t('myAccount.myPersonalData.deleteAccount.popin.confirmation'),
          onClick: () => removeAccount()
        }}
        cancelBtn={{
          label: t('myAccount.myPersonalData.deleteAccount.popin.cancel'),
          onClick: () => {
            setIsOpen(false);
          }
        }}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <>
          <div className="myPersonalData__deletePopin_title">
            {t('myAccount.myPersonalData.deleteAccount.popin.title')}
          </div>

          <div className="myPersonalData__deletePopin_container">
            <div className="myPersonalData__deletePopin_subTitle">
              {t('myAccount.myPersonalData.deleteAccount.popin.subTitle1')}
            </div>
            <p
              className="myPersonalData__deletePopin_content"
              dangerouslySetInnerHTML={{
                __html: t(
                  'myAccount.myPersonalData.deleteAccount.popin.content1'
                )
              }}
            />
            <div className="myPersonalData__deletePopin_subTitle">
              {t('myAccount.myPersonalData.deleteAccount.popin.subTitle2')}
            </div>
            <p
              className="myPersonalData__deletePopin_content"
              dangerouslySetInnerHTML={{
                __html: t(
                  'myAccount.myPersonalData.deleteAccount.popin.content2'
                )
              }}
            />
            <div className="myPersonalData__deletePopin_subTitle">
              {t('myAccount.myPersonalData.deleteAccount.popin.subTitle3')}
            </div>
            <p
              className="myPersonalData__deletePopin_content"
              dangerouslySetInnerHTML={{
                __html: t(
                  'myAccount.myPersonalData.deleteAccount.popin.content3'
                )
              }}
            />
            <p
              className="myPersonalData__deletePopin_content"
              dangerouslySetInnerHTML={{
                __html: t(
                  'myAccount.myPersonalData.deleteAccount.popin.content4'
                )
              }}
            />
            <p
              className="myPersonalData__deletePopin_content"
              dangerouslySetInnerHTML={{
                __html: t(
                  'myAccount.myPersonalData.deleteAccount.popin.content5'
                )
              }}
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default DeleteAccount;
