import React, { useMemo } from 'react';
import './style.scss';

type CurrencyProps = {
  amount?: number;
  symbol?: string;
  decimalSeperator?: string;
};

const Currency = ({
  amount = 0,
  symbol = '€',
  decimalSeperator = ','
}: CurrencyProps) => {
  const [integer, decimal] = useMemo(
    () => (amount / 100).toFixed(2).split('.'),
    [amount]
  );

  return (
    <span className="currency">
      {`${integer}${decimalSeperator}${decimal} ${symbol}`}
    </span>
  );
};

export default Currency;
