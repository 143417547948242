import { ContentProps } from '@/components/templates/homepage/content';
import { normalize as BaseNormalize, NormalizeParams, Component } from '..';

const normalize = ({ data, includes }: NormalizeParams): ContentProps => {
  const elements: ContentProps = {};

  if (data.slides) {
    elements.slider = {
      slides: []
    };

    data.slides.forEach((slide: any) => {
      const slideData = includes[slide.sys.id] || null;

      if (slideData) {
        const elt = BaseNormalize({
          data: slideData,
          includes
        });

        if (elt) {
          elements.slider?.slides.push(elt);
        }
      }
    });
  }

  if (data.grilleCategorie) {
    elements.categories = {
      slides: []
    };

    data.grilleCategorie.forEach((slide: any) => {
      const categoryData = includes[slide.sys.id] || null;

      if (categoryData) {
        const elt = BaseNormalize({ data: categoryData, includes });
        if (elt) {
          elements.categories?.slides.push(elt);
        }
      }
    });
  }

  if (data.infoOuverture && data.topBackground) {
    const topBackgroundData = includes[data.topBackground.sys.id] || null;

    elements.heading = {
      background: `${topBackgroundData.fields.file.url}?fm=jpg&fl=progressive`,
      title: data.infoOuverture
    };
  }

  if (data.iterations) {
    elements.blocks = [];

    const iterationsBlocs: Array<Component> = [];
    const iterationsCBlock: Array<Component> = [];

    data.iterations.forEach((iteration: any) => {
      let iterationData = includes[iteration.sys.id] || null;

      if (iterationData) {
        iterationData = includes[iterationData.sys.id] || null;

        if (iterationData.sys.contentType.sys.id === 'c-block') {
          iterationData = includes[iterationData.sys.id] || null;
          iterationData =
            includes[iterationData.fields.content[0].sys.id] || null;

          const elt = BaseNormalize({ data: iterationData, includes });

          if (elt) {
            iterationsCBlock.push(elt);
          }
        } else {
          const elt = BaseNormalize({ data: iterationData, includes });

          if (elt && elt['props'].blocks.length) {
            iterationsBlocs.push(elt);
          }
        }
      }
    });

    elements.blocks.push(...iterationsBlocs, ...iterationsCBlock);
  }

  return elements;
};

export default normalize;
