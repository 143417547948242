import React from 'react';
import '../../detail/item/style.scss';
import t from '@/lib/i18n';
import Link from '@/components/atoms/link';
import Image from '@/components/atoms/image';
import { MkpOrderItemAdvantages } from '@/lib/api/order/types';
import { PromotionType } from '@/lib/model/productTypes';
import Promotion from '@/components/atoms/promotion';

export type MkpOrderItemProps = {
  id: string;
  url: string;
  image: {
    src: string;
    alt: string;
  };
  title: string;
  status: string;
  brand: string;
  quantity: string;
  total: number;
  initialPrice: number;
  price: number;
  advantages: MkpOrderItemAdvantages[];
};

// provisional solution until we refactor the whole thing someday
const displayPromotion = (advantages: MkpOrderItemAdvantages[]) => {
  const current = advantages[0]; // because we're sure for the moment an mkp order can't have more than one promotion

  let type = 'ri';
  let unit = 'PCT';
  let value = current.value || '';
  switch (current.type) {
    case 'DEVISE':
      unit = 'amount';
      value = String(current.discount.toFixed(2)).replace('.', ',');
      break;
    case 'PCT':
      unit = 'percentage';
      value = current.value;
      break;
    case 'UNITE':
      unit = 'quantity';
      value = current.value;
      break;
  }
  switch (current.category.toLowerCase()) {
    case 'ri':
      type = 'immediateDiscount';
      break;
    case 'ac':
      type = 'deferredDiscount';
      break;
    case 'lv':
      type = unit === 'quantity' ? 'offeredDiscount' : 'quantityDiscount';
      break;
  }

  const pt: PromotionType = {
    type,
    unit,
    value
  };

  return <Promotion key={pt.type} data={pt} />;
};

const OrderItem = ({
  url,
  image,
  title,
  status,
  brand,
  initialPrice,
  price,
  quantity,
  total,
  advantages
}: MkpOrderItemProps) => {
  return (
    <div className="orderItem">
      <Link href={url} className="orderItem__info">
        <>
          <div className="orderItem__media">
            {url && url && (
              <Image
                className="orderItem__image"
                {...image}
                width={65}
                height={65}
              />
            )}
            {!image && <div className="orderItem__media__placeholder" />}
          </div>
          <div className="orderItem__texts">
            <h1 className="orderItem__title">
              {brand && <span className="orderItem__brand">{brand} - </span>}
              {title}
            </h1>
            {status && (
              <div>
                {t('product.status', {
                  '%status%': status
                })}
              </div>
            )}
          </div>
        </>
      </Link>
      <footer className="orderItem__footer">
        <div className="orderItem__footer__pricesWrapper">
          <>
            {!!advantages.length && (
              <>
                <div className="product__promotions">
                  {displayPromotion(advantages)}
                </div>
                <div className="orderItem__prices">
                  <span className="product__crossedOutPrice">
                    {t('product.total', {
                      '%price%': String(initialPrice.toFixed(2)).replace(
                        '.',
                        ','
                      )
                    })}
                  </span>
                  <span className="orderItem__productPrice hasPromo">
                    {t('product.total', {
                      '%price%': String(price.toFixed(2)).replace('.', ',')
                    })}
                  </span>
                </div>
              </>
            )}
            {!advantages.length && (
              <div className="orderItem__prices">
                <span className="orderItem__productPrice">
                  {t('product.total', {
                    '%price%': String(price.toFixed(2)).replace('.', ',')
                  })}
                </span>
                <div>{t('cart.unit')}</div>
              </div>
            )}
            <div className="orderItem__quantity">{quantity}</div>
            <span className="orderItem__totalPrice">
              {t('product.total', {
                '%price%': String(total.toFixed(2)).replace('.', ',')
              })}
            </span>
          </>
        </div>
      </footer>
    </div>
  );
};

export default OrderItem;
