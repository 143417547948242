import { LinkProps } from '@/components/atoms/link';
import { Component, NormalizeParams } from '..';

const normalize = ({
  data,
  includes
}: NormalizeParams): CtaTypeMdiaLinkProps | null => {
  const {
    text,
    ctaFile: { sys: { linkType = '', id: ctaFileId = '' } = {} } = {}
  } = data;

  if (linkType === 'Asset') {
    const assetData = includes[ctaFileId] || {};
    const { fields: { file: { url = '' } = {} } = {} } = assetData;
    if (url) {
      url.replace(/^\/\//g, '');
      return {
        type: 'atoms',
        name: 'link',
        props: {
          label: text || '',
          button: true,
          href: url,
          className: 'cmsCtaMdia'
        }
      };
    }
  }

  return null;
};

export default normalize;

export interface CtaTypeMdiaLinkProps extends Component {
  props: LinkProps;
}
