import genericPageNormalize from '../genericPage';
import { Content, NormalizeParams } from '..';

const normalize = ({ data, includes, extras }: NormalizeParams): BonsPlans => {
  return genericPageNormalize({
    data,
    includes,
    extras: {
      ...extras,
      id: 'bonsPlans'
    }
  });
};

export default normalize;

type BonsPlans = Content;
