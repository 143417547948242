import React from 'react';
import t from '@/lib/i18n';

import './style.scss';
import Link from '../link';

export type PersonalDataPolicyProps = {
  extended: boolean;
  handleClick?: () => void;
};

export const PersonalDataPolicy = ({
  extended,
  handleClick
}: PersonalDataPolicyProps) => {
  return (
    <div className="personal-data-policy">
      <div className="personal-data-policy__label">
        {t('createAccount.form.label.personalDataPolicy')}
      </div>
      <a
        className="personal-data-policy__link"
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={0}
      >
        {extended
          ? t('createAccount.form.lessDetails')
          : t('createAccount.form.moreDetails')}
      </a>
      <div
        className={
          extended
            ? 'personal-data-policy__fadeIn'
            : 'personal-data-policy__fadeOut'
        }
      >
        <p>{t('createAccount.form.personalDataPolicy.p1')}</p>
        <p>{t('createAccount.form.personalDataPolicy.p2')}</p>

        <div>
          {t('createAccount.form.personalDataPolicy.p4')}{' '}
          <Link
            href="/legal-informations/personal-data-and-cookies"
            target="_blank"
          >
            {t('createAccount.form.personalDataPolicy.click')}
          </Link>
        </div>
      </div>
      <div className="personal-data-policy__fade" />
    </div>
  );
};
