import React from 'react';
import './style.scss';
import { SubCartType, Animation } from '@/store/cart/types';
import { useDispatch } from 'react-redux';
import Link from '@/components/atoms/link';
import classnames from 'classnames';
import t from '@/lib/i18n';
import Checkbox from '@/components/atoms/formCheckbox';
import { ACCEPT_GAMES_CONTEST } from '@/store/cart/cartActions';
import useEvent from '@/lib/hooks/useEvent';

type GamesContestProps = {
  itmCart: SubCartType | null;
  isAccepted: boolean;
};

const JEUX_CONCOURS = 'JEUX_CONCOURS';
const LANDING_PAGE = 'LANDING_PAGE';

const GamesContest = ({ itmCart, isAccepted }: GamesContestProps) => {
  const dispatch = useDispatch();
  const event = useEvent();

  if (!itmCart) return null;

  const animation: Animation | null =
    itmCart.animations.find(
      (animationTemp: Animation) => animationTemp.type === JEUX_CONCOURS
    ) || null;

  if (!animation) return null;

  const seeMoreUrl: string | null =
    animation.actions.find((action) => action.typeAction === LANDING_PAGE)
      ?.redirectUrl || null;

  const rulesUrl: string | null =
    animation.actions.find((action) => action.typeAction === 'REGLEMENT')
      ?.redirectUrl || null;

  return (
    <>
      <h1 className="gamesContest__title">{t('gamesContest.title')}</h1>
      <div className="gamesContest__content">
        {animation.title && (
          <h2 className="gamesContest__content__title">{animation.title}</h2>
        )}
        {animation.texte && (
          <div className="gamesContest__content__texte">{animation.texte}</div>
        )}
        <div className="gamesContest__description">
          {animation.footer && (
            <div className="gamesContest__description__footer">
              {animation.footer}
            </div>
          )}
          {seeMoreUrl && (
            <Link
              className="gamesContest__link"
              href={seeMoreUrl}
              label={t('gamesContest.seeMore')}
            />
          )}
          <div className="gamesContest__checkBox">
            <Checkbox
              onChange={(e) => {
                dispatch({
                  type: ACCEPT_GAMES_CONTEST,
                  payload: {
                    isAccepted: e,
                    idGameContest: e ? [animation.id] : null
                  }
                });
                e &&
                  event.send('checkout', {
                    type: 'gameEvent',
                    animation: animation.title
                  });
              }}
              id="GamesContest"
              name="GamesContest"
              checked={isAccepted}
              label={t('gamesContest.checkBox.label')}
            />
            {rulesUrl && (
              <Link
                className={classnames(
                  'gamesContest__link',
                  'gamesContest__link--rules'
                )}
                href={rulesUrl}
                label={t('gamesContest.rules')}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GamesContest;
