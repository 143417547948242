import Checkbox from '@/components/atoms/formCheckbox';
import t from '@/lib/i18n';
import { FilterValue } from '@/lib/model/productList';
import classNames from 'classnames';
import React, { useState } from 'react';

import './style.scss';

const MIN_NB_FILTERS_DISPLAYED = 6;

export type FilterProps = {
  label: string | JSX.Element;
  options: Array<FilterValue>;
  name: string;
  onFilterChecked?: (
    name: string,
    option: FilterValue,
    checked?: boolean
  ) => void;
};

const Filter = ({ label, options, name, onFilterChecked }: FilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectOption = (selectedOption: FilterValue, checked?: boolean) => {
    onFilterChecked && onFilterChecked(name, selectedOption, checked);
  };

  return (
    <div className="filter">
      <div className="filter__title">{label}</div>
      <div
        className={classNames('filter__content', {
          open: isOpen && options.length > MIN_NB_FILTERS_DISPLAYED,
          close: !isOpen && options.length > MIN_NB_FILTERS_DISPLAYED
        })}
      >
        {options.map((option) => {
          return (
            <Checkbox
              className="filter__checkbox"
              key={`${name}_${option.label}_${option.id}`}
              name={option.id.toString()}
              id={option.id.toString()}
              label={`${option.label} (${option.countProducts})`}
              checked={!!option.active}
              disabled={!!option.disabled}
              onChange={(checked: boolean) => {
                selectOption(option, checked);
              }}
            />
          );
        })}
      </div>
      {options.length > MIN_NB_FILTERS_DISPLAYED && (
        <div className="filter__footer">
          <a className="filter__show" onClick={() => setIsOpen(!isOpen)}>
            {isOpen
              ? t('productList.filters.less')
              : t('productList.filters.more')}
          </a>
        </div>
      )}
    </div>
  );
};

export default Filter;
