import React from 'react';
import Title from '@/components/atoms/title';
import Paragraph from '@/components/atoms/paragraph';
import t from '@/lib/i18n';
import Link from '@/components/atoms/link';
import './style.scss';

const RenewPassordSuccess = () => (
  <>
    <Title
      tag="h2"
      label={t('renew.password.successTitle')}
      className="renewPasswordSuccess__title"
    />
    <Paragraph
      label={t('renew.password.successText1')}
      className="renewPasswordSuccess__text"
    />
    <Paragraph
      label={t('renew.password.successText2')}
      className="renewPasswordSuccess__text"
    />
    <div className="renewPasswordSuccess__link__resendEmail">
      <Link
        href="/connection?redirect=/home"
        label={t('renew.password.successConnect')}
        className="renewPasswordSuccess__link"
      />
    </div>
  </>
);

export default RenewPassordSuccess;
