import React from 'react';
import { NextSeo } from 'next-seo';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Layout from '@/components/organisms/layout';

export type PrehomeProps = {
  page: ContentfulContent;
  footer: ContentfulContent;
  pageView?: string;
};

const Prehome = ({ page, footer, pageView }: PrehomeProps) => {
  return (
    <>
      {page && page.meta && (
        <NextSeo
          title={page.meta.title ?? ''}
          description={page.meta.description ?? ''}
        />
      )}
      <Layout
        content={
          page && <ContentfulRenderComponents components={page.components} />
        }
        pageView={pageView}
        footer={
          footer && (
            <ContentfulRenderComponents components={footer.components} />
          )
        }
      />
    </>
  );
};

export default Prehome;
