import React from 'react';
import PdvApi from '@/lib/api/pdv';
import { Request, Response } from 'express';
import PdvMessage, { PdvMessageProps } from '@/components/molecules/pdvMessage';
import usePdv from '@/lib/hooks/usePdv';

type InitialProps = {
  props: PdvMessageProps;
  req: Request;
  res: Response;
};

const PdvMessageESI = (props: PdvMessageProps) => {
  return <PdvMessage {...props} />;
};

PdvMessageESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref } = usePdv(req);

  let message: string | null = null;

  if (ref) {
    const pdv = await PdvApi.getPdvInfo(ref);

    if (pdv) {
      message = pdv.message ?? null;
    }
  }

  return new Promise((resolve) => {
    if (res) {
      // Cache for 1 hour
      res.set('Cache-Control', 's-maxage=3600, max-age=3600');
    }

    resolve({
      ...props,
      message
    });
  });
};

export default PdvMessageESI;
