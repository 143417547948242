import { OrderDetail } from '@/lib/api/order/types';

export type OnBuyType = {
  nfProductID: string;
  ean: string;
};

const sendToCriteo = (onBuyParam: Array<OnBuyType>) => {
  const interval = setInterval(() => {
    const onBuy = window.__sto?.globals?.onBuy;
    if (onBuy) {
      onBuy(onBuyParam);
      clearInterval(interval);
    }
  }, 1000);
};

export const criteoConfirmOrder = (orderDetail: OrderDetail) => {
  const onBuyParam: Array<OnBuyType> = orderDetail.items.map((item) => ({
    nfProductID: item.product.id,
    ean: item.product.ean
  }));
  sendToCriteo(onBuyParam);
};

export const criteoConfirmOrderNew = (orderDetail: OrderDetail) => {
  if (orderDetail.confirmItems) {
    const onBuyParam: Array<OnBuyType> = orderDetail.confirmItems.map(
      (item) => ({
        nfProductID: item.id,
        ean: item.ean
      })
    );
    sendToCriteo(onBuyParam);
  }
};
