import React, { memo, useEffect, useState } from 'react';
import { AuthStateType } from '@/store/auth/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import UserInformationApi from '@/lib/api/userInformation';
import { Informations } from '@/lib/api/userInformation/types';
import LoadableContent from '@/components/atoms/loadableContent';
import Link from '@/components/atoms/link';
import { CardCreationStateType } from '@/store/cardCreation/reducer';
import { SET_PARTIAL_FLOWS } from '@/store/cardCreation/actions';

export type CardCreationLinkProps = {
  labelNotConnected?: string;
  labelHasFid?: string;
  labelHasNoFid?: string;
  href?: string;
  screens: {
    [key: string]: any;
  };
};

type StateType = {
  userInfo?: Informations;
  isLoading: boolean;
};

const CreationJourney = ({
  labelNotConnected,
  labelHasFid,
  labelHasNoFid,
  screens
}: CardCreationLinkProps) => {
  const { userId, isHydrated, flows } = useSelector(
    ({
      auth,
      cardCreation
    }: {
      auth: AuthStateType;
      cardCreation: CardCreationStateType;
    }) => ({
      userId: auth.user?.id,
      isHydrated: cardCreation.isHydrated,
      flows: cardCreation.flows
    })
  );

  const [state, setState] = useState<StateType>({
    userInfo: undefined,
    isLoading: true
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isHydrated && !flows) {
      dispatch({
        type: SET_PARTIAL_FLOWS,
        payload: {
          field: 'fidelityCardCreationFlow',
          value: { props: { screens } }
        }
      });
    }
  }, [dispatch, isHydrated, screens, flows]);

  useEffect(() => {
    (async () => {
      if (!userId) {
        setState({ isLoading: false });
        return;
      }
      const userInfo = await UserInformationApi.getInformations(userId);

      setState({
        userInfo,
        isLoading: false
      });
    })();
  }, [userId]);

  const hasFid = !!(state.userInfo && state.userInfo.fidelityCardNumber);

  return (
    <LoadableContent loading={state.isLoading}>
      <div className="creationJourney">
        {!userId && (
          <Link
            href="/connection?redirect=/fidelity-advantages/personal-offers"
            button
          >
            {labelNotConnected}
          </Link>
        )}
        {userId && !hasFid && (
          <Link
            href="/fidelity-advantages/card-creation?redirect=/fidelity-advantages/personal-offers"
            button
          >
            {labelHasNoFid}
          </Link>
        )}
        {userId && hasFid && (
          <Link href="#fidelity-offers" button>
            {labelHasFid}
          </Link>
        )}
      </div>
    </LoadableContent>
  );
};

export default memo(CreationJourney);
