import React from 'react';
import '../style.scss';
import t from '@/lib/i18n';
import CartSprite from '@/assets/images/sprites/cart.svg';
import Product from '@/components/organisms/product';
import { SimpleFullMainNav } from '@/lib/api/categories';
import { CartItemType } from '@/store/cart/types';
import useEvent from '@/lib/hooks/useEvent';
import { ReactComponent as AlcoholBanner } from '@/assets/images/icons/alcohol-banner.svg';

type ProductByCategorieProps = {
  categories: Array<ProductByCategorieType>;
  isCheckout: boolean;
  sellerId: string;
  clearUnavailable: (
    category: ProductByCategorieType,
    sellerId: string
  ) => void;
  redirectOnProductClick?: boolean;
};

export type ProductByCategorieType = SimpleFullMainNav & {
  qty?: number;
  products?: Array<CartItemType>;
};

const ProductByCategorie = ({
  isCheckout,
  categories,
  sellerId,
  clearUnavailable,
  redirectOnProductClick
}: ProductByCategorieProps) => {
  const event = useEvent();
  return (
    <>
      {categories?.map((category) => {
        if (!category.qty) return null;
        const showAlcoholIcon =
          category.hasAlcohol &&
          category.products?.some(
            ({ product }) => product.informations.hasAlcohol
          );
        return (
          <React.Fragment key={category.title}>
            {isCheckout && (
              <>
                <div className="cart__category">
                  {category.picto && (
                    <img
                      className="cart__category_picto"
                      src={category.picto}
                      alt=""
                      width={25}
                      height={25}
                    />
                  )}
                  <span className="cart__category__title">
                    {category.title}&nbsp;
                  </span>
                  {t(`cart.numberOfArticle${category.qty > 1 ? 's' : ''}`, {
                    '%qty%': category.qty
                  })}
                  {showAlcoholIcon && (
                    <div className="cart__category__hasAlcohol">
                      <AlcoholBanner width={160} />
                    </div>
                  )}
                </div>
                {showAlcoholIcon && (
                  <div className="cart__category__hasAlcoholMobile">
                    <AlcoholBanner width={160} />
                  </div>
                )}
                {category.key === 'unavailable' && (
                  <div className="cart__empty">
                    <span className="cart__empty__text">
                      <svg
                        className="cart__empty__picto"
                        width={20}
                        height={20}
                      >
                        <use xlinkHref={`${CartSprite}#info`} />
                      </svg>
                      {t('cart.empty.warning')}
                    </span>
                    <button
                      onClick={() => {
                        event.send('checkout', {
                          type: 'removeUnavailableProducts',
                          productId: category.products
                            ?.map(({ product }) => product.id)
                            .join('::')
                        });
                        clearUnavailable(category, sellerId);
                      }}
                      className="cart__empty__button"
                    >
                      {t('cart.empty.deleteAll')}
                    </button>
                  </div>
                )}
              </>
            )}
            {category.products?.map((product) => {
              return (
                <Product
                  key={product.product.id}
                  data={product.product}
                  isCheckout={isCheckout}
                  isSmall={true}
                  sellerId={sellerId}
                  redirectOnProductClick={
                    category.key === 'unavailable'
                      ? false
                      : redirectOnProductClick
                  }
                  productClassName={isCheckout ? 'productCheckout' : undefined}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default ProductByCategorie;
