import { NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): Period => {
  return {
    begin: data.debut,
    end: data.fin
  };
};

export default normalize;

export type Period = {
  begin: string;
  end: string;
};
