import React, { memo, useEffect, useState } from 'react';
import { AuthStateType } from '@/store/auth/authReducer';
import { useSelector } from 'react-redux';
import UserInformationApi from '@/lib/api/userInformation';
import { Informations } from '@/lib/api/userInformation/types';
import LoadableContent from '@/components/atoms/loadableContent';
import { getPdvRef } from '@/lib/hooks/usePdv';
import getConfigValue from '@/lib/config';
import Iframe from '@/components/atoms/iframe';
import { CatalinaEncrypt } from '@/lib/utils/crypto';

export type CatalinaOfferProps = {
  height: string;
  width: string;
};

type StateType = {
  userInfo?: Informations;
  isLoading: boolean;
  link?: string;
};

const CatalinaOffer = ({ height, width }: CatalinaOfferProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [state, setState] = useState<StateType>({
    userInfo: undefined,
    isLoading: true
  });

  useEffect(() => {
    (async () => {
      if (!userId) {
        setState({ isLoading: false });
        return;
      }
      try {
        const userInfo = await UserInformationApi.getInformations(userId);

        const pdvRef = CatalinaEncrypt(getPdvRef());
        const fidelityCardNumber = userInfo.fidelityCardNumber
          ? CatalinaEncrypt(userInfo.fidelityCardNumber)
          : '';

        const link = `${getConfigValue(
          'CATALINA_LINK',
          ''
        )}/?fsc="${fidelityCardNumber}"&id="${pdvRef}"`;
        setState({
          userInfo,
          link,
          isLoading: false
        });
      } catch (error: any) {
        setState({ isLoading: false });
      }
    })();
  }, [userId]);

  const hasFid = !!(state.userInfo && state.userInfo.fidelityCardNumber);

  return (
    <LoadableContent loading={state.isLoading}>
      <div className="catalinaOffer" id="fidelity-offers">
        {hasFid && <Iframe attributes={{ width, height, src: state.link }} />}
      </div>
    </LoadableContent>
  );
};

export default memo(CatalinaOffer);
