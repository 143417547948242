import React, { useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import t from '@/lib/i18n';
import { ReactComponent as CheckIcon } from '@/assets/images/icons/check.svg';
import { ReactComponent as CrossIcon } from '@/assets/images/icons/cross.svg';

export type PasswordPoliciesProps = {
  className?: string;
  value: string;
  validation?: boolean;
};

type RulesType =
  | 'minLength'
  | 'uppercase'
  | 'lowercase'
  | 'number'
  | 'specialCharacter';

export const IsValid = (value: string) => {
  const policies = checkPolicies(value);

  return (
    policies.minLength &&
    policies.uppercase &&
    policies.lowercase &&
    policies.number &&
    policies.specialCharacter
  );
};

export const checkPolicies = (value: string) => {
  return {
    minLength: value.length >= 8,
    uppercase: !!value.match(/[A-Z]/g)?.length,
    lowercase: !!value.match(/[a-z]/g)?.length,
    number: !!value.match(/[0-9]/g)?.length,
    specialCharacter: !!value.match(
      /[[²&~«“#\\'"‘{|_`^@()\]°=+}<>,?;.:/!§¡¨$£¤*µ%-]/g
    )?.length
  };
};

const PasswordPolicies = ({
  className,
  value,
  validation = false
}: PasswordPoliciesProps) => {
  const [state, setState] = useState({
    value,
    validation,
    policies: {
      minLength: false,
      uppercase: false,
      lowercase: false,
      number: false,
      specialCharacter: false
    }
  });

  useEffect(() => {
    setState((oldState) => {
      return {
        ...oldState,
        validation,
        policies: checkPolicies(value ?? '')
      };
    });
  }, [value, validation]);

  const rules = [
    'minLength',
    'uppercase',
    'lowercase',
    'number',
    'specialCharacter'
  ];

  return (
    <div className={classnames('passwordPolicies', className)}>
      <div className="passwordPolicies__label">
        {t('passwordPolicies.label')}
      </div>
      <ul className="passwordPolicies__items">
        {rules.map((rule: string) => {
          const key = `password-rule-${rule}`;
          const type = rule as RulesType;
          const isValidated = state.policies[type];
          const isError = validation && !state.policies[type];

          return (
            <li
              key={key}
              className={classnames('passwordPolicies__item', {
                'passwordPolicies__item--validated': isValidated,
                'passwordPolicies__item--error': isError
              })}
            >
              <div className="passwordPolicies__iconContainer">
                {isValidated && (
                  <CheckIcon
                    className="passwordPolicies__icon"
                    width={10}
                    height={10}
                  />
                )}
                {isError && (
                  <CrossIcon
                    className="passwordPolicies__icon"
                    width={9}
                    height={9}
                  />
                )}
              </div>
              {t(`passwordPolicies.${rule}`)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PasswordPolicies;
