import React, { ChangeEvent } from 'react';
import classnames from 'classnames';
import './style.scss';

export type FormTextAreaProps = {
  id?: string;
  label?: string;
  className?: string;
  classNameTextArea?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  errorMessage?: string;
  name?: string;
  rows?: number;
  cols?: number;
  maxLength?: number;
  classNameField?: string;
};

const FormTextArea = React.forwardRef(
  (
    {
      id,
      label,
      className,
      onChange = (e: ChangeEvent<HTMLTextAreaElement>) => null,
      value,
      errorMessage,
      name,
      rows,
      cols,
      maxLength,
      classNameField,
      classNameTextArea
    }: FormTextAreaProps,
    ref: any
  ) => {
    return (
      <div
        className={classnames('formTextarea', className, {
          'textArea--hasError': !!errorMessage
        })}
      >
        {label && (
          <label
            className={classnames('formTextarea__label', classNameTextArea)}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <textarea
          className={classnames('formTextarea__field', classNameField)}
          name={name}
          id={id}
          rows={rows}
          cols={cols}
          maxLength={maxLength}
          value={value}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e)}
          ref={ref}
        />
        {errorMessage && (
          <div className="formTextarea__errorMessage">{errorMessage}</div>
        )}
      </div>
    );
  }
);

export default FormTextArea;
