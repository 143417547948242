import { ReactComponent as Spinner } from '@/assets/images/icons/spinner.svg';
import RegisterSprite from '@/assets/images/sprites/register.svg';
import Button from '@/components/atoms/button';
import Link from '@/components/atoms/link';
import AccountApi from '@/lib/api/account';
import { Content as ContentfulContent } from '@/lib/contentful';
import useEvent from '@/lib/hooks/useEvent';
import t from '@/lib/i18n';
import { RESET_USER_REGISTER } from '@/store/register/registerActions';
import { NewUserLocal } from '@/store/register/types';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import CguModal from '../../cguModal';

export type StepProps = {
  cgu: ContentfulContent;
  user: NewUserLocal;
  dispatch: any;
};

const FidelityStep = ({ user, cgu, dispatch }: StepProps) => {
  const [state, setState] = useState({
    modalIsOpen: false,
    isLoading: false,
    submitError: []
  });
  const router = useRouter();
  const event = useEvent();

  const submitAccountWithCgu = async (cguFid: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true
    }));

    const isCreated = await AccountApi.createAccount({
      ...user,
      cguValided: cguFid
    });

    switch (isCreated.status) {
      case 'success':
        dispatch({
          type: RESET_USER_REGISTER
        });
        event.send('api', {
          type: 'success',
          name: 'signUp'
        });
        router.push(
          cguFid
            ? '/my-account/create-my-account/confirmation'
            : '/my-account/create-my-account/validation'
        );
        break;

      case 'error':
        event.send('api', {
          type: 'error',
          name: 'signUp'
        });
        setState((prevState) => ({
          ...prevState,
          submitError: isCreated.data,
          isLoading: false
        }));
        break;
    }
  };

  return (
    <div className="formSubscribe">
      <div className="title">{t('createAccount.form.fidelity.title')}</div>
      <div className="subtitle">
        {t('createAccount.form.fidelity.description')}
      </div>
      <div className="box">
        <div className="icon">
          <svg height="150" width="150">
            <use xlinkHref={`${RegisterSprite}#fidelity1`} />
          </svg>
        </div>
        <div className="card">
          <div className="card__title">{t('register.fidelity.message1')}</div>
        </div>
      </div>
      <div className="box">
        <div className="icon">
          <svg height="150" width="150">
            <use xlinkHref={`${RegisterSprite}#fidelity2`} />
          </svg>
        </div>
        <div className="card">
          <div>{t('register.fidelity.message2')}</div>
        </div>
      </div>
      <div className="box">
        <div className="icon">
          <svg height="150" width="150">
            <use xlinkHref={`${RegisterSprite}#fidelity3`} />
          </svg>
        </div>
        <div className="card">
          <div>{t('register.fidelity.message3')}</div>
        </div>
      </div>
      <CguModal cgu={cgu} user={user} state={state} setState={setState} />
      {/* Temporary Error Message */}
      {state.submitError?.length > 0 && (
        <div className="submittingError">
          {state.submitError.map(({ message }) => (
            <li key={`err--${message}`}>{message}</li>
          ))}
        </div>
      )}
      {/* End : Temporary Error Message */}
      <div className="footer">
        <div />
        <div className="footer__btn">
          {state.isLoading ? (
            <div className="spinner">
              <Spinner className="spinner__loader" />
            </div>
          ) : (
            <Link
              className="footer__link"
              onClick={() => submitAccountWithCgu(false)}
              id="laterFid"
            >
              {t('register.cgu.later')}
            </Link>
          )}
          <Button
            label={t('register.cgu.takeIt')}
            id="takeFid"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                modalIsOpen: true
              }))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FidelityStep;
