import getConfigValue from '@/lib/config';
import countries from 'i18n-iso-countries';
import pt from 'i18n-iso-countries/langs/pt.json';

countries.registerLocale(pt);

const firstCountries = [
  'PRT',
  'FRA',
  'DEU',
  'BEL',
  'ESP',
  'ITA',
  'LUX',
  'CHE',
  'GLP',
  'GUF',
  'MTQ',
  'MYT',
  'MCO',
  'NCL',
  'PYF',
  'REU',
  'BLM',
  'MAF',
  'SPM',
  'ATF',
  'WLF'
];

export type Country = {
  code: string;
  label: string;
};

const countriesList: Array<Country> = [];
const originalCoutries = countries.getNames(
  getConfigValue('LOCALE', 'pt').toString(),
  {
    select: 'official'
  }
);
Object.keys(originalCoutries).forEach((alpha2Code: string) => {
  const alpha3Code = countries.alpha2ToAlpha3(alpha2Code);
  const country = {
    label: originalCoutries[alpha2Code],
    code: alpha3Code
  };

  if (firstCountries.includes(alpha3Code)) {
    countriesList.splice(firstCountries.indexOf(alpha3Code), 0, country);
  } else {
    countriesList.push(country);
  }
});

export default countriesList;
