import React from 'react';
import t from '@/lib/i18n';
import Title from '@/components/atoms/title';
import Paragraph from '@/components/atoms/paragraph';
import Link from '@/components/atoms/link';
import { ReactComponent as Mail } from '@/assets/images/icons/mail.svg';
import './style.scss';

export type SuccessForgottenPasswordProps = {
  email: string;
};

const SuccessForgottenPassword = ({ email }: SuccessForgottenPasswordProps) => {
  return (
    <div className="successPassForgot">
      <Title
        tag="h2"
        label={t('forgottenPassword.success.title')}
        className="successPassForgot__title"
      />
      <Paragraph className="successPassForgot__info">
        {t('forgottenPassword.success.info')}
      </Paragraph>
      <div className="successPassForgot__emailBlock">
        <Mail width="20" height="20" />
        <Paragraph className="successPassForgot__emailBlock__email">
          {email}
        </Paragraph>
      </div>
      <Paragraph className="successPassForgot__explanation">
        {t('forgottenPassword.success.explanation1')}
      </Paragraph>

      <Paragraph className="successPassForgot__explanation">
        {t('forgottenPassword.success.explanation2')}
      </Paragraph>
      <div className="successPassForgot__linkBlock">
        <Link
          href="/home"
          className="successPassForgot__linkBlock__link"
          label={t('forgottenPassword.success.link')}
        />
      </div>
    </div>
  );
};

export default SuccessForgottenPassword;
