import React from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';

export type IterationProps = {
  blocks: Array<Component>;
};

const Iteration = ({ blocks = [] }: IterationProps) => {
  return <ContentfulRenderComponents components={blocks} />;
};

export default Iteration;
