import React, { useEffect } from 'react';
import './style.scss';
import SecurityLayout from '@/components/organisms/securityLayout';
import AccountApi from '@/lib/api/account';
import t from '@/lib/i18n';

export type ActivateAccountProps = {
  email: string;
};

const ActivateAccount = ({ email }: ActivateAccountProps) => {
  useEffect(() => {
    (async () => {
      await AccountApi.resendActivationEmail(email);
    })();
  }, [email]);

  return (
    <SecurityLayout
      goBack={{
        label: t('connexion.goBack.label'),
        url: '/home'
      }}
    >
      <>
        <h1 className="activateAccount__title">
          {t('activationAccount.title')}
        </h1>
        <p
          className="activateAccount__message"
          dangerouslySetInnerHTML={{ __html: t('activationAccount.message') }}
        />
      </>
    </SecurityLayout>
  );
};

export default ActivateAccount;
