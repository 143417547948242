import t from '@/lib/i18n';
import dayjs from 'dayjs';

export type ApiDiscountCodeType = {
  id?: number;
  type?: 'EURO' | 'POURCENTAGE' | 'CADEAU';
  code?: string;
  conditionUsage?: {
    typeLivraison: string;
  };
  description?: string;
  dateDebut?: Date | string;
  dateFin?: Date | string;
  libelle?: string;
  montantMinimumPanier?: number;
  montantRemise?: number;
  dateFinRemiseDifferee?: Date | string;
};

export type ApiDiscountCodeErrorType = {
  message?: string;
  code?: string;
};

class DiscountCode {
  id?: number;

  type?: 'EURO' | 'POURCENTAGE' | 'CADEAU';

  code?: string;

  usageCondition?: {
    typeLivraison: string;
  };

  startingDate?: Date | string;

  endingDate?: Date | string;

  libelle?: string;

  minimumAmountCart?: number;

  amountDiscount?: number;

  dateEndingDiscountDelayed?: Date | string;

  errorMessage?: string | null;

  description?: string;

  validationMessage?: string | null = null;

  constructor({
    apiData,
    apiErrorsHandled,
    totalCart
  }: {
    apiData: ApiDiscountCodeType | null;
    apiErrorsHandled: ApiDiscountCodeErrorType | null;
    totalCart: number;
  }) {
    this.errorMessage =
      apiErrorsHandled?.message ||
      DiscountCode.getErrorsMessageFromApiData(apiData, totalCart);

    if (this.errorMessage) return;

    this.id = apiData?.id;
    this.type = apiData?.type;
    this.code = apiData?.code;
    this.usageCondition = apiData?.conditionUsage;
    this.startingDate = apiData?.dateDebut;
    this.endingDate = apiData?.dateFin;
    this.libelle = apiData?.libelle;
    this.minimumAmountCart = apiData?.montantMinimumPanier;
    this.amountDiscount = apiData?.dateFinRemiseDifferee
      ? 0
      : apiData?.montantRemise;
    this.dateEndingDiscountDelayed = apiData?.dateFinRemiseDifferee;
    this.description = apiData?.description;
    this.validationMessage = DiscountCode.getValidationMessage(this);
  }

  static getErrorsMessageFromApiData(
    discountCode: ApiDiscountCodeType | null,
    totalCart: number
  ): string | null {
    if (
      (discountCode?.dateDebut &&
        dayjs().isBefore(dayjs(discountCode.dateDebut))) ||
      (discountCode?.dateFin && dayjs().isAfter(dayjs(discountCode.dateFin)))
    ) {
      return t('discountCode.error.notCorresponding');
    }
    if (discountCode?.dateFinRemiseDifferee) {
      return t('discountCode.DiscountDelayed');
    }
    if (
      (discountCode?.montantRemise &&
        totalCart - discountCode.montantRemise < 1) ||
      (discountCode?.montantMinimumPanier &&
        totalCart < discountCode.montantMinimumPanier)
    ) {
      return t('discountCode.error.errorTotalCartNotEnought');
    }
    return null;
  }

  static getDiscount(
    discountCode: DiscountCode | null,
    totalCart: number
  ): number {
    if (!discountCode?.type || !discountCode?.amountDiscount) {
      return 0;
    }
    let amount = 0;
    switch (discountCode.type) {
      case 'EURO':
        amount = discountCode.amountDiscount;
        break;
      case 'CADEAU':
        amount = 0;
        break;
      case 'POURCENTAGE': {
        const totalDiscount = (discountCode.amountDiscount / 100) * totalCart;
        amount = Math.round(totalDiscount * 100) / 100;
        break;
      }
    }
    return amount;
  }

  static getValidationMessage(discountCode: DiscountCode): string | null {
    return discountCode.type === 'CADEAU' && discountCode.description
      ? t('discountCode.shop.gift', {
          '%gift%': discountCode.description
        })
      : null;
  }
}

export default DiscountCode;
