const errors = (
  error: string,
  errorMsg: string
): {
  modalType: string;
  dataText: string | number | null;
  backToCart: boolean;
  dataTitle: string | number | null;
  backTo: string | null;
} => {
  let modalType;
  let dataText = null;
  let dataTitle = null;
  let backToCart = false;
  let backTo = null;
  switch (error) {
    case 'MAX_ITEMS_NUMBER_EXCEEDED':
      modalType = 'toomuch';
      backToCart = true;
      dataText =
        errorMsg?.split('maxItemsNumber=')?.pop()?.split('}}')?.[0] ?? '';
      break;
    case 'MAX_VOLUME_EXCEEDED':
      modalType = 'maxVolume';
      dataText = errorMsg?.split('maxVolume=')?.pop()?.split('}}L')?.[0] ?? '';
      backToCart = true;
      break;
    case 'EMPTY_CART':
    case 'EMPTY_ITEMS':
      modalType = 'empty';
      backToCart = true;
      break;
    case 'BAD_REQUEST':
    case 'MISSING_REQUEST_PARAMETER':
    case 'METHOD_ARGUMENT_NOT_VALID':
    case 'UNKNOWN_SALE_POINT':
    case 'UNKNOWN_CUSTOMER':
    case 'UNSYNCHRONIZED_CUSTOMER':
    case 'UNKNOWN_ORDER_RANK':
    case 'USER_DATA_ACCESS_DENIED':
    case 'JSON_FIELD_TYPE_NOT_VALID':
    case 'JSON_FORMAT_NOT_VALID':
    case 'COMMON_409':
      modalType = 'bad';
      backToCart = true;
      break;
    case 'FULL_SLOT':
    case 'UNAVAILABLE_SLOT':
    case 'SLOT_NOT_FOUND':
    case 'UNAVAILABLE_DELIVERY_SLOT':
      modalType = 'slotIssue';
      backTo = 'slots';
      break;
    case 'INVALID_ORDER_AMOUNT':
      modalType = 'invalidOrderAmount';
      break;
    case 'INVALID_ORDER_SNAPSHOT':
      modalType = 'invalidOrder';
      break;
    case 'ONLINE_PAYMENT_UNAVAILABLE':
    case 'ONLINE_PAYMENT_NOT_SUPPORTED':
    case 'REFUSED_PAYMENT':
      modalType = 'retryPay';
      break;
    case 'BILLING_ADDRESS_NOT_FOUND':
      modalType = 'billing';
      backTo = 'compte';
      break;
    case 'MAX_AMOUNT_EXCEEDED':
      modalType = 'maxAmount';
      dataTitle = errorMsg?.split('maxAmount=')?.pop()?.split('}}')?.[0] ?? '';
      backToCart = true;
      break;
    case 'MIN_AMOUNT_NOT_REACHED':
      modalType = 'amountNotReached';
      dataTitle = errorMsg?.split('minAmount=')?.pop()?.split('}}')?.[0] ?? '';
      backToCart = true;
      break;
    case 'LOYALTY_BALANCE_EXCEEDED':
      modalType = 'loyaltyExceeded';
      dataText =
        errorMsg?.split('loyatyBalance=')?.pop()?.split('}}')?.[0] ?? '';
      break;
    case 'BASKET_NOT_SYNC':
      modalType = 'basket';
      backToCart = true;
      break;
    case 'UNSUPPORTED_PAYMENT_TYPE':
      modalType = 'unsupportedPay';
      break;
    case 'UNSUPPORTED_PAYMENT_PROVIDER':
    case 'UNSUPPORTED_CARTS_TYPE':
    case 'MKP_SELLER_NOT_FOUND':
      modalType = 'mkpPayProblem';
      backToCart = true;
      break;
    case 'INTERNAL_SERVER_ERROR':
    case 'GATEWAY_TIMEOUT':
    case 'FEATURE_NOT_IMPLEMENTED':
    default:
      modalType = 'problem';
  }

  return {
    modalType,
    dataText,
    dataTitle,
    backToCart,
    backTo
  };
};

export default errors;
