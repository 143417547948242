export enum TypeMapping {
  AVEC_LE_CODE_DE_REDUCTION = 'discount',
  CLIENT_FIDELE = 'fidelity',
  DECOUVERTE = 'discover',
  GESTE_COMMERCIAL = 'offer',
  OPERATION_SPECIALE = 'specialOffer',
  PARRAINAGE_FILLEUL = 'sponsorshipGodson',
  PARRAINAGE_PARRAIN = 'sponsorshipGodfather',
  REMBOURSEMENT_FRAIS = 'refundFees',
  REMBOURSEMENT_PAIEMENT_EN_LIGNE = 'refundPayment',
  REMBOURSEMENT_PRODUIT = 'refundProduct'
}
