import { HeadingProps } from '@/components/molecules/heading';
import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): ComponentHeadingProps => {
  const { title = '', subhead = '', description = '' } = data;

  return {
    type: 'molecules',
    name: 'heading',
    props: {
      title,
      subhead,
      description
    }
  };
};

export default normalize;

export interface ComponentHeadingProps extends Component {
  props: HeadingProps;
}
