/* eslint  class-methods-use-this: off */
import createProducts from '@/lib/utils/products';
import Date from '@/lib/utils/date';
import { CartType, PaymentStateType } from '@/store/cart/types';
import {
  DELIVERY_TYPE_MAPPING_INVERSE,
  NEED_BAG_ELIGIBLE
} from '@/lib/model/slot';
import { AuthUserStateType } from '@/store/auth/authReducer';
import { NormalizerInterface } from '..';

class Normalizer implements NormalizerInterface {
  normalize(
    cart: CartType,
    pdv: string,
    userInformations: AuthUserStateType,
    paymentState: PaymentStateType
  ) {
    const pdvCart = cart.subCarts.find((subCart) => {
      return subCart.type === 'PDV';
    });
    if (!pdvCart) {
      return { url: null };
    }
    const { firstName, lastName, mobilePhone } = userInformations;
    const smsInfos = {
      numeroTelephoneSms: cart.trackingByPhone ? mobilePhone : null,
      optinSMS: cart.trackingByPhone
    };

    return {
      accepteSubstitution: createProducts(pdvCart).some(
        (item) => item.accepteSubstitution
      ),
      codeReduction: paymentState.discountCode?.code || null,
      consigneLivraison: cart.consigneLivraison,
      customerInfos: { firstName, lastName },
      demandeFid: false,
      idCreneau: cart.slots.selectedSlot.slot?.idSlot,
      idTypeCommande: 'COMMANDE',
      idTypeLivraison:
        DELIVERY_TYPE_MAPPING_INVERSE[cart.slots.selectedSlot.deliveryType],
      needBag:
        !NEED_BAG_ELIGIBLE.includes(
          DELIVERY_TYPE_MAPPING_INVERSE[cart.slots.selectedSlot.deliveryType]
        ) || !cart.slots.wantsBags,
      livraisonADomicile: cart.slots.selectedProvider
        ? {
            identifiantPrestataire: cart.slots.selectedProvider.id,
            privateData: cart.slots.selectedProvider.privateData
          }
        : null,
      montantDecagnotageFid: cart.selectedFID,
      nif: '',
      paymentApi: 'PAYLINE',
      smsInfos,
      typeCarte: 'CB',
      identifiantsJeux: cart.gamesContest?.isAccepted
        ? cart.gamesContest.idGameContest
        : null,
      typePaiement: paymentState.paymentType,
      utiliserAvoir: cart.selectedVoucher && cart.selectedVoucher > 0,
      panier: {
        events: cart.events,
        lastSynchronizedPanier: {
          lastSynchronizedDate:
            cart.synchronizedAt ?? Date().format('YYYY-MM-DDTHH:mm:ssZ'),
          products: createProducts(pdvCart)
        }
      },
      pdv,
      hostPayment: window.location.host
    };
  }
}

export default new Normalizer();
