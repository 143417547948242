import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import classnames from 'classnames';
import Accordion from '@/components/molecules/accordion';
import { ReactComponent as QuestionMark } from '@/assets/images/icons/question-mark.svg';
import { SlotListType } from '@/store/cart/types';

export type CarrierCostExplanationProps = {
  data: SlotListType;
  classNameContent?: string;
  classNameAccordion?: string;
  withAccordion?: boolean;
};

const CarrierCostExplanation = ({
  data,
  classNameContent,
  classNameAccordion,
  withAccordion = false
}: CarrierCostExplanationProps) => {
  const {
    housingExtraCostAvailable,
    housingExtraCost,
    floorMinExtraCost,
    VolumeExtraCostAvailable,
    volumeExtraCost,
    volumeMinExtraCost
  } = data;
  const content = (
    <ul
      className={classnames('carrierCostExplanation__infos', classNameContent)}
    >
      <li className="carrierCostExplanation__info">
        {t('delivery.howCostCalculated.infoTitle')}
      </li>
      {housingExtraCostAvailable && (housingExtraCost || 0) > 0 && (
        <li className="carrierCostExplanation__info">
          {`${t('delivery.howCostCalculated.housingExtraCost', {
            '%minEtage%': floorMinExtraCost,
            '%fraisEtage%': housingExtraCost?.toFixed(2).replace('.', ',')
          })}`}
        </li>
      )}
      {VolumeExtraCostAvailable && (volumeExtraCost || 0) > 0 && (
        <li className="carrierCostExplanation__info">
          {`${t('delivery.howCostCalculated.volumeExtraCost', {
            '%minVolume%': volumeExtraCost?.toFixed(2).replace('.', ','),
            '%fraisVolume%': volumeMinExtraCost?.toString().replace('.', ',')
          })}`}
        </li>
      )}
      <li className="carrierCostExplanation__info">
        {`${t('delivery.howCostCalculated.carrierFees')}`}
      </li>
    </ul>
  );
  return withAccordion ? (
    <Accordion
      itemsList={[
        {
          title: (
            <div className="carrierCostExplanation__accordion__title">
              <QuestionMark className="carrierCostExplanation__accordion__icon" />
              {t('delivery.howCostCalculated')}
            </div>
          ),
          htmlBody: content,
          id: '0'
        }
      ]}
      className={classnames(
        'carrierCostExplanation__accordion',
        classNameAccordion
      )}
    />
  ) : (
    content
  );
};

export default CarrierCostExplanation;
