import React from 'react';
import './styles.scss';
import Link from '@/components/atoms/link';

type MyAccountTileProps = {
  icon: JSX.Element;
  title: string;
  description?: string;
  link: {
    label: string;
    href: string;
  };
  onClick?: () => void;
};

const MyAccountTile = ({
  icon,
  title,
  description,
  link,
  onClick
}: MyAccountTileProps) => {
  return (
    <Link className="myAccountTile" href={link.href} onClick={onClick}>
      <>
        <div className="myAccountTile__icon">{icon}</div>
        <div className="myAccountTile__title">{title}</div>
        {description && (
          <div className="myAccountTile__description">{description}</div>
        )}
        <div className="myAccountTile__link">{link.label}</div>
      </>
    </Link>
  );
};

export default MyAccountTile;
