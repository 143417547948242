import React from 'react';
import './style.scss';
import Image from '@/components/atoms/image';
// import LogoRepublique from '@/assets/images/logo-republique-francaise.png';
import Link from '@/components/atoms/link';
import LogoReclam from '@/assets/images/livroreclamacoes_rouge.png';

const AlcoholBanner = () => {
  return (
    <div className="alcohol-banner">
      <div className="alcohol-banner__logo">
        <Link
          id="logo-reclamtion-footer"
          href="https://www.livroreclamacoes.pt/Inicio/"
          className="alcohol-banner__logo"
          title="reclamacoes"
          target="_blank"
        >
          <Image src={LogoReclam} alt="logo du livre de réclamation" />
        </Link>
      </div>
    </div>
  );
};

export default AlcoholBanner;
