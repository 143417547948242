import { HeaderInteractiveProps } from '@/components/organisms/headerInteractive';
import { NormalizeParams } from '..';

const normalize = ({ data, includes }: NormalizeParams) => {
  const props: HeaderInteractiveProps = {};

  if (data.background) {
    props.video = {
      attributes: {
        controls: false,
        autoPlay: true,
        loop: true,
        preload: 'auto',
        muted: true
      },
      sources: []
    };

    const videoSourceKeys = ['video', 'video2'];
    const backgroundData = includes[data.background.sys.id];

    videoSourceKeys.forEach((videoSourceKey: string) => {
      if (backgroundData.fields[videoSourceKey]) {
        const sourceData =
          includes[backgroundData.fields[videoSourceKey].sys.id];

        props.video?.sources.push({
          src: sourceData.fields.file.url,
          type: sourceData.fields.file.contentType
        });
      }
    });
  }

  if (data.heading) {
    const headingData = includes[data.heading.sys.id];
    props.title = headingData.fields.text;
  }

  return {
    type: 'organisms',
    name: 'headerInteractive',
    props
  };
};

export default normalize;
