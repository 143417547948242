import React, { memo, useState } from 'react';
import t from '@/lib/i18n';
import CommonSprite from '@/assets/images/sprites/common.svg';
import shoppingListApi, { List } from '@/lib/api/shoppingList';

import './style.scss';

type ShoppingListTitleProps = {
  list: List;
  userId: string;
};

type StateTypeProps = {
  isEditing: boolean;
  title: string;
  error: Error | null;
};

const ShoppingListTitle = ({ list, userId }: ShoppingListTitleProps) => {
  const [state, setState] = useState<StateTypeProps>({
    isEditing: false,
    title: list.name,
    error: null
  });

  const submitTitle = async (title: string) => {
    try {
      await shoppingListApi.updateListName(userId, list.id, title);
      setState({
        isEditing: false,
        title,
        error: null
      });
    } catch (error: any) {
      setState((oldState) => {
        return {
          ...oldState,
          error
        };
      });
    }
  };

  return (
    <div className="myShoppingListTitle">
      {state.isEditing && (
        <div className="myShoppingListTitle__form">
          <div className="myShoppingListTitle__fields">
            <input
              id={`${list.id}-title`}
              className="product__field__input"
              type="text"
              value={state.title}
              maxLength={50}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  await submitTitle(e.currentTarget.value);
                }
              }}
              onChange={(e) => {
                const target = e.currentTarget;
                setState((s) => ({
                  ...s,
                  title: target.value
                }));
              }}
            />
            {state.error && (
              <span className="myShoppingListTitle__fields-error">
                {t('myAccount.lists.shoppingList.updateTitle.error')}
              </span>
            )}
          </div>
          <svg
            className="myShoppingListTitle__form-picto"
            width={30}
            height={30}
            onClick={async () => {
              await submitTitle(state.title);
            }}
          >
            <use xlinkHref={`${CommonSprite}#checkBold`} />
          </svg>
        </div>
      )}
      {!state.isEditing && (
        <div
          className="myShoppingListTitle__text"
          onClick={() =>
            setState((oldState) => {
              return {
                ...oldState,
                isEditing: !oldState.isEditing
              };
            })
          }
        >
          {state.title}
        </div>
      )}
    </div>
  );
};

export default memo(ShoppingListTitle);
