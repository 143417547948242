import React from 'react';
import { withESI, ESIOptions } from '@/lib/esi';
import Content from './contentESI';

const PdvName = () => {
  const ContentESI = withESI(Content, {
    id: 'header-pdvName-content',
    type: 'templates',
    name: 'checkout/header/pdvName/content'
  });

  return <ContentESI esi={ESIOptions()} />;
};

export default PdvName;
