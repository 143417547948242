import { Component } from 'react';
import { ServiceProps } from '@/components/molecules/service';
import { NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams) => {
  const { serviceLabel, serviceId, serviceDescription } = data;
  return {
    type: 'molecules',
    name: 'service',
    props: {
      id: serviceId,
      label: serviceLabel,
      description: serviceDescription,
      className: `cmsService`
    }
  };
};

export default normalize;

export interface ComponentServiceProps extends Component {
  props: ServiceProps;
}
