import { SocialSharingProps } from '@/components/molecules/socialSharing';
import { Component, NormalizeParams } from '..';

const normalize = ({ data, extras }: NormalizeParams): SocialProps | null => {
  const { title = '', uriToShare = '' } = data;
  const { id } = extras || {};

  if (id === 'landingPage' || id === 'brandPage') {
    return {
      type: 'molecules',
      name: 'socialSharing',
      props: {
        title,
        uriToShare
      }
    };
  }
  // TODO footer have c-social item but different render and data as proxy page
  return null;
};

export default normalize;

export interface SocialProps extends Component {
  props: SocialSharingProps | null;
}
