import React, { memo } from 'react';
import { ExtensionSection } from '@/lib/model/productTypes';
import t from '@/lib/i18n';
import Title from '@/components/atoms/title';

type ExtensionsProps = {
  sections: { [key: string]: ExtensionSection };
};

const Extensions = ({ sections }: ExtensionsProps) => {
  return (
    <div className="productDetail__extensions" id="caracteristics">
      <Title
        className="productDetail__section_title"
        label={t('pdp.informations.details')}
        tag="h2"
      />
      <div className="productDetail__tableWrapper">
        <table className="productDetail__table">
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>
          <tbody>
            {Object.keys(sections).map(
              (key) =>
                sections[key].id !== 'informations' && (
                  <tr
                    key={sections[key].id}
                    className="productDetail__extensions-row"
                  >
                    <th>{sections[key].label}</th>
                    <td
                      className="productDetail__content__text"
                      dangerouslySetInnerHTML={{
                        __html: sections[key].value
                      }}
                    />
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(Extensions);
