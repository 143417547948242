import React, { memo } from 'react';
import { Offer } from '@/lib/model/productTypes';
import t from '@/lib/i18n';
import Slider from '@/components/molecules/slider';
import ProductView from '@/components/organisms/product';
import ProductModel from '@/lib/model/product';
import Title from '@/components/atoms/title';
import useEvent from '@/lib/hooks/useEvent';

type AvailableOffersProps = {
  product: ProductModel;
  currentOffer?: Offer;
  setOffer: (offer: Offer) => void;
};

type FakeProductType = {
  product: ProductModel;
  offer: Offer;
};
const AvailableOffers = ({
  currentOffer,
  product,
  setOffer
}: AvailableOffersProps) => {
  const event = useEvent();

  if (!currentOffer || !product?.offers?.length) {
    return null;
  }

  const otherOffers = product?.offers.filter(
    (offer) => offer.itemId !== currentOffer.itemId
  );

  if (!otherOffers.length) {
    return null;
  }

  const fakeProductsFromOffers: FakeProductType[] = [];

  otherOffers.forEach((offer) => {
    const item: ProductModel = {
      ...product,
      id: offer.itemId,
      prices: {
        ...product.prices,
        crossedOutPrice: offer.crossedOutPrice || null,
        productPrice: offer.price || null
      },
      bestOffer: offer,
      getBestOffer: () => offer
    };

    fakeProductsFromOffers.push({ product: item, offer });
  });

  const chooseProduct = (item: FakeProductType) => {
    event.send('offerClick', { itemId: item.offer.itemId });
    setOffer(item.offer);
    window.scrollTo(0, 0);
  };

  return (
    <div className="productDetail__availableOffers">
      <Title
        className="productDetail__section_title"
        tag="h2"
        label={t('pdp.informations.availableOffers.title')}
      />
      <div className="productDetail__availableOffers-list">
        <Slider
          className="productSlider__products"
          slidesToSlide={1}
          showDots={false}
          type="secondary"
          arrows={true}
        >
          {fakeProductsFromOffers.map((item, index) => {
            return (
              <div
                key={`slider-offer-${item.offer.itemId}`}
                onClick={() => chooseProduct(item)}
              >
                <ProductView
                  redirectOnProductClick={false}
                  sellerId={item.offer.seller.id}
                  data={item.product}
                  listPosition={index}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default memo(AvailableOffers);
