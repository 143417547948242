import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';

export type Wallet = {
  redirectURL: string;
  token: string;
};

class WalletApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  async init(userId: string): Promise<Wallet | null> {
    let wallet = null;

    try {
      const response = await this.axios.get(
        `/gestiondecompte/v1/accounts/${userId}/wallets`
      );

      wallet = {
        redirectURL: response.data.redirectURL,
        token: response.data.token
      };
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch user wallets',
        error
      });
    }

    return wallet;
  }
}

export default new WalletApi();
