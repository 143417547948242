import React, { useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import './style.scss';
import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import usePdv from '@/lib/hooks/usePdv';

export type FooterSecondaryProps = {
  links: Array<Component>;
};

const FooterSecondary = ({ links }: FooterSecondaryProps) => {
  const [secondaryFooterLinks, setSecondaryFooterLinks] = useState(links);
  useIsomorphicLayoutEffect(() => {
    const { isEcommerce } = usePdv();
    if (!isEcommerce) {
      const newLinks = links.filter(
        (link) => link.props.href !== '/stores/tos'
      );
      setSecondaryFooterLinks(newLinks);
    }
  }, []);
  return (
    <div className="footerSecondary">
      <div className="footerSecondary__content">
        <ContentfulRenderComponents components={secondaryFooterLinks} />
      </div>
    </div>
  );
};

export default FooterSecondary;
