import React from 'react';

export type FreeContentCmsProps = {
  content: string;
};

const FreeContentCms = ({ content }: FreeContentCmsProps) => (
  <div dangerouslySetInnerHTML={{ __html: content }} />
);

export default FreeContentCms;
