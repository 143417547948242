import React from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import t from '@/lib/i18n';
import './style.scss';

type PdvServicesProps = {
  services: string[];
  servicesList: ContentfulContent;
};

const Services = ({ services, servicesList }: PdvServicesProps) => {
  const filteredServicesList = servicesList?.components.filter(({ props }) =>
    services.includes(props.id)
  );

  if (Array.isArray(filteredServicesList) && !filteredServicesList.length) {
    return null;
  }

  return (
    <div className="pdvServices">
      <h2 className="pdvServices__title"> {t('pdv.services.title')}</h2>
      <p className="pdvServices__header">{t('pdv.services.subtitle')}</p>
      <div className="pdvServices__list">
        <ContentfulRenderComponents components={filteredServicesList} />
      </div>
    </div>
  );
};

export default React.memo(Services);
