import MyAccountLayout from '@/components/organisms/myAccount/layout';
import React, { useEffect, useState } from 'react';
import './style.scss';
import './widget.scss';
import t from '@/lib/i18n';
import Head from 'next/head';
import getConfigValue from '@/lib/config';
import { Content as ContentfulContent } from '@/lib/contentful';
import Image from '@/components/atoms/image';
import { AuthStateType } from '@/store/auth/authReducer';
import { useSelector } from 'react-redux';
import usePdv from '@/lib/hooks/usePdv';
import WalletApi from '@/lib/api/wallet';
import LoadableContent from '@/components/atoms/loadableContent';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import customAction from './customAction';

export type WalletProps = {
  footer: ContentfulContent;
  pageView: string;
};

type StateType = {
  isLoading: boolean;
  token: string | null;
};

declare global {
  interface Window {
    customAction: any;
  }
}

const Wallet = ({ footer, pageView }: WalletProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [state, setState] = useState<StateType>({
    isLoading: true,
    token: null
  });

  const paylineScriptUrl = getConfigValue('PAYLINE_SCRIPT_URL', '').toString();
  const paylineStyleUrl = getConfigValue('PAYLINE_STYLE_URL', '').toString();

  useEffect(() => {
    if (!userId) return;

    const { isEcommerce } = usePdv();

    if (typeof window !== 'undefined') {
      window.customAction = customAction;
    }

    if (!isEcommerce) {
      setState((oldState) => ({
        ...oldState,
        isLoading: false
      }));

      return;
    }

    (async () => {
      const wallet = await WalletApi.init(userId);

      setState((oldState) => ({
        ...oldState,
        token: wallet?.token ?? null,
        isLoading: false
      }));
    })();
  }, [userId]);

  const contentPart = (
    <LoadableContent className="myAccountWallet" loading={state.isLoading}>
      <>
        {!state.token && (
          <div className="myAccountWallet__empty">
            <svg
              className="myAccountWallet__empty_picto"
              height="150"
              width="150"
            >
              <use xlinkHref={`${MyAccountSprite}#wallet-error`} />
            </svg>
            {t('myAccount.wallet.error')}
          </div>
        )}
        {!!state.token && (
          <>
            <Head>
              {paylineScriptUrl && (
                <script type="text/javascript" src={paylineScriptUrl} async />
              )}
              {paylineScriptUrl && (
                <link href={paylineStyleUrl} rel="stylesheet" />
              )}
            </Head>
            <div className="myAccountWallet__description">
              {t('myAccount.wallet.description')}
            </div>
            <div className="myAccountWallet__content">
              <div
                id="PaylineWidget"
                className="myAccountWallet__widget"
                data-template="column"
                data-token={state.token}
                data-event-didshowstate="customAction"
                data-embeddedredirectionallowed="false"
              />
            </div>
            <div className="myAccountWallet__footer">
              <Image
                className="myAccountWallet__footer_picto"
                src="https://webpayment.payline.com/v2//res/product"
                alt="Logo payline"
                height="26"
              />
              <div className="myAccountWallet__footer_text">
                {t('myAccount.wallet.footer')}
              </div>
            </div>
          </>
        )}
      </>
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.wallet')}
      enabledId="wallet"
      content={contentPart}
      footer={footer}
      pageView={pageView}
      backTo={{
        label: t('myAccount.menu.dashboard'),
        href: '/account-management/dashboard'
      }}
    />
  );
};

export default Wallet;
