import React from 'react';
import CartSprite from '@/assets/images/sprites/cart.svg';

const AlcoholSlide = () => {
  return (
    <div className="cartModal__content hasAlcohol">
      <svg className="hasAlcohol__media" width={310} height={135}>
        <use xlinkHref={`${CartSprite}#alcohol`} />
      </svg>
      <svg className="hasAlcohol__media" width={310} height={135}>
        <use xlinkHref={`${CartSprite}#alcoholMax`} />
      </svg>
    </div>
  );
};

export default AlcoholSlide;
