import Link from '@/components/atoms/link';
import PreferencesApi from '@/lib/api/preferences';
import t from '@/lib/i18n';
import logger from '@/lib/logger/base';
import { selectUser } from '@/store/auth/authSelectors';
import { passSurveyFirstStepsAction } from '@/store/survey/actions';
import { selectSurvey } from '@/store/survey/surveySelectors';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import usePageView from '@/lib/hooks/usePageView';
import './style.scss';

export const ValidateFirstStepsForm = () => {
  const dispatch = useDispatch();
  const validateFirstStep = passSurveyFirstStepsAction(dispatch);
  const user = useSelector(selectUser, shallowEqual);
  const survey = useSelector(selectSurvey, shallowEqual);
  const pageView = usePageView();

  useEffect(() => {
    // Update user data when displaying this page
    try {
      if (user?.id) {
        PreferencesApi.update(user.id, survey);
      }
    } catch (error: any) {
      logger.error({
        message: 'Unable to save the survey responses',
        error,
        context: {
          survey
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, survey.responses.length]);

  useEffect(() => {
    pageView.send('smartConso', {
      vPath: '/compte/conso_mieux/questions_mi_parcours'
    });
  }, [pageView]);

  return (
    <div className="first-steps-confirmation">
      <h3 className="first-steps-confirmation__title">
        {t('smartconso.survey.firstSteps.title')}
      </h3>
      <p className="first-steps-confirmation__description">
        {t('smartconso.survey.firstSteps.description')}
      </p>

      <Link
        className="step-footer__button-continue"
        onClick={validateFirstStep}
        label={t('smartconsoForm.button.continue')}
        button
      />
    </div>
  );
};
