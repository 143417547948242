import React from 'react';
import t from '@/lib/i18n';
import { Address, CalendarOpeningHour } from '@/lib/model/pdv';
import Link from '@/components/atoms/link';
import OpeningDaysItem from './openingDays';

type Calendar = {
  openingHours: CalendarOpeningHour[];
};

type OpenHoursProps = {
  address: Address;
  phone?: string;
  fax?: string;
  name: string;
  calendar: Calendar;
};

const getIndexOfDaysOfWeek = (binaryDate: string) =>
  binaryDate?.split('').reduce((acc, curr, i) => {
    if (curr === '1') {
      return [...acc, i];
    }

    return acc;
  }, [] as number[]);

const getDaysOfWeek = (calendar: Calendar) => {
  const number = [...Array(7).keys()];
  const [primary, secondary] = calendar.openingHours;
  const listOfPrimaryDays = getIndexOfDaysOfWeek(primary?.days);

  return number.reduce((acc, curr) => {
    if (listOfPrimaryDays?.includes(curr)) {
      return { ...acc, [curr]: { ...primary } };
    }
    return { ...acc, [curr]: { ...secondary } };
  }, {});
};

const OpeningHours = ({
  address,
  phone,
  name,
  fax,
  calendar
}: OpenHoursProps) => {
  const { address1, address2, zipCode, city } = address;
  const itineraryLink = `https://www.google.com/maps/dir//${encodeURIComponent(
    `${name} ${address1} ${address2} ${zipCode} ${city}`
  )}`;
  return (
    <div className="wrapperOpeningHours">
      <div className="pdvOpeningHours">
        <div className="pdvOpeningHours__title">
          {t('pdv.openHours.opening')}
        </div>
        {Object.entries(getDaysOfWeek(calendar)).map(
          ([key, { startHours, endHours }]: any) => (
            <OpeningDaysItem
              key={`pdvOpeningHours--${key}`}
              dayKey={parseInt(key, 10)}
              startHours={startHours}
              endHours={endHours}
            />
          )
        )}
      </div>
      <div className="pdvOpeningHours">
        <address className="address">
          <div className="pdvOpeningHours__title">
            {t('pdv.openHours.address')}
          </div>
          <div className="pdvOpeningHours__list__custom">
            <div className="pdvOpeningHours__item">{address1}</div>
          </div>
          {address2 && (
            <div className="pdvOpeningHours__list__custom">
              <div className="pdvOpeningHours__item">{address2}</div>
            </div>
          )}
          <div className="pdvOpeningHours__list__custom">
            <div className="pdvOpeningHours__item">{`${zipCode} ${city}`}</div>
          </div>
        </address>
        <div className="pdvOpeningHours__list__custom">
          <div className="pdvOpeningHours__item__interaryLink">
            <Link
              target="_blank"
              href={itineraryLink}
              label={`> ${t('pdv.openHours.itinerary')}`}
            />
          </div>
        </div>
        <div className="pdvOpeningHours__title">
          {t('pdv.openHours.contact')}
        </div>
        <div className="pdvOpeningHours__list">
          <div className="pdvOpeningHours__item">
            {t('pdv.openHours.phone')}
          </div>
          <div className="pdvOpeningHours__item__shifted">{phone}</div>
        </div>
        <div className="pdvOpeningHours__list">
          <div className="pdvOpeningHours__item__legalNotice">
            {t('common.phoneLegalNotice')}
          </div>
        </div>
        <div className="pdvOpeningHours__list">
          <div className="pdvOpeningHours__item">{t('pdv.openHours.fax')}</div>
          <div className="pdvOpeningHours__item__shifted">{fax && fax}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(OpeningHours);
