import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import useClickOut from '@/lib/hooks/useClickOut';
import Button from '@/components/atoms/button';
import classnames from 'classnames';
import t from '@/lib/i18n';
import CommonSprite from '@/assets/images/sprites/common.svg';

export type Option = {
  label: string;
  active?: boolean;
  selected?: boolean;
  value: string | number;
  extra?: { [key: string]: string | number | null };
};

export type Options = Array<Option>;

export type SelectProps = {
  className?: string;
  label: string | JSX.Element;
  options: Options;
  multiple?: boolean;
  name: string;
  apply: (name: string, v: Option | Options | null) => void;
};

const Select = ({
  className,
  label,
  options,
  multiple = false,
  name,
  apply
}: SelectProps) => {
  const [clickOutRef, clickOutHandler] = useClickOut();
  const isFirstRun = useRef(true);

  const [data, setData] = useState(() => {
    let dataOptions = [...options];

    dataOptions = dataOptions.map((option) => ({
      ...option,
      selected: false
    }));

    return {
      options: dataOptions,
      layerIsOpen: false
    };
  });

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    setData((actualData) => {
      let dataOptions = [...options];

      dataOptions = dataOptions.map((option) => ({
        ...option,
        selected: false
      }));

      return {
        ...actualData,
        options: dataOptions
      };
    });
  }, [options]);

  const sendSelected = () => {
    const value =
      data.options.filter((option) => option.selected !== option.active) ??
      null;

    setData((actualData) => {
      const newOptions = actualData.options.map((option) => {
        const newOption = { ...option };

        if (newOption.selected) {
          newOption.active = !newOption.active;
          newOption.selected = false;
        }

        return newOption;
      });

      return {
        options: newOptions,
        layerIsOpen: false
      };
    });

    apply(name, value);
  };

  const selectOption = (selectedOption: Option) => {
    setData((actualData) => {
      const newOptions = actualData.options.map((option) => {
        if (option.value === selectedOption.value) {
          return { ...option, selected: !option.selected };
        }

        return multiple ? option : { ...option, active: false };
      });

      return {
        options: newOptions,
        layerIsOpen: multiple
      };
    });

    if (!multiple) {
      apply(name, selectedOption);
    }
  };

  clickOutHandler(() => {
    if (data.layerIsOpen) {
      setData((actualData) => {
        const newOptions = actualData.options.map((option) => {
          return { ...option, selected: false };
        });

        return {
          options: newOptions,
          layerIsOpen: false
        };
      });
    }
  });

  return (
    <div
      ref={clickOutRef}
      className={classnames('select', className, {
        'select--active': data.layerIsOpen
      })}
    >
      <div
        onClick={() => {
          setData((actualData) => {
            return {
              ...actualData,
              layerIsOpen: !actualData.layerIsOpen
            };
          });
        }}
        className="select__label"
      >
        {(!multiple && data.options.find((option) => option.active)?.label) ||
          label}
        <svg className="select__arrow" height="14" width="14">
          <use xlinkHref={`${CommonSprite}#arrow-up`} />
        </svg>
      </div>
      {data.layerIsOpen && (
        <div className="select__options">
          <ul className="select__list">
            {data.options.map((option) => {
              const isActive =
                (multiple && option.active !== option.selected) ||
                (!multiple && option.active);

              return (
                <li key={option.value}>
                  <div
                    className={classnames({
                      select__option: true,
                      'select__option--active': isActive
                    })}
                    onClick={() => {
                      selectOption(option);
                    }}
                  >
                    {option.label}
                    {isActive && (
                      <svg className="select__check" height="14" width="14">
                        <use xlinkHref={`${CommonSprite}#check`} />
                      </svg>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
          {multiple && (
            <div className="select__action">
              <Button
                className="select__btn"
                onClick={sendSelected}
                label={t('common.apply')}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Select;
