import React from 'react';
import t from '@/lib/i18n';
import { Informations, Offer } from '@/lib/model/productTypes';

import Product from '@/lib/model/product';
import AvailableOffers from './availableOffers';
import Extensions from './extensions';

export type DescriptionProps = {
  informations: Informations;
  currentOffer?: Offer;
  product?: Product;
  setOffer: (offer: Offer) => void;
  hasCaracteristic: boolean;
  afterTitle?: JSX.Element;
};

const Description = ({
  informations,
  product,
  setOffer,
  currentOffer,
  hasCaracteristic,
  afterTitle
}: DescriptionProps) => {
  const origin = informations?.originPdp ?? informations?.originPlp;
  const { section } = informations?.dynamicFieldInformations;
  return (
    <section id="description" className="productDetail__section">
      <div className="container container--md">
        <h2 className="productDetail__section_title">
          {t('pdp.informations.description.title')}
        </h2>
        {afterTitle && (
          <div className="productDetail__section_after_title">{afterTitle}</div>
        )}
        <div className="productDetail__section_content">
          {hasCaracteristic && (
            <>
              <span className="productDetail__content__title">
                {t('pdp.informations.details')}
              </span>
              <div className="productDetail__content__section">
                {origin && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.origin')}
                    <span className="productDetail__content__text--xl">
                      {origin}
                    </span>
                  </p>
                )}
                {informations.countryTransformation && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.countryTransformation', {
                      '%countryTransformation%':
                        informations.countryTransformation
                    })}
                  </p>
                )}
                {informations.variety && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.variety', {
                      '%variety%': informations.variety
                    })}
                  </p>
                )}
                {informations.category && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.category', {
                      '%category%': informations.category
                    })}
                  </p>
                )}
                {informations.alcoholRate && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.alcoholRate', {
                      '%rate%': informations.alcoholRate
                    })}
                  </p>
                )}
                {informations.caliber && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.caliber', {
                      '%caliber%': informations.caliber
                    })}
                  </p>
                )}
                {informations.chemicalTreatment && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.chemicalTreatment', {
                      '%chemicalTreatment%': informations.chemicalTreatment
                    })}
                  </p>
                )}
                {informations.slaughterCountry && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.slaughterCountry')}
                    <span className="productDetail__content__text--xl">
                      {informations.slaughterCountry}
                    </span>
                  </p>
                )}
                {informations.species && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.species', {
                      '%species%': informations.species
                    })}
                  </p>
                )}
                {informations.animalCountryOrigin && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.animalCountryOrigin', {
                      '%animalCountryOrigin%': informations.animalCountryOrigin
                    })}
                  </p>
                )}
                {informations.animalCountryGrowing && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.animalCountryGrowing', {
                      '%animalCountryGrowing%':
                        informations.animalCountryGrowing
                    })}
                  </p>
                )}
                {informations.fishingArea && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.fishingArea')}
                    <span className="productDetail__content__text--xl">
                      {informations.fishingArea}
                    </span>
                  </p>
                )}
                {informations.subFishingArea && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.subFishingArea', {
                      '%subFishingArea%': informations.subFishingArea
                    })}
                  </p>
                )}
                {informations.methodeCapture && (
                  <p className="productDetail__content__text">
                    {t('pdp.informations.methodeCapture')}
                    <span className="productDetail__content__text--xl">
                      {informations.methodeCapture}
                    </span>
                  </p>
                )}
              </div>
            </>
          )}
          {informations.advantages && (
            <div className="productDetail__content__section">
              <span className="productDetail__content__title">
                {t('pdp.informations.advantages')}
              </span>
              <div
                className="productDetail__content__text"
                dangerouslySetInnerHTML={{
                  __html: informations.advantages
                }}
              />
            </div>
          )}
          {section.map((field) => (
            <div className="productDetail__content__section" key={field?.id}>
              <span className="productDetail__content__title">
                {field?.label}
              </span>
              <div
                className="productDetail__content__text"
                dangerouslySetInnerHTML={{
                  __html: field?.value
                }}
              />
            </div>
          ))}
          {informations.emballage && (
            <div className="productDetail__content__section">
              <span className="productDetail__content__title">
                {t('pdp.informations.emballage')}
              </span>
              <div
                className="productDetail__content__text"
                dangerouslySetInnerHTML={{
                  __html: informations.emballage
                }}
              />
            </div>
          )}
          {informations.extension?.sections?.informations && (
            <div className="productDetail__content__section">
              <span className="productDetail__content__title">
                {informations.extension?.sections?.informations.label}
              </span>
              <div
                className="productDetail__content__text"
                dangerouslySetInnerHTML={{
                  __html: informations.extension?.sections?.informations.value
                }}
              />
            </div>
          )}
          {informations.features?.sections &&
            !!Object.keys(informations.features?.sections)?.length && (
              <Extensions sections={informations.features?.sections} />
            )}
          {product?.offers && (
            <div className="productDetail__content__section">
              <AvailableOffers
                currentOffer={currentOffer}
                product={product}
                setOffer={setOffer}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Description;
