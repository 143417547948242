import { normalize as BaseNormalize, NormalizeParams } from '..';

const normalize = ({ data, includes, context = {} }: NormalizeParams) => {
  let elements = null;
  let key = null;

  if (context.isEcommerce || false) {
    key = 'eCommerce';
  } else {
    key = 'nonECommerce';
  }

  const itemData = includes[data[key].sys.id] || null;

  if (itemData) {
    elements = BaseNormalize({ data: itemData, includes });
  }

  return elements;
};

export default normalize;
