import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import FormRadio from '@/components/atoms/formRadio';
import FormInput from '@/components/atoms/formInput';
import FormSelect from '@/components/atoms/formSelect';
import countries from '@/lib/utils/countries';
import { Controller, FieldError, useForm } from 'react-hook-form';
import t from '@/lib/i18n';
import AccountApi from '@/lib/api/account';
import Button from '@/components/atoms/button';
import {
  RESET_USER_REGISTER,
  USER_CONTACT
} from '@/store/register/registerActions';
import { ReactComponent as ArrowIcon } from '@/assets/images/icons/arrow-down.svg';
import { NewUserLocal } from '@/store/register/types';
import Checkbox from '@/components/atoms/formCheckbox';
import { getFidelityCardNumberValidationError } from '@/lib/api/fidelity/exceptions';

import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import classNames from 'classnames';
import Link from '@/components/atoms/link';
import Modal from '@/components/organisms/modal';
import { isValidAddress } from '@/lib/utils/stringUtils';
import useEvent from '@/lib/hooks/useEvent';
import CityWithZipCode from '@/components/organisms/cityWithZipCode';
import getConfigValue from '@/lib/config';
import { validatePhoneNumber } from '@/lib/utils/myAccount';

const IS_FIDELITY_ENABLED = getConfigValue(
  'IS_FIDELITY_ENABLED',
  false
).toBoolean();

type ContactProps = {
  userType: string;
  hasFidelityCard: number;
  address: string;
  additionalAddress: string;
  additionalInformation: string;
  zipCode: string;
  city: string;
  country: string;
  phone: string;
  mobilePhone: string;
  itmEmail: number;
  itmSms: number;
  itmLetter: number;
  partnerEmail: number;
  partnerSms: number;
  personalCommunication: boolean;
};

export type StepProps = {
  cgu: ContentfulContent;
  legals: ContentfulContent;
  user: NewUserLocal;
  dispatch: any;
};

type ContactState = {
  modalIsOpen: boolean;
  isLoading: boolean;
  submitError: [];
  fidError: null | Array<JSX.Element>;
  fidErrTitle: string;
  cguInfoModal: boolean;
  phonesInputsError: boolean;
  openFidModal: boolean;
};

const Contact = ({ dispatch, user, cgu, legals }: StepProps) => {
  const [show, setShow] = useState(true);
  const [state, setState] = useState<ContactState>({
    modalIsOpen: false,
    isLoading: false,
    submitError: [],
    fidError: null,
    fidErrTitle: '',
    cguInfoModal: false,
    phonesInputsError: false,
    openFidModal: false
  });
  const router = useRouter();
  const event = useEvent();

  const {
    control,
    errors,
    register,
    setValue,
    handleSubmit,
    getValues,
    trigger,
    watch,
    setError,
    clearErrors
  } = useForm<ContactProps>({
    shouldUnregister: false,
    defaultValues: {
      address: user.address || '',
      additionalAddress: user.additionalAddress || '',
      additionalInformation: user.additionalInformation || '',
      zipCode: user.zipCode || '',
      city: user.city || '',
      country: user.country || 'PT',
      phone: user.phone || '',
      mobilePhone: user.mobilePhone || '',
      itmEmail: user.itmEmail,
      itmSms: user.itmSms,
      itmLetter: user.itmLetter,
      partnerEmail: 0,
      partnerSms: 0,
      //  partnerEmail: user.partnerEmail,
      //  partnerSms: user.partnerSms,
      personalCommunication: user.personalCommunication || false
    }
  });

  const onSubmit = async (data: ContactProps) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true
    }));

    if (Number(data.partnerSms) === 1 || Number(data.itmSms) === 1) {
      if (data.mobilePhone === '') {
        setError('mobilePhone', {
          message: t('createAccount.form.mobilePhone.required')
        });
        setState((prevState) => ({
          ...prevState,
          isLoading: false
        }));
        return;
      }
    }

    const userContact = {
      address: data.address,
      additionalAddress: data.additionalAddress,
      additionalInformation: data.additionalInformation,
      zipCode: data.zipCode,
      city: data.city,
      country: data.country,
      phone: data.phone || '',
      mobilePhone: data.mobilePhone,
      itmEmail: Number(data.itmEmail),
      itmSms: Number(data.itmSms),
      itmLetter: Number(data.itmLetter),
      partnerEmail: 0,
      partnerSms: 0,
      //  partnerEmail: Number(data.partnerEmail),
      //  partnerSms: Number(data.partnerSms),
      personalCommunication: !data.personalCommunication
    };

    const dispatchForm = () => {
      dispatch({
        type: USER_CONTACT,
        payload: {
          ...userContact
        }
      });
    };

    if (user.isPro || user.hasFidelityCard || !IS_FIDELITY_ENABLED) {
      const isCreated = await AccountApi.createAccount({
        ...user,
        ...userContact
      });
      switch (isCreated.status) {
        case 'success':
          dispatch({
            type: RESET_USER_REGISTER
          });
          event.send('api', {
            type: 'success',
            name: 'signUp'
          });
          router.push(
            user.hasFidelityCard
              ? '/my-account/create-my-account/confirmation'
              : '/my-account/create-my-account/validation'
          );
          break;
        case 'error': {
          event.send('api', {
            type: 'error',
            name: 'signUp'
          });
          const isCardAlreadyAssociated = isCreated.data?.find(
            (err: { code: string }) =>
              err.code === 'LOYALTY_CARD_ALREADY_ASSOCIATED'
          );
          const errorTitle = isCardAlreadyAssociated
            ? 'form.error.accountCreation.LOYALTY_CARD_ALREADY_ASSOCIATED.title'
            : 'connexion.internalError.title';
          const fidErr = isCardAlreadyAssociated ? (
            <p key="LOYALTY_CARD_ALREADY_ASSOCIATED">
              {t(`form.error.accountCreation.LOYALTY_CARD_ALREADY_ASSOCIATED`)}
            </p>
          ) : (
            isCreated.data.map((err: { code: string }) => (
              <p key={err.code}>
                {getFidelityCardNumberValidationError(err.code)?.getTrad()}
              </p>
            ))
          );

          setState((prevState) => ({
            ...prevState,
            submitError: isCreated.data,
            isLoading: false
          }));
          if (fidErr) {
            setState((s) => ({
              ...s,
              fidError: fidErr,
              fidErrTitle: errorTitle,
              openFidModal: true
            }));
          }
          break;
        }
      }
    } else {
      dispatchForm();
      router.push('/my-account/create-my-account/fidelity');
    }
  };

  const watchFields = watch(['phone', 'mobilePhone', 'partnerSms', 'itmSms']);

  useEffect(() => {
    if (state.phonesInputsError) {
      setError('phone', { message: ' ' });
      setError('mobilePhone', { message: ' ' });
    }
  }, [state.phonesInputsError, setError]);

  useEffect(() => {
    if (
      Number(watchFields.partnerSms) === 0 &&
      Number(watchFields.itmSms) === 0
    ) {
      clearErrors('mobilePhone');
    }
  }, [watchFields.partnerSms, watchFields.itmSms, clearErrors]);

  const getPhoneAndMobilePhoneErrorMessage = (type: string): string => {
    return type
      ? t(`form.error.${errors.mobilePhone?.type}`)
      : (errors.mobilePhone?.message as string);
  };

  return (
    <form className="formSubscribe" onSubmit={handleSubmit(onSubmit)}>
      <div className="title"> {t('createAccount.form.details')}</div>
      <div className="box">
        <div className="card">
          <FormInput
            id="address"
            name="address"
            className="inputRegister"
            type="text"
            label={`${t('address.label.address')} *`}
            message={`
                    ${t('register.address.message')} /
                    ${t('form.error.maxLength', {
                      '%max%': 38
                    })}`}
            maxLength={38}
            ref={register({
              required: true,
              validate: {
                isValidAddressLine: isValidAddress
              }
            })}
            errorMessage={
              errors.address ? t(`form.error.${errors.address.type}`) : ''
            }
          />
          <FormInput
            id="additionalAddress"
            name="additionalAddress"
            type="text"
            maxLength={38}
            label={`${t('createAccount.form.label.additionalAddress')}`}
            ref={register({
              validate: {
                isValidAddressLine: isValidAddress
              }
            })}
            errorMessage={
              errors.additionalAddress
                ? t(`form.error.${errors.additionalAddress.type}`)
                : ''
            }
            message={`
                    ${t('register.additionalAddress.message')} /
                    ${t('form.error.maxLength', {
                      '%max%': 38
                    })}`}
            className="inputRegister"
          />
          <FormInput
            id="additionalInformation"
            name="additionalInformation"
            type="text"
            maxLength={38}
            label={t('register.additionalInformation.label')}
            ref={register({
              validate: {
                isValidAddressLine: isValidAddress
              }
            })}
            errorMessage={
              errors.additionalInformation
                ? t(`form.error.${errors.additionalInformation.type}`)
                : ''
            }
            message={`
                    ${t('register.additionalInformation.message')} /
                    ${t('form.error.maxLength', {
                      '%max%': 38
                    })}`}
            className="inputRegister"
          />
          <CityWithZipCode
            oldCity={user.city}
            zipCodeClassName="inputRegister"
            getValues={getValues}
            onCitiesLoaded={(newValue) => setValue('city', newValue)}
            errors={errors}
            control={control}
            cityClassName="myAddressForm__select"
            register={register}
            trigger={trigger}
          />
          <Controller
            control={control}
            name="country"
            render={({ value, name, onChange }) => (
              <FormSelect
                id={name}
                name={name}
                value={value}
                onChange={(newValue) => {
                  setValue('city', '', { shouldValidate: true });
                  setValue('zipCode', '', { shouldValidate: true });
                  onChange(newValue);
                }}
                label={`${t('address.label.country')} *`}
                className="inputRegister"
                ref={register({ required: true })}
                options={countries.map((country) => {
                  return {
                    label: country.label,
                    value: country.code
                  };
                })}
                errorMessage={errors.country && t('register.form.email.error')}
              />
            )}
          />
          <div className="formRegister phoneInputs">
            <div className="phoneInputs__label">
              {`${t('createAccount.form.phoneLabel')} *`}
            </div>
            <FormInput
              id="phone"
              name="phone"
              label={t('myAccount.myDetails.label.landlinePhone')}
              className="updateProfile__input"
              maxLength={16}
              type="tel"
              onChange={() =>
                setState((s) => ({ ...s, phonesInputsError: false }))
              }
              ref={register({
                validate: validatePhoneNumber('mobilePhone', getValues)
              })}
              errorMessage={
                errors.phone ? t(`form.error.${errors.phone.type}`) : ''
              }
            />
            <FormInput
              id="mobilePhone"
              name="mobilePhone"
              type="tel"
              label={t('myAccount.myDetails.label.mobilePhone')}
              className="updateProfile__input"
              maxLength={16}
              onChange={() => clearErrors('mobilePhone')}
              ref={register({
                validate: {
                  invalidPhoneNumber: validatePhoneNumber('phone', getValues)
                    .invalidPhoneNumber
                }
              })}
              errorMessage={
                errors.mobilePhone
                  ? getPhoneAndMobilePhoneErrorMessage(errors.mobilePhone?.type)
                  : ''
              }
            />
            {state.phonesInputsError && (
              <div className="inputRegister__error">
                {t('register.form.requiredPhone')}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="box">
        <div className="card">
          <div className="card__title">
            {t('createAccount.form.OffersTitle')}
          </div>
          <div className="card__message">
            {t('createAccount.form.itmOfferDescription')}
          </div>
          <Controller
            control={control}
            name="itmEmail"
            render={({ onChange, value, name }) => (
              <FormRadio
                name={name}
                className="inputRegister"
                ref={register({ required: true })}
                label={`${t('createAccount.form.byEmail')} *`}
                options={[
                  {
                    id: 'yes',
                    label: t('createAccount.form.yes'),
                    value: 1
                  },
                  {
                    id: 'no',
                    label: t('createAccount.form.no'),
                    value: 0
                  }
                ]}
                onChange={(newValue) => onChange(newValue)}
                value={Number(value)}
                errorMessage={
                  errors.itmEmail
                    ? t(`form.error.${(errors.itmEmail as FieldError).type}`)
                    : ''
                }
              />
            )}
          />
          <Controller
            control={control}
            name="itmSms"
            render={({ onChange, value, name }) => {
              return (
                <FormRadio
                  name={name}
                  className="inputRegister"
                  label={`${t('createAccount.form.byMessage')} *`}
                  ref={register({
                    required: true
                  })}
                  options={[
                    {
                      id: 'yes',
                      label: t('createAccount.form.yes'),
                      value: 1
                    },
                    {
                      id: 'no',
                      label: t('createAccount.form.no'),
                      value: 0
                    }
                  ]}
                  onChange={(newValue) => onChange(newValue)}
                  value={Number(value)}
                  errorMessage={
                    errors.itmSms
                      ? t(`form.error.${(errors.itmSms as FieldError).type}`)
                      : ''
                  }
                />
              );
            }}
          />
          <Controller
            control={control}
            name="itmLetter"
            render={({ onChange, value, name }) => {
              return (
                <FormRadio
                  name={name}
                  className="inputRegister"
                  label={`${t('createAccount.form.byLetter')} *`}
                  ref={register({
                    required: true
                  })}
                  options={[
                    {
                      id: 'yes',
                      label: t('createAccount.form.yes'),
                      value: 1
                    },
                    {
                      id: 'no',
                      label: t('createAccount.form.no'),
                      value: 0
                    }
                  ]}
                  onChange={(newValue) => onChange(newValue)}
                  value={Number(value)}
                  errorMessage={
                    errors.itmLetter
                      ? t(`form.error.${(errors.itmLetter as FieldError).type}`)
                      : ''
                  }
                />
              );
            }}
          />

          {/*
          <div
            className="card__message"
            dangerouslySetInnerHTML={{
              __html: t('createAccount.form.partnersOfferDescription')
            }}
          />
          */}

          {/*
          <Controller
            control={control}
            name="partnerEmail"
            render={({ onChange, value, name }) => (
              <FormRadio
                ref={register({ required: true })}
                name={name}
                id={name}
                className="inputRegister"
                label={`${t('createAccount.form.byEmail')} *`}
                options={[
                  {
                    id: 'yes',
                    label: t('createAccount.form.yes'),
                    value: 1
                  },
                  {
                    id: 'no',
                    label: t('createAccount.form.no'),
                    value: 0
                  }
                ]}
                onChange={(newValue) => onChange(newValue)}
                value={Number(value)}
                errorMessage={
                  errors.partnerEmail
                    ? t(
                        `form.error.${(errors.partnerEmail as FieldError).type}`
                      )
                    : ''
                }
              />
            )}
          />
          <Controller
            control={control}
            name="partnerSms"
            render={({ onChange, value, name }) => {
              return (
                <FormRadio
                  ref={register({
                    required: true
                  })}
                  name={name}
                  className="inputRegister"
                  label={`${t('createAccount.form.byMessage')} *`}
                  options={[
                    {
                      id: 'yes',
                      label: t('createAccount.form.yes'),
                      value: 1
                    },
                    {
                      id: 'no',
                      label: t('createAccount.form.no'),
                      value: 0
                    }
                  ]}
                  onChange={(newValue) => onChange(newValue)}
                  value={Number(value)}
                  errorMessage={
                    errors.partnerSms
                      ? t(
                          `form.error.${(errors.partnerSms as FieldError).type}`
                        )
                      : ''
                  }
                />
              );
            }}
          />
          */}
          <div className="userSettingCustomized">
            <div className="solComMessage">
              {t('createAccount.form.personalDataContent')}
            </div>
            {show && (
              <Checkbox
                name="personalCommunication"
                id="personalCommunication"
                className="personnalCommunicationCheckbox"
                label={t('register.personnalCommunication.message')}
                checked={getValues('personalCommunication')}
                onChange={(value: boolean) => {
                  setValue('personalCommunication', value);
                }}
              />
            )}
            <div
              className={classNames({
                arrowIcon: true,
                'arrowIcon--open': show
              })}
              onClick={() => setShow(!show)}
            >
              <ArrowIcon width={20} height={20} fill="#000000" />
            </div>
          </div>
        </div>
      </div>
      <div className="box">
        <p className="card cguMessage">
          {t('register.cgu.message')}
          <Link onClick={() => setState((s) => ({ ...s, cguInfoModal: true }))}>
            {t('register.cgu.link')}
          </Link>
        </p>
        <Modal
          className="cguModal"
          open={state.cguInfoModal}
          scrollable={true}
          closable={true}
          onClose={() =>
            setState((s) => ({
              ...s,
              cguInfoModal: false
            }))
          }
        >
          <div className={classNames('cguWrapper')}>
            <ContentfulRenderComponents components={legals.components} />
          </div>
        </Modal>
      </div>
      <Modal
        open={state.openFidModal}
        scrollable={true}
        closable={false}
        confirmBtn={{
          label: t('cart.modal.getIt'),
          onClick: () => {
            router.push('/my-account/create-my-account/identity', undefined, {
              shallow: true
            });
          }
        }}
      >
        <div className="errorModal">
          <div className="errorModal__title">{t(state.fidErrTitle)}</div>
          {state.fidError}
        </div>
      </Modal>
      {/* Temporary Error Message */}
      {state.submitError?.length > 0 && (
        <div className="submittingError">
          {state.submitError.map(({ message }) => (
            <li key={`err--${message}`}>{message}</li>
          ))}
        </div>
      )}
      {/* End : Temporary Error Message */}
      <div className="footer">
        <div className="footer__required">
          {t('register.footer.requiredFields')}
        </div>
        <div className="footer__btn">
          <Button
            id="sign-up-form-contact-back-button"
            color="secondary"
            onClick={() =>
              router.push('/my-account/create-my-account/identity', undefined, {
                shallow: true
              })
            }
            disabled={state.isLoading}
            label={t('common.back')}
          />
          <Button
            id="sign-up-form-contact-next-button"
            type="submit"
            onClick={() => {
              trigger(['address', 'zipCode', 'city', 'country', 'mobilePhone']);
            }}
            label={
              user.hasFidelityCard || user.isPro
                ? t('myAccount.myDetails.confirm')
                : t('common.next')
            }
            loading={state.isLoading}
          />
        </div>
      </div>
    </form>
  );
};

export default Contact;
