import { AccordionItemProps } from '@/components/molecules/accordion';
import { normalize as BaseNormalize, NormalizeParams, Component } from '..';

const normalize = ({ data, includes }: NormalizeParams): AccordionItemProps => {
  const { title = '', body, id = null } = data;

  const itemsBodiesList: Array<Component> = [];

  body.forEach((element: any) => {
    const itemData = includes[element.sys.id] || null;
    itemsBodiesList.push(BaseNormalize({ data: itemData, includes }));
  });

  return {
    title,
    body: itemsBodiesList,
    id
  };
};

export default normalize;
