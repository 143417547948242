import { CollectionGridProps } from '@/components/molecules/collectionGrid';
import {
  Component,
  normalize as BaseNormalize,
  NormalizeParams
} from '@/lib/contentful';

const normalize = ({
  data,
  includes
}: NormalizeParams): ComponentTitleProps => {
  const components: Array<Component> = [];
  const columnNumber = parseInt(data.columns.split(' ')[0], 10);
  data.components.forEach((component: any) => {
    const componentData = includes[component.sys.id];
    if (componentData) {
      const elt: Component = BaseNormalize({ data: componentData, includes });
      components.push(elt);
    }
  });

  return {
    type: 'molecules',
    name: 'collectionGrid',
    props: {
      components,
      columnNumber
    }
  };
};

export default normalize;

export interface ComponentTitleProps extends Component {
  props: CollectionGridProps;
}
