import React, { useEffect, useState } from 'react';
import './style.scss';
import CartSprite from '@/assets/images/sprites/cart.svg';
import t from '@/lib/i18n';
import FormInput from '@/components/atoms/formInput';
import { useForm } from 'react-hook-form';
import UserInfoApi from '@/lib/api/userInformation';
import Loader from '@/components/atoms/loader';
import { usePrevious } from '@/lib/hooks/usePrevious';
import { regexValidPhone } from '@/lib/utils/myAccount';

type PhoneState = {
  value: null | string;
  modify: boolean;
  isLoading: boolean;
};

const Phone = ({
  mobilePhone,
  userId,
  phoneIsValid,
  isLoading,
  isValidPhone,
  isRequired
}: {
  mobilePhone: string | null;
  isLoading: boolean;
  userId: string;
  phoneIsValid: (value: boolean, newPhone: string | null) => void;
  isValidPhone: boolean;
  isRequired: boolean;
}) => {
  const [phone, setPhone] = useState<PhoneState>({
    value: mobilePhone || null,
    modify: false,
    isLoading: false
  });
  const { register, errors, handleSubmit, trigger, setValue } = useForm<any>();

  const updatePhone = async (data: any) => {
    setPhone((state) => ({ ...state, isLoading: true }));
    try {
      await UserInfoApi.changePhone(userId, data.mobilePhone);
      setPhone((state) => ({
        ...state,
        isLoading: false,
        modify: false,
        value: data.mobilePhone
      }));
      phoneIsValid(true, data.mobilePhone);
    } catch (error: any) {
      setPhone((state) => ({
        ...state,
        isLoading: false,
        modify: false,
        value: data.mobilePhone
      }));
      phoneIsValid(false, mobilePhone);
      // Todo: handle change phone error
    }
  };

  const prevPhoneValue = usePrevious(mobilePhone);
  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if ((!isLoading && prevIsLoading) || (!prevPhoneValue && mobilePhone)) {
      if (!prevPhoneValue && mobilePhone) {
        if (mobilePhone?.match(regexValidPhone)) {
          setValue('mobilePhone', mobilePhone);
          phoneIsValid(true, mobilePhone);
        }
        trigger();
      }
      setPhone((p) => ({
        ...p,
        value: mobilePhone,
        modify:
          (isRequired && !mobilePhone) ||
          (isRequired && !mobilePhone?.match(regexValidPhone))
      }));
    }
  }, [
    mobilePhone,
    prevPhoneValue,
    trigger,
    setValue,
    isLoading,
    prevIsLoading,
    phoneIsValid,
    isRequired
  ]);

  useEffect(() => {
    if (phone.modify && !phone.isLoading && !errors.mobilePhone) {
      trigger();
    }

    if (
      phone.modify &&
      !phone.isLoading &&
      errors.mobilePhone &&
      isValidPhone
    ) {
      phoneIsValid(false, phone.value);
    }
  }, [
    phone.modify,
    phone.isLoading,
    phone.value,
    errors.mobilePhone,
    phoneIsValid,
    trigger,
    isValidPhone
  ]);

  return (
    <>
      {isLoading && <Loader size="medium" />}
      {!isLoading && (
        <div className="cartInfo">
          <svg className="cartInfo__picto" height="25" width="25">
            <use xlinkHref={`${CartSprite}#phone`} />
          </svg>
          <div className="cartInfo__content">
            <span className="cartInfo__title">{t('cart.form.tel.label')}</span>
            <p className="cartInfo__item">{t('cart.form.tel.text')}</p>
            {!phone.value && !phone.modify && !isRequired && (
              <button
                onClick={() => {
                  setPhone((state) => ({ ...state, modify: true }));
                }}
                type="button"
                className="cartInfo__modify cartInfo__modify-phone"
                id="btn-cartInfoAddPhoneNumber"
              >
                {t('cart.slot.addPhoneNumber')}
              </button>
            )}
            {phone.value && !phone.modify && (
              <div className="cartInfo__modifyContainer">
                <span className="cartInfo__title">{phone.value}</span>
                <button
                  onClick={() => {
                    setPhone((state) => ({ ...state, modify: true }));
                  }}
                  type="button"
                  className="cartInfo__modify cartInfo__modify-phone"
                >
                  {t('cart.slot.modify')}
                </button>
              </div>
            )}
            {phone.modify && (
              <form onSubmit={handleSubmit(updatePhone)}>
                <FormInput
                  id="mobilePhone"
                  name="mobilePhone"
                  isLoading={phone.isLoading}
                  type="tel"
                  buttonLabel={t('common.add')}
                  className="trackingInput"
                  placeholder={t('cart.form.tel.placeholder')}
                  ref={register({
                    required: isRequired,
                    pattern: regexValidPhone
                  })}
                  value={phone.value || undefined}
                  errorMessage={errors.mobilePhone ? t('cart.error.phone') : ''}
                />
              </form>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Phone;
