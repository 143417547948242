import React from 'react';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import { withESI, ESIOptions } from '@/lib/esi';
import t from '@/lib/i18n';
import Content from './contentESI';
import { MyStickersProps } from './types';

const MyStickers = ({ footer, pageView }: MyStickersProps) => {
  const ContentESI = withESI(Content, {
    id: 'mystickers-content',
    type: 'templates',
    name: 'myAccount/fidelity/myStickers/content'
  });

  const contentPart = <ContentESI esi={ESIOptions()} />;

  return (
    <MyAccountLayout
      enabledId="fidelity"
      content={contentPart}
      footer={footer}
      pageView={pageView}
      backTo={{
        label: t('myAccount.menu.fidelity'),
        href: '/account-management/my-fidelity'
      }}
    />
  );
};

export default MyStickers;
