import React from 'react';
import Title from '@/components/atoms/title';
import Link from '@/components/atoms/link';
import classnames from 'classnames';
import './style.scss';

export type LinksSummaryProps = {
  id: string;
  label: string;
  elementNumber: number;
};

export type SummaryProps = {
  links: Array<LinksSummaryProps>;
};

const Summary = ({ links }: SummaryProps) => (
  <div className={classnames('summary', 'container', 'container--md')}>
    <Title tag="h2" label="Resumo" className="summary__title" />
    <nav>
      {links.map((link) => (
        <Link
          href={`#${link.id}`}
          label={link.label}
          key={link.id}
          className="summary__link"
        />
      ))}
    </nav>
  </div>
);

export default Summary;
