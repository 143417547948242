/* eslint-disable react-hooks/exhaustive-deps */
import t from '@/lib/i18n';
import CartSprite from '@/assets/images/sprites/cart.svg';
import CartAddresses from '@/components/organisms/checkout/addresses';
/* import Phone from '@/components/organisms/checkout/phone'; */
import React, { MouseEvent, useEffect, useState } from 'react';
import { CartType } from '@/store/cart/types';
import Loader from '@/components/atoms/loader';
import { useDispatch } from 'react-redux';
import {
  UPDATE_TRACKING_BY_PHONE,
  UPDATE_DELIVERY_COMMENT
} from '@/store/cart/cartActions';
import FormTextArea from '@/components/atoms/formTextArea';
import { Addresses, DeliveryAddress } from '@/lib/api/address/types';
import addressApi from '@/lib/api/address';
import CommonSprite from '@/assets/images/sprites/common.svg';
/* import ToggleSwitch from '@/components/atoms/toggleSwitch'; */
import BottomFooter from '@/components/organisms/checkout/sideBarSummary/bottomFooter';
import DiscountCode from '@/lib/model/discountCode';
import UserInfoApi from '@/lib/api/userInformation';
import { Informations as UserInformations } from '@/lib/api/userInformation/types';
import Button from '@/components/atoms/button';
import { getTransportDeliveryCost, getMkpDeliveryCost } from '@/lib/utils/cart';
import useEvent from '@/lib/hooks/useEvent';

import Accordion from '@/components/molecules/accordion';
import Modal from '@/components/organisms/modal';
import DeliveriesInfos from './deliveriesInfos';

type SideBarSummaryProps = {
  cart: CartType;
  userId: string;
  href?: string;
  mobilePhone?: string | null;
  totalDiscount: string;
  isLoading?: boolean;
  isPayment?: boolean;
  isDeliveryPage?: boolean;
  isSlots?: boolean;
  disabled: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  changeAddress?: boolean;
  discountCode?: DiscountCode | null;
  isDisabled?: (key: boolean) => void;
  setSlotsAddresses?: (addresses: Addresses) => void;
  btnValidationLabel?: string;
  sellerId?: string;
};

const SideBarSummary = ({
  cart,
  userId,
  totalDiscount,
  disabled,
  href,
  onClick,
  isPayment = false,
  isLoading = false,
  changeAddress = false,
  mobilePhone = null,
  discountCode = null,
  isDisabled,
  setSlotsAddresses,
  btnValidationLabel,
  sellerId,
  isDeliveryPage,
  isSlots
}: SideBarSummaryProps) => {
  const dispatch = useDispatch();
  const payment = cart.hasAlcoholProduct
    ? { alcohol: false, cgv: false, tracking: false, cgvMKP: false }
    : { cgv: false, tracking: false, cgvMKP: false };
  const [sideBarState, setSideBarState] = useState<{
    payment: {
      tracking: boolean;
    };
    comment: {
      visible: boolean;
      text: string;
      isLoaded: boolean;
    };
    addresses: Addresses | null;
    disabledButton: boolean;
    phone: {
      value: null | string;
      isLoading: boolean;
      isValid: boolean;
      isRequired: boolean;
    };
    userInfo?: UserInformations;
    isThereMKP?: boolean;
    isTherePDV?: boolean;
    deliveryNeedAdress: boolean;
    addNewAddress: boolean;
    addNewAddressModal: boolean;
    isLoadingAddress: boolean;
  }>({
    comment: {
      visible: false,
      text: '',
      isLoaded: false
    },
    addresses: null,
    payment,
    disabledButton: false,
    phone: {
      value: mobilePhone,
      isLoading: true,
      isValid: true,
      isRequired: false
    },
    deliveryNeedAdress: false,
    addNewAddress: false,
    addNewAddressModal: false,
    isLoadingAddress: true
  });
  const [expandComment, setExpandComment] = useState(true);

  const event = useEvent();

  useEffect(() => {
    const isThereMKP = cart.subCarts.some((s) => s.type === 'MKP');
    const isTherePDV = cart.subCarts.some((s) => s.type === 'PDV');

    if (isThereMKP && isDeliveryPage) {
      setSideBarState((s) => ({
        ...s,
        phone: { ...s.phone, isRequired: true }
      }));
    }

    setSideBarState((s) => ({
      ...s,
      disabledButton: disabled || !s.phone.isValid,
      isThereMKP,
      isTherePDV
    }));
  }, [
    disabled,
    sideBarState.phone.isValid,
    isPayment,
    cart.subCarts,
    cart.hasAlcoholProduct,
    cart.slots.selectedSlot.deliveryType,
    isDeliveryPage,
    sideBarState.addresses?.delivery?.length,
    cart.mkpPackages,
    sellerId
  ]);

  useEffect(() => {
    if (isDisabled) {
      isDisabled(sideBarState.disabledButton);
    }
  }, [sideBarState.disabledButton]);

  useEffect(() => {
    if (
      isDeliveryPage &&
      !sideBarState.isLoadingAddress &&
      sellerId !== 'ITM'
    ) {
      let disabledDelivery = false;
      let mkpDeliveryNeedAdress = false;
      const isTherePDV = cart.subCarts.some((s) => s.type === 'PDV');

      const isThereOnlyMKP = cart.subCarts.every((s) => s.type === 'MKP');
      let isNoDeliveryItm = false;
      const mkpPackages =
        sellerId &&
        cart.mkpPackages?.[sellerId]?.packages &&
        Object.values(cart.mkpPackages?.[sellerId]?.packages);
      if (Array.isArray(mkpPackages)) {
        mkpDeliveryNeedAdress = mkpPackages?.some(
          (pac) =>
            pac.selectedProvider?.id &&
            ['ETG', 'EXP', 'PDOM', 'STD'].includes(pac.selectedProvider.id)
        );
      }
      if (isTherePDV) {
        isNoDeliveryItm = cart.slots.selectedSlot.deliveryType !== 'delivery';
      }
      disabledDelivery =
        mkpDeliveryNeedAdress &&
        (isThereOnlyMKP || isNoDeliveryItm) &&
        !sideBarState.addresses?.delivery?.length;

      setSideBarState((s) => ({
        ...s,
        disabledButton: s.disabledButton || disabledDelivery,
        addNewAddressModal:
          mkpDeliveryNeedAdress &&
          !sideBarState.addresses?.delivery?.length &&
          !s.addNewAddress
      }));
    }
  }, [cart.mkpPackages, sideBarState.addresses?.delivery?.length]);

  useEffect(() => {
    (async () => {
      setSideBarState((s) => ({
        ...s,
        isLoadingAddress: true,
        comment: { ...s.comment, isLoaded: true }
      }));
      try {
        const data = await Promise.all([
          addressApi.getAll(userId),
          UserInfoApi.getInformations(userId)
        ]);
        const addressesApi: Addresses = data[0];
        const userInfo: UserInformations = data[1];

        const commentText: string =
          addressesApi.delivery.find((i) => i.isDefault)
            ?.deliverymanInstructions || '';

        setSideBarState((s) => ({
          ...s,
          addresses: addressesApi,
          userInfo,
          comment: { ...s.comment, text: commentText, isLoaded: false },
          phone: {
            ...s.phone,
            isLoading: false,
            value: (isPayment ? mobilePhone : userInfo.mobilePhone) || null
          },
          isLoadingAddress: false
        }));
        dispatch({ type: UPDATE_DELIVERY_COMMENT, payload: commentText });
      } catch (error) {
        setSideBarState((s) => ({
          ...s,
          isLoadingAddress: false
        }));
      }
    })();
  }, [userId, mobilePhone, isPayment, dispatch]);

  const setAddresses = async () => {
    const addresses: Addresses = await addressApi.getAll(userId);
    setSlotsAddresses && setSlotsAddresses(addresses);

    setSideBarState((s) => ({
      ...s,
      addresses
    }));
  };

  useEffect(() => {
    if (
      !sideBarState.phone.value &&
      !sideBarState.phone.isLoading &&
      !isPayment
    ) {
      setSideBarState((p) => ({
        ...p,
        payment: { ...p.payment, tracking: false }
      }));
      dispatch({ type: UPDATE_TRACKING_BY_PHONE, payload: false });
    }
  }, [
    sideBarState.phone.value,
    sideBarState.phone.isLoading,
    dispatch,
    isPayment
  ]);

  const sendComment = async (value: string) => {
    setSideBarState((s) => ({
      ...s,
      comment: { visible: false, text: value, isLoaded: false }
    }));
    await addressApi.update(userId, {
      ...sideBarState.addresses?.delivery?.find((item) => item.isDefault),
      deliverymanInstructions: value
    } as DeliveryAddress);
    setAddresses();
    dispatch({ type: UPDATE_DELIVERY_COMMENT, payload: value });
  };

  const closeDeliveryNeedAdress = () => {
    setSideBarState((s) => ({
      ...s,
      addNewAddressModal: false
    }));
  };

  const setNewAddress = () => {
    setSideBarState((s) => ({
      ...s,
      addNewAddress: true,
      addNewAddressModal: false
    }));
  };

  const { selectedProvider } = cart.slots || {};
  const transportCost = getTransportDeliveryCost(cart);
  const transportCostWitoutExtra = getTransportDeliveryCost(cart, false);
  const mkpTransportCost = getMkpDeliveryCost(cart);

  const isDeliveryType = cart.slots.selectedSlot.deliveryType === 'delivery';
  const { housingCosts = 0, volumeCosts = 0 } = cart.slots.selectedSlot || {};
  const commentClassName = expandComment
    ? 'cartInfo__item cartInfo__item-expanded'
    : 'cartInfo__item';
  const addresses = (
    <div>
      <CartAddresses
        addressesList={sideBarState.addresses}
        deliveryType={cart.slots.selectedSlot.deliveryType}
        changeAddress={changeAddress || sideBarState.addNewAddress}
        userInfo={sideBarState.userInfo}
        creationIfEmpty
        isMKPDelivery={isDeliveryPage && sellerId !== 'ITM'}
        onListChanged={setAddresses}
        isPayment={isPayment}
        onCloseCallback={() =>
          setSideBarState((s) => ({
            ...s,
            addNewAddress: false
          }))
        }
      />
    </div>
  );
  /*   const phone = (
    <div>
      <Phone
        isLoading={sideBarState.phone.isLoading}
        mobilePhone={sideBarState.phone.value}
        isValidPhone={sideBarState.phone.isValid}
        isRequired={sideBarState.phone.isRequired}
        phoneIsValid={(valid, newPhone) => {
          setSideBarState((sideState) => ({
            ...sideState,
            phone: {
              ...sideState.phone,
              isValid: valid,
              value: valid ? newPhone : sideState.phone.value
            }
          }));
        }}
        userId={userId}
      />
      {sideBarState.phone.value && (
        <div className="cartInfo cart__preparation">
          <div className="cartInfo__content">{t('cart.preparation.text')}</div>
          <ToggleSwitch
            className="cartInfo__picto cart__preparation__picto"
            onChange={(value) => {
              value &&
                event.send('checkout', {
                  type: 'optin'
                });
              setSideBarState((p) => ({
                ...p,
                payment: { ...p.payment, tracking: value }
              }));
              dispatch({ type: UPDATE_TRACKING_BY_PHONE, payload: value });
            }}
            id="tracking"
            checked={cart.trackingByPhone}
          />
        </div>
      )}
    </div>
  ); */
  const comment = (
    <div>
      {!sideBarState.comment.isLoaded && (
        <div className="cartInfo">
          <svg className="cartInfo__picto" height="25" width="25">
            <use
              xlinkHref={`${CartSprite}#${
                sideBarState.comment.text ? 'validComment' : 'emptyComment'
              }`}
            />
          </svg>
          <div className="cartInfo__content">
            {sideBarState.comment.visible && (
              <>
                <FormTextArea
                  classNameField="cartInfo__content__textArea"
                  maxLength={255}
                  value={sideBarState.comment.text}
                  onChange={(e) => {
                    setSideBarState((s) => ({
                      ...s,
                      comment: { ...s.comment, text: e.target.value }
                    }));
                  }}
                />
                <button
                  onClick={() => {
                    event.send('checkout', {
                      type: 'deliveryComment',
                      userId
                    });
                    sendComment(sideBarState.comment.text);
                  }}
                  type="button"
                  className="cartInfo__comment--validate"
                >
                  {t('form.button.validate')}
                </button>
              </>
            )}
            {!sideBarState.comment.visible &&
              (sideBarState.comment.text ? (
                <>
                  <div className={commentClassName}>
                    {sideBarState.comment.text}
                  </div>
                  <button
                    onClick={() => {
                      setSideBarState((s) => ({
                        ...s,
                        comment: {
                          ...s.comment,
                          visible: !s.comment.visible
                        }
                      }));
                    }}
                    type="button"
                    className="cartInfo__comment--validate"
                  >
                    {t('common.modify')}
                  </button>
                </>
              ) : (
                <button
                  onClick={() => {
                    setSideBarState((s) => ({
                      ...s,
                      comment: {
                        ...s.comment,
                        visible: !s.comment.visible
                      }
                    }));
                  }}
                  type="button"
                  className="cartInfo__modify"
                  id="btn-cartInfoLeaveComment"
                >
                  {t('cart.info.comment.leaveComment')}
                </button>
              ))}
          </div>
          {!sideBarState.comment.visible &&
            sideBarState.comment.text &&
            sideBarState.comment.text.length > 35 && (
              <Button isLink onClick={() => setExpandComment(!expandComment)}>
                <svg height="16" width="16">
                  <use
                    xlinkHref={`${CommonSprite}#${
                      expandComment ? 'arrow-up' : 'arrow-down'
                    }`}
                  />
                </svg>
              </Button>
            )}
        </div>
      )}
      {sideBarState.comment.isLoaded && <Loader size="medium" />}
    </div>
  );

  const associatedFees = (
    <div>
      <div className="cartInfo">
        <svg className="cartInfo__picto" height="25" width="25">
          <use xlinkHref={`${CommonSprite}#cart`} />
        </svg>
        <div className="cartInfo__content">
          <div className="cartInfo__title cart__footer__delivery">
            <span>{t('cart.total')}</span>
            <span>
              {`${String(cart.total.toFixed(2)).replace('.', ',')} €`}
            </span>
          </div>
          {(transportCostWitoutExtra > 0 ||
            (cart.slots.selectedSlot?.slot?.freeFees && isDeliveryType)) && (
            <div className="cartInfo__item cart__footer__delivery">
              <span>{t('common.delivery')}</span>
              <span className="cartInfo__item--red">
                {transportCostWitoutExtra === 0
                  ? t('cart.slot.fees.offered')
                  : `+ ${String(transportCostWitoutExtra.toFixed(2)).replace(
                      '.',
                      ','
                    )} €`}
              </span>
            </div>
          )}
          {((isDeliveryType &&
            !!selectedProvider?.deliveryCost?.housingExtraTax) ||
            !!housingCosts) && (
            <div className="cartInfo__item cart__footer__delivery">
              <span>{t('delivery.housingExtraTax')}</span>
              <span className="cartInfo__item--red">
                {`+ ${String(
                  (
                    selectedProvider?.deliveryCost?.housingExtraTax ||
                    housingCosts
                  )?.toFixed(2)
                ).replace('.', ',')} €`}
              </span>
            </div>
          )}
          {((isDeliveryType &&
            !!selectedProvider?.deliveryCost?.volumeExtraTax) ||
            !!volumeCosts) && (
            <div className="cartInfo__item cart__footer__delivery">
              <span>{t('delivery.volumeExtraCost')}</span>
              <span className="cartInfo__item--red">
                {`+ ${String(
                  (
                    selectedProvider?.deliveryCost?.volumeExtraTax ||
                    volumeCosts
                  )?.toFixed(2)
                ).replace('.', ',')} €`}
              </span>
            </div>
          )}
          {(discountCode?.amountDiscount || 0) > 0 && (
            <div className="cartInfo__item cart__footer__delivery">
              <span>{t('discountCode.discountCode')}</span>
              <span className="cartInfo__item--red">
                {`- ${String(
                  DiscountCode.getDiscount(discountCode, cart.total)?.toFixed(2)
                ).replace('.', ',')} €`}
              </span>
            </div>
          )}
          {cart.selectedVoucher > 0 && (
            <div className="cartInfo__item cart__costItem">
              <span>{t('cart.voucher')}</span>
              <span className="cart__costItem__highlight">
                {t('cart.summary.minus.price', {
                  '%price%': String(cart.selectedVoucher.toFixed(2)).replace(
                    '.',
                    ','
                  )
                })}
              </span>
            </div>
          )}
          {cart.selectedFID > 0 && (
            <div className="cartInfo__item cart__costItem">
              <span>{t('cart.fid')}</span>
              <span className="cart__costItem__highlight">
                {t('cart.summary.minus.price', {
                  '%price%': String(
                    Number(cart.selectedFID).toFixed(2)
                  )?.replace('.', ',')
                })}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="cart__footer">
      <h2 className="cart__footer__title">{t('cart.slot.summary')}</h2>
      <DeliveriesInfos
        cart={cart}
        currentSellerId={sellerId}
        isSlots={isSlots}
      />
      <Accordion
        clickArrowToToogle
        openIndex={isPayment ? '' : 'addresses'}
        className="deliveriesInfos__accordion"
        itemsList={[
          {
            id: 'addresses',
            title: t('cart.slot.section.title.addresses'),
            htmlBody: addresses,
            classNameItem: 'deliveriesInfos__accordion__item',
            classNameTitle: 'deliveriesInfos__accordion__title'
          }
        ]}
      />
      {/*   <Accordion
        clickArrowToToogle
        openIndex={isPayment ? '' : 'phone'}
        className="deliveriesInfos__accordion"
        itemsList={[
          {
            id: 'phone',
            title: t('cart.slot.section.title.tracking'),
            htmlBody: phone,
            classNameItem: 'deliveriesInfos__accordion__item',
            classNameTitle: 'deliveriesInfos__accordion__title'
          }
        ]}
      /> */}
      {(isDeliveryType || sideBarState.isThereMKP) &&
        !!sideBarState.addresses?.delivery?.length && (
          <Accordion
            clickArrowToToogle
            openIndex={isPayment ? '' : 'comment'}
            className="deliveriesInfos__accordion"
            itemsList={[
              {
                id: 'comment',
                title: t('cart.info.comment.title'),
                htmlBody: comment,
                classNameItem: 'deliveriesInfos__accordion__item',
                classNameTitle: 'deliveriesInfos__accordion__title'
              }
            ]}
          />
        )}
      {((selectedProvider && selectedProvider?.deliveryCost?.totalCost >= 0) ||
        discountCode?.amountDiscount ||
        mkpTransportCost ||
        (cart.slots.selectedSlot.slot &&
          cart.slots.selectedSlot.slot.fees >= 0 &&
          isDeliveryType) ||
        cart.selectedVoucher > 0 ||
        cart.selectedFID > 0) && (
        <Accordion
          clickArrowToToogle
          openIndex="associatedFees"
          className="deliveriesInfos__accordion"
          itemsList={[
            {
              id: 'associatedFees',
              title: t('cart.associatedFees'),
              htmlBody: associatedFees,
              classNameItem: 'deliveriesInfos__accordion__item',
              classNameTitle: 'deliveriesInfos__accordion__title'
            }
          ]}
        />
      )}
      {isPayment && (
        <div className="cart__footer__section">
          <div className="cart__footer__total">
            <span>{t('cart.price.total')}</span>
            <span>
              {t('cart.info.price', {
                '%price%': String(
                  (
                    cart.total -
                    cart.selectedVoucher -
                    cart.selectedFID +
                    transportCost -
                    DiscountCode.getDiscount(discountCode, cart.total)
                  ).toFixed(2)
                ).replace('.', ',')
              })}
            </span>
          </div>
        </div>
      )}
      {isDeliveryPage && (
        <Modal
          closable={true}
          open={sideBarState.addNewAddressModal}
          onClose={() => closeDeliveryNeedAdress()}
        >
          <div className="tabsSlot__unavailable">
            <svg
              className="tabsSlot__unavailable__picto"
              height="130"
              width="169"
            >
              <use xlinkHref={`${CartSprite}#no-delivery`} />
            </svg>
            <strong>{t('cart.slot.userAddress.unavailable')}</strong>
            <Button
              className="tabsSlot__unavailable__button"
              onClick={setNewAddress}
              label={t(`cart.slot.addAddresses`)}
            />
          </div>
        </Modal>
      )}
      <BottomFooter
        cart={cart}
        ctaLabel="cart.footer.validate"
        deliveryCost={transportCost}
        totalDiscount={totalDiscount}
        isPayment={isPayment}
        href={href}
        onClick={onClick}
        isLoading={isLoading}
        disabledButton={sideBarState.disabledButton}
        discountCode={discountCode}
        btnValidationLabel={btnValidationLabel}
      />
    </div>
  );
};

export default SideBarSummary;
