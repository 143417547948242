import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import CartHelp from '@/components/organisms/checkout/help';
import { useDispatch } from 'react-redux';
import { ReactComponent as InfoIcon } from '@/assets/images/icons/info.svg';
import t from '@/lib/i18n';
import {
  StateType,
  SlotsDataType,
  SlotListType,
  SlotType,
  SlotInformationType,
  CartItemType
} from '@/store/cart/types';
import Modal, { ModalProps } from '@/components/organisms/modal';
import CartSprite from '@/assets/images/sprites/cart.svg';
import dayjs from 'dayjs';
import classnames from 'classnames';
import Carousel from '@/components/molecules/slider';
import { ADD_SELECTED_SLOT } from '@/store/cart/cartActions';
import SideBarSummary from '@/components/organisms/checkout/sideBarSummary';
import { SLOT_LIST, SLOT_TYPE_MAPPING } from '@/lib/model/slot';
import AddressApi from '@/lib/api/address';
import Button from '@/components/atoms/button';
import CarrierCostExplanation from '@/components/organisms/checkout/carrierCostExplanation';
import { Addresses } from '@/lib/api/address/types';
import BottomFooter from '@/components/organisms/checkout/sideBarSummary/bottomFooter';
import usePageView from '@/lib/hooks/usePageView';
import useEvent from '@/lib/hooks/useEvent';
import usePdv from '@/lib/hooks/usePdv';
import { getItmDeliveryCost } from '@/lib/utils/cart';
import { findNextCartToHandle } from '@/lib/utils/providerSelect';
import Product from '@/components/organisms/product';
import ClosedShelvesModal, {
  deleteAllClosedEans,
  getProductsShelfClosed
} from './closedShelfModal';
import './style.scss';

export type StepTwoProps = {
  cart: StateType;
  userId: string;
};

type TabsSlotType = {
  list: Array<{
    label: string;
    id: string;
  }>;
  selectedTab: string;
};

type SlotsHandle = {
  slotByTab: SlotListType;
  selectedSlots: Array<SlotType>;
  selectedSlot: SlotType | null;
  wantsBags?: boolean;
  tabs: TabsSlotType;
  modalConf: ModalProps;
  lastUpdateDateSlot: string | null;
  noDeliveryAddress: boolean;
  unCompatibleConsigne: Array<CartItemType> | null;
};

type DeliveryMethods = {
  [key: string]: string;
};

export const DELIVERY_METHODS_ENUM: DeliveryMethods = {
  drive: 'DRIVE',
  drive24: 'DRIVE_24',
  drivepieton: 'STORE_PICK_UP',
  delivery: 'HOME_DELIVERY'
};

const Slots = ({
  list,
  selected,
  selectedSlot,
  period,
  type
}: {
  selectedSlot: SlotType | null;
  list: Array<SlotType>;
  selected: (e: { selectedSlot: SlotType }) => void;
  period?: string;
  type: string;
}) => {
  const event = useEvent();
  const getDeliveryCostSentence = (slot: SlotType) => {
    let cost;
    if (slot.providers?.length) {
      if (slot.providers?.length > 1) {
        cost = slot.minimalCost;
      } else {
        cost = slot.providers[0].deliveryCost.cost;
      }
    } else {
      cost = slot.freeFees ? 0 : slot.fees;
    }
    const fixedPrice =
      cost === 0
        ? t('cart.slot.fees.free')
        : `+ ${
            Math.floor(cost) === cost
              ? cost
              : cost?.toFixed(2).replace('.', ',')
          } €`;
    return (
      <div className="slots__slot__feesInfos">
        {slot.providers && slot.providers.length > 1 && cost > 0 && (
          <span className="slots__slot__feesText">{t('cart.slot.from')}</span>
        )}
        <span className="slots__slot__fees">{fixedPrice}</span>
      </div>
    );
  };

  return (
    <div className="slots__slotByPeriod">
      {period && <span className="slots__period">{period}</span>}
      {list.map((slot: SlotType) => {
        return (
          <div
            onClick={() => {
              selected({ selectedSlot: slot });
              event.send('checkout', {
                type: 'slotTime',
                slotTime: `${slot.day}_${slot.startTime}-${slot.endTime}`
              });
            }}
            key={slot.idSlot}
            className={classnames('slots__slot', {
              'slots__slot--selected': selectedSlot?.idSlot === slot.idSlot
            })}
            id={`slotsSlot-${slot.idSlot}`}
          >
            {slot.startTime} - {slot.endTime}
            {type === 'delivery' && getDeliveryCostSentence(slot)}
          </div>
        );
      })}
    </div>
  );
};

const emptyModal: ModalProps = {
  open: false,
  children: null
};

const SlotsComponent = ({ cart, userId }: StepTwoProps) => {
  const dispatch = useDispatch();
  const event = useEvent();
  const router = useRouter();

  const { current: cartRef } = useRef(cart);

  useEffect(() => {
    const pdvCart = cartRef?.subCarts?.find((sub) => sub.type === 'PDV');
    const products = Object.values(pdvCart?.items || {})?.map((item) => item);
    !!products.length &&
      event.send('checkoutEcommerce', {
        step: '2',
        items: products,
        path: router.asPath
      });
  }, [event, cartRef, router]);

  const getModalConf = (
    selectedSlotTemp: SlotType | null,
    isLoading = false
  ): ModalProps => {
    const productsShelfClosedTemp: Array<CartItemType> | null = getProductsShelfClosed(
      selectedSlotTemp,
      cart
    );
    const modalConf = !productsShelfClosedTemp.length
      ? emptyModal
      : {
          classNameContainer: 'slots__modal__container',
          classNameContent: 'slots__modal__content',
          open: true,
          children: (
            <ClosedShelvesModal
              selectedSlot={selectedSlotTemp}
              productsShelfClosed={productsShelfClosedTemp}
              isLoading={isLoading}
            />
          ),
          confirmBtn: {
            label: t('modal.closedShelves.deleteProducts'),
            onClick: async () => {
              setSlotsState((el: any) => ({
                ...el,
                modalConf: getModalConf(selectedSlotTemp, true)
              }));
              await deleteAllClosedEans({
                productsShelfClosed: productsShelfClosedTemp,
                dispatch,
                userId
              });
              setSlotsState((el: any) => ({
                ...el,
                modalConf: {
                  open: false,
                  children: null
                }
              }));
            }
          },
          cancelBtn: {
            label: t('modal.closedShelves.chooseOtherSlot'),
            onClick: () => {
              setSlotsState((el) => ({
                ...el,
                modalConf: emptyModal
              }));
            }
          },
          onClose: () => {
            setSlotsState((el) => ({
              ...el,
              modalConf: emptyModal
            }));
          }
        };
    return modalConf;
  };

  const setSlotsAddresses = (address: Addresses) => {
    if (
      slotsState.slotByTab?.state === 'NON_DESSERVI' &&
      slotsState.noDeliveryAddress
    ) {
      setSlotsState((s) => ({
        ...s,
        noDeliveryAddress: !address.delivery.length
      }));
    }
  };

  const [slotsState, setSlotsState] = useState<SlotsHandle>({
    slotByTab: {
      state: null,
      isMovingForThePlanet: false,
      days: [],
      maxLiquidVolume: 0,
      serviceProviders: [],
      minimumOrderAmount: 0,
      deliveryType: '',
      availablePayments: {}
    },
    selectedSlots: [],
    selectedSlot: null,
    tabs: {
      list: [],
      selectedTab: ''
    },
    modalConf: getModalConf(cart.slots.selectedSlot?.slot || null),
    lastUpdateDateSlot: null,
    noDeliveryAddress: false,
    unCompatibleConsigne: null
  });

  const [buttonMobileDisabled, setButtonMobileDisabled] = useState(false);

  const [openChangeAddress, openChangeAddressState] = useState(false);

  const pageViewer = usePageView();

  useEffect(() => {
    if (openChangeAddress) {
      const { ref } = usePdv();
      pageViewer.send('slotSelectAddress', {
        pdvRef: ref
      });
    }
  }, [pageViewer, openChangeAddress]);

  const productsShelfClosed: Array<CartItemType> = getProductsShelfClosed(
    cart.slots.selectedSlot?.slot,
    cart
  );
  const pdvCart = cart.subCarts.find((sub) => sub.type === 'PDV');
  const immediateDiscount = String(cart.immediateDiscount.toFixed(2)).replace(
    '.',
    ','
  );

  const selectedSlot = (item: { selectedSlot: SlotType | null }) => {
    const selectedProvider =
      item.selectedSlot?.providers?.length === 1
        ? item.selectedSlot.providers[0]
        : null;
    dispatch({
      type: ADD_SELECTED_SLOT,
      payload: {
        list: setSlots(cart.slots.list, item.selectedSlot),
        selectedSlot: {
          slot: item.selectedSlot,
          maxLiquidVolume: slotsState.slotByTab.maxLiquidVolume,
          deliveryType: slotsState.slotByTab.deliveryType,
          availablePayments: slotsState.slotByTab.availablePayments,
          housingCosts: slotsState.slotByTab.housingCosts,
          volumeCosts: slotsState.slotByTab.volumeCosts
        },
        selectedProvider
      }
    });

    setSlotsState((el) => ({
      ...el,
      selectedSlot: item.selectedSlot,
      modalConf: getModalConf(item.selectedSlot)
    }));
  };

  const setSlots = (
    SlotsByDeliveryList: SlotsDataType,
    selected: SlotType | null
  ) => {
    const slotsResult: SlotsDataType = { ...SlotsByDeliveryList };
    const slotList = Object.keys(slotsResult);
    slotList.forEach((item) => {
      let fistElementIsSelected = false;
      slotsResult[item] = {
        ...slotsResult[item],
        days: slotsResult[item]?.days?.map((element) => {
          let isSelected = false;
          const isAvailable = element.slots.filter(
            (sl: any) => sl.status === 'DISPONIBLE'
          );
          if (selected?.day) {
            isSelected = selected?.day === element.day;
          } else if (!fistElementIsSelected && isAvailable.length) {
            fistElementIsSelected = true;
            isSelected = true;
          }

          return { ...element, isSelected };
        })
      };
    });
    return slotsResult;
  };

  useEffect(() => {
    if (!cart.isHydrated) {
      return;
    }
    const allListsAreEmpty = Object.keys(cart.slots.list).every(
      (deliveryType) => !cart.slots.list[deliveryType]?.days?.length
    );
    const isTereUnompatibleMode = !!cart.metaData?.deliveryMethods?.length;

    if (
      (Object.keys(cart.slots.list).length === 0 || allListsAreEmpty) &&
      !isTereUnompatibleMode
    ) {
      // TODO display a message to inform user that slots are empty
      window.location.href = '/orders/cart';
    }
  }, [
    cart.isHydrated,
    cart.metaData?.deliveryMethods?.length,
    cart.slots.list
  ]);

  useEffect(() => {
    (async () => {
      const slotList = SLOT_LIST.filter((slot) =>
        Object.keys(cart.slots.list).includes(slot)
      );
      const availableSlots = setSlots(
        cart.slots.list,
        cart.slots.selectedSlot.slot
      );

      let deliveryType = cart.slots.selectedSlot.deliveryType || slotList[0];

      deliveryType =
        deliveryType === SLOT_TYPE_MAPPING.DRIVELIGHT ||
        deliveryType === SLOT_TYPE_MAPPING.DRIVESOLO
          ? SLOT_TYPE_MAPPING.DRIVE
          : deliveryType;

      const slotToSelect = availableSlots[deliveryType];

      const slotByTab =
        cart.slots.lastUpdate !== slotsState.lastUpdateDateSlot
          ? slotToSelect
          : slotsState.slotByTab;

      setSlotsState((elt) => ({
        ...elt,
        tabs: {
          selectedTab: slotsState.tabs.selectedTab || deliveryType,
          list: slotList.map((s) => ({
            label: t(`cart.slot.${s}`),
            id: s
          }))
        },
        wantsBags: cart.slots.wantsBags,
        selectedSlot: cart.slots.selectedSlot
          ? cart.slots.selectedSlot.slot
          : null,
        slotByTab,
        selectedSlots:
          slotByTab?.days
            ?.find((el) => el.isSelected)
            ?.slots.filter((item) => item.status === 'DISPONIBLE') || [],
        lastUpdateDateSlot: cart.slots.lastUpdate || null
      }));
    })();
  }, [
    cart.slots,
    slotsState.slotByTab,
    slotsState.tabs.selectedTab,
    slotsState.lastUpdateDateSlot
  ]);

  useEffect(() => {
    (async () => {
      const address: Addresses = await AddressApi.getAll(userId);
      if (!address.delivery.length) {
        setSlotsState((s) => ({ ...s, noDeliveryAddress: true }));
      }
    })();
  }, [userId]);

  useEffect(() => {
    if (slotsState.tabs.selectedTab === 'drive24') {
      const isEncombrant = drive24IsInError();
      if (isEncombrant) {
        event.send('checkout', {
          type: 'encombrants'
        });
        const unCompatibleConsigne = Object.values(pdvCart?.items || {}).filter(
          (item) => !item.product.compatibleConsigne
        );
        setSlotsState((s) => ({ ...s, unCompatibleConsigne }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotsState.tabs.selectedTab]);

  useEffect(() => {
    if (openChangeAddress) {
      openChangeAddressState(false);
    }
  }, [openChangeAddress]);
  const selectTabs = (index: number) => {
    const slotsByDelivery = setSlots(
      cart.slots.list,
      cart.slots.selectedSlot.slot
    );

    dispatch({
      type: ADD_SELECTED_SLOT,
      payload: {
        list: slotsByDelivery,
        selectedSlot: {
          slot: null,
          deliveryType: slotsState.tabs.list[index].id,
          maxLiquidVolume: 0,
          availablePayments: {},
          housingCosts: 0,
          volumeCosts: 0
        },
        wantsBags: slotsState.wantsBags,
        selectedProvider: null
      }
    });
    setSlotsState((slotElement) => ({
      ...slotElement,
      tabs: {
        ...slotElement.tabs,
        selectedTab: slotElement.tabs.list[index].id
      },
      slotByTab: slotsByDelivery[slotElement.tabs.list[index].id],
      selectedSlots:
        slotsByDelivery[slotElement.tabs.list[index].id]?.days
          ?.find((el) => el.isSelected)
          ?.slots.filter((el) => el.status === 'DISPONIBLE') || [],
      selectedSlot: null
    }));
  };

  const responsive = {
    tablet: {
      breakpoint: { max: 1023, min: 0 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 4094, min: 1024 },
      items: 8
    }
  };

  const drive24IsInError = () => {
    if (slotsState.tabs.selectedTab !== 'drive24') {
      return false;
    }
    const compatibleProducts = Object.values(pdvCart?.items || {}).filter(
      (item) => !item.product.compatibleConsigne
    );
    return !!compatibleProducts.length;
  };

  const volumeIsInError = () => {
    return slotsState.tabs.selectedTab === 'delivery' ||
      slotsState.tabs.selectedTab === 'drive24'
      ? getVolumeInError()
      : false;
  };

  const uncompatibleMode = (deliveryMode: string) => {
    const { metaData } = cart;
    if (metaData?.deliveryMethods?.length) {
      const tab = metaData?.deliveryMethods;
      return tab.some(
        (mode) => mode.deliveryMethod === DELIVERY_METHODS_ENUM[deliveryMode]
      );
    }
    return false;
  };

  const getVolumeInError = () => {
    const volumes = cart.metaData?.volume;
    let authorizedVolume = 0;
    if (volumes?.length) {
      volumes.forEach((volume) => {
        volume.deliveryTypeAuthorizedVolume.forEach((deliveryType) => {
          if (
            (deliveryType.deliveryType === 'DOMICILE' &&
              cart.slots.selectedSlot.deliveryType === 'delivery') ||
            deliveryType.deliveryType === 'DRIVE24'
          ) {
            authorizedVolume = deliveryType.authorizedVolume;
          }
        });
      });
    }
    return authorizedVolume;
  };

  const redirectOnValidate = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const isThereDeliveryItm =
      cart.slots.selectedSlot.slot?.providers &&
      cart.slots.selectedSlot.slot.providers?.length > 1 &&
      cart.slots.selectedSlot.deliveryType === 'delivery';
    const isThereDeliveryMkp = cart.subCarts.some(
      (subCart) => subCart.type === 'MKP'
    );
    const nextCartToHandle = findNextCartToHandle(false, cart, null);
    if (nextCartToHandle && (isThereDeliveryItm || isThereDeliveryMkp)) {
      window.location.href = `/orders/delivery#${nextCartToHandle.seller.sellerId}`;
    } else {
      window.location.href = `/orders/payment`;
    }
  };

  const renderUncompatible = () => {
    const { unCompatibleConsigne } = slotsState;
    if (!unCompatibleConsigne?.length) {
      return null;
    }
    return (
      <div className="tabsSlot__unavailable__drive24">
        <Button
          className="tabsSlot__unavailable__button"
          onClick={() => {
            deleteAllClosedEans({
              productsShelfClosed: unCompatibleConsigne,
              dispatch,
              userId
            });
            setSlotsState((s) => ({
              ...s,
              unCompatibleConsigne: null
            }));
          }}
          label="supprimer les produits encombrants"
        />
        {unCompatibleConsigne?.map((product: CartItemType) => {
          return (
            <Product
              data={product.product}
              key={product.product.id}
              isSmall
              disableAddToCart
            />
          );
        })}
      </div>
    );
  };
  const drive24IsEncombrant = drive24IsInError();
  const volumeInError = volumeIsInError();

  return (
    <div className="cart cart--checkout">
      <Modal {...slotsState.modalConf} />
      {cart.subCarts.length > 0 && (
        <>
          <div className="cart__content">
            <h1 className="cart__title">{t('cart.slot.title')}</h1>
            <p className="cart__text">{t('cart.slot.text')}</p>
            <div className="cart__heading">
              <div className="cart__infos">
                <div className="cart__infos__infoCart">
                  <strong className="cart__infos__title">
                    {t('cart.info.PDV')}
                  </strong>
                  <span className="cart__infos__price">
                    {t('cart.info.price', {
                      '%price%': String(pdvCart?.total.toFixed(2)).replace(
                        '.',
                        ','
                      )
                    })}
                  </span>
                </div>
                <span className="cart__infos__products">
                  {t(
                    pdvCart && pdvCart?.qty > 1
                      ? 'cart.info.products'
                      : 'cart.info.product',
                    { '%number%': pdvCart?.qty }
                  )}
                </span>
              </div>
            </div>
            <div className="tabsSlot">
              {slotsState.tabs.list.length > 0 &&
                slotsState.tabs.list.map((tab, i) => {
                  const key = `${tab.id}-tab`;
                  return (
                    <div
                      key={key}
                      onClick={() => {
                        selectTabs(i);
                        event.send('checkout', {
                          type: 'slotType',
                          slotId: tab.id
                        });
                      }}
                      className={classnames('tabsSlot__tab', {
                        active: tab.id === slotsState.tabs.selectedTab
                      })}
                      id={`tabsSlotTab-${tab.id}`}
                    >
                      <svg className="tabsSlot__picto" height="30" width="30">
                        <use xlinkHref={`${CartSprite}#${tab.id}`} />
                      </svg>

                      {tab.label}
                    </div>
                  );
                })}
            </div>
            <div className="tabsSlot__content slots">
              {slotsState.slotByTab?.state === 'ACTIF' && (
                <>
                  <div className="slots__intro">
                    <svg className="slots__intro__picto" height="30" width="30">
                      <use
                        xlinkHref={`${CartSprite}#${slotsState.tabs.selectedTab}`}
                      />
                    </svg>

                    <div className="slots__intro__content">
                      <div className="slots__intro__title">
                        {t(`cart.slot.${slotsState.tabs.selectedTab}.title`)}
                      </div>
                      <div className="slots__intro__text">
                        {t(
                          `cart.slot.${slotsState.tabs.selectedTab}.${
                            SLOT_TYPE_MAPPING['DOMICILE'] ===
                              slotsState.tabs.selectedTab &&
                            slotsState.slotByTab.serviceProviders.length &&
                            pdvCart &&
                            slotsState.slotByTab.minimumOrderAmount <=
                              pdvCart.total
                              ? 'textSeveralCarriers'
                              : 'text'
                          }`,
                          {
                            '%montantPanier%': String(
                              pdvCart?.total.toFixed(2)
                            ).replace('.', ',')
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  {cart.slots.selectedSlot.deliveryType ===
                    SLOT_TYPE_MAPPING.DOMICILE &&
                    cart.slots.list[SLOT_TYPE_MAPPING.DOMICILE].serviceProviders
                      .length > 0 &&
                    pdvCart &&
                    slotsState.slotByTab.minimumOrderAmount <=
                      pdvCart?.total && (
                      <CarrierCostExplanation
                        data={cart.slots.list[SLOT_TYPE_MAPPING.DOMICILE]}
                        classNameAccordion="tabsSlot__accordion"
                        withAccordion
                        classNameContent="tabsSlot__accordion__infos"
                      />
                    )}
                  {pdvCart &&
                    slotsState.slotByTab.isMovingForThePlanet &&
                    slotsState.slotByTab.minimumOrderAmount <= pdvCart.total &&
                    slotsState.slotByTab?.days?.length > 0 && (
                      <div className="slots__planet">
                        {slotsState.wantsBags && (
                          <p className="slots__planet__text">
                            <span className="slots__planet__boldText">
                              {t('cart.slot.planet.boldText')}{' '}
                            </span>
                            {t('cart.slot.planet.text')}
                          </p>
                        )}
                        {!slotsState.wantsBags && (
                          <p className="slots__planet__boldText">
                            {t('cart.slot.planet.textNo')}
                          </p>
                        )}
                        <div className="slots__planet__switch">
                          <span
                            onClick={() => {
                              setSlotsState((s) => ({ ...s, wantsBags: true }));
                              event.send('checkout', { type: 'optoutBag' });
                              dispatch({
                                type: ADD_SELECTED_SLOT,
                                payload: {
                                  wantsBags: true
                                }
                              });
                            }}
                            className={classnames('slots__planet__response', {
                              selected_yes: slotsState.wantsBags
                            })}
                          >
                            {t('cart.slot.planet.yes')}
                          </span>
                          <span
                            onClick={() => {
                              setSlotsState((s) => ({
                                ...s,
                                wantsBags: false
                              }));
                              event.send('checkout', { type: 'optinBag' });
                              dispatch({
                                type: ADD_SELECTED_SLOT,
                                payload: {
                                  wantsBags: false
                                }
                              });
                            }}
                            className={classnames('slots__planet__response', {
                              selected_no: !slotsState.wantsBags
                            })}
                          >
                            {t('cart.slot.planet.no')}
                          </span>
                        </div>
                      </div>
                    )}
                  {!!pdvCart &&
                    slotsState.slotByTab.minimumOrderAmount > pdvCart.total && (
                      <div className="slots__noServices">
                        <svg width="170" height="130">
                          <use xlinkHref={`${CartSprite}#orderServices`} />
                        </svg>
                        <p className="slots__noServices__text">
                          {t('cart.slot.minimumOrderAmount', {
                            '%amount%': slotsState.slotByTab.minimumOrderAmount
                          })}
                        </p>
                        <Button
                          className="tabsSlot__unavailable__button"
                          onClick={() => {
                            window.location.href = '/home';
                          }}
                          label={t('cart.continue')}
                        />
                      </div>
                    )}

                  {pdvCart &&
                    slotsState.slotByTab.minimumOrderAmount <= pdvCart.total &&
                    slotsState.slotByTab?.days?.length > 0 &&
                    !drive24IsEncombrant &&
                    !uncompatibleMode(slotsState.tabs.selectedTab) &&
                    !volumeInError && (
                      <>
                        <Carousel
                          type="category"
                          className="slots__days"
                          showDots={false}
                          deviceType="desktop-xlarge"
                          slidesToSlide={1}
                          ssr={false}
                          responsive={responsive}
                        >
                          {slotsState.slotByTab?.days?.map(
                            (de: SlotInformationType) => {
                              const availableSlots = de.slots.filter(
                                (sl) => sl.status === 'DISPONIBLE'
                              );
                              let day = dayjs(de.day).format('ddd DD MMM');

                              if (dayjs(de.day).isTomorrow())
                                day = t('cart.slot.tomorrow');
                              if (dayjs(de.day).isToday())
                                day = t('cart.slot.today');
                              const key = `${de.day}-day`;
                              return (
                                <div
                                  onClick={() => {
                                    if (availableSlots.length) {
                                      setSlotsState((elem) => {
                                        const slotByTab = {
                                          ...elem.slotByTab,
                                          days: elem.slotByTab?.days?.map(
                                            (item) => ({
                                              ...item,
                                              isSelected: de.day === item.day
                                            })
                                          )
                                        };
                                        return {
                                          ...elem,
                                          selectedSlots: availableSlots,
                                          slotByTab
                                        };
                                      });
                                    }
                                  }}
                                  className={classnames('slots__day', {
                                    'slots__day--disabled': !availableSlots.length,
                                    'slots__day--active': de.isSelected
                                  })}
                                  key={key}
                                >
                                  {day}
                                  {!availableSlots.length && (
                                    <div>{t('cart.slot.full')}</div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </Carousel>
                        <div className="slots__container slots__container--desktop">
                          {!!slotsState.selectedSlots.find(
                            (selectedSlotTemp: SlotType) =>
                              selectedSlotTemp.closedEans.length > 0
                          ) && (
                            <div className="slots__closeShelf">
                              <InfoIcon width={15} height={15} fill="#e2001a" />
                              <span className="slots__closeShelf__text">
                                {t('cart.slot.closedShelves')}
                              </span>
                            </div>
                          )}
                          {['morning', 'afternoon', 'evening'].map((period) => {
                            const list = slotsState.selectedSlots.filter(
                              (slot) => slot.period === period
                            );
                            if (list.length) {
                              return (
                                <Slots
                                  key={period}
                                  type={slotsState.tabs.selectedTab}
                                  period={t(`cart.slot.${period}`)}
                                  list={list}
                                  selected={selectedSlot}
                                  selectedSlot={slotsState.selectedSlot}
                                />
                              );
                            }
                            return <React.Fragment key={period} />;
                          })}
                        </div>
                        <div className="slots__container slots__container--mobile">
                          {!!slotsState.selectedSlots.find(
                            (selectedSlotTemp: SlotType) =>
                              selectedSlotTemp.closedEans.length > 0
                          ) && (
                            <div className="slots__closeShelf">
                              <InfoIcon width={15} height={15} fill="#e2001a" />
                              <span className="slots__closeShelf__text">
                                {t('cart.slot.closedShelves')}
                              </span>
                            </div>
                          )}
                          {slotsState.slotByTab?.days?.map(
                            (de: SlotInformationType) => {
                              const availableSlots = de.slots.filter(
                                (sl) => sl.status === 'DISPONIBLE'
                              );
                              let day = dayjs(de.day).format('ddd DD MMM');

                              if (dayjs(de.day).isTomorrow())
                                day = t('cart.slot.tomorrow');
                              if (dayjs(de.day).isToday())
                                day = t('cart.slot.today');
                              const key = `${de.day}-mobile`;
                              return (
                                <Slots
                                  period={day}
                                  type={slotsState.tabs.selectedTab}
                                  key={key}
                                  list={availableSlots}
                                  selected={selectedSlot}
                                  selectedSlot={slotsState.selectedSlot}
                                />
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                </>
              )}
              {(slotsState.slotByTab?.state === 'NON_DESSERVI' ||
                drive24IsEncombrant) &&
                !volumeInError && (
                  <div className="tabsSlot__unavailable">
                    <svg
                      className="tabsSlot__unavailable__picto"
                      height="130"
                      width="169"
                    >
                      <use
                        xlinkHref={`${CartSprite}#no-${slotsState.tabs.selectedTab}`}
                      />
                    </svg>
                    {!drive24IsEncombrant && slotsState.noDeliveryAddress && (
                      <strong>{t('cart.slot.userAddress.unavailable')}</strong>
                    )}
                    {(drive24IsEncombrant || !slotsState.noDeliveryAddress) && (
                      <>
                        <strong>
                          {t(
                            `cart.slot.${slotsState.tabs.selectedTab}.unavailable`
                          )}
                        </strong>
                        <p>
                          {t(`cart.slot.${slotsState.tabs.selectedTab}.other`)}
                        </p>
                      </>
                    )}
                    {drive24IsEncombrant && renderUncompatible()}
                    {!drive24IsEncombrant && slotsState.noDeliveryAddress && (
                      <Button
                        className="tabsSlot__unavailable__button"
                        onClick={() => openChangeAddressState(true)}
                        label={t(`cart.slot.addAddresses`)}
                      />
                    )}
                    {!drive24IsEncombrant && !slotsState.noDeliveryAddress && (
                      <Button
                        className="tabsSlot__unavailable__button"
                        onClick={() => openChangeAddressState(true)}
                        label={t(`cart.slot.manageAddresses`)}
                      />
                    )}
                  </div>
                )}
              {uncompatibleMode(slotsState.tabs.selectedTab) && (
                <div className="tabsSlot__unavailable">
                  <svg
                    className="tabsSlot__unavailable__picto"
                    height="130"
                    width="169"
                  >
                    <use xlinkHref={`${CartSprite}#no-delivery`} />
                  </svg>
                  <strong>{t(`cart.slot.uncompatibleMode.title`)}</strong>
                  <p>{t(`cart.slot.uncompatibleMode.text`)}</p>
                  <Button
                    className="tabsSlot__unavailable__button"
                    onClick={() => {
                      window.location.href = '/orders/cart';
                    }}
                    label={t(`cart.slot.uncompatibleMode.button`)}
                  />
                </div>
              )}
              {!!volumeInError && (
                <div className="tabsSlot__unavailable">
                  <svg
                    className="tabsSlot__unavailable__picto"
                    height="130"
                    width="169"
                  >
                    <use
                      xlinkHref={`${CartSprite}#no-${slotsState.tabs.selectedTab}`}
                    />
                  </svg>
                  <strong>{t(`cart.slot.minimumVolume.title`)}</strong>
                  <p>
                    {t(`cart.slot.minimumVolume.text`, {
                      '%volume%': volumeInError
                    })}
                  </p>
                  <Button
                    className="tabsSlot__unavailable__button"
                    onClick={() => {
                      window.location.href = '/orders/cart';
                    }}
                    label={t(`cart.slot.minimumVolume.button`)}
                  />
                </div>
              )}
            </div>
            <CartHelp />
          </div>
          <SideBarSummary
            isDisabled={(isDisabled) => {
              setButtonMobileDisabled(isDisabled);
            }}
            onClick={redirectOnValidate}
            disabled={
              !cart.slots.selectedSlot.slot ||
              !!productsShelfClosed.length ||
              (!!pdvCart &&
                slotsState.slotByTab?.minimumOrderAmount > pdvCart.total)
            }
            cart={cart}
            userId={userId}
            totalDiscount={immediateDiscount}
            changeAddress={openChangeAddress}
            setSlotsAddresses={setSlotsAddresses}
            isSlots
          />
        </>
      )}

      <BottomFooter
        cart={cart}
        ctaLabel="cart.footer.validate"
        isMobile={true}
        deliveryCost={getItmDeliveryCost(cart)}
        totalDiscount={immediateDiscount}
        onClick={redirectOnValidate}
        disabledButton={buttonMobileDisabled}
      />
    </div>
  );
};

export default SlotsComponent;
