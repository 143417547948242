import t from '@/lib/i18n';
import React, { ReactChild, ReactChildren, useEffect, useState } from 'react';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { useSelector } from 'react-redux';
import { SurveyState } from '@/store/survey/types';
import Button from '@/components/atoms/button';
import Legals from './legals';
import Navigation from './navigation';
import './style.scss';

type FormLayoutProps = {
  children: ReactChild | ReactChildren;
};

const FormLayout = ({ children }: FormLayoutProps) => {
  const { isPostReinit, withMessage } = useSelector(
    ({ survey }: { survey: SurveyState }) => {
      return {
        isPostReinit: survey.postReinit,
        withMessage: survey.withMessage
      };
    }
  );

  useEffect(() => {
    if (isPostReinit && withMessage) {
      setOpenReinitMessage(true);
    }
  }, [isPostReinit, withMessage]);

  const [openReinitMessage, setOpenReinitMessage] = useState(
    isPostReinit && withMessage
  );

  return (
    <div className="smartConsoForm-layout">
      <Navigation
        classname="smartConsoForm-layout__header"
        title={t('smartconso.header.landingTitle')}
        subTitle={t('smartconso.header.landingSubTitle')}
      />
      <div className="smartConsoForm-layout__content">
        {openReinitMessage && isPostReinit && withMessage && (
          <div className="smartConsoForm-layout__reinitMessage">
            <svg className="reinitMessage__icon" height="20px" width="20px">
              <use xlinkHref={`${CommonSprite}#check`} />
            </svg>
            <p className="reinitMessage__text">
              {t('myAccount.smartConso.reinit.success')}
            </p>
            <Button isLink onClick={() => setOpenReinitMessage(false)}>
              <svg
                className="reinitMessage__closeBtn"
                height="16px"
                width="16px"
              >
                <use xlinkHref={`${CommonSprite}#cross-bold`} />
              </svg>
            </Button>
          </div>
        )}
        {children}
      </div>
      <Legals classname="smartConsoForm-layout__footer legals-mobile" />
    </div>
  );
};

export default FormLayout;
