import React, { useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import { notification } from '@/lib/notification';
import { Informations as UserInfo } from '@/lib/api/userInformation/types';
import { InvalidOldPasswordException } from '@/lib/api/userInformation/exceptions';
import userInformationApi from '@/lib/api/userInformation';
import { Controller, useForm } from 'react-hook-form';
import FormInput from '@/components/atoms/formInput';
import Button from '@/components/atoms/button';
import PasswordPolicies, {
  IsValid as IsValidPassword
} from '@/components/molecules/passwordPolicies';
import useEvent from '@/lib/hooks/useEvent';

type ChangePasswordProps = {
  userInfo: UserInfo;
};

type StateType = {
  edit: boolean;
  updating: boolean;
  updated: boolean;
};

type FormType = {
  actualPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

const ChangePassword = ({ userInfo }: ChangePasswordProps) => {
  const [state, setState] = useState<StateType>({
    edit: false,
    updating: false,
    updated: false
  });
  const event = useEvent();

  const {
    register,
    errors,
    handleSubmit,
    getValues,
    setError,
    reset,
    control
  } = useForm<FormType>({
    shouldUnregister: false,
    defaultValues: {
      actualPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    }
  });

  const onSubmit = async (data: FormType) => {
    setState({
      ...state,
      updating: true
    });

    let updated = false;

    try {
      await userInformationApi.changePassword(
        userInfo.id,
        data.actualPassword,
        data.newPassword
      );
      event.send('api', {
        name: 'changePassword',
        type: 'success'
      });
      updated = true;
    } catch (error: any) {
      event.send('api', {
        name: 'changePassword',
        type: 'error'
      });
      if (error instanceof InvalidOldPasswordException) {
        setError('actualPassword', {
          type: 'isValidOldPassword'
        });
      } else {
        notification.error(t('notification.erreur'));
      }
    }

    setState({
      ...state,
      updating: false,
      edit: !updated,
      updated
    });

    if (updated) {
      reset({
        actualPassword: '',
        newPassword: '',
        newPasswordConfirmation: ''
      });
    }
  };

  return (
    <div className="changePassword">
      {!state.edit && (
        <>
          <FormInput
            id="password"
            name="password"
            type="password"
            label={t('myAccount.myDetails.label.password')}
            className="changePassword__input"
            value="password"
            disabled={true}
            withEyes={false}
          />
          <div
            className="changePassword__cta"
            onClick={() => {
              setState({
                ...state,
                edit: true
              });
            }}
          >
            {t('myAccount.myDetails.changePassword')}
          </div>
        </>
      )}
      {state.edit && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            id="actualPassword"
            name="actualPassword"
            type="password"
            label={`${t('myAccount.myDetails.actualPassword')} *`}
            className="changePassword__input"
            ref={register({ required: true })}
            autoComplete="off"
            errorMessage={
              errors.actualPassword
                ? t(`form.error.${errors.actualPassword.type}`)
                : ''
            }
          />
          <Controller
            control={control}
            name="newPassword"
            rules={{
              validate: {
                policies: (value) => {
                  return IsValidPassword(value);
                }
              }
            }}
            render={({ value, name, ref, onChange }, { invalid }) => (
              <>
                <FormInput
                  id={name}
                  name={name}
                  type="password"
                  label={`${t('myAccount.myDetails.newPassword')} *`}
                  className="changePassword__input"
                  ref={ref}
                  onChange={(newValue) => onChange(newValue)}
                  errorMessage={errors.newPassword ? ' ' : ''}
                />
                <PasswordPolicies
                  className="changePassword__policies"
                  value={value}
                  validation={invalid}
                />
              </>
            )}
          />
          <FormInput
            id="newPasswordConfirmation"
            name="newPasswordConfirmation"
            type="password"
            label={`${t('myAccount.myDetails.newPasswordConfirmation')} *`}
            className="changePassword__input"
            ref={register({
              required: true,
              validate: {
                samePassword: (value) => {
                  return getValues('newPassword') === value;
                }
              }
            })}
            errorMessage={
              errors.newPasswordConfirmation
                ? t(`form.error.${errors.newPasswordConfirmation.type}`)
                : ''
            }
          />
          <div className="changePassword__buttons">
            <Button
              className="changePassword__reset"
              type="button"
              color="secondary"
              onClick={() => {
                reset({
                  actualPassword: '',
                  newPassword: '',
                  newPasswordConfirmation: ''
                });

                setState((oldState) => ({
                  ...oldState,
                  edit: false
                }));
              }}
            >
              {t('myAccount.myDetails.cancel')}
            </Button>
            <Button
              className="changePassword__submit"
              type="submit"
              loading={state.updating}
            >
              {t('myAccount.myDetails.validate')}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ChangePassword;
