import React, { useState, FormEvent, ChangeEvent } from 'react';
import { useRouter } from 'next/router';
import t from '@/lib/i18n';
import AccountApi from '@/lib/api/account';
import SecurityLayout from '@/components/organisms/securityLayout';
import useEvent from '@/lib/hooks/useEvent';
import { DisabledAccountException } from '@/lib/api/security/exceptions';
import { ReactComponent as WarningPicto } from '@/assets/images/icons/warning.svg';
import FormPasswordForgotten from '../form';
import SuccessForgottenPassword from '../success';

const EMAIL_VALIDITY_STATUS = {
  initial: 'initial',
  valid: 'valid',
  nonvalid: 'nonvalid'
};

const ContentForgottenPassword = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState(
    (router?.query?.email as string) || ''
  );
  const [emailValidity, setEmailValidity] = useState(
    EMAIL_VALIDITY_STATUS.initial
  );
  const [disabledAccountMessage, setDisabledAccountMessage] = useState(false);
  const event = useEvent();
  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (emailValue?.length > 0) {
      try {
        const resultApi = await AccountApi.sendResetPasswordEmail(emailValue);
        resultApi
          ? event.send('api', {
              name: 'forgottenPassword',
              type: 'success'
            })
          : event.send('ap', {
              name: 'forgottenPassword',
              type: 'error'
            });
        setEmailValidity(
          resultApi
            ? EMAIL_VALIDITY_STATUS.valid
            : EMAIL_VALIDITY_STATUS.nonvalid
        );
      } catch (error) {
        if (error instanceof DisabledAccountException) {
          setDisabledAccountMessage(true);
        }
      }

      setLoading(false);
    }
  };

  const goBack = {
    label: t('forgottenPassword.goBack.label'),
    url: '/home'
  };

  return (
    <SecurityLayout
      goBack={
        emailValidity !== EMAIL_VALIDITY_STATUS.valid ? goBack : undefined
      }
    >
      {emailValidity === EMAIL_VALIDITY_STATUS.valid ? (
        <SuccessForgottenPassword email={emailValue} />
      ) : (
        <>
          {disabledAccountMessage && (
            <div className="securityLayout__errorMessage">
              <WarningPicto width={20} height={20} />
              <span
                dangerouslySetInnerHTML={{
                  __html: t('connexion.disabled-account', {
                    '%link%': `<a class="emailLink" href="/account-activation?email=${emailValue}">${t(
                      'connexion.disabled-account.link'
                    )}</a>`
                  })
                }}
              />
            </div>
          )}
          <FormPasswordForgotten
            handleSubmit={handleSubmit}
            submitActive={emailValue.length > 0}
            emailValue={emailValue}
            onChange={onChangeEmail}
            isEmailError={emailValidity === EMAIL_VALIDITY_STATUS.nonvalid}
            loading={loading}
          />
        </>
      )}
    </SecurityLayout>
  );
};

export default ContentForgottenPassword;
