import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import { parseToPrice } from '@/lib/model/price';
import Date from '@/lib/utils/date';
import { TypeMapping } from './mappings';
import { PdvVoucher, Type, Voucher } from './types';

class VoucherApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  async list(userId: string): Promise<Array<PdvVoucher>> {
    let response = null;
    const data: Array<PdvVoucher> = [];

    try {
      response = await this.axios.get(
        `/bonachat/v1/consommateurs/${userId}/bons-achat`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch user vouchers',
        error
      });
    }

    response?.data.forEach((elt: any) => {
      let total = 0;

      const vouchers = elt.bonsAchat.map((voucher: any) => {
        const type = voucher.type as keyof typeof TypeMapping;

        if (voucher.statut === 'ACTIF') {
          total += voucher.montant;
        }

        return {
          id: voucher.id,
          amount: parseToPrice(voucher.montant),
          createdAt: Date(voucher.dateCreation),
          endDate: Date(voucher.dateFin),
          type: TypeMapping[type] as Type,
          status: voucher.statut === 'ACTIF' ? 'enabled' : 'disabled'
        } as Voucher;
      });

      const pdvVouchers: PdvVoucher = {
        pdv: {
          ref: elt.pdv.idPdv,
          format: elt.pdv.enseigne.replace('Intermarché', '').trim(),
          city: elt.pdv.ville
        },
        vouchers: vouchers.filter((v: Voucher) => v.status === 'enabled'),
        amount: parseToPrice(total),
        endDate: Date(elt.dateLimite)
      };

      data.push(pdvVouchers);
    });

    return data;
  }
}

export default new VoucherApi();
