import React from 'react';
import Image from '@/components/atoms/image';
import classnames from 'classnames';
import './style.scss';

export type HeadingBrandProps = {
  title: string;
  backgroundImage: HeadingBrandImageProps;
  brandImage: HeadingBrandImageProps;
  description: string;
};

export type HeadingBrandImageProps = {
  title: string;
  fileUrl: string;
  filewidth: number;
  fileHeight: number;
};

const HeadingBrand = ({
  title,
  backgroundImage,
  brandImage,
  description
}: HeadingBrandProps) => {
  return (
    <div className={classnames('headingBrand')}>
      <div className="headingBrand__background">
        <Image
          className="headingBrand__background__image"
          src={backgroundImage.fileUrl}
          alt={backgroundImage.title}
        />
      </div>

      <div className="headingBrand__wrapper">
        <Image
          src={brandImage.fileUrl}
          alt={brandImage.title}
          width={brandImage.filewidth}
          height={brandImage.fileHeight}
        />
        {title && (
          <h1
            className="headingBrand__wrapper__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {description && (
          <span
            className="headingBrand__wrapper__description"
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HeadingBrand;
