import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import dayjs, { Dayjs } from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { useMountedState } from 'react-use';

export type HighlightProps = {
  id: string;
  endDate: Dayjs | string;
};

const Highlight = ({ id, endDate }: HighlightProps) => {
  const toolTipIsLoaded = useMountedState();
  return (
    <div data-tip data-for={`promo-${id}`} className="highlightTag">
      {t('highlight')}

      {toolTipIsLoaded() && (
        <ReactTooltip place="top" id={`promo-${id}`} effect="float">
          <span
            className="highlightTag__tip"
            dangerouslySetInnerHTML={{
              __html: t(`highlight.endDate`, {
                '%date%': dayjs(endDate).format('DD/MM/YYYY')
              })
            }}
          />
        </ReactTooltip>
      )}
    </div>
  );
};

export default Highlight;
