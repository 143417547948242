import React from 'react';
import { withESI, ESIOptions } from '@/lib/esi';
import Layout from '@/components/organisms/layout';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Header from '@/components/organisms/header';
import Content from './contentESI';

export type PdvProps = {
  pdvRef?: string;
  footer: ContentfulContent;
};

const Pdv = ({ pdvRef, footer }: PdvProps) => {
  const headerPart = <Header />;

  const ContentESI = withESI(Content, {
    id: 'store-page',
    type: 'templates/pdv',
    name: 'content'
  });

  const content = <ContentESI esi={ESIOptions()} pdvRef={pdvRef} />;

  return (
    <Layout
      className="StorePdv"
      header={headerPart}
      content={content}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
    />
  );
};

export default Pdv;
