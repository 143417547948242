import React from 'react';
import { ReactComponent as NoAddressIcon } from '@/assets/images/sprites/myAccount/address.svg';

import './style.scss';

export type NoAddressProps = {
  text: string;
};

const NoAddress = ({ text }: NoAddressProps) => {
  return (
    <div className="noAddress">
      <NoAddressIcon className="noAddress__icon" />
      <span className="noAddress__text">{text}</span>
    </div>
  );
};

export default NoAddress;
