import React from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import { useRouter } from 'next/router';
import { withESI, ESIOptions } from '@/lib/esi';
import Layout from '@/components/organisms/layout';
import Header from '@/components/organisms/header';
import ProductList from '@/components/organisms/productListESI';
import StoreLocatorLayer from '@/components/organisms/storeLocatorLayer';
import Heading from './headingESI';
import './style.scss';

export type PlpProps = {
  footer: ContentfulContent;
};

const Plp = ({ footer }: PlpProps) => {
  const router = useRouter();
  const query = { ...((router?.query ?? {}) as { [key: string]: any }) };

  const slug = (query['slug'] ?? []) as Array<string>;
  delete query['slug'];

  const baseUrl = `/shelves/${slug.join('/')}`;
  const categoryId = slug[slug.length - 1];

  const headerPart = <Header />;

  const ProductListESI = withESI(ProductList, {
    id: 'plp-content',
    type: 'organisms',
    name: 'productList'
  });

  const HeadingtESI = withESI(Heading, {
    id: 'plp-heading',
    type: 'templates',
    name: 'plp/heading'
  });

  const content = (
    <>
      <div className="container container--xl">
        <HeadingtESI slug={slug} />
        <ProductListESI
          esi={ESIOptions()}
          type="category"
          categoryId={categoryId}
          baseUrl={baseUrl}
          query={query}
        />
      </div>
      <StoreLocatorLayer redirectType="plp" redirectId={categoryId} />
    </>
  );

  return (
    <Layout
      header={headerPart}
      content={content}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
    />
  );
};

export default Plp;
