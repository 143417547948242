import React from 'react';
import SlotsPublicApi from '@/lib/api/slots/public';
import { Request, Response } from 'express';
import usePdv from '@/lib/hooks/usePdv';
import Slot from '@/lib/model/slot';
import { Provider } from 'react-redux';
import { store } from '@/store';
import SlotsBanner, { SlotsBannerProps } from '../slots';

type InitialProps = {
  props: SlotsBannerProps;
  req: Request;
  res: Response;
};

const SlotsBannerESI = ({ slots }: SlotsBannerProps) => {
  return (
    <Provider store={store}>
      {!!slots?.length && <SlotsBanner slots={slots} />}
    </Provider>
  );
};

SlotsBannerESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref, isEcommerce } = usePdv(req);

  let slots: Array<Slot> = [];

  if (ref && isEcommerce) {
    slots = await SlotsPublicApi.getPdvSlots(ref);
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=900, max-age=900');
    }

    resolve({
      ...props,
      slots: slots.map((slot) => {
        return {
          type: slot.type,
          date: slot.date,
          time: slot.time,
          status: slot.status
        };
      })
    });
  });
};

export default SlotsBannerESI;
