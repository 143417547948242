import React from 'react';
import './style.scss';
import CustomScoreLabels from '@/components/atoms/scoreLabels/custom';

export type LabelsAvoidProps = {
  flags: Array<{ key: number }>;
};

const LabelsAvoid = ({ flags }: LabelsAvoidProps) => {
  return (
    <div className="labelsAvoid">
      <div className="nutritionSection">
        <div className="nutritionSection__content">
          <div className="nutritionSection__content__item">
            <CustomScoreLabels avoid flags={flags} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabelsAvoid;
