import Link from '@/components/atoms/link';
import React, { memo } from 'react';
import t from '@/lib/i18n';
import useEvent from '@/lib/hooks/useEvent';
import { Operation as OperationItemProps } from '@/lib/api/stickers/types';

import SharePopin from '../sharePopin';

export type FooterLinksProps = {
  operation: OperationItemProps;
  balance: number;
  cardNumber?: string;
};

const FooterLinks = ({ operation, balance, cardNumber }: FooterLinksProps) => {
  const event = useEvent();
  const partner = operation.shortName;
  return (
    <ul className="operation__footer">
      {operation.associatedStoreId && (
        <li className="operation__footer_item">
          <Link
            href={`/shop/${operation.associatedStoreId}`}
            label={t(`myAccount.fidelity.myStickers.operations.links.partner`)}
            className="operation__footer_link"
            onClick={() =>
              event.send('stickers', {
                type: 'shop',
                partner
              })
            }
          />
        </li>
      )}
      <li className="operation__footer_item">
        <SharePopin
          operation={operation}
          balance={balance}
          cardNumber={cardNumber}
        />
      </li>
      {operation.links.modality && (
        <li className="operation__footer_item">
          <Link
            href={operation.links.modality}
            label={t(`myAccount.fidelity.myStickers.operations.links.modality`)}
            className="operation__footer_link"
            onClick={() =>
              event.send('stickers', {
                type: 'modality',
                partner
              })
            }
          />
        </li>
      )}
      {operation.links.landing && (
        <li className="operation__footer_item operation__footer_item--emphasis">
          <Link
            href={operation.links.landing}
            label={t(`myAccount.fidelity.myStickers.operations.links.landing`)}
            className="operation__footer_link"
            onClick={() =>
              event.send('stickers', {
                type: 'details',
                partner
              })
            }
          />
        </li>
      )}
    </ul>
  );
};

export default memo(FooterLinks);
