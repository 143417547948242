import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import Link from '@/components/atoms/link';
import { FidelityInfo } from '@/lib/api/fidelity/types';
// import FidelityLevel, {
//   FidelityLevelValue
// } from '@/components/molecules/fidelityLevel';
// import Date from '@/lib/utils/date';
// import MyVisitMessages from '../myVisitMessages';

type MyAdvantagesProps = {
  fidelityInfo?: FidelityInfo;
};

const MyAdvantages = ({ fidelityInfo }: MyAdvantagesProps) => {
  return (
    <div className="myAdvantages">
      {!fidelityInfo && (
        <>
          <div className="myAccountDashboard__fidelity_title">
            {t('myAccount.dashboard.fidelity.empty.title')}
          </div>
          <div className="myAccountDashboard__fidelity_content--empty">
            {t(`myAccount.dashboard.fidelity.empty.content`)}
          </div>
          <div className="myAccountDashboard__fidelity_action">
            <Link
              id="createCard"
              className="myAccountDashboard__fidelity_link"
              href="/fidelity-advantages"
              button
            >
              {t('myAccount.dashboard.fidelity.empty.link')}
            </Link>
          </div>
        </>
      )}
      {/* {!!fidelityInfo && (
        <Link
          className="myAccountDashboard__fidelity_content"
          href="/account-management/my-fidelity"
        >
          <>
            <div className="myAccountDashboard__fidelity_visiteTitle">
              {t(`myAccount.dashboard.fidelity.visiteTitle`, {
                '%month%': Date().format('MMMM')
              })}
            </div>
            <FidelityLevel
              level={fidelityInfo?.countVisits as FidelityLevelValue}
            />
            <MyVisitMessages countVisits={fidelityInfo?.countVisits} />
          </>
        </Link>
      )} */}
    </div>
  );
};

export default MyAdvantages;
