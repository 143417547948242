import React from 'react';
import ProductApi from '@/lib/api/product';
import { Request, Response } from 'express';
import ProductModel from '@/lib/model/product';
import usePdv from '@/lib/hooks/usePdv';
import { Provider } from 'react-redux';
import { store } from '@/store';
import ProductDetail, { ProductDetailProps } from '../content';

type InitialProps = {
  props: ProductDetailProps;
  req: Request;
  res: Response;
};

const ProductDetailESI = (props: ProductDetailProps) => {
  return (
    <Provider store={store}>
      <ProductDetail {...props} />
    </Provider>
  );
};

ProductDetailESI.getInitialProps = async ({
  props,
  req,
  res
}: InitialProps) => {
  const { ref } = usePdv(req);

  let product: ProductModel | null = null;

  if (ref) {
    try {
      product = await ProductApi.findByEan(ref, props.ean);
    } catch (error: any) {
      product = null;
    }
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=900, max-age=900');
    }

    resolve({
      ...props,
      data: product,
      currentOffer: product?.bestOffer
    });
  });
};

export default ProductDetailESI;
