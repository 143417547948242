import React from 'react';

import Layout from '@/components/organisms/layout';
import {
  Content as ContentfulContent,
  RendererComponents as ContentfulRenderComponents
} from '@/lib/contentful';
import t from '@/lib/i18n';
import { useSelector } from 'react-redux';
import ConfirmationComponent from './content';
import Header from '../header';

export type InscriptionProps = {
  footer: ContentfulContent;
};

const Validation: React.FunctionComponent<InscriptionProps> = ({ footer }) => {
  const { register: local } = useSelector(({ register }: any) => {
    return {
      register
    };
  });
  const headerPart = <Header step="confirm" />;

  const contentPart = (
    <ConfirmationComponent
      title={t('register.validation.title')}
      subtitle={t('register.validation.subtitle')}
      message={t('register.validation.message')}
      description={t('register.validation.description', {
        '%mail%': local.email ? local.email : ''
      })}
      cta={t('register.validation.cta')}
    />
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout
      className="create-my-account"
      header={headerPart}
      content={contentPart}
      footer={footerPart}
    />
  );
};

export default Validation;
