import { TimelineItemProps } from '@/components/molecules/timeline';
import { normalize as BaseNormalize, NormalizeParams } from '..';

const normalize = ({ data, includes, extras }: NormalizeParams) => {
  const { data: items = [] } = data;
  const itemsList: Array<TimelineItemProps> = [];

  items.forEach((element: any) => {
    const itemData = includes[element.sys.id] || null;
    itemsList.push(
      (BaseNormalize({
        data: itemData,
        includes,
        extras
      }) as unknown) as TimelineItemProps
    );
  });

  return {
    type: 'molecules',
    name: 'timeline',
    props: {
      title: data.title || null,
      data,
      itemsList
    }
  };
};

export default normalize;
