import React, { useCallback, useEffect, useRef, useState } from 'react';
import './style.scss';
import PaymentApi from '@/lib/api/payment';
import LoadableContent from '@/components/atoms/loadableContent';
import t from '@/lib/i18n';
import Button from '@/components/atoms/button';
import CartSprite from '@/assets/images/sprites/cart.svg';
import { AuthStateType } from '@/store/auth/authReducer';
import { useSelector } from 'react-redux';
import { StateType as CartStateType } from '@/store/cart/types';
import { useRouter } from 'next/router';
import { DELIVERY_TYPE_MAPPING_INVERSE } from '@/lib/model/slot';
import Loader from '@/components/atoms/loader';
import PaymentFallbackModal from '../payment/paymentFallbackModal';

const TIMEOUT = 240000;
const MBWAY = 'PAYMENT_IN_PROGRESS';

const Validation = () => {
  const [stateValidation, setStateValidation] = useState<{
    loading: boolean;
    token: string | null;
    code: string | null;
    message: {
      title: string;
      text: string;
      button: string;
    };
    paymentFallback?: boolean;
  }>({
    loading: true,
    code: null,
    token: '',
    paymentFallback: false,
    message: {
      title: '',
      text: '',
      button: ''
    }
  });
  const [elapsedTime, setElapsedTime] = useState(0);
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );

  const { availablePayments, deliveryType } = useSelector(
    ({ cart }: { cart: CartStateType }) => cart.slots.selectedSlot
  );
  const isMounted = useRef(false);

  const validation = useCallback(
    async (paylinetoken: string | null) => {
      setStateValidation((state) => ({ ...state, loading: true }));
      if (!paylinetoken) return;
      try {
        const data = await PaymentApi.validation(paylinetoken, userId);
        window.location.href = `/orders/confirmation?orderNumber=${data.orderId}`;
      } catch (error: any) {
        const firstError = error.response?.data?.errors?.shift();
        const firstErrorCode = firstError?.code ?? null;
        const firstErrorMessage = firstError?.message ?? '';
        const mobilePhone =
          firstErrorMessage
            ?.split('mobilePhone=')
            ?.pop()
            ?.split('}}')?.[0]
            .replace('#', ' ') ?? '';
        if (firstErrorCode === MBWAY) {
          setStateValidation((state) => ({
            ...state,
            code: firstErrorCode,
            loading: false,
            message: {
              text: t(`cart.payment.validation.${firstErrorCode}.text`, {
                '%mobilePhone%': mobilePhone
              }),
              title: t(`cart.payment.validation.${firstErrorCode}.title`),
              button: ''
            }
          }));
          return;
        }
        const validationErrorCodes = [
          'INVALID_ORDER_SNAPSHOT',
          'PAYLINE_TRANSACTION_REFUSED',
          'GATEWAY_TIMEOUT',
          'ORDER_ALREADY_CREATED',
          'SESSION_EXPIRED',
          'MISSING_REQUEST_PARAMETER',
          'BAD_REQUEST',
          'UNKNOWN_PAYLINE_TOKEN',
          'INVALID_CUSTOMER_ID',
          'UNKNOWN_PARENT_ORDER_REFERENCE'
        ];

        const paymentErrorCodes = [
          'ONLINE_PAYMENT_NOT_SUPPORTED',
          'REFUSED_PAYMENT_CARD',
          'REFUSED_PAYMENT'
        ];

        const isPaymentError = paymentErrorCodes.includes(firstErrorCode);

        const isPaymentFallbackPossible =
          isPaymentError &&
          deliveryType === DELIVERY_TYPE_MAPPING_INVERSE.drive &&
          Object.keys(availablePayments).includes('RECEPTION');

        const isValidationError = validationErrorCodes.includes(firstErrorCode);
        const validationErrorCode =
          isValidationError || isPaymentError ? firstErrorCode : 'DEFAULT';

        setStateValidation((state) => ({
          ...state,
          code: firstErrorCode,
          loading: false,
          ...(isPaymentFallbackPossible
            ? { paymentFallback: true }
            : {
                message: {
                  text: t(
                    `cart.payment.validation.${validationErrorCode}.text`
                  ),
                  title: t(
                    `cart.payment.validation.${validationErrorCode}.title`
                  ),
                  button: t(
                    `cart.payment.validation.${validationErrorCode}.button`
                  )
                }
              })
        }));
      }
    },
    [userId, availablePayments, deliveryType]
  );

  useEffect(() => {
    const retryFunc = () => {
      setElapsedTime((prevTime) => prevTime + 5000);
    };
    if (
      stateValidation.message.text.includes('MB Way') &&
      elapsedTime <= TIMEOUT
    ) {
      setTimeout(retryFunc, 5000);
    }
  }, [elapsedTime, stateValidation.message.text]);

  useEffect(() => {
    if (isMounted.current && (elapsedTime === 0 || elapsedTime >= TIMEOUT))
      return;
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('paylinetoken');
      setStateValidation((state) => ({
        ...state,
        token
      }));
      await validation(token);
      isMounted.current = true;
    })();
  }, [isMounted, elapsedTime, validation]);

  const onClickHandler = async () => {
    if (
      stateValidation.code === 'GATEWAY_TIMEOUT' ||
      stateValidation.code === 'ORDER_ALREADY_CREATED'
    ) {
      window.location.href = '/account-management/my-groceries';
    } else {
      //  await validation(stateValidation.token);
      window.location.href = '/orders/payment';
    }
  };

  const router = useRouter();
  const handleFallbackPayment = (paymentType: string) => {
    router.push(`/orders/payment/fallback/${paymentType}`);
  };
  if (stateValidation.message.text.includes('MB Way')) {
    return (
      <>
        <Loader color="red" size="medium" />
        <div className="validationPage__mbway__text">
          <strong className="validationPage__mbway__text_title">
            {stateValidation.message.title}
          </strong>
          <p className="validationPage__text">{stateValidation.message.text}</p>
        </div>
      </>
    );
  }

  return (
    <LoadableContent
      loading={stateValidation.loading}
      className="validationPage"
    >
      {stateValidation.paymentFallback ? (
        <PaymentFallbackModal
          open={stateValidation.paymentFallback}
          onClose={() => {
            setStateValidation((oldState) => ({
              ...oldState,
              paymentFallback: false
            }));
          }}
          handleFallbackPayment={handleFallbackPayment}
          availablePayments={availablePayments}
        />
      ) : (
        <div className="container">
          <svg className="validationPage__picto" width={128} height={116}>
            <use xlinkHref={`${CartSprite}#warning`} />
          </svg>
          <strong>{stateValidation.message.title}</strong>
          <p className="validationPage__text">{stateValidation.message.text}</p>
          <Button
            onClick={onClickHandler}
            label={stateValidation.message.button}
          />
        </div>
      )}
    </LoadableContent>
  );
};

export default Validation;
