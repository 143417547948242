import React from 'react';
import t from '@/lib/i18n';
import { Content as ContentfulContent } from '@/lib/contentful';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import Link from '@/components/atoms/link';

export type MyContactProps = {
  footer: ContentfulContent;
  content: string;
  pageView: string;
};

const MyContact = ({ footer, pageView, content }: MyContactProps) => {
  const contentPart = (
    <div className="myAccountDashboard">
      <h3>CONTACTAR LOJA ONLINE</h3>
      <div>
        Para questões relacionadas com o funcionamento do site e a sua conta
        online, envie mensagem para o email:{' '}
        <Link href="mailto:lojaonline@mousquetaires.com" target="_blank">
          lojaonline@mousquetaires.com
        </Link>
        <br /> Para questões referentes à sua encomenda/compra online no site,
        contacte diretamente a sua loja através dos contactos de telefone ou
        email disponíveis no site.
      </div>
    </div>
  );
  return (
    <MyAccountLayout
      title={t('myAccount.menu.contact')}
      enabledId="contact"
      content={contentPart}
      footer={footer}
      pageView={pageView}
    />
  );
};

export default MyContact;
