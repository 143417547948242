import React, { memo } from 'react';
import classnames from 'classnames';

import './style.scss';

import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import { useRouter } from 'next/router';

export type SubNavProps = {
  navItems?: Array<Component>;
};

const addPrefixe = (items: Array<Component>, path?: string) =>
  items.map((item) => {
    const href = `/fidelity-advantages${
      item.props.href !== '/' ? item.props.href : ''
    }`;

    return {
      ...item,
      props: {
        ...item.props,
        href,
        className: classnames({
          'subNav--active': path === href
        })
      }
    };
  });

const SubNav = ({ navItems }: SubNavProps) => {
  const router = useRouter();

  return (
    <div className="subNav">
      {navItems && (
        <ContentfulRenderComponents
          components={addPrefixe(navItems, router?.asPath)}
        />
      )}
    </div>
  );
};

export default memo(SubNav);
