import React from 'react';
import './style.scss';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import { useRouter } from 'next/router';
import { withESI, ESIOptions } from '@/lib/esi';
import Layout from '@/components/organisms/layout';
import Header from '@/components/organisms/header';
import ProductList from '@/components/organisms/productListESI';
import StoreLocatorLayer from '@/components/organisms/storeLocatorLayer';

export type ShopProps = {
  footer: ContentfulContent;
};

const Shop = ({ footer }: ShopProps) => {
  const router = useRouter();
  const query = { ...((router?.query ?? {}) as { [key: string]: any }) };

  const shopId = query['shopId'] ?? '';
  const baseUrl = `/shop/${shopId}`;
  delete query['shopId'];

  const headerPart = <Header />;

  const ProductListESI = withESI(ProductList, {
    id: 'shop-content',
    type: 'organisms',
    name: 'productList'
  });

  const content = (
    <>
      <div className="container container--xl">
        <ProductListESI
          esi={ESIOptions()}
          type="shop"
          shopId={shopId}
          baseUrl={baseUrl}
          query={query}
        />
      </div>
      <StoreLocatorLayer redirectType="shop" redirectId={shopId} />
    </>
  );

  return (
    <Layout
      className="shopPage"
      header={headerPart}
      content={content}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
    />
  );
};

export default Shop;
