import React from 'react';

import CartSprite from '@/assets/images/sprites/cart.svg';
import t from '@/lib/i18n';

const BlockSubstitution = () => (
  <div className="blockSubstition">
    <div className="blockSubstition__heading">
      <svg width="30" height="30">
        <use xlinkHref={`${CartSprite}#IllustrationsUnivers`} />
      </svg>
      <h3 className="blockSubstition__heading__header">
        {t('cart.blockSubstitution.header')}
      </h3>
    </div>
    <p className="blockSubstition__heading__description">
      {t('cart.blockSubstitution.description')}
    </p>
  </div>
);

export default BlockSubstitution;
