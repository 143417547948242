import React, { useState, useEffect } from 'react';
import './style.scss';
import Date from '@/lib/utils/date';
import { Dayjs } from 'dayjs';
import t from '@/lib/i18n';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import FormInput from '../formInput';

export type FormDateProps = {
  id?: string;
  className?: string;
  placeholder?: string;
  label?: string;
  name?: string;
  value?: Dayjs;
  onChange?: (value: Dayjs | null) => void;
  errorMessage?: string | JSX.Element;
  message?: string;
  disabled?: boolean;
};

const FormDate = ({
  id,
  className,
  label,
  value,
  onChange,
  errorMessage,
  message,
  disabled
}: FormDateProps) => {
  const [state, setState] = useState({
    date1: value ? Date(value).format('DD') : '',
    date2: value ? Date(value).format('MM') : '',
    date3: value ? Date(value).format('YYYY') : ''
  });

  const { current: onChangeDate } = React.useRef(onChange);

  const { errors, register, trigger, watch } = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      date1: state.date1 || '',
      date2: state.date2 || '',
      date3: state.date3 || ''
    }
  });

  const regexDay = /^(3[01]|[12][0-9]|0?[1-9])$/;
  const regexFebruary = /^([12][0-9]|0?[1-9])$/;
  const regexMonth = /^(0?[1-9]|1[012])$/;
  const regexYear = /^(19|20)\d{2}$/;

  const watchFields = watch(['date2']);

  const [isFebruary, setIsFebruary] = useState(false);

  const getDayErrorMessage = (): string => {
    return isFebruary
      ? t('register.form.dayFebruary.error')
      : t('register.form.day.error');
  };

  useEffect(() => {
    setIsFebruary(Number(watchFields.date2) === 2);
  }, [setIsFebruary, watchFields.date2]);

  useEffect(() => {
    const { date1, date2, date3 } = state;

    const formatedDay = Number(date1).toLocaleString('fr-FR', {
      minimumIntegerDigits: 2
    });
    const formatedMonth = Number(date2).toLocaleString('fr-FR', {
      minimumIntegerDigits: 2
    });

    if (onChangeDate) {
      onChangeDate(
        Date(`${formatedDay}/${formatedMonth}/${date3}`, 'DD/MM/YYYY', true)
      );
    }
  }, [state, onChangeDate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value: dateValue, name } = e.target;
    const [, fieldIndex] = name.split('-');

    trigger(['date1', 'date2', 'date3']);

    if (dateValue.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < 3) {
        const nextDate: HTMLInputElement | null = document.querySelector(
          `input[name=date${parseInt(fieldIndex, 10) + 1}]`
        );
        if (nextDate !== null) {
          nextDate.focus();
        }
      }
    }
    setState({
      ...state,
      [`${name}`]: dateValue
    });
  };

  return (
    <div
      className={classnames('formDate', className, {
        'formDate--hasError': !!errorMessage
      })}
    >
      {label && (
        <label htmlFor={id} className="formDate__label">
          {label}
        </label>
      )}
      <div className="formDate__inputs">
        <FormInput
          id="formDateInputsDay"
          placeholder="DD"
          name="date1"
          value={state.date1}
          onChange={handleChange}
          maxLength={2}
          disabled={disabled}
          ref={register({
            required: true,
            validate: {
              isValidDay: (newValue) => {
                return isFebruary
                  ? regexFebruary.test(newValue)
                  : regexDay.test(newValue);
              }
            }
          })}
          errorMessage={errors.date1 ? getDayErrorMessage() : ''}
        />
        <FormInput
          id="formDateInputsMonth"
          placeholder="MM"
          name="date2"
          maxLength={2}
          value={state.date2}
          onChange={handleChange}
          disabled={disabled}
          ref={register({
            required: true,
            validate: {
              isValidMonth: (newValue) => {
                return regexMonth.test(newValue);
              }
            }
          })}
          errorMessage={errors.date2 ? t('register.form.month.error') : ''}
        />
        <FormInput
          id="formDateInputsYear"
          placeholder="AAAA"
          name="date3"
          maxLength={4}
          value={state.date3}
          onChange={handleChange}
          disabled={disabled}
          ref={register({
            required: true,
            validate: {
              isValidYear: (newValue) => {
                return regexYear.test(newValue);
              }
            }
          })}
          errorMessage={errors.date3 ? t('register.form.year.error') : ''}
        />
      </div>
      {errorMessage && (
        <div className="formDate__errorMessage">{errorMessage}</div>
      )}
      {!errorMessage && message && (
        <div className="formDate__message">{message}</div>
      )}
    </div>
  );
};

export default FormDate;
