import React from 'react';
import Layout from '@/components/organisms/layout';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Header from '@/components/organisms/header';

export type ContactProps = {
  content: ContentfulContent;
  footer: ContentfulContent;
};

const Contact = ({ content, footer }: ContactProps) => {
  const headerPart = <Header />;

  const contentPart = content && (
    <ContentfulRenderComponents components={content.components} />
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout header={headerPart} content={contentPart} footer={footerPart} />
  );
};

export default Contact;
