import React from 'react';
import './style.scss';
import Link from '@/components/atoms/link';
import Title from '@/components/atoms/title';
import Paragraph from '@/components/atoms/paragraph';
import t from '@/lib/i18n';
import SavSubBanner, { SavSubBannerProps } from './savSubBanner';

export type SavBannerProps = {
  withSubBanner?: boolean;
  subBanner?: SavSubBannerProps;
};

const SavBanner = ({ withSubBanner = true, subBanner }: SavBannerProps) => {
  const { href = '' } = subBanner || {};
  return (
    <>
      <div className="savBanner">
        <Title tag="h3" label={t('sav.title')} className="savBanner__h3" />
        <div className="savBanner__image">
          <Paragraph
            label={t('sav.banner.title')}
            className="savBanner__image__text"
          />
          <Link
            button
            href="/sign/top-deals/FAQ-intermarche"
            label={t('sav.banner.btn')}
            className="savBanner__image__button"
          />
        </div>
      </div>
      {withSubBanner && <SavSubBanner href={href} />}
    </>
  );
};

export default SavBanner;
