import React, { useState } from 'react';
import './style.scss';
import { ServiceProvidersType, SlotsCartType } from '@/store/cart/types';
import CartSprite from '@/assets/images/sprites/cart.svg';
import t from '@/lib/i18n';
import Button from '@/components/atoms/button';
import Modal from '@/components/organisms/modal';
import CarrierCostExplanation from '@/components/organisms/checkout/carrierCostExplanation';
import { SLOT_TYPE_MAPPING } from '@/lib/model/slot';
import useEvent from '@/lib/hooks/useEvent';
import { getDeliveryType } from '@/lib/model/pdv';

type ProviderProps = {
  slots: SlotsCartType;
  isConfirmPage?: boolean;
};

const Providers = ({ slots, isConfirmPage = false }: ProviderProps) => {
  const [
    selectedProvider,
    setSelectedProvider
  ] = useState<ServiceProvidersType | null>(null);
  const event = useEvent();

  const providerInfo: ServiceProvidersType | undefined = slots.list[
    getDeliveryType(slots.selectedSlot.deliveryType)
  ].serviceProviders.find((p) => p.id === slots.selectedProvider?.id);

  const { providers } = slots.selectedSlot.slot || {};

  let showProviderInfos = true;

  if (
    providers?.length === 1 &&
    providerInfo &&
    providerInfo.type === 'INTERNE'
  ) {
    showProviderInfos = false;
  }

  const conditionToShow =
    slots.selectedProvider && providerInfo && showProviderInfos;

  const providerImage = conditionToShow ? (
    <img
      height={25}
      src={providerInfo?.logo}
      alt={providerInfo?.desktopTitle}
    />
  ) : null;

  if (isConfirmPage) return providerImage;

  return (
    <>
      {slots.selectedProvider && providerInfo && showProviderInfos && (
        <div key={slots.selectedProvider.id} className="cartInfo providers">
          <svg width={25} height={25}>
            <use xlinkHref={`${CartSprite}#help`} />
          </svg>
          <div className="cartInfo__content">
            {providerImage}
            <div className="cartInfo__item">
              {t('cart.slot.selectedDelivery')}
            </div>
            {providerInfo.type !== 'INTERNE' && (
              <div className="cartInfo__item">
                <Button
                  onClick={() => {
                    if (!slots.selectedProvider) return;
                    setSelectedProvider(providerInfo);
                    event.send('checkout', {
                      type: 'partnerInfo',
                      provider: providerInfo.desktopTitle
                    });
                  }}
                  isLink={true}
                  label={t('cart.slot.delivery.moreInfo')}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {selectedProvider && selectedProvider.type !== 'INTERNE' && (
        <Modal
          open={!!selectedProvider}
          className="providers__modal"
          onClose={() => {
            setSelectedProvider(null);
          }}
        >
          <strong className="providers__titleModal providers__titleModal--mobile">
            {t('delivery.deliverBy')}
          </strong>
          <strong className="providers__titleModal providers__titleModal--desktop">
            {t('delivery.deliverBy')}
          </strong>
          <img
            width={250}
            src={selectedProvider?.logo}
            alt={selectedProvider?.desktopTitle}
            height={50}
          />
          <div className="providers__textModal providers__textModal--mobile">
            {selectedProvider?.descriptionDesktop}
          </div>
          <div className="providers__textModal providers__textModal--desktop">
            {selectedProvider?.descriptionMobile}
          </div>
          <CarrierCostExplanation
            data={slots.list[SLOT_TYPE_MAPPING.DOMICILE]}
            classNameContent="providers__infosModal"
          />
        </Modal>
      )}
    </>
  );
};

export default Providers;
