import React from 'react';
import FormInput from '@/components/atoms/formInput';
import Title from '@/components/atoms/title';
import Button from '@/components/atoms/button';
import PasswordPolicies, {
  IsValid as IsValidPassword
} from '@/components/molecules/passwordPolicies';
import { Controller, useForm } from 'react-hook-form';
import t from '@/lib/i18n';
import './style.scss';

export type FormRenewPasswordProps = {
  submitNewPassword: (newPassword: string) => void;
};

type ValidationFormData = {
  password: string;
  passwordConf: string;
};

const FormRenewPassword = ({ submitNewPassword }: FormRenewPasswordProps) => {
  const { register, errors, handleSubmit, control, getValues } = useForm();
  const onSubmit = (data: ValidationFormData) => {
    submitNewPassword(data.password);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title
        tag="h2"
        label={t('renew.password.chooseNewPassword')}
        className="renewPassword__form__title"
      />
      <Controller
        control={control}
        name="password"
        type="password"
        rules={{
          validate: {
            policies: (value) => {
              return IsValidPassword(value || '');
            }
          }
        }}
        render={({ value, name, ref, onChange }, { invalid }) => (
          <>
            <FormInput
              id={name}
              name={name}
              value={value}
              type="password"
              label={`${t('renew.password.newPassword')}`}
              className="renewPassword__form__input"
              onChange={() => onChange(ref.current.value)}
              ref={ref}
            />
            <PasswordPolicies
              className="changePassword__policies"
              value={value}
              validation={invalid}
            />
          </>
        )}
      />
      <FormInput
        name="passwordConf"
        ref={register({
          validate: (value) => value === getValues().password
        })}
        label={t('renew.password.titleConfPassword')}
        placeholder={t('renew.password.newPasswordConfimration')}
        type="password"
        withEyes
        className="renewPassword__form__input"
        errorMessage={
          errors.passwordConf ? t('renew.password.notSamePassword') : ''
        }
      />
      <Button
        type="submit"
        className="renewPassword__form__button--submit"
        label={t('form.button.validate')}
      />
    </form>
  );
};

export default FormRenewPassword;
