import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import './style.scss';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import t from '@/lib/i18n';
import classnames from 'classnames';
import { Content as ContentfulContent } from '@/lib/contentful';
import { notification } from '@/lib/notification';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import { AuthStateType } from '@/store/auth/authReducer';
import shoppingListApi, { List } from '@/lib/api/shoppingList';
import LoadableContent from '@/components/atoms/loadableContent';
import { ReactComponent as ListError } from '@/assets/images/icons/list-error.svg';
import { ReactComponent as ListAdd } from '@/assets/images/icons/list-add.svg';
import CommonSprite from '@/assets/images/sprites/common.svg';
import MyAccount from '@/assets/images/sprites/myAccount.svg';
import CreateShoppingList from '@/components/organisms/myAccount/createShoppingList';
import Link from '@/components/atoms/link';
import Button from '@/components/atoms/button';
import Modal from '@/components/organisms/modal';
import useEvent from '@/lib/hooks/useEvent';
import usePdv from '@/lib/hooks/usePdv';
import { updateCartQuantity } from '@/store/cart/actions/cart';
import { StateType as CartStateType, SubCartType } from '@/store/cart/types';
import NotEcommerce from '../notEcommerce';
import ShoppingListTitle from './title';

export type MyShoppingListProps = {
  footer: ContentfulContent;
  pageView: string;
};

type StateType = {
  isLoading: boolean;
  lists: Array<List>;
  idsToDelete: Array<number>;
  addToCartLoading: Array<number>;
  deleteModalOpened: boolean;
  isEcommerce: boolean;
};

const MyShoppingList = ({ footer, pageView }: MyShoppingListProps) => {
  const { cartStore, userId } = useSelector(
    ({ cart, auth }: { cart: CartStateType; auth: AuthStateType }) => {
      return {
        cartStore: cart,
        userId: auth.user?.id
      };
    },
    shallowEqual
  );
  const dispatch = useDispatch();
  const event = useEvent();
  const router = useRouter();
  const [state, setState] = useState<StateType>({
    addToCartLoading: [],
    isLoading: true,
    lists: [],
    idsToDelete: [],
    deleteModalOpened: false,
    isEcommerce: false
  });

  const load = useCallback(async () => {
    if (!userId) {
      return;
    }

    const lists = await shoppingListApi.list(userId);

    setState((oldState) => ({
      ...oldState,
      isLoading: false,
      lists
    }));
  }, [userId]);

  useEffect(() => {
    const { isEcommerce } = usePdv();

    setState((oldState) => ({
      ...oldState,
      isEcommerce,
      isLoading: isEcommerce
    }));

    if (!isEcommerce) return;

    (async () => {
      await load();
    })();
  }, [userId, load]);

  const onDelete = async () => {
    if (!userId || !state.idsToDelete.length) {
      return;
    }

    setState((oldState) => ({
      ...oldState,
      isLoading: true
    }));

    const apiCalls: Array<Promise<void>> = [];
    event.send('account', {
      type: 'removeShoppingList',
      productsList: state.lists.map(({ id }) => id).join('::')
    });

    state.idsToDelete.forEach((id: number) => {
      apiCalls.push(shoppingListApi.delete(userId, id));
    });

    try {
      Promise.all(apiCalls);
    } catch (error: any) {
      // Todo: handle user error display unable to delete lists
    }

    setState((oldState) => {
      const newLists = oldState.lists.filter((list: List) => {
        return !state.idsToDelete.includes(list.id);
      });

      return {
        ...oldState,
        isLoading: false,
        deleteModalOpened: false,
        lists: newLists,
        idsToDelete: []
      };
    });
  };

  const addToCart = async (list: List) => {
    const { ref } = usePdv();

    if (!userId || !ref) {
      return;
    }

    setState((oldState) => ({
      ...oldState,
      addToCartLoading: [...oldState.addToCartLoading, list.id]
    }));

    const productList = await shoppingListApi.listProducts(
      userId,
      list.id,
      ref
    );

    const itmCart = cartStore.subCarts.find(
      (subCart: SubCartType) => subCart.seller.sellerId === 'ITM'
    );

    const items = productList.products
      .filter((product) => !!product.maxQty)
      .map((product) => {
        const itemInCart = itmCart?.items[product.id];
        return itemInCart?.qty
          ? { product, quantity: itemInCart.qty + 1, sellerId: 'ITM' }
          : { product, quantity: 1, sellerId: 'ITM' };
      });

    event.send('addAllToCart', {
      products: productList.products,
      path: router.asPath
    });

    if (items.length) {
      try {
        await dispatch(updateCartQuantity(items));
        items.length === productList.products.length
          ? notification.success(
              t('myAccount.lists.shoppingList.reorderList.allDispo')
            )
          : notification.success(
              t('myAccount.lists.shoppingList.reorderList.dispo', {
                '%total%': productList.products.length,
                '%added%': items.length,
                '%indispo%': productList.products.length - items.length
              })
            );
      } catch (error: any) {
        // Todo: handle user error display unable to add products to cart
      }
    } else {
      notification.success(
        t('myAccount.lists.shoppingList.reorderList.allIndispo', {
          '%indispo%': productList.products.length
        })
      );
    }

    setState((oldState) => ({
      ...oldState,
      addToCartLoading: oldState.addToCartLoading.filter((id) => id !== list.id)
    }));
  };

  const contentPart = (
    <LoadableContent
      className={classnames('myShoppingList', {
        'myShoppingList--empty': !state.lists.length
      })}
      loading={state.isLoading}
    >
      <>
        {!state.isEcommerce && <NotEcommerce />}
        {state.isEcommerce && !!state.lists.length && (
          <>
            <div className="myShoppingList__heading">
              <Button
                type="submit"
                className="myShoppingList__delete"
                color="secondary"
                disabled={!state.idsToDelete.length}
                onClick={() =>
                  setState((oldState) => ({
                    ...oldState,
                    deleteModalOpened: true
                  }))
                }
              >
                <svg
                  className="myShoppingList__trash fill--black"
                  width="20"
                  height="20"
                >
                  <use xlinkHref={`${CommonSprite}#trash`} />
                </svg>
              </Button>
            </div>
            <div className="myShoppingList__container">
              {state.lists.map((list: List) => {
                const key = `key-${list.id}`;

                return (
                  <div
                    className={classnames('myShoppingList__tile', {
                      'myShoppingList__tile--no-product': !list.count
                    })}
                    key={key}
                  >
                    <div className="myShoppingList__header">
                      <input
                        className="myShoppingList__checkbox"
                        name="ids"
                        value={list.id}
                        type="checkbox"
                        checked={state.idsToDelete.includes(list.id)}
                        onChange={(e) => {
                          const isChecked = e.target.checked;

                          setState((oldState) => {
                            let newIdsToDelete = oldState.idsToDelete;

                            if (isChecked) {
                              oldState.idsToDelete.push(list.id);
                            } else {
                              newIdsToDelete = oldState.idsToDelete.filter(
                                (id) => {
                                  return id !== list.id;
                                }
                              );
                            }

                            return {
                              ...oldState,
                              idsToDelete: newIdsToDelete
                            };
                          });
                        }}
                      />
                    </div>
                    {userId && (
                      <ShoppingListTitle list={list} userId={userId} />
                    )}

                    {!!list.count && (
                      <>
                        <div className="myShoppingList__label">
                          {t('myAccount.lists.shoppingList.nb-products')}
                        </div>
                        <div className="myShoppingList__count">
                          {list.count}
                        </div>
                      </>
                    )}
                    {!list.count && (
                      <>
                        <div className="myShoppingList__label">
                          {t('myAccount.lists.shoppingList.nb-products')}
                        </div>
                        <div className="myShoppingList__count">
                          {list.count}
                        </div>
                        <div className="myShoppingList__label">
                          {t('myAccount.lists.shoppingList.no-products')}
                        </div>
                      </>
                    )}
                    <div className="myShoppingList__footer">
                      <Link
                        className="myShoppingList__link"
                        href={
                          list.count
                            ? `/account-management/my-lists/my-shopping-lists/detail/${list.id}`
                            : '/home'
                        }
                      >
                        <>
                          <svg
                            className="myShoppingList__picto fill--red"
                            width="25"
                            height="25"
                          >
                            <use xlinkHref={`${MyAccount}#list-check`} />
                          </svg>
                          {t(
                            `myAccount.lists.shoppingList.button.${
                              list.count ? 'detail' : 'add-products'
                            }`
                          )}
                        </>
                      </Link>
                      <Button
                        className="myShoppingList__addToCart"
                        disabled={!list.count}
                        loading={state.addToCartLoading.includes(list.id)}
                        onClick={() => addToCart(list)}
                      >
                        <svg className="fill--white" width="40" height="40">
                          <use xlinkHref={`${CommonSprite}#add-to-cart`} />
                        </svg>
                      </Button>
                    </div>
                  </div>
                );
              })}
              <div className="myShoppingList__tile myShoppingList__add">
                <ListAdd
                  className="myShoppingList__add_picto"
                  width={140}
                  height={150}
                />
                <CreateShoppingList
                  className="myShoppingList__add_link"
                  type="link"
                  ctaLabel={t('myAccount.lists.shoppingList.create')}
                  onCreated={() => {
                    setState((oldState) => ({
                      ...oldState,
                      isLoading: true
                    }));

                    load();
                  }}
                />
              </div>
            </div>
            <Modal
              open={state.deleteModalOpened}
              className="myShoppingList__deleteModal"
              confirmBtn={{
                label: t('yes'),
                onClick: () => onDelete()
              }}
              cancelBtn={{
                label: t('no'),
                onClick: () =>
                  setState((oldState) => ({
                    ...oldState,
                    idsToDelete: [],
                    deleteModalOpened: false
                  }))
              }}
              onClose={() =>
                setState((oldState) => ({
                  ...oldState,
                  deleteModalOpened: false
                }))
              }
            >
              <h3>
                {t(
                  `myAccount.lists.shoppingList.deleteModal.${
                    state.idsToDelete.length === 1 ? 'single' : 'multiple'
                  }.title`
                )}
              </h3>
              <p>
                {t(
                  `myAccount.lists.shoppingList.deleteModal.${
                    state.idsToDelete.length === 1 ? 'single' : 'multiple'
                  }.content`
                )}
              </p>
            </Modal>
          </>
        )}
        {state.isEcommerce && !state.lists.length && (
          <div className="myShoppingList__empty_container">
            <ListError width={95} height={95} />
            <div className="myShoppingList__empty_title">
              {t('myAccount.lists.shoppingList.empty.title')}
            </div>
            <div className="myShoppingList__empty_message">
              {t('myAccount.lists.shoppingList.empty.message')}
            </div>
            <CreateShoppingList
              type="button"
              className="myShoppingList__empty_button"
              ctaLabel={t('myAccount.lists.shoppingList.empty.button')}
              onCreated={async () => {
                setState((oldState) => ({
                  ...oldState,
                  isLoading: true
                }));

                await load();
              }}
            />
          </div>
        )}
      </>
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      enabledId="lists"
      title={t('myAccount.menu.lists.shoppingList')}
      content={contentPart}
      pageView={pageView}
      footer={footer}
      backTo={{
        label: t('myAccount.menu.lists'),
        href: '/account-management/my-lists'
      }}
    />
  );
};

export default MyShoppingList;
