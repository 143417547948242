import React, { useEffect } from 'react';
import { withESI, ESIOptions } from '@/lib/esi';
import PdvModel from '@/lib/model/pdv';
import {
  Content as ContentfulContent,
  RendererComponent as ContentfulRenderComponent
} from '@/lib/contentful';
import { ContentfulStoreProps } from '@/lib/contentful/normalizers/magasin';
import usePageView from '@/lib/hooks/usePageView';
import Jumbotron from './jumbotron';
import OpenHoursAndMap from './openHoursAndMap';
import ProspectusInfo from './prospectusInfo';
import Services from './services';
import SlotsPdv from '../slotsPdvESI';

type StorePdvProps = {
  pdv: PdvModel;
  servicesList: ContentfulContent;
  contentfulStore: ContentfulStoreProps;
};

const StorePdv = ({ pdv, servicesList, contentfulStore }: StorePdvProps) => {
  const {
    name,
    address,
    phone,
    allServicesCodes,
    format,
    message,
    fax,
    exceptionDates,
    calendar
  } = pdv;

  const pageView = usePageView();

  useEffect(() => {
    pageView.send('pdv', { pdvRef: pdv.ref });
  }, [pageView, pdv.ref]);

  const {
    storeMagasin,
    storeProspectus,
    storeCurrently
  } = contentfulStore?.props;

  const SlotsPdvESI = withESI(SlotsPdv, {
    id: 'store-page-slots',
    type: 'templates/pdv',
    name: 'slotsPdv'
  });

  return (
    <>
      <Jumbotron
        storeName={name}
        format={format}
        storeMagasin={storeMagasin}
        message={message}
      />
      {address && (
        <OpenHoursAndMap
          name={name}
          address={address}
          exceptionDates={exceptionDates}
          phone={phone}
          fax={fax}
          calendar={calendar}
        />
      )}
      {storeProspectus && <ProspectusInfo storeProspectus={storeProspectus} />}
      {storeCurrently && (
        <ContentfulRenderComponent component={storeCurrently} />
      )}
      <SlotsPdvESI esi={ESIOptions()} pdvRef={pdv.ref} />
      {allServicesCodes && (
        <Services services={allServicesCodes} servicesList={servicesList} />
      )}
    </>
  );
};

export default React.memo(StorePdv);
