import React, { useEffect, useState } from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Layout from '@/components/organisms/layout';
import CartOrganism from '@/components/organisms/checkout/payment';
import { useSelector, shallowEqual } from 'react-redux';
import { StateType as CartStateType } from '@/store/cart/types';
import { AuthStateType } from '@/store/auth/authReducer';
import LoadableContent from '@/components/atoms/loadableContent';
import Header from '../header';

export type CartProps = {
  footer: ContentfulContent;
  pageView: string;
};

const PaymentTemplate = ({ footer, pageView }: CartProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { cartStore, userId, authStore } = useSelector(
    ({ cart, auth }: { cart: CartStateType; auth: AuthStateType }) => {
      return {
        cartStore: cart,
        userId: auth.user?.id,
        authStore: auth
      };
    },
    shallowEqual
  );

  useEffect(() => {
    setIsLoading(!(cartStore.isHydrated && authStore.isHydrated));
  }, [authStore.isHydrated, cartStore.isHydrated]);

  useEffect(() => {
    if (!userId && !isLoading) {
      window.location.href = '/connection?redirect=/orders/cart';
    }
  }, [userId, isLoading]);

  const headerPart = <Header cart={cartStore} step="payment" />;
  const content = (
    <div className="container">
      <LoadableContent loading={isLoading}>
        {userId && <CartOrganism userId={userId} cart={cartStore} />}
      </LoadableContent>
    </div>
  );

  return (
    <Layout
      header={headerPart}
      content={content}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
      pageView={pageView}
    />
  );
};

export default PaymentTemplate;
