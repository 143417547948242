import React, { useEffect, useState } from 'react';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { useDispatch, useSelector } from 'react-redux';
import CartSprite from '@/assets/images/sprites/cart.svg';
import t from '@/lib/i18n';
import Modal from '@/components/organisms/modal';

import './style.scss';

import { deleteAllCarts } from '@/store/cart/actions/cart';
import classnames from 'classnames';
import useEvent from '@/lib/hooks/useEvent';
import usePageView from '@/lib/hooks/usePageView';
import { CartItemType, StateType as CartStateType } from '@/store/cart/types';
import usePdv from '@/lib/hooks/usePdv';
import { useRouter } from 'next/router';

type DeleteAllCartsProps = {
  onValidate?: () => void;
  onCancel?: () => void;
  isCheckout?: boolean;
};

const DeleteAllCarts = ({
  onValidate = () => {},
  onCancel = () => {},
  isCheckout = false
}: DeleteAllCartsProps) => {
  const [modalOpened, setModalOpened] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const event = useEvent();
  const pageView = usePageView();
  // TODO : const cartStore = useShalloEqualSelector(useCart);
  const { cartStore } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      cartStore: cart
    };
  });

  useEffect(() => {
    if (modalOpened) {
      const { ref } = usePdv();
      pageView.send(isCheckout ? 'ClearingCart' : 'ClearingMiniCart', {
        pdvRef: ref
      });
    }
  }, [pageView, isCheckout, modalOpened]);

  const sendGTMDeleteEvent = () => {
    const products: CartItemType[] = [];
    cartStore.subCarts.forEach((sc) =>
      products.push(...Object.values(sc?.items || {})?.map((item) => item))
    );

    event.send('deleteCart', {
      type: 'all',
      isCheckout,
      route: router.asPath,
      products
    });
  };
  return (
    <div className="deleteAllCarts">
      <div
        className="deleteAllCarts__items"
        onClick={() => {
          setModalOpened(true);
        }}
      >
        {isCheckout && (
          <span className="deleteAllCarts__label">
            {t(
              cartStore.subCarts.length > 1 ? 'cart.deleteMany' : 'cart.delete'
            )}
          </span>
        )}
        <svg
          className={classnames('deleteAllCarts__delete', {
            'deleteAllCarts__delete--minicart': !isCheckout
          })}
          width="24px"
          height="24px"
        >
          <use xlinkHref={`${CommonSprite}#trash`} />
        </svg>
      </div>

      <Modal
        closable={true}
        onClose={() => {
          setModalOpened(false);
          onCancel();
        }}
        confirmBtn={{
          label: t('common.delete'),
          onClick: async () => {
            setModalOpened(false);
            onValidate();
            sendGTMDeleteEvent();
            await dispatch(deleteAllCarts());
          }
        }}
        cancelBtn={{
          label: t('common.cancel'),
          onClick: () => {
            setModalOpened(false);
            onCancel();
          }
        }}
        open={modalOpened}
      >
        <div className="deleteAllCarts__modal">
          <svg width="150" height="150">
            <use xlinkHref={`${CartSprite}#trash`} />
          </svg>
          <span className="deleteAllCarts__modal-title">
            {t('cart.deleteMany')}
          </span>
          <p>{t('cart.info.deleteAllCarts')}</p>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAllCarts;
