import React from 'react';
import Modal from '@/components/organisms/modal';
import t from '@/lib/i18n';

type ModalReinitProps = {
  validate: () => void;
  closeModal: () => void;
  open: boolean;
};

const ModalReinit = ({ validate, closeModal, open }: ModalReinitProps) => {
  return (
    <Modal
      open={open}
      closable={false}
      pageView="ModalReinitSes"
      className="reinitModal"
      scrollable={false}
      confirmBtn={{
        label: t('myAccount.smartConso.modalReinit.confirm'),
        onClick: validate,
        isLink: true
      }}
      cancelBtn={{
        label: t('myAccount.smartConso.modalReinit.cancel'),
        onClick: closeModal,
        isLink: true
      }}
    >
      <p className="reinitModal__content reinitModal__title">
        {t('myAccount.smartConso.modalReinit.p1')}
      </p>
      <p className="reinitModal__content">
        {t('myAccount.smartConso.modalReinit.p2')}
      </p>
    </Modal>
  );
};

export default ModalReinit;
