/* eslint  class-methods-use-this: off */
import {
  CartType,
  PaymentStateType,
  MkpSlotsType,
  CartItemType,
  RequestPackage
} from '@/store/cart/types';
import createProducts from '@/lib/utils/products';
import {
  DELIVERY_TYPE_MAPPING_INVERSE,
  NEED_BAG_ELIGIBLE
} from '@/lib/model/slot';
import Date from '@/lib/utils/date';
import dayjs, { Dayjs } from 'dayjs';
import { AuthUserStateType } from '@/store/auth/authReducer';
import { NormalizerInterface } from '..';

const parseItems = (items: { [key: string]: CartItemType }) => {
  const itemsParsed = Object.values(items)?.map((item: CartItemType) => ({
    id: item.product.id,
    itemParentId: item.product.itemParentId,
    item: {
      idProduit: item.product.ean,
      itemId: item.product.id
    },
    quantity: item.qty,
    catalog: item.product.type,
    amount: item.total,
    acceptSubstitution: item.acceptSubstitution,
    comment: item.comment
  }));
  return itemsParsed;
};

const formatDate = (
  date: string | Dayjs | undefined,
  startOrEnd: string | undefined
) => {
  if (!date) {
    return null;
  }
  const startHours = startOrEnd?.split(':')?.[0];
  const startMinutes = startOrEnd?.split(':')?.[1];
  return dayjs(date)
    .set('hour', Number(startHours))
    .set('minute', Number(startMinutes))
    .format('YYYY-MM-DDTHH:mm:ssZ');
};

const getItmDeliveryRequest = (
  cart: CartType,
  pdv: string,
  userInformations: AuthUserStateType,
  paymentState: PaymentStateType
) => {
  const pdvCart = cart.subCarts.find((subCart) => {
    return subCart.type === 'PDV';
  });
  const { mobilePhone } = userInformations;
  const deliveryTimeSlotStartDateTime = formatDate(
    cart.slots.selectedSlot.slot?.day,
    cart.slots.selectedSlot.slot?.startTime
  );
  const deliveryTimeSlotEndDateTime = formatDate(
    cart.slots.selectedSlot.slot?.day,
    cart.slots.selectedSlot.slot?.endTime
  );
  const smsInfos = {
    numeroTelephoneSms: cart.trackingByPhone ? mobilePhone : null,
    optinSMS: cart.trackingByPhone
  };

  return {
    catalog: 'PDV',
    userCreditNote: cart.selectedVoucher && cart.selectedVoucher > 0,
    depositDelivery: cart.consigneLivraison,
    acceptSubstitution:
      pdvCart &&
      createProducts(pdvCart).some((item) => item.accepteSubstitution),
    timeSlotId: cart.slots.selectedSlot.slot?.idSlot,
    smsInfos,
    loyaltyAmountToApply: cart.selectedFID,
    discountCode: paymentState.discountCode?.code || null,
    taxeIdentificationNumber: '',
    deliveryType:
      DELIVERY_TYPE_MAPPING_INVERSE[cart.slots.selectedSlot.deliveryType],
    orderType: 'COMMANDE',
    paymentType: paymentState.paymentType,
    commercialGameId: cart.gamesContest?.isAccepted
      ? cart.gamesContest.idGameContest
      : null,
    deliveryTimeSlotStartDateTime,
    deliveryTimeSlotEndDateTime,
    needBag:
      !NEED_BAG_ELIGIBLE.includes(
        DELIVERY_TYPE_MAPPING_INVERSE[cart.slots.selectedSlot.deliveryType]
      ) || !cart.slots.wantsBags,
    deliveryProviderId: cart.slots.selectedProvider?.id,
    homeDeliveryDetails: cart.slots.selectedProvider
      ? {
          deliveryProviderId: cart.slots.selectedProvider.id,
          deliveryProviderSlots: cart.slots.selectedProvider.privateData
        }
      : null
  };
};

const getMkpDeliveryRequest = (
  mkpPackages: { [key: string]: MkpSlotsType } | undefined,
  sellerId: string
) => {
  const packages = [] as RequestPackage[];
  if (mkpPackages?.[sellerId]?.packages) {
    Object.values(mkpPackages?.[sellerId]?.packages).forEach((colis) =>
      colis.items?.forEach((item) =>
        packages.push({
          id: item.product.id,
          catalog: item.product.type,
          quantity: item.qty,
          leadTimeToShip: colis?.selectedProvider?.leadTimeToShip ?? null,
          selectedShippingType: colis?.selectedProvider?.id ?? null
        })
      )
    );
  }

  return {
    catalog: 'MKP',
    packages
  };
};

class Normalizer implements NormalizerInterface {
  normalize(
    cart: CartType,
    pdv: string,
    userInformations: AuthUserStateType,
    paymentState: PaymentStateType
  ): any {
    const {
      firstName,
      lastName,
      mobilePhone: phoneNumber,
      email,
      civility
    } = userInformations;
    const customerInfos = {
      firstName,
      lastName,
      email,
      civility: civility === 'male' ? '1' : '2'
    };
    const {
      total: amount,
      qty: itemsNumber,
      synchronizedAt: synchronizeDateTime,
      mkpPackages
    } = cart;

    const carts = cart.subCarts.map((subCart) => ({
      delivery:
        subCart?.type === 'PDV'
          ? getItmDeliveryRequest(cart, pdv, userInformations, paymentState)
          : getMkpDeliveryRequest(mkpPackages, subCart?.seller.sellerId),
      items: parseItems(subCart?.items),
      catalog: subCart?.type,
      amount: subCart?.total,
      itemsNumber: subCart?.qty,
      seller: {
        id: subCart?.seller.sellerId,
        name: subCart?.seller.sellerName
      }
    }));
    const data = {
      customerDateTime: Date().format('YYYY-MM-DDTHH:mm:ssZ'),
      synchronizeDateTime,
      amount,
      itemsNumber,
      storeId: pdv,
      paymentType: paymentState.paymentType,
      customerInfos,
      carts,
      deliveryInstructions: {
        phoneNumber,
        comment: cart.consigneLivraison
      },
      hostPayment: window.location.host
    };
    return data;
  }
}

export default new Normalizer();
