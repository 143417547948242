import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import { AuthStateType } from '@/store/auth/authReducer';
import { Content as ContentfulContent } from '@/lib/contentful';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import LoadableContent from '@/components/atoms/loadableContent';
import userInformationApi from '@/lib/api/userInformation';
import { Informations as UserInfo } from '@/lib/api/userInformation/types';
import { useSelector } from 'react-redux';
import userPdvApi from '@/lib/api/userPdv';
import PdvModel from '@/lib/model/pdv';
import { notification } from '@/lib/notification';
import Store from './store';

export type MyStoresProps = {
  footer: ContentfulContent;
  pageView: string;
};

type StateType = {
  isLoading: boolean;
  pdvs: Array<PdvModel>;
  userInfo?: UserInfo | null;
};

const MyStores = ({ footer, pageView }: MyStoresProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );

  const [state, setState] = useState<StateType>({
    isLoading: true,
    pdvs: [],
    userInfo: null
  });

  const onDelete = async (pdvToDelete: PdvModel) => {
    setState((oldState) => {
      const newPdvs = oldState.pdvs.filter((pdv: PdvModel) => {
        return pdv.ref !== pdvToDelete.ref;
      });

      return {
        ...oldState,
        pdvs: newPdvs
      };
    });
  };

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }

      let data: [PdvModel[], UserInfo | null];

      try {
        data = await Promise.all([
          userPdvApi.getFavorites(userId),
          userInformationApi.getInformations(userId)
        ]);
      } catch (error: any) {
        data = [[], null];
        notification.error(t('notification.erreur'));
      }

      setState((oldState) => ({
        ...oldState,
        isLoading: false,
        pdvs: data[0] ?? [],
        userInfo: data[1] ?? null
      }));
    })();
  }, [userId]);

  const contentPart = (
    <LoadableContent className="myStores" loading={state.isLoading}>
      <>
        {state.pdvs.length > 0 && (
          <Store
            data={state.pdvs[0]}
            variation="primary"
            userInfo={state.userInfo}
          />
        )}

        {state.pdvs.length > 1 && (
          <>
            <h2 className="myStores__frequentsTitle">
              {t('myAccount.myStores.frequentStores')}
            </h2>
            <div className="myStores__frequentsPdvs">
              {state.pdvs.map((pdv: PdvModel, index) => {
                if (index === 0) {
                  return null;
                }

                const key = `pdv-${pdv.ref}`;

                return (
                  <Store
                    key={key}
                    data={pdv}
                    variation="secondary"
                    userInfo={state.userInfo}
                    onDelete={onDelete}
                  />
                );
              })}
            </div>
          </>
        )}
      </>
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.userInfo.myStores')}
      enabledId="userInfo"
      content={contentPart}
      footer={footer}
      backTo={{
        label: t('myAccount.menu.userInfo.long'),
        href: '/account-management/my-info'
      }}
      pageView={pageView}
    />
  );
};

export default MyStores;
