import t from '@/lib/i18n';
import getConfigValue from '@/lib/config';

const IS_SMART_CONSO_LOT2 = getConfigValue(
  'IS_SMART_CONSO_LOT2',
  false
).toBoolean();
/* const IS_MKP_ENABLED = getConfigValue('IS_MKP_ENABLED', false).toBoolean(); */
const IS_FIDELITY_ENABLED = getConfigValue(
  'IS_FIDELITY_ENABLED',
  false
).toBoolean();

const DATA = [
  {
    id: 'dashboard',
    label: t('myAccount.menu.dashboard'),
    link: '/account-management/dashboard',
    icon: 'home'
  },
  {
    id: 'orders',
    label: t('myAccount.menu.orders'),
    link: '/account-management/my-groceries',
    icon: 'cart'
  },
  IS_SMART_CONSO_LOT2 && {
    id: 'smartConso',
    label: t('myAccount.menu.smartConso'),
    link: '/account-management/smart-conso/',
    icon: 'like',
    isNew: true
  },
  IS_FIDELITY_ENABLED && {
    id: 'myFidelityProgram',
    label: t('myAccount.menu.fidelity.myFidelityProgram'),
    link: '/account-management/program-fidelity',
    icon: 'fildelity-program'
  },
  IS_FIDELITY_ENABLED && {
    label: t('myAccount.menu.fidelity.vouchers'),
    link: '/account-management/my-vouchers',
    id: 'vouchers',
    icon: 'fidelity-vouchers'
  },
  /* {
    id: 'fidelity',
    label: t('myAccount.menu.fidelity'),
    link: '/account-management/my-fidelity',
    icon: 'gift',
    children: IS_FIDELITY_ENABLED && [
      {
        label: t('myAccount.menu.fidelity.myFidelityProgram'),
        link: '/account-management/my-fidelity/program-fidelity',
        id: 'myFidelityProgram'
      },
      {
        label: t('myAccount.menu.fidelity.vouchers'),
        link: '/account-management/my-fidelity/my-vouchers',
        id: 'vouchers'
      }
       {
        label: t('myAccount.menu.fidelity.personalOffers'),
        link: '/fidelity-advantages/personal-offers',
        id: 'personalOffers'
      },
      {
        label: t('myAccount.menu.fidelity.myStickers'),
        link: '/account-management/my-fidelity/my-stickers',
        id: 'myStickers'
      } 
    ]
  }, */
  {
    id: 'lists',
    label: t('myAccount.menu.lists'),
    link: '/account-management/my-lists',
    icon: 'star',
    children: [
      {
        label: t('myAccount.menu.lists.shoppingList'),
        link: '/account-management/my-lists/my-shopping-lists',
        id: 'shoppingList'
      },
      {
        label: t('myAccount.menu.lists.frequentList'),
        link: '/account-management/my-lists/my-usual-products',
        id: 'frequentList'
      }
    ]
  },
  {
    id: 'userInfo',
    label: t('myAccount.menu.userInfo'),
    link: '/account-management/my-info',
    icon: 'user',
    children: [
      {
        label: t('myAccount.menu.userInfo.details'),
        link: '/account-management/my-info/my-details',
        id: 'details'
      },
      {
        label: t('myAccount.menu.userInfo.myStores'),
        link: '/account-management/my-info/my-stores',
        id: 'myStores'
      },
      {
        label: t('myAccount.menu.userInfo.myAddresses'),
        link: '/account-management/my-info/my-adresses',
        id: 'myAddresses'
      },
      {
        label: t('myAccount.menu.userInfo.myPersonalData'),
        link: '/account-management/my-info/my-personal-data',
        id: 'myPersonalData'
      }
      /*  {
        label: t('myAccount.menu.userInfo.myNewsletters'),
        link: '/account-management/my-info/my-newsletters',
        id: 'myNewsletters'
      } */
    ]
  },
  {
    label: t('myAccount.menu.contact'),
    link: '/account-management/contact',
    id: 'contact',
    icon: 'phone'
  }
  /*   IS_MKP_ENABLED && {
    id: 'wallet',
    label: t('myAccount.menu.wallet'),
    link: '/account-management/wallet',
    icon: 'wallet'
  } */
  /*   {
    id: 'contact',
    label: t('myAccount.menu.contact'),
    link: '/help-and-contact/vas',
    icon: 'phone'
  } */
];

export default DATA;
