import DiscountCodeModel from '@/lib/model/discountCode';
import { CartType, CartItemType, SubCartType } from '@/store/cart/types';
import { Dispatch } from 'redux';
import {
  updateCartQuantity,
  UpdateCartQtyElt
} from '@/store/cart/actions/cart';
import logger from '@/lib/logger/base';

const minimumCartValid = 1;

export const isValidCartAmount = (
  cart: CartType,
  discount: DiscountCodeModel | null
): boolean => getTotalCart(cart, discount) >= minimumCartValid;

export const getTotalCart = (
  cart: CartType,
  discount: DiscountCodeModel | null = null,
  deliveryCost = 0
): number =>
  cart.total -
  cart.selectedVoucher -
  cart.selectedFID +
  deliveryCost -
  DiscountCodeModel.getDiscount(discount, cart.total);

export const getItmTotalCart = (
  cart: CartType,
  discount: DiscountCodeModel | null = null,
  deliveryCost = 0
): number => {
  const itmCart = cart.subCarts.find((subCart) => subCart.type === 'PDV');
  if (itmCart) {
    return (
      itmCart.total -
      cart.selectedVoucher -
      cart.selectedFID +
      deliveryCost -
      DiscountCodeModel.getDiscount(discount, cart.total)
    );
  }
  return 0;
};

export const isValidFid = (
  cart: CartType,
  discount: DiscountCodeModel | null,
  newFidAmount: number
): boolean => {
  const itmCartTotal = cart.subCarts.find((subCart) => subCart.type === 'PDV')
    ?.total;
  if (itmCartTotal) {
    return (
      itmCartTotal -
        cart.selectedVoucher -
        DiscountCodeModel.getDiscount(discount, itmCartTotal) -
        newFidAmount >=
      minimumCartValid
    );
  }
  return false;
};

export const isValidVoucher = (
  cart: CartType,
  discount: DiscountCodeModel | null,
  newVoucherAmount: number
): boolean => {
  const itmCartTotal = cart.subCarts.find((subCart) => subCart.type === 'PDV')
    ?.total;
  if (itmCartTotal) {
    return (
      itmCartTotal -
        cart.selectedFID -
        DiscountCodeModel.getDiscount(discount, itmCartTotal) -
        newVoucherAmount >=
      minimumCartValid
    );
  }
  return false;
};

export const isValidDiscount = (
  cart: CartType,
  newDiscount: DiscountCodeModel | null
): boolean => {
  const itmCartTotal = cart.subCarts.find((subCart) => subCart.type === 'PDV')
    ?.total;
  if (itmCartTotal) {
    return (
      itmCartTotal -
        cart.selectedVoucher -
        cart.selectedFID -
        DiscountCodeModel.getDiscount(newDiscount, itmCartTotal) >=
      minimumCartValid
    );
  }
  return false;
};

export const getItmDeliveryCost = (
  cart: CartType,
  withExtraCost = true
): number => {
  if (withExtraCost) {
    if (cart.slots.selectedProvider?.deliveryCost?.totalCost) {
      return cart.slots.selectedSlot?.slot?.freeFees
        ? cart.slots.selectedProvider.deliveryCost?.volumeExtraTax +
            cart.slots.selectedProvider.deliveryCost?.housingExtraTax
        : cart.slots.selectedProvider?.deliveryCost?.totalCost;
    }
    return (
      (cart.slots.selectedSlot.housingCosts || 0) +
      (cart.slots.selectedSlot.volumeCosts || 0) +
      (cart.slots.selectedSlot?.slot?.freeFees
        ? 0
        : cart.slots.selectedSlot.slot?.fees || 0)
    );
  }
  if (cart.slots.selectedProvider?.deliveryCost?.totalCost) {
    return cart.slots.selectedProvider?.deliveryCost?.cost;
  }
  return cart.slots.selectedSlot?.slot?.freeFees
    ? 0
    : cart.slots.selectedSlot.slot?.fees || 0;
};

export const getMkpDeliveryCost = (cart: CartType): number => {
  let deliveryCostMkp = 0;
  Object.keys(cart.mkpPackages || {}).forEach((sellerId) => {
    Object.keys(cart.mkpPackages?.[sellerId].packages || {}).forEach(
      (packageId) => {
        deliveryCostMkp +=
          cart.mkpPackages?.[sellerId].packages[packageId].selectedProvider
            ?.deliveryCost ?? 0;
      }
    );
  });
  return deliveryCostMkp;
};

export const getTransportDeliveryCost = (
  cart: CartType,
  withExtraCost = true
): number => {
  const deliveryCostItm = getItmDeliveryCost(cart, withExtraCost);
  const deliveryCostMkp = getMkpDeliveryCost(cart);
  return deliveryCostItm + deliveryCostMkp;
};

export const handle409UpdateCart = async ({
  productsToUpdate,
  dispatch
}: {
  productsToUpdate: Array<CartItemType> | null;
  dispatch: Dispatch;
}) => {
  const itemsToUpdate: Array<UpdateCartQtyElt> =
    productsToUpdate?.map(
      (product: CartItemType): UpdateCartQtyElt => {
        return {
          product: product.product,
          quantity: product.qty,
          sellerId: product.sellerId
        };
      }
    ) || [];

  try {
    await dispatch(updateCartQuantity(itemsToUpdate, true) as any);
  } catch (error: any) {
    window.location.href = '/orders/cart';
  }
};

export const updateInvalidPartialStocks = async ({
  subCarts,
  dispatch
}: {
  subCarts: SubCartType[];
  dispatch: Dispatch;
}) => {
  let items: Array<UpdateCartQtyElt> = [];

  try {
    subCarts.forEach((sub) => {
      items = [
        ...items,
        ...Object.keys(sub.items)
          .filter(
            (id) =>
              sub.meta?.invalidItems?.partialStocks?.includes(id) &&
              sub.items[id]?.product?.maxQty < sub.items[id].qty
          )
          .map((id) => {
            return {
              product: sub.items[id]?.product,
              quantity: sub.items[id]?.product?.maxQty,
              sellerId: sub.seller?.sellerId || 'ITM'
            };
          })
      ];
    });

    if (items.length) {
      await dispatch(updateCartQuantity(items, true) as any);
    }
  } catch (error: any) {
    logger.error({
      message: 'Unable to update partialStocks',
      error
    });
  }
};
