import React, { useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import { Informations } from '@/lib/api/userInformation/types';
import fidelityInfoApi from '@/lib/api/fidelity';
import { FidelityInfo } from '@/lib/api/fidelity/types';
import LoadableContent from '@/components/atoms/loadableContent';
import MyAdvantages from '../../fidelity/myAdvantages';

type FidelityProps = {
  userInfo?: Informations;
};

type StateType = {
  fidelityInfo?: FidelityInfo;
  isLoading: boolean;
};

const Fidelity = ({ userInfo }: FidelityProps) => {
  const [state, setState] = useState<StateType>({
    fidelityInfo: undefined,
    isLoading: true
  });

  useEffect(() => {
    (async () => {
      if (!userInfo) {
        return;
      }

      if (userInfo.fidelityCardNumber) {
        try {
          const fidelityInfo = await fidelityInfoApi.getInfo({
            lastName: userInfo.lastName,
            firstName: userInfo.firstName,
            birthdayDate: userInfo.birthdayDate,
            fidelityCardNumber: userInfo.fidelityCardNumber
          });
          setState({
            fidelityInfo,
            isLoading: false
          });
        } catch (error: any) {
          // Todo: handle user error display if unable to fetch user fidelity info
        }
      }

      setState((oldState) => {
        return {
          ...oldState,
          isLoading: false
        };
      });
    })();
  }, [userInfo]);

  if (!state.fidelityInfo)
    return (
      <LoadableContent
        loading={state.isLoading}
        className={classnames(
          'myAccountDashboard__container',
          'myAccountDashboard__fidelity',
          {
            'myAccountDashboard__fidelity--empty': !state.fidelityInfo,
            'myAccountDashboard__fidelity--fidEnabled': true
          }
        )}
      >
        <MyAdvantages fidelityInfo={state.fidelityInfo} />
      </LoadableContent>
    );

  return null;
};

export default Fidelity;
