import Product from '@/lib/model/product';
import { Dispatch } from 'redux';
import { SET_LAST_EAN_SKU, SET_SELECTED_PRODUCT } from '../commonActions';

export const setLastEanOrSku = (lastEanOrSku: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_LAST_EAN_SKU,
      payload: {
        lastEanOrSku
      }
    });
  };
};

export const clearLastEanOrSku = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_LAST_EAN_SKU,
      payload: {
        lastEanOrSku: null
      }
    });
  };
};

export const setSelectedProduct = (product?: Product) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_SELECTED_PRODUCT,
      payload: {
        product
      }
    });
  };
};
