import React from 'react';
import Header from '@/components/organisms/header';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Layout from '@/components/organisms/layout';
import Head from 'next/head';
import getConfigValue from '@/lib/config';

export type GameContestProps = {
  footer: ContentfulContent;
};

const GameContest = ({ footer }: GameContestProps) => {
  const headerPart = <Header />;
  const contentPart = <div id="luckypayment" />;
  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  const luckyCartGameScriptUrl = getConfigValue(
    'LUCKYCART_GAME_SCRIPT_URL',
    ''
  ).toString();

  return (
    <>
      <Head>
        {luckyCartGameScriptUrl && (
          <script type="text/javascript" src={luckyCartGameScriptUrl} defer />
        )}
      </Head>
      <Layout header={headerPart} content={contentPart} footer={footerPart} />
    </>
  );
};

export default GameContest;
