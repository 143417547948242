import CgvFaqSprite from '@/assets/images/sprites/sav.svg';
import Link from '@/components/atoms/link';
import Paragraph from '@/components/atoms/paragraph';
import Title from '@/components/atoms/title';
import usePageView from '@/lib/hooks/usePageView';
import t from '@/lib/i18n';
import React, { useEffect } from 'react';
import './style.scss';

const SavPopinBlockPhone = () => {
  const pageView = usePageView();

  useEffect(() => {
    pageView.send('contactPhone');
  }, [pageView]);

  return (
    <>
      <div className="SavPopinBlockPhone">
        <svg width="120" height="120">
          <use xlinkHref={`${CgvFaqSprite}#telephone`} />
        </svg>
        <Title
          tag="h5"
          label={t('sav.phone.title')}
          className="SavPopinBlockPhone__title"
        />
        <svg width="100%" height="50">
          <use xlinkHref={`${CgvFaqSprite}#aideTelephone`} />
        </svg>
        <Paragraph
          className="SavPopinBlockPhone__text"
          label={t('sav.phone.openingDate')}
        />
      </div>
      <div className="SavPopinShareScreen">
        <p>{t('sav.phone.shareScreen.description')}</p>
        <Link
          onClick={(e) => {
            // @ts-ignore : Add by screenmeet script
            window.Cobrowse?.modal?.openModal();
            e.preventDefault();
          }}
        >
          <>
            <svg width="24" height="21">
              <use xlinkHref={`${CgvFaqSprite}#screen-share`} />
            </svg>
            <span className="SavPopinShareScreen__title">
              {t('sav.phone.shareScreen.title')}
            </span>
          </>
        </Link>
      </div>
    </>
  );
};

export default SavPopinBlockPhone;
