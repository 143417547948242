import React from 'react';
import t from '@/lib/i18n';
import { Content as ContentfulContent } from '@/lib/contentful';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import MyAccountTiles from '@/components/organisms/myAccount/tiles';
import MyAccountTile from '@/components/organisms/myAccount/tile';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';

export type ListsProps = {
  footer: ContentfulContent;
  pageView: string;
};

const Lists = ({ footer, pageView }: ListsProps) => {
  const contentPart = (
    <MyAccountTiles>
      <MyAccountTile
        icon={
          <svg width="37" height="36">
            <use xlinkHref={`${MyAccountSprite}#shoppingList`} />
          </svg>
        }
        title={t('myAccount.lists.tiles.shoppingList.title')}
        link={{
          label: t('myAccount.lists.tiles.shoppingList.link'),
          href: '/account-management/my-lists/my-shopping-lists'
        }}
      />
      <MyAccountTile
        icon={
          <svg width="35" height="34">
            <use xlinkHref={`${MyAccountSprite}#frequentList`} />
          </svg>
        }
        title={t('myAccount.lists.tiles.frequentList.title')}
        link={{
          label: t('myAccount.lists.tiles.frequentList.link'),
          href: '/account-management/my-lists/my-usual-products'
        }}
      />
    </MyAccountTiles>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.lists')}
      enabledId="lists"
      content={contentPart}
      pageView={pageView}
      footer={footer}
    />
  );
};

export default Lists;
