import React, { memo } from 'react';
import { Seller } from '@/lib/model/productTypes';
import classnames from 'classnames';

import t from '@/lib/i18n';
import Link from '@/components/atoms/link';
import './style.scss';
import useEvent from '@/lib/hooks/useEvent';

type SoldByNameProps = {
  seller: Seller;
  enableClick?: boolean;
  className?: string;
  centered?: boolean;
  size?: 'small' | 'large' | 'big';
};

const SoldByName = ({
  seller,
  enableClick,
  className,
  centered,
  size = 'small'
}: SoldByNameProps) => {
  const event = useEvent();

  if (!seller) {
    return null;
  }

  return (
    <div
      className={classnames(className, 'soldByName', `soldByName--${size}`, {
        'soldByName--centered': !!centered
      })}
    >
      <span className="soldByName__by">{`${t('common.sellBy')} `}</span>
      {enableClick && (
        <Link
          href={seller.url}
          label={seller.name}
          className="soldByName__name"
          target="_blank"
          onClick={() => event.send('sellerClick', { sellerName: seller.name })}
        />
      )}
      {!enableClick && (
        <span className="soldByName__name">{`${seller.name}`}</span>
      )}
    </div>
  );
};

export default memo(SoldByName);
