import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';

import Modal from '@/components/organisms/modal';
import t from '@/lib/i18n';
import { AuthStateType } from '@/store/auth/authReducer';
import {
  selectHasFilledMandatoriesQuestions,
  selectHasCompletedSurvey
} from '@/store/survey/surveySelectors';
import getConfigValue from '@/lib/config';

import './style.scss';
import { ScoreInfo } from './scoreInfo';

const IS_SMART_CONSO_LOT2 = getConfigValue(
  'IS_SMART_CONSO_LOT2',
  false
).toBoolean();

const QuestionMark = () => {
  const [isOpen, setIsOpen] = useState(false);
  const hasFilledMandatoriesQuestions = useSelector(
    selectHasFilledMandatoriesQuestions
  );
  const hasCompletedSurvey = useSelector(selectHasCompletedSurvey);

  // TODO :   const userId = useShallowEqualSelector(selectUserId);
  const { userId } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      userId: auth.user?.id
    };
  });
  const isCustomScore =
    hasFilledMandatoriesQuestions && !!userId && IS_SMART_CONSO_LOT2;

  return (
    <div className="scoreBarContainer">
      <div className="scoreBarInfoBtn" onClick={() => setIsOpen(!isOpen)}>
        ?
      </div>
      <Modal
        closable={true}
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        classNameContainer="scoreBarContainer__modalContainer"
        pageView="ScoreInit"
      >
        <div className="scoreBarModal">
          <h3 className="scoreBarModal__modalTitle">
            {isCustomScore
              ? t('pdp.score.modal.title.custom')
              : t('pdp.score.modal.title')}
          </h3>
          <h4 className="scoreBarModal__title">{t('pdp.score.modal.t1')}</h4>
          <div className="scoreBarModal__content">
            {isCustomScore
              ? t('pdp.score.modal.p1.custom')
              : t('pdp.score.modal.p1')}
          </div>
          <h4 className="scoreBarModal__title">{t('pdp.score.modal.t2')}</h4>
          <div className="scoreBarModal__content">
            {isCustomScore
              ? t('pdp.scrore.modal.p2.custom')
              : t('pdp.score.modal.p2')}
          </div>
          <div>
            <ScoreInfo displayBlockAvoid={isCustomScore} />
          </div>
          <h4 className="scoreBarModal__title">{t('pdp.score.modal.t3')}</h4>
          <div className="scoreBarModal__content">
            {isCustomScore
              ? t('pdp.score.modal.p3.custom')
              : t('pdp.score.modal.p1')}
          </div>
          {isCustomScore && (
            <>
              <div className="scoreBarModal__content scoreBarModal__content-p4">
                {t('pdp.score.modal.p4.custom')}
              </div>
              <Link href="/account-management/smart-conso">
                <div className="scoreBarModal__content scoreBarModal__content-link">
                  {hasCompletedSurvey
                    ? t('pdp.score.modal.changPref')
                    : t('pdp.alternativeProducts.btn.formStarted')}
                </div>
              </Link>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default QuestionMark;
