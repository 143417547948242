import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): Component | null => {
  return {
    type: 'templates/avantageFidelite',
    name: 'ctaFid',
    props: {
      textNoCard: data.textNoCard || ''
    }
  };
};

export default normalize;
