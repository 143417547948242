import ProductList from '@/components/organisms/productList';
import WithoutResults, {
  SuggestionsProps
} from '@/components/templates/search/withoutResults';
import SearchWithoutResultsPageViewSender from '@/components/templates/search/withoutResults/SearchWithoutResultsPageViewSender';
import WithResults from '@/components/templates/search/withResults';
import CmsApi from '@/lib/api/cms';
import usePdv from '@/lib/hooks/usePdv';
import { Request, Response } from 'express';

import ProductListModel, {
  Sort,
  SORT_RELEVANCE,
  FiltersType
} from '@/lib/model/productList';
import { store } from '@/store';
import React from 'react';

import { Provider } from 'react-redux';

import fetchData from './fetchData';

const PER_PAGE = 40;
const PER_PAGE_PROMO = 100;

type InitialProps = {
  props: ProductListESIProps;
  req: Request;
  res: Response;
};

type ProductListESIProps = {
  type: 'category' | 'search' | 'shop';
  baseUrl: string;
  query: { [key: string]: any };
  list?: ProductListModel | null;
  filters?: FiltersType;
  sort?: Sort;
  categoryId?: string;
  keyword?: string;
  shopId?: string;
  suggestions?: SuggestionsProps;
};

const ProductListESI = ({
  type,
  baseUrl,
  query,
  list,
  categoryId,
  keyword,
  shopId,
  sort,
  filters,
  suggestions
}: ProductListESIProps) => {
  let productList = list;

  if (!productList) {
    productList = new ProductListModel([]);
  }

  const fetch = async (
    p: number,
    f: FiltersType,
    s: Sort
  ): Promise<ProductListModel> => {
    const { ref } = usePdv();

    if (!ref) {
      return new ProductListModel([]);
    }

    return fetchData(ref, type, p, f, s, categoryId, keyword, shopId);
  };

  const filtersProps = filters ?? [];
  const sortProps = sort ?? {
    type: SORT_RELEVANCE,
    direction: undefined
  };
  const perPage =
    shopId === 'PROMOTIONS' || shopId === '2119' ? PER_PAGE_PROMO : PER_PAGE;

  const renderProductList = (
    <Provider store={store}>
      <ProductList
        list={productList}
        baseUrl={baseUrl}
        filters={filtersProps}
        sort={sortProps}
        fetch={fetch}
        perPage={perPage}
        type={type}
        categoryId={categoryId}
        keyword={keyword}
      />
    </Provider>
  );

  if (type === 'search' && keyword) {
    return suggestions ? (
      <>
        <Provider store={store}>
          <SearchWithoutResultsPageViewSender
            type={type}
            keyword={keyword}
            suggestions={suggestions}
            baseUrl={baseUrl}
          />
        </Provider>
        <WithoutResults keyword={keyword} suggestions={suggestions} />
      </>
    ) : (
      <WithResults keyword={keyword}>{renderProductList}</WithResults>
    );
  }

  if (!productList.products.length) {
    return <WithoutResults keyword={keyword || ''} suggestions={suggestions} />;
  }

  return renderProductList;
};

ProductListESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref } = usePdv(req);

  let list: ProductListModel | null = null;
  let suggestions: SuggestionsProps | null = null;
  let page = 1;
  let sort: Sort;
  const filters: FiltersType = [];

  if (ref) {
    const query = { ...props.query };

    page = query.page ?? 1;
    sort = {
      type: query.trier ?? 'pertinence',
      direction: query.ordre ?? null
    };

    delete query['page'];
    delete query['trier'];
    delete query['ordre'];

    Object.keys(query).forEach((type: string) => {
      const data: string | Array<string> = query[type];

      if (Array.isArray(data)) {
        filters.push({
          type,
          values: data
        });
      } else {
        filters.push({
          type,
          values: [data]
        });
      }
    });

    list = await fetchData(
      ref,
      props.type,
      page,
      filters,
      sort,
      props.categoryId,
      props.keyword,
      props.shopId
    );

    if (list.products.length === 0 && props.type === 'search') {
      suggestions = await CmsApi.getTemplatesNoResults();
    }
  }

  return new Promise((resolve) => {
    if (res) {
      if (list?.meta.total) {
        res.set('Cache-Control', 's-maxage=900, max-age=900');
      } else {
        res.set('Cache-Control', 's-maxage=60, max-age=60');
      }
    }

    resolve({
      ...props,
      list,
      suggestions,
      sort,
      filters
    });
  });
};

export default ProductListESI;
