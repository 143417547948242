import React from 'react';
import { RendererComponent, Component } from '@/lib/contentful';
import Link from '@/components/atoms/link';
import ContentSection from '../contentSection';

export type ContentImageProps = {
  text: string;
  title: string;
  href: string | null;
  image: Component | null;
  cta: Component | null;
};

const ContentImage = ({ text, title, image, cta, href }: ContentImageProps) => {
  return (
    <div>
      {href && image && (
        <Link href={href}>
          <RendererComponent component={image} />
        </Link>
      )}
      {!href && image && <RendererComponent component={image} />}
      <ContentSection title={title} content={text} />
      {cta && <RendererComponent component={cta} />}
    </div>
  );
};

export default ContentImage;
