import { CatalinaOfferProps } from '@/components/templates/avantageFidelite/catalinaOffer';
import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): ComponentProps => {
  return {
    type: 'templates/avantageFidelite',
    name: 'catalinaOffer',
    props: {
      height: data.height,
      width: data.width
    }
  };
};

export default normalize;

export interface ComponentProps extends Component {
  props: CatalinaOfferProps;
}
