import { FooterPrimaryProps } from '@/components/organisms/footerPrimary';
import { normalize as BaseNormalize, NormalizeParams, Component } from '..';

type FooterElements =
  | 'socialNetworks'
  | 'logos'
  | 'otherSites'
  | 'pdvNav'
  | 'nav';

const normalize = ({ data, includes }: NormalizeParams) => {
  const props: FooterPrimaryProps = {
    socialNetworks: [],
    otherSites: [],
    logos: [],
    apps: [],
    pdvNav: [],
    nav: []
  };

  const footerKeys = ['socialNetworks', 'logos', 'otherSites', 'pdvNav', 'nav'];

  footerKeys.forEach((footerKey: string) => {
    const key = footerKey as FooterElements;

    if (data[key]) {
      data[key].forEach((item: any) => {
        const footerItemData = includes[item.sys.id] || null;

        if (footerItemData) {
          const elt = BaseNormalize({
            data: footerItemData,
            includes
          }) as Component | null;

          if (elt) {
            elt.props.width = '155px';

            props[key].push(elt);
          }
        }
      });
    }
  });

  if (data.footerApps) {
    data.footerApps.forEach((app: any) => {
      const appData = includes[app.sys.id] || null;
      const image = includes[appData.fields.image.sys.id] ?? null;

      if (image) {
        const file = includes[image.fields.image.sys.id] ?? null;

        props.apps.push({
          title: appData.fields.title || null,
          subTitle: appData.fields.titleSecondary || null,
          image: file.fields.file.url,
          href: appData.fields.hrefImage || null
        });
      }
    });
  }

  return {
    type: 'organisms',
    name: 'footerPrimary',
    props
  };
};

export default normalize;
