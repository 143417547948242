import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import { OrderItem as OrderItemType } from '@/lib/api/order/types';
import Link from '@/components/atoms/link';
import Image from '@/components/atoms/image';
import Promotion from '@/components/atoms/promotion';
import Highlight from '@/components/atoms/highlight';
import classNames from 'classnames';
import { PromotionType } from '@/lib/model/productTypes';
import QuantityDiscount from '@/components/atoms/quantityDiscount';
import ProductModel from '@/lib/model/product';

type OrderItemProps = {
  data: OrderItemType;
};

const displayPromo = (
  promotions: Array<PromotionType>,
  product: ProductModel
) => {
  const quantityDiscountPromo = promotions.find(
    (p) => p.type === 'quantityDiscount'
  );
  const collectorPartner = promotions.find(
    (p) => p.type === 'collectorPartner'
  );
  if (quantityDiscountPromo) {
    return (
      <>
        <QuantityDiscount
          id={product.id}
          qty={0}
          promotion={quantityDiscountPromo}
        />
        {collectorPartner && (
          <Promotion key={collectorPartner.type} data={collectorPartner} />
        )}
      </>
    );
  }
  const promo = promotions.find((p) => {
    return (
      ['offeredDiscount', 'immediateDiscount', 'deferredDiscount'].includes(
        p.type
      ) || p.flashSale
    );
  });
  if (promo) {
    return (
      <>
        <Promotion key={promo.type} data={promo} />
        {collectorPartner && (
          <Promotion key={collectorPartner.type} data={collectorPartner} />
        )}
      </>
    );
  }
  return collectorPartner ? (
    <Promotion key={collectorPartner.type} data={collectorPartner} />
  ) : null;
};

const OrderItem = ({ data }: OrderItemProps) => {
  return (
    <div className="orderItem">
      <Link href={data.product.url} className="orderItem__info">
        <>
          <div className="orderItem__media">
            {data.image && data.image.src && (
              <>
                <Image
                  className="orderItem__image"
                  {...data.image}
                  width={65}
                  height={65}
                />
                {!!data.product.informations.flags.length && (
                  <div className="product__flags__container">
                    {data.product.informations.flags.slice(0, 3).map((flag) => {
                      return (
                        <Image
                          key={flag}
                          className="product__flags__flag"
                          alt="flag"
                          width={16}
                          height={16}
                          src={flag}
                        />
                      );
                    })}
                  </div>
                )}
              </>
            )}

            {!data.image && <div className="orderItem__media__placeholder" />}
          </div>
          <div className="orderItem__texts">
            <h1 className="orderItem__title">
              {data.brand && (
                <span className="orderItem__brand">{data.brand} - </span>
              )}
              {data.title}
            </h1>
            {data.allowSubstition && (
              <div className="orderItem__comment">
                {t('myAccount.orders.replacementAllowed')}
              </div>
            )}
          </div>
        </>
      </Link>
      <footer className="orderItem__footer">
        <div className="orderItem__footer__pricesWrapper">
          <>
            {!!data.product.promotions.length && (
              <div className="product__promotions">
                {displayPromo(data.product.promotions, data.product)}
              </div>
            )}
            {!data.product.promotions.length &&
              data.product.informations.highlight && (
                <Highlight
                  endDate={data.product.informations.highlight.endDate}
                  id={`highlight-${data.product.id}`}
                />
              )}
          </>
          {data.prices && (
            <>
              <div className="orderItem__prices">
                {data.prices.productPrice && (
                  <span
                    className={classNames('orderItem__productPrice', {
                      hasPromo:
                        data.product.promotions.length ||
                        data.product.informations.highlight
                    })}
                  >
                    {data.prices.productPrice.integer},
                    {data.prices.productPrice.decimal}{' '}
                    {data.prices.productPrice.currency}
                  </span>
                )}
                {data.prices.unitPrice && (
                  <>
                    {data.prices.unitPrice.integer},
                    {data.prices.unitPrice.decimal}
                    {data.prices.unitPrice.currency}
                  </>
                )}
              </div>
              <div className="orderItem__quantity">{data.quantity}</div>
              <span className="orderItem__totalPrice">
                {t('product.total', {
                  '%price%': String(data.total.toFixed(2)).replace('.', ',')
                })}
              </span>
            </>
          )}
        </div>
      </footer>
    </div>
  );
};

export default OrderItem;
