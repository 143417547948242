import React from 'react';
import { Request, Response } from 'express';
import PdvApi from '@/lib/api/pdv';
import PdvModel from '@/lib/model/pdv';
import usePdv from '@/lib/hooks/usePdv';
import Heading, { HeadingProps } from '../heading';

type InitialProps = {
  props: HeadingProps;
  req: Request;
  res: Response;
};

const HeadingESI = (props: HeadingProps) => {
  return <Heading {...props} />;
};

HeadingESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref } = usePdv(req);

  let pdv: PdvModel | null = null;

  if (ref) {
    pdv = await PdvApi.getPdvInfo(ref);
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=900, max-age=900');
    }

    let pdvProps = null;

    if (pdv) {
      pdvProps = {
        name: pdv.name,
        isOpen: pdv.isOpen(),
        nextOpeningDate: pdv.getNextOpeningDate()?.toString(),
        nextClosingDate: pdv.getNextClosingDate()?.toString()
      };
    }

    resolve({
      ...props,
      pdv: pdvProps
    });
  });
};

export default HeadingESI;
