import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import classnames from 'classnames';
import { Content as ContentfulContent } from '@/lib/contentful';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import { ReactComponent as MoreIcon } from '@/assets/images/icons/more.svg';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import { FidelityInfo } from '@/lib/api/fidelity/types';
import { Informations } from '@/lib/api/userInformation/types';
import { AuthStateType } from '@/store/auth/authReducer';
import { useSelector } from 'react-redux';
import userInformationApi from '@/lib/api/userInformation';
import fidelityInfoApi from '@/lib/api/fidelity/index';
import Pdv from '@/lib/model/pdv';
import LoadableContent from '@/components/atoms/loadableContent';
import useEvent from '@/lib/hooks/useEvent';
import usePageView from '@/lib/hooks/usePageView';
import Link from '@/components/atoms/link';
import Dropdown from '@/components/atoms/dropdown';
import Modal from '@/components/organisms/modal';
import FidelityDetailedBalance from '@/components/molecules/FidelityDetailedBalance';
import Store from '../userInfo/myStores/store';

export type MyFidelityProgramProps = {
  footer: ContentfulContent;
};

type StateType = {
  userInfo?: Informations;
  fidelityInfo?: FidelityInfo;
  favoritePdv?: Pdv | null;
  isLoading: boolean;
  card: {
    removeModal: boolean;
    removing: boolean;
    removedModal: boolean;
    removed: boolean;
  };
};

const MyFidelityProgram = ({ footer }: MyFidelityProgramProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const event = useEvent();
  const pageview = usePageView();

  const [state, setState] = useState<StateType>({
    userInfo: undefined,
    fidelityInfo: undefined,
    favoritePdv: undefined,
    isLoading: true,
    card: {
      removeModal: false,
      removing: false,
      removedModal: false,
      removed: false
    }
  });

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }

      const userInfo = await userInformationApi.getInformations(userId);

      setState((oldState) => {
        return {
          ...oldState,
          userInfo
        };
      });
    })();
  }, [userId]);

  useEffect(() => {
    pageview.send('programFidelity');
  }, [pageview]);

  useEffect(() => {
    (async () => {
      if (!state.userInfo) {
        return;
      }

      if (!state.userInfo.fidelityCardNumber) {
        window.location.href = '/fidelity-advantages';

        return;
      }

      try {
        const data = await Promise.all([
          fidelityInfoApi.getInfo({
            lastName: state.userInfo.lastName,
            firstName: state.userInfo.firstName,
            birthdayDate: state.userInfo.birthdayDate,
            fidelityCardNumber: state.userInfo.fidelityCardNumber
          })
        ]);
        setState((oldState) => {
          return {
            ...oldState,
            isLoading: false,
            fidelityInfo: data[0]
            // favoritePdv: data[1]
          };
        });
      } catch (error: any) {
        setState((oldState) => {
          return {
            ...oldState,
            isLoading: false
          };
        });
        if (
          error?.response?.status === 400 &&
          error?.response?.data?.code === '3'
        ) {
          window.location.href = '/fidelity-advantages';
        }
      }
    })();
  }, [state.userInfo]);

  const removeCard = async () => {
    if (!userId) return;

    try {
      await userInformationApi.removeFidelityCard(userId);

      event.send('api', {
        name: 'removeFid',
        type: 'success'
      });

      setState((oldState) => ({
        ...oldState,
        card: {
          removeModal: false,
          removing: false,
          removedModal: true,
          removed: true
        }
      }));
    } catch (error: any) {
      event.send('api', {
        name: 'removeFid',
        type: 'error'
      });
    }
  };

  const closeRemoveModalHandler = () =>
    setState((oldState) => ({
      ...oldState,
      card: {
        ...oldState.card,
        remove: false,
        removeModal: false
      }
    }));

  const contentPart = (
    <LoadableContent
      className={classnames('myFidelityProgram', {
        'myFidelityProgram--removed': state.card.removed
      })}
      loading={state.isLoading}
    >
      <>
        {!!state.userInfo?.fidelityCardNumber && !!state.fidelityInfo && (
          <>
            <div className="myFidelityProgram__bloc myFidelityProgram__card">
              <div className="myFidelityProgram__heading">
                <div className="myFidelityProgram__title">
                  {!state.card.removed &&
                    t('myAccount.fidelity.fildelity_program.card.title')}
                  {state.card.removed &&
                    t('myAccount.fidelity.fildelity_program.no-card.title')}
                </div>
                {!state.card.removed && (
                  <Dropdown
                    className="myFidelityProgram__remove_menu"
                    size="130px"
                    position={{
                      right: '25px',
                      top: '0px'
                    }}
                    list={[
                      {
                        id: 'fidelityMenu_remove',
                        label: t(
                          'myAccount.fidelity.fildelity_program.card.menu.remove'
                        ),
                        action: () =>
                          setState((oldState) => ({
                            ...oldState,
                            card: {
                              ...oldState.card,
                              removeModal: true
                            }
                          }))
                      }
                    ]}
                  >
                    <MoreIcon
                      className="myFidelityProgram__remove_picto"
                      width={20}
                      height={20}
                    />
                  </Dropdown>
                )}
              </div>
              {!state.card.removed && (
                <div className="myFidelityProgram__cardNumber">
                  <svg height="20" width="35">
                    <use xlinkHref={`${MyAccountSprite}#fildelity-program`} />
                  </svg>
                  {t('myAccount.fidelity.fildelity_program.card.number', {
                    '%cardNumber%': state.userInfo.fidelityCardNumber
                  })}
                </div>
              )}
              <p className="myFidelityProgram__message">
                {!state.card.removed &&
                  t('myAccount.fidelity.fildelity_program.card.message')}
                {state.card.removed &&
                  t('myAccount.fidelity.fildelity_program.no-card.message')}
              </p>
              {state.card.removed && (
                <Link
                  button={true}
                  href="/fidelity-advantages"
                  className="myFidelityProgram__create"
                >
                  {t('myAccount.fidelity.fildelity_program.no-card.cta')}
                </Link>
              )}
              {/* {!state.card.removed && (
                <Link
                  href="/fidelity-advantages/personal-offers"
                  className="myFidelityProgram__link"
                >
                  <>
                    <svg height="13" width="13">
                      <use xlinkHref={`${MyAccountSprite}#caret`} />
                    </svg>
                    {t('myAccount.fidelity.fildelity_program.card.link')}
                  </>
                </Link>
              )} */}
            </div>
            <div className="myFidelityProgram__row">
              <div className="myFidelityProgram__bloc myFidelityProgram__balance">
                <div className="myFidelityProgram__balance_title">
                  {t('myAccount.fidelity.fildelity_program.balance.title')}
                </div>
                <div className="myFidelityProgram__balance_amount">
                  <span className="myFidelityProgram__balance_amountInteger">
                    {state.fidelityInfo.onlineUsableBalance?.amount.integer}
                  </span>
                  ,{state.fidelityInfo.onlineUsableBalance?.amount.decimal}{' '}
                  &euro;
                </div>

                <div className="myFidelityProgram__balance_date">
                  {t(
                    'myAccount.fidelity.fildelity_program.balance.balanceDate',
                    {
                      '%date%': state.fidelityInfo.globalBalance?.calculatedOn.format(
                        'D MMMM YYYY'
                      )
                    }
                  )}
                </div>
                <div className="myFidelityProgram__balance_use">
                  {t('myAccount.fidelity.fildelity_program.balance.use')}
                </div>
                {!!state.fidelityInfo.balanceBeforeReset && (
                  <div className="myFidelityProgram__balance_reset">
                    {t(
                      'myAccount.fidelity.fildelity_program.balance.resetDate',
                      {
                        '%amount%': `${state.fidelityInfo.balanceBeforeReset.amount.integer},${state.fidelityInfo.balanceBeforeReset.amount.decimal}`,
                        '%currency%':
                          state.fidelityInfo.balanceBeforeReset.amount.currency,
                        '%resetOn%': state.fidelityInfo.balanceBeforeReset.resetOn.format(
                          'DD/MM/YYYY'
                        ),
                        '%calculatedOn%': state.fidelityInfo.balanceBeforeReset.calculatedOn.format(
                          'DD/MM/YYYY'
                        )
                      }
                    )}
                  </div>
                )}
              </div>
              {state?.fidelityInfo?.availableBalance ? (
                <div className="myFidelityProgram__bloc">
                  <FidelityDetailedBalance fidelityInfo={state.fidelityInfo} />
                </div>
              ) : (
                <></>
              )}
              {/* <MyAdvantages fidelityInfo={state.fidelityInfo} /> */}
            </div>
            {!!state.favoritePdv && (
              <Store
                data={state.favoritePdv}
                userInfo={state.userInfo}
                variation="primary"
              />
            )}
            <Modal
              className="myFidelityProgram__modal"
              pageView="MyAccountCardDissociation"
              open={state.card.removeModal}
              onClose={closeRemoveModalHandler}
              cancelBtn={{
                label: t(
                  'myAccount.fidelity.fildelity_program.card.remove.cancel'
                ),
                onClick: closeRemoveModalHandler
              }}
              confirmBtn={{
                label: t(
                  'myAccount.fidelity.fildelity_program.card.remove.confirm'
                ),
                loading: state.card.removing,
                onClick: () => {
                  setState((oldState) => ({
                    ...oldState,
                    card: {
                      ...oldState.card,
                      removing: true
                    }
                  }));

                  removeCard();
                }
              }}
            >
              <h3 className="myFidelityProgram__modal_title">
                {t('myAccount.fidelity.fildelity_program.card.remove.title')}
              </h3>
              <p
                className="myFidelityProgram__modal_text"
                dangerouslySetInnerHTML={{
                  __html: t(
                    'myAccount.fidelity.fildelity_program.card.remove.text1'
                  )
                }}
              />
              <p
                className="myFidelityProgram__modal_text"
                dangerouslySetInnerHTML={{
                  __html: t(
                    'myAccount.fidelity.fildelity_program.card.remove.text2'
                  )
                }}
              />
              <p
                className="myFidelityProgram__modal_text myFidelityProgram__modal_strong"
                dangerouslySetInnerHTML={{
                  __html: t(
                    'myAccount.fidelity.fildelity_program.card.remove.text3'
                  )
                }}
              />
            </Modal>
            <Modal
              className="myFidelityProgram__modal"
              open={state.card.removedModal}
              confirmBtn={{
                label: t(
                  'myAccount.fidelity.fildelity_program.card.remove.confirmationBtn'
                ),
                loading: state.card.removing,
                onClick: () => {
                  setState((oldState) => ({
                    ...oldState,
                    card: {
                      ...oldState.card,
                      removedModal: false
                    }
                  }));
                }
              }}
            >
              <div className="myFidelityProgram__modalRemoved">
                {t(
                  'myAccount.fidelity.fildelity_program.card.remove.confirmation'
                )}
              </div>
            </Modal>
          </>
        )}
      </>
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.fidelity.myFidelityProgram')}
      enabledId="myFidelityProgram"
      content={contentPart}
      footer={footer}
    />
  );
};

export default MyFidelityProgram;
