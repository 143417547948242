import { useSelector } from 'react-redux';
import { NavStateType } from '@/store/nav/navReducer';
import React, {
  Key,
  MouseEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import './style.scss';
import classnames from 'classnames';
import { ReactComponent as Arrow } from '@/assets/images/icons/arrow-right.svg';
import usePdv from '@/lib/hooks/usePdv';
import Category from '@/lib/model/category';
import ListCategories from '@/components/molecules/categories';

export type PlpBreadcrumbItem = {
  label: string;
  link?: string;
  onClick?: (event: MouseEvent<any>) => void;
};

export type PlpBreadcrumbProps = {
  items: Array<PlpBreadcrumbItem>;
  handleSlected: () => void;
  categoriesIsVisible: boolean;
};

const PlpBreadcrumb = ({
  items = [],
  handleSlected,
  categoriesIsVisible
}: PlpBreadcrumbProps) => {
  const [state, setState] = useState<{
    list: Array<Category>;
    selected: Array<Category>;
    items: Array<PlpBreadcrumbItem>;
  }>({ list: [], selected: [], items });

  const navMainCategories = useSelector(
    ({ nav }: { nav: NavStateType }) => nav.mainCategories
  );

  useEffect(() => {
    const { ref } = usePdv();

    setState((s) => ({ ...s, list: navMainCategories[ref]?.data || s.list }));
  }, [navMainCategories]);

  const selectedItem = useCallback(
    (label: string, index) => {
      const selected = state.list.find((cat) => cat.title === label);
      if (selected) {
        handleSlected();
      }
      setState((s) => ({
        ...s,
        selected: selected ? selected.children : [],
        items: s.items.slice(0, index + 1)
      }));
    },
    [state, handleSlected]
  );

  return (
    <>
      <div className="plpBreadcrumb">
        {state.items.map((item, index: Key) => {
          const key = `plpBreadcrumb-${index}`;
          const classNames = classnames('plpBreadcrumb__item', {
            'plpBreadcrumb__item--last': state.items.length - 1 === index
          });

          return (
            <React.Fragment key={key}>
              <div
                onClick={() => {
                  if (index === 1) {
                    selectedItem(item.label, index);
                    return;
                  }

                  if (item.link) window.location.href = item.link;
                }}
                className={classNames}
              >
                <>{item.label}</>
              </div>
              {index !== state.items.length - 1 && (
                <Arrow
                  className="plpBreadcrumb__picto"
                  width="11"
                  height="11"
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
      {state.selected && !categoriesIsVisible && (
        <ListCategories
          classNames="plpBreadcrumb__categories"
          data={state.selected}
        />
      )}
    </>
  );
};

export default PlpBreadcrumb;
