import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import { Content as ContentfulContent } from '@/lib/contentful';
import VoucherWallet from '@/assets/images/voucher-wallet.png';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import { useSelector, shallowEqual } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { PdvVoucher } from '@/lib/api/voucher/types';
import LoadableContent from '@/components/atoms/loadableContent';
import VoucherApi from '@/lib/api/voucher';
import PdvApi from '@/lib/api/pdv';
import ChooseStore from '@/components/organisms/chooseStore';
import Pdv from '@/lib/model/pdv';

export type MyVouchersProps = {
  footer: ContentfulContent;
  pageView: string;
};

type PdvListType = { [key: string]: Pdv };

type StateType = {
  isLoading: boolean;
  pdvVouchers: Array<PdvVoucher>;
  pdvList: PdvListType;
};

const MyVouchers = ({ footer, pageView }: MyVouchersProps) => {
  // TODO :   const userId = useShallowEqualSelector(selectUserId);
  const { userId } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      userId: auth.user?.id
    };
  }, shallowEqual);
  const [state, setState] = useState<StateType>({
    isLoading: true,
    pdvVouchers: [],
    pdvList: {}
  });

  useEffect(() => {
    (async () => {
      if (!userId) return;

      let pdvVouchers: Array<PdvVoucher>;

      try {
        pdvVouchers = await VoucherApi.list(userId);
      } catch {
        pdvVouchers = [];
      }

      const pdvApiCalls = pdvVouchers.map((pdvVoucher) => {
        return PdvApi.getPdvInfo(pdvVoucher.pdv.ref);
      });

      const pdvList: PdvListType = {};
      const pdvApiData = await Promise.all(pdvApiCalls);
      pdvApiData.forEach((pdv) => {
        if (!pdv) return;

        pdvList[pdv.ref] = pdv;
      });

      setState((oldState) => ({
        ...oldState,
        pdvVouchers,
        pdvList,
        isLoading: false
      }));
    })();
  }, [userId]);

  const contentPart = (
    <LoadableContent className="myAccountVouchers" loading={state.isLoading}>
      <>
        {!!state.pdvVouchers.length &&
          state.pdvVouchers.map((pdvVouchers, index) => {
            const key = `pdvVouchers-${index}`;

            return (
              <div key={key} className="myAccountVouchers__pdvVouchers">
                <div className="myAccountVouchers__pdvVouchers_store">
                  {pdvVouchers.pdv.format} {pdvVouchers.pdv.city}
                  {state.pdvList[pdvVouchers.pdv.ref] && (
                    <ChooseStore
                      type="link"
                      className="myAccountVouchers__pdvVouchers_choose"
                      ctaLabel={t('myAccount.vouchers.choose')}
                      forcePdv={{
                        pdv: state.pdvList[pdvVouchers.pdv.ref]
                      }}
                    />
                  )}
                </div>
                <div className="myAccountVouchers__pdvVouchers_content">
                  <div className="myAccountVouchers__pdvVouchers_left">
                    <div className="myAccountVouchers__pdvVouchers_title">
                      {t('myAccount.vouchers.text1', {
                        '%city%': pdvVouchers.pdv.city
                      })}
                    </div>
                    <div className="myAccountVouchers__pdvVouchers_details">
                      {t('myAccount.vouchers.text2')}
                      {pdvVouchers.vouchers.map((voucher) => {
                        const voucherKey = `voucher-${voucher.id}`;

                        return (
                          <div
                            key={voucherKey}
                            className="myAccountVouchers__pdvVouchers_detail"
                            dangerouslySetInnerHTML={{
                              __html: t('myAccount.vouchers.text3', {
                                '%type%': t(
                                  `myAccount.vouchers.type.${voucher.type}.message`
                                ),
                                '%createdAt%': voucher.createdAt.format(
                                  'DD/MM/YYYY'
                                ),
                                '%endDate%': voucher.endDate.format(
                                  'DD/MM/YYYY'
                                ),
                                '%price%': voucher.amount.value
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ','),
                                '%currency%': voucher.amount.currency
                              })
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="myAccountVouchers__pdvVouchers_right">
                    <div className="myAccountVouchers__pdvVouchers_amount">
                      {pdvVouchers.amount.value
                        .toFixed(2)
                        .toString()
                        .replace('.', ',')}{' '}
                      {pdvVouchers.amount.currency}
                    </div>
                    {t('myAccount.vouchers.text4')}
                  </div>
                </div>
              </div>
            );
          })}
        {!state.pdvVouchers.length && (
          <div className="myAccountVouchers__empty">
            <img
              className="myAccountVouchers__empty_img"
              src={VoucherWallet}
              alt=""
            />
            <div className="myAccountVouchers__empty_message">
              {t('myAccount.myVouchers.empty')}
            </div>
          </div>
        )}
      </>
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.fidelity.vouchers')}
      enabledId="vouchers"
      content={contentPart}
      footer={footer}
      pageView={pageView}
    />
  );
};

export default MyVouchers;
