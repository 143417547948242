import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import './style.scss';
import t from '@/lib/i18n';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Link from '@/components/atoms/link';

export type ListProps = {
  className?: string;
};

const List = ({ className }: ListProps) => {
  const user = useSelector(({ auth }: { auth: AuthStateType }) => auth.user);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(!!user);
  }, [user]);

  return (
    <>
      <div className={classnames('list', className)}>
        <div id="btn-headerList" className="list__button">
          <svg className="fill--black" height="30" width="30">
            <use xlinkHref={`${CommonSprite}#chrono`} />
          </svg>
          <div className="list__title">{t('header.lists')}</div>
        </div>
        <div className="list__menu">
          <ul className="list__menu_content">
            <li className="list__menu_item">
              <Link
                id="headerShoppingList"
                href={`${
                  !isActive ? '/connection?redirect=' : ''
                }/account-management/my-lists/my-shopping-lists`}
              >
                <svg className="fill--black" height="30" width="30">
                  <use xlinkHref={`${CommonSprite}#list`} />
                </svg>
                <div className="list__menu_item__info">
                  <div className="list__menu_item__title">
                    {t('header.lists.shopping_list')}
                  </div>
                  <div className="list__menu_item__subTitle">
                    {t('header.lists.shopping_list.subTitle')}
                  </div>
                </div>
              </Link>
            </li>
            <li className="list__menu_item">
              <Link
                id="headerFrequentList"
                href={`${
                  !isActive ? '/connection?redirect=' : ''
                }/account-management/my-lists/my-usual-products`}
              >
                <svg className="fill--black" height="30" width="30">
                  <use xlinkHref={`${CommonSprite}#star-cart`} />
                </svg>
                <div className="list__menu_item__info">
                  <div className="list__menu_item__title">
                    {t('header.lists.frequent_list')}
                  </div>
                  <div className="list__menu_item__subTitle">
                    {t('header.lists.frequent_list.subTitle')}
                  </div>
                </div>
              </Link>
            </li>
            <li className="list__menu_item">
              <Link
                id="headerLastOrder"
                href={`${
                  !isActive ? '/connection?redirect=' : ''
                }/account-management/my-groceries`}
              >
                <svg className="fill--black" height="30" width="30">
                  <use xlinkHref={`${CommonSprite}#loading-arrow-cart`} />
                </svg>
                <div className="list__menu_item__info">
                  <div className="list__menu_item__title">
                    {t('header.lists.last_order')}
                  </div>
                  <div className="list__menu_item__subTitle">
                    {t('header.lists.last_order.subTitle')}
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default List;
