import {
  Content,
  normalize as BaseNormalize,
  Component,
  NormalizeParams
} from '..';

const normalize = ({
  data,
  includes,
  extras
}: NormalizeParams): Component | null => {
  const { noPdv: { sys: { id: idNoPdv = '' } = {} } = {} } = data;
  const { pdvDrive: { sys: { id: idPdvDrive = '' } = {} } = {} } = data;

  if (idPdvDrive) {
    const itemData = includes[idPdvDrive] || null;
    return BaseNormalize({ data: itemData, includes, extras }) as Component;
  }
  if (idNoPdv) {
    const itemData = includes[idNoPdv] || null;
    return BaseNormalize({ data: itemData, includes, extras }) as Component;
  }

  return {
    type: 'atoms',
    name: 'link',
    props: {
      href: data.href || '',
      label: data.text || '',
      button:
        ['accountActivationFailure', 'bonsPlans', 'communautes'].includes(
          extras?.id ?? ''
        ) || !!data.button
    }
  };
};

export default normalize;

export type CtaLinkProps = Content;
