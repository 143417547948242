/* eslint  class-methods-use-this: off */
import {
  AvailablePaymentsType,
  SlotListType,
  SlotsType,
  SlotType,
  CartType
} from '@/store/cart/types';
import createProducts from '@/lib/utils/products';
import Date from '@/lib/utils/date';
import { SLOT_TYPE_MAPPING } from '@/lib/model/slot';
import { ShelfStatusApiType } from './types';
import { NormalizerInterface } from '..';

class Normalizer implements NormalizerInterface {
  normalize(cart: CartType, pdv: string) {
    const pdvCart = cart.subCarts.find((subCart) => {
      return subCart.type === 'PDV';
    });

    if (!pdvCart) {
      return {
        data: {},
        discountCodeActive: false,
        vouchersCodeActive: false
      };
    }

    return {
      panier: {
        events: [],
        lastSynchronizedPanier: {
          lastSynchronizedDate: cart.synchronizedAt ?? Date(),
          products: createProducts(pdvCart)
        }
      },
      pdv,
      selectLad: true
    };
  }

  denormalize(data: any, cart: CartType): SlotsType {
    const createSlotsResponse = (slot: any) => {
      const deliveryType = slot.typeLivraison;
      const res: SlotListType = {
        days: slot.jours.map((day: any) => {
          return {
            slots: day.creneaux.map((s: any) => {
              let period = 'evening';
              if (s.heureDebut.split(':')[0] <= '11') {
                period = 'morning';
              }

              if (
                s.heureDebut.split(':')[0] >= '12' &&
                s.heureDebut.split(':')[0] < '18' &&
                s.heureFin.split(':')[0] <= '19'
              ) {
                period = 'afternoon';
              }

              const closedShelf: {
                closedEans: Array<string>;
                closedShelvesNames: Array<string>;
              } = s.rayons.reduce(
                (
                  acc: {
                    closedEans: Array<string>;
                    closedShelvesNames: Array<string>;
                  },
                  shelf: ShelfStatusApiType
                ) => {
                  if (shelf.statut === 'FERME') {
                    if (!acc.closedShelvesNames.includes(shelf.libelle)) {
                      acc.closedShelvesNames.push(shelf.libelle);
                    }
                    acc.closedEans = acc.closedEans.concat(shelf.produits);
                  }
                  return acc;
                },
                { closedEans: [], closedShelvesNames: [] }
              );

              let result: SlotType = {
                day: day.date,
                period,
                fees: s.frais,
                freeFees: s.fraisOfferts,
                startTime: s.heureDebut,
                endTime: s.heureFin,
                idSlot: s.idCreneau,
                status: s.statut,
                periodType: s.typePeriode,
                minimalCost: s.fraisMin,
                ...closedShelf
              };
              if (s.prestataires) {
                result = {
                  ...result,
                  providers: s.prestataires.map((provider: any) => ({
                    type: JSON.parse(provider.privateData).carrierType,
                    id: provider.id,
                    privateData: provider.privateData,
                    deliveryCost: {
                      cost: provider.deliveryCost.cost,
                      volumeExtraTax: provider.deliveryCost.volumeExtraTax,
                      housingExtraTax: provider.deliveryCost.housingExtraTax,
                      totalCost: provider.deliveryCost.totalCost
                    }
                  }))
                };
              }
              return result;
            }),
            day: day.date,
            isSelected: false
          };
        }),
        serviceProviders: [],
        state: slot.etatService,
        minimumOrderAmount: slot.montantMinimumCommande,
        maxLiquidVolume: slot.volumeMax,
        deliveryType: SLOT_TYPE_MAPPING[deliveryType],
        isMovingForThePlanet: slot.isMovingForThePlanet ?? false,
        availablePayments: slot.paiementsDisponibles.reduce(
          (
            payment: AvailablePaymentsType,
            current: { typeDePaiement: string; moyenDePaiement: string }
          ) => {
            return {
              ...payment,
              [current.typeDePaiement]: payment[current.typeDePaiement]
                ? [...payment[current.typeDePaiement], current.moyenDePaiement]
                : [current.moyenDePaiement]
            };
          },
          {}
        ),
        volumeCosts: slot.fraisVolume,
        housingCosts: slot.fraisHabitation
      };

      if (slot.carrierMetaData) {
        res.serviceProviders = slot.carrierMetaData.map((provider: any) => ({
          descriptionDesktop: provider.descriptionDesktop,
          descriptionMobile: provider.descriptionMobile,
          id: provider.id,
          desktopTitle: provider.libelleDesktop,
          mobileTitle: provider.libelleMobile,
          logo: provider.logoUrl,
          type: provider.typePrestataire
        }));
      }

      res.housingExtraCostAvailable =
        slot.montantSurcoutHabitationActif ?? false;
      res.housingExtraCost = slot.montantSurcoutHabitation;
      res.floorMinExtraCost = slot.typeEtage;
      res.VolumeExtraCostAvailable = slot.montantSurcoutVolumeActif;
      res.volumeExtraCost = slot.montantSurcoutVolume;
      res.volumeMinExtraCost = slot.volumeSurcout;

      return res;
    };

    const result: SlotsType = {
      discountCodeActive: data.codeReductionActif,
      vouchersCodeActive: data.fidActive,
      data: {}
    };

    if (data.drive) {
      result.data.drive = createSlotsResponse(data.drive);
    }
    if (data.drive24) {
      result.data.drive24 = createSlotsResponse(data.drive24);
    }
    if (data.livraisonADomicile) {
      result.data.delivery = createSlotsResponse(data.livraisonADomicile);
    }
    if (data.retraitMagasin) {
      result.data.drivepieton = createSlotsResponse(data.retraitMagasin);
    }

    if (data.livraisonADomicile && !data.livraisonADomicile.jours) {
      const deliveryType = 'delivery';
      result.data.delivery = {
        isMovingForThePlanet: false,
        days: [],
        serviceProviders: [],
        state: data.etatService ? data.etatService : 'NON_DESSERVI',
        minimumOrderAmount: 0,
        maxLiquidVolume: 0,
        deliveryType: SLOT_TYPE_MAPPING[deliveryType],
        availablePayments: {},
        housingExtraCostAvailable: false,
        housingExtraCost: 0,
        floorMinExtraCost: 0,
        VolumeExtraCostAvailable: false,
        volumeExtraCost: 0,
        volumeMinExtraCost: 0,
        housingCosts: 0,
        volumeCosts: 0
      };
    }
    return result;
  }
}

export default new Normalizer();
