import { HeadingMarketingProps } from '@/lib/contentful/normalizers/c-heading-marketing';
import React from 'react';
import './style.scss';

const HeadingMarketing = ({
  title,
  textColor,
  background
}: HeadingMarketingProps) => {
  const styleTitle = {
    color: textColor
  };

  const styleBackground = {
    backgroundImage: `url(${background})`
  };

  return (
    <div className="headingMarketing">
      <div style={styleBackground} className="headingMarketing__content">
        <h2 style={styleTitle} className="headingMarketing__title">
          {title}
        </h2>
      </div>
    </div>
  );
};

export default HeadingMarketing;
