import ShowHideList from '@/components/molecules/showHideList';
import t from '@/lib/i18n';
import React from 'react';
import CommonSprite from '@/assets/images/sprites/common.svg';
import SmartConsoSprite from '@/assets/images/sprites/smartconso.svg';
import './style.scss';

import labelsData from '@/components/templates/pdp/content/labels/labels.json';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import classNames from 'classnames';

type CustomScoreLabelsProps = {
  flags: Array<{ key: number }>;
  avoid?: boolean;
};
const groupedLabelsData = groupBy(labelsData.labels, 'key');

const CustomScoreLabels = ({ flags, avoid }: CustomScoreLabelsProps) => {
  let flagsWithLabel = flags
    .map((f) => {
      if (groupedLabelsData[f.key]?.[0]) {
        return { ...f, ...groupedLabelsData[f.key]?.[0] };
      }
      return undefined;
    })
    .filter(Boolean);

  flagsWithLabel = sortBy(flagsWithLabel, avoid ? 'display' : 'order');
  if (avoid && flagsWithLabel?.[0]) {
    flagsWithLabel = [
      {
        ...flagsWithLabel[0],
        display: flagsWithLabel.map((f) => f?.display).join(', ')
      }
    ];
  }

  const renderItem = (flag: any, key: string) => {
    const src = `https://cdn.intermarche.pt/mobileAppAssets/${flag.picto}.png`;
    return (
      <div className="scoreLabels__item" key={key}>
        {!avoid && (
          <div className="scoreLabels__item__imageContainer">
            <img src={src} alt={flag.key} height="auto" width={30} />
          </div>
        )}
        {avoid && (
          <span className="scoreLabels__item-svg nutritionSection__heading__avoid">
            <svg className="rank--avoid" width="25px" height="25px">
              <use xlinkHref={`${SmartConsoSprite}#avoid`} />
            </svg>
          </span>
        )}
        <span
          className={classNames({
            scoreLabels__item__labelAvoid: avoid
          })}
        >
          {flag.display}
        </span>
      </div>
    );
  };

  const hideBtn = (
    <div>
      <span>{t('pdp.nutrition.label.custom.hide')}</span>
      <svg className="scoreLabels__arrow" height="10" width="10">
        <use xlinkHref={`${CommonSprite}#arrow-up`} />
      </svg>
    </div>
  );

  const showAllBtn = (
    <div>
      <span>
        {t('pdp.nutrition.label.custom.show', {
          '%n%': flagsWithLabel.length
        })}
      </span>
      <svg className="scoreLabels__arrow" height="10" width="10">
        <use xlinkHref={`${CommonSprite}#arrow-down`} />
      </svg>
    </div>
  );

  return (
    <div className="scoreLabels">
      {flagsWithLabel.length > 0 && (
        <>
          <div className="scoreLabels__title">
            <p className="scoreLabels__title--custom">
              {t(
                avoid
                  ? 'pdp.nutrition.label.custom.title.avoid'
                  : 'pdp.nutrition.label.custom.title'
              )}
            </p>
            <p className="scoreLabels__description--custom">
              {t(
                avoid
                  ? 'pdp.nutrition.label.custom.desc.avoid'
                  : 'pdp.nutrition.label.custom.desc'
              )}
            </p>
          </div>
          <div className="">
            <ShowHideList
              items={flagsWithLabel}
              itemRenderer={renderItem}
              btnHideContent={hideBtn}
              btnShowContent={showAllBtn}
              min={3}
              btnClass="scoreLabels__showHideBtn"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CustomScoreLabels;
