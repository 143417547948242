import React, { useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';
import MyAccount from '@/assets/images/sprites/myAccount.svg';
import StoreLocatorSprite from '@/assets/images/sprites/storeLocator.svg';
import PdvModel, { getDeliveryType } from '@/lib/model/pdv';
import Date, { getHoursColor } from '@/lib/utils/date';
import Link from '@/components/atoms/link';
import Modal from '@/components/organisms/modal';
import ChooseStore from '@/components/organisms/chooseStore';
import userPdvApi from '@/lib/api/userPdv';
import { Informations as UserInfo } from '@/lib/api/userInformation/types';
import useEvent from '@/lib/hooks/useEvent';

type StoreProps = {
  variation?: 'primary' | 'secondary';
  data: PdvModel;
  userInfo?: UserInfo | null;
  onDelete?: (pdv: PdvModel) => Promise<void>;
};

const getMessage = (pdv: PdvModel) => {
  let openingMessage = '';
  const isOpen = pdv.isOpen();
  const nextClosingDate = pdv.getNextClosingDate();
  const nextOpeningDate = pdv.getNextOpeningDate();

  if (isOpen && nextClosingDate) {
    openingMessage = t('header.storeInfo.openingMessage.todayUntil', {
      '%hours%': nextClosingDate.format('HH'),
      '%minutes%': nextClosingDate.format('mm')
    });
  } else if (!isOpen && nextOpeningDate) {
    const today = Date().hour(0).minute(0);
    const tomorrow = today.add(1, 'day');

    if (nextOpeningDate > today && nextOpeningDate < tomorrow) {
      openingMessage = t('header.storeInfo.openingMessage.today', {
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    } else if (nextOpeningDate > tomorrow) {
      openingMessage = t('header.storeInfo.openingMessage.tomorrow', {
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    } else {
      openingMessage = t('header.storeInfo.openingMessage', {
        '%date%': nextOpeningDate.format('dddd'),
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    }
  }

  return openingMessage;
};

const Store = ({
  variation = 'secondary',
  data,
  userInfo,
  onDelete
}: StoreProps) => {
  const [state, setState] = useState({
    deleteModalOpen: false,
    isDeleting: false
  });
  const event = useEvent();

  const deletePdv = async () => {
    if (!userInfo) return;

    try {
      await userPdvApi.delete(userInfo.id, data);
      event.send('account', {
        type: 'removeStore',
        storeId: data.ref
      });
    } catch (error: any) {
      // Todo: handle user error display unable to delete user pdv
    }

    if (onDelete) {
      onDelete(data);
    }
  };

  return (
    <div className={classnames('myStore', `myStore--${variation}`)}>
      <div className="myStore__heading">
        <div className="myStore__name myStore__title">
          <span className="myStore__format">{data.format}</span>
          <span className="myStore__city">
            <div className="myStore__commonName">{`${t('common.name')}`}</div>
            {data.address?.city}
          </span>
        </div>
        {variation === 'secondary' && data.ref !== userInfo?.pdvFidelity && (
          <svg
            className="myStore__remove"
            onClick={() => {
              setState((oldState) => ({
                ...oldState,
                deleteModalOpen: true
              }));
            }}
            width="25"
            height="25"
          >
            <use xlinkHref={`${CommonSprite}#trash`} />
          </svg>
        )}
      </div>
      <div className="myStore__row">
        <div className="myStore__address">
          <div className="myStore__title">
            {t('myAccount.myStores.address')}
          </div>
          <div className="myStore__address_content">
            {data.address?.address1} <br />
            {!!data.address?.address2 && (
              <>
                {data.address?.address2} <br />
              </>
            )}
            {data.address?.zipCode} {data.address?.city}
          </div>
        </div>
        {data.types.length > 0 && (
          <div className="myStore__modes">
            <div className="myStore__title">
              {t('myAccount.myStores.modes')}
            </div>
            <div className="myStore__modes_content">
              {data.types.map((type) => {
                const key = `pdv-${data.ref}-type-${type}`;
                const spriteType = getDeliveryType(type);

                return (
                  <div className="myStore__mode" key={key}>
                    <svg height="20" width="20">
                      <use xlinkHref={`${StoreLocatorSprite}#${spriteType}`} />
                    </svg>
                    {t(`pdv.type.${type}`)}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {variation === 'primary' && (
        <>
          <div className="myStore__row">
            <div className="myStore__contact">
              <div className="myStore__title">
                {t('myAccount.myStores.contact')}
              </div>
              <ul className="myStore__contact_content">
                <li>
                  {t('myAccount.myStores.phone', {
                    '%phone%': data.phone || ''
                  })}
                  <div className="myStore__contact__phone__legalNotice">
                    {t('common.phoneLegalNotice')}
                  </div>
                </li>
              </ul>
            </div>
            <div className="myStore__hours">
              <div className="myStore__title">
                {t('myAccount.myStores.hours')}
              </div>
              <div className="myStore__hours_content">
                {!data.isOpen() && (
                  <>
                    <span className="myStore__hours_highlight myStore__hours_highlight--closed">
                      {t('header.storeInfo.closed')}
                    </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getMessage(data)
                      }}
                    />
                  </>
                )}
                {data.isOpen() && (
                  <div
                    className={classnames(
                      'myStore__hours_highlight',
                      `myStore__hours_highlight--${getHoursColor(
                        data.getNextClosingDate()
                      )}`
                    )}
                  >
                    {getMessage(data)}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="myStore__row myStore__goToStorePage">
            <div className="myStore__storePage">
              <Link href="/store" button>
                {t('myAccount.myStores.storePage')}
              </Link>
            </div>
            <div className="myStore__seeHours">
              <Link href="/store">
                <>
                  <svg className="fill--red" width="20" height="20">
                    <use xlinkHref={`${MyAccount}#clock`} />
                  </svg>
                  {t('myAccount.myStores.seeHours')}
                </>
              </Link>
            </div>
          </div>
        </>
      )}
      {variation === 'secondary' && (
        <div className="myStore__switchTo">
          <ChooseStore
            type="link"
            className="myStore__switchTo_link"
            ctaLabel={t('myAccount.myStores.chooseStore')}
            forcePdv={{
              pdv: data
            }}
          />
        </div>
      )}
      <Modal
        className="myStore__deleteModal"
        pageView="RemoveStore"
        open={state.deleteModalOpen}
        onClose={() => {
          setState((oldState) => ({
            ...oldState,
            deleteModalOpen: false
          }));
        }}
        confirmBtn={{
          label: t('yes'),
          loading: state.isDeleting,
          onClick: () => {
            setState((oldState) => ({
              ...oldState,
              isDeleting: true
            }));

            deletePdv();
          }
        }}
        cancelBtn={{
          label: t('no'),
          onClick: () => {
            setState((oldState) => ({
              ...oldState,
              deleteModalOpen: false
            }));
          }
        }}
      >
        <h2 className="myStore__deleteModal_title">
          {t('myAccount.myStores.deleteModal.title')}
        </h2>
        <p className="myStore__deleteModal_message">
          {t('myAccount.myStores.deleteModal.message', {
            '%store%': data.name
          })}
        </p>
      </Modal>
    </div>
  );
};

export default Store;
