import CommonSprite from '@/assets/images/sprites/common.svg';
import Loader from '@/components/atoms/loader';
import { useDidomi } from '@/lib/hooks/useDidomi';
import t from '@/lib/i18n';
import Product from '@/lib/model/product';
import { notification } from '@/lib/notification';
import React, { useState } from 'react';
import { BottomProductsLayerProps } from '../bottomProductsLayer/types';
import getRecommendationProducts from './getRecommendationProducts';
// eslint-disable-next-line import/no-cycle
import RecommendationProductPopin from './popin';

type Props = BottomProductsLayerProps & {
  onClickCallback?: () => void;
};

type StateProps = {
  isOpen: boolean;
  isLoading: boolean;
  productList: Array<Product>;
};

const RecommendationProduct = ({
  productModel,
  onClickCallback,
  onValidate,
  onCancel,
  hasFooter,
  title,
  information,
  isCheckout
}: Props) => {
  const [state, setState] = useState<StateProps>({
    isOpen: false,
    productList: [],
    isLoading: false
  });

  const { data: isSelectPersonalizedContent } = useDidomi(
    'select_personalized_content'
  );

  const checkRecommendations = async () => {
    setState((s) => ({ ...s, isLoading: true }));
    try {
      const response = await getRecommendationProducts(
        isSelectPersonalizedContent,
        productModel?.id
      );
      if (!response.products.length) {
        notification.info(t('similar.products.noResults'));
      } else {
        setState((s) => ({
          ...s,
          isOpen: true,
          productList: response.products
        }));
      }
    } finally {
      setState((s) => ({ ...s, isLoading: false }));
      onClickCallback && onClickCallback();
    }
  };

  return (
    <>
      <RecommendationProductPopin
        productModel={productModel}
        productList={state.productList}
        setIsOpen={(value) => setState((s) => ({ ...s, isOpen: value }))}
        isOpen={state.isOpen}
        hasFooter={hasFooter}
        onCancel={onCancel}
        onValidate={onValidate}
        title={title}
        information={information}
        isCheckout={isCheckout}
      />
      <div className="product__recommendation" onClick={checkRecommendations}>
        {t('product.similar')}
        <div className="product__recommendation__svg">
          {state.isLoading && <Loader />}
          {!state.isLoading && (
            <svg width="24px" height="24px">
              <use xlinkHref={`${CommonSprite}#exchange`} />
            </svg>
          )}
        </div>
      </div>
    </>
  );
};

export default RecommendationProduct;
