import React, { VideoHTMLAttributes, SourceHTMLAttributes, Key } from 'react';
import './style.scss';

export type VideoProps = {
  sources: Array<SourceHTMLAttributes<HTMLSourceElement>>;
  attributes: VideoHTMLAttributes<HTMLVideoElement>;
};

const Video = ({ sources, attributes }: VideoProps) => {
  return (
    <video className="video" {...attributes}>
      {sources.map(
        (source: SourceHTMLAttributes<HTMLSourceElement>, index: Key) => {
          const key = `video-${index}`;
          return <source key={key} src={source.src} type={source.type} />;
        }
      )}
    </video>
  );
};

export default Video;
