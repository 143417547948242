import React from 'react';
import { CartItemType, SellerType } from '@/store/cart/types';
import Image from '@/components/atoms/image';
import SoldByName from '@/components/organisms/product/soldByName';
import './style.scss';
import t from '@/lib/i18n';

type ItemsHeaderProps = {
  items?: Array<CartItemType>;
  currentPackageNumber: number;
  totalPackageNumber: number;
  seller: SellerType;
};

const ItemsHeader = ({
  items,
  currentPackageNumber,
  totalPackageNumber,
  seller
}: ItemsHeaderProps) => {
  return !items ? null : (
    <>
      <div className="itemsHeader__BlocContent">
        <p className="itemsHeader__text">{`Colis  ${currentPackageNumber}/${totalPackageNumber}`}</p>
      </div>
      {items.map((item: CartItemType) => (
        <div className="itemsHeader__item" key={item.product.id}>
          <div className="itemsHeader__imageText">
            <Image
              src={item.product.informations.image?.src || ''}
              alt={item.product.informations.image?.alt || ''}
              className="itemsHeader__imageText__image"
              height={53}
              width={60}
            />
            <div className="itemsHeader__imageText__text">
              <p className="itemsHeader__brand">
                <strong>{`${item.product.informations.brand} - `}</strong>
                {item.product.informations.title}
              </p>
              <SoldByName
                seller={{
                  id: seller.sellerId,
                  name: seller.sellerName
                }}
                enableClick={false}
                centered={false}
              />
            </div>
          </div>
          <div className="itemsHeader__QuantityPriceWrapper">
            <p className="itemsHeader__quantity">
              {`${t('cart.quantity')} : `}
              <strong>{item.qty}</strong>
            </p>
            <p className="itemsHeader__price">{`${item.total
              .toFixed(2)
              .replace('.', ',')} €`}</p>
          </div>
        </div>
      ))}
      <div className="itemsHeader__lineBreak" />
    </>
  );
};

export default ItemsHeader;
