import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.scss';
import t from '@/lib/i18n';
import { Content as ContentfulContent } from '@/lib/contentful';
import { AuthStateType } from '@/store/auth/authReducer';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import newsletterApi from '@/lib/api/newsletter';
import userInformationApi from '@/lib/api/userInformation';
import LoadableContent from '@/components/atoms/loadableContent';
import Newsletter, { NewsletterProps } from './newsletter';

export type MyNewslettersProps = {
  footer: ContentfulContent;
  pageView: string;
};

const defaultNewsletters: Array<NewsletterProps> = [
  {
    code: '3',
    translateKey: 'drive-news',
    icon: (
      <svg height="50" width="35">
        <use xlinkHref={`${MyAccountSprite}#drive-news`} />
      </svg>
    ),
    subscribed: false,
    disabled: false
  },
  {
    code: '1',
    translateKey: 'promo-news',
    icon: (
      <svg height="50" width="55">
        <use xlinkHref={`${MyAccountSprite}#promo-news`} />
      </svg>
    ),
    subscribed: false,
    disabled: false
  },
  {
    code: '2',
    translateKey: 'fid-news',
    icon: (
      <svg height="35" width="50">
        <use xlinkHref={`${MyAccountSprite}#fildelity-program`} />
      </svg>
    ),
    subscribed: false,
    disabled: false
  },
  {
    code: '9',
    translateKey: 'pdv-news',
    icon: (
      <svg height="35" width="50">
        <use xlinkHref={`${MyAccountSprite}#store`} />
      </svg>
    ),
    subscribed: false,
    disabled: false
  }
];

const MyNewsletters = ({ footer, pageView }: MyNewslettersProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newsletters, setNewsletters] = useState<Array<NewsletterProps>>([]);
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );

  useEffect(() => {
    (async () => {
      if (!userId) {
        setNewsletters([]);

        return;
      }

      setIsLoading(true);

      const data = await Promise.all([
        newsletterApi.getAllStatus(userId),
        userInformationApi.getInformations(userId)
      ]);

      const allStatus = data[0];
      const userInfo = data[1];

      const newNewsletter = defaultNewsletters.map(
        (newsletter: NewsletterProps) => {
          return {
            ...newsletter,
            subscribed: allStatus[newsletter.code] || false,
            disabled: newsletter.code === '2' && !userInfo.fidelityCardNumber
          };
        }
      );

      setNewsletters(newNewsletter);
      setIsLoading(false);
    })();
  }, [userId]);

  const contentPart = (
    <LoadableContent loading={isLoading} className="myNewsletters">
      {newsletters.map((newsletter) => {
        return <Newsletter key={newsletter.translateKey} {...newsletter} />;
      })}
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.userInfo.myNewsletters')}
      enabledId="userInfo"
      content={contentPart}
      footer={footer}
      backTo={{
        label: t('myAccount.menu.userInfo.long'),
        href: '/account-management/my-info'
      }}
      pageView={pageView}
    />
  );
};

export default MyNewsletters;
