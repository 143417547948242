import { ReactComponent as ComptePicto } from '@/assets/images/icons/monCompte.svg';
import { ReactComponent as StorePicto } from '@/assets/images/icons/store.svg';
import Button from '@/components/atoms/button';
import FormInput from '@/components/atoms/formInput';
import { PersonalDataPolicy } from '@/components/atoms/personalDataPolicy';
import PasswordPolicies, {
  IsValid as IsValidPassword
} from '@/components/molecules/passwordPolicies';
import ChooseStore from '@/components/organisms/chooseStore';
import AccountApi from '@/lib/api/account';
import PdvApi from '@/lib/api/pdv';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
// import PdvModel from '@/lib/model/pdv';
import { notification } from '@/lib/notification';
// import { setPdvCookie } from '@/lib/utils/pdv';
import { emailValidationRegExp } from '@/lib/utils/stringUtils';
import { USER_IDENTIFIANTS } from '@/store/register/registerActions';
import { NewUserLocal } from '@/store/register/types';
import { useRouter } from 'next/router';
import React, { ElementRef, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type IdentifiantsProps = {
  pdv: string;
  email: string;
  password: string;
};

export type StepProps = {
  user: NewUserLocal;
  dispatch: any;
};

export type ChangeCredentialsProps = {
  email?: string;
  password?: string;
};

export type StateProps = {
  extended: boolean;
  isLoading: boolean;
  requiredPdv: boolean;
  pdvRef: string | null;
  pdvName: string | undefined;
  pdvAddress: string | undefined;
  email: string;
  password: string;
  token: string;
};

enum ERROR {
  EMAIL_ACTIVE = 'EMAIL_ACTIVE',
  EMAIL_NOT_ACTIVE = 'EMAIL_NOT_ACTIVE',
  USER_LOCKED = 'USER_LOCKED'
}

type ErrorMessageProps = {
  message: string;
  buttonLabel: string;
  onClick: () => void;
};

const ErrorMessage = ({ message, buttonLabel, onClick }: ErrorMessageProps) => (
  <div className="formInput__errorMessage">
    {message}
    <span className="formInput__errorMessage--link" onClick={onClick}>
      {buttonLabel}
    </span>
  </div>
);

const Identifiants = ({ dispatch, user }: StepProps) => {
  const [state, setState] = useState<StateProps>({
    extended: false,
    isLoading: false,
    requiredPdv: false,
    pdvRef: null,
    pdvName: undefined,
    pdvAddress: undefined,
    email: '',
    password: '',
    token: ''
  });

  const router = useRouter();

  type ChooseStoreHandle = ElementRef<typeof ChooseStore>;

  const chooseStoreRef = useRef<ChooseStoreHandle>(null);

  useEffect(() => {
    (async () => {
      const { ref } = usePdv();
      if (ref && typeof ref === 'string') {
        const res = await PdvApi.getPdvInfo(ref);
        setState((s) => ({
          ...s,
          pdvRef: ref,
          pdvName: res?.name,
          pdvAddress: res?.address?.address1
        }));
      }
    })();
  }, []);

  const {
    errors,
    control,
    register,
    handleSubmit,
    trigger,
    setError
  } = useForm<IdentifiantsProps>({
    shouldUnregister: false,
    defaultValues: {
      email: user.email || '',
      password: user.password || ''
    }
  });

  const onChangeCredentials = (value: ChangeCredentialsProps) => {
    setState((s) => ({
      ...s,
      ...value
    }));
    dispatch({
      type: USER_IDENTIFIANTS,
      payload: {
        email: value?.email || state.email,
        password: value?.password || state.password
      }
    });
  };

  const onSubmit = async (form: IdentifiantsProps) => {
    const onSubmitCallback = async () => {
      setState((s) => ({
        ...s,
        isLoading: true,
        requiredPdv: false
      }));
      const isValidEmail = await AccountApi.emailAlreadyExistV2(form.email);
      if (state.pdvRef) {
        if (isValidEmail.result) {
          dispatch({
            type: USER_IDENTIFIANTS,
            payload: {
              pdv: state.pdvRef,
              email: form.email,
              password: form.password
            }
          }) && router.push('/my-account/create-my-account/identity');
        } else {
          let message = '';
          switch (isValidEmail.errorKey) {
            case 'EMAIL_ALREADY_USED':
              message = ERROR.EMAIL_ACTIVE;
              break;

            case 'USER_NOT_ACTIVATED':
              message = ERROR.EMAIL_NOT_ACTIVE;
              break;

            case 'USER_LOCKED':
              message = ERROR.USER_LOCKED;
              break;

            default:
              notification.error(t('notification.erreur'));
              break;
          }

          setError('email', {
            message
          });
        }
        setState((s) => ({ ...s, isLoading: false }));
      } else {
        setState((s) => ({ ...s, requiredPdv: true, isLoading: false }));
      }
    };
    onSubmitCallback();
  };

  /* 
  const onStoreSelect = (pdv: PdvModel) => {
    const { ref: pdvRef, name: pdvName, address } = pdv;
    const pdvAddress = address?.address1;

    if (pdvRef === state.pdvRef) {
      chooseStoreRef.current?.storeLocatorClose();

      return;
    }

    setPdvCookie(pdv);

    setState((s) => ({
      ...s,
      pdvRef,
      pdvName,
      pdvAddress,
      requiredPdv: false
    }));
  };

*/
  const sendActivationEmail = async (email: string) => {
    const response = await AccountApi.resendActivationEmailV2(email);
    if (response) {
      notification.success(t('register.form.sendActivationEmail'));
    }
  };

  useEffect(() => {
    if (state.pdvRef) {
      chooseStoreRef.current?.storeLocatorClose();
    }
  }, [state.pdvRef]);

  return (
    <>
      <form className="formSubscribe" onSubmit={handleSubmit(onSubmit)}>
        <div className="title">{t('createAccount.form.createAccount')}</div>
        {/* <div className="subtitle">{t('register.store.subtitle')}</div> */}
        <div className="box">
          <div className="icon">
            <StorePicto width={150} height={150} />
          </div>
          <div className="card">
            <div className="card__title">
              {t('createAccount.form.chooseStore.label')}
            </div>
            <div className="card__description">
              <>
                <div className="storeName">
                  {state.pdvName && `${t('common.name')} ${state.pdvName}`}
                </div>
                <div>{state.pdvAddress && state.pdvAddress}</div>
              </>

              <div>
                <ChooseStore
                  /* ctaLabel={
                    // state.pdvRef
                      ? t('register.chooseStore.change')
                      : t('register.chooseStore.choose')
                  }
                */

                  title={t('myAccount.dashboard.store.title')}
                  type="button"
                  className="chooseStore"
                  content={t('header.storeInfo.chooseStore.content')}
                  storeLocator={{
                    withInStore: true,
                    withDrive: true,
                    withDelivery: true

                    /* 
                      redirect: {
                      auto: false,
                      url: '/my-account/create-my-account'
                      onSelect: onStoreSelect
                    } 
                  */
                  }}
                  ref={chooseStoreRef}
                />
              </div>

              {state.requiredPdv && (
                <div className="inputRegister__error">
                  {t('register.chooseStore.error')}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="box box--no-border">
          <div className="icon">
            <ComptePicto width={150} height={150} />
          </div>
          <div className="card">
            <div className="card__title">
              {t('createAccount.form.password.title')}
            </div>

            <div className="card__description">
              {t('createAccount.form.password.description.first')}
              <br />
              {t('createAccount.form.password.description.second')}
            </div>
          </div>
        </div>
        <div className="box">
          <div className="card">
            <div className="input-label">
              <label htmlFor="email">
                {`${t('forgottenPassword.form.emailLabel')} *`}
              </label>
            </div>
            <>
              <FormInput
                id="email"
                name="email"
                className="inputRegister"
                onChange={(e) => onChangeCredentials({ email: e.target.value })}
                ref={register({
                  required: t('form.error.required'),
                  pattern: {
                    value: emailValidationRegExp,
                    message: t('register.form.email.error')
                  }
                })}
                errorMessage={
                  errors.email?.message &&
                  !Object.values(ERROR).includes(errors.email.message as ERROR)
                    ? errors.email.message
                    : undefined
                }
              />
              {errors.email?.message &&
                errors.email.message === ERROR.EMAIL_ACTIVE && (
                  <ErrorMessage
                    message={t('register.form.emailActive')}
                    buttonLabel={t('register.form.cliqueIci')}
                    onClick={() => {
                      router.push(`/forgotten-password?email=${state.email}`);
                    }}
                  />
                )}
              {errors.email?.message &&
                errors.email.message === ERROR.EMAIL_NOT_ACTIVE && (
                  <ErrorMessage
                    message={t('register.form.emailNotActive')}
                    buttonLabel={t('register.form.cliqueIci')}
                    onClick={() => sendActivationEmail(state.email)}
                  />
                )}
              {errors.email?.message &&
                errors.email.message === ERROR.USER_LOCKED && (
                  <ErrorMessage
                    message={t('connexion.blocked-account')}
                    buttonLabel={t(
                      'createAccount.form.personalDataPolicy.click'
                    )}
                    onClick={() => {
                      router.push(`/forgotten-password?email=${state.email}`);
                    }}
                  />
                )}
            </>
            <Controller
              control={control}
              name="password"
              type="password"
              rules={{
                required: true,
                validate: {
                  policies: (value) => {
                    return IsValidPassword(value);
                  }
                }
              }}
              render={({ value, name, ref, onChange }, { invalid }) => (
                <>
                  <FormInput
                    id={name}
                    name={name}
                    value={value}
                    type="password"
                    label={`${t('createAccount.form.label.password')} *`}
                    className="inputRegister"
                    onChange={(e) => {
                      onChange(ref.current.value);
                      onChangeCredentials({ password: e.target.value });
                    }}
                    ref={ref}
                    errorMessage={
                      errors.password ? t(`form.error.password`) : ''
                    }
                    readOnly
                    onFocus={(e) => {
                      e.currentTarget.removeAttribute('readonly');
                    }}
                  />
                  <PasswordPolicies
                    className="changePassword__policies"
                    value={value}
                    validation={invalid}
                  />
                </>
              )}
            />
            <PersonalDataPolicy
              handleClick={() =>
                setState((s) => ({
                  ...s,
                  extended: !state.extended
                }))
              }
              extended={state.extended}
            />
          </div>
        </div>
        <div className="footer">
          <div className="footer__required">
            {t('register.footer.requiredFields')}
          </div>
          <Button
            onClick={() => {
              trigger();
            }}
            type="submit"
            label={t('common.next')}
            loading={state.isLoading}
          />
        </div>
      </form>
    </>
  );
};

export default Identifiants;
