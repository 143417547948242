import React from 'react';
import './style.scss';

export type TitleSectionProps = {
  text: string;
};

const TitleSection = ({ text }: TitleSectionProps) => (
  <div className="titleSection">
    <span
      className="titleSection__text"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </div>
);

export default TitleSection;
