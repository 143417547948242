import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import './style.scss';
import Title from '@/components/atoms/title';
import Button from '@/components/atoms/button';
import t from '@/lib/i18n';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import consumers, { PostContactFormProps } from '@/lib/api/consumers';
import userInformationApi from '@/lib/api/userInformation';
import classnames from 'classnames';
import formsTemplate, {
  FormsTemplateProps,
  FieldFormProps
} from '../formsTemplate';
import RenderField from './renderField';

export type FormContactUsProps = {
  slug: string;
};

export type ResultForm = {
  email: string;
  lastName: string;
  phone: string;
  firstName: string;
  postCode: string;
  expirationDate: string;
  barCodeOfProduct: string;
  operationName: string;
  productAndBrandName: string;
  cardFidNumber: string;
  orderNumber: string;
  batchNumber: string;
  city: string;
  ordersType: string;
  orderDetail: string;
  files: FileList;
};
export type SchemaValidation = any;

export type Schema = { [key: string]: any };

const FormContactUs = ({ slug }: FormContactUsProps) => {
  const formTemplate: FormsTemplateProps | null =
    formsTemplate.find((form) => form.urlSlug === slug) || null;

  const user = useSelector(({ auth }: { auth: AuthStateType }) => auth.user);

  const [orderTypeSelected, setOrderTypeSelected] = useState(
    formTemplate?.fields.find((field) => field.tag === 'ordersType')
      ?.ordersList?.[0] || null
  );
  const schema: Schema = {};

  const schemaValidation: SchemaValidation = () => {
    formTemplate?.fields.forEach((field) => {
      if (field.validator) {
        schema[field.name] = field.validator;
      }
    });
    return yup.object().shape(schema);
  };

  const {
    register,
    handleSubmit,
    formState: { dirtyFields },
    errors,
    setValue,
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(schemaValidation()),
    shouldFocusError: true
  });

  useEffect(() => {
    if (user) {
      (async () => {
        const userInfo = await userInformationApi.getInformations(user.id);
        reset({
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, reset]);

  if (!formTemplate) {
    return null;
  }

  const isRequiredFieldsFulfilled = !formTemplate.fields.find(
    (field) =>
      !(
        !schema[field.name]?.exclusiveTests?.required ||
        getValues(field.name) ||
        field.name in dirtyFields
      )
  );

  const onSubmit = async (resultForm: ResultForm) => {
    const dataToSend: PostContactFormProps = {
      client: {
        email: resultForm.email,
        nom: resultForm.lastName,
        numeroTelephone: resultForm.phone,
        prenom: resultForm.firstName
      },
      informations: {
        codePostal: resultForm.postCode,
        dateLimiteConsommation: resultForm.expirationDate,
        identifiantProduit: resultForm.barCodeOfProduct,
        nomOperation: resultForm.operationName,
        nomProduit: resultForm.productAndBrandName,
        numeroCarteFID: resultForm.cardFidNumber,
        numeroCommande: resultForm.orderNumber,
        numeroLot: resultForm.batchNumber,
        ville: resultForm.city
      },
      reponseCaptcha: 'unused and to be deleted by service',
      sujetDemande: resultForm.ordersType,
      theme: t(formTemplate.label),
      typeDemande: orderTypeSelected?.label ? t(orderTypeSelected.label) : '',
      detailDemande: resultForm.orderDetail
    };
    const resApi: boolean = await consumers.postContactForm(
      dataToSend,
      resultForm.files
    );
    if (resApi) {
      window.location.href = '/help-and-contact/confirmation';
    }
  };

  return (
    <div className="formContactUs">
      {formTemplate?.displayTitle && (
        <Title
          tag="h2"
          label={t(formTemplate.label)}
          className="formContactUs__title"
        />
      )}
      <form
        className={classnames(
          'formContactUs__content',
          'container',
          'container--md'
        )}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Title
          tag="h4"
          label={t('formContactUs.contact.title')}
          className="formContactUs__content__title"
        />
        {formTemplate.fields.map((field: FieldFormProps) => {
          const isMandatoryField = schema[field.name]?.exclusiveTests?.required;
          return (
            <div key={field.name}>
              <RenderField
                field={field}
                register={register}
                errors={errors}
                isMandatoryField={isMandatoryField}
                setValue={setValue}
                orderTypeSelected={orderTypeSelected}
                setOrderType={setOrderTypeSelected}
              />
            </div>
          );
        })}
        <Button
          type="submit"
          className="formContactUs__content__button--submit"
          color={isRequiredFieldsFulfilled ? 'primary' : 'secondary'}
          label={t('form.button.send')}
          disabled={!isRequiredFieldsFulfilled}
        />
      </form>
    </div>
  );
};

export default FormContactUs;
