import React, { useEffect, MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CLOSE_NAV, TOGGLE_NAV_DESCUBRA_ACTIVE } from '@/store/nav/navActions';
import { NavStateType } from '@/store/nav/navReducer';
import useClickOutWithExcludedElement from '@/lib/hooks/useClickOutWithExcludedElement';
import CommonSprite from '@/assets/images/sprites/common.svg';
import t from '@/lib/i18n';
import classnames from 'classnames';
import './style.scss';

const DescubraContainer = () => {
  const dispatch = useDispatch();

  const [clickOutRef, clickOutHandler] = useClickOutWithExcludedElement(
    '#toggleNavigationDescubraButton'
  );
  const isDescubraActive = useSelector(
    ({ nav }: { nav: NavStateType }) => nav.isDescubraActive
  );

  const toggleActive = () => {
    dispatch({ type: TOGGLE_NAV_DESCUBRA_ACTIVE });
  };

  useEffect(() => {
    document.documentElement.style.overflow = isDescubraActive
      ? 'hidden'
      : 'visible';
  }, [isDescubraActive]);

  clickOutHandler(() => {
    if (!isDescubraActive) return;
    dispatch({ type: CLOSE_NAV });
  });

  const onNavClick = (event: MouseEvent) => {
    if (!event) return;
    const clickedElement = event.target as HTMLElement;
    const isOverlayClicked =
      clickedElement?.classList.contains('descubra__content') ||
      clickedElement?.classList.contains('descubra__columns');
    if (isOverlayClicked) {
      toggleActive();
    }
  };

  return (
    <div
      ref={clickOutRef}
      className={classnames({
        descubra: true,
        'descubra--isActive': isDescubraActive
      })}
      id="vertical-navigation"
      onClick={onNavClick}
    >
      <div className="descubra__content">
        <nav className="descubra__navigation" role="navigation">
          <div className="descubra__header">
            <button
              type="button"
              aria-label="TOGGLE NAV HOME DESCUBRAR ACTIVE"
              className="descubra__header__closeButton"
              onClick={toggleActive}
            >
              <svg width="10px" height="10px">
                <use xlinkHref={`${CommonSprite}#cross-light`} />
              </svg>
            </button>
            <div onClick={toggleActive} className="descubra__header__title">
              {t('header.close')}
            </div>
          </div>
          <div className="descubra__columns">
            <ul className="descubra__list" ref={clickOutRef}>
              <li className="descubra__item">
                <a
                  className="descubra__link descubra__link__border"
                  href="https://www.intermarche.pt/porsi/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="descubra__link__label">
                    {t('header.porsi')}
                  </span>
                </a>
              </li>
              <li className="descubra__item">
                <a
                  className="descubra__link descubra__link__border"
                  href="https://www.intermarche.pt/programa-origens/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="descubra__link__label">
                    {t('header.programa_origens')}
                  </span>
                </a>
              </li>
              <li className="descubra__item">
                <a
                  className="descubra__link descubra__link__border"
                  href="https://www.intermarche.pt/receitas/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="descubra__link__label">
                    {t('header.receitas')}
                  </span>
                </a>
              </li>
              <li className="descubra__item">
                <a
                  className="descubra__link descubra__link__border"
                  href="https://www.intermarche.pt/combustiveis-intermarche/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="descubra__link__label">
                    {t('header.combustiveis')}
                  </span>
                </a>
              </li>
              <li className="descubra__item">
                <a
                  className="descubra__link descubra__link__noborder"
                  href="https://www.intermarche.pt/amiga-me/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="descubra__link__label">
                    {t('header.amigame')}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default DescubraContainer;
