import { NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams) => {
  const props = {
    title: data.title ?? null,
    buttonLabel: data.buttonText,
    shopId: data.idBoutique,
    isProducteursDIci: data.isProducteursDIci ?? null
  };

  return {
    type: 'organisms',
    name: 'productSlider',
    esi: {
      enabled: true,
      id: `productSlider_${data.idBoutique}`
    },
    props
  };
};

export default normalize;
