import React, { useState, useEffect } from 'react';
import './style.scss';
import classNames from 'classnames';
import t from '@/lib/i18n';
import Date from '@/lib/utils/date';
import { ReactComponent as InfoIcon } from '@/assets/images/icons/info.svg';
import { ReactComponent as ArrowIcon } from '@/assets/images/icons/arrow-down.svg';
import { ReactComponent as CrossIcon } from '@/assets/images/icons/cross.svg';
import ReactTooltip from 'react-tooltip';
import { SlotSchedule } from '@/lib/model/slot';
import DriveSprite from '@/assets/images/sprites/pdv.svg';
import PdvApi from '@/lib/api/pdv';
import usePdv from '@/lib/hooks/usePdv';

export type SlotProps = {
  date: string;
  type: string;
  time?: SlotSchedule;
  status: string;
};

export type SlotsBannerProps = {
  slots?: Array<SlotProps>;
};

const SlotsBanner = ({
  slots: initialSlots
}: SlotsBannerProps): JSX.Element => {
  const [state, setState] = useState({
    toolTipIsLoaded: false,
    navOpen: false,
    isDisplayed: true,
    slots: initialSlots
  });

  const { slots } = state;

  useEffect(() => {
    (async () => {
      const { ref } = usePdv();
      const data = await PdvApi.getPdvInfo(ref);
      const slotsTypes = slots?.map(({ type }) => type);
      const pdvTypes = data?.types;
      if (
        slots &&
        slotsTypes &&
        pdvTypes &&
        slotsTypes.length !== pdvTypes.length
      ) {
        const completeSlots = pdvTypes
          .filter((slot) => {
            return !slotsTypes.includes(slot);
          })
          .map((slot) => {
            return {
              type: slot,
              date: Date().add(7, 'days').format(),
              status: 'COMPLET'
            };
          });
        setState((s) => ({ ...s, slots: [...slots, ...completeSlots] }));
      }
    })();
  }, [slots]);

  useEffect(() => {
    setState((oldState) => ({
      ...oldState,
      toolTipIsLoaded: true
    }));
  }, []);

  const formatTime = (hour: number, minutes: number) => {
    return `${Date().hour(hour).format('HH')}h${Date()
      .minute(minutes)
      .format('mm')}`;
  };

  const nextSchedulingHours = (time: SlotSchedule) => {
    return `${formatTime(time.begin.hour, time.begin.minute)} - ${formatTime(
      time.end.hour,
      time.end.minute
    )}`;
  };

  return (
    <>
      {state.isDisplayed && (
        <div className="slotsBanner">
          <div
            className={classNames({
              slotsBanner__links: true,
              'slotsBanner__links--open': state.navOpen,
              'slotsBanner__links--closed': !state.navOpen
            })}
          >
            <div className="slotsHeading">
              <div className="intro">
                <div className="intro__title">
                  <div className="text">{t('pdv.slots.title')}</div>
                  <div className="tooltip">
                    <InfoIcon
                      width={15}
                      height={15}
                      fill="#e2001a"
                      data-tip
                      data-for="tooltip-home-slots"
                    />
                    {state.toolTipIsLoaded && (
                      <ReactTooltip
                        className="slotTooltip"
                        place="top"
                        effect="solid"
                        type="dark"
                        id="tooltip-home-slots"
                        textColor="white"
                      >
                        {t('pdv.slots.tooltip.message')}
                      </ReactTooltip>
                    )}
                  </div>
                </div>
                <div className="intro__schedule">
                  {t('pdv.schedule.nextDate')}
                </div>
              </div>
              <div
                onClick={() =>
                  setState((oldState) => ({
                    ...oldState,
                    navOpen: !state.navOpen
                  }))
                }
                className="iconWrapper"
              >
                <ArrowIcon width={20} height={20} fill="#e2001a" />
              </div>
            </div>
            <div className="slotsContent">
              <div className="slotList">
                {state.slots &&
                  state.slots.map(({ date, type, time, status }) => (
                    <div key={`slot--${status}--${type}`}>
                      {status === 'INDISPONIBLE' && (
                        <div className="unavaibleSlot">
                          {t('pdv.slots.unavailable')}
                        </div>
                      )}
                      {status === 'DISPONIBLE' && (
                        <div className="slot">
                          <div className="slot__icon">
                            <svg width="25" height="25" className="fill--black">
                              <use xlinkHref={`${DriveSprite}#${type}`} />
                            </svg>
                          </div>
                          <div className="slot__content">
                            <div className="slot__title">
                              {Date(date).format('dddd DD MMMM')}
                            </div>
                            <div className="slot__text">
                              {time && nextSchedulingHours(time)}
                            </div>
                          </div>
                        </div>
                      )}
                      {status === 'COMPLET' && (
                        <div className="slot" key={`slots--${type}`}>
                          <div className="slot__icon">
                            <svg width="25" height="25" className="fill--black">
                              <use xlinkHref={`${DriveSprite}#${type}`} />
                            </svg>
                          </div>
                          <div className="slot__content">
                            <div className="slot__title">
                              {t('pdv.slots.complete.title')}
                            </div>
                            <div className="slot__text">
                              {t(`pdv.slots.complete.date`, {
                                '%date%': Date(date).format('dddd DD MMMM')
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="btnWrapper">
              <CrossIcon
                height="20"
                width="20"
                onClick={() =>
                  setState((oldState) => ({
                    ...oldState,
                    isDisplayed: !state.isDisplayed
                  }))
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SlotsBanner;
