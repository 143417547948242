import React from 'react';
import './style.scss';
// import Link from '@/components/atoms/link';
// import Title from '@/components/atoms/title';
// import CgvFaqSprite from '@/assets/images/sprites/sav.svg';
// import t from '@/lib/i18n';

export type SavSubBannerProps = {
  href: string;
};

const SavSubBanner = ({ href }: SavSubBannerProps) => (
  <div className="subBanner">
    {/* <svg width="12" height="12">
      <use xlinkHref={`${CgvFaqSprite}#arrow_left`} />
    </svg>
    <Link
      label={t('common.back')}
      className="subBanner__backWrapper__link--back"
      href={href}
    /> */}
    {/* <Title
      className="subBanner__backWrapper__title"
      label={t('sav.subBanner.title')}
      tag="h2"
    /> */}
  </div>
);

export default SavSubBanner;
