import React, { useEffect, useState } from 'react';
import SecurityLayout from '@/components/organisms/securityLayout';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import AccountApi from '@/lib/api/account';
import { AuthStateType } from '@/store/auth/authReducer';
import { ReactComponent as Spinner } from '@/assets/images/icons/spinner.svg';
import classnames from 'classnames';
import Title from '@/components/atoms/title';
import Paragraph from '@/components/atoms/paragraph';
import Link from '@/components/atoms/link';
import t from '@/lib/i18n';
import './style.scss';

export type Context = {
  apiCalled: boolean;
  redirectLink: string;
  title: string;
  text1: string;
  text2: string;
  redirectLinlText: string;
};

const ContentChangeEmailConf = () => {
  const [context, setContext] = useState<Context>({
    apiCalled: false,
    redirectLink: '/home',
    title: '',
    text1: '',
    text2: '',
    redirectLinlText: ''
  });
  const router = useRouter();
  const token = router?.query['token'] as string;
  const user = useSelector(({ auth }: { auth: AuthStateType }) => auth.user);
  const isAuthenticated = !!user;

  useEffect(() => {
    (async () => {
      const resultApi = await AccountApi.newEmailConfirmation(token);
      setContext({
        apiCalled: true,
        redirectLink:
          resultApi || isAuthenticated ? '/home' : '/connection?redirect=/home',
        redirectLinlText:
          !resultApi || isAuthenticated
            ? 'error.backToHome'
            : 'header.user.connexion',
        title: `myAccount.emailChange.title.${resultApi ? 'success' : 'error'}`,
        text1: `myAccount.emailChange.text1.${resultApi ? 'success' : 'error'}`,
        text2: resultApi
          ? 'myAccount.emailChange.text2.success'
          : 'error.pleaseTryLater'
      });
    })();
  }, [token, isAuthenticated]);

  return (
    <SecurityLayout>
      <>
        <div
          className={classnames('changeEmailConf__wrapper', {
            'changeEmailConf__wrapper--visible': context.apiCalled
          })}
        >
          <Title
            tag="h2"
            label={t(context.title)}
            className="changeEmailConf__wrapper__title"
          />
          <Paragraph
            label={t(context.text1)}
            className="changeEmailConf__wrapper__text"
          />
          <Paragraph
            label={t(context.text2)}
            className="changeEmailConf__wrapper__text"
          />
          <div className="changeEmailConf__wrapper__linkWrapper">
            <Link
              href={context.redirectLink}
              label={t(context.redirectLinlText)}
              className="changeEmailConf__wrapper__linkWrapper__link"
            />
          </div>
        </div>
        {!context.apiCalled && (
          <Spinner className="changeEmailConf__wrapper__loader" />
        )}
      </>
    </SecurityLayout>
  );
};

export default ContentChangeEmailConf;
