import getConfigValue from '@/lib/config';
import { PdvCookie } from '@/lib/model/pdv';
import Cookie from 'js-cookie';
import { Redirect } from 'next';

export type ReturnType = {
  ref: string;
  isEcommerce: boolean;
  isVirtual: boolean;
  redirect: Redirect;
  name: string;
};

const getPdv = (pdvCookie?: PdvCookie | string): ReturnType => {
  const pdvCookieValue =
    typeof pdvCookie === 'string' ? JSON.parse(pdvCookie) : pdvCookie;

  const pdv = pdvCookieValue ?? getPdvCookie();

  return {
    ref: pdv?.ref ?? getConfigValue('VIRTUAL_PDV_REF', '00000').toString(),
    isEcommerce: pdv?.isEcommerce ?? false,
    isVirtual: !pdv?.ref,
    name: pdv?.name ? decodeURIComponent(pdv.name) : '',
    redirect: {
      statusCode: 303,
      destination: '/'
    }
  };
};

export const getPdvRef = () => {
  const pdv = getPdvCookie();

  return pdv?.ref ?? undefined;
};

const getPdvCookie = () => {
  const pdvCookie = Cookie.get('itm_pdv');

  let data;

  try {
    data = pdvCookie ? JSON.parse(pdvCookie) : undefined;
  } catch (error) {
    data = undefined;
  }

  return data;
};

export default getPdv;
