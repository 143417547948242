import React, { useEffect, useState } from 'react';
import Title from '@/components/atoms/title';
import './style.scss';
import t from '@/lib/i18n';
import DriveSprite from '@/assets/images/sprites/pdv.svg';
import Date from '@/lib/utils/date';
import { SlotSchedule } from '@/lib/model/slot';
import Link from '@/components/atoms/link';
import PdvApi from '@/lib/api/pdv';

export type SlotProps = {
  date: string;
  type: string;
  time?: SlotSchedule;
  status: string;
};

export type SlotsProps = {
  slots?: SlotProps[];
  pdvRef: string;
};

const SlotPdv = ({ pdvRef, slots: initialSlots }: SlotsProps) => {
  const [slots, setSlots] = useState(initialSlots);

  const formatTime = (hour: number, minutes: number) => {
    return `${Date().hour(hour).format('HH')}h${Date()
      .minute(minutes)
      .format('mm')}`;
  };

  const nextSchedulingHours = (date: string, time: SlotSchedule) => {
    return `${Date(date).format('dddd DD MMMM')} ${formatTime(
      time.begin.hour,
      time.begin.minute
    )}-${formatTime(time.end.hour, time.end.minute)}`;
  };

  useEffect(() => {
    (async () => {
      const data = await PdvApi.getPdvInfo(pdvRef);
      const slotsTypes = slots?.map(({ type }) => type);
      const pdvTypes = data?.types;

      if (
        slots &&
        slotsTypes &&
        pdvTypes &&
        slotsTypes.length !== pdvTypes.length
      ) {
        const completeSlots = pdvTypes
          .filter((slot) => {
            return !slotsTypes.includes(slot);
          })
          .map((slot) => {
            return {
              type: slot,
              date: Date().add(7, 'days').format(),
              status: 'COMPLET'
            };
          });
        setSlots([...slots, ...completeSlots]);
      }
    })();
  }, [slots, pdvRef]);

  return (
    <div className="driveScheduling">
      <Title label={t('pdv.schedule.title')} tag="h2" />
      <div className="driveScheduling__content">
        {slots &&
          slots.map(({ date, type, time, status }) => {
            if (!['DISPONIBLE', 'COMPLET'].includes(status)) return null;

            return (
              <div
                key={`driveScheduling__item-${type}`}
                className="driveScheduling__item"
              >
                <div className="driveScheduling__icon">
                  <svg className="fill--black">
                    <use xlinkHref={`${DriveSprite}#${type}`} />
                  </svg>
                </div>
                <div className="driveScheduling__description">
                  <div className="driveScheduling__title">
                    {t(`pdv.type.${type}`)}
                  </div>
                  <div className="driveScheduling__paragraph">
                    {t('pdv.schedule.nextDate')}
                  </div>

                  <div className="driveScheduling__schedule">
                    {status === 'DISPONIBLE' &&
                      time &&
                      nextSchedulingHours(date, time)}
                    {status === 'COMPLET' &&
                      t(`pdv.slots.complete.all`, {
                        '%date%': Date(date).format('dddd DD MMMM')
                      })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div>
        <Link href="/home" label={t('pdv.schedule.cta')} button />
      </div>
    </div>
  );
};

export default React.memo(SlotPdv);
