import React from 'react';
import PdvPageSprite from '@/assets/images/sprites/pdvServices.svg';
import './style.scss';

export type ServiceProps = {
  id: string;
  label: string;
  description: string;
  className?: string;
};

const Service = ({ id, label, description }: ServiceProps) => {
  return (
    <div className="pdvServicesItem">
      <div className="pdvServicesItem__left">
        <div className="pdvServicesItem__picto">
          <svg width="40" height="40">
            <use xlinkHref={`${PdvPageSprite}#${id}`} />
          </svg>
        </div>
      </div>
      <div className="pdvServicesItem__right">
        <h3 className="pdvServicesItem__title">{label}</h3>
        <p className="pdvServicesItem__description">{description}</p>
      </div>
    </div>
  );
};

export default Service;
