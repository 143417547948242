import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import Slot from '@/lib/model/slot';

class SlotsPublicApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi']);
  }

  getPdvSlots = async (ref: string): Promise<Array<Slot>> => {
    let response = null;

    try {
      response = await this.axios.get(
        `/creneaux/v1/pdvs/${ref}/prochains-creneaux`
      );
    } catch (error: any) {
      logger.info({
        message: 'Unable to fetch next slots',
        error,
        context: {
          pdvRef: ref
        }
      });
    }

    const data = response?.data ?? [];
    const slots: Array<Slot> = [];

    data.forEach((slot: any) => {
      slots.push(new Slot(slot));
    });

    return slots;
  };
}

export default new SlotsPublicApi();
