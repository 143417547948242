import React from 'react';
import t from '@/lib/i18n';
import Button from '@/components/atoms/button';
import classnames from 'classnames';
import {
  StateType,
  ServiceProvidersType,
  ProviderType
} from '@/store/cart/types';
import { SLOT_TYPE_MAPPING, getMkpDeliveryServiceSvg } from '@/lib/model/slot';
import { formattedLeadTimeToShip } from '@/lib/utils/providerSelect';
import './style.scss';

export type ProviderBlocProps = {
  provider: ServiceProvidersType;
  cart: StateType;
  selectProvider: (data: {
    selectedProvider: ProviderType | ServiceProvidersType | null;
    packageId?: string;
  }) => void;
  sellerId: string;
  packageId: string;
};

const ProviderBloc = ({
  provider,
  cart,
  selectProvider,
  sellerId,
  packageId
}: ProviderBlocProps): JSX.Element => {
  const isITM = sellerId === 'ITM';
  const selectedProviderITM =
    cart.slots.selectedSlot.slot?.providers?.find(
      (it) => it.id === provider.id
    ) || null;
  const { housingExtraCostAvailable, VolumeExtraCostAvailable } =
    cart.slots.list[SLOT_TYPE_MAPPING.DOMICILE] || {};
  const { housingExtraTax = 0, volumeExtraTax = 0 } =
    selectedProviderITM?.deliveryCost || {};
  const boolMontantSurcoutHabitationActif =
    housingExtraCostAvailable && housingExtraTax > 0;
  const boolMontantSurcoutVolumeActif =
    VolumeExtraCostAvailable && volumeExtraTax > 0;
  return (
    <div
      onClick={() =>
        selectProvider({
          selectedProvider: isITM ? selectedProviderITM : provider,
          packageId
        })
      }
      className={classnames('deliveryPage__product', {
        'deliveryPage__product--active':
          cart.slots.selectedProvider?.id === provider.id ||
          cart.mkpPackages?.[sellerId]?.packages[packageId]?.selectedProvider
            ?.id === provider.id,
        'deliveryPage__product--margin': !isITM
      })}
    >
      {!isITM && getMkpDeliveryServiceSvg({ id: provider.id || '' })}
      {isITM && (
        <img
          className="deliveryPage__picto"
          alt={provider.desktopTitle}
          src={provider.logo}
        />
      )}
      {!isITM && (
        <p className="providerBloc__mkp--title">
          {t(`mkp.deliveryService.${provider.id}`)}
        </p>
      )}
      {isITM && (
        <p className="deliveryPage__text">{provider.descriptionDesktop}</p>
      )}
      {!isITM && provider.leadTimeToShip && (
        <>
          <p className="providerBloc__mkp--text">
            {t('mkp.deliveryService.deliverFrom')}
            <span className="providerBloc__mkp--date">{` ${formattedLeadTimeToShip(
              provider.totalDeliveryTime as number
            )}`}</span>
          </p>
        </>
      )}
      <Button
        label={
          (provider.deliveryCost || 0) > 0
            ? `${t('delivery.product.fees', {
                '%price%': (provider.deliveryCost || 0)
                  .toFixed(2)
                  .replace('.', ',')
              })}
                                ${
                                  boolMontantSurcoutHabitationActif ||
                                  boolMontantSurcoutVolumeActif
                                    ? t('delivery.asterix')
                                    : ''
                                }
                                `
            : t('cart.slot.fees.free')
        }
        className="deliveryPage__fees"
      />
      <div className="deliveryPage__extraCostExplanation">
        {(boolMontantSurcoutHabitationActif || boolMontantSurcoutVolumeActif) &&
          t('delivery.asterix')}
        {boolMontantSurcoutHabitationActif &&
          t('delivery.surcoutExplanationHabitation', {
            '%fraisHabitation%': housingExtraTax?.toFixed(2)?.replace('.', ',')
          })}
        {boolMontantSurcoutHabitationActif &&
          boolMontantSurcoutVolumeActif &&
          t('delivery.and')}
        {boolMontantSurcoutVolumeActif &&
          t('delivery.surcoutExplanationVolume', {
            '%fraisVolume%': volumeExtraTax?.toFixed(2).replace('.', ',')
          })}
      </div>
    </div>
  );
};

export default ProviderBloc;
