import React, { memo } from 'react';
import FidelitySprite from '@/assets/images/sprites/carteFidelite.svg';
import CartSprite from '@/assets/images/sprites/cart.svg';
import ReactTooltip from 'react-tooltip';
import t from '@/lib/i18n';

type TooltipLineProps = {
  label: string;
  image?: string;
};

type InfoTooltipProps = {
  pdvIsActivated?: number;
  tooltipId: string;
};

const TooltipLine = ({ image, label }: TooltipLineProps) => {
  return (
    <div className="operation__tooltip_line">
      {image && (
        <div className="operation__tooltip_svg">
          <svg width={20} height={20}>
            <use xlinkHref={image} />
          </svg>
        </div>
      )}
      <div className="operation__tooltip_text">
        <span>{label}</span>
      </div>
    </div>
  );
};

const getTooltipLines = (pdvIsActivated?: number) => {
  switch (pdvIsActivated) {
    case 1:
      return (
        <TooltipLine
          label={t(
            'myAccount.fidelity.myStickers.operations.tooltip.pdvActivated'
          )}
        />
      );
    case 0:
      return (
        <TooltipLine
          label={t(
            'myAccount.fidelity.myStickers.operations.tooltip.pdvDisactivated'
          )}
        />
      );
    default:
      return (
        <>
          <div className="operation__tooltip_title">
            {t(
              'myAccount.fidelity.myStickers.operations.countDownTooltip.title'
            )}
          </div>
          <TooltipLine
            image={`${FidelitySprite}#operation-clock-green`}
            label={t(
              'myAccount.fidelity.myStickers.operations.countDownTooltip.green'
            )}
          />
          <TooltipLine
            image={`${FidelitySprite}#operation-clock-orange`}
            label={t(
              'myAccount.fidelity.myStickers.operations.countDownTooltip.orange'
            )}
          />
          <TooltipLine
            image={`${FidelitySprite}#operation-clock-red`}
            label={t(
              'myAccount.fidelity.myStickers.operations.countDownTooltip.red'
            )}
          />
        </>
      );
  }
};

const InfoTooltip = ({ pdvIsActivated, tooltipId }: InfoTooltipProps) => (
  <>
    <div className="operation__body_vignette--info">
      <svg
        width={15}
        height={15}
        data-tip
        data-for={tooltipId}
        data-place="left"
        data-event-off="mouseleave"
        data-event="mouseenter click"
      >
        <use xlinkHref={`${CartSprite}#info`} />
      </svg>
    </div>
    <ReactTooltip id={tooltipId} className="operation__tooltip">
      {getTooltipLines(pdvIsActivated)}
    </ReactTooltip>
  </>
);

export default memo(InfoTooltip);
