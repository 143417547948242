import { normalize as BaseNormalize, NormalizeParams, Component } from '..';

const normalize = ({ data, includes }: NormalizeParams) => {
  const components: Array<Component> = [];

  data.blocs.forEach((bloc: any) => {
    const blocData = includes[bloc.sys.id] || null;

    if (blocData) {
      const elt = BaseNormalize({ data: blocData, includes });

      if (elt) {
        components.push(elt);
      }
    }
  });

  return {
    type: 'templates',
    name: 'homepage/iteration',
    props: {
      blocks: components
    }
  };
};

export default normalize;
