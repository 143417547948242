import Link from '@/components/atoms/link';
import LoadableContent from '@/components/atoms/loadableContent';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import ShoppingList from '@/components/organisms/myAccount/shoppingList';
import shoppingListApi, { List } from '@/lib/api/shoppingList';
import { Content as ContentfulContent } from '@/lib/contentful';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import ProductModel from '@/lib/model/product';
import { Meta } from '@/lib/model/productList';
import { notification } from '@/lib/notification';
import { AuthStateType } from '@/store/auth/authReducer';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import './style.scss';

export type MyShoppingListDetailProps = {
  listId: number;
  footer: ContentfulContent;
  pageView: string;
};

type StateType = {
  isLoading: boolean;
  name: string;
  products: Array<ProductModel>;
  meta: Meta;
  page: number;
};

const MyShoppingListDetail = ({
  listId,
  footer,
  pageView
}: MyShoppingListDetailProps) => {
  // TODO :   const userId = useShallowEqualSelector(selectUserId);
  const { userId } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      userId: auth.user?.id
    };
  }, shallowEqual);

  const [state, setState] = useState<StateType>({
    isLoading: true,
    name: '',
    products: [],
    meta: {
      total: 0,
      page: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      perPage: 40
    },
    page: 1
  });

  useEffect(() => {
    (async () => {
      const { ref } = usePdv();

      if (!userId || !ref) {
        return;
      }

      const data = await Promise.all([
        shoppingListApi.list(userId),
        shoppingListApi.listProducts(userId, listId, ref, state.page)
      ]);

      const list = data[1];

      setState((oldState) => ({
        ...oldState,
        isLoading: false,
        name:
          data[0].find((elt: List) => {
            return elt.id === listId;
          })?.name ?? '',
        products: oldState.products.concat(list.products),
        meta: list.meta
      }));
    })();
  }, [userId, listId, state.page]);

  const deleteProducts = async (products: Array<ProductModel>) => {
    const { ref } = usePdv();

    if (!userId || !ref) {
      return;
    }
    setState((oldState) => ({
      ...oldState,
      isLoading: true
    }));

    let productsInList = [...state.products];

    try {
      await shoppingListApi.deleteProducts(userId, listId, ref, products);
      productsInList = (await shoppingListApi.listProducts(userId, listId, ref))
        .products;
    } catch (error: any) {
      notification.error(t('notification.erreur'));
    }

    setState((oldState) => ({
      ...oldState,
      isLoading: false,
      products: productsInList
    }));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 0);
    setState((oldState) => ({
      ...oldState,
      isLoading: true,
      page: oldState.page + 1
    }));
  };

  const contentPart = (
    <>
      <LoadableContent className="shoppingListDetail" loading={state.isLoading}>
        <ShoppingList
          products={state.products}
          meta={state.meta}
          onDelete={(products) => deleteProducts(products)}
        />
      </LoadableContent>
      {state.meta.hasNextPage && (
        <Link
          onClick={(clickEvent) => {
            clickEvent.preventDefault();
            handleNextPage();
          }}
          button
          label={t('productList.next')}
        />
      )}
    </>
  );

  return (
    <MyAccountLayout
      enabledId="lists"
      pageView={pageView}
      title={state.name}
      content={contentPart}
      footer={footer}
      backTo={{
        label: t('header.lists.shopping_list'),
        href: '/account-management/my-lists/my-shopping-lists'
      }}
    />
  );
};

export default MyShoppingListDetail;
