import React, { useState } from 'react';
import './style.scss';
import classnames from 'classnames';

export type SwitchProps = {
  id: string;
  className?: string;
  checked?: boolean;
  onChange?: (key: boolean) => void;
};

const ToggleSwitch = ({ id, className, checked, onChange }: SwitchProps) => {
  const [state, setState] = useState(checked);
  return (
    <label htmlFor={id} className={classnames('toggleSwitch', className)}>
      <input
        className="toggleSwitch__input"
        onChange={() => {
          if (onChange) onChange(!state);
          setState((s) => !s);
        }}
        checked={state}
        id={id}
        name={id}
        type="checkbox"
      />
      <span className="toggleSwitch__slider toggleSwitch__slider--round" />
    </label>
  );
};

export default ToggleSwitch;
