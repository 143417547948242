import { ReactComponent as AlcoholBanner } from '@/assets/images/icons/alcohol-banner.svg';
import { ReactComponent as Arrow } from '@/assets/images/icons/arrow-right.svg';
import CommonSprite from '@/assets/images/sprites/common.svg';
import NutriscoreSprite from '@/assets/images/sprites/nutriscore.svg';
import PdpSprite from '@/assets/images/sprites/pdp.svg';
import CartSprite from '@/assets/images/sprites/cart.svg';
import Button from '@/components/atoms/button';
import Highlight from '@/components/atoms/highlight';
import Image from '@/components/atoms/image';
import Link from '@/components/atoms/link';
import PromotionComponent from '@/components/atoms/promotion';
import QuantityDiscount from '@/components/atoms/quantityDiscount';
import ScoreInit from '@/components/atoms/scoreInit';
import AddToCart from '@/components/molecules/addCartButton';
import RatingScore from '@/components/molecules/ratingScore';
import Lot from '@/components/organisms/lot';
import LayerList from '@/components/organisms/product/layerList';
import SoldByName from '@/components/organisms/product/soldByName';
import RecommendationProduct from '@/components/organisms/recommendation';
import PromoDescription from '@/components/templates/pdp/content/promoDescriptions';
import categoriesApi from '@/lib/api/categories';
import getConfigValue from '@/lib/config';
import { useDidomi } from '@/lib/hooks/useDidomi';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import ProductModel from '@/lib/model/product';
import { Offer, PromotionType, SmartConso } from '@/lib/model/productTypes';
import { StateType as CartStateType } from '@/store/cart/types';
import classnames from 'classnames';
import Router from 'next/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FrancoScore from '@/components/organisms/product/francoScore';
import ProductSLider from '../product_slider';
import ProductState from './productState';
import ShippingModes from './shippingModes';

export type DetailProps = {
  data: ProductModel;
  currentOffer?: Offer;
  innitScore?: SmartConso;
  anchorLabel: (label: string) => void;
};

type BreadcrumbItem = {
  slug: string | null;
  title: string;
  link?: string;
};

const Detail = ({
  data,
  currentOffer,
  innitScore,
  anchorLabel
}: DetailProps) => {
  // TODO : const { subCarts } = useShalloEqualSelector(useCart);
  const { subCarts } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      subCarts: cart.subCarts
    };
  });

  const isEmerchEnabled = getConfigValue(
    'IS_EMERCH_ENABLED',
    false
  ).toBoolean();

  const [lotIsVisible, setLotIsVisible] = useState(false);
  const [listLayer, setListLayer] = useState(false);
  const { informations, type, id, repairIndex, energyClass } = data;
  const { data: isSelectPersonalizedContent } = useDidomi(
    'select_personalized_content'
  );

  let promotions = data?.promotions;
  if (data.type === 'MKP') {
    promotions = currentOffer?.promotions || [];
  }
  const quantityPromo = promotions.find(
    (promo) => promo.type === 'quantityDiscount'
  );
  const highlightPromo = informations.highlight?.endDate
    ? { type: 'highlightDiscount', endDate: informations.highlight?.endDate }
    : null;

  const [cartItemQty, setCartItemQty] = useState(0);

  const [breadcrumbItems, setBreadcrumbItems] = useState<
    Array<BreadcrumbItem>
  >();

  useEffect(() => {
    const cartItem = subCarts.find((sub) => sub.seller.sellerId === 'ITM')
      ?.items[id];
    setCartItemQty(cartItem?.qty || 0);
  }, [setCartItemQty, id, subCarts]);

  useEffect(() => {
    (async () => {
      const { ref } = usePdv();

      let department;
      let familly;

      const univers = await categoriesApi.findUniversById(data.universId, ref);

      const breadcrumbItemsData: Array<BreadcrumbItem> = [
        { title: t('common.home'), link: '/', slug: 'accueil' }
      ];

      if (data.departmentId && univers && univers.data) {
        // univers
        breadcrumbItemsData.push({
          title: univers.title,
          slug: univers.data.slug
        });

        department = univers.data.children.find(
          (cat) => cat.id === `${data.departmentId}`
        );
        if (department) {
          // rayon
          breadcrumbItemsData.push({
            title: department.title,
            link: department.link,
            slug: department.slug
          });

          // because business said no go for universes' pages
          breadcrumbItemsData[1].link = department.link;

          if (data.famillyId) {
            familly = department.children.find(
              (cat) => cat.id === `${data.famillyId}`
            );
            // famille
            if (familly) {
              breadcrumbItemsData.push({
                title: familly.title,
                link: familly.link,
                slug: familly.slug
              });
            }
          }
        }

        breadcrumbItemsData.push({
          title: data.informations.title,
          slug: data.universId
        });
      }

      setBreadcrumbItems(breadcrumbItemsData);
    })();
  }, [
    data.departmentId,
    data.famillyId,
    data.informations.title,
    data.universId
  ]);

  const prices =
    currentOffer && data.type !== 'PDV'
      ? {
          ...data.prices,
          productPrice: currentOffer.price,
          crossedOutPrice: currentOffer?.crossedOutPrice
        }
      : data.prices;

  return (
    <div className="productDetail__detail container">
      <div className="productDetail__pdpBreadcrumb">
        {breadcrumbItems &&
          breadcrumbItems.map((item, i) => {
            return (
              <React.Fragment key={item.slug}>
                <Link
                  href={item.link}
                  disabled={!item.link}
                  className={classnames('productDetail__pdpBreadcrumb__item', {
                    'productDetail__pdpBreadcrumb__item--last':
                      i === breadcrumbItems.length - 1
                  })}
                >
                  {item.title}
                </Link>
                {i < breadcrumbItems.length - 1 && (
                  <Arrow className="breadcrumb__picto" width="11" height="11" />
                )}
              </React.Fragment>
            );
          })}
      </div>

      <div className="productDetail__heading">
        <button onClick={() => Router.back()} className="productDetail__back">
          <svg className="productDetail__back__icon" width="10" height="10">
            <use xlinkHref={`${CommonSprite}#arrow-left`} />
          </svg>
          {t('common.back')}
        </button>
      </div>
      <div className="productDetail__resume">
        <div className="productDetail__resume__media">
          {informations.image && (
            <div className="productDetail__resume__mediaWrapper">
              {listLayer && (
                <LayerList
                  dataProduct={data}
                  close={() => {
                    setListLayer(false);
                  }}
                />
              )}
              {informations.allImages && (
                <ProductSLider images={informations.allImages} />
              )}
              <div className="productDetail__smartConso">
                {innitScore && (
                  <div
                    onClick={() => {
                      const elt = document.querySelector(
                        `#nutrition`
                      ) as HTMLElement;

                      window.scrollTo({
                        top: elt.offsetTop - 100,
                        behavior: 'smooth'
                      });
                    }}
                  >
                    <ScoreInit
                      ratio={true}
                      scoreInit={innitScore.default}
                      rank={innitScore.rating.key}
                    />
                  </div>
                )}
                {informations.nutriscore && (
                  <div className="productDetail__smartConso__nutriscore">
                    <div>{t('pdp.nutrition.nutriscore')}</div>
                    <svg width="54px" height="100%">
                      <use
                        xlinkHref={`${NutriscoreSprite}#${informations.nutriscore}`}
                      />
                    </svg>
                  </div>
                )}
                <FrancoScore score={data.francoScore} />
                {energyClass && (
                  <div
                    onClick={() => {
                      const elt = document.querySelector(
                        `#toKnowMore`
                      ) as HTMLElement;
                      anchorLabel('energyClass');
                      window.scrollTo({
                        top: elt.offsetTop - 100,
                        behavior: 'smooth'
                      });
                    }}
                    className="productDetail__energyClass"
                  >
                    <svg width="54px" height="100%">
                      <use
                        xlinkHref={`${PdpSprite}#energyClass${energyClass.replaceAll(
                          '+',
                          ''
                        )}`}
                      />
                    </svg>
                  </div>
                )}
                {repairIndex?.value && (
                  <div
                    className="productDetail__repairIndex"
                    onClick={() => {
                      const elt = document.querySelector(
                        `#toKnowMore`
                      ) as HTMLElement;
                      anchorLabel('repairIndex');

                      window.scrollTo({
                        top: elt.offsetTop - 100,
                        behavior: 'smooth'
                      });
                    }}
                  >
                    <div className="productDetail__repairIndex__value">
                      {String(repairIndex.value).replace('.', ',')}
                    </div>
                    <svg width="54px" height="30px">
                      <use
                        xlinkHref={`${PdpSprite}#repairIndex-${repairIndex.id}`}
                      />
                    </svg>
                    <span className="productDetail__repairIndex__ten">
                      {t('product.ten')}
                    </span>
                    <span className="productDetail__repairIndex__indiceReparabilite">
                      {t('product.repairabilityIndex')}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="productDetail__resume__content">
          {type === 'PDV' && !listLayer && data.lot?.type !== 'PICNIC' && (
            <svg
              className="productDetail__shopList fill--red"
              onClick={() => setListLayer(!listLayer)}
              width="30"
              height="30"
            >
              <use xlinkHref={`${CommonSprite}#add-to-list`} />
            </svg>
          )}
          <div>
            {informations.brand && (
              <strong className="productDetail__brand">
                {informations.brand}
              </strong>
            )}
            <div className="productDetail__title">
              <h1 className="productDetail__main_title">
                {informations.title}
                {informations.category ? `, cat. ${informations.category}` : ''}
              </h1>
            </div>
            <div
              className="productDetail__description"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />

            {data.type === 'MKP' && (
              <ProductState currentOffer={currentOffer} />
            )}
            {informations.packaging && (
              <div className="productDetail__packaging">
                {informations.packaging}
              </div>
            )}
            {informations.hasAlcohol && (
              <div className="alcoholBanner">
                <AlcoholBanner />
              </div>
            )}
            {informations.flags.length > 0 && (
              <div className="productDetail__tags">
                {informations.flags.map((flag: string, index: number) => {
                  const key = `flag-${index}`;

                  return (
                    <Image
                      key={key}
                      src={flag}
                      alt=""
                      height={30}
                      width={30}
                      lazy={false}
                    />
                  );
                })}
              </div>
            )}
          </div>
          {data.lot && (
            <div className="productDetail__lot">
              <div className="productDetail__lot__heading">
                <strong className="productDetail__title">
                  {t('cart.lot.title', { '%qty%': data.lot.products.length })}
                </strong>
                <Button
                  onClick={() => {
                    setLotIsVisible((l) => !l);
                  }}
                  isLink={true}
                  label={t(lotIsVisible ? 'common.hidden' : 'common.show')}
                />
              </div>
              <div
                className={classnames('productDetail__lot__content', {
                  visible: lotIsVisible
                })}
              >
                <Lot product={data} cartItemQty={cartItemQty || 0} />
              </div>
            </div>
          )}
          {quantityPromo && (
            <QuantityDiscount
              id={data.id}
              qty={cartItemQty || 0}
              promotion={quantityPromo}
            />
          )}
          {informations.highlight && (
            <Highlight
              id={`highlight-${data.id}`}
              endDate={informations.highlight.endDate}
            />
          )}
          {promotions.length > 0 && (
            <div className="productDetail__discounts">
              {promotions.map((promo: PromotionType) => {
                const key = `${promo.type}-1`;
                return <PromotionComponent key={key} data={promo} />;
              })}
            </div>
          )}
          <div className="productDetail__footer">
            {isEmerchEnabled &&
              (!data.maxQty || !data.available) &&
              isSelectPersonalizedContent && (
                <div className="product__soon pdp">
                  {t('product.unavailable')}
                </div>
              )}
            {(prices.crossedOutPrice ||
              prices.productPrice ||
              !data.available) && (
              <div className="productDetail__actions">
                {data.maxQty && data.available ? (
                  <>
                    <div className="productDetail__prices">
                      {prices.crossedOutPrice && (
                        <span className="productDetail__crossedOutPrice">
                          {prices.crossedOutPrice?.integer},
                          {prices.crossedOutPrice?.decimal}
                          {prices.crossedOutPrice?.currency}
                        </span>
                      )}
                      {prices.productPrice && (
                        <div>
                          <span
                            className={classnames(
                              'productDetail__productPrice',
                              {
                                hasPromo:
                                  promotions.length > 0 ||
                                  highlightPromo ||
                                  prices.crossedOutPrice
                              }
                            )}
                          >
                            <span className="productDetail__integer">
                              {prices.productPrice?.integer}
                            </span>
                            ,{prices.productPrice?.decimal}{' '}
                            {prices.productPrice?.currency}
                          </span>
                          {prices.unitPrice && (
                            <span className="productDetail__unitPrice">
                              {prices.unitPrice?.integer},
                              {prices.unitPrice?.decimal}
                              {prices.unitPrice?.currency}
                            </span>
                          )}
                          {currentOffer?.ecoPart && (
                            <div className="productDetail__ecoPart">
                              <svg width={13} height={13}>
                                <use xlinkHref={`${CartSprite}#info`} />
                              </svg>
                              {t('pdp.informations.ecoPart', {
                                '%ecoPart%': currentOffer?.ecoPart
                              })}
                            </div>
                          )}
                          {data?.privateCopie && (
                            <div className="productDetail__privateCopie">
                              <svg width={13} height={13}>
                                <use xlinkHref={`${CartSprite}#info`} />
                              </svg>
                              {t('pdp.informations.privateCopie', {
                                '%privateCopie%': data?.privateCopie
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <AddToCart
                      product={data}
                      isPdp={true}
                      sellerId={currentOffer?.seller?.id}
                      itemParentId={currentOffer?.itemParentId}
                      currentOfferItemId={currentOffer?.itemId}
                    />
                  </>
                ) : (
                  <>
                    {isEmerchEnabled && (
                      <>
                        {data.type === 'PDV' && isSelectPersonalizedContent && (
                          <RecommendationProduct
                            productModel={data}
                            title={t('bottomProductsLayer.toReplace')}
                          />
                        )}

                        {data.type === 'MKP' ||
                          (data.type === 'PDV' &&
                            !isSelectPersonalizedContent && (
                              <div className="product__soon">
                                {t('product.unavailable')}
                              </div>
                            ))}
                      </>
                    )}
                    {!isEmerchEnabled && (
                      <div className="product__soon">{t('product.soon')}</div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          {(promotions.length || highlightPromo) && (
            <div className="productDetail__promotions">
              <PromoDescription
                data={promotions}
                hightlight={highlightPromo}
                prices={data.prices}
              />
            </div>
          )}
          {data.type === 'MKP' && currentOffer && (
            <div className="productDetail__extra">
              <div className="productDetail__sellerNameAndRating">
                <SoldByName seller={currentOffer.seller} enableClick />
                <div className="productDetail__ratingScore">
                  <RatingScore
                    score={currentOffer?.seller?.rating?.rate}
                    sellerUrl={currentOffer.seller.url}
                    sellerName={currentOffer.seller.name}
                    reviewsCount={currentOffer?.seller?.rating?.reviewsCount}
                    activeColor="#e2001a"
                  />
                </div>
              </div>
              <ShippingModes currentOffer={currentOffer} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Detail;
