import React from 'react';
import { Request, Response } from 'express';
import usePdv from '@/lib/hooks/usePdv';
import { MyStickersProps } from '../types';
import pilotPDVs from '../pilotPdvs.json';

import MyStickers from '../myStickers';

type InitialProps = {
  props: MyStickersProps;
  req: Request;
  res: Response;
};

export type ContentProps = {
  pdv?: string;
};

const Content = (props: any) => {
  if (!props.pdv) {
    return null;
  }

  const pdvIsActivated = pilotPDVs
    .map((pdvId) => parseInt(pdvId, 10))
    .includes(parseInt(props.pdv, 10));

  return <MyStickers pdvIsActivated={pdvIsActivated} />;
};

Content.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref } = usePdv(req);

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=10800, max-age=10800');
    }
    resolve({
      ...props,
      pdv: ref
    });
  });
};

export default Content;
