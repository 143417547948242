import React, { memo } from 'react';

import './style.scss';

export type MessageProps = {
  image: string;
  desc: string;
  title: string;
};

const Message = ({ image, desc, title }: MessageProps) => {
  return (
    <div className="message">
      <div className="message__image">
        <svg width="120" height="120">
          <use xlinkHref={image} />
        </svg>
      </div>
      <div className="message__title">
        <p>{title}</p>
      </div>
      <div className="message__desc">
        <p>{desc}</p>
      </div>
    </div>
  );
};

export default memo(Message);
