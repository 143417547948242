export const regexValidPhone =
  // eslint-disable-next-line max-len
  /^(?!\+?(\d)\1+$|\+?0123456|\+?1234567|\+?2345678|\+?3456789|\+?45678910|\+?5678910|\+?0987654|\+?9876543|\+?87654321|\+?7654321)\+?\d{7,15}$|^$/;

export const validatePhoneNumber = (
  numberType: string,
  getValues: (value: string) => string
) => ({
  requiredOnPhoneNumber: (value: string) => {
    return !!value || !!getValues(numberType);
  },
  invalidPhoneNumber: (value: string) => {
    if (numberType === 'phone' && !getValues('mobilePhone')) return true;
    return regexValidPhone.test(value) && getValues(numberType) !== value;
  }
});
