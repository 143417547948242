import React, { useState } from 'react';
import './style.scss';
import classnames from 'classnames';

export type TabsProps = {
  className?: string;
  tabs: Array<{ label: string; anchor: string }>;
};

const Tabs = ({ tabs, className }: TabsProps) => {
  const [activeAnchor, setActiveAnchor] = useState(tabs[0].anchor);

  return (
    <div className={classnames('tabs', { [`${className}`]: !!className })}>
      {tabs.map(({ label, anchor }) => (
        <button
          type="button"
          key={anchor}
          className={classnames('tabs__tab', {
            'tabs__tab--selected': anchor === activeAnchor
          })}
          onClick={() => {
            const elt = document.querySelector(`#${anchor}`) as HTMLElement;

            window.scrollTo({
              top: elt.offsetTop - 100,
              behavior: 'smooth'
            });

            setActiveAnchor(anchor);
          }}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
