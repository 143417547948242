import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';

type NewslettersStatus = { [key: string]: boolean };

const codes = ['1', '2', '3', '9'];

class NewsletterApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  getAllStatus = async (userId: string): Promise<NewslettersStatus> => {
    let response = null;
    const newsletters: NewslettersStatus = {};

    try {
      response = await this.axios.get(
        `/gestiondecompte/v1/moncompte/${userId}/newsletters`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to get newsletters status',
        error
      });
    }

    const data = response?.data.abonnements ?? [];

    data.forEach((elt: any) => {
      newsletters[elt.code.toString()] = elt.abo;
    });

    return newsletters;
  };

  update = async (
    userId: string,
    code: string,
    subscribe: boolean
  ): Promise<void> => {
    try {
      await this.axios.post(
        `/gestiondecompte/v1/moncompte/${userId}/newsletters`,
        {
          abonnements: [
            {
              code,
              abo: subscribe
            }
          ]
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to update a newsletter status',
        error
      });
    }
  };

  disableAll = async (userId: string): Promise<void> => {
    const data: Array<{
      code: string;
      abo: boolean;
    }> = [];

    codes.forEach((code: string) => {
      data.push({
        code,
        abo: false
      });
    });

    try {
      await this.axios.post(
        `/gestiondecompte/v1/moncompte/${userId}/newsletters`,
        {
          abonnements: data
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to disable all newsletters',
        error
      });
    }
  };
}

export default new NewsletterApi();
