import React from 'react';
import './style.scss';
import classnames from 'classnames';
import t from '@/lib/i18n';

const CartHelp = () => {
  return (
    <div className={classnames(['cartHelp'])}>
      <div className="cartHelp__content">
        <strong>{t('cart.help.title')}</strong>
        <p className="cartHelp__text">{t('cart.help.text')}</p>
      </div>
    </div>
  );
};

export default CartHelp;
