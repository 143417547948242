import React from 'react';
import { FidelityInfo } from '@/lib/api/fidelity/types';
import Currency from '@/components/atoms/currency';
import dayjs from 'dayjs';
import './style.scss';
import t from '@/lib/i18n';
import key from 'weak-key';

type FidelityDetailedBalanceProps = {
  fidelityInfo: FidelityInfo;
};

const FidelityDetailedBalance = ({
  fidelityInfo
}: FidelityDetailedBalanceProps) => {
  const { availableBalance, pendingBalance, deferredBalances } = fidelityInfo;

  const pdvAvailableBalance = availableBalance?.find(
    (balance) => balance.codeCanalDecagnot === 'PDV'
  )?.balance;
  const genAvailableBalance = availableBalance?.find(
    (balance) => balance.codeCanalDecagnot === 'GEN'
  )?.balance;
  const pacAvailableBalance = availableBalance?.find(
    (balance) => balance.codeCanalDecagnot === 'PAC'
  )?.balance;

  const pdvPendingBalances = pendingBalance?.filter(
    (balance) => balance.codeCanalDecagnot === 'PDV'
  );
  const genPendingBalances = pendingBalance?.filter(
    (balance) => balance.codeCanalDecagnot === 'GEN'
  );
  const pacPendingBalances = pendingBalance?.filter(
    (balance) => balance.codeCanalDecagnot === 'PAC'
  );

  const pdvDeferredInfo = deferredBalances?.filter(
    (balance) => balance.codeCanalDecagnot === 'PDV'
  );
  const genDeferredInfo = deferredBalances?.filter(
    (balance) => balance.codeCanalDecagnot === 'GEN'
  );
  const pacDeferredInfo = deferredBalances?.filter(
    (balance) => balance.codeCanalDecagnot === 'PAC'
  );

  return (
    <div className="fidelity_detailed_balance">
      <table>
        <thead>
          <Row
            label={t('fidelity.detailedBalance.availableBalance')}
            amount={genAvailableBalance}
            isHeader
          />
          <Row
            label={t('fidelity.detailedBalance.availableBalanceLoja')}
            amount={pdvAvailableBalance}
            isHeader
          />
          <Row
            label={t('fidelity.detailedBalance.availableBalancePAC')}
            amount={pacAvailableBalance}
            isHeader
          />
        </thead>
        <tbody>
          {genPendingBalances?.map((balance) => (
            <Row
              key={key(balance)}
              label={`${t(
                'fidelity.detailedBalance.availableFrom'
              )} ${dayjs().add(1, 'day').format('DD/MM/YYYY')}`}
              amount={balance?.balance}
            />
          ))}
          {pdvPendingBalances?.map((balance) => (
            <Row
              key={key(balance)}
              label={`${t(
                'fidelity.detailedBalance.lojaAvailableFrom'
              )} ${dayjs().add(1, 'day').format('DD/MM/YYYY')}`}
              amount={balance?.balance}
            />
          ))}
          {pacPendingBalances?.map((balance) => (
            <Row
              key={key(balance)}
              label={`${t(
                'fidelity.detailedBalance.pacAvailableFrom'
              )} ${dayjs().add(1, 'day').format('DD/MM/YYYY')}`}
              amount={balance?.balance}
            />
          ))}
        </tbody>
        <tbody>
          {genDeferredInfo?.map((info) => (
            <Row
              key={key(info)}
              label={`${t('fidelity.detailedBalance.availableFromTo', {
                '%from%': info?.validityStartDate.format('DD/MM/YYYY'),
                '%to%': info?.validityEndDate.format('DD/MM/YYYY')
              })}`}
              amount={info?.balance}
            />
          ))}
          {pdvDeferredInfo?.map((info) => (
            <Row
              key={key(info)}
              label={`${t('fidelity.detailedBalance.lojaAvailableFromTo', {
                '%from%': info?.validityStartDate.format('DD/MM/YYYY'),
                '%to%': info?.validityEndDate.format('DD/MM/YYYY')
              })}`}
              amount={info?.balance}
            />
          ))}
          {pacDeferredInfo?.map((info) => (
            <Row
              key={key(info)}
              label={`${t('fidelity.detailedBalance.pacAvailableFromTo', {
                '%from%': info?.validityStartDate.format('DD/MM/YYYY'),
                '%to%': info?.validityEndDate.format('DD/MM/YYYY')
              })}`}
              amount={info?.balance}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FidelityDetailedBalance;

type RowProps = {
  label?: string;
  amount?: number;
  isHeader?: boolean;
};

const Row = ({ label, amount, isHeader = false }: RowProps) => {
  if (!amount) return <></>;

  if (isHeader)
    return (
      <tr>
        <th>{label}</th>
        <th>
          <Currency amount={amount} />
        </th>
      </tr>
    );

  return (
    <tr>
      <td>{label}</td>
      <td>
        <Currency amount={amount} />
      </td>
    </tr>
  );
};
