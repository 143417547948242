import { SummaryBoxProps } from '@/components/templates/avantageFidelite/summaryBox';
import {
  Component,
  NormalizeParams,
  normalize as BaseNormalize
} from '@/lib/contentful';

const normalize = ({
  data,
  includes,
  extras
}: NormalizeParams): FidelitySummaryBoxV2 => {
  const components: { [key: string]: Component } = {};

  const list = [
    'backgroundImage',
    'fidelityCardImage',
    'fidelityCardCreationFlow',
    'fidelityCardAttachmentFlow'
  ];

  list.forEach((element) => {
    const itemData = includes[data[element]?.sys.id] || null;
    const dataToPass = { ...itemData, id: data[element]?.sys.id };

    const baseElement = BaseNormalize({
      data: dataToPass,
      includes,
      extras
    });
    if (baseElement) {
      components[element] = baseElement;
    }
  });

  return {
    type: 'templates/avantageFidelite',
    name: 'summaryBox',
    props: {
      components
    }
  };
};

export default normalize;

export interface FidelitySummaryBoxV2 extends Component {
  props: SummaryBoxProps;
}
