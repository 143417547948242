import React from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Layout from '@/components/organisms/layout';
import Header from '@/components/organisms/header';
import ContentUniverses from './content';

export type AllUniversesProps = {
  footer: ContentfulContent;
  pageView: string;
};

const AllUniverses = ({ footer, pageView }: AllUniversesProps) => {
  const headerPart = <Header />;

  const content = <ContentUniverses />;

  return (
    <Layout
      className="searchPage"
      header={headerPart}
      content={content}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
      pageView={pageView}
    />
  );
};

export default AllUniverses;
