import React from 'react';
import './style.scss';
import classnames from 'classnames';
import t from '@/lib/i18n';
import Date from '@/lib/utils/date';
import Image from '@/components/atoms/image';

export type HeadingProps = {
  title: string;
  background: string;
  pdv?: {
    name: string;
    isOpen: boolean;
    nextOpeningDate?: string;
    nextClosingDate?: string;
  };
};

const Heading = ({ title, background, pdv }: HeadingProps) => {
  if (!pdv) {
    return null;
  }

  let openingMessage = null;

  if (pdv.isOpen && pdv.nextClosingDate) {
    const nextClosingDate = Date(pdv.nextClosingDate);

    openingMessage = t('home.heading.openingMessage.todayUntil', {
      '%hours%': nextClosingDate.format('HH'),
      '%minutes%': nextClosingDate.format('mm')
    });
  } else if (!pdv.isOpen && pdv.nextOpeningDate) {
    const nextOpeningDate = Date(pdv.nextOpeningDate);
    const today = Date().hour(0).minute(0);
    const tomorrow = today.add(1, 'day');

    if (nextOpeningDate > today && nextOpeningDate < tomorrow) {
      openingMessage = t('home.heading.openingMessage.today', {
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    } else if (nextOpeningDate > tomorrow) {
      openingMessage = t('home.heading.openingMessage.tomorrow', {
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    } else {
      openingMessage = t('home.heading.openingMessage', {
        '%date%': nextOpeningDate.format('dddd'),
        '%hours%': nextOpeningDate.format('HH'),
        '%minutes%': nextOpeningDate.format('mm')
      });
    }
  }

  return (
    <div className="homeHeading">
      <Image
        className="homeHeading__image"
        src={background}
        alt=""
        lazy={false}
      />
      <div className="container homeHeading__info">
        <strong>{title.replace('[%MAGASIN%]', pdv.name)}</strong>
        {openingMessage && (
          <div className="homeHeading__infoShop">
            {t('home.heading.youStoreIs')}
            <span
              className={classnames({
                homeHeading__schedule: true,
                'homeHeading__schedule--opening': pdv.isOpen
              })}
            >
              {openingMessage}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Heading;
