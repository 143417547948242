import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import ChooseStore from '@/components/organisms/chooseStore';
import { useRouter } from 'next/router';

const NotEcommerce = () => {
  const router = useRouter();

  return (
    <div className="myAccountList__notEcommerce">
      <svg height="200" width="200">
        <use xlinkHref={`${MyAccountSprite}#list-change-store`} />
      </svg>
      <div className="myAccountList__notEcommerce_title">
        {t('myAccount.lists.notEcommerce.title')}
      </div>
      <div className="myAccountList__notEcommerce_message">
        {t('myAccount.lists.notEcommerce.message')}
      </div>
      <ChooseStore
        className="myAccountList__notEcommerce_button"
        ctaLabel={t('myAccount.lists.notEcommerce.button')}
        storeLocator={{
          redirect: {
            url: router.asPath
          }
        }}
      />
    </div>
  );
};

export default NotEcommerce;
