import Checkbox from '@/components/atoms/formCheckbox';
import { Choice } from '@/lib/api/preferences';
import t from '@/lib/i18n';
import {
  UpdateSurveyAction,
  UPDATE_SURVEY_ANSWER
} from '@/store/survey/actions';
import React from 'react';
import StepHeader from '../stepHeader';
import { SharedProps, Suggestion } from '../types';
import './style.scss';

const CheckboxForm: React.FC<SharedProps> = (props) => {
  const { dispatch, questionId, suggestions } = props;
  const currentResponse = props.currentResponse || {
    questionId,
    selectedChoices: []
  };

  const dispatchSelectedChoices = (selectedChoices: Choice[]) => {
    dispatch({
      type: UPDATE_SURVEY_ANSWER,
      payload: {
        response: {
          questionId,
          selectedChoices
        }
      }
    } as UpdateSurveyAction);
  };

  const updateExclusiveResponse = (currentSuggestion: Suggestion) => (
    checked: boolean
  ) => {
    const newSuggestions = checked
      ? [{ suggestionId: currentSuggestion.suggestionId }]
      : [];
    dispatchSelectedChoices(newSuggestions);
  };

  const updateResponse = (currentSuggestion: Suggestion) => (
    checked: boolean
  ) => {
    const { suggestionId } = currentSuggestion;
    const formerSuggestions = currentResponse.selectedChoices || [];
    const exclusivesSuggestionIds = suggestions
      .filter((suggestion) => suggestion.exclusive)
      .map((suggestion) => suggestion.suggestionId);

    let newSuggestions: Choice[] = [];
    if (!checked) {
      newSuggestions = formerSuggestions.filter(
        (suggestion) => suggestion.suggestionId !== suggestionId
      );
    } else {
      newSuggestions = [
        ...formerSuggestions.filter(
          (suggestion) =>
            !exclusivesSuggestionIds.includes(suggestion.suggestionId)
        ),
        { suggestionId }
      ];
    }
    dispatchSelectedChoices(newSuggestions);
  };

  return (
    <form className="smartconso-checkbox">
      <StepHeader {...props} />
      {suggestions.map((currentSuggestion) => {
        const { suggestion, suggestionId, exclusive } = currentSuggestion;
        const onChange = exclusive
          ? updateExclusiveResponse(currentSuggestion)
          : updateResponse(currentSuggestion);
        const exist = Boolean(
          currentResponse.selectedChoices?.find(
            (s) => s.suggestionId === suggestionId
          )
        );

        return (
          <>
            <Checkbox
              className="smartconso-checkbox__input"
              onChange={onChange}
              key={`sugestion-${suggestionId}`}
              name={suggestion}
              checked={exist}
              label={t(suggestion)}
              id={`suggestion-${suggestionId.toString}`}
            />
          </>
        );
      })}
    </form>
  );
};

export default CheckboxForm;
