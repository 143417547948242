import React from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Layout from '@/components/organisms/layout';
import CartOrganism from '@/components/organisms/checkout/cart';
import { StateType as CartStateType } from '@/store/cart/types';
import { useSelector, shallowEqual } from 'react-redux';
import Header from '../header';
import './style.scss';

export type CartProps = {
  footer: ContentfulContent;
  pageView: string;
};

const StepOneTemplate = ({ footer, pageView }: CartProps) => {
  // TODO : const cartStore = useShalloEqualSelector(useCart);
  const { cartStore } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      cartStore: cart
    };
  }, shallowEqual);
  const headerPart = <Header cart={cartStore} step="cart" />;
  const content = (
    <div className="container cartTemplate">
      <CartOrganism isCheckout={true} />
    </div>
  );

  return (
    <Layout
      header={headerPart}
      content={content}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
      pageView={pageView}
    />
  );
};

export default StepOneTemplate;
