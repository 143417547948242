import { useEffect, useRef, RefObject, useCallback } from 'react';

type ReturnType = [RefObject<any>, (callback: () => void) => void];

const useClickOutWithExcludedElement = (
  excludedElementSelector?: string
): ReturnType => {
  const ref = useRef<any>();
  const callbackRef = useRef(() => {});

  const callback = useCallback((handler: () => void) => {
    callbackRef.current = handler;
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (excludedElementSelector) {
        const excludedElement = document.querySelector(
          excludedElementSelector
        ) as Element;
        if (excludedElement?.contains(event.target as Element)) return;
      }

      if (!!ref.current && !ref.current?.contains(event.target)) {
        callbackRef.current();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [excludedElementSelector]);

  return [ref, callback];
};

export default useClickOutWithExcludedElement;
