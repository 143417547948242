import React from 'react';
import Layout from '@/components/organisms/layout';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Header from '@/components/organisms/header';
import Iframe from '@/components/atoms/iframe';
import './style.scss';
import getConfigValue from '@/lib/config';

export type SellerPartnerProps = {
  footer: ContentfulContent;
  partnerId: string;
  sellerName?: string;
};

const sellerPartner = ({ footer, partnerId }: SellerPartnerProps) => {
  const headerPart = <Header />;

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  const partnerRootUrl = getConfigValue(
    'PARTNERS_ROOT_URL',
    'https://www.intermarche-shopping.fr'
  ).toString();

  const content: JSX.Element = (
    <Iframe
      attributes={{
        name: 'partner',
        src: `${partnerRootUrl}/op/shops/${partnerId}`,
        height: '420'
      }}
    />
  );

  return (
    <Layout
      header={headerPart}
      footer={footerPart}
      content={content}
      pageView="SellerPage"
      className="partner"
    />
  );
};

export default sellerPartner;
