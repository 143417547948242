import React from 'react';
import Image from '@/components/atoms/image';
import Link from '@/components/atoms/link';
import classnames from 'classnames';
import { PartenersDatasProps, LuckyCartCover } from '@/lib/thirdPartyApi';
import './style.scss';

export type LuckyCartCoversProps = {
  partnersDatas: PartenersDatasProps;
};

type PageType = {
  isPromoPage: boolean;
  productGridCount: number;
};

const LuckyCartCovers = ({
  partnersDatas,
  isPromoPage,
  productGridCount
}: LuckyCartCoversProps & PageType) => {
  if (!partnersDatas.covers?.length) return null;

  if (isPromoPage) {
    if (productGridCount < 3) return null;
  } else {
    let productGridCountTemp = productGridCount;
    if (partnersDatas.isHighcoExist) productGridCountTemp += 1;
    if (
      partnersDatas.criteoButterflyProducts1?.length ||
      partnersDatas.criteoButterflyProducts2?.length
    ) {
      /* eslint-disable eqeqeq */
      if (
        partnersDatas.criteoButterflyProducts1?.length !=
        partnersDatas.criteoButterflyProducts2?.length
      ) {
        productGridCountTemp += 2;
      } else {
        productGridCountTemp += 3;
      }
    }
    if (productGridCountTemp < 11) return null;
  }
  const dynamicOrderClassName = (() => {
    if (isPromoPage) {
      return 'luckyCart__promo';
    }
    if (
      partnersDatas.criteoButterflyProducts1?.length &&
      partnersDatas.isHighcoExist
    ) {
      return 'luckyCart__HighcoCriteoBefore';
    }
    if (
      !partnersDatas.criteoButterflyProducts1?.length &&
      partnersDatas.isHighcoExist
    ) {
      return 'luckyCart__HighcoBefore';
    }
    if (
      partnersDatas.criteoButterflyProducts1?.length &&
      !partnersDatas.isHighcoExist
    ) {
      return 'luckyCart__CriteoBefore';
    }
    return 'luckyCart__noPrevious';
  })();

  return (
    <>
      {partnersDatas.covers.map((cover: LuckyCartCover, index: number) => {
        if (index === 1 && productGridCount < 8) return null;
        return (
          <div
            key={cover.id}
            className={classnames(
              'productList__grid__item',
              'luckyCart',
              `${dynamicOrderClassName}--${index}`
            )}
          >
            <Link href={cover.href} className="luckyCart__link">
              <Image
                src={cover.img}
                alt={cover.alt || ''}
                className="luckyCart__img"
              />
            </Link>
          </div>
        );
      })}
    </>
  );
};

export default LuckyCartCovers;
