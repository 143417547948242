import React from 'react';
import { ReactComponent as StorePicto } from '@/assets/images/icons/store.svg';
import t from '@/lib/i18n';
import './style.scss';
import Link from '@/components/atoms/link';

const Confirmation = () => {
  return (
    <div className="accountCreatedWrapper">
      <div className="accountCreated">
        <div className="accountCreated__icon">
          <StorePicto width={250} height={250} />
        </div>
        <div className="accountCreated__title">{t('register.final.title')}</div>
        <div className="accountCreated__content">
          {t('register.final.content')}
        </div>
        <Link label={t('register.confirmation.cta')} href="/home" button />
      </div>
    </div>
  );
};

export default Confirmation;
