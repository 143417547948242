import {
  Content,
  normalize as BaseNormalize,
  NormalizeParams,
  Component
} from '..';

const buildSummary = (
  components: Array<Component>,
  includes: any,
  summaryItem: any
) => {
  const componentsTemp: Array<Component> = [];
  const compileComponents = (
    componentsCompiled: Array<Component>,
    componentsToCompile: Array<Component>
  ) => {
    componentsToCompile.forEach((componentTemp: Component) => {
      if (
        componentTemp.name === 'block' &&
        componentTemp.props?.cmsComponents?.length > 0
      ) {
        compileComponents(
          componentsCompiled,
          componentTemp.props.cmsComponents
        );
      }
      componentsCompiled.push(componentTemp);
    });
  };

  compileComponents(componentsTemp, components);

  const dataForSummary = {
    ...summaryItem.dataContent,
    fields: {
      ...summaryItem.itemData?.fields,
      components: componentsTemp
    }
  };

  return BaseNormalize({
    data: dataForSummary,
    includes
  });
};

const normalize = ({
  data,
  includes,
  extras
}: NormalizeParams): GenericPage => {
  const meta = {
    title: null,
    description: null
  };
  const components: Array<Component> = [];

  if (data.meta) {
    const { id } = data.meta.sys;
    const metaData = includes[id];

    meta.title = metaData?.fields?.pageTitle ?? null;
    meta.description = metaData?.fields?.pageDescription ?? null;
  }

  if (Array.isArray(data.content)) {
    data.content?.forEach((content: any) => {
      const itemData = includes[content.sys.id] || null;
      if (itemData) {
        const elt = BaseNormalize({
          data: itemData,
          includes,
          extras
        });

        if (elt) {
          components.push(elt);
        }
      }
    });
  } else {
    const itemData = includes[data.content.sys.id] || null;
    if (itemData) {
      const elt = BaseNormalize({
        data: itemData,
        includes,
        extras
      });

      if (elt) {
        components.push(elt);
      }
    }
  }

  const findAndReplaceSummaryAtEnd = (
    componentsTemp: Array<Component>
  ): any => {
    return !!componentsTemp.find((componentTemp: Component, index: number) => {
      if (componentTemp.name === 'block') {
        return !!findAndReplaceSummaryAtEnd(componentTemp.props.cmsComponents);
      }
      if (componentTemp.type === 'summaryAtEnd') {
        // eslint-disable-next-line no-param-reassign
        componentsTemp[index] = buildSummary(
          components,
          includes,
          componentTemp
        );
        return true;
      }
      return false;
    });
  };

  findAndReplaceSummaryAtEnd(components);

  return {
    meta,
    components
  };
};

export default normalize;

export type GenericPage = Content;
