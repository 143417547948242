import { LandingPageProps } from '@/components/molecules/landingPage';
import { Component, normalize as BaseNormalize, NormalizeParams } from '..';

const normalize = ({
  data,
  includes,
  extras
}: NormalizeParams): ComponentLandingPageProps => {
  const componentsLeft: Array<Component> = [];
  const componentsRight: Array<Component> = [];
  let componentHeaderImage;
  let componentHeading;

  const { headerImage, heading, leftSide, rightSide } = data;

  if (headerImage) {
    const itemData = includes[headerImage.sys.id] || null;
    if (itemData) {
      const elt = BaseNormalize({
        data: itemData,
        includes,
        extras
      });
      if (elt) {
        componentHeaderImage = elt;
      }
    }
  }

  if (heading) {
    const itemData = includes[heading.sys.id] || null;
    if (itemData) {
      const elt = BaseNormalize({
        data: itemData,
        includes,
        extras
      });
      if (elt) {
        componentHeading = elt;
      }
    }
  }

  leftSide?.forEach((content: any) => {
    const itemData = includes[content.sys.id] || null;
    if (itemData) {
      const elt = BaseNormalize({
        data: itemData,
        includes,
        extras
      });
      if (elt) {
        componentsLeft.push(elt);
      }
    }
  });

  rightSide?.forEach((content: any) => {
    const itemData = includes[content.sys.id] || null;
    if (itemData) {
      const elt = BaseNormalize({
        data: itemData,
        includes,
        extras
      });
      if (elt) {
        componentsRight.push(elt);
      }
    }
  });

  return {
    type: 'molecules',
    name: 'landingPage',
    props: {
      headerImage: componentHeaderImage,
      heading: componentHeading,
      componentsLeft,
      componentsRight
    }
  };
};

export default normalize;

export interface ComponentLandingPageProps extends Component {
  props: LandingPageProps;
}
