import React, { memo, useEffect, useState } from 'react';
import { Content as ContentfulContent } from '@/lib/contentful';
import { STATUS } from '@/store/cardCreation/reducer';
import Button from '@/components/atoms/button';
import { ReactComponent as ArrowIcon } from '@/assets/images/icons/arrow-down.svg';
import { useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import UserInformationApi from '@/lib/api/userInformation';
import Date from '@/lib/utils/date';

import Step from './step';

export type CGUStepProps = {
  content: ContentfulContent;
};

const CGUStep = ({ content }: CGUStepProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [nextStep, setNextStep] = useState(STATUS.IN_PROGRESS);
  const scrollToBtn = () => {
    const acceptCGUBtn = document.querySelector(
      '.steps__item-validationBtn'
    ) as HTMLElement;
    const cguContainer = document.querySelector('.steps__item') as HTMLElement;
    if (acceptCGUBtn && cguContainer) {
      cguContainer.scrollTo({
        top: acceptCGUBtn.offsetTop,
        behavior: 'smooth'
      });
    }
  };
  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }
      const user = await UserInformationApi.getInformations(userId);
      const { civility, lastName, firstName, birthdayDate } = user;
      const age = Date().diff(birthdayDate, 'year');
      const thereIsMissingInfos =
        !civility || !firstName || !lastName || age < 18 || age > 120;
      if (user.isPro) {
        setNextStep(STATUS.USER_IS_PRO);
      } else if (thereIsMissingInfos) {
        setNextStep(STATUS.MISSING_USER_INFOS);
      }
    })();
  }, [userId]);

  return (
    <div className="steps__cgu">
      <Step content={content} screenId="ecranCgu" nextStep={nextStep} />
      <Button className="steps__cgu-scrollBtn" onClick={scrollToBtn}>
        <ArrowIcon width={20} height={20} fill="#FFF" />
      </Button>
    </div>
  );
};

export default memo(CGUStep);
