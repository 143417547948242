import Link from '@/components/atoms/link';
import Title from '@/components/atoms/title';
import ChatBotMessenger from '@/components/molecules/chatbotMessenger';
import SavBanner from '@/components/molecules/savBanner';
import Header from '@/components/organisms/header';
import Layout from '@/components/organisms/layout';
import {
  Content as ContentfulContent,
  RendererComponents as ContentfulRenderComponents
} from '@/lib/contentful';
import React from 'react';
import t from '@/lib/i18n';
import SavBlocContact, { InfosProps } from './savBlocContact';
import SavPopinBlockPhone from './savBlocContact/SavPopinBlockPhone';
import './style.scss';

export type SavProps = {
  footer: ContentfulContent;
  pageView: string;
};

export const CONTACT_ITEMS_LIST: InfosProps[] = [
  {
    label: t('formContactUs.label.email'),
    icon: 'email-arobase',
    button: (
      <Link button href="/help-and-contact/vas/mail" label={t('sav.contact')} />
    )
  },
  {
    label: t('myAccount.myDetails.label.phone'),
    icon: 'icon-phone',
    popinContent: <SavPopinBlockPhone />
  }
];

const Sav = ({ footer, pageView }: SavProps) => {
  const headerPart = <Header />;

  const contentPart = (
    <>
      <SavBanner />
      <div className="sav__contactList">
        <Title
          tag="h4"
          label={t('myAccount.menu.contact')}
          className="sav__contactList__title"
        />
        <div className="sav__contactList__list">
          {CONTACT_ITEMS_LIST.map((item: InfosProps) => (
            <SavBlocContact
              infos={item}
              key={`sav-bloc-contact-${item.label}`}
            />
          ))}
        </div>
      </div>
      <ChatBotMessenger />
    </>
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout
      className="sav"
      header={headerPart}
      content={contentPart}
      footer={footerPart}
      pageView={pageView}
    />
  );
};

export default Sav;
