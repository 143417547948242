import React, { useCallback, useEffect, useRef, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import CSSTransition from 'react-transition-group/CSSTransition';
import { AuthStateType } from '@/store/auth/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import LoadableContent from '@/components/atoms/loadableContent';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import userInformationApi from '@/lib/api/userInformation';
import {
  Addresses as AddressesType,
  BillingAddress,
  DeliveryAddress
} from '@/lib/api/address/types';
import { ADD_SLOTS } from '@/store/cart/cartActions';
import { StateType as CartStateType } from '@/store/cart/types';
import { getPdvRef } from '@/lib/hooks/usePdv';
import { Informations as UserInformations } from '@/lib/api/userInformation/types';
import AddressApi from '@/lib/api/address';
import slotsApi from '@/lib/api/slots';
import Button from '@/components/atoms/button';
import classnames from 'classnames';
import Address from './address';
import Create from './create';
import Edit from './edit';
import NoAddress from './noAddress';

const defaultAddresses = {
  delivery: [],
  billing: []
};

type StateType = {
  editAddress: BillingAddress | DeliveryAddress | null;
  createNewAddress: boolean;
  openForm: boolean;
  isLoading: boolean;
  addresses: AddressesType;
  userInfo: UserInformations | null;
  pageViewCreate?: string;
  pageViewModify?: string;
  newAddressType?: 'billing' | 'delivery' | null;
};

const UserAddresses = ({
  isSelectable = false,
  pageViewCreate,
  pageViewModify,
  validate
}: {
  isSelectable?: boolean;
  pageViewCreate?: string;
  pageViewModify?: string;
  validate?: (address: DeliveryAddress, billing: BillingAddress) => void;
}) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );

  // TODO : const cartStore = useShalloEqualSelector(useCart);
  const { cartStore } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      cartStore: cart
    };
  });

  const [state, setState] = useState<StateType>({
    editAddress: null,
    createNewAddress: false,
    openForm: false,
    isLoading: true,
    addresses: defaultAddresses,
    userInfo: null,
    newAddressType: null
  });

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }

      let newAddresses: AddressesType = defaultAddresses;

      try {
        newAddresses = await AddressApi.getAll(userId);
      } catch (error: any) {
        // Todo: handle user error display if unable to fetch user addresses
      }

      setState((oldState) => {
        return {
          ...oldState,
          isLoading: false,
          addresses: newAddresses
        };
      });
    })();
  }, [userId]);

  const onCancel = useCallback(async (): Promise<void> => {
    if (!userId) return;
    setState((oldState) => ({
      ...oldState,
      isLoading: false
    }));
    const newAddresses = await AddressApi.getAll(userId);
    setState((oldState) => ({
      ...oldState,
      isLoading: false,
      addresses: newAddresses,
      openForm: false
    }));
  }, [userId]);

  const onDelete = useCallback(
    async (addressId: number) => {
      if (!userId) return;
      setState((oldState) => ({
        ...oldState,
        isLoading: false
      }));
      await AddressApi.delete(userId, addressId);
      const newAddresses = await AddressApi.getAll(userId);
      setState((oldState) => ({
        ...oldState,
        isLoading: false,
        addresses: newAddresses,
        openForm: false
      }));
    },
    [userId]
  );

  const onSelect = useCallback(
    async (address: DeliveryAddress, list: Array<DeliveryAddress>) => {
      if (!userId) return;
      const delivery = list.map((item) => ({
        ...item,
        isDefault: item.id === address.id
      }));
      setState((oldState) => ({
        ...oldState,
        isLoading: false,
        addresses: { ...oldState.addresses, delivery },
        openForm: false
      }));
      await AddressApi.update(userId, { ...address, isDefault: true });
    },
    [userId]
  );

  const addressEdit = useCallback(
    (addressToEdit: BillingAddress | DeliveryAddress) => {
      setState((s) => ({
        ...s,
        editAddress: addressToEdit,
        openForm: true
      }));
    },
    []
  );

  const updateSlots = useCallback(
    async (cart) => {
      const pdvRef = getPdvRef();

      try {
        const {
          data,
          vouchersCodeActive,
          discountCodeActive,
          mkpPackages,
          defaultPayments
        } = await slotsApi.getAll(cart, pdvRef, userId);

        dispatch({
          type: ADD_SLOTS,
          payload: {
            data,
            vouchersCodeActive,
            discountCodeActive,
            mkpPackages,
            defaultPayments
          }
        });
      } catch (error: any) {
        window.location.href = '/orders/cart';
      }
    },
    [dispatch, userId]
  );

  return (
    <LoadableContent loading={state.isLoading} className="myAddresses">
      <>
        {!state.editAddress && !state.createNewAddress && (
          <div className="myAddresses__list">
            <div className="myAddresses__subTitle">
              {t('myAccount.myAddresses.billing.title')}
            </div>
            {userId &&
              state.addresses.billing.map((address: BillingAddress) => {
                const key = `address-${address.id}`;
                return (
                  <Address key={key} address={address} edit={addressEdit} />
                );
              })}

            {userId && !state.addresses.billing.length && (
              <>
                <div
                  className="myAddresses__add"
                  onClick={async () => {
                    if (!userId) {
                      return;
                    }

                    const userInformations = await userInformationApi.getInformations(
                      userId
                    );

                    setState((s) => ({
                      ...s,
                      userInfo: userInformations,
                      createNewAddress: !s.createNewAddress,
                      openForm: !s.openForm,
                      newAddressType: 'billing'
                    }));
                  }}
                >
                  <svg
                    className="fill-red myAddresses__add_picto"
                    height="20"
                    width="20"
                  >
                    <use xlinkHref={`${MyAccountSprite}#plus-circle`} />
                  </svg>
                  {t('myAccount.myAddresses.billing.add')}
                </div>
                <NoAddress
                  text={t('myAccount.myAddresses.billing.noAddress')}
                />
              </>
            )}

            <div className="myAddresses__subTitle">
              {t('myAccount.myAddresses.delivery.title')}
            </div>
            <div
              className="myAddresses__add"
              onClick={async () => {
                if (!userId) {
                  return;
                }

                const userInformations = await userInformationApi.getInformations(
                  userId
                );

                setState((s) => ({
                  ...s,
                  userInfo: userInformations,
                  createNewAddress: !s.createNewAddress,
                  openForm: !s.openForm,
                  newAddressType: 'delivery'
                }));
              }}
            >
              <svg
                className="fill-red myAddresses__add_picto"
                height="20"
                width="20"
              >
                <use xlinkHref={`${MyAccountSprite}#plus-circle`} />
              </svg>
              {t('myAccount.myAddresses.delivery.add')}
            </div>
            {userId &&
              state.addresses.delivery.map((address: DeliveryAddress) => {
                const key = `address-${address.id}`;
                return (
                  <Address
                    className={classnames({ selectable: isSelectable })}
                    key={key}
                    onSelect={() => {
                      if (!isSelectable || state.addresses.delivery.length < 2)
                        return;
                      onSelect(address, state.addresses.delivery);
                    }}
                    onDelete={async () => {
                      if (state.addresses.delivery.length < 2) return;
                      await onDelete(address.id || 0);
                    }}
                    address={address}
                    edit={addressEdit}
                  />
                );
              })}

            {userId && !state.addresses.delivery.length && (
              <NoAddress text={t('myAccount.myAddresses.delivery.noAddress')} />
            )}

            {validate && (
              <Button
                label={t('form.button.validate')}
                onClick={async () => {
                  if (!userId) return;
                  const selectedAddress: DeliveryAddress | null =
                    state.addresses.delivery.find((item) => item.isDefault) ||
                    null;
                  if (!selectedAddress) return;
                  setState((s) => ({ ...s, isLoading: true }));
                  await AddressApi.update(userId, selectedAddress);
                  const newAddresses = await AddressApi.getAll(userId);
                  await updateSlots(cartStore);
                  setState((s) => ({
                    ...s,
                    addresses: newAddresses,
                    isLoading: false
                  }));
                  validate(selectedAddress, newAddresses?.billing?.[0] ?? null);
                }}
              />
            )}
          </div>
        )}
        <CSSTransition
          classNames="myAddresses__form"
          in={state.openForm}
          timeout={300}
          nodeRef={formRef}
          unmountOnExit={true}
          mountOnEnter={true}
          onEntering={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }}
          onExited={() => {
            setState({
              ...state,
              editAddress: null,
              createNewAddress: false,
              newAddressType: null
            });
          }}
        >
          <div className="myAddresses__form" ref={formRef}>
            {state.editAddress && userId && (
              <Edit
                userId={userId}
                address={state.editAddress}
                onCancel={onCancel}
                pageView={pageViewModify}
              />
            )}
            {state.createNewAddress && state.userInfo && (
              <Create
                pageView={pageViewCreate}
                userInfo={state.userInfo}
                onCancel={onCancel}
                type={state.newAddressType}
              />
            )}
          </div>
        </CSSTransition>
      </>
    </LoadableContent>
  );
};

export default UserAddresses;
