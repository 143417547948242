import React, { useState } from 'react';
import classnames from 'classnames';
import t from '@/lib/i18n';
import { Informations } from '@/lib/model/productTypes';
import SmartConsoSprite from '@/assets/images/sprites/smartconso.svg';
import PdpSprite from '@/assets/images/sprites/pdp.svg';
import NutritionModal from './nutritionModal';
import './style.scss';

export type IngredientsAllergensProps = {
  informations: Informations;
};

const IngredientsAllergens = ({ informations }: IngredientsAllergensProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <section
      id="ingredients-allergens"
      className="productDetail__section productDetail__composition allergenSection"
    >
      <div className="container container--md">
        <h2 className="productDetail__section_title">
          {t('pdp.informations.ingredients_allergens.title')}
        </h2>
        {informations.nutritionalScores && (
          <div className="highlightNutris">
            <span className="highlightNutris__content">
              {t('pdp.nutrition.help')}
            </span>
            <span className="arrowDown">
              <svg width="34px" height="30px">
                <use xlinkHref={`${PdpSprite}#arrowDown`} />
              </svg>
            </span>
          </div>
        )}
        <div className="productDetail__section_content">
          <div className="productDetail__nutritionalValues">
            {informations.nutritionalValues && (
              <div className="productDetail__content__section">
                <div className="productDetail__tableWrapper">
                  <table className="productDetail__table">
                    <tbody>
                      <tr>
                        <td className="productDetail__table__item--title">
                          <span className="productDetail__table__title">
                            {t('pdp.informations.nutritionalValues.rate', {
                              '%rate%': informations.nutritionalValues.rate
                            })}
                          </span>
                        </td>
                        <td className="productDetail__table__item" />
                        {informations.nutritionalScores && (
                          <td
                            className="productDetail__table__item--innit-nutrients"
                            data-tip
                            data-for="nutritionalScores"
                          >
                            <div
                              className="nutritionInfoBtn"
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              ?
                            </div>
                            <svg width="76" height="18">
                              <use
                                xlinkHref={`${SmartConsoSprite}#scoreInnitFull`}
                              />
                            </svg>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className="productDetail__table__item">
                          {t('pdp.informations.nutritionalValues.energy')}
                        </td>
                        <td className="productDetail__table__item">
                          {informations.nutritionalValues.energy.kj} kj (
                          {informations.nutritionalValues.energy.kcal} kcal)
                        </td>
                        {informations.nutritionalScores && (
                          <td className="productDetail__table__item">
                            <div className="productDetail__table__detail innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${
                                    informations.nutritionalScores?.energy
                                      ?.kj ??
                                    informations.nutritionalScores?.energy?.kcal
                                  }`]:
                                    informations.nutritionalScores?.energy
                                      ?.kj ??
                                    informations.nutritionalScores?.energy?.kcal
                                })}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className="productDetail__table__item">
                          {t('pdp.informations.nutritionalValues.fat')}
                          <div className="productDetail__table__detail">
                            {t(
                              'pdp.informations.nutritionalValues.fat.saturatedFattyAcid'
                            )}
                          </div>
                          <div className="productDetail__table__detail">
                            {t(
                              'pdp.informations.nutritionalValues.fat.monounsaturatedFattyAcid'
                            )}
                          </div>
                          <div className="productDetail__table__detail">
                            {t(
                              'pdp.informations.nutritionalValues.fat.polyunsaturatedFattyAcid'
                            )}
                          </div>
                        </td>
                        <td className="productDetail__table__item">
                          {informations.nutritionalValues.fat.value}
                          <div className="productDetail__table__detail">
                            {informations.nutritionalValues.fat.detail
                              .saturatedFattyAcid ?? '-'}
                          </div>
                          <div className="productDetail__table__detail">
                            {informations.nutritionalValues.fat.detail
                              .monounsaturatedFattyAcid ?? '-'}
                          </div>
                          <div className="productDetail__table__detail">
                            {informations.nutritionalValues.fat.detail
                              .polyunsaturatedFattyAcid ?? '-'}
                          </div>
                        </td>
                        {informations.nutritionalScores && (
                          <td className="productDetail__table__item">
                            <div className="productDetail__table__detail innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.fat?.value}`]: informations
                                    .nutritionalScores?.fat?.value
                                })}
                              />
                            </div>
                            <div className="productDetail__table__detail innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.fat?.detail?.saturatedFattyAcid}`]: informations
                                    .nutritionalScores?.fat?.detail
                                    ?.saturatedFattyAcid
                                })}
                              />
                            </div>
                            <div className="productDetail__table__detail innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.fat?.detail?.monounsaturatedFattyAcid}`]: informations
                                    .nutritionalScores?.fat?.detail
                                    ?.monounsaturatedFattyAcid
                                })}
                              />
                            </div>
                            <div className="productDetail__table__detail innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.fat?.detail?.polyunsaturatedFattyAcid}`]: informations
                                    .nutritionalScores?.fat?.detail
                                    ?.polyunsaturatedFattyAcid
                                })}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className="productDetail__table__item">
                          {t(
                            'pdp.informations.nutritionalValues.carbohydrates'
                          )}
                          <div className="productDetail__table__detail">
                            {t(
                              'pdp.informations.nutritionalValues.carbohydrates.sugar'
                            )}
                          </div>
                          <div className="productDetail__table__detail">
                            {t(
                              'pdp.informations.nutritionalValues.carbohydrates.polyols'
                            )}
                          </div>
                          <div className="productDetail__table__detail">
                            {t(
                              'pdp.informations.nutritionalValues.carbohydrates.starch'
                            )}
                          </div>
                        </td>
                        <td className="productDetail__table__item">
                          {informations.nutritionalValues.carbohydrates.value}
                          <div className="productDetail__table__detail">
                            {informations.nutritionalValues.carbohydrates.detail
                              .sugar ?? '-'}
                          </div>
                          <div className="productDetail__table__detail">
                            {informations.nutritionalValues.carbohydrates.detail
                              .polyols ?? '-'}
                          </div>
                          <div className="productDetail__table__detail">
                            {informations.nutritionalValues.carbohydrates.detail
                              .starch ?? '-'}
                          </div>
                        </td>
                        {informations.nutritionalScores && (
                          <td className="productDetail__table__item">
                            <div className="innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.carbohydrates?.value}`]: informations
                                    .nutritionalScores?.carbohydrates?.value
                                })}
                              />
                            </div>
                            <div className="productDetail__table__detail innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.carbohydrates?.detail?.sugar}`]: informations
                                    .nutritionalScores?.carbohydrates?.detail
                                    ?.sugar
                                })}
                              />
                            </div>
                            <div className="productDetail__table__detail innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.carbohydrates?.detail?.polyols}`]: informations
                                    .nutritionalScores?.carbohydrates?.detail
                                    ?.polyols
                                })}
                              />
                            </div>
                            <div className="productDetail__table__detail innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.carbohydrates?.detail?.starch}`]: informations
                                    .nutritionalScores?.carbohydrates?.detail
                                    ?.starch
                                })}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className="productDetail__table__item">
                          {t('pdp.informations.nutritionalValues.protein')}
                        </td>
                        <td className="productDetail__table__item">
                          {informations.nutritionalValues.protein ?? '-'}
                        </td>
                        {informations.nutritionalScores && (
                          <td className="productDetail__table__item">
                            <div className="innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.protein}`]: informations
                                    .nutritionalScores?.protein
                                })}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className="productDetail__table__item">
                          {t('pdp.informations.nutritionalValues.salt')}
                        </td>
                        <td className="productDetail__table__item">
                          {informations.nutritionalValues.salt ?? '-'}
                        </td>
                        {informations.nutritionalScores && (
                          <td className="productDetail__table__item">
                            <div className="innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.salt}`]: informations
                                    .nutritionalScores?.salt
                                })}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td className="productDetail__table__item">
                          {t('pdp.informations.nutritionalValues.dietaryFiber')}
                        </td>
                        <td className="productDetail__table__item">
                          {informations.nutritionalValues.dietaryFiber ?? '-'}
                        </td>
                        {informations.nutritionalScores && (
                          <td className="productDetail__table__item">
                            <div className="innit-nutrients__tag--bloc">
                              <div
                                className={classnames('innit-nutrients__tag', {
                                  [`innit-nutrients__tag--${informations.nutritionalScores?.dietaryFiber}`]: informations
                                    .nutritionalScores?.dietaryFiber
                                })}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <NutritionModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </section>
  );
};

export default IngredientsAllergens;
