import React, { useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import { notification } from '@/lib/notification';
import {
  Informations as UserInfo,
  UpdateInfoRequest
} from '@/lib/api/userInformation/types';
import userInformationApi from '@/lib/api/userInformation';
import { Controller, FieldError, useForm } from 'react-hook-form';
import FormInput from '@/components/atoms/formInput';
import FormRadio from '@/components/atoms/formRadio';
import FormDate from '@/components/atoms/formDate';
import Button from '@/components/atoms/button';
import Date from '@/lib/utils/date';
import Modal from '@/components/organisms/modal';
import { isValidNIF } from '@/lib/utils/company';
import { validatePhoneNumber } from '@/lib/utils/myAccount';

type UpdateProfileProps = {
  userInfo: UserInfo;
};

type StateType = {
  newUserInfo?: UserInfo;
  cardDissociation: boolean;
  updating: boolean;
};

const UpdateProfile = ({ userInfo }: UpdateProfileProps) => {
  const [state, setState] = useState<StateType>({
    newUserInfo: undefined,
    cardDissociation: false,
    updating: false
  });

  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    getValues
  } = useForm<UserInfo>({
    defaultValues: {
      ...userInfo
    }
  });

  const isAttachFidelityCardNumber = !!userInfo.fidelityCardNumber;

  const update = async (newUserInfo: UserInfo, cardDissociation = false) => {
    const request: UpdateInfoRequest = {
      id: userInfo.id,
      isPro: userInfo.isPro,
      ...(newUserInfo?.nif?.length && { nif: newUserInfo.nif }),
      company: newUserInfo.company,
      civility: newUserInfo.civility,
      lastName: newUserInfo.lastName,
      firstName: newUserInfo.firstName,
      birthdayDate: newUserInfo.birthdayDate,
      phone: newUserInfo.phone,
      mobilePhone: newUserInfo.mobilePhone
    };
    if (!newUserInfo.phone) {
      request.phone = '';
    }
    if (!newUserInfo.mobilePhone) {
      request.mobilePhone = '';
    }

    if (cardDissociation) {
      request.fidelityCardNumber = '';
    }

    setState({
      ...state,
      updating: true
    });

    try {
      await userInformationApi.updateInfo(request);
      notification.success(t('myAccount.myPersonalData.notification.success'));
    } catch (error: any) {
      notification.error(t('notification.erreur'));
    }

    setState({
      newUserInfo: undefined,
      cardDissociation: false,
      updating: false
    });
  };

  const onSubmit = async (data: UserInfo) => {
    const cardDissociation =
      isAttachFidelityCardNumber &&
      ((data.firstName && data.firstName !== userInfo.firstName) ||
        (data.lastName && data.lastName !== userInfo.lastName) ||
        (data.birthdayDate &&
          data.birthdayDate.format('YYYY-MM-DD') !==
            userInfo.birthdayDate.format('YYYY-MM-DD')));

    setState({
      ...state,
      newUserInfo: data,
      cardDissociation
    });

    if (!cardDissociation) {
      await update(data);
    }
  };

  return (
    <div className="updateProfile">
      <form onSubmit={handleSubmit(onSubmit)} className="updateProfile__form">
        <Controller
          control={control}
          name="isPro"
          render={({ onChange, value, name }) => (
            <FormRadio
              className="updateProfile__radio"
              name={name}
              type="block"
              label={`${t('myAccount.myDetails.label.isPro')} *`}
              options={
                userInfo.isPro
                  ? [
                      {
                        id: 'yes',
                        label: t('myAccount.myDetails.label.pro'),
                        value: 1
                      }
                    ]
                  : [
                      {
                        id: 'no',
                        label: t('myAccount.myDetails.label.not-pro'),
                        value: 0
                      }
                    ]
              }
              disabled={true}
              value={Number(value)}
              onChange={(newValue) => onChange(newValue)}
            />
          )}
        />
        <Controller
          control={control}
          name="civility"
          render={({ onChange, value, name }) => (
            <FormRadio
              className="updateProfile__radio"
              type="block"
              name={name}
              label={`${t('myAccount.myDetails.label.civility')} *`}
              options={[
                {
                  id: 'female',
                  label: t('myAccount.myDetails.label.female'),
                  value: 'female'
                },
                {
                  id: 'male',
                  label: t('myAccount.myDetails.label.male'),
                  value: 'male'
                }
              ]}
              value={value}
              onChange={(newValue) => onChange(newValue)}
            />
          )}
        />
        <FormInput
          id="nif"
          name="nif"
          label={`${t('myAccount.myDetails.label.nif')}`}
          className="updateProfile__input"
          disabled={!!userInfo?.nif && userInfo?.verifiedClient}
          maxLength={9}
          ref={register({
            required: false,
            minLength: 9,
            validate: {
              isValidNIF: (value) => {
                if (!value) {
                  return true;
                }
                return isValidNIF(value);
              }
            }
          })}
          errorMessage={errors.nif ? t(`form.error.isValidNIF`) : ''}
        />

        {userInfo.isPro && (
          <>
            <FormInput
              id="company"
              name="company"
              label={`${t('myAccount.myDetails.label.company')} *`}
              className="updateProfile__input"
              ref={register({ required: true })}
              errorMessage={
                errors.company ? t(`form.error.${errors.company.type}`) : ''
              }
            />
          </>
        )}

        <FormInput
          id="firstName"
          name="firstName"
          disabled={isAttachFidelityCardNumber}
          label={`${t('myAccount.myDetails.label.firstName')} *`}
          className="updateProfile__input"
          ref={register({ required: true })}
          errorMessage={
            errors.firstName ? t(`form.error.${errors.firstName.type}`) : ''
          }
        />
        <FormInput
          id="lastName"
          name="lastName"
          disabled={isAttachFidelityCardNumber}
          label={`${t('myAccount.myDetails.label.lastName')} *`}
          className="updateProfile__input"
          ref={register({ required: true })}
          errorMessage={
            errors.lastName ? t(`form.error.${errors.lastName.type}`) : ''
          }
        />
        {!userInfo.isPro && (
          <Controller
            className="updateProfile__radio"
            control={control}
            name="birthdayDate"
            defaultValue={undefined}
            rules={{
              required: true,
              validate: {
                isValidDate: (value) => {
                  return !!value && Date(value).isValid();
                },
                isInAge: (value) => {
                  return Date().diff(value, 'year') >= 18;
                }
              }
            }}
            render={({ onChange, value, name }) => (
              <FormDate
                id={name}
                name={name}
                label={`${t('myAccount.myDetails.label.birthdayDate')} *`}
                className="updateProfile__input"
                value={value}
                onChange={(newValue) => onChange(newValue)}
                errorMessage={
                  errors.birthdayDate
                    ? t(
                        `form.error.${(errors.birthdayDate as FieldError).type}`
                      )
                    : ''
                }
              />
            )}
          />
        )}
        <div className="updateProfile__phoneLabel">
          {t('myAccount.myDetails.label.phone')} *
        </div>
        <div className="updateProfile__row updateProfile__phone">
          <FormInput
            id="phone"
            name="phone"
            label={t('myAccount.myDetails.label.landlinePhone')}
            className="updateProfile__input"
            maxLength={16}
            ref={register({
              validate: validatePhoneNumber('mobilePhone', getValues)
            })}
            errorMessage={
              errors.phone ? t(`form.error.${errors.phone.type}`) : ''
            }
          />
          <FormInput
            id="mobilePhone"
            name="mobilePhone"
            label={t('myAccount.myDetails.label.mobilePhone')}
            className="updateProfile__input"
            maxLength={16}
            ref={register({
              validate: {
                invalidPhoneNumber: validatePhoneNumber('phone', getValues)
                  .invalidPhoneNumber
              }
            })}
            errorMessage={
              errors.mobilePhone
                ? t(`form.error.${errors.mobilePhone.type}`)
                : ''
            }
          />
        </div>
        <div className="updateProfile__buttons">
          <Button
            className="updateProfile__reset"
            type="button"
            color="secondary"
            onClick={() =>
              reset({
                ...userInfo
              })
            }
          >
            {t('myAccount.myDetails.cancel')}
          </Button>
          <Button
            className="updateProfile__submit"
            type="submit"
            loading={state.updating}
          >
            {t('myAccount.myDetails.validate')}
          </Button>
        </div>
      </form>
      <Modal
        className="updateProfile__modal"
        pageView="MyAccountCardDissociation"
        open={state.cardDissociation}
        onClose={() => {
          setState({
            ...state,
            cardDissociation: false
          });
        }}
        confirmBtn={{
          label: t('myAccount.myDetails.confirm'),
          onClick: async () => {
            if (state.newUserInfo) {
              await update(state.newUserInfo, true);
            }
          }
        }}
        cancelBtn={{
          label: t('myAccount.myDetails.cancel'),
          onClick: () => {
            setState({
              ...state,
              cardDissociation: false
            });
          }
        }}
      >
        <>
          <h2 className="updateProfile__modal_title">
            {t('myAccount.myDetails.cardDissociation.title')}
          </h2>
          <p className="updateProfile__modal_message">
            {t('myAccount.myDetails.cardDissociation.message')}
          </p>
        </>
      </Modal>
    </div>
  );
};

export default UpdateProfile;
