import Link from '@/components/atoms/link';
import PreferencesApi from '@/lib/api/preferences';
import t from '@/lib/i18n';
import logger from '@/lib/logger/base';
import useShallowEqualSelector from '@/lib/utils/useShallowEqualSelector';
import { selectUser } from '@/store/auth/authSelectors';
import {
  Action,
  UpdateSurveyAction,
  UPDATE_SURVEY_ANSWER
} from '@/store/survey/actions';
import {
  selectGetFilledAnswers,
  selectHasFilledAllAnswers
} from '@/store/survey/surveySelectors';
import { SurveyState } from '@/store/survey/types';
import React, { useEffect, useState } from 'react';
import ModalError from '../../Modal/ModalError';
import surveyList from '../survey.json';
import './style.scss';

type StepFooterProps = {
  moveToNextStep: () => void;
  currentStep: number;
  totalSteps: number;
  state: SurveyState;
  previousStep: () => void;
  nextStep: () => void;
  dispatch: React.Dispatch<Action>;
};

const StepFooter = ({
  nextStep,
  previousStep,
  currentStep,
  totalSteps,
  state,
  moveToNextStep,
  dispatch
}: StepFooterProps) => {
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const user = useShallowEqualSelector(selectUser);
  const filledAnswers = useShallowEqualSelector(selectGetFilledAnswers);
  const hasFilledAllAnswers = useShallowEqualSelector(
    selectHasFilledAllAnswers
  );

  const currentQuestionId = surveyList.questions[currentStep - 1].questionId;

  const isOnFirstStepsValidation =
    currentQuestionId === 3 && !state.hasPassedFirstStep;

  const currentResponse = !hasFilledAllAnswers
    ? state.responses.find(
        (response) => response.questionId === currentQuestionId
      )
    : state.responses.find(
        (response) => response.questionId === currentStep - 1
      );

  const currentResponseValue =
    currentResponse?.responseValue ||
    currentResponse?.selectedChoices
      ?.map(({ suggestionId }) => suggestionId)
      .sort((a, b) => a - b)
      .join();

  const [oldResponseValue, setOldResponseValue] = React.useState(
    currentResponseValue
  );

  const [isEmpty, setIsEmpty] = React.useState(true);

  const isFirstStepValidated =
    totalSteps === surveyList.questions.length
      ? currentStep > 3
      : filledAnswers.length > 3 ||
        // Edge case when the user select the third question with an existing survey
        (filledAnswers.length === 3 && currentQuestionId !== 2);

  const isLast = currentStep === totalSteps;

  const isEditMode = hasFilledAllAnswers && !!oldResponseValue;

  const cantSkip = isEmpty && !isFirstStepValidated;

  const cancelResponse = () => {
    dispatch({
      type: UPDATE_SURVEY_ANSWER,
      payload: {
        response: {
          questionId: currentQuestionId
        }
      }
    } as UpdateSurveyAction);
  };

  useEffect(() => {
    setIsEmpty(
      !currentResponse?.selectedChoices?.length &&
        !currentResponse?.responseValue
    );
  }, [
    currentResponse?.responseValue,
    currentResponse?.selectedChoices?.length
  ]);

  useEffect(() => {
    setOldResponseValue(currentResponseValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  if (isOnFirstStepsValidation) return null;

  const submitResults = async (onSucceed: () => void) => {
    try {
      if (user) {
        await PreferencesApi.update(user.id, state);
        onSucceed();
      }
    } catch (error: any) {
      setOpenErrorModal(true);
      logger.error({
        message: 'Unable to save the survey responses',
        error,
        context: {
          state
        }
      });
    }
  };

  const onCloseErrorModal = () => {
    setOpenErrorModal(false);
    window.location.href = '/account-management/smart-conso';
  };

  let onClickValidate = nextStep;
  let onClickSkip = () => {
    cancelResponse();
    nextStep();
  };

  if (isEditMode) {
    onClickSkip = () => {
      nextStep();
    };
  }

  if (isLast) {
    if (!isEmpty) {
      onClickValidate = () => {
        submitResults(() => {
          moveToNextStep();
        });
      };
    }

    if (isEditMode) {
      onClickSkip = () => {
        submitResults(() => {
          moveToNextStep();
        });
      };
    } else {
      onClickSkip = () => {
        submitResults(() => {
          cancelResponse();
          moveToNextStep();
        });
      };
    }
  }

  return (
    <>
      <div className="step-footer">
        <Link
          className="step-footer__button-continue"
          disabled={cantSkip}
          onClick={() => {
            if (cantSkip) return;
            currentResponseValue ? onClickValidate() : onClickSkip();
          }}
          label={t('smartconsoForm.button.continue')}
          button
        />
        {currentStep > 1 && (
          <Link
            className="step-footer__button-previous"
            onClick={() => {
              previousStep();
            }}
            label={t('smartconsoForm.button.previous')}
          />
        )}
      </div>
      <ModalError
        open={openErrorModal}
        closeModal={() => onCloseErrorModal()}
        validate={() => {
          setOpenErrorModal(false);
          onClickValidate();
        }}
        errorTitle={t('error.500.message')}
        errorMessage={t('smartConsoForm.error.message')}
        cancelBtnLabel={t('smartconso.backModal.confirm')}
      />
    </>
  );
};

export default StepFooter;
