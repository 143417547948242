import React from 'react';
import Modal from '@/components/organisms/modal';
import t from '@/lib/i18n';
import './style.scss';

type ModalConsentSesProps = {
  validate: () => void;
  closeModal: () => void;
  open: boolean;
  errorTitle?: string;
  errorMessage?: string;
  cancelBtnLabel?: string;
};

const ModalError = ({
  open,
  closeModal,
  validate,
  errorTitle,
  errorMessage,
  cancelBtnLabel
}: ModalConsentSesProps) => (
  <Modal
    open={open}
    closable={false}
    className="resetModal"
    scrollable={false}
    confirmBtn={{
      label: t('retry'),
      onClick: validate,
      isLink: true
    }}
    cancelBtn={{
      label: cancelBtnLabel || t('myAccount.smartConso.modalRefuse.cancel'),
      onClick: closeModal,
      isLink: true
    }}
  >
    <>
      {errorTitle && <h3 className="resetModal__content">{errorTitle}</h3>}
      {errorMessage && <p className="resetModal__content">{errorMessage}</p>}
    </>
  </Modal>
);

export default ModalError;
