import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import CSSTransition from 'react-transition-group/CSSTransition';

// eslint-disable-next-line import/no-cycle
import BottomProductsLayer from '../bottomProductsLayer';
import { BottomProductsLayerProps } from '../bottomProductsLayer/types';

const RecommendationProductPopin = ({
  productModel,
  productList,
  onCancel,
  onValidate,
  setIsOpen,
  hasFooter,
  title,
  information,
  isOpen,
  isCheckout
}: BottomProductsLayerProps) => {
  const ref = useRef(null);

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = isOpen
      ? 'hidden'
      : 'auto';
  }, [isOpen]);

  const getPortal = () => {
    if (typeof document === 'undefined') {
      return null;
    }
    return ReactDOM.createPortal(
      <CSSTransition
        timeout={300}
        in={isOpen}
        classNames="productsLayer"
        nodeRef={ref}
        unmountOnExit
        mountOnEnter
        onEntering={() => {
          if (!isCheckout) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }
        }}
      >
        <BottomProductsLayer
          productModel={productModel}
          productList={productList}
          title={title}
          information={information}
          hasFooter={hasFooter}
          setIsOpen={setIsOpen}
          onCancel={onCancel}
          onValidate={onValidate}
        />
      </CSSTransition>,
      document.getElementById('__next') as Element
    );
  };

  return <div className="recommendationProducts__popin">{getPortal()}</div>;
};

export default RecommendationProductPopin;
