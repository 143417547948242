import React, { Key, useState, useEffect } from 'react';
import {
  RendererComponent as ContentfulRenderComponent,
  Component
} from '@/lib/contentful';
import usePdv, { ReturnType } from '@/lib/hooks/usePdv';
import CampaignSlider, { Slide as CampaigneSlide } from '../campaignSlider';
import { Slide as CategorySlide } from '../categorySlider';
import { HeadingProps } from '../heading';

export type ContentProps = {
  heading?: HeadingProps;
  categories?: {
    slides: Array<CategorySlide>;
  };
  slider?: {
    slides: Array<CampaigneSlide>;
  };
  blocks?: Array<Component>;
};

const Content = ({
  slider = {
    slides: []
  },
  blocks = []
}: ContentProps) => {
  const [pdvState, setPdvState] = useState<ReturnType | null>(null);
  // const [blockState, setBlockState] = useState<Component[]>(blocks);
  const [blockState] = useState<Component[]>(blocks);

  useEffect(() => {
    const pdv: ReturnType = usePdv();
    setPdvState(pdv);
    // if (pdvState?.ref && pdvState?.isEcommerce) {
    //   const shocaseComponent: Component = {
    //     type: 'organisms',
    //     name: 'showcase',
    //     props: {
    //       pdvRef: pdvState.ref
    //     }
    //   };
    //   setBlockState([shocaseComponent, ...blocks]);
    // }
    // }, [blocks, pdvState?.ref, pdvState?.isEcommerce]);
  }, [pdvState?.ref, pdvState?.isEcommerce]);

  return (
    <>
      {slider && slider.slides.length > 0 && (
        <div className="container home__sliderContainer">
          <CampaignSlider slides={slider.slides} />
        </div>
      )}

      {pdvState?.ref && pdvState?.isEcommerce && (
        <div data-regie-id="regie-homePdv-top" />
      )}
      <div className="home__blocks">
        {blockState &&
          blockState.map((block, index: Key) => {
            const key = `home-block-${index}`;
            return (
              <div key={key} className="home__block">
                <ContentfulRenderComponent component={block} />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Content;
