import React, { memo } from 'react';

import InfoTooltip from './infoTooltip';

export type VignetteProps = {
  cpt?: number;
  image: string;
  label: string;
  pdvIsActivated?: number;
  code: string;
};

const Vignette = ({
  image,
  cpt = 0,
  label,
  pdvIsActivated,
  code
}: VignetteProps) => {
  return (
    <div className="operation__body_vignette">
      <div className="operation__body_vignette--head">
        <InfoTooltip
          pdvIsActivated={pdvIsActivated}
          tooltipId={`${code}${cpt}${label}`}
        />
        <div className="operation__body_vignette--content">
          <svg width="24" height="24">
            <use xlinkHref={image} />
          </svg>
          {cpt}
        </div>
      </div>
      <div className="operation__body_vignette--foot">{label}</div>
    </div>
  );
};

export default memo(Vignette);
