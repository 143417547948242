import React from 'react';
import classnames from 'classnames';
import Image from '@/components/atoms/image';
import './style.scss';

export type CarouselWideEntryProps = {
  data: {
    id?: string;
    title?: string;
    caption?: string;
    src?: string;
    alt?: string;
  };
};

const CarouselWideEntry = ({ data }: CarouselWideEntryProps) => {
  const isHtmlCmsText = /^</;
  return (
    <div className={classnames('carouselWideEntry', data.id)}>
      <div className="carouselWideEntry__content__title">{data.title}</div>

      <div className="carouselWideEntry__content__image">
        {data.src && data.alt && (
          <Image
            className={classnames('picture', `picture-${data.alt}`)}
            src={data.src}
            alt={data.alt}
          />
        )}
      </div>

      {!data.caption?.match(isHtmlCmsText)?.length ? (
        <div className="carouselWideEntry__content__caption">
          {data.caption}
        </div>
      ) : (
        <div
          className="carouselWideEntry__content__caption"
          dangerouslySetInnerHTML={{ __html: data.caption }}
        />
      )}
    </div>
  );
};

export default CarouselWideEntry;
