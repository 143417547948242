import { PartenersDatasProps } from '@/lib/thirdPartyApi';

export const lazyAddCriteo = (
  setPartnersDatas: any,
  tileCriteoRef1: any,
  tileCriteoRef2: any
) => ({
  butterflyProducts1,
  butterflyProducts2
}: {
  butterflyProducts1: any;
  butterflyProducts2?: any;
}): Promise<any> => {
  /* eslint-disable no-console */
  console.info('*** butterflyProducts ***');
  if (
    (!Array.isArray(butterflyProducts1) || !butterflyProducts1.length) &&
    (!Array.isArray(butterflyProducts2) || !butterflyProducts2.length)
  ) {
    throw new Error(
      '"butterflyProducts" must be an array containing at least one product'
    );
  }

  setPartnersDatas((partnesDatasTemp: PartenersDatasProps) => ({
    ...partnesDatasTemp,
    ...(butterflyProducts1 && {
      criteoButterflyProducts1: butterflyProducts1
    }),
    ...(butterflyProducts2 && {
      criteoButterflyProducts2: butterflyProducts2
    })
  }));

  return new Promise((resolve) => {
    resolve(
      [
        butterflyProducts1 && tileCriteoRef1,
        butterflyProducts2 && tileCriteoRef2
      ].filter(Boolean)
    );
  });
};
