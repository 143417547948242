import React from 'react';
import PdvApi from '@/lib/api/pdv';
import { Request, Response } from 'express';
import PdvModel from '@/lib/model/pdv';
import usePdv from '@/lib/hooks/usePdv';
import { Provider } from 'react-redux';
import { store } from '@/store';
import {
  Content,
  Content as ContentfulContent,
  fetchContent
} from '@/lib/contentful';
import { ContentfulStoreProps } from '@/lib/contentful/normalizers/magasin';
import { NextSeo } from 'next-seo';
import t from '@/lib/i18n';
import StorePdv from '../content';

/* eslint-disable react/no-unused-prop-types */
type StorePdvESIProps = {
  servicesList?: ContentfulContent;
  contentfulStore?: ContentfulStoreProps;
  pdv?: PdvModel;
  pdvRef?: string;
};

type InitialProps = {
  props: {
    pdvRef?: string;
  };
  req: Request;
  res: Response;
};

const StorePdvESI = ({
  pdv,
  servicesList,
  contentfulStore
}: StorePdvESIProps) => {
  const tradsParams = {
    '%format%': pdv?.format?.toUpperCase(),
    '%city%': pdv?.address?.city,
    '%zipCode%': pdv?.address?.zipCode
  };

  return (
    <Provider store={store}>
      {!!pdv && !!servicesList && !!contentfulStore && (
        <>
          <NextSeo
            title={t('seo.pageShop.title', tradsParams)}
            description={t('seo.pageShop.description', tradsParams)}
          />
          <StorePdv
            pdv={pdv}
            servicesList={servicesList}
            contentfulStore={contentfulStore}
          />
        </>
      )}
    </Provider>
  );
};

StorePdvESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  let ref = props.pdvRef;
  const preview = req?.cookies?.__next_preview_data;

  if (!ref) {
    ref = usePdv(req).ref;
  }

  let pdv: PdvModel | null = null;
  let cmsData: Array<Content | null> = [];

  if (ref) {
    try {
      pdv = await PdvApi.getPdvInfo(ref);

      cmsData = await Promise.all([
        fetchContent({ contentType: 'magasin', preview }),
        fetchContent({ contentType: 'servicesList', preview })
      ]);
    } catch (error: any) {
      pdv = null;
      cmsData = [];
    }
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=21600, max-age=21600');
      res.set('Surrogate-Control', 'content="ESI/1.0"');
    }

    let data = null;

    if (pdv) {
      data = {
        pdv: {
          name: pdv.name,
          format: pdv.format,
          message: pdv.message,
          ref: pdv.ref,
          address: pdv.address,
          phone: pdv.phone,
          allServicesCodes: pdv.allServicesCodes,
          exceptionDates: pdv.exceptionDates,
          getNextOpeningHours: pdv.getNextOpeningHours(),
          types: pdv.types,
          fax: pdv.fax,
          calendar: pdv.calendar
        },
        contentfulStore: cmsData[0],
        servicesList: cmsData[1]
      };
    }

    resolve({
      ...props,
      ...data
    });
  });
};

export default StorePdvESI;
