import { useEffect, useState } from 'react';

type StateType = {
  data: boolean;
  loading: boolean;
};

export const useDidomi = (
  key: string,
  consentsList = 'didomiPurposesConsent'
) => {
  const [state, setState] = useState<StateType>({
    data: false,
    loading: true
  });

  useEffect(() => {
    if (!(typeof window === 'undefined')) {
      setState((s) => ({
        ...s,
        // @ts-ignore
        data: window?.didomiState?.[consentsList]?.includes(key),
        loading: false
      }));
    }
  }, [key, consentsList]);
  return state;
};
