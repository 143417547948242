import React, { useState } from 'react';
import { Dayjs } from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import t from '@/lib/i18n';
import Button from '@/components/atoms/button';
import FormInput from '@/components/atoms/formInput';
import FormDate from '@/components/atoms/formDate';
import FormFidelityCard from '@/components/atoms/formFidelityCard';
import Date from '@/lib/utils/date';
import PublicFidelityApi from '@/lib/api/fidelity/public';
import { Informations } from '@/lib/api/userInformation/types';

import { ReactComponent as CardIcon } from '@/assets/images/icons/accepted-new-card.svg';
import UserInformation from '@/lib/api/userInformation';
import Modal from '@/components/organisms/modal';
import './style.scss';
import Link from '@/components/atoms/link';
import { useDispatch, useSelector } from 'react-redux';
import { CardCreationStateType } from '@/store/cardCreation/reducer';
import { RendererComponents as ContentfulRenderComponents } from '@/lib/contentful';
import { ICardValidationErrorException } from '@/lib/api/fidelity/exceptions';
import { FidelityInfo } from '@/lib/api/fidelity/types';
import useEvent from '@/lib/hooks/useEvent';
import { SET_CARD_LS_CREATION_STATE } from '@/store/cardCreation/actions';
import Title from '@/components/atoms/title';

type ModalLinkCardProps = {
  isActiveCardContext: boolean;
  userInfo: Informations;
  label: string;
};

type FormProps = {
  fidelityCardNumber: string | null;
  firstName: string;
  lastName: string;
  birthdayDate: Dayjs;
};

type StateProps = {
  isOpen: boolean;
  isLoading: boolean;
  cardValidityErrorMessage: ICardValidationErrorException | null;
  step: string;
  acceptedCGU: boolean;
  nextFidelityCardNumber: string;
  pageView: string;
  cardInfos: FidelityInfo | null;
  attachCard?: boolean;
  keepOldCard?: boolean;
  needRefresh?: boolean;
};

const ModalLinkCard = ({
  userInfo,
  isActiveCardContext,
  label
}: ModalLinkCardProps) => {
  const { errors, control, register, handleSubmit } = useForm<FormProps>({
    defaultValues: {
      fidelityCardNumber: null,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      birthdayDate: userInfo?.birthdayDate || null
    }
  });
  const event = useEvent();
  const dispatch = useDispatch();

  const [state, setState] = useState<StateProps>({
    isOpen: false,
    isLoading: false,
    cardValidityErrorMessage: null,
    step: 'newCard',
    acceptedCGU: false,
    nextFidelityCardNumber: '',
    pageView: 'AttachFidCard',
    cardInfos: null,
    attachCard: false
  });

  const { cgu } = useSelector(
    ({ cardCreation }: { cardCreation: CardCreationStateType }) => {
      return {
        cgu: cardCreation.cgu
      };
    }
  );

  const submitNewCard = async (attachCard?: boolean) => {
    let currentError: ICardValidationErrorException;
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      cardValidityErrorMessage: null,
      attachCard
    }));
    try {
      await UserInformation.updateFidelityCard(
        userInfo,
        state.nextFidelityCardNumber,
        state.cardInfos,
        attachCard
      );

      if (attachCard && state.cardInfos?.type === 'LS') {
        dispatch({
          type: SET_CARD_LS_CREATION_STATE,
          payload: {
            inProgress: true,
            nextFidelityCardNumber: state.nextFidelityCardNumber
          }
        });
      }
      event.send('api', {
        name: 'attachCard',
        type: 'success'
      });
      setState((prevState) => ({
        ...prevState,
        step: 'final',
        needRefresh: !(attachCard && state.cardInfos?.type === 'LS'),
        pageView: 'AttachFidCardSuccess'
      }));
    } catch (error: any) {
      currentError = error;
      event.send('api', {
        name: 'attachCard',
        type: 'error'
      });
      setState((s) => ({
        ...s,
        pageView: 'AttachFidCardError',
        step: attachCard ? s.step : 'error'
      }));
    } finally {
      setState((s) => ({
        ...s,
        isLoading: false,
        cardValidityErrorMessage: currentError
      }));
    }
  };

  const submitStep1 = async (form: any) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      cardValidityErrorMessage: null
    }));

    const body = {
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      fidelityCardNumber: form.fidelityCardNumber,
      birthdayDate: userInfo?.birthdayDate
    };

    let currentError: ICardValidationErrorException;

    try {
      const cardInfos = await PublicFidelityApi.isValidFidelity(body);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        nextFidelityCardNumber: form.fidelityCardNumber,
        step: 'cgu',
        pageView: 'AttachFidCardCGU',
        cardInfos
      }));
    } catch (error: any) {
      currentError = error;
    } finally {
      setState((s) => ({
        ...s,
        isLoading: false,
        cardValidityErrorMessage: currentError
      }));
    }
  };

  const submitStep2 = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      cardValidityErrorMessage: null
    }));

    if (userInfo.fidelityCardNumber) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        step: 'alreadyLinked',
        pageView: 'ModifyAttachFidInfo'
      }));
    } else {
      submitNewCard(true);
    }
  };

  return (
    <>
      {isActiveCardContext ? (
        <Link
          label={label}
          className="summaryBox__activate-link"
          onClick={() => {
            if (!userInfo) {
              window.location.href =
                '/connection?redirect=/fidelity-advantages';
            } else {
              setState((prevState) => ({
                ...prevState,
                isOpen: true
              }));
            }
          }}
        />
      ) : (
        <Button
          className="summaryBox__activate-link"
          label={t('fidelityCard.activateCardBloc.second.link')}
          onClick={() =>
            setState((prevState) => ({
              ...prevState,
              isOpen: true
            }))
          }
        />
      )}
      <Modal
        className="modalLinkCard"
        pageView={state.pageView}
        open={state.isOpen}
        scrollable={true}
        closable={state.step !== 'final'}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isOpen: false,
            step: 'newCard',
            pageView: 'AttachFidCard',
            cardValidityErrorMessage: null,
            isLoading: false,
            keepOldCard: false,
            needRefresh: false
          }))
        }
      >
        <>
          {state.step === 'newCard' && (
            <form
              className="formAttachCard"
              onSubmit={handleSubmit(submitStep1)}
            >
              <div className="title">
                {t('myAccount.form.attachCard.title')}
              </div>
              <div className="description">
                {t('myAccount.form.attachCard.description')}
              </div>
              <Controller
                control={control}
                name="fidelityCardNumber"
                rules={{
                  required: true,
                  validate: {
                    rules: (value) => {
                      return value.split('').length === 19;
                    }
                  }
                }}
                render={({ onChange, value, name }) => (
                  <>
                    <FormFidelityCard
                      className="inputRegister"
                      placeholder="3250 / 390 / XXXX / XXXX / XXXX"
                      id={name}
                      name={name}
                      value={value}
                      onChange={(newValue) => onChange(newValue)}
                      label={t('register.fidelity.label')}
                      errorMessage={
                        errors.fidelityCardNumber
                          ? t(`form.error.fidelityCardNumber.size`)
                          : ''
                      }
                    />
                    {state.cardValidityErrorMessage && (
                      <div className="errorMessage">
                        {state.cardValidityErrorMessage.getTrad()}
                      </div>
                    )}
                  </>
                )}
              />
              <FormInput
                id="lastName"
                name="lastName"
                type="text"
                ref={register({ required: true, maxLength: 32 })}
                className="inputFormAttach"
                label={`${t('myAccount.myDetails.label.lastName')}`}
                maxLength={32}
                disabled
              />
              <FormInput
                id="firstName"
                name="firstName"
                className="inputFormAttach"
                ref={register({ required: true, maxLength: 32 })}
                label={`${t('myAccount.myDetails.label.firstName')}`}
                maxLength={32}
                disabled
              />
              <Controller
                control={control}
                name="birthdayDate"
                defaultValue={undefined}
                rules={{
                  required: true,
                  validate: {
                    isValidDate: (value) => {
                      return !!value && Date(value).isValid();
                    },
                    isInAge: (value) => {
                      return Date().diff(value, 'year') >= 18;
                    }
                  }
                }}
                render={({ onChange, value, name }) => (
                  <FormDate
                    disabled
                    className="inputFormAttach"
                    id={name}
                    name={name}
                    value={value}
                    placeholder={t('register.birthday.placeholder')}
                    label={`${t('myAccount.myDetails.label.birthdayDate')}`}
                    onChange={(newValue) => onChange(newValue)}
                  />
                )}
              />
              <div className="btnFooter">
                <Button
                  type="submit"
                  label={t('myAccount.dashboard.fidelity.attachCard')}
                  loading={state.isLoading}
                />
              </div>
            </form>
          )}
          {state.step === 'cgu' && (
            <form
              className="formAttachCard"
              onSubmit={handleSubmit(submitStep2)}
            >
              <div className="formAttachCard__cgu">
                <Title tag="h1" label={t('common.cguFid.popinTitle')} />
                <ContentfulRenderComponents components={cgu.components} />
              </div>
              {state.cardValidityErrorMessage && (
                <div className="errorMessage">
                  {state.cardValidityErrorMessage.getTrad()}
                </div>
              )}
              <div className="btnFooter">
                <div className="btnFooter__btn">
                  <Button
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isOpen: false,
                        step: 'newCard',
                        cardValidityErrorMessage: null
                      }))
                    }
                    label={t('register.cgu.btnCancel')}
                    color="secondary"
                    id="declineCgu"
                  />
                  <Button
                    type="submit"
                    label={t('register.cgu.btnConfirm')}
                    id="acceptCgu"
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        cardValidityErrorMessage: null,
                        isLoading: true,
                        acceptedCGU: true
                      }))
                    }
                    loading={state.isLoading}
                  />
                </div>
              </div>
            </form>
          )}
          {state.step === 'alreadyLinked' && (
            <div className="attachCardFinal">
              <div className="title">
                {t('fidelity.form.alreadyLinked.title')}
              </div>
              {!state.cardValidityErrorMessage && (
                <div className="description">
                  {t('fidelity.form.alreadyLinked.description')}
                </div>
              )}
              {state.cardValidityErrorMessage && (
                <div className="attachCardError">
                  {state.cardValidityErrorMessage.getTrad()}
                </div>
              )}
              <div className="btnFooter">
                <div className="btnFooter__btn">
                  <Button
                    color="secondary"
                    onClick={() => {
                      setState((s) => {
                        return {
                          ...s,
                          keepOldCard: true
                        };
                      });
                      submitNewCard(false);
                    }}
                    label={t('fidelity.form.alreadyLinked.cancel')}
                  />
                  <Button
                    onClick={() => {
                      setState((s) => {
                        return {
                          ...s,
                          keepOldCard: false
                        };
                      });
                      submitNewCard(true);
                    }}
                    label={t('fidelity.form.alreadyLinked.confirm')}
                  />
                </div>
              </div>
            </div>
          )}
          {state.step === 'final' && (
            <div className="attachCardFinal">
              <div className="attachCardIcon">
                <CardIcon width={160} />
              </div>
              <div className="title">{t('fidelity.form.finalStep.title')}</div>
              <div className="description">
                {t(
                  state.attachCard && !state.keepOldCard
                    ? 'fidelity.form.finalStep.description'
                    : 'fidelity.form.finalStep.description.keep'
                )}
              </div>
              <div className="btnFooter">
                <Button
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      isOpen: false,
                      step: 'newCard',
                      cardValidityErrorMessage: null
                    }));
                    if (state.needRefresh) {
                      window.location.href = '/fidelity-advantages';
                    }
                  }}
                  label={t('fidelity.form.finalStep.btn')}
                />
              </div>
            </div>
          )}
          {state.step === 'error' && state.cardValidityErrorMessage && (
            <div className="attachCardFinal">
              <div className="title">
                {state.cardValidityErrorMessage.getTrad()}
              </div>
              <div className="description">
                <p>{t('common.cguFid.error.retry')}</p>
              </div>
              <div className="btnFooter">
                <div className="btnFooter__btn">
                  <Button
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isOpen: false,
                        step: 'newCard',
                        cardValidityErrorMessage: null
                      }))
                    }
                    label={t('common.cguFid.error.btnRetryLater')}
                    color="secondary"
                  />
                  <Button
                    label={t('common.cguFid.error.btnRetryNow')}
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        cardValidityErrorMessage: null,
                        step: 'newCard'
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </>
      </Modal>
    </>
  );
};

export default ModalLinkCard;
