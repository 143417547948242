import BaseWithESI from 'jamyouss-react-esi';

type ComponentOptions = {
  type: string;
  name: string;
};

type AnalyticsOptions = {
  name: string;
};

type WithESIOptions = {
  id: string;
  isAnalytics?: boolean;
} & (ComponentOptions | AnalyticsOptions);

export interface IWithESIProps {
  esi?: {
    attrs?: {
      [key: string]: string | null;
    };
  };
}

export const ESIOptions = () => {
  return {
    attrs: {
      timeout: '10',
      alt: '',
      onerror: 'continue'
    }
  };
};

export const withESI = <P>(
  component: React.ComponentType<P>,
  options: WithESIOptions
): React.ComponentClass<IWithESIProps & P> => {
  const idData = Buffer.from(JSON.stringify(options)).toString('base64');

  return BaseWithESI(component, idData);
};

export const getFragment = (options: WithESIOptions) => {
  if (options.isAnalytics ?? false) {
    const analyticsOptions = options as AnalyticsOptions;

    /* eslint-disable import/no-dynamic-require */
    /* eslint-disable @typescript-eslint/no-var-requires */
    return require(`../../lib/analytics/esi/${analyticsOptions.name}ESI.tsx`)
      .default;
  }

  const componentOptions = options as ComponentOptions;

  /* eslint-disable import/no-dynamic-require */
  /* eslint-disable @typescript-eslint/no-var-requires */
  return require(`../../components/${componentOptions.type}/${componentOptions.name}ESI/index.tsx`)
    .default;
};

export const parseId = (id: string): WithESIOptions => {
  const buffer = Buffer.from(id, 'base64');
  const data = JSON.parse(buffer.toString('ascii'));

  return data;
};
