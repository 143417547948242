import React from 'react';
import { Provider } from 'react-redux';
import PdvApi from '@/lib/api/pdv';
import { Response, Request } from 'express';
import Pdv from '@/lib/model/pdv';
import usePdv from '@/lib/hooks/usePdv';
import { store } from '@/store';
import Content, { ContentProps } from '../content';

type InitialProps = {
  props: ContentProps;
  req: Request;
  res: Response;
};
const ContentESI = (props: ContentProps) => {
  return (
    <Provider store={store}>
      <Content {...props} />
    </Provider>
  );
};

ContentESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref, isVirtual } = usePdv(req);

  let pdv: Pdv | null = null;

  if (ref && !isVirtual) {
    try {
      pdv = await PdvApi.getPdvInfo(ref);
    } catch (error: any) {
      pdv = null;
    }
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=3600, max-age=3600');
    }

    let pdvProps = null;

    if (pdv) {
      pdvProps = {
        ref: pdv.ref,
        format: pdv.format,
        address: pdv.address,
        phone: pdv.phone,
        isClosed: pdv.isClosed, // ECITM-12494
        isOpen: pdv.isOpen(),
        exceptionDate: pdv.getStoreLocatorExceptionDate(),
        nextOpeningDate: pdv.getNextOpeningDate()?.toString(),
        nextClosingDate: pdv.getNextClosingDate()?.toString()
      };
    }

    resolve({
      ...props,
      ...(pdvProps && { pdv: pdvProps })
    });
  });
};

export default ContentESI;
