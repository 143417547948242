import React from 'react';
import './style.scss';
import classnames from 'classnames';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';

export type FidelityLevelValue = 1 | 2 | 3 | 4;

export type FidelityLevelProps = {
  level: FidelityLevelValue;
};

const FidelityLevel = ({ level }: FidelityLevelProps) => {
  return (
    <div className="fidelityLevel">
      {[1, 2, 3].map((i) => {
        const key = `fidelity-level-${i}`;

        return (
          <div
            className={classnames('fidelityLevel__item', {
              'fidelityLevel__item--enabled': i <= level
            })}
            key={key}
          >
            {`${i}.`}
            <svg height="20" width="20">
              <use xlinkHref={`${MyAccountSprite}#cart`} />
            </svg>
          </div>
        );
      })}
    </div>
  );
};

export default FidelityLevel;
