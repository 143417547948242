import { SubNavProps } from '@/components/templates/avantageFidelite/subNav';
import {
  Component,
  normalize as BaseNormalize,
  NormalizeParams
} from '@/lib/contentful';

const normalize = ({
  data,
  includes,
  extras
}: NormalizeParams): ComponentSousNavigation => {
  const { content = [] } = data;
  const itemsList: Array<Component> = [];
  content.forEach((element: any) => {
    const itemData = includes[element.sys.id] || null;
    const dataToPass = {
      ...itemData,
      fields: {
        ...itemData.fields,
        id: element.sys.id
      }
    };
    const baseElement = BaseNormalize({
      data: dataToPass,
      includes,
      extras
    }) as Component;
    if (baseElement) {
      itemsList.push(baseElement);
    }
  });

  return {
    type: 'templates/avantageFidelite',
    name: 'subNav',
    props: {
      navItems: itemsList
    }
  };
};

export default normalize;

export interface ComponentSousNavigation extends Component {
  props: SubNavProps;
}
