import CommonSprite from '@/assets/images/sprites/common.svg';
import Image from '@/components/atoms/image';
import Link from '@/components/atoms/link';
import Select, { Option, Options } from '@/components/atoms/select';
import StaticHeading from '@/components/templates/plp/staticHeading';
import CategoriesApi from '@/lib/api/categories';
import getConfigValue from '@/lib/config';
import { useDesktopMediaQuery } from '@/lib/hooks/useBreakpointMediaQuery';
import useClickOut from '@/lib/hooks/useClickOut';
import useEvent from '@/lib/hooks/useEvent';
import usePageView from '@/lib/hooks/usePageView';
import usePdv, { getPdvRef } from '@/lib/hooks/usePdv';
import { usePrevious } from '@/lib/hooks/usePrevious';
import t from '@/lib/i18n';
import CategoryModel from '@/lib/model/category';
import Product from '@/lib/model/product';
import ProductListModel, {
  Filter as FilterModel,
  Filters as FiltersModel,
  FiltersType,
  FilterType,
  FilterValue,
  Meta,
  Sort,
  SORT_ASCENDING,
  SORT_DESCENDING,
  SORT_PRICE,
  SORT_PRICE_PER_KG,
  SORT_RELEVANCE
} from '@/lib/model/productList';
import { PartenersDatasProps } from '@/lib/thirdPartyApi';
import { lazyAddCriteo } from '@/lib/thirdPartyApi/productGrid/criteo';
import { lazyAddHighCo } from '@/lib/thirdPartyApi/productGrid/highco';
import { lazyAddLuckyCart } from '@/lib/thirdPartyApi/productGrid/luckyCart';
import { store } from '@/store';
import { AuthStateType } from '@/store/auth/authReducer';
import { clearLastEanOrSku } from '@/store/common/actions/common';
import { CommonStateType } from '@/store/common/commonReducer';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import fetchData from '../productListESI/fetchData';
import ProductGrid from './productGrid';
import './style.scss';
import VerticalFilters from './verticalFilters';

export type Fetch = (
  page: number,
  filters: FiltersType,
  sort: Sort
) => Promise<ProductListModel>;

export type ProductListProps = {
  list: ProductListModel;
  perPage: number;
  baseUrl: string;
  filters: FiltersType;
  sort: Sort;
  fetch: Fetch;
  type: 'category' | 'search' | 'shop';
  categoryId?: string;
  keyword?: string;
  activableFilters?: FiltersType;
};

type PreloadedPages = Array<ProductListModel>;

const getQueryString = (
  page: number,
  filters: FiltersType,
  sort: Sort
): string => {
  const elts: { [key: string]: string | number | Array<string | number> } = {
    page,
    trier: sort.type
  };

  if (sort.direction) {
    elts['ordre'] = sort.direction;
  }

  filters.forEach((filter: FilterType) => {
    elts[filter.type] = filter.values;
  });

  return queryString.stringify(elts);
};

type StateType = {
  filters: FiltersType;
  sort: Sort;
  banner?: string;
  list: {
    products: Product[];
    filters: FiltersModel;
    meta: Meta;
  };
  meta: {
    firstPage: number;
    lastPage: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  };
  scrollY: number;
  headingFilter: FilterValue;
  initialFilters: FiltersModel;
  activableFilters: FiltersModel;
};

const Content = (
  props: ProductListProps & {
    sendPageView: (filters: FiltersType, sort: Sort) => void;
  }
) => {
  const {
    list,
    perPage,
    baseUrl,
    filters,
    sort,
    type,
    sendPageView,
    fetch
  } = props;

  const dispatch = useDispatch();
  const pageView = usePageView();
  const eventGTM = useEvent();
  const router = useRouter();
  const preloadedPages = useRef<PreloadedPages>([]);
  const bannerLuckyCart = useRef(null);
  const tileCriteo1 = useRef(null);
  const tileCriteo2 = useRef(null);
  const tileHighCo = useRef(null);
  const { commonStore, userId } = useSelector(
    ({ common, auth }: { common: CommonStateType; auth: AuthStateType }) => {
      return {
        commonStore: common,
        userId: auth.user?.id
      };
    },
    shallowEqual
  );
  const [data, setData] = useState<StateType>({
    filters,
    sort,
    banner: list.banner,
    list: {
      products: list.products,
      filters: list.filters,
      meta: list.meta
    },
    meta: {
      firstPage: list.meta.page,
      lastPage: list.meta.page,
      hasPreviousPage: list.meta.hasPreviousPage,
      hasNextPage: list.meta.hasNextPage
    },
    scrollY: 0,
    headingFilter: list.headings?.[0],
    initialFilters: list.filters,
    activableFilters: list.filters
  });

  const [partnersDatas, setPartnersDatas] = useState<PartenersDatasProps>({
    covers: null,
    criteoButterflyProducts1: null,
    criteoButterflyProducts2: null,
    tileCriteo1,
    tileCriteo2,
    tileHighCo,
    isHighcoExist: false,
    isLuckyCartExist: false
  });
  const isPromoPage = list?.shop?.id.toUpperCase() === 'PROMOTIONS';

  const [luckyCartData] = useState<Array<Product>>(list.products);

  const IS_SMART_CONSO_LOT2 = getConfigValue(
    'IS_SMART_CONSO_LOT2',
    false
  ).toBoolean();
  const IS_EMERCH_ENABLED = getConfigValue(
    'IS_EMERCH_ENABLED',
    false
  ).toBoolean();

  const getUrlForPage = (page: number): string => {
    const qs = getQueryString(page, data.filters, data.sort);

    return `${baseUrl}${qs ? `?${qs}` : ''}`;
  };

  const loadPage = useCallback(
    async (page: number): Promise<ProductListModel | null> => {
      let productList = preloadedPages.current
        .reverse() // In order to retrieve the last updated value
        .find((preloadedPage: ProductListModel) => {
          return page === preloadedPage.meta.page;
        });

      if (productList) {
        preloadedPages.current = preloadedPages.current.filter(
          (preloadedPage: ProductListModel) => {
            return page !== preloadedPage.meta.page;
          }
        );
      } else {
        productList = await fetchAndFilterHeading(
          page,
          data.filters,
          data.sort
        );

        if (!productList.meta.total) return null;
      }
      return productList;
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const loadPreviousPage = async (page: number): Promise<void> => {
    const productList = await loadPage(page);

    if (productList) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          hasPreviousPage: productList.meta.hasPreviousPage,
          firstPage: productList.meta.page
        },
        list: {
          ...data.list,
          products: [...productList.products, ...data.list.products]
        },
        scrollY: window.scrollY
      });
    }
  };
  const prevIsHydrated = usePrevious(commonStore.isHydrated);

  const isDesktop = useDesktopMediaQuery();
  const [isOpen, setIsOpen] = useState(false);

  const sortFilters = [
    {
      value: 0,
      label: t('productList.filters.sortBy.default'),
      extra: {
        type: SORT_RELEVANCE,
        direction: null
      }
    },
    {
      value: 1,
      label: t('productList.filters.sortBy.priceIncreasing'),
      active:
        data.sort.type === SORT_PRICE && data.sort.direction === SORT_ASCENDING,
      extra: {
        type: SORT_PRICE,
        direction: SORT_ASCENDING
      }
    },
    {
      value: 2,
      label: t('productList.filters.sortBy.priceDecreasing'),
      active:
        data.sort.type === SORT_PRICE &&
        data.sort.direction === SORT_DESCENDING,
      extra: {
        type: SORT_PRICE,
        direction: SORT_DESCENDING
      }
    },
    {
      value: 3,
      label: t('productList.filters.sortBy.priceUnitIncreasing'),
      active:
        data.sort.type === SORT_PRICE_PER_KG &&
        data.sort.direction === SORT_ASCENDING,
      extra: {
        type: SORT_PRICE_PER_KG,
        direction: SORT_ASCENDING
      }
    },
    {
      value: 4,
      label: t('productList.filters.sortBy.priceUnitDecreasing'),
      active:
        data.sort.type === SORT_PRICE_PER_KG &&
        data.sort.direction === SORT_DESCENDING,
      extra: {
        type: SORT_PRICE_PER_KG,
        direction: SORT_DESCENDING
      }
    }
  ];

  const closeFilters = () => {
    if (!isDesktop) {
      setIsOpen(false);
    }
  };
  const openFilters = () => {
    if (!isDesktop) {
      setIsOpen(true);
    }
  };
  useEffect(() => {
    if (isDesktop && !isOpen) {
      setIsOpen(true);
    }
  }, [isDesktop, isOpen]);

  const [clickOutRef, clickOutHandler] = useClickOut();
  clickOutHandler(() => {
    closeFilters();
  });

  useEffect(() => {
    if (!isDesktop) {
      document.documentElement.style.overflow = isOpen ? 'hidden' : 'visible';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
  }, [isDesktop, isOpen]);

  useEffect(() => {
    window.exposedApi = window.exposedApi || {};
    window.exposedApi.addLuckyCart = lazyAddLuckyCart(
      setPartnersDatas,
      bannerLuckyCart,
      luckyCartData || []
    );
    window.exposedApi.addCriteo = lazyAddCriteo(
      setPartnersDatas,
      tileCriteo1,
      tileCriteo2
    );
    window.exposedApi.addHighCo = lazyAddHighCo(setPartnersDatas, tileHighCo);
    return () => {
      delete window.exposedApi.addLuckyCart;
      delete window.exposedApi.getSearchResults;
    };
  }, [luckyCartData]);

  useEffect(() => {
    const unload = () => window.scrollTo(0, 0);
    window.addEventListener('unload', unload);

    if (!prevIsHydrated && commonStore.isHydrated && commonStore.lastEanOrSku) {
      const { offsetTop = 0 } =
        document.getElementById(`productEan_${commonStore.lastEanOrSku}`) || {};
      window.scrollTo({ behavior: 'smooth', top: offsetTop - 20 });
      dispatch(clearLastEanOrSku());
    }

    return () => {
      window.removeEventListener('unload', unload);
    };
  }, [
    commonStore.isHydrated,
    commonStore.lastEanOrSku,
    prevIsHydrated,
    dispatch
  ]);

  useEffect(() => {
    if (list.meta.page === data.meta.firstPage) {
      return;
    }

    const elements = document.querySelectorAll(
      `.productList__grid__item[data-page="${data.meta.firstPage}"]`
    );
    const element = elements[elements.length - 1];

    if (element) {
      const deltaToAdd = data.meta.firstPage === 1 ? 70 : 0; // 70 = height of previous button
      window.scrollTo(
        0,
        element.getBoundingClientRect().y +
          element.getBoundingClientRect().height / 2 +
          window.scrollY -
          deltaToAdd
      );
    }
  }, [data.meta.firstPage, list.meta.page]);

  const loadNextPage = async (page: number): Promise<void> => {
    const productList = await loadPage(page);

    if (productList) {
      setData({
        ...data,
        meta: {
          ...data.meta,
          hasNextPage: productList.meta.hasNextPage,
          lastPage: productList.meta.page
        },
        list: {
          ...data.list,
          products: [...data.list.products, ...productList.products]
        }
      });
    }
  };

  const fetchAndFilterHeading = useCallback(
    async (
      page: number,
      f: FiltersType,
      s: Sort,
      headingFilter?: FilterValue
    ) => {
      const pdvRef = getPdvRef();
      let productList;
      if (type === 'shop') {
        productList = await fetchData(
          pdvRef,
          'heading',
          page,
          f,
          s,
          props.categoryId,
          props.keyword,
          list.shop?.id,
          headingFilter?.id ?? data.headingFilter?.id
        );
      } else {
        productList = await fetch(page, f, s);
      }
      return productList;
    },
    [
      data.headingFilter,
      list?.shop?.id,
      props?.categoryId,
      props?.keyword,
      type,
      fetch
    ]
  );

  useEffect(() => {
    (async () => {
      if (data.meta.hasPreviousPage) {
        return;
      }
      if (IS_EMERCH_ENABLED || IS_SMART_CONSO_LOT2) {
        const productList = await fetchAndFilterHeading(
          1,
          props.filters,
          props.sort
        );
        setData((s) => {
          return {
            ...s,
            ...(productList?.meta?.total && { list: productList })
          };
        });
      }
    })();
  }, [userId, IS_SMART_CONSO_LOT2, IS_EMERCH_ENABLED]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (data.meta.hasPreviousPage) {
        const productList = await fetchAndFilterHeading(
          data.meta.firstPage - 1,
          data.filters,
          data.sort
        );

        if (productList) {
          preloadedPages.current = [productList, ...preloadedPages.current];
        }
      }

      if (data.meta.hasNextPage) {
        const productList = await fetchAndFilterHeading(
          data.meta.lastPage + 1,
          data.filters,
          data.sort
        );

        if (productList) {
          preloadedPages.current = [...preloadedPages.current, productList];
        }
      }
    })();
  }, [data.meta, data.filters, data.sort, fetchAndFilterHeading]);

  const applyMultipleFilters = async (allfilters: Array<FilterModel>) => {
    let filtersToSend: FiltersType = [];

    allfilters.forEach((filter) => {
      let item = filtersToSend.find((f) => f.type === filter.type);
      if (!item) {
        item = { type: filter.type, values: [] };
        filtersToSend.push(item);
      }
      filter.values.forEach((a) => {
        if (item && a.active) {
          item.values.push(a.id.toString());
        }
      });
    });

    const qs = getQueryString(1, filtersToSend, data.sort);
    const url = `${baseUrl}${qs ? `?${qs}` : ''}`;
    window.history.pushState({}, '', url);

    const productList = await fetchAndFilterHeading(
      1,
      filtersToSend,
      data.sort
    );
    // update filters list according to list of filters returned by sevice
    filtersToSend = filtersToSend.map((f1: FilterType) => {
      const newF1 = { ...f1 };
      const v = newF1.values;
      newF1.values = [];
      productList.filters.forEach((f2: FilterModel) => {
        if (newF1.type === f2.type) {
          f2.values.forEach((value: FilterValue) => {
            const id = value.id.toString();

            if (v.includes(id)) {
              newF1.values.push(id);
            }
          });
        }
      });

      return newF1;
    });

    setData({
      ...data,
      activableFilters: productList.filters,
      filters: filtersToSend,
      list: {
        products: productList.products,
        filters: productList.filters,
        meta: productList.meta
      },
      meta: {
        firstPage: productList.meta.page,
        lastPage: productList.meta.page,
        hasPreviousPage: productList.meta.hasPreviousPage,
        hasNextPage: productList.meta.hasNextPage
      }
    });
    preloadedPages.current = [];
    resetScroll();
    sendPageViewWithHeadingFilter(filtersToSend, data.sort);
  };

  const resetScroll = () => {
    const { offsetTop = 0 } =
      (document.getElementsByClassName(
        'productList__orderProducts'
      )?.[0] as HTMLElement) || {};

    if (window.scrollY > offsetTop) {
      window.scrollTo({ behavior: 'smooth', top: offsetTop });
    }
  };

  const applySort = async (name: string, values: Option | Options | null) => {
    let newSort: Sort;

    if (Array.isArray(values)) {
      return;
    }
    if (!values) {
      newSort = {
        type: SORT_RELEVANCE,
        direction: undefined
      };
    } else {
      newSort = values?.extra as Sort;
    }

    const qs = getQueryString(1, data.filters, newSort);
    const url = `${baseUrl}${qs ? `?${qs}` : ''}`;

    window.history.pushState({}, '', url);

    const productList = await fetchAndFilterHeading(1, data.filters, newSort);

    setData({
      ...data,
      sort: newSort,
      list: {
        products: productList.products,
        filters: productList.filters,
        meta: productList.meta
      },
      meta: {
        firstPage: productList.meta.page,
        lastPage: productList.meta.page,
        hasPreviousPage: productList.meta.hasPreviousPage,
        hasNextPage: productList.meta.hasNextPage
      }
    });
    preloadedPages.current = [];
    sendPageViewWithHeadingFilter(data.filters, newSort);
  };

  const clearFilters = async () => {
    const qs = getQueryString(1, [], data.sort);
    const url = `${baseUrl}${qs ? `?${qs}` : ''}`;

    window.history.pushState({}, '', url);

    const productList = await fetchAndFilterHeading(1, [], data.sort);

    setData({
      ...data,
      filters: [],
      list: {
        products: productList.products,
        filters: productList.filters,
        meta: productList.meta
      },
      meta: {
        firstPage: productList.meta.page,
        lastPage: productList.meta.page,
        hasPreviousPage: productList.meta.hasPreviousPage,
        hasNextPage: productList.meta.hasNextPage
      },
      initialFilters: productList.filters,
      activableFilters: []
    });
    preloadedPages.current = [];
    resetScroll();
    sendPageViewWithHeadingFilter([], data.sort);
  };

  const sendPageViewWithHeadingFilter = (
    f: FiltersType,
    s: Sort,
    currentFilter?: FilterValue
  ) => {
    const category = currentFilter ?? data.headingFilter;
    // send special event if we are in shop and user select a filter else send common event
    if (type === 'shop' && category?.id !== list.headings?.[0]?.id) {
      pageView.send('shop', {
        type: 'headingFilter',
        filters: data.filters,
        sort: data.sort,
        list,
        category: category.label,
        categoryId: category.id
      });
    } else {
      sendPageView(f, s);
    }
  };

  const setHeadingFilter = async (f: FilterValue) => {
    const productsList = await fetchAndFilterHeading(1, [], data.sort, f);

    setData((s) => {
      return {
        ...s,
        headingFilter: f,
        filters: [],
        list: {
          products: productsList.products,
          filters: productsList.filters,
          meta: productsList.meta
        },
        meta: {
          firstPage: productsList.meta.page,
          lastPage: productsList.meta.page,
          hasPreviousPage: productsList.meta.hasPreviousPage,
          hasNextPage: productsList.meta.hasNextPage
        },
        activableFilters: productsList.filters
      };
    });

    sendPageViewWithHeadingFilter(data.filters, data.sort, f);

    eventGTM.send('productsImpression', {
      products: productsList?.products,
      path: router.asPath
    });
  };

  return (
    <>
      {data.banner && (
        <Image
          className="productList__banner"
          src={data.banner}
          alt=""
          width={1410}
          height={350}
        />
      )}
      {type === 'shop' && <div data-regie-id="productGrid-store-banner-top" />}
      {type === 'shop' && (
        <StaticHeading
          list={list}
          setHeadingFilter={setHeadingFilter}
          headingFilter={data.headingFilter}
        />
      )}

      <div className="productList__display">
        <div className="productList__display__left">
          {isOpen && (
            <VerticalFilters
              initialFilters={data.initialFilters}
              applyFilters={applyMultipleFilters}
              clearFilters={clearFilters}
              isDesktop={isDesktop}
              clickOutRef={clickOutRef}
              close={closeFilters}
              activableFilters={data.activableFilters}
              checkedFilters={data.filters}
              totalNbProducts={data.list.meta.total}
            />
          )}
        </div>

        <div className="productList__display__right">
          <div className="productList__filters-header">
            <div className="productList__filters-left">
              <button
                className="productList__open-filters"
                onClick={openFilters}
              >
                <svg width="16" height="16">
                  <use xlinkHref={`${CommonSprite}#filter`} />
                </svg>
                <span className="productList__open-filters__label">
                  {t('productList.filters.title')}
                </span>
              </button>
              <p className="productList__nb-products-filters">
                {t('productList.filters.nbProducts', {
                  '%count%': data.list.meta.total
                })}
              </p>
            </div>
            <div className="productList__filters-right">
              <Select
                className="productList__filters-right__select"
                name="trier"
                options={sortFilters}
                apply={applySort}
                label={t('productList.filters.sortBy')}
              />
            </div>
          </div>
          {data.meta.hasPreviousPage && (
            <div className="productList__previous">
              <Link
                rel="prev"
                href={getUrlForPage(data.meta.firstPage - 1)}
                onClick={(event: MouseEvent) => {
                  event.preventDefault();

                  loadPreviousPage(data.meta.firstPage - 1);
                }}
                button
                label={t('productList.previous')}
              />
            </div>
          )}
          <div className="productList__section">
            <Provider store={store}>
              <ProductGrid
                products={data.list.products}
                perPage={perPage}
                firstPage={data.meta.firstPage}
                partnersDatas={partnersDatas}
                isPromoPage={isPromoPage}
              />
              {isPromoPage && (
                <div
                  ref={bannerLuckyCart}
                  data-regie-id="luckyCart-banner-promotions"
                />
              )}
            </Provider>
          </div>
          {data.meta.hasNextPage && (
            <div className="productList__next">
              <Link
                rel="next"
                href={getUrlForPage(data.meta.lastPage + 1)}
                onClick={(event: MouseEvent) => {
                  event.preventDefault();

                  loadNextPage(data.meta.lastPage + 1);
                }}
                button
                label={t('productList.next')}
              />
            </div>
          )}
          {list.hasAlcohol && (
            <div className="productListAlcoholBanner">
              {t('productList.alcohol.message')}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ProductList = (props: ProductListProps) => {
  const { type, list } = props;
  const pageView = usePageView();
  const event = useEvent();
  const router = useRouter();
  useEffect(() => {
    (async () => {
      const { ref } = usePdv();
      const catId = window.location.href.split('/').pop();
      let cat: CategoryModel | null = null;
      if (catId && type === 'category')
        cat = await CategoriesApi.getCategoryById(catId, ref);
      pageView.send(type, {
        ...props,
        catTilte: cat?.title.replace(/ /g, '_').toLowerCase() ?? ''
      });
      event.send('productsImpression', {
        products: list.products,
        path: router.asPath
      });
    })();
  }, [pageView, list.products, props, type, event, router]);

  return (
    <Content
      {...props}
      sendPageView={(filters, sort) => {
        pageView.send(type, { ...props, filters, sort });
      }}
    />
  );
};

export default ProductList;
