import React from 'react';
import { StoreInfo } from '@/lib/api/pdv/types';
import './style.scss';

type StoresSuggestionListProps = {
  stores: StoreInfo[];
  onStoreClick: (store: StoreInfo) => void;
};

const StoresSuggestionList = React.forwardRef<
  HTMLDivElement,
  StoresSuggestionListProps
>(({ stores, onStoreClick }: StoresSuggestionListProps, ref) => {
  return (
    <div className="storeAutocompleteInput__suggestions" ref={ref}>
      {stores.map((store) => {
        return (
          <div
            key={store.storeId}
            className="storeAutocompleteInput__suggestion"
            onClick={() => onStoreClick(store)}
          >
            <div className="storeAutocompleteInput__suggestion__name">
              {`${store.name} ${store.townLabel}`}
            </div>
            <div className="storeAutocompleteInput__suggestion__address">
              {`${store.address}, ${store.zipCode} ${store.townLabel}`}
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default StoresSuggestionList;
