import React from 'react';
import '../style.scss';
import t from '@/lib/i18n';
import CartSprite from '@/assets/images/sprites/cart.svg';
import {
  PaymentStateType,
  StateType as CartStateType
} from '@/store/cart/types';
import Modal from '@/components/organisms/modal';
import slotsApi from '@/lib/api/slots';
import { ADD_SLOTS } from '@/store/cart/cartActions';
import { getPdvRef } from '@/lib/hooks/usePdv';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';

export type ModalPaymentProps = {
  paymentState: PaymentStateType;
  setPaymentState: any;
  sendPayment: () => void;
};

const ModalPayment = ({
  paymentState,
  setPaymentState,
  sendPayment
}: ModalPaymentProps) => {
  const dispatch = useDispatch();
  // TODO :
  // const { userId, cartStore } = useShallowEqualSelector({ auth, cart }) => {
  //   return {
  //     userId: auth.user?.id,
  //     cartStore: cart
  //   };
  // }
  const { userId, cartStore } = useSelector(
    ({ auth, cart }: { auth: AuthStateType; cart: CartStateType }) => {
      return {
        userId: auth.user?.id,
        cartStore: cart
      };
    }
  );
  return (
    <>
      <Modal
        className="modalFid"
        pageView="paymentValidation"
        onClose={() => {
          setPaymentState((state: PaymentStateType) => ({
            ...state,
            modalFidInfo: false
          }));
        }}
        confirmBtn={{
          label: t('common.validate'),
          loading: paymentState.modal.isLoading,
          onClick: async () => {
            setPaymentState((state: PaymentStateType) => ({
              ...state,
              modalFidInfo: false,
              modal: { ...state.modal, isLoading: true }
            }));
            await sendPayment();
          }
        }}
        open={paymentState.modalFidInfo}
      >
        <strong>{t('cart.payment.fid.info.title')}</strong>
        <p
          dangerouslySetInnerHTML={{
            __html: t('cart.payment.fid.info.description')
          }}
        />
      </Modal>
      <Modal
        className="commonErrorModal"
        pageView="paymentError"
        confirmBtn={{
          label: paymentState.modal.button || '',
          loading: paymentState.modal.isLoading,
          onClick: async () => {
            if (paymentState.modal.backTo === 'slots') {
              const pdvRef = getPdvRef();

              try {
                const {
                  data,
                  vouchersCodeActive,
                  discountCodeActive,
                  mkpPackages,
                  defaultPayments
                } = await slotsApi.getAll(cartStore, pdvRef, userId);
                dispatch({
                  type: ADD_SLOTS,
                  payload: {
                    data,
                    vouchersCodeActive,
                    discountCodeActive,
                    mkpPackages,
                    defaultPayments
                  }
                });
              } catch (error: any) {
                window.location.href = '/orders/cart';
                return;
              }
              window.location.href = '/orders/slots';
            }
            if (paymentState.modal.backTo === 'compte') {
              window.location.href = '/account-management/my-info/my-adresses';
              return;
            }
            if (paymentState.modal.backToCart) {
              window.location.href = '/orders/cart';
            } else {
              setPaymentState((state: PaymentStateType) => ({
                ...state,
                modal: { ...state.modal, isActive: !state.modal.isActive }
              }));
            }
          }
        }}
        open={paymentState.modal.isActive}
        onClose={() => {
          setPaymentState((state: PaymentStateType) => ({
            ...state,
            modal: { ...state.modal, isActive: !state.modal.isActive }
          }));
        }}
      >
        <svg width={128} className="commonErrorModal__picto">
          <use xlinkHref={`${CartSprite}#${paymentState.modal.picto}`} />
        </svg>
        <strong className="commonErrorModal__title">
          {paymentState.modal.title}
        </strong>
        <p className="commonErrorModal__text">{paymentState.modal.text}</p>
      </Modal>
    </>
  );
};

export default ModalPayment;
