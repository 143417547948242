import { FreeContentCmsProps } from '@/components/molecules/freeContentCms';
import { Component, NormalizeParams } from '..';

const normalize = ({
  data
}: NormalizeParams): TemplateContenuLibreBlocTexte => {
  return {
    type: 'molecules',
    name: 'freeContentCms',
    props: {
      content: data.longText || ''
    }
  };
};

export default normalize;

export interface TemplateContenuLibreBlocTexte extends Component {
  props: FreeContentCmsProps;
}
