import React from 'react';
import { CartType } from '@/store/cart/types';
import CartSprite from '@/assets/images/sprites/cart.svg';
import t from '@/lib/i18n';
import Providers from '@/components/organisms/checkout/providers';
import dayjs from 'dayjs';
import Link from '@/components/atoms/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import './style.scss';

type ItmInfosProps = {
  cart: CartType;
  currentSellerId?: string;
};

const ItmInfos = ({ cart, currentSellerId }: ItmInfosProps) => {
  const router = useRouter();
  let itmDeliveryCost;

  if (cart.slots.selectedProvider?.deliveryCost?.totalCost) {
    // LAD
    itmDeliveryCost = cart.slots.selectedSlot?.slot?.freeFees
      ? 0
      : cart.slots.selectedProvider?.deliveryCost?.totalCost;
  }
  if (cart.slots.selectedSlot?.slot?.freeFees) {
    // ancien LAD
    itmDeliveryCost = cart.slots.selectedSlot?.slot?.freeFees
      ? 0
      : cart.slots.selectedSlot.slot?.fees || 0;
  }
  return (
    <>
      <strong className="cart__footer__subTitle">
        {t('cart.slot.method')}
      </strong>
      <div className="cartInfo">
        <svg className="cartInfo__picto" height="25" width="25">
          <use
            xlinkHref={`${CartSprite}#${
              cart.slots.selectedSlot.deliveryType ||
              Object.keys(cart.slots.list)[0]
            }`}
          />
        </svg>
        <div className="cartInfo__content">
          <div className="cartInfo__title">
            {t(
              `cart.slot.${
                cart.slots.selectedSlot.deliveryType ||
                Object.keys(cart.slots.list)[0]
              }`
            )}
          </div>
          {!cart.slots.selectedSlot.slot && (
            <div className="cartInfo__item">{t('cart.slot.selectSlot')}</div>
          )}
          {cart.slots.selectedSlot.slot && (
            <>
              <div className="cartInfo__item slotDate">
                {dayjs(cart.slots.selectedSlot.slot?.day).format('dddd D MMMM')}
              </div>
              <div className="cartInfo__item">
                {cart.slots.selectedSlot.slot?.startTime} -{' '}
                {cart.slots.selectedSlot.slot?.endTime}
              </div>
              {cart.slots.selectedSlot.deliveryType === 'delivery' &&
                itmDeliveryCost !== undefined && (
                  <div className="mkpInfos__feesLine">
                    <div>{t('mkp.deliveryService.fees')}</div>
                    <div
                      className={classnames({
                        'mkpInfos__price--green': itmDeliveryCost === 0
                      })}
                    >
                      {itmDeliveryCost === 0
                        ? t('cart.slot.fees.offer')
                        : `${String(itmDeliveryCost?.toFixed(2))?.replace(
                            '.',
                            ','
                          )} €`}
                    </div>
                  </div>
                )}
              {(!router.asPath?.includes('/orders/slots') ||
                !!currentSellerId) && (
                <div className="mkpInfos__modify">
                  <Link
                    className="mkpInfos__modify__btn"
                    href="/orders/slots"
                    label={t('common.modify')}
                    button={false}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {cart.slots.selectedSlot.deliveryType && <Providers slots={cart.slots} />}
    </>
  );
};

export default ItmInfos;
