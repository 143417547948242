import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import {
  Addresses,
  BillingAddress,
  DeliveryAddress,
  FloorMapping
} from './types';

export class AddressApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  async getAll(userId: string): Promise<Addresses> {
    let response = null;
    const addresses: Addresses = {
      delivery: [],
      billing: []
    };

    try {
      response = await this.axios.get(
        `gestiondecompte/v1/moncompte/${userId}/adresses`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch addresses',
        error
      });

      throw error;
    }

    const data = response?.data ?? {};

    data?.adressesFacturation.forEach((elt: any) => {
      addresses.billing.push(
        AddressApi.parseAddress('billing', elt) as BillingAddress
      );
    });

    data?.adressesLivraison.forEach((elt: any) => {
      addresses.delivery.push(
        AddressApi.parseAddress('delivery', elt) as DeliveryAddress
      );
    });

    return addresses;
  }

  async create(
    userId: string,
    address: BillingAddress | DeliveryAddress
  ): Promise<void> {
    const body = AddressApi.reverseParseAddress(address.type, address);

    try {
      await this.axios.post(
        `gestiondecompte/v1/moncompte/${userId}/adresses`,
        body
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to create address',
        error
      });

      throw error;
    }
  }

  async createProfile(
    userId: string,
    civility: string,
    address: BillingAddress | DeliveryAddress
  ): Promise<void> {
    const body = AddressApi.reverseParseAddressProfile(
      address.type,
      civility,
      address
    );

    try {
      await this.axios.post(`profile/v1/profiles/${userId}/addresses`, body);
    } catch (error: any) {
      logger.error({
        message: 'Unable to create address',
        error
      });

      throw error;
    }
  }

  async delete(userId: string, addressId: number) {
    try {
      await this.axios.delete(
        `gestiondecompte/v1/moncompte/${userId}/adresses/${addressId}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to delete address',
        error
      });
    }
  }

  async update(
    userId: string,
    address: BillingAddress | DeliveryAddress
  ): Promise<void> {
    if (!address.id) {
      logger.error({
        message: 'Try to update address with no id'
      });

      throw new Error();
    }

    const body = AddressApi.reverseParseAddress(address.type, address);

    try {
      await this.axios.put(
        `gestiondecompte/v1/moncompte/${userId}/adresses/${address.id}`,
        body
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to update addresses',
        error
      });

      throw error;
    }
  }

  static reverseParseAddress(type: 'billing' | 'delivery', data: any) {
    let address = {
      addressType: type,
      addresseRc: false,
      id: data.id,
      libelle: data.name,
      principale: data.isDefault,
      adresse3: data.streetName,
      adresse4: data.additionalStreetName,
      codePostal: data.zipCode,
      ville: data.city,
      pays: data.country
    } as any;

    if (type === 'delivery') {
      address = {
        ...address,
        adresse1: data.isApartment ? data?.floor?.toString() : '',
        adresse2: data.isApartment ? data.buildingNumber : '',
        appartement: data.isApartment,
        ascenseur: data.isApartment ? data.elevator : false,
        digicode: data.isApartment ? data.digicode : '',
        consigneLivreur: data.deliverymanInstructions
      } as any;
    } else {
      address = {
        ...address,
        adresse2: data.additionalInformation
      };
    }

    return address;
  }

  static reverseParseAddressProfile(
    type: 'billing' | 'delivery',
    civility: string,
    data: any
  ) {
    let address = {
      type: type.toUpperCase(),
      id: data.id,
      label: data.name,
      isMainAddress: data.isDefault,
      line3: data.streetName,
      line4: data.additionalStreetName,
      postalCode: data.zipCode,
      city: data.city,
      country: data.country,
      title: civility === 'male' ? '1' : '2'
    } as any;

    if (type === 'delivery') {
      address = {
        ...address,
        line1: data.isApartment ? data?.floor?.toString() : '',
        line2: data.isApartment ? data.buildingNumber : '',
        appartement: data.isApartment,
        ascenseur: data.isApartment ? data.elevator : false,
        digicode: data.isApartment ? data.digicode : '',
        consigneLivreur: data.deliverymanInstructions
      } as any;
    } else {
      address = {
        ...address,
        line2: data.additionalInformation
      };
    }

    return address;
  }

  static parseAddress(
    type: 'billing' | 'delivery',
    data: any
  ): BillingAddress | DeliveryAddress {
    let address = {
      type,
      id: data.id,
      name: data.libelle,
      isDefault: data.principale,
      streetName: data.adresse3,
      additionalStreetName: data.adresse4,
      zipCode: data.codePostal,
      city: data.ville,
      country: data.pays
    } as BillingAddress | DeliveryAddress;

    if (type === 'delivery') {
      address = {
        ...address,
        isApartment: data.appartement,
        buildingNumber: data.adresse2,
        floor: FloorMapping[data.adresse1] || 0,
        elevator: data.ascenseur,
        deliverymanInstructions: data.consigneLivreur,
        digicode: data.digicode
      } as DeliveryAddress;
    } else {
      address = {
        ...address,
        additionalInformation: data.adresse2
      } as BillingAddress;
    }

    return address;
  }

  static parseNewVersionAddress(
    type: 'billing' | 'delivery',
    data: any
  ): BillingAddress | DeliveryAddress {
    let address = {
      type,
      id: data.addressId,
      name: data.addressLabel,
      isDefault: data.isMainAddress,
      streetName: data.address3,
      additionalStreetName: data.address4,
      zipCode: data.zipCode,
      city: data.city,
      country: data.country,
      firstName: '',
      lastName: ''
    } as BillingAddress | DeliveryAddress;

    if (type === 'delivery') {
      address = {
        ...address,
        isApartment: data.apartment,
        buildingNumber: data.address2,
        floor: FloorMapping[data.address1] || 0,
        elevator: data.elevator,
        deliverymanInstructions: data.additionalsInfos,
        digicode: data.digicode
      } as DeliveryAddress;
    } else {
      address = {
        ...address,
        additionalInformation: data.address2
      } as BillingAddress;
    }

    return address;
  }
}

export default new AddressApi();
