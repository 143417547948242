import React, { useState } from 'react';
import t from '@/lib/i18n';
import classnames from 'classnames';
import { SmartConso } from '@/lib/model/productTypes';
import groupBy from 'lodash/groupBy';
import labelsData from './labels.json';
import './style.scss';

export type LabelsProps = {
  score: SmartConso | undefined;
};
type StateLabelType =
  | {
      key: number;
      display: string;
      order: number;
      picto: string;
      description: string;
    }
  | undefined;

const groupedLabelsData = groupBy(labelsData.labels, 'key');

const Labels = ({ score }: LabelsProps) => {
  const qualificationsWithLabel = score?.qualifications
    .map((f) => {
      if (groupedLabelsData[f.key]?.[0].description) {
        return { ...f, ...groupedLabelsData[f.key]?.[0] };
      }
      if (!score.isCustom) {
        return labelsData.labels.find(
          (elem) => elem.picto === f.key?.toString()
        );
      }
      return undefined;
    })
    .filter(Boolean);

  const [label, setLabel] = useState<StateLabelType>(
    qualificationsWithLabel?.[0]
  );
  const onClickLabel = (flag: any) => {
    setLabel(flag);
  };

  return (
    <section
      id="labels"
      className="productDetail__section productDetail__composition labelSection"
    >
      <div className="container container--md">
        <h2 className="productDetail__section_title">
          {t('pdp.informations.labels.title')}
        </h2>
        <div className="labelDescription">
          <div className="productDetail__labels">
            <div className="nutritionSection__content__labels">
              {qualificationsWithLabel?.map((flag: any) => {
                const src = `https://cdn.intermarche.pt/mobileAppAssets/${flag.picto}.png`;
                return (
                  <div
                    className={classnames(
                      ['nutritionSection__content__labels__item'],
                      {
                        'nutritionSection__content__labels__item--selected':
                          flag.key === label?.key
                      }
                    )}
                    key={flag.key}
                    onClick={() => onClickLabel(flag)}
                  >
                    <img src={src} alt={flag.key} width={42} height={42} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="productDetail__description">
            <div className="productDetail__description__title">
              {label?.display}
            </div>
            <div>{label?.description}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Labels;
