import React, { useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import Loader from '../loader';

export type LoadableContentProps = {
  className?: string;
  loading?: boolean;
  loaderSize?: 'small' | 'medium' | 'large';
  children?: JSX.Element | JSX.Element[] | string;
};

const LoadableContent = ({
  className,
  children,
  loading,
  loaderSize = 'medium'
}: LoadableContentProps) => {
  const [isLoading, setIsLoading] = useState(loading);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <div
      className={classnames('loadableContent', className, {
        'loadableContent--loading': isLoading
      })}
    >
      {isLoading && <Loader color="red" size={loaderSize} />}
      {!isLoading && <div className="loadableContent__content">{children}</div>}
    </div>
  );
};

export default LoadableContent;
