/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Action } from '@/store/survey/actions';
import {
  selectSurvey,
  selectGetFilledAnswers,
  selectHasCompletedSurvey,
  selectHasFilledAllAnswers
} from '@/store/survey/surveySelectors';
import { SurveyState } from '@/store/survey/types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import usePageView from '@/lib/hooks/usePageView';
import StepWizard from 'react-step-wizard';
import { ValidateFirstStepsForm } from '../firstStepsConfirmation';
import CheckboxForm from './checkboxForm';
import DateForm from './dateForm';
import RadioForm from './radioForm';
import StepFooter from './stepFooter';
import './style.scss';
import surveyList from './survey.json';
import { SharedProps } from './types';

enum StyleType {
  DATE_PICKER = 'datePicker',
  COLLECTION = 'collection',
  CHECKOUT = 'checkbox',
  RADIO = 'radio'
}

interface SharedNavProps {
  nextStep?: () => void;
  previousStep?: () => void;
  lastStep?: () => void;
  currentStep?: number;
  totalSteps?: number;
  goToStep?: (step: number) => void;
  dispatch: React.Dispatch<Action>;
  state: SurveyState;
  moveToNextStep: () => void;
}

const SharedNav: React.FC<SharedNavProps> = ({
  previousStep,
  nextStep,
  currentStep,
  totalSteps,
  dispatch,
  state,
  moveToNextStep
}) => {
  return (
    <div>
      <StepFooter
        moveToNextStep={moveToNextStep}
        state={state}
        currentStep={currentStep!}
        totalSteps={totalSteps!}
        previousStep={previousStep!}
        nextStep={nextStep!}
        dispatch={dispatch}
      />
    </div>
  );
};

const Steps = ({ moveToNextStep }: { moveToNextStep: () => void }) => {
  const state = useSelector(selectSurvey, shallowEqual);
  const dispatch: React.Dispatch<Action> = useDispatch();
  const filledAnswers = useSelector(selectGetFilledAnswers);
  const hasCompleteSurvey = useSelector(selectHasCompletedSurvey);
  const hasFilledAllAnswers = useSelector(selectHasFilledAllAnswers);
  const pageView = usePageView();

  const [storedResponses, setStoredResponses] = useState(
    hasCompleteSurvey && hasFilledAllAnswers
      ? []
      : filledAnswers.map(({ questionId }) => questionId)
  );
  useEffect(() => {
    if (storedResponses.length && !state.responses.length)
      setStoredResponses([]);
  }, [state.responses.length, storedResponses.length]);

  useEffect(() => {
    pageView.send('smartConso', {
      vPath: '/compte/conso_mieux/questions'
    });
  }, [pageView]);

  const steps = surveyList.questions.map((question) => {
    const {
      themeId,
      questionLabel,
      description,
      questionId,
      suggestions
    } = question;

    const showFirstStepConfirmation =
      !state.hasPassedFirstStep && questionId === 3;
    if (showFirstStepConfirmation) {
      return <ValidateFirstStepsForm />;
    }

    const key = `forms-steps__step-${questionId}`;
    const theme = surveyList.referential.themes[themeId];
    const sharedProps = {
      key,
      theme,
      questionLabel,
      description,
      dispatch,
      questionId,
      suggestions: suggestions || [],
      state,
      currentResponse: state.responses.find(
        (response) => response.questionId === questionId
      ),
      mandatory: false
    } as SharedProps;
    switch (question.styleId as StyleType) {
      case StyleType.CHECKOUT:
        return <CheckboxForm {...sharedProps} />;
      case StyleType.DATE_PICKER:
        return <DateForm {...sharedProps} />;
      case StyleType.COLLECTION:
      default:
        return <RadioForm {...sharedProps} />;
    }
  });

  return (
    <div className="form-steps">
      <StepWizard
        initialStep={storedResponses.length + 1}
        className="form-steps__step-wizard"
        nav={
          <SharedNav
            moveToNextStep={moveToNextStep}
            state={state}
            dispatch={dispatch}
          />
        }
        isLazyMount
      >
        {steps}
      </StepWizard>
    </div>
  );
};

export default Steps;
