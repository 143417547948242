import React from 'react';
import Link, { LinkProps } from '@/components/atoms/link';
import '@/components/organisms/savTilesGrid/style.scss';
import './style.scss';

export type TileProps = {
  imageContent?: JSX.Element | null;
  textContent?: JSX.Element | null;
  linkAttributes?: LinkProps;
  isTextFirst?: boolean;
  className?: string;
  key?: string;
};

const Tile = ({
  imageContent = null,
  textContent = null,
  linkAttributes = {},
  className = '',
  isTextFirst = false
}: TileProps): JSX.Element => {
  const { href, className: linkClassName } = linkAttributes;

  const wordingContent = isTextFirst ? (
    <>
      {textContent}
      {imageContent}
    </>
  ) : (
    <>
      {imageContent}
      {textContent}
    </>
  );

  const tileContent: JSX.Element = (
    <div className={className || 'tile'}>{wordingContent}</div>
  );

  return href ? (
    <Link {...linkAttributes} className={linkClassName || 'tile__link'}>
      {tileContent}
    </Link>
  ) : (
    <div {...linkAttributes} className={linkClassName || 'tile__link'}>
      {tileContent}
    </div>
  );
};

export default Tile;
