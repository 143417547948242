import React, { memo, useEffect, useRef, useState } from 'react';
import t from '@/lib/i18n';
import Link from '@/components/atoms/link';
import { useForm } from 'react-hook-form';
import Modal from '@/components/organisms/modal';
import Image from '@/components/atoms/image';
import Title from '@/components/atoms/title';
import FidelitySprite from '@/assets/images/sprites/carteFidelite.svg';
import FormInput from '@/components/atoms/formInput';
import Button from '@/components/atoms/button';
import FormCheckbox from '@/components/atoms/formCheckbox';
import {
  Operation as OperationItemProps,
  ShareRequest
} from '@/lib/api/stickers/types';
import CSSTransition from 'react-transition-group/CSSTransition';
import StickersApi from '@/lib/api/stickers';
import { IException } from '@/lib/api/stickers/exceptions';
import Banner from '@/components/atoms/banner';
import Message from '@/components/molecules/message';

import './style.scss';
import usePageView from '@/lib/hooks/usePageView';
import usePdv from '@/lib/hooks/usePdv';
import useEvent from '@/lib/hooks/useEvent';

type SharePopinProps = {
  operation: OperationItemProps;
  balance: number;
  cardNumber?: string;
};

type SubmitedDataProps = {
  quantity?: number;
  cardNumber: string;
};

type ShareViewProps = SharePopinProps & {
  visible: boolean;
  postSubmit: () => void;
};

const ShareView = ({
  operation,
  balance,
  cardNumber = '',
  postSubmit,
  visible
}: ShareViewProps) => {
  if (!visible) {
    return null;
  }
  const [submitting, setSubmitting] = useState(false);
  const [hasAcceptedConditions, setHasAcceptedConditions] = useState(false);
  const [apiError, setApiError] = useState<IException>();
  const [submittedData, setSubmittedData] = useState<SubmitedDataProps>({
    cardNumber: ''
  });
  const pageView = usePageView();
  const event = useEvent();

  const { register, errors, handleSubmit, formState } = useForm<any>({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      ...submittedData
    }
  });

  const getApiErrorView = (destCardNumber: string) =>
    apiError && (
      <Banner
        message={apiError.getTrad(destCardNumber)}
        className="sharePopin__error"
      />
    );

  const onSubmit = async (data: SubmitedDataProps) => {
    setSubmitting(true);
    setSubmittedData(data);
    setApiError(undefined);

    const request: ShareRequest = {
      code: operation.code,
      consent: hasAcceptedConditions,
      quantity: data.quantity ?? 0,
      senderCard: cardNumber,
      recipientCard: data.cardNumber
    };
    try {
      await StickersApi.share(request);
      event.send('stickers', {
        type: 'shareMyStickers',
        partner: `${operation.shortName}::${data.quantity}`
      });
      event.send('api', { type: 'success', name: 'shareStickers' });
      postSubmit();
    } catch (e: any) {
      setApiError(e);
      event.send('api', { type: 'error', name: 'shareStickers' });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const { ref } = usePdv();
    if (apiError) {
      pageView.send('MyShareStickersError', {
        pdvRef: ref
      });
    }
    if (submitting && !apiError) {
      pageView.send('MyShareStickersSuccess', {
        pdvRef: ref
      });
    }
  }, [pageView, apiError, submitting]);

  return (
    <div className="sharePopin__header">
      <div className="sharePopin__header-logo">
        <Image src={operation.logo} alt="sharePopin__header-logo" />
      </div>
      <div className="sharePopin__form">
        <Title
          tag="h2"
          className="sharePopin__form-title"
          label={t('myAccount.fidelity.myStickers.share.title')}
        />
        {apiError && getApiErrorView(submittedData.cardNumber)}
        <p>{t('myAccount.fidelity.myStickers.share.desc')}</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="sharePopin__form-inputGroup">
            <FormInput
              id="cardNumber"
              name="cardNumber"
              className="sharePopin__form-input"
              label={`${t('myAccount.fidelity.myStickers.share.label.card')} *`}
              ref={register({
                required: true,
                validate: {
                  cardHasOnlyDigit: (value) => {
                    return /^\d+$/.test(value);
                  },
                  cardHasExactLength: (value) => {
                    return value?.length === 19;
                  }
                }
              })}
              errorMessage={
                errors.cardNumber
                  ? t(
                      `myAccount.fidelity.myStickers.share.error.${errors.cardNumber?.type}`
                    )
                  : ''
              }
            />
          </div>
          <div className="sharePopin__form-inputGroup">
            <FormInput
              id="quantity"
              name="quantity"
              className="sharePopin__form-input"
              label={`${t('myAccount.fidelity.myStickers.share.label.nb')} *`}
              ref={register({
                required: true,
                maxLength: 3,
                validate: {
                  nbHasOnlyDigit: (value) => {
                    return /^\d+$/.test(value);
                  },
                  nbIsZero: (value) => {
                    return Number(value) !== 0;
                  },
                  nbMoreThanBalance: (value) => {
                    return Number(value) <= balance;
                  }
                }
              })}
              errorMessage={
                errors.quantity
                  ? t(
                      `myAccount.fidelity.myStickers.share.error.${errors.quantity?.type}`
                    )
                  : ''
              }
            />
          </div>
          <div className="sharePopin__form-inputGroup">
            <FormCheckbox
              name="cb.acceptConditions"
              checked={hasAcceptedConditions}
              className="sharePopin__form-checkbox"
              onChange={(value: boolean) => {
                setHasAcceptedConditions(value);
              }}
              id="acceptConditions"
              label={t('myAccount.fidelity.myStickers.share.acceptConditions')}
            />
          </div>
          <div className="sharePopin__form-actions">
            <Button
              type="submit"
              disabled={!formState.isValid || !hasAcceptedConditions}
              loading={submitting}
            >
              {t('myAccount.fidelity.myStickers.share.validate')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const SharePopin = ({ operation, balance, cardNumber }: SharePopinProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const formRef = useRef(null);

  const postSubmit = () => {
    setShowMessage(true);
  };

  const onClose = () => {
    if (showMessage) {
      window.location.href = '/account-management/my-fidelity/my-stickers';
    } else {
      setShowMessage(false);
      setShowForm(true);
      setIsOpen(false);
    }
  };

  const disabled = !cardNumber || balance < 1;
  const event = useEvent();

  return (
    <div className="sharePopin">
      <Link
        disabled={disabled}
        label={t(`myAccount.fidelity.myStickers.operations.links.transfer`)}
        className="operation__footer_link"
        onClick={() => {
          event.send('stickers', {
            type: 'share',
            partner: operation.shortName
          });
          setIsOpen(!disabled);
        }}
      />
      <Modal
        pageView="MyShareStickers"
        className="sharePopin__modal"
        open={isOpen}
        onClose={onClose}
      >
        <ShareView
          visible={showForm}
          postSubmit={postSubmit}
          operation={operation}
          balance={balance}
          cardNumber={cardNumber}
        />
        <CSSTransition
          in={showMessage}
          nodeRef={formRef}
          timeout={300}
          classNames="sharePopin__message"
          unmountOnExit
          onEnter={() => setShowForm(false)}
          onExited={() => setShowForm(true)}
        >
          <div className="sharePopin__message sharePopin__form" ref={formRef}>
            <Message
              image={`${FidelitySprite}#share-success`}
              title={t('myAccount.fidelity.myStickers.share.success.title')}
              desc={t('myAccount.fidelity.myStickers.share.success.desc')}
            />
            <div className="sharePopin__form-actions">
              <Button onClick={onClose}>
                {t('myAccount.fidelity.myStickers.share.close')}
              </Button>
            </div>
          </div>
        </CSSTransition>
      </Modal>
    </div>
  );
};

export default memo(SharePopin);
