import React from 'react';

export type StarGradientSVGProps = {
  gradientRatio?: number;
  width?: string;
  height?: string;
  viewBox?: string;
  activeColor?: string;
  gradientId: string;
};

const starGradientSVG = ({
  gradientRatio = 100,
  width = '20px',
  height = '20px',
  viewBox = '0 0 20 20',
  gradientId,
  activeColor = '#ffd10f'
}: StarGradientSVGProps): JSX.Element => {
  const getGradient = () => {
    if (gradientRatio === 100) {
      return (
        <>
          <stop offset="0%" stopColor={activeColor} stopOpacity="1" />
          <stop offset="100%" stopColor={activeColor} stopOpacity="1" />
        </>
      );
    }
    if (gradientRatio === 0) {
      return (
        <>
          <stop offset="0%" stopColor="#f0f2f6" stopOpacity="1" />
          <stop offset="100%" stopColor="#f0f2f6" stopOpacity="1" />
        </>
      );
    }
    return (
      <>
        <stop offset="0%" stopColor={activeColor} stopOpacity="1" />
        <stop
          offset={`${gradientRatio}%`}
          stopColor={activeColor}
          stopOpacity="1"
        />
        <stop
          offset={`${gradientRatio}%`}
          stopColor="#f0f2f6"
          stopOpacity="1"
        />
        <stop offset="100%" stopColor="#f0f2f6" stopOpacity="1" />
      </>
    );
  };
  return (
    <svg width={width} height={height} viewBox={viewBox}>
      <linearGradient
        id={`MyGradient${gradientId}`}
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
      >
        {getGradient()}
      </linearGradient>
      <path
        d="M 0 7.863281 C 0 8.101562 0.0898438 8.351562 0.273438 8.535156 L 4.433594 12.78125 L 4.472656 12.878906 L 4.472656 12.902344 L 3.488281 18.898438 L 3.476562 19.058594 C 3.480469 19.597656 3.894531 20 4.375 20 C 4.515625 20 4.660156 19.964844 4.792969 19.890625 L 9.941406 17.058594 L 10 17.042969 L 10.058594 17.058594 L 15.207031 19.890625 C 15.339844 19.964844 15.484375 20 15.625 20 C 16.105469 20 16.519531 19.597656 16.523438 19.058594 L 16.511719 18.898438 L 15.527344 12.902344 L 15.527344 12.878906 L 15.566406 12.78125 L 19.726562 8.535156 C 19.910156 8.347656 20 8.101562 20 7.863281 C 20 7.644531 19.929688 7.433594 19.796875 7.261719 C 19.664062 7.089844 19.460938 6.964844 19.230469 6.929688 L 13.476562 6.054688 L 13.378906 5.980469 L 10.808594 0.523438 C 10.644531 0.179688 10.3125 -0.00390625 10 0 C 9.6875 -0.00390625 9.355469 0.179688 9.195312 0.523438 L 6.621094 5.980469 L 6.523438 6.054688 L 0.769531 6.929688 C 0.539062 6.964844 0.335938 7.089844 0.203125 7.261719 C 0.0703125 7.433594 0 7.644531 0 7.863281 Z M 0 7.863281 "
        fill={`url(#MyGradient${gradientId})`}
      />
    </svg>
  );
};

export default starGradientSVG;
