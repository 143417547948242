import React, { useState } from 'react';
import './styles.scss';
import t from '@/lib/i18n';
import Button from '@/components/atoms/button';
import Link from '@/components/atoms/link';
import { AuthStateType } from '@/store/auth/authReducer';
import { useSelector } from 'react-redux';
import Modal from '@/components/organisms/modal';
import FormInput from '@/components/atoms/formInput';
import { useForm } from 'react-hook-form';
import { notification } from '@/lib/notification';
import shoppingListApi from '@/lib/api/shoppingList';
import useEvent from '@/lib/hooks/useEvent';

type CreateShoppingListProps = {
  className?: string;
  ctaLabel: string;
  type: 'link' | 'button';
  onCreated: () => void;
};

type StateType = {
  openModal: boolean;
  isLoading: boolean;
};

type FormType = {
  name?: string;
};

const CreateShoppingList = ({
  className,
  type,
  ctaLabel,
  onCreated
}: CreateShoppingListProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );

  const event = useEvent();

  const [state, setState] = useState<StateType>({
    openModal: false,
    isLoading: false
  });

  const onClick = () => {
    setState((oldState) => ({
      ...oldState,
      openModal: true
    }));
  };

  const close = () => {
    reset({
      name: undefined
    });
    setState((oldState) => ({
      ...oldState,
      isLoading: false,
      openModal: false
    }));
  };

  const onSubmit = async (data: FormType) => {
    if (!userId || !data.name) {
      return;
    }
    try {
      await shoppingListApi.create(userId, data.name);
      notification.success(t('myAccount.lists.created'));
      event.send('api', {
        name: 'createShoppingList',
        type: 'success'
      });

      close();
      onCreated();
    } catch (error: any) {
      event.send('api', {
        name: 'createShoppingList',
        type: 'error'
      });
      if (
        error.response.status === 409 &&
        error.response.data?.errors?.[0]?.code === 'CONFLICT_SHOPPING_LIST_NAME'
      ) {
        setError('name', {
          message: t('myAccount.lists.shoppingList.create.error.existName')
        });
      } else {
        notification.error(t('notification.erreur'));
      }
    }
  };

  const { register, errors, reset, handleSubmit, setError } = useForm<FormType>(
    {
      shouldUnregister: false,
      defaultValues: {
        name: undefined
      }
    }
  );

  const errorMessage = () => {
    if (errors.name) {
      if (errors.name.message) {
        return errors.name.message;
      }
      return t(`form.error.${errors.name.type}`);
    }
    return '';
  };

  return (
    <>
      {type === 'button' && (
        <Button className={className} onClick={() => onClick()}>
          {ctaLabel}
        </Button>
      )}
      {type === 'link' && (
        <Link
          className={className}
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          {ctaLabel}
        </Link>
      )}
      <Modal
        className="myShoppingListCreate"
        open={state.openModal}
        onClose={() =>
          setState((oldState) => ({
            ...oldState,
            openModal: false
          }))
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            className="myShoppingListCreate__input"
            id="name"
            name="name"
            label={t('myAccount.lists.create-modal.label')}
            ref={register({
              required: true
            })}
            errorMessage={errorMessage()}
          />
          <div className="myShoppingListCreate__buttons">
            <Button
              color="secondary"
              onClick={() => {
                close();
              }}
            >
              {t('myAccount.lists.create-modal.cancel')}
            </Button>
            <Button type="submit" loading={state.isLoading}>
              {t('myAccount.lists.create-modal.confirmation')}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CreateShoppingList;
