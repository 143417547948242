// @ts-nocheck
import React, { useEffect, useState } from 'react';
import './style.scss';

const ChatBotMessenger = () => {
  const [fbLoaded, setFbLoad] = useState(false);

  const removeElementByIds = (ids = []) => {
    ids.forEach((id) => {
      const element = document.getElementById(id);
      if (element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeFacebookSDK = () => {
    removeElementByIds(['facebook-jssdk', 'fb-root']);
    delete window.FB;
  };

  const loadSDKAsynchronously = () => {
    (function loadSDK(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  const setFbAsyncInit = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '1596524097191018',
        xfbml: true,
        version: 'v6.0'
      });
    };
  };

  useEffect(() => {
    if (!fbLoaded) {
      setFbAsyncInit();
      removeFacebookSDK();
      loadSDKAsynchronously();
      setFbLoad(true);
    }
    return () => {
      removeFacebookSDK();
    };
  }, [fbLoaded, removeFacebookSDK]);

  const createMarkup = () => ({
    __html: `<div
          class="fb-customerchat"
          page_id="366230539391"
          theme_color="#ef1033"
          logged_in_greeting="Bonjour, Intermarché répond à toutes vos questions"
          logged_out_greeting="Bonjour, Intermarché répond à toutes vos questions"
          attribution="setup_tool"
          ></div>`
  });

  return fbLoaded ? (
    <div
      className="chatBot__Messenger"
      key={Date()}
      dangerouslySetInnerHTML={createMarkup()}
    />
  ) : null;
};

export default ChatBotMessenger;
