import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';

class CmsApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi']);
  }

  getTemplatesNoResults = async (): Promise<any> => {
    let response = null;

    try {
      response = await this.axios.get(
        `/cms/templateAucunResultat/recherche-produit-aucun-resultat`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to get Templates for nos results search',
        error
      });
    }

    const data = response?.data ?? [];
    const ProductSliderTemplates: SuggestionsContentProps[] = [];

    data.content?.forEach((value: any) => {
      ProductSliderTemplates.push({
        title: value.properties.title,
        buttonLabel: value.properties.buttonText,
        shopId: value.properties.idBoutique
      });
    });

    const NoResultsTemplates: any = {
      essentials: ProductSliderTemplates[0],
      recommendations: ProductSliderTemplates[1]
    };

    return NoResultsTemplates;
  };
}

export default new CmsApi();

export type SuggestionsContentProps = {
  shopId: string;
  title: string;
  buttonLabel: string;
};
