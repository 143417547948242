import React, { memo } from 'react';
import './style.scss';

import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import Link from '@/components/atoms/link';

export type CTAStoreProps = {
  items?: Array<CTAStoreItemProps>;
};

export type CTAStoreItemProps = {
  image: Component;
  id: string;
  link?: {
    href: string;
    title: string;
  };
};

const CTAStore = ({ items }: CTAStoreProps) => (
  <div className="ctaStore">
    {items?.map((item) => (
      <Link href={item.link?.href} title={item.link?.title} key={item.id}>
        <ContentfulRenderComponents components={[item.image]} />
      </Link>
    ))}
  </div>
);

export default memo(CTAStore);
