import React from 'react';
import Modal from '@/components/organisms/modal';
import t from '@/lib/i18n';
import classnames from 'classnames';

type NutritionModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const NutritionModal = ({ isOpen, setIsOpen }: NutritionModalProps) => {
  return (
    <Modal
      closable={true}
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      pageView="Nutrition"
    >
      <div className="nutritionModal">
        <div className="nutritionModal__modalTitle">
          {t('pdp.nutrition.modal.title')}
        </div>
        <div className="nutritionModal__content">
          {t('pdp.nutrition.modal.p1')}
        </div>
        <div className="nutritionModal__content">
          {t('pdp.nutrition.modal.p2')}
        </div>
        <div className="nutritionModal__content">
          {t('pdp.nutrition.modal.p3')}
        </div>
        <h4 className="nutritionModal__title">{t('pdp.nutrition.modal.t2')}</h4>
        <div className="nutritionModal__content__desc">
          {['excellent', 'good', 'insignificant', 'ok', 'weak', 'bad'].map(
            (item) => (
              <div className="nutritionModal__content__item" key={item}>
                <div className="nutritionModal__content__item__label">
                  <div
                    className={classnames(
                      'innit-nutrients__tag',
                      `innit-nutrients__tag--${item}`
                    )}
                  />
                </div>
                <div>
                  <div className="nutritionModal__content__item__title">
                    {t(`pdp.nutrition.modal.${item}.title`)}
                  </div>
                  <div>{t(`pdp.nutrition.modal.${item}.desc`)}</div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};
export default NutritionModal;
