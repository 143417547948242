import React, { Key, MouseEvent } from 'react';
import './style.scss';
import classnames from 'classnames';
import Link from '@/components/atoms/link';
import { ReactComponent as Arrow } from '@/assets/images/icons/arrow-right.svg';

export type BreadcrumbItem = {
  label: string;
  link?: string;
  onClick?: (event: MouseEvent<any>) => void;
};

export type BreadcrumbProps = {
  items: Array<BreadcrumbItem>;
};

const Breadcrumb = ({ items = [] }: BreadcrumbProps) => {
  return (
    <div className="breadcrumb">
      {items.map((item, index: Key) => {
        const key = `breadcrumb-${index}`;

        const classNames = classnames({
          breadcrumb__item: true,
          'breadcrumb__item--last': items.length - 1 === index
        });

        return (
          <React.Fragment key={key}>
            {!item.link && <span className={classNames}>{item.label}</span>}
            {item.link && (
              <Link
                href={item.link}
                className={classNames}
                label={item.label}
                onClick={item.onClick}
              />
            )}
            {index !== items.length - 1 && (
              <Arrow className="breadcrumb__picto" width="11" height="11" />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
