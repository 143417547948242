import React, { memo } from 'react';
import Image from '@/components/atoms/image';
import { FidelityInfo } from '@/lib/api/fidelity/types';
import t from '@/lib/i18n';

import './style.scss';
import FidelityDetailedBalance from '@/components/molecules/FidelityDetailedBalance';
// import MyAdvantages from '../../myAccount/fidelity/myAdvantages';

export type FidInfosProps = {
  infos: FidelityInfo;
  fidelityCardImage?: string;
};

const FidInfos = ({ infos, fidelityCardImage }: FidInfosProps) => {
  return (
    <div className="fidInfos">
      <div className="fidInfos__bloc fidInfos__bloc_left">
        <div className="fidInfos__bloc_left_withImage">
          <Image
            src={fidelityCardImage ?? ''}
            alt="fidInfos__bloc_left_withImage"
            width={110}
          />
          {t('fidelityCard.fidInfo.myCard')}
        </div>
        <div className="fidInfos__text">
          <div className="fidInfos__text--bold">
            {t('fidelityCard.fidInfo.myCard')} {infos.cardNumber}
          </div>
          {t('fidelityCard.fidInfo.yourInfos')}{' '}
          {infos.onlineUsableBalance?.calculatedOn.format('DD/MM/YYYY')}
        </div>
      </div>

      <div className="fidInfos__bloc fidInfos__bloc_middle">
        <div className="fidInfos__text--md fidInfos__bloc_fixedWidth">
          {t('fidelityCard.fidInfo.myBalance')}
        </div>
        <div className="fidInfos__text--xl">
          <span className="fidInfos__text--xl--integer">
            {infos.onlineUsableBalance?.amount.integer}
          </span>
          ,{infos.onlineUsableBalance?.amount?.decimal}{' '}
          {infos.onlineUsableBalance?.amount?.currency}
        </div>
      </div>

      <div className="fidInfos__bloc fidInfos__bloc_right">
        <FidelityDetailedBalance fidelityInfo={infos} />
        {/* <MyAdvantages fidelityInfo={infos} /> */}
      </div>
    </div>
  );
};

export default memo(FidInfos);
