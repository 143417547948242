import React from 'react';
import './style.scss';
import Layout from '@/components/organisms/layout';
import { useIsomorphicLayoutEffect } from 'react-use';
import usePdv from '@/lib/hooks/usePdv';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import { withESI, ESIOptions } from '@/lib/esi';
import Title from '@/components/atoms/title';
import Header from '@/components/organisms/header';
import Content from './contentESI';

export type CgvProps = {
  footer: ContentfulContent;
  pageView: string;
};

const Cgv = ({ footer, pageView }: CgvProps) => {
  useIsomorphicLayoutEffect(() => {
    const { isEcommerce } = usePdv();
    if (!isEcommerce) {
      window.location.href = '/home';
    }
  }, []);

  const headerPart = <Header />;

  const ContentESI = withESI(Content, {
    id: 'cgv-content',
    type: 'templates',
    name: 'cgv/content'
  });

  const contentPart = (
    <>
      <Title
        tag="h2"
        label="Condições gerais de venda"
        className="cgv__title"
      />
      <ContentESI esi={ESIOptions()} />
    </>
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout
      className="cgv"
      header={headerPart}
      content={contentPart}
      footer={footerPart}
      pageView={pageView}
    />
  );
};

export default Cgv;
