import React from 'react';
import './style.scss';
import { NextSeo } from 'next-seo';
import Layout from '@/components/organisms/layout';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Header from '@/components/organisms/header';
import SavSubBanner from '@/components/molecules/savBanner/savSubBanner';
import classnames from 'classnames';
import FormContactUs from './form';

export type ContactUsProps = {
  footer: ContentfulContent;
  slug: string;
  pageView?: string;
};

const ContactUs = ({ footer, slug, pageView }: ContactUsProps) => {
  const headerPart = <Header />;

  const contentPart = (
    <div className={classnames('contactezNous', 'container', 'container--md')}>
      <SavSubBanner href="/help-and-contact/vas/mail" />
      <FormContactUs slug={slug} />
    </div>
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <>
      <NextSeo title="Intermarché" />
      <Layout
        className="sav"
        header={headerPart}
        content={contentPart}
        footer={footerPart}
        pageView={pageView}
      />
    </>
  );
};

export default ContactUs;
