import React from 'react';
import Image from '@/components/atoms/image';
import './style.scss';

export type PdvMessageProps = {
  title: string;
  image?: string;
  message: string | null;
};

const PdvMessage = ({ title, image, message }: PdvMessageProps) => {
  if (!message) {
    return null;
  }

  return (
    <div className="pdv_message">
      <div className="container">
        <div className="pdv_message__image">
          {image && <Image src={image} alt="" />}
        </div>
        <div className="pdv_message__container">
          <div className="pdv_message__title">{title}</div>
          <div
            className="pdv_message__message"
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PdvMessage;
