import SmartConsoSprite from '@/assets/images/sprites/smartconso.svg';
import t from '@/lib/i18n';
import React from 'react';
import { SharedProps } from '../types';
import './style.scss';

const StepHeader: React.FC<SharedProps> = ({
  theme,
  questionLabel,
  description
}) => {
  return (
    <div className="step-header">
      <div className="step-header__header">
        {theme.id === 0 && (
          <svg width="45px" height="32px">
            <use xlinkHref={`${SmartConsoSprite}#userInfo`} />
          </svg>
        )}
        {theme.id === 1 && (
          <svg width="45px" height="32px">
            <use xlinkHref={`${SmartConsoSprite}#plate`} />
          </svg>
        )}
        {theme.id === 2 && (
          <svg width="45px" height="32px">
            <use xlinkHref={`${SmartConsoSprite}#values`} />
          </svg>
        )}
        <div className="step-header__header__theme">{t(theme.value)}</div>
      </div>

      <div className="step-header__question">{t(`${questionLabel}`)}</div>
      <div className="step-header__description">
        {description && t(`${description}`)}
      </div>
    </div>
  );
};

export default StepHeader;
