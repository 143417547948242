import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import Link from '@/components/atoms/link';
import { useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import { PdvVoucher } from '@/lib/api/voucher/types';
import VoucherApi from '@/lib/api/voucher';

const Voucher = () => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [pdvVouchers, setPdvVouchers] = useState<PdvVoucher | null>(null);

  useEffect(() => {
    (async () => {
      if (!userId) return;

      let data: Array<PdvVoucher>;

      try {
        data = await VoucherApi.list(userId);
      } catch {
        data = [];
      }

      const pdvVoucher = data.shift();

      if (pdvVoucher) {
        setPdvVouchers(pdvVoucher as PdvVoucher);
      }
    })();
  }, [userId]);

  if (!pdvVouchers) return null;

  return (
    <Link className="myAccountDashboard__container myAccountDashboard__voucher">
      <>
        <div className="myAccountDashboard__voucher_title">
          {t('myAccount.dashboard.voucher.title', {
            '%amount%': pdvVouchers.amount.value
              .toFixed(2)
              .toString()
              .replace('.', ','),
            '%currency%': pdvVouchers.amount.currency
          })}
        </div>
        <div className="myAccountDashboard__voucher_message">
          {t('myAccount.dashboard.voucher.message', {
            '%storeName%': pdvVouchers.pdv.city,
            '%storeFormat%': pdvVouchers.pdv.format,
            '%endDate%': pdvVouchers.endDate.format('DD/MM/YYYY')
          })}
        </div>
        <div className="myAccountDashboard__voucher_highlight">
          <div className="myAccountDashboard__voucher_amountTitle">
            {t('myAccount.dashboard.voucher.text1')}
          </div>
          <div className="myAccountDashboard__voucher_amount">
            {pdvVouchers.amount.value.toFixed(2).toString().replace('.', ',')}
            {pdvVouchers.amount.currency}
          </div>
        </div>
      </>
    </Link>
  );
};

export default Voucher;
