import React from 'react';
import { Request, Response } from 'express';
import SlotsPublicApi from '@/lib/api/slots/public';
import usePdv from '@/lib/hooks/usePdv';
import Slot, { SlotSchedule } from '@/lib/model/slot';
import Date from '@/lib/utils/date';

export type SlotData = {
  date: string;
  type: string;
  time: SlotSchedule;
};

type InitialProps = {
  req: Request;
  res: Response;
};

const NextSlotESI = ({ slots }: { slots?: Array<SlotData> }) => {
  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `const ANALYTICS_NEXT_SLOT = ${JSON.stringify(
          slots?.map(({ type, date, time }) => ({
            type,
            datetime: Date(date).hour(time.begin.hour).minute(time.begin.minute)
          }))
        )}`
      }}
    />
  );
};

NextSlotESI.getInitialProps = async ({ req, res }: InitialProps) => {
  const { ref, isEcommerce } = usePdv(req);

  let slots: Array<Slot> = [];

  if (ref && isEcommerce) {
    try {
      slots = await SlotsPublicApi.getPdvSlots(ref);
    } catch (error: any) {
      slots = [];
    }
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=900, max-age=900');
    }

    resolve({
      slots: slots.map((slot) => {
        return {
          type: slot.type,
          date: slot.date,
          time: slot.time
        };
      })
    });
  });
};

export default NextSlotESI;
