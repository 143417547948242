import Rijndael from 'rijndael-js';
import getConfigValue from '@/lib/config';

export const CatalinaEncrypt = (value: string): string => {
  // Key can be 16/24/32 bytes long (128/192/256 bit)
  const key = getConfigValue('CATALINA_CRYPTO_KEY')?.toString();

  // Create Rijndael instance `new Rijndael(key, mode)`
  const cipher = new Rijndael(key, 'ecb');

  // `Rijndael.encrypt(plaintext, blockSize[, iv]) -> <Array>`
  // Output will always be <Array> where every element is an integer <Number>
  const ciphertext = Buffer.from(cipher.encrypt(value, '128', ''));

  return ciphertext.toString('base64');
};
