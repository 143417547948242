import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { withESI, ESIOptions } from '@/lib/esi';
import ProductSlider from '@/components/organisms/productSliderESI';
import topCategoriesTiles from '@/components/organisms/topCategoriesTilesESI';
import { SuggestionsContentProps } from '@/lib/api/cms';

type WithoutResultProps = {
  keyword: string;
  suggestions?: SuggestionsProps;
};

export type SuggestionsProps = {
  essentials: SuggestionsContentProps;
  recommendations: SuggestionsContentProps;
};

const WithoutResults = ({ keyword, suggestions }: WithoutResultProps) => {
  const CategoriesESI = withESI(topCategoriesTiles, {
    id: 'search-topCategoriesTiles',
    type: 'organisms',
    name: 'topCategoriesTiles'
  });

  const SliderEssentialsESI = withESI(ProductSlider, {
    id: 'search-products-slider-essentials',
    type: 'organisms',
    name: 'productSlider'
  });

  const SliderRecommendationsESI = withESI(ProductSlider, {
    id: 'search-products-slider-recommendations',
    type: 'organisms',
    name: 'productSlider'
  });

  return (
    <>
      <div className="searchWithoutResults">
        <div>
          <svg className="noSearchIcon">
            <use xlinkHref={`${CommonSprite}#search-no-results`} />
          </svg>
        </div>
        <p
          className="title"
          dangerouslySetInnerHTML={{
            __html: t('search.isEmpty', { '%keyword%': keyword })
          }}
        />
      </div>
      <CategoriesESI esi={ESIOptions()} />
      {suggestions && (
        <>
          <SliderEssentialsESI
            esi={ESIOptions()}
            shopId={suggestions.essentials?.shopId}
            title={suggestions.essentials?.title}
            buttonLabel={suggestions.essentials?.buttonLabel}
          />
          <SliderRecommendationsESI
            esi={ESIOptions()}
            shopId={suggestions.recommendations?.shopId}
            title={suggestions.recommendations?.title}
            buttonLabel={suggestions.recommendations?.buttonLabel}
          />
        </>
      )}
    </>
  );
};

export default WithoutResults;
