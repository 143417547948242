import React, { useMemo, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import './style.scss';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import Link from 'next/link';
import t from '@/lib/i18n';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  TOGGLE_NAV_ACTIVE,
  TOGGLE_NAV_DESCUBRA_ACTIVE
} from '@/store/nav/navActions';
import usePdv from '@/lib/hooks/usePdv';
import useEvent from '@/lib/hooks/useEvent';
import { NavStateType } from '@/store/nav/navReducer';
import getConfigValue from '@/lib/config';
import { useIsPro } from '@/lib/hooks/useIsPro';

const IS_FIDELITY_ENABLED = getConfigValue(
  'IS_FIDELITY_ENABLED',
  false
).toBoolean();

export type NavigationProps = {
  className?: string;
};

const Navigation = ({ className }: NavigationProps) => {
  const [isPdvEcommerce, setIsPdvEcommerce] = useState(true);
  const [isPro] = useIsPro();
  const isActive = useSelector(
    ({ nav }: { nav: NavStateType }) => nav.isActive
  );
  const dispatch = useDispatch();
  const toggleActive = () => {
    event.send('header', { type: 'categories' });
    dispatch({ type: TOGGLE_NAV_ACTIVE });
  };

  const toggleDescubraModalActive = () => {
    dispatch({ type: TOGGLE_NAV_DESCUBRA_ACTIVE });
  };

  const router = useRouter();
  const event = useEvent();

  useIsomorphicLayoutEffect(() => {
    const { isEcommerce } = usePdv();
    setIsPdvEcommerce(isEcommerce);
  }, []);

  const fid = useMemo(() => {
    return (
      IS_FIDELITY_ENABLED &&
      !isPro && (
        <li className="navigation__item">
          <Link href="/fidelity-advantages">
            <a
              onClick={() => event.send('header', { type: 'fid' })}
              className={classnames({
                navigation__link: true,
                'navigation__link--active': router.asPath?.includes(
                  '/fidelity-advantages'
                )
              })}
            >
              {t('header.fidelityCard')}
            </a>
          </Link>
        </li>
      )
    );
  }, [event, isPro, router.asPath]);

  return (
    <ul className={classnames('navigation', className)}>
      {isPdvEcommerce && (
        <li
          id="NavBarRayons"
          className={classnames(
            ['navigation__item', 'navigation__categories'],
            {
              'navigation__categories--isActive': isActive
            }
          )}
        >
          <span id="toggleNavigationButton" onClick={toggleActive}>
            <svg width="22" height="21">
              <use xlinkHref={`${CommonSprite}#rayons`} />
            </svg>
            {t('header.categories')}
          </span>
        </li>
      )}

      <li className="navigation__item">
        <Link href="/sign/brands/catalog-page">
          <a
            onClick={() => event.send('header', { type: 'catalogs' })}
            className={classnames({
              navigation__link: true,
              'navigation__link--active': router.asPath?.includes('/catalogs')
            })}
          >
            {t('header.catalogs')}
          </a>
        </Link>
      </li>
      {fid}
      <li className="navigation__item navigation__mobile">
        <a
          id="toggleNavigationDescubraButton"
          onClick={toggleDescubraModalActive}
          className="promotions descubraHeader"
        >
          {t('header.saiba')}
        </a>
      </li>
    </ul>
  );
};

export default React.memo(Navigation);
