import { selectHasFilledAllAnswers } from '@/store/survey/surveySelectors';
import usePageView from '@/lib/hooks/usePageView';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AlmostDoneForm } from './AlmostDoneForm';
import { DoneForm } from './DoneForm';
import './style.scss';

const Result = () => {
  const isSurveyCompleted = useSelector(selectHasFilledAllAnswers);
  const pageView = usePageView();

  useEffect(() => {
    pageView.send('smartConso', {
      vPath: `/compte/conso_mieux/${
        isSurveyCompleted
          ? 'questions_fin_parcours_complet'
          : 'questions_fin_parcours'
      }`
    });
  }, [pageView, isSurveyCompleted]);

  return (
    <div className="result-Container">
      {isSurveyCompleted ? <DoneForm /> : <AlmostDoneForm />}
    </div>
  );
};

export default Result;
