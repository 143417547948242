import CommonSprite from '@/assets/images/sprites/common.svg';
import ChooseStore from '@/components/organisms/chooseStore';
import getConfigValue from '@/lib/config';
import useEvent from '@/lib/hooks/useEvent';
import usePageView from '@/lib/hooks/usePageView';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import Product from '@/lib/model/product';
import { AuthStateType } from '@/store/auth/authReducer';
import { getCart } from '@/store/cart/actions/cart';
import { TOGGLE_MINICART_OPENED } from '@/store/cart/cartActions';
import { StateType as CartStateType } from '@/store/cart/types';
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsomorphicLayoutEffect } from 'react-use';
import getRecommendationProducts from '../../recommendation/getRecommendationProducts';
import RecommendationProductPopin from '../../recommendation/popin';
import './style.scss';

export type CartProps = {
  className?: string;
};

const Cart = ({ className }: CartProps) => {
  const {
    total,
    qty,
    isMiniCartOpened,
    cartIsHydrated,
    subCarts
  } = useSelector(
    ({ cart, auth }: { cart: CartStateType; auth: AuthStateType }) => {
      return {
        total: cart.total,
        qty: cart.qty,
        isMiniCartOpened: cart.isMiniCartOpened,
        cartIsHydrated: cart.isHydrated,
        subCarts: cart?.subCarts
      };
    }
  );
  const [isPdvEcommerce, setIsPdvEcommerce] = useState(true);
  const dispatch = useDispatch();
  const [addItem, setAddItem] = useState(false);
  const [hasItems, setHasItems] = useState(false);
  const [cartQty, setCartQty] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const pageViewMiniCart = usePageView();
  const event = useEvent();
  const [showRecoProducts, setShowRecoProducts] = useState(false);
  const [recoProducts, setRecoProducts] = useState<Product[]>([]);

  useIsomorphicLayoutEffect(() => {
    const { isEcommerce } = usePdv();
    setIsPdvEcommerce(isEcommerce);
  }, []);

  useEffect(() => {
    setCartQty(qty);
    setCartTotal(total);
    if (qty) {
      setHasItems(true);
      setAddItem(true);
      setTimeout(() => {
        setAddItem(false);
      }, 1000);
    } else {
      setHasItems(false);
    }
  }, [qty, total]);

  const toogleActive = async () => {
    pageViewMiniCart.send('miniCart');
    event.send('header', { type: 'cart' });
    dispatch({ type: TOGGLE_MINICART_OPENED });
    await dispatch(getCart(true));
  };

  useEffect(() => {
    if (!isMiniCartOpened) {
      document.documentElement.style.overflow = 'visible';
    } else {
      document.documentElement.style.overflow = 'hidden';
    }
  }, [isMiniCartOpened]);

  const OpeningCart = (isMobile: boolean, isHydrated: boolean) => {
    if (!addItem && isHydrated) {
      if (isMobile) {
        checkRecommendations();
        event.send('header', { type: 'cart' });
      } else {
        toogleActive();
      }
    }
  };

  const isEmerchEnabled = getConfigValue(
    'IS_EMERCH_ENABLED',
    false
  ).toBoolean();

  const checkRecommendations = useCallback(async () => {
    const isThereMkp = subCarts.some((subCart) => subCart.type === 'MKP');
    const isTherePDV = subCarts.find((s) => s.type === 'PDV');
    const isOnlyMkpCart = isThereMkp && !isTherePDV;

    if (!isOnlyMkpCart && isEmerchEnabled && subCarts.length > 0) {
      try {
        const response = await getRecommendationProducts();
        if (!response.products.length) {
          onRecoCancel();
        } else {
          setShowRecoProducts(true);
          setRecoProducts(response.products);
        }
      } catch (error) {
        setShowRecoProducts(false);
        onRecoCancel();
      }
    } else window.location.href = '/orders/cart';
  }, [subCarts, isEmerchEnabled]);

  const onRecoCancel = () => {
    window.location.href = '/orders/cart';
  };

  const onValidateReco = () => {
    window.location.href = '/orders/cart';
  };

  const CartButton = ({
    id,
    classNames,
    isMobile,
    isHydrated
  }: {
    id?: string;
    classNames?: string;
    isMobile: boolean;
    isHydrated: boolean;
  }) => {
    return (
      <div
        id={id}
        onClick={() => OpeningCart(isMobile, isHydrated)}
        className={classnames('cartButton', classNames)}
      >
        <svg className="fill--black" height="24" width="24">
          <use xlinkHref={`${CommonSprite}#cart`} />
        </svg>
        <span
          id="headerBadgeCart"
          className={classnames('cartButton__qty', {
            'cartButton__qty--visible': hasItems,
            'cartButton__qty--add': addItem
          })}
        >
          {cartQty}
        </span>
        {cartTotal > 0 && (
          <div id="headerCartPriceLabel" className="cartButton__price">
            {String(cartTotal.toFixed(2)).replace('.', ',')}&nbsp;&euro;
          </div>
        )}
        <div className="cartButton__label">
          {cartTotal === 0 && t('cart.heading.cartLayer')}
        </div>
      </div>
    );
  };

  return (
    <>
      {isPdvEcommerce && (
        <>
          <CartButton
            id="btn-headerMiniCartButton"
            classNames={`${className} cartButton--desktop`}
            isMobile={false}
            isHydrated={cartIsHydrated}
          />
          <CartButton
            id="btn-headerMiniCartButton"
            classNames={`${className} cartButton--mobile`}
            isMobile={true}
            isHydrated={cartIsHydrated}
          />

          {showRecoProducts && !!recoProducts?.length && (
            <RecommendationProductPopin
              productList={recoProducts}
              setIsOpen={(value) => {
                setShowRecoProducts(value);
                setRecoProducts([]);
              }}
              isOpen
              hasFooter
              onCancel={onRecoCancel}
              onValidate={onValidateReco}
              title={t('bottomProductsLayer.reco.title')}
              information={t('bottomProductsLayer.reco.informations')}
            />
          )}
        </>
      )}
      {!isPdvEcommerce && (
        <ChooseStore
          type="link"
          className={classnames('cartButton', 'cartButton__start', className)}
          title={t('header.storeInfo.chooseStore.title')}
          content={t('header.storeInfo.chooseStore.content')}
        >
          <>
            <svg className="fill--black" height="30" width="30">
              <use xlinkHref={`${CommonSprite}#cart`} />
            </svg>
            <div className="cartButton__start_message">
              {t('cart.heading.start')}
            </div>
          </>
        </ChooseStore>
      )}
    </>
  );
};

export default Cart;
