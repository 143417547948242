import { PartenersDatasProps, LuckyCartCover } from '@/lib/thirdPartyApi';
import Product from '@/lib/model/product';

class LuckyCartDataManager {
  data: Array<Product> | null;

  constructor() {
    this.data = null;
  }

  setData(data: Array<Product>) {
    this.data = data;
  }

  getData() {
    return this.data;
  }
}
const luckyCartData = new LuckyCartDataManager();

const getSearchResults = () => luckyCartData.getData();

export const lazyAddLuckyCart = (
  setPartnersDatas: any,
  bannerLuckyCArt: any,
  products: Array<Product>
) => {
  luckyCartData.setData(products);
  window.exposedApi.getSearchResults = getSearchResults;
  return ({ covers = [] }: { covers: Array<LuckyCartCover> }) => {
    /* eslint-disable no-console */
    console.info('*** luckyCart ***');

    const caversWithIds = covers
      .filter((cover: LuckyCartCover) => cover.href && cover.img)
      .map((cover: LuckyCartCover, index) => ({
        ...cover,
        id: index.toString()
      }));
    setPartnersDatas((partnesDatasTemp: PartenersDatasProps) => ({
      ...partnesDatasTemp,
      covers: caversWithIds,
      isLuckyCartExist: true
    }));
    return new Promise((resolve) => {
      resolve(bannerLuckyCArt);
    });
  };
};
