export const lazyAddShowCase = (setShowcaseProducts: any, coverRef: any) => ({
  products = []
} = {}) => {
  if (!Array.isArray(products) || !products.length) {
    /* eslint-disable no-console */
    console.info('*** addShowCase ***');

    throw new Error(
      '"products" must be an array containing at least one product'
    );
  }

  return new Promise((resolve) => {
    setShowcaseProducts([...products]);
    if (window?.exposedApi?.addShowCase) {
      delete window.exposedApi.addShowCase;
    }
    resolve([coverRef]);
  });
};
