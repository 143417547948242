import { CookiesPreferencesButtonProps } from '@/components/molecules/cookiesPreferencesButton';
import { Component, NormalizeParams } from '..';

const normalize = ({
  data
}: NormalizeParams): ComponentcookiesPreferencesFlowProps => {
  const { label } = data;
  return {
    type: 'molecules',
    name: 'cookiesPreferencesButton',
    props: {
      label
    }
  };
};

export default normalize;

interface ComponentcookiesPreferencesFlowProps extends Component {
  props: CookiesPreferencesButtonProps;
}
