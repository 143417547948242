import classnames from 'classnames';
import React from 'react';
import './style.scss';

export type TimelineEntryProps = {
  date: string;
  text: string;
};

const TimelineEntry = ({ date, text }: TimelineEntryProps) => {
  const isHtmlCmsText = /^</;

  return (
    <div className={classnames('timelineEntry', `timelineEntry_${date}`)}>
      <div className="timelineEntry__content">
        <div className="timelineEntry__content__date">
          {date}
          <div className="timelineEntry__separator" />
        </div>
        {!text?.match(isHtmlCmsText)?.length ? (
          <div className="timelineEntry__content__text">{text}</div>
        ) : (
          <div
            className="timelineEntry__content__text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      </div>
    </div>
  );
};
export default TimelineEntry;
