/* eslint-disable */
// @ts-nocheck

/***** TODO: Full refacto *****/

function customAction(state) {
  function AddNewCardSvg() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="130" height="126">
  <defs>
    <ellipse id="a" cx="13.26" cy="12.657" rx="13.26" ry="12.657" />
    <ellipse id="b" cx="13.26" cy="12.657" rx="12.597" ry="12.024" />
  </defs>
  <g fill="none" fillRule="evenodd" transform="translate(0 .273)">
    <g fillRule="nonzero" transform="translate(18.46 100.017)">
      <ellipse cx="3.094" cy="2.854" fill="#FFF" rx="1.924" ry="1.837" />
      <path fill="#9E9C9C" d="M3.094.1C1.508.1.234 1.314.234 2.83c0 1.513 1.274 2.73 2.86 2.73 1.586 0 2.86-1.217 2.86-2.73C5.954 1.34 4.68.1 3.094.1zM1.17 2.853c0-1.017.858-1.836 1.924-1.836 1.066 0 1.924.819 1.924 1.836 0 1.018-.858 1.837-1.924 1.837-1.066 0-1.924-.82-1.924-1.837z" />
    </g>
    <path fill="#EEEDED" fillRule="nonzero" d="M96.642 13.724c-.156-.297-.52-.421-.832-.297l-1.56.695-.728-1.49c-.156-.297-.52-.421-.832-.297a.608.608 0 00-.312.794l.728 1.489-1.56.695a.608.608 0 00-.312.794.626.626 0 00.572.347.92.92 0 00.26-.05l1.56-.694.728 1.489a.626.626 0 00.572.347.92.92 0 00.26-.05c.312-.148.442-.496.312-.793l-.728-1.49 1.56-.694a.61.61 0 00.312-.795z" />
    <ellipse cx="111.722" cy="16.703" fill="#EEEDED" fillRule="nonzero" rx="2.574" ry="2.457" />
    <ellipse cx="38.09" cy="110.143" fill="#EEEDED" fillRule="nonzero" rx="2.574" ry="2.457" />
    <ellipse cx="111.02" cy="37.103" fill="#EEEDED" fillRule="nonzero" rx="2.574" ry="2.457" />
    <path fill="#9E9C9C" fillRule="nonzero" d="M21.45 86.566c-.104-.224-.39-.323-.624-.224l-1.716.745-.78-1.638c-.104-.223-.39-.323-.624-.223-.234.099-.338.372-.234.595l.78 1.638-1.716.745c-.234.1-.338.372-.234.595.078.174.26.273.416.273.078 0 .13-.024.208-.05l1.716-.744.78 1.638c.078.174.26.273.416.273.078 0 .13-.025.208-.05.234-.099.338-.372.234-.595l-.78-1.638 1.716-.745c.234-.099.338-.372.234-.595z" />
    <g fillRule="nonzero" transform="translate(101.14 23.33)">
      <ellipse cx="2.938" cy="2.904" fill="#FFF" rx="1.924" ry="1.837" />
      <path fill="#9E9C9C" d="M2.938.174C1.352.174.078 1.39.078 2.904s1.274 2.73 2.86 2.73c1.586 0 2.86-1.216 2.86-2.73s-1.3-2.73-2.86-2.73zm-1.95 2.73c0-1.018.858-1.837 1.924-1.837 1.066 0 1.924.82 1.924 1.837S3.978 4.74 2.912 4.74c-1.04 0-1.924-.819-1.924-1.836z" />
    </g>
    <ellipse cx="65" cy="62.045" fill="#EEEDED" fillRule="nonzero" rx="46.8" ry="44.673" />
    <path d="M0 0h130v124.091H0z" />
    <g transform="translate(22.88 21.344)">
      <path d="M23.27 36.11l-.234-.372-.078.372h.312z" />
      <path fill="#FFF" fillRule="nonzero" d="M11.7 11.49c2.522 0 4.81.944 6.5 2.483.208-.348.442-.67.702-.968-1.898-1.663-4.42-2.68-7.202-2.68-5.902 0-10.686 4.566-10.686 10.2 0 2.159.702 4.144 1.898 5.782.26-.298.572-.595.858-.868a8.737 8.737 0 01-1.534-4.94c0-4.938 4.238-9.008 9.464-9.008z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M2.912 26.332a9.803 9.803 0 01-1.898-5.783c0-5.633 4.784-10.2 10.686-10.2 2.782 0 5.304 1.018 7.202 2.68.182-.223.39-.446.572-.67A11.823 11.823 0 0011.7 9.481C5.304 9.48.104 14.444.104 20.549c0 2.433.832 4.666 2.21 6.503a5.63 5.63 0 01.598-.72z" />
      <path fill="#FFF" fillRule="nonzero" d="M17.732 14.767a8.812 8.812 0 00-6.032-2.383c-4.706 0-8.554 3.673-8.554 8.165 0 1.614.494 3.103 1.326 4.368a11.886 11.886 0 017.228-2.432c2.028 0 3.952.497 5.616 1.39a10.354 10.354 0 01-.832-4.12c0-1.787.442-3.5 1.248-4.988z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M11.7 12.36c2.366 0 4.498.918 6.032 2.382.13-.273.286-.521.468-.794-1.69-1.539-3.978-2.482-6.5-2.482-5.226 0-9.464 4.045-9.464 9.034 0 1.812.572 3.524 1.534 4.939.234-.199.468-.397.702-.571A7.91 7.91 0 013.146 20.5c0-4.467 3.822-8.14 8.554-8.14z" />
      <path fill="#FFF" fillRule="nonzero" d="M20.748 36.11h1.274c.13-.52.234-1.042.286-1.588l-1.248-2.06c.052.348.078.72.078 1.092 0 .869-.13 1.737-.39 2.556zm-2.652-10.721c-1.794-1.29-4.004-2.035-6.396-2.035-5.902 0-10.686 4.566-10.686 10.2 0 3.946 2.366 7.396 5.824 9.084v-1.34c-2.756-1.589-4.602-4.468-4.602-7.744 0-4.988 4.238-9.034 9.464-9.034 2.444 0 4.654.894 6.344 2.333 0-.025 0-.05.026-.099.078-.273.26-.521.52-.67-.182-.223-.338-.472-.494-.695z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M6.838 42.613C3.38 40.925 1.014 37.5 1.014 33.529c0-5.633 4.784-10.2 10.686-10.2 2.392 0 4.628.77 6.396 2.035a8.404 8.404 0 01-.78-1.514 11.867 11.867 0 00-5.616-1.39c-2.73 0-5.252.919-7.228 2.433-.234.173-.468.372-.702.57a9.012 9.012 0 00-.858.87c-.208.222-.39.47-.598.719a10.618 10.618 0 00-2.21 6.502c0 4.443 2.756 8.29 6.734 10.052v-.993zm16.12-6.503l.078-.372-.728-1.216a10.703 10.703 0 01-.286 1.588h.936z" />
      <path fill="#FFF" fillRule="nonzero" d="M11.7 25.364c-4.706 0-8.554 3.673-8.554 8.165 0 2.78 1.456 5.237 3.692 6.726v-2.482c0-.918.78-1.663 1.742-1.663h11.232a7.668 7.668 0 00.442-2.556c-.026-4.517-3.848-8.19-8.554-8.19z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M11.7 24.496c-5.226 0-9.464 4.045-9.464 9.033 0 3.276 1.846 6.18 4.602 7.744V40.23c-2.236-1.464-3.692-3.921-3.692-6.725 0-4.493 3.848-8.166 8.554-8.166s8.554 3.673 8.554 8.166c0 .893-.156 1.762-.442 2.556h.962c.26-.82.39-1.688.39-2.556 0-.373-.026-.745-.078-1.092l-2.938-4.865a1.047 1.047 0 01-.13-.744 9.79 9.79 0 00-6.318-2.308z" />
      <path fill="#FFF" fillRule="nonzero" d="M28.08 9.555c-5.902 0-10.686 4.567-10.686 10.2 0 2.184.728 4.22 1.976 5.907l1.066-.57a8.687 8.687 0 01-1.82-5.312c0-4.988 4.238-9.034 9.464-9.034 3.874 0 7.202 2.234 8.658 5.41l1.066-.57c-1.69-3.55-5.408-6.031-9.724-6.031z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M18.902 13.005c-.26.322-.494.645-.702.968-.156.248-.312.52-.468.794a10.475 10.475 0 00-1.248 4.988c0 1.44.286 2.83.832 4.12.234.521.494 1.042.78 1.514.156.248.312.472.468.695l.78-.422a9.784 9.784 0 01-1.976-5.907c0-5.633 4.784-10.2 10.686-10.2 4.316 0 8.06 2.457 9.724 6.006l.78-.422c-1.846-3.797-5.876-6.428-10.53-6.428a11.88 11.88 0 00-8.606 3.648c-.156.199-.338.422-.52.646z" />
      <path fill="#FFF" fillRule="nonzero" d="M28.08 11.59c-4.706 0-8.554 3.673-8.554 8.165a7.9 7.9 0 001.69 4.89l14.716-8.066c-1.274-2.929-4.316-4.989-7.852-4.989z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M28.08 10.721c-5.226 0-9.464 4.046-9.464 9.034 0 1.986.676 3.822 1.82 5.311l.78-.422a7.894 7.894 0 01-1.69-4.889c0-4.492 3.848-8.165 8.554-8.165 3.536 0 6.578 2.06 7.878 4.989l.78-.422c-1.456-3.202-4.784-5.436-8.658-5.436z" />
      <path fill="#FFF" fillRule="nonzero" d="M41.496 36.11l3.978-2.134a.46.46 0 01.624.174c.13.198.052.471-.182.595l-2.548 1.365h27.846l11.908-6.527c.104-.05.156-.198.078-.298L73.138 12.558 29.198 36.11h12.298zm22.62-13.7l4.368-2.407c.884-.471 1.898-.62 2.86-.372.988.248 1.794.844 2.288 1.688l.442.744c1.04 1.713.416 3.946-1.378 4.94l-4.368 2.407a3.836 3.836 0 01-1.872.496c-.338 0-.65-.05-.988-.124-.988-.248-1.794-.844-2.288-1.688l-.442-.744c-1.066-1.713-.442-3.922 1.378-4.94zm-4.16 9.084c.13.199.052.472-.182.596l-7.098 3.797c-.078.05-.156.05-.234.05a.433.433 0 01-.39-.224c-.13-.198-.052-.471.182-.595l7.098-3.797c.234-.1.52-.025.624.173zm-10.14.15l7.098-3.798a.46.46 0 01.624.174c.13.198.052.471-.182.595l-7.098 3.798c-.078.05-.156.05-.234.05a.433.433 0 01-.39-.224.457.457 0 01.182-.596zm22.88-19.855L70.07 7.396 22.438 32.934l1.898 3.176h3.016zm-3.094-5.138l-3.276-5.435c-.052-.074-.104-.1-.13-.1h-.052c-.026 0-.078 0-.104.026L19.032 26.828c-.104.05-.156.199-.078.298l3.042 5.063L69.602 6.651z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M83.98 28.864L67.132.769a1.104 1.104 0 00-.702-.496 1.145 1.145 0 00-.858.1L38.61 15.138l-.78.422-1.066.57-.78.423-14.716 8.066-.78.422-1.066.57-.78.422c-.26.15-.442.373-.52.67 0 .025 0 .05-.026.1-.052.248 0 .52.13.744l2.938 4.865 1.248 2.06.728 1.216.234.372h1.04l-1.898-3.152-.676.347-.442-.769.598-.323-3.042-5.063c-.052-.099-.026-.248.078-.297L66.014 1.117c.052-.025.078-.025.104-.025h.052c.026 0 .104.025.13.1l3.276 5.434.208-.099.234.372.234.373-.182.099 2.626 4.393.39-.224.442.77-.39.223L83.2 29.285c.052.1.026.249-.078.298L71.214 36.11h.598c.312 0 .598.075.832.199l10.92-5.981c.546-.298.728-.943.416-1.464z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M70.07 7.396l.182-.099-.234-.373-.208-.372-.208.099L21.97 32.189l-.598.323.468.744.598-.322zM29.224 36.11l43.914-23.552.416-.223-.468-.745-.39.199L27.352 36.11z" />
      <path fill="#FFF" fillRule="nonzero" d="M64.558 23.18c-1.378.745-1.846 2.432-1.04 3.748l.442.744a2.772 2.772 0 001.742 1.266 2.92 2.92 0 002.184-.273l4.368-2.407c1.378-.745 1.846-2.433 1.04-3.748l-.442-.744A2.772 2.772 0 0071.11 20.5c-.26-.075-.494-.1-.754-.1-.494 0-.988.125-1.43.373l-4.368 2.407z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M63.18 28.094c.494.844 1.326 1.44 2.288 1.688.338.074.65.124.988.124.65 0 1.3-.174 1.872-.496l4.368-2.408c1.794-.993 2.418-3.201 1.378-4.939l-.442-.744c-.494-.844-1.326-1.44-2.288-1.688-.988-.248-2.002-.124-2.86.372l-4.368 2.408c-1.794.993-2.418 3.201-1.378 4.939l.442.744zm7.93-7.594a2.96 2.96 0 011.742 1.266l.442.744c.78 1.315.312 2.978-1.04 3.748l-4.368 2.407a2.91 2.91 0 01-2.184.273 2.963 2.963 0 01-1.742-1.266l-.442-.744c-.78-1.316-.312-2.978 1.04-3.748l4.368-2.407a2.923 2.923 0 011.43-.372c.26 0 .52.024.754.099zM50.05 32.462c.078 0 .156-.025.234-.05l7.098-3.797a.456.456 0 00.182-.595.503.503 0 00-.624-.174l-7.098 3.797a.457.457 0 00-.182.596.432.432 0 00.39.223zm-3.952 1.688a.503.503 0 00-.624-.174l-3.978 2.134h1.846l2.548-1.365c.234-.124.312-.397.208-.595zm5.98 1.588a.43.43 0 00.39.224c.078 0 .156-.025.234-.05l7.098-3.797a.457.457 0 00.182-.596.504.504 0 00-.624-.174l-7.098 3.798c-.234.124-.312.372-.182.595z" />
      <path fill="#FFF" fillRule="nonzero" d="M54.626 62.641v-9.034c0-.819.702-1.489 1.56-1.489h16.458v-14.37c0-.421-.364-.794-.832-.794H8.58c-.442 0-.832.348-.832.794v41.77c0 .421.364.794.832.794h63.232c.442 0 .832-.348.832-.795V64.13H56.186c-.858 0-1.56-.67-1.56-1.489zm-25.792 8.662c-7.306 0-13.26-5.684-13.26-12.658 0-6.974 5.954-12.657 13.26-12.657s13.26 5.683 13.26 12.657-5.954 12.658-13.26 12.658z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M71.812 36.11H8.58c-.962 0-1.742.745-1.742 1.663v41.769c0 .919.78 1.663 1.742 1.663h63.232c.962 0 1.742-.744 1.742-1.663V64.155h-.91v15.387c0 .422-.364.794-.832.794H8.58c-.442 0-.832-.347-.832-.794V37.748c0-.421.364-.794.832-.794h63.232c.442 0 .832.348.832.794v14.37h.91v-14.37c0-.62-.364-1.166-.884-1.439a1.975 1.975 0 00-.858-.199z" />
      <path fill="#FFF" fillRule="nonzero" d="M56.186 53.012c-.364 0-.65.273-.65.62v9.034c0 .347.286.62.65.62h19.188c.364 0 .65-.273.65-.62v-9.034c0-.347-.286-.62-.65-.62H56.186zm5.434 7.842c-1.56 0-2.86-1.216-2.86-2.73 0-1.489 1.274-2.73 2.86-2.73 1.586 0 2.86 1.216 2.86 2.73s-1.274 2.73-2.86 2.73z" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M72.644 52.143H56.186c-.858 0-1.56.67-1.56 1.49v9.033c0 .819.702 1.489 1.56 1.489h19.188c.858 0 1.56-.67 1.56-1.49v-9.033c0-.819-.702-1.489-1.56-1.489h-2.73zm3.38 1.49v9.033c0 .347-.286.62-.65.62H56.186c-.364 0-.65-.273-.65-.62v-9.034c0-.347.286-.62.65-.62h19.188c.364 0 .65.273.65.62z" />
      <ellipse cx="61.62" cy="58.124" fill="#FFF" fillRule="nonzero" rx="1.95" ry="1.861" />
      <path fill="#1D1D1D" fillRule="nonzero" d="M61.62 55.419c-1.56 0-2.86 1.216-2.86 2.73 0 1.49 1.274 2.73 2.86 2.73 1.586 0 2.86-1.216 2.86-2.73s-1.274-2.73-2.86-2.73zm0 4.567c-1.066 0-1.95-.82-1.95-1.862 0-1.042.858-1.861 1.95-1.861s1.95.819 1.95 1.861c0 1.043-.884 1.862-1.95 1.862z" />
      <g transform="translate(15.86 46.162)">
        <use fill="#040505" fillRule="nonzero" xlinkHref="#a" />
        <mask id="c" fill="#fff"><use xlinkHref="#b" /></mask>
        <g fill="#E41E37" fillRule="nonzero" mask="url(#c)"><path d="M0 0h26.52v25.315H0z" /></g>
        <path fill="#FCFAFA" fillRule="nonzero" d="M17.82 9.49a.677.677 0 00-.334-.183c-.31-.081-.732.02-1.22.49l-4.37 4.035-.111-.102-1.531-1.427c-.51-.469-.932-.57-1.22-.489-.134.04-.222.102-.333.183-.089.082-.155.204-.222.327-.133.346-.111.754.133.978l2.285 2.12c.444.407.732.61.999.61.266 0 .576-.203.998-.61l5.014-4.627c.244-.224.266-.632.133-.978a.871.871 0 00-.222-.326z" mask="url(#c)" />
      </g>
    </g>
    <ellipse cx="52" cy="80.182" fill="#E41E37" rx="12" ry="11.455" />
    <path fill="#FFF" fillRule="nonzero" d="M52 75.182c.408 0 .744.322.79.736l.005.097v3.371h3.372c.46 0 .833.357.833.796 0 .408-.322.744-.736.79l-.097.005h-3.372v3.371c0 .46-.356.834-.795.834-.408 0-.744-.322-.79-.736l-.005-.098-.001-3.37h-3.37c-.46 0-.834-.357-.834-.796 0-.408.322-.744.736-.79l.097-.006h3.371v-3.37c0-.461.357-.834.796-.834z" />
  </g>
  </svg>`;
  }
  function checkSvg() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21">
    <path fill-rule="evenodd" d="M17.758 4.293L7.178 14.4l-4.91-5.1a.835.835 0 00-1.202 1.158v-.001l5.488 5.697a.836.836 0 001.176.024l11.18-10.68a.834.834 0 00-1.152-1.205"/>
    </svg>
    `;
  }
  function cardSvg() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
    <path fill="#1D1D1B" fill-rule="evenodd" d="M26.083 6A1.92 1.92 0 0128 7.917V21.42a1.92 1.92 0 01-1.917 1.917H4.917A1.921 1.921 0 013 21.42V7.917A1.92 1.92 0 014.917 6zm.532 8.554H4.385v6.865c0 .289.244.532.532.532h21.166a.539.539 0 00.532-.532v-6.865zm-11.46 2.974a.692.692 0 010 1.385h-1.98a.692.692 0 11.001-1.385zm-8.905 0a.693.693 0 010 1.385h-.349a.692.692 0 110-1.385zm4.452 0a.693.693 0 110 1.385H8.723a.692.692 0 010-1.385zm13.737 0a.692.692 0 110 1.385h-.99a.692.692 0 110-1.385zm-2.613 0a.693.693 0 010 1.385h-1.298a.692.692 0 110-1.385zm-2.923 0a.693.693 0 110 1.385h-.99a.692.692 0 11.001-1.385zm7.712-6.761H4.385v2.401h22.23v-2.401zm-.532-3.382H4.917a.533.533 0 00-.532.532v1.465h22.23V7.917a.533.533 0 00-.532-.532z"/>
    </svg>
    `;
  }
  function calendarSvg() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25">
    <path fill="#020203" fill-rule="evenodd" d="M16.328 15.433h4.988v-4.495h-4.988v4.495zm0 1.1h4.988v4.514c0 .194-.16.352-.353.353h-4.636v-4.867zm4.988-8.998v2.303H4.433V7.535a.43.43 0 01.428-.427H20.89c.235 0 .426.192.427.427zM10.53 21.4h4.699v-4.867H10.53V21.4zm0-5.967h4.699v-4.495H10.53v4.495zM4.79 21.4a.357.357 0 01-.356-.353v-4.514h4.998V21.4H4.788zm-.355-5.967H9.43v-4.495H4.434v4.495zM20.89 6.008h-1.753V3.883a.55.55 0 00-1.1 0v2.125H7.722V3.883a.55.55 0 00-1.1 0v2.125h-1.76c-.843 0-1.528.685-1.528 1.527v13.512c0 .8.653 1.452 1.454 1.453h16.175c.8 0 1.451-.653 1.453-1.453V7.535c0-.842-.685-1.527-1.527-1.527z"/>
    </svg>
    `;
  }
  function trashSvg() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25">
    <path fill="#1D1D1D" fill-rule="evenodd" d="M14.524 3.994c.153 0 .296.058.404.161a.541.541 0 01.164.387v1.296h4.179c.151 0 .293.056.403.16a.539.539 0 01.165.388v2.942a.533.533 0 01-.166.388.577.577 0 01-.402.16h-.638V21.53a.54.54 0 01-.165.388.577.577 0 01-.403.16H6.775a.581.581 0 01-.404-.16.54.54 0 01-.165-.388V9.876h-.637a.577.577 0 01-.402-.16A.529.529 0 015 9.328V6.386c0-.144.06-.285.167-.389a.587.587 0 01.402-.16h4.177V4.543a.54.54 0 01.167-.387.59.59 0 01.403-.161zm2.971 5.882H7.345v11.105h10.15V9.876zm-2.423 1.755a.56.56 0 01.57.55v6.798a.56.56 0 01-.57.548.56.56 0 01-.569-.548V12.18c0-.303.256-.55.57-.55zm-2.687 0c.313 0 .57.246.57.55v6.798a.56.56 0 01-.57.548.56.56 0 01-.57-.548V12.18c0-.303.256-.55.57-.55zm-2.689 0a.56.56 0 01.57.55v6.798a.56.56 0 01-.57.548.56.56 0 01-.569-.548V12.18c0-.303.256-.55.57-.55zm9.005-4.697H6.137v1.845h12.564V6.934zM13.954 5.09h-3.07v.653h3.07V5.09z"/>
    </svg>
    `;
  }

  function alertSvg() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
    <g fill="none" fill-rule="evenodd">
    <circle cx="29.928" cy="29.772" r="21.6" fill="#EEEDED" fill-rule="nonzero"/>
    <g fill-rule="nonzero" transform="translate(38.76 15.84)"><circle cx="1.404" cy="1.404" r="1" fill="#FFF"/>
    <path fill="#9E9C9C" d="M1.404.072C.672.072.084.66.084 1.392c0 .732.588 1.32 1.32 1.32.732 0 1.32-.588 1.32-1.32 0-.732-.588-1.32-1.32-1.32zM.516 1.404c0-.492.396-.888.888-.888s.888.396.888.888-.396.888-.888.888-.888-.396-.888-.888z"/>
    </g>
    <g fill-rule="nonzero" transform="translate(6 47.16)">
    <circle cx="1.368" cy="1.416" r="1" fill="#FFF"/>
    <path fill="#9E9C9C" d="M1.368.096c-.732 0-1.32.588-1.32 1.32 0 .732.588 1.32 1.32 1.32.732 0 1.32-.588 1.32-1.32 0-.732-.588-1.32-1.32-1.32zM.48 1.416c0-.492.396-.888.888-.888s.888.396.888.888-.396.888-.888.888S.48 1.908.48 1.416z"/>
    </g>
    <path fill="#EEEDED" fill-rule="nonzero" d="M45.48 8.82a.3.3 0 00-.384-.144l-.72.336-.336-.72a.3.3 0 00-.384-.144.3.3 0 00-.144.384l.336.72-.72.336a.3.3 0 00-.144.384.288.288 0 00.264.168.426.426 0 00.12-.024l.72-.336.336.72a.288.288 0 00.264.168.426.426 0 00.12-.024.3.3 0 00.144-.384l-.336-.72.72-.336a.3.3 0 00.144-.384z"/>
    <circle cx="15.18" cy="50.34" r="1.188" fill="#EEEDED" fill-rule="nonzero"/>
    <circle cx="8.688" cy="41.94" r="1.188" fill="#EEEDED" fill-rule="nonzero"/>
    <circle cx="50.796" cy="13.104" r="1.188" fill="#EEEDED" fill-rule="nonzero"/>
    <path fill="#9E9C9C" fill-rule="nonzero" d="M48.564 19.452c-.048-.108-.18-.156-.288-.108l-.792.36-.36-.792c-.048-.108-.18-.156-.288-.108-.108.048-.156.18-.108.288l.36.792-.792.36c-.108.048-.156.18-.108.288.036.084.12.132.192.132.036 0 .06-.012.096-.024l.792-.36.36.792c.036.084.12.132.192.132.036 0 .06-.012.096-.024.108-.048.156-.18.108-.288l-.36-.792.792-.36c.108-.048.156-.18.108-.288z"/>
    <path d="M0 0h60v60H0z"/>
    <g fill-rule="nonzero">
    <path fill="#000" d="M13.404 41.664a.282.282 0 000 .276.263.263 0 00.24.132h24.072c.084.144.168.288.264.42H13.644a.681.681 0 01-.6-.348.718.718 0 010-.696l16.284-27.432c.12-.216.348-.336.588-.336s.468.132.588.336L41.568 32.64c-.144.036-.276.084-.408.132l-11.004-18.54c-.048-.084-.132-.132-.228-.132s-.18.048-.228.132L13.404 41.664z"/>
    <path fill="#FFF" d="M41.16 32.772l-11.004-18.54c-.048-.084-.132-.132-.228-.132s-.18.048-.228.132L13.404 41.664a.282.282 0 000 .276.263.263 0 00.24.132h24.072a6.452 6.452 0 01-.852-3.204 6.477 6.477 0 014.296-6.096zM19.404 41.1H16.26a.681.681 0 01-.6-.348.718.718 0 010-.696l2.1-3.54a.214.214 0 01.288-.072c.096.06.132.192.072.288l-.096.156-2.016 3.396a.282.282 0 000 .276.263.263 0 00.24.132h3.144c.12 0 .216.096.216.216s-.096.192-.204.192zm.18-6.84l-.816 1.38a.198.198 0 01-.18.108c-.036 0-.072-.012-.108-.024a.214.214 0 01-.072-.288l.816-1.38a.214.214 0 01.288-.072c.096.048.132.168.072.276zm10.548 4.608c0 .12-.096.216-.216.216s-.216-.096-.216-.216v-1.992c0-.12.096-.216.216-.216s.216.096.216.216v1.992zm0-3.792c0 .12-.096.216-.216.216s-.216-.096-.216-.216V20.244c0-.12.096-.216.216-.216s.216.096.216.216v14.832z"/><path fill="#000" d="M19.608 40.884c0 .12-.096.216-.216.216h-3.144a.681.681 0 01-.6-.348.718.718 0 010-.696l2.1-3.54a.214.214 0 01.288-.072c.096.06.132.192.072.288l-.096.156-2.016 3.396a.282.282 0 000 .276.263.263 0 00.24.132h3.144c.132-.012.228.084.228.192zm-.024-6.624l-.816 1.38a.198.198 0 01-.18.108c-.036 0-.072-.012-.108-.024a.214.214 0 01-.072-.288l.816-1.38a.214.214 0 01.288-.072c.096.048.132.168.072.276zm10.548-14.016v14.832c0 .12-.096.216-.216.216s-.216-.096-.216-.216V20.244c0-.12.096-.216.216-.216s.216.096.216.216zm0 16.632v1.992c0 .12-.096.216-.216.216s-.216-.096-.216-.216v-1.992c0-.12.096-.216.216-.216.12.012.216.096.216.216z"/>
    <path fill="#E41E37" d="M49.404 38.868a6.066 6.066 0 00-6.06-6.06 6.066 6.066 0 00-6.06 6.06 6.066 6.066 0 006.06 6.06 6.066 6.066 0 006.06-6.06zm-7.116 2.784h-1.56a.766.766 0 01-.768-.768V40.8c0-.396.3-.72.684-.756l.024-2.076v-.036a2.745 2.745 0 012.052-2.16v-.096c0-.336.264-.6.6-.6h.06c.336 0 .6.264.6.6v.096c1.08.24 1.848 1.02 2.04 2.064v.036l.024 2.172a.764.764 0 01.672.756v.096c0 .42-.336.768-.768.768H44.4c-.108.516-.54.9-1.068.9-.504 0-.936-.396-1.044-.912z"/>
    <path fill="#E41E37" d="M43.344 42.144c.288 0 .54-.204.636-.48h-1.26c.096.276.336.48.624.48zm2.964-1.248V40.8a.348.348 0 00-.348-.348h-5.244a.348.348 0 00-.348.348v.096c0 .192.156.348.348.348h5.244c.192-.012.348-.168.348-.348zm-.696-3c-.18-.912-.888-1.572-1.872-1.74a.217.217 0 01-.18-.204v-.264a.185.185 0 00-.18-.18h-.06a.185.185 0 00-.18.18v.264a.198.198 0 01-.18.204c-.948.156-1.692.9-1.872 1.836l-.024 2.052h4.56l-.012-2.148z"/>
    <path fill="#040505" d="M43.344 32.388c-.612 0-1.212.084-1.776.252-.144.036-.276.084-.408.132-2.496.9-4.296 3.288-4.296 6.096a6.452 6.452 0 001.116 3.624 6.48 6.48 0 005.364 2.856 6.484 6.484 0 006.48-6.48c0-3.564-2.916-6.48-6.48-6.48zm0 12.54c-3.336 0-6.06-2.724-6.06-6.06a6.066 6.066 0 016.06-6.06 6.066 6.066 0 016.06 6.06 6.066 6.066 0 01-6.06 6.06z"/><path fill="#FCFAFA" d="M41.064 40.032l.024-2.052c.18-.936.936-1.68 1.872-1.836a.217.217 0 00.18-.204v-.264c0-.096.084-.18.18-.18h.06c.096 0 .18.084.18.18v.264c0 .108.072.192.18.204.984.168 1.692.828 1.872 1.74l.024 2.136h.42l-.024-2.172v-.036c-.192-1.044-.972-1.824-2.04-2.064v-.096c0-.336-.264-.6-.6-.6h-.072c-.336 0-.6.264-.6.6v.096a2.746 2.746 0 00-2.052 2.16v.036l-.024 2.076h.42v.012zm3.348 1.62h-.432a.677.677 0 01-.636.48c-.288 0-.54-.204-.636-.48h-.432c.108.516.552.9 1.068.9.528.012.96-.384 1.068-.9z"/>
    <path fill="#FCFAFA" d="M43.98 41.652h1.98c.42 0 .768-.336.768-.768V40.8c0-.396-.3-.708-.672-.756h-5.4a.757.757 0 00-.684.756v.096c0 .42.336.768.768.768h3.24v-.012zm-3.6-.756V40.8c0-.192.156-.348.348-.348h5.232c.192 0 .348.156.348.348v.096a.348.348 0 01-.348.348h-5.244a.363.363 0 01-.336-.348z"/>
    </g>
    </g>
    </svg>
    `;
  }
  function lockSvg() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25">
    <path fill="#1D1D1B" fill-rule="evenodd" d="M12.831 3.333c3.88 0 7.045 3.454 7.178 7.752.734.12 1.297.755 1.297 1.523v8.344a1.55 1.55 0 01-1.55 1.548H5.715a1.549 1.549 0 01-1.547-1.548v-8.344c0-.834.663-1.51 1.488-1.543.142-4.289 3.302-7.732 7.176-7.732zm6.926 8.737H5.714a.538.538 0 00-.537.538v8.344c0 .296.241.537.537.537h14.043c.297 0 .539-.24.539-.537v-8.344a.54.54 0 00-.54-.538zm-6.912 1.984a1.667 1.667 0 011.21 2.811l.4 1.624c.035.152 0 .311-.095.433s-.242.194-.397.194h-2.307a.507.507 0 01-.489-.633l.434-1.661a1.66 1.66 0 01-.42-1.103c0-.918.747-1.665 1.664-1.665zm0 1.01a.656.656 0 00-.654.655c0 .21.1.403.272.527a.508.508 0 01.192.538l-.345 1.32h1.008l-.32-1.3a.507.507 0 01.21-.54.653.653 0 00.293-.545.657.657 0 00-.656-.655zm-.014-9.012c-2.315 0-4.21 2.22-4.34 5.007h8.68c-.13-2.787-2.024-5.007-4.34-5.007zm0-1.725c-3.326 0-6.04 2.994-6.181 6.732h.847c.136-3.335 2.473-6.001 5.334-6.001 2.862 0 5.2 2.666 5.335 6.001h.848c-.142-3.738-2.857-6.732-6.183-6.732z"/>
    </svg>
    `;
  }
  const dispatchGtmAction = (eaction, elabel) => {
    window.dataLayer.push({
      event: 'generic',
      e_category: 'compte',
      e_action: eaction,
      e_label: elabel
    });
  };

  const clickSave = (status) =>
    dispatchGtmAction('ajout moyen de paiement', status);
  const deleteCard = () =>
    dispatchGtmAction('suppression moyen de paiement', 'success');

  document.querySelector('.pl-pay-btn ').innerText = 'Enregistrer';
  const walletBox = document.querySelector('.pl-wallets');
  const walletLayout = document.querySelector('.pl-wallet-layout');
  if (walletBox) {
    walletBox.insertAdjacentHTML(
      'beforeend',
      `<div id="addNewCard"><div>${AddNewCardSvg()}</div><div>Ajouter une carte</div></div>`
    );
  } else {
    walletLayout.insertAdjacentHTML(
      'afterend',
      `<div id="addNewCard"><div>${AddNewCardSvg()}</div><div>Ajouter une carte</div></div>`
    );
  }
  const cardSvgInForm = document.querySelector('.pl-icon-credit-card');
  cardSvgInForm.classList.remove('pl-icon-credit-card');
  cardSvgInForm.innerHTML = `${cardSvg()}`;
  const calendarIcon = document.querySelector('.pl-icon-date');
  calendarIcon.classList.remove('pl-icon-date');
  calendarIcon.innerHTML = `${calendarSvg()}`;
  const lockIcon = document.querySelector('.pl-pmForm .pl-icon-cvv');
  lockIcon.classList.remove('pl-icon-cvv');
  lockIcon.innerHTML = `${lockSvg()}`;

  const allTrashs = document.querySelectorAll('.pl-icon-garbage');
  for (let row = 0; row < allTrashs.length; row++) {
    allTrashs[row].classList.remove('pl-icon-garbage');
    allTrashs[row].classList.add('pl-icon-garbage-new');
    allTrashs[row].innerHTML = `${trashSvg()}`;
  }

  document.querySelector('.pl-layout').classList.add('hideAddCard');
  const addNewCardButton = document.querySelector('#addNewCard');
  const addNewCardBloc = document.querySelector('.pl-layout');
  // eslint-disable-next-line func-names
  addNewCardButton.onclick = function () {
    addNewCardBloc.classList.remove('hideAddCard');
    addNewCardButton.style.display = 'none';
    if (window.innerHeight < addNewCardBloc.offsetTop + 250) {
      const isSmoothScrollSupported =
        'scrollBehavior' in document.documentElement.style;
      if (isSmoothScrollSupported) {
        window.scrollTo({
          top: addNewCardBloc.offsetTop - 250,
          behavior: 'smooth'
        });
      } else {
        window.scrollTo(0, addNewCardBloc.offsetTop - 250);
      }
    }
  };
  const allRows = document.querySelectorAll('.pl-wallet-infos-sub');
  for (let row = 0; row < allRows.length; row++) {
    const tab = allRows[row].innerHTML.split(' - ');
    allRows[
      row
    ].innerHTML = `<div><span class='unbold'>Numéro de carte :</span><span> ${tab[0]}</span></div> <div><span class='unbold'>Date d’expiration :</span><span> ${tab[1]}</span></div>`;
  }

  const walletsTitle = document.querySelector('.pl-wallets-title');
  if (walletsTitle) {
    walletsTitle.parentNode.removeChild(walletsTitle);
  }

  const containerTitle = document.querySelector('.pl-container-title');
  if (containerTitle) {
    containerTitle.parentNode.removeChild(containerTitle);
  }

  const editFavoritesRow = document.querySelectorAll('.pl-walletrow');
  for (let fav = 0; fav < editFavoritesRow.length; fav++) {
    editFavoritesRow[fav].classList.remove('pl-walletrowmng-closed');
  }
  const defaultFavs = document.querySelectorAll('.pl-defaultLabel-container');
  for (let fav = 0; fav < defaultFavs.length; fav++) {
    const elem = defaultFavs[fav];
    if (elem.parentNode) {
      elem.parentNode.removeChild(elem);
    }
  }
  const editFavs = document.querySelectorAll('.pl-mng-lea-btn-container');
  for (let fav = 0; fav < editFavs.length; fav++) {
    const elem = editFavs[fav];
    if (elem.parentNode) {
      elem.parentNode.removeChild(elem);
    }
  }
  const editPencils = document.querySelectorAll('.pl-icon-pencil');
  for (let fav = 0; fav < editPencils.length; fav++) {
    const elem = editPencils[fav];
    if (elem.parentNode) {
      elem.parentNode.removeChild(elem);
    }
  }
  const noTextsForIcons = document.querySelectorAll('.pl-mng-btn-message');
  for (let fav = 0; fav < noTextsForIcons.length; fav++) {
    const elem = noTextsForIcons[fav];
    if (elem.parentNode) {
      elem.parentNode.removeChild(elem);
    }
  }
  const favorate = document.querySelector('.pl-icon-star');
  favorate.classList.add('favoriteIcon');
  const textSuppressionMsg = document.querySelectorAll('.pl-confirm-message');
  for (let fav = 0; fav < textSuppressionMsg.length; fav++) {
    textSuppressionMsg[fav].innerText = 'Confirmer la suppression';
    textSuppressionMsg[fav].insertAdjacentHTML(
      'beforebegin',
      `<div id="deleteSvg">${alertSvg()}<div>`
    );
    textSuppressionMsg[fav].insertAdjacentHTML(
      'afterend',
      '<div id="decriptDelete">Voulez-vous supprimer ce moyen de paiement ?</div>'
    );
  }
  const successMsgDiv = document.querySelector('.pl-message-success');
  if (successMsgDiv) {
    successMsgDiv.insertAdjacentHTML(
      'beforebegin',
      `<div id="checkSvg">${checkSvg()}</div>`
    );
  }
  const saveButton = document.querySelector('.pl-pay-btn ');
  if (saveButton) {
    const status = document.querySelector('.has-error') ? 'error' : 'success';
    saveButton.addEventListener('click', clickSave(status), false);
  }
  const deleteButtons = document.querySelectorAll('.pl-confirm-yes');
  for (let row = 0; row < deleteButtons.length; row++) {
    deleteButtons[row].addEventListener('click', deleteCard, false);
  }
}
export default customAction;
