import React from 'react';
import classnames from 'classnames';

import ProductListModel, { FilterValue } from '@/lib/model/productList';

type StaticHeadingProps = {
  list: ProductListModel;
  headingFilter: FilterValue;
  setHeadingFilter: (f: FilterValue) => void;
};

const StaticHeading = ({
  list,
  headingFilter,
  setHeadingFilter
}: StaticHeadingProps) => {
  return (
    <div className="categoriesNavigation">
      {list?.headings?.length > 1 &&
        list.headings.map((heading) => {
          return (
            <span
              key={`heading-${heading.id}`}
              className={classnames('categoriesNavigation__item', {
                categoriesNavigation__current: headingFilter?.id === heading.id
              })}
              onClick={() => setHeadingFilter(heading)}
            >
              {heading.label}
            </span>
          );
        })}
    </div>
  );
};

export default StaticHeading;
