import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import { AuthStateType } from '@/store/auth/authReducer';
import { NavStateType } from '@/store/nav/navReducer';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { ReactComponent as ListError } from '@/assets/images/icons/list-error.svg';
import Button from '@/components/atoms/button';
import Link from '@/components/atoms/link';
import Modal from '@/components/organisms/modal';
import Product from '@/components/organisms/product';
import { SimpleFullMainNav } from '@/lib/api/categories';
import ProductModel from '@/lib/model/product';
import usePdv from '@/lib/hooks/usePdv';
import { updateCartQuantity } from '@/store/cart/actions/cart';
import { StateType as CartStateType, SubCartType } from '@/store/cart/types';
import useEvent from '@/lib/hooks/useEvent';
import { useRouter } from 'next/router';
import { Meta } from '@/lib/model/productList';

export type ShoppingListProps = {
  products: Array<ProductModel>;
  onDelete: (eans: Array<ProductModel>) => void;
  meta?: Meta;
};

type StateType = {
  productsByCategories: Array<ProductsByCategoryType>;
  selectedEans: Array<string>;
  selectedCategories: Array<string>;
  selectAll: boolean;
  deleteModal: boolean;
};

export type ProductsByCategoryType = SimpleFullMainNav & {
  products?: Array<ProductModel>;
};

const ShoppingList = ({ products, meta, onDelete }: ShoppingListProps) => {
  const { categories, cartStore } = useSelector(
    ({
      nav,
      cart
    }: {
      auth: AuthStateType;
      nav: NavStateType;
      cart: CartStateType;
    }) => {
      return {
        cartStore: cart,
        categories: nav.mainCategories
      };
    },
    shallowEqual
  );
  const dispatch = useDispatch();
  const event = useEvent();
  const router = useRouter();
  const [state, setState] = useState<StateType>({
    productsByCategories: [],
    selectedEans: [],
    selectedCategories: [],
    selectAll: false,
    deleteModal: false
  });

  useEffect(() => {
    (async () => {
      const { ref } = usePdv();
      const pdvMainCategories = categories[ref] ?? null;

      if (!pdvMainCategories) {
        return;
      }

      let productsByCategories: Array<ProductsByCategoryType> = [
        ...pdvMainCategories?.data,
        {
          key: 'other',
          title: t('common.other'),
          picto: null
        }
      ];

      products.forEach((product) => {
        const key =
          productsByCategories.filter(
            (c) => c.key === String(product.universId)
          ).length > 0
            ? String(product.universId)
            : 'other';

        productsByCategories = productsByCategories.map((cat) => {
          const newCat = {
            ...cat
          };

          if (newCat.key === key) {
            newCat.products = [...(newCat?.products ?? []), product];
          }

          return newCat;
        });
      });

      setState((oldState) => ({
        ...oldState,
        productsByCategories: productsByCategories.filter(
          (productByCategory) => !!productByCategory.products?.length
        )
      }));
    })();
  }, [products, categories]);

  const handleSelectAll = () => {
    setState((oldState) => {
      const isChecked = !oldState.selectAll;

      let selectedEans: Array<string> = [];
      let selectedCategories: Array<string> = [];

      if (isChecked) {
        selectedEans = products.map((product) => product.ean);
        selectedCategories = oldState.productsByCategories.map(
          (category) => category.key
        );
      }

      return {
        ...oldState,
        selectAll: isChecked,
        selectedEans,
        selectedCategories
      };
    });
  };

  const reset = () => {
    setState((oldState) => ({
      ...oldState,
      selectedEans: [],
      selectedCategories: [],
      selectAll: false
    }));
  };

  return (
    <div className="shoppingList">
      <div className="shoppingList__header">
        <div className="shoppingList__count">
          <span className="shoppingList__count_label">
            {t('myAccount.lists.count.label')}
          </span>
          &nbsp;
          {t(
            `myAccount.lists.count.${
              products.length === 1 ? 'singular' : 'plural'
            }`,
            {
              '%count%': products.length
            }
          )}
        </div>
        {!!products.length && (
          <div className="shoppingList__actions">
            <Button
              className="myShoppingList__trash"
              color="secondary"
              disabled={!state.selectedEans.length}
              onClick={() => {
                setState((oldState) => ({
                  ...oldState,
                  deleteModal: true
                }));
              }}
            >
              <svg className="fill--black" width="16" height="16">
                <use xlinkHref={`${CommonSprite}#trash`} />
              </svg>
            </Button>
            <Button
              disabled={!state.selectedEans.length}
              onClick={async () => {
                const productsToAdd = products.filter((product) => {
                  return (
                    state.selectedEans.includes(product.ean) && !!product.maxQty
                  );
                });

                const itmCart = cartStore.subCarts.find(
                  (subCart: SubCartType) => subCart.seller.sellerId === 'ITM'
                );

                const items = productsToAdd
                  .filter((product) => !itmCart?.items[product.id])
                  .map((product) => {
                    return { product, quantity: 1, sellerId: 'ITM' };
                  });

                event.send('engagement', {
                  type: 'addUsualProduct',
                  productId: productsToAdd.map(({ id }) => id).join('::')
                });

                event.send('addAllToCart', {
                  products: productsToAdd,
                  path: router.asPath
                });

                try {
                  await dispatch(updateCartQuantity(items));
                } catch (error: any) {
                  // Todo: handle user error display if unable to add products to cart
                }

                reset();
              }}
            >
              {t('myAccount.lists.addToCart')}
            </Button>
          </div>
        )}
      </div>
      {!!products.length && (
        <div className="shoppingList__content">
          <div className="shoppingList__selected">
            {t('myAccount.lists.selected', {
              '%count%': state.selectedEans.length
            })}
            <div className="shoppingList__selectAll">
              <input
                className="shoppingList__checkbox"
                name="ids"
                type="checkbox"
                checked={state.selectAll}
                onChange={() => handleSelectAll()}
              />
              <Link
                onClick={(clickEvent) => {
                  clickEvent.preventDefault();
                  handleSelectAll();
                }}
              >
                {t(`myAccount.lists.${state.selectAll ? 'un' : ''}selectedAll`)}
              </Link>
            </div>
          </div>
          {state.productsByCategories.map(
            (productsByCategory: ProductsByCategoryType, position: number) => {
              const productsByCategoryKey = `frequentList-category-${productsByCategory.key}`;

              if (!productsByCategory.products?.length) return null;

              return (
                <div
                  className="shoppingList__category"
                  key={productsByCategoryKey}
                >
                  <div className="shoppingList__category_name">
                    <input
                      className="shoppingList__checkbox"
                      name="ids"
                      type="checkbox"
                      checked={
                        state.selectedCategories.includes(
                          productsByCategory.key
                        ) &&
                        productsByCategory.products.some(
                          (product) => product.available && !!product.maxQty
                        )
                      }
                      disabled={productsByCategory.products.every(
                        (product) => !product.available || !product.maxQty
                      )}
                      onChange={(evnt) => {
                        const isChecked = evnt.target.checked;

                        setState((oldState) => {
                          let selectedEans = [...oldState.selectedEans];
                          let selectedCategories = [
                            ...oldState.selectedCategories
                          ];

                          const categoryProductEans =
                            productsByCategory.products?.map(
                              (product) => product.ean
                            ) ?? [];

                          if (isChecked) {
                            selectedCategories.push(productsByCategory.key);
                            selectedEans = [
                              ...selectedEans,
                              ...categoryProductEans
                            ];
                          } else {
                            selectedCategories = selectedCategories.filter(
                              (key) => {
                                return key !== productsByCategory.key;
                              }
                            );

                            selectedEans = selectedEans.filter(
                              (selectedEan) =>
                                !categoryProductEans.includes(selectedEan)
                            );
                          }

                          return {
                            ...oldState,
                            selectedCategories,
                            selectedEans,
                            selectAll:
                              selectedCategories.length ===
                              state.productsByCategories.length
                          };
                        });
                      }}
                    />
                    {productsByCategory.picto && (
                      <img
                        className="shoppingList__category_picto"
                        src={productsByCategory.picto}
                        alt=""
                        width={25}
                        height={25}
                      />
                    )}
                    {productsByCategory.title}&nbsp;
                    {t(
                      `myAccount.lists.numberOfArticle${
                        productsByCategory.products.length > 1 ? 's' : ''
                      }`,
                      {
                        '%qty%': productsByCategory.products.length
                      }
                    )}
                  </div>
                  {productsByCategory.products.map((product: ProductModel) => {
                    const productKey = `shoppingList-product-${product.ean}`;

                    return (
                      <div key={productKey} className="shoppingList__product">
                        <input
                          className="shoppingList__checkbox"
                          name="ids"
                          value={product.ean}
                          type="checkbox"
                          checked={
                            state.selectedEans.includes(product.ean) &&
                            product.available &&
                            !!product.maxQty
                          }
                          disabled={!product.available || !product.maxQty}
                          onChange={(evnt) => {
                            const isChecked = evnt.target.checked;

                            setState((oldState) => {
                              let selectedEans = [...oldState.selectedEans];
                              let selectedCategories = [
                                ...oldState.selectedCategories
                              ];

                              if (isChecked) {
                                selectedEans.push(product.ean);

                                const categoryEans = productsByCategory.products?.reduce(
                                  (acc, p) =>
                                    acc && selectedEans.includes(p.ean),
                                  true
                                );

                                if (categoryEans) {
                                  selectedCategories.push(
                                    productsByCategory.key
                                  );
                                }
                              } else {
                                selectedEans = selectedEans.filter((ean) => {
                                  return ean !== product.ean;
                                });
                                selectedCategories = selectedCategories.filter(
                                  (categoryKey) => {
                                    return (
                                      categoryKey !== productsByCategory.key
                                    );
                                  }
                                );
                              }

                              return {
                                ...oldState,
                                selectedEans,
                                selectedCategories,
                                selectAll:
                                  selectedCategories.length ===
                                  state.productsByCategories.length
                              };
                            });
                          }}
                        />
                        <Product
                          data={product}
                          isList={true}
                          listPosition={position}
                          isHorizontalLabel
                          isToReplace={true}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            }
          )}
        </div>
      )}
      {!products.length && (
        <div className="shoppingList__empty_content">
          <div className="shoppingList__empty_container">
            <ListError width={95} height={95} />
            <div className="shoppingList__empty_title">
              {t('myAccount.lists.shoppingList.empty.title')}
            </div>
            <div className="shoppingList__empty_message">
              {t('myAccount.lists.shoppingList.button.find-products-msg')}
            </div>
            <a href="/home" className="shoppingList__empty_button link--button">
              {t('myAccount.lists.shoppingList.button.find-products-btn')}
            </a>
          </div>
        </div>
      )}
      <Modal
        className="shoppingList__modal"
        open={state.deleteModal}
        pageView="RemoveMyFrequentProductsList"
        confirmBtn={{
          label: t('myAccount.lists.deleteModal.success'),
          onClick: () => {
            const productsToDelete = products.filter((product) => {
              return state.selectedEans.includes(product.ean);
            });

            onDelete(productsToDelete);
            reset();

            setState((oldState) => ({
              ...oldState,
              deleteModal: false
            }));
          }
        }}
        cancelBtn={{
          label: t('myAccount.lists.deleteModal.cancel'),
          onClick: () => {
            setState((oldState) => ({
              ...oldState,
              deleteModal: false
            }));
          }
        }}
        onClose={() => {
          setState((oldState) => ({
            ...oldState,
            deleteModal: false
          }));
        }}
        scrollable={false}
      >
        {t('myAccount.lists.deleteModal.message')}
      </Modal>
    </div>
  );
};

export default ShoppingList;
