import React from 'react';
import Product from '@/components/organisms/product';
import classnames from 'classnames';
import ProductModel from '@/lib/model/product';
import { PartenersDatasProps } from '@/lib/thirdPartyApi';
import './style.scss';

export type CriteoData = {
  partnersDatas: PartenersDatasProps;
  productGridCount: number;
};

const CriteoTiles = ({ partnersDatas, productGridCount }: CriteoData) => {
  if (
    !partnersDatas.criteoButterflyProducts1?.length &&
    !partnersDatas.criteoButterflyProducts2?.length
  )
    return null;
  if (partnersDatas.isHighcoExist && productGridCount < 3) return null;
  if (!partnersDatas.isHighcoExist && productGridCount < 4) return null;

  return (
    <>
      {partnersDatas.criteoButterflyProducts1 && (
        <>
          <div
            ref={partnersDatas.tileCriteo1}
            data-regie-id="criteo-custom-tile1"
            className={classnames(
              'productList__grid__item',
              'criteoTile',
              'criteoTile--position-1',
              { criteoTile__HighcoBefore: partnersDatas.isHighcoExist }
            )}
          />
          {partnersDatas.criteoButterflyProducts1.map(
            (product: ProductModel, index: number) => (
              <Product
                key={product.ean}
                className={classnames('productList__grid__item')}
                data={product}
                sellerId="ITM"
                display={index === 0}
              />
            )
          )}
        </>
      )}
      {partnersDatas.criteoButterflyProducts2 && (
        <>
          <div
            ref={partnersDatas.tileCriteo2}
            data-regie-id="criteo-custom-tile2"
            className={classnames(
              'productList__grid__item',
              'criteoTile',
              'criteoTile--position-2',
              { criteoTile__HighcoBefore: partnersDatas.isHighcoExist },
              { criteoTile__LuckyCartBefore: partnersDatas.isLuckyCartExist }
            )}
          />
          {partnersDatas.criteoButterflyProducts2.map(
            (product: ProductModel, index: number) => (
              <Product
                key={product.ean}
                className={classnames('productList__grid__item')}
                data={product}
                sellerId="ITM"
                display={index === 0}
              />
            )
          )}
        </>
      )}
    </>
  );
};

export default CriteoTiles;
