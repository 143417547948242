import React from 'react';
import Date from '@/lib/utils/date';
import t from '@/lib/i18n';

export type OpeningDaysItemProps = {
  dayKey: number;
  startHours: string;
  endHours: string;
};

function OpeningDaysItem({
  dayKey,
  startHours,
  endHours
}: OpeningDaysItemProps) {
  const day = Date().isoWeekday(dayKey + 1);
  const isoDay = day.format('dddd');
  const isToday = day.isToday();

  const className = isToday
    ? `pdvOpeningHours__list--modifier`
    : 'pdvOpeningHours__list';

  return (
    <div key={`pdvOpeningHours__list--${isoDay}`} className={className}>
      <div className="pdvOpeningHours__item">{isoDay}</div>
      {startHours ? (
        <div className="pdvOpeningHours__item__shifted">
          {`${startHours} - ${endHours}`}
        </div>
      ) : (
        <div className="pdvOpeningHours__item__shifted">
          {' '}
          {t('pdv.schedule.closed')}{' '}
        </div>
      )}
    </div>
  );
}

export default OpeningDaysItem;
