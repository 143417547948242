import React from 'react';
import Title from '@/components/atoms/title';
import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import './style.scss';

export type TimelineItemProps = {
  date?: string;
  text?: string;
};

export type TimelineProps = {
  title?: string;
  itemsList: Array<Component>;
};

const Timeline = ({ title, itemsList }: TimelineProps) => {
  return (
    <div className="timeline">
      <Title label={title} className="timeline__title" tag="h2" />
      <div className="timeline__content">
        <div className="timelineEntry__separator" />
        <ContentfulRenderComponents components={itemsList} />
      </div>
    </div>
  );
};

export default Timeline;
