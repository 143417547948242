import * as yup from 'yup';
import t from '@/lib/i18n';

export type FormsTemplateProps = {
  urlSlug: string;
  label: string;
  displayTitle: boolean;
  fields: Array<FieldFormProps>;
};

export type FieldFormProps = {
  label?: string;
  name: string;
  tag?: 'textarea' | 'inputFile' | 'input' | 'ordersType';
  type?: 'text' | 'password' | 'date' | 'tel' | 'store';
  validator?: any;
  ordersList?: Array<OrderTypeProps>;
  maxLength?: number;
};

export type OrderTypeProps = {
  label: string;
  orders: Array<string>;
};

const regexValidPhone = /^\d{10}$/;
const regexValidNumeric = /^[0-9]*$/;
const regexValidOrder = /^[A-Z0-9-]*$/;

const emailValidator = () =>
  yup
    .string()
    .email('form.error.invalid.email')
    .required('form.error.required');

const phoneValidator = () =>
  yup
    .string()
    .required('form.error.required')
    .test('len', 'form.error.invalid.phone', (val) =>
      !val ? true : !!val?.match(regexValidPhone)
    );

const mendatoryNumberFieldValidator = () =>
  yup
    .string()
    .required('form.error.required')
    .test(
      'num',
      'form.error.numberRequired',
      (val) => !!val?.match(regexValidNumeric)
    );

const mandatoryStringFieldValidator = () =>
  yup.string().required(t('form.error.required'));

const mendatoryOrderFieldValidator = () =>
  yup
    .string()
    .required('form.error.required')
    .test(
      'num',
      'form.error.numberOrderRequired',
      (val) => !!val?.match(regexValidOrder)
    );

const MY_INTERMARCHE_ACCOUNT: FormsTemplateProps = {
  urlSlug: 'Mon-compte-Intermarche',
  label: 'formContactUs.formName.myIntermarcheAccount',
  displayTitle: false,
  fields: [
    {
      label: 'formContactUs.label.name',
      name: 'lastName',
      type: 'text',
      tag: 'input'
    },
    {
      label: 'formContactUs.label.firstName',
      name: 'firstName',
      type: 'text',
      tag: 'input'
    },
    {
      label: 'formContactUs.label.email',
      name: 'email',
      type: 'text',
      tag: 'input',
      validator: emailValidator()
    },
    {
      label: 'formContactUs.label.phoneNumber',
      name: 'phone',
      type: 'text',
      tag: 'input',
      maxLength: 10
    },
    {
      label: 'formContactUs.label.cartao',
      name: 'cartao',
      type: 'text',
      tag: 'input'
    },
    {
      label: 'formContactUs.label.cityShop',
      name: 'city',
      type: 'store',
      tag: 'input'
    },
    {
      name: 'ordersType',
      tag: 'ordersType',
      validator: mandatoryStringFieldValidator(),
      ordersList: [
        {
          label: 'formContactUs.label.info',
          orders: [
            'formContactUs.order.accountPersonalData',
            'formContactUs.order.savingsCard',
            'formContactUs.order.orders',
            'formContactUs.order.promotionsOffers',
            'formContactUs.order.storeInformation',
            'formContactUs.order.otherTopic'
          ]
        }
      ]
    },
    {
      label: 'formContactUs.label.joinFile',
      name: 'files',
      tag: 'inputFile'
    },
    {
      label: 'formContactUs.label.explainOrder',
      name: 'orderDetail',
      tag: 'textarea',
      validator: mandatoryStringFieldValidator()
    }
  ]
};

const MY_WEB_SHOPPING: FormsTemplateProps = {
  urlSlug: 'Mes-courses-en-ligne',
  label: 'formContactUs.formName.myWebShopping',
  displayTitle: true,
  fields: [
    {
      label: 'formContactUs.label.name',
      name: 'lastName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.firstName',
      name: 'firstName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.email',
      name: 'email',
      type: 'text',
      tag: 'input',
      validator: emailValidator()
    },
    {
      label: 'formContactUs.label.phoneNumber',
      name: 'phone',
      type: 'text',
      tag: 'input',
      validator: phoneValidator(),
      maxLength: 10
    },
    {
      label: 'formContactUs.label.cityShop',
      name: 'city',
      type: 'text',
      tag: 'input'
    },
    {
      name: 'ordersType',
      tag: 'ordersType',
      validator: mandatoryStringFieldValidator(),
      ordersList: [
        {
          label: 'formContactUs.label.info',
          orders: [
            'formContactUs.order.aboutMyOrder',
            'formContactUs.order.aboutMyAccount',
            'formContactUs.order.aboutMyDelivery',
            'formContactUs.order.aboutMyProducts',
            'formContactUs.order.aboutTimeSlotsDelivery',
            'formContactUs.order.aboutMyInvoice',
            'formContactUs.order.paymentMode',
            'formContactUs.order.myFidelityAdvantages',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.claims',
          orders: [
            'formContactUs.order.aboutMyDiscountPurchaseVoucher',
            'formContactUs.order.aboutMyProducts',
            'formContactUs.order.aboutMyOrder',
            'formContactUs.order.aboutMyInvoice',
            'formContactUs.order.aboutMyDelivery',
            'formContactUs.order.aboutmyFidelityAdvantages',
            'formContactUs.order.technicalsIssues',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.suggestion',
          orders: [
            'formContactUs.order.aboutWebSite',
            'formContactUs.order.aboutProductOffer',
            'formContactUs.order.aboutMobileApps',
            'formContactUs.order.other'
          ]
        }
      ]
    },
    {
      label: 'formContactUs.label.joinFile',
      name: 'files',
      tag: 'inputFile'
    },
    {
      label: 'formContactUs.label.explainOrder',
      name: 'orderDetail',
      tag: 'textarea',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.yourOrderNumber',
      name: 'orderNumber',
      tag: 'input',
      type: 'text',
      validator: mendatoryOrderFieldValidator()
    }
  ]
};

const MY_FIDELITY_CARD: FormsTemplateProps = {
  urlSlug: 'Ma-Carte-de-Fidelite',
  label: 'formContactUs.formName.myFidelityCard',
  displayTitle: true,
  fields: [
    {
      label: 'formContactUs.label.name',
      name: 'lastName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.firstName',
      name: 'firstName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.email',
      name: 'email',
      type: 'text',
      tag: 'input',
      validator: emailValidator()
    },
    {
      label: 'formContactUs.label.phoneNumber',
      name: 'phone',
      type: 'text',
      tag: 'input',
      validator: phoneValidator(),
      maxLength: 10
    },
    {
      name: 'ordersType',
      tag: 'ordersType',
      validator: mandatoryStringFieldValidator(),
      ordersList: [
        {
          label: 'formContactUs.label.info',
          orders: [
            'formContactUs.order.cardOperation',
            'formContactUs.order.fidelityProgram',
            'formContactUs.order.communities',
            'formContactUs.order.myBalance',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.claims',
          orders: [
            'formContactUs.order.registration',
            'formContactUs.order.myAdvantages',
            'formContactUs.order.loss/robbery',
            'formContactUs.order.cardResiliation',
            'formContactUs.order.myPersonalDatas',
            'formContactUs.order.aboutCommunities',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.suggestion',
          orders: [
            'formContactUs.order.aboutProgram',
            'formContactUs.order.aboutCommunities',
            'formContactUs.order.other'
          ]
        }
      ]
    },
    {
      label: 'formContactUs.label.joinFile',
      name: 'files',
      tag: 'inputFile'
    },
    {
      label: 'formContactUs.label.explainOrder',
      name: 'orderDetail',
      tag: 'textarea',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.yourFidelityCardNumber',
      name: 'cardFidNumber',
      tag: 'input',
      type: 'text',
      validator: mendatoryNumberFieldValidator()
    }
  ]
};

const SALES_OPERATIONS: FormsTemplateProps = {
  urlSlug: 'Operations-commerciales',
  label: 'formContactUs.formName.salesOperations',
  displayTitle: true,
  fields: [
    {
      label: 'formContactUs.label.name',
      name: 'lastName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.firstName',
      name: 'firstName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.email',
      name: 'email',
      type: 'text',
      tag: 'input',
      validator: emailValidator()
    },
    {
      label: 'formContactUs.label.phoneNumber',
      name: 'phone',
      type: 'text',
      tag: 'input',
      validator: phoneValidator(),
      maxLength: 10
    },
    {
      label: 'formContactUs.label.cityShop',
      name: 'city',
      type: 'text',
      tag: 'input'
    },
    {
      name: 'ordersType',
      tag: 'ordersType',
      validator: mandatoryStringFieldValidator(),
      ordersList: [
        {
          label: 'formContactUs.label.info',
          orders: [
            'formContactUs.order.shopOperation',
            'formContactUs.order.driveOperation',
            'formContactUs.order.thumbnailOperation',
            'formContactUs.order.gameContestOperation'
          ]
        }
      ]
    },
    {
      label: 'formContactUs.label.joinFile',
      name: 'files',
      tag: 'inputFile'
    },
    {
      label: 'formContactUs.label.explainOrder',
      name: 'orderDetail',
      tag: 'textarea',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.operationName',
      name: 'operationName',
      tag: 'input',
      type: 'text',
      validator: mandatoryStringFieldValidator()
    }
  ]
};

const MY_STORE: FormsTemplateProps = {
  urlSlug: 'Mon-Magasin',
  label: 'formContactUs.formName.myStore',
  displayTitle: true,
  fields: [
    {
      label: 'formContactUs.label.name',
      name: 'lastName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.firstName',
      name: 'firstName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.email',
      name: 'email',
      type: 'text',
      tag: 'input',
      validator: emailValidator()
    },
    {
      label: 'formContactUs.label.phoneNumber',
      name: 'phone',
      type: 'text',
      tag: 'input',
      validator: phoneValidator(),
      maxLength: 10
    },
    {
      label: 'formContactUs.label.cityShop',
      name: 'city',
      type: 'text',
      tag: 'input'
    },
    {
      name: 'ordersType',
      tag: 'ordersType',
      validator: mandatoryStringFieldValidator(),
      ordersList: [
        {
          label: 'formContactUs.label.info',
          orders: [
            'formContactUs.order.aboutYourShop',
            'formContactUs.order.aboutIntermarche',
            'formContactUs.order.flyerRetailing',
            'formContactUs.order.others'
          ]
        },
        {
          label: 'formContactUs.label.claims',
          orders: [
            'formContactUs.order.cleanliness',
            'formContactUs.order.promotionalOffers/prices',
            'formContactUs.order.aboutMyShop',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.suggestion',
          orders: [
            'formContactUs.order.aboutAProduct',
            'formContactUs.order.aboutMyShop',
            'formContactUs.order.aboutPromotion',
            'formContactUs.order.other'
          ]
        }
      ]
    },
    {
      label: 'formContactUs.label.joinFile',
      name: 'files',
      tag: 'inputFile'
    },
    {
      label: 'formContactUs.label.explainOrder',
      name: 'orderDetail',
      tag: 'textarea',
      validator: mandatoryStringFieldValidator()
    }
  ]
};

const PARTNERS_OFFERS: FormsTemplateProps = {
  urlSlug: 'Les-offres-de-nos-partenaires',
  label: 'formContactUs.formName.partnersOffers',
  displayTitle: true,
  fields: [
    {
      label: 'formContactUs.label.name',
      name: 'lastName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.firstName',
      name: 'firstName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.email',
      name: 'email',
      type: 'text',
      tag: 'input',
      validator: emailValidator()
    },
    {
      label: 'formContactUs.label.phoneNumber',
      name: 'phone',
      type: 'text',
      tag: 'input',
      validator: phoneValidator(),
      maxLength: 10
    },
    {
      label: 'formContactUs.label.cityShop',
      name: 'city',
      type: 'text',
      tag: 'input'
    },
    {
      name: 'ordersType',
      tag: 'ordersType',
      validator: mandatoryStringFieldValidator(),
      ordersList: [
        {
          label: 'formContactUs.label.info',
          orders: [
            'formContactUs.order.aboutMyOrder',
            'formContactUs.order.aboutMyAccount',
            'formContactUs.order.aboutMyDelivery',
            'formContactUs.order.aboutMyProducts',
            'formContactUs.order.aboutTimeSlotsDelivery',
            'formContactUs.order.aboutMyInvoice',
            'formContactUs.order.paymentMode',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.claims',
          orders: [
            'formContactUs.order.aboutMyProducts',
            'formContactUs.order.aboutMyOrder',
            'formContactUs.order.aboutMyInvoice',
            'formContactUs.order.aboutMyDelivery',
            'formContactUs.order.technicalsIssues',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.suggestion',
          orders: [
            'formContactUs.order.aboutWebSite',
            'formContactUs.order.aboutProductOffer',
            'formContactUs.order.aboutMobileApps',
            'formContactUs.order.other'
          ]
        }
      ]
    },
    {
      label: 'formContactUs.label.joinFile',
      name: 'files',
      tag: 'inputFile'
    },
    {
      label: 'formContactUs.label.explainOrder',
      name: 'orderDetail',
      tag: 'textarea',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.yourOrderNumber',
      name: 'orderNumber',
      tag: 'input',
      type: 'text',
      validator: mendatoryOrderFieldValidator()
    }
  ]
};

const CUSTOMERS_SERVICE: FormsTemplateProps = {
  urlSlug: 'Le-service-Consommateurs',
  label: 'formContactUs.formName.customersService',
  displayTitle: true,
  fields: [
    {
      label: 'formContactUs.label.name',
      name: 'lastName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.firstName',
      name: 'firstName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.email',
      name: 'email',
      type: 'text',
      tag: 'input',
      validator: emailValidator()
    },
    {
      label: 'formContactUs.label.phoneNumber',
      name: 'phone',
      type: 'text',
      tag: 'input',
      validator: phoneValidator(),
      maxLength: 10
    },
    {
      label: 'formContactUs.label.postCode',
      name: 'postCode',
      tag: 'input',
      type: 'text'
    },
    {
      label: 'formContactUs.label.cityShop',
      name: 'city',
      type: 'text',
      tag: 'input'
    },
    {
      name: 'ordersType',
      tag: 'ordersType',
      validator: mandatoryStringFieldValidator(),
      ordersList: [
        {
          label: 'formContactUs.label.info',
          orders: [
            'formContactUs.order.aboutaproduct',
            'formContactUs.order.searchAProduct',
            'formContactUs.order.priceOfAProduct',
            'formContactUs.order.aboutIntermarche',
            'formContactUs.order.aboutAFlyer',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.claims',
          orders: [
            'formContactUs.order.aboutQualityOfAProduct',
            'formContactUs.order.flyerProductUnvailable',
            'formContactUs.order.SAV',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.suggestion',
          orders: [
            'formContactUs.order.aboutAProduct',
            'formContactUs.order.aboutAShop',
            'formContactUs.order.aboutAPromotion',
            'formContactUs.order.other'
          ]
        }
      ]
    },
    {
      label: 'formContactUs.label.joinFile',
      name: 'files',
      tag: 'inputFile'
    },
    {
      label: 'formContactUs.label.explainOrder',
      name: 'orderDetail',
      tag: 'textarea',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.productAndBrandName',
      name: 'productAndBrandName',
      tag: 'input',
      type: 'text'
    },
    {
      label: 'formContactUs.label.barCodeOfProduct',
      name: 'barCodeOfProduct',
      tag: 'input',
      type: 'text'
    },
    {
      label: 'formContactUs.label.batchNumber',
      name: 'batchNumber',
      tag: 'input',
      type: 'text'
    },
    {
      label: 'formContactUs.label.expirationDate',
      name: 'expirationDate',
      type: 'date',
      tag: 'input'
    }
  ]
};

const VEHICULE_RENTING: FormsTemplateProps = {
  urlSlug: 'Location-de-vehicule',
  label: 'formContactUs.formName.vehiculeRenting',
  displayTitle: true,
  fields: [
    {
      label: 'formContactUs.label.name',
      name: 'lastName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.firstName',
      name: 'firstName',
      type: 'text',
      tag: 'input',
      validator: mandatoryStringFieldValidator()
    },
    {
      label: 'formContactUs.label.email',
      name: 'email',
      type: 'text',
      tag: 'input',
      validator: emailValidator()
    },
    {
      label: 'formContactUs.label.phoneNumber',
      name: 'phone',
      type: 'text',
      tag: 'input',
      validator: phoneValidator(),
      maxLength: 10
    },
    {
      label: 'formContactUs.label.cityShop',
      name: 'city',
      type: 'text',
      tag: 'input'
    },
    {
      name: 'ordersType',
      tag: 'ordersType',
      validator: mandatoryStringFieldValidator(),
      ordersList: [
        {
          label: 'formContactUs.label.info',
          orders: [
            'formContactUs.order.contributorShops',
            'formContactUs.order.schedules',
            'formContactUs.order.vehiculeScale',
            'formContactUs.order.rates',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.claims',
          orders: [
            'formContactUs.order.aboutARent',
            'formContactUs.order.aboutARate',
            'formContactUs.order.aboutAReturn',
            'formContactUs.order.other'
          ]
        },
        {
          label: 'formContactUs.label.suggestion',
          orders: [
            'formContactUs.order.aboutService',
            'formContactUs.order.aboutVehiculeScale',
            'formContactUs.order.aboutRates',
            'formContactUs.order.other'
          ]
        }
      ]
    },
    {
      label: 'formContactUs.label.joinFile',
      name: 'files',
      tag: 'inputFile'
    },
    {
      label: 'formContactUs.label.explainOrder',
      name: 'orderDetail',
      tag: 'textarea',
      validator: mandatoryStringFieldValidator()
    }
  ]
};

const formsTemplate: Array<FormsTemplateProps> = [
  MY_INTERMARCHE_ACCOUNT,
  MY_WEB_SHOPPING,
  MY_FIDELITY_CARD,
  SALES_OPERATIONS,
  MY_STORE,
  PARTNERS_OFFERS,
  CUSTOMERS_SERVICE,
  VEHICULE_RENTING
];

export default formsTemplate;
