import t from '@/lib/i18n';
import { ExceptionDates } from '@/lib/model/pdv';
import Date from '@/lib/utils/date';
import { Dayjs } from 'dayjs';

import React from 'react';

const ExceptionDatesCard = ({ opening, closure }: ExceptionDates) => {
  const formatDate = (date: Dayjs) => {
    const now = Date();
    const startDate = Date(date);
    return startDate.year() > now.year()
      ? startDate.format('dddd DD MMMM YYYY')
      : startDate.format('dddd DD MMMM');
  };

  return (
    <div className="exceptionDateCard">
      {closure && closure.length > 0 && (
        <div className="exceptionDayType">
          <div className="title">
            <div className="title__closure">
              {t('pdv.exceptionClosure.message')}
            </div>
          </div>
          {closure.map(({ startDate }) => (
            <div
              key={`date--${startDate}`}
              dangerouslySetInnerHTML={{
                __html: t('pdv.exceptionClosure.control', {
                  '%date%': startDate && formatDate(startDate)
                })
              }}
            />
          ))}
        </div>
      )}
      {opening && opening.length > 0 && (
        <div className="exceptionDayType">
          <div className="title">
            <div className="title__opening">
              {t('pdv.exceptionOpening.message')}
            </div>
          </div>
          {opening.map(({ startDate, begin, end }: any) => (
            <div
              key={`date--${startDate}`}
              dangerouslySetInnerHTML={{
                __html: t('pdv.exceptionOpening.control', {
                  '%date%': Date(startDate).format('dddd DD MMMM'),
                  '%beginH%': begin.hour,
                  '%beginM%': begin.minute,
                  '%endH%': end.hour,
                  '%endM%': end.minute
                })
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ExceptionDatesCard;
