import { ReactComponent as CartSvg } from '@/assets/images/icons/cart.svg';
import CartSprite from '@/assets/images/sprites/cart.svg';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Button from '@/components/atoms/button';
import Dropdown from '@/components/atoms/dropdown';
import Checkbox from '@/components/atoms/formCheckbox';
import Link from '@/components/atoms/link';
import LoadableContent from '@/components/atoms/loadableContent';
import Discount from '@/components/organisms/checkout/discount';
import Errors from '@/components/organisms/checkout/errors';
import CartHelp from '@/components/organisms/checkout/help';
import BottomFooter from '@/components/organisms/checkout/sideBarSummary/bottomFooter';
import Modal from '@/components/organisms/modal';
import { SimpleFullMainNav } from '@/lib/api/categories';
import slotsApi from '@/lib/api/slots';
import UserInformationApi from '@/lib/api/userInformation';
import getConfigValue from '@/lib/config';
import useEvent from '@/lib/hooks/useEvent';
import usePageView from '@/lib/hooks/usePageView';
import usePdv, { getPdvRef } from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import Product from '@/lib/model/product';
import { persistor } from '@/store';
import { AuthStateType } from '@/store/auth/authReducer';
import {
  deleteCart,
  getCart,
  updateAuthorizeCart,
  UpdateCartQtyElt,
  updateCartQuantity
} from '@/store/cart/actions/cart';
import {
  ADD_SLOTS,
  RESET_SLOTS,
  TOGGLE_MINICART_OPENED
} from '@/store/cart/cartActions';
import {
  CartItemType,
  CartType,
  PartialItem,
  StateType as CartStateType
} from '@/store/cart/types';
import { NavStateType } from '@/store/nav/navReducer';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect, useIsomorphicLayoutEffect } from 'react-use';
import shallowEqual from 'recompose/shallowEqual';
import getRecommendationProducts from '../../recommendation/getRecommendationProducts';
import RecommendationProductPopin from '../../recommendation/popin';
import BlockSubstitution from './blockSubstitution';
import GamesContest from './gamesContest';
import CartModal from './modal';
import ProductByCategorie, {
  ProductByCategorieType
} from './productByCategorie';
import './style.scss';

export type CartProps = {
  isCheckout?: boolean;
  redirectOnProductClick?: boolean;
};

export type ProductsCategoriesProps = {
  items: {
    [key: string]: CartItemType;
  };
  categories: Array<SimpleFullMainNav> | null;
  isCheckout: boolean;
};

type FullCategories = {
  [key: string]: Array<ProductByCategorieType>;
} | null;

const Cart = ({ isCheckout = false, redirectOnProductClick }: CartProps) => {
  const dispatch = useDispatch();
  const {
    cartStore,
    cartStoreHydrated,
    categories,
    categoriesHydrated,
    user
  } = useSelector(
    ({
      cart,
      nav,
      auth
    }: {
      cart: CartStateType;
      nav: NavStateType;
      auth: AuthStateType;
    }) => {
      return {
        cartStore: cart,
        cartStoreHydrated: cart.isHydrated,
        categories: nav.mainCategories,
        categoriesHydrated: nav.isHydrated,
        user: auth.user
      };
    },
    shallowEqual
  );

  const [state, setState] = useState<{
    allowSubstituable: boolean;
    totalDiscount: string;
    modal: {
      isActive: boolean;
      title: string | null;
      text: string | null;
      button: string | null;
    };
    openModal: boolean;
    isLoading: boolean;
    isSlotsLoading: boolean;
    isDataLoading: boolean;
    layerConfirm: boolean;
    layerConfirmSellerId: string;
    layerConfirmSellerName: string;
    outOfStock: Array<CartItemType> | null;
    unavailblePromotion: Array<CartItemType> | null;
    unavailbleProducts: Array<CartItemType> | null;
    partialStocks409: Array<PartialItem> | null;
    unavailbleCategory: {
      [key: string]: ProductByCategorieType | null;
    } | null;
    fullCategories: FullCategories;
    handle409: boolean;
    productsSubstituable: number | null;
    userProductsSubstituable: number;
    slotsRetrieved?: boolean;
    showRecoProducts: boolean;
    recoProducts: Product[];
    clearUnavailables?: boolean;
  }>({
    allowSubstituable: false,
    modal: {
      isActive: false,
      title: null,
      text: null,
      button: null
    },
    totalDiscount: '0',
    openModal: false,
    isLoading: false,
    isSlotsLoading: false,
    isDataLoading: true,
    layerConfirm: false,
    layerConfirmSellerId: '',
    layerConfirmSellerName: '',
    outOfStock: null,
    unavailblePromotion: null,
    unavailbleCategory: null,
    unavailbleProducts: null,
    partialStocks409: null,
    fullCategories: null,
    handle409: false,
    productsSubstituable: null,
    userProductsSubstituable: 0,
    showRecoProducts: false,
    recoProducts: [],
    clearUnavailables: false
  });

  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(!cartStoreHydrated || !cartStore.total);
  }, [cartStoreHydrated, cartStore.total]);

  const isMounted = useRef(true);
  const pageView = usePageView();
  const event = useEvent();
  const router = useRouter();

  const itmCart =
    cartStore.subCarts.find((subCart) => subCart.seller.sellerId === 'ITM') ||
    null;

  useEffect(() => {
    if (!cartStoreHydrated) {
      return;
    }
    const productsSubstituable =
      (itmCart?.items && Object.values(itmCart?.items).length) || 0;
    const userProductsSubstituable =
      (itmCart &&
        Object.values(itmCart?.items).filter(
          (item: CartItemType) =>
            item.acceptSubstitution === true &&
            item.product.maxQty !== 0 &&
            item.product.available
        ).length) ||
      0;

    setState((oldState) => ({
      ...oldState,
      productsSubstituable,
      userProductsSubstituable
    }));
  }, [cartStore, itmCart, cartStoreHydrated]);

  useEffect(() => {
    if (!cartStoreHydrated) {
      return;
    }
    const products: CartItemType[] = [];
    cartStore.subCarts.forEach((subCart) => {
      const productsTemp = Object.values(subCart?.items || {})?.map(
        (item) => item
      );
      products.push(...productsTemp);
    });
    !!products.length &&
      isCheckout &&
      event.send('checkoutEcommerce', {
        step: '1',
        items: products,
        path: router.asPath
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckout, cartStoreHydrated]);

  useIsomorphicLayoutEffect(() => {
    if (cartStoreHydrated) {
      if (
        isMounted.current &&
        (!!Object.keys(cartStore.slots.list).length ||
          !!Object.keys(cartStore.mkpPackages || {}).length)
      ) {
        dispatch({ type: RESET_SLOTS });
      }

      isMounted.current = false;
    }
  }, [dispatch, cartStoreHydrated, cartStore.slots.list, isMounted]);

  useEffect(() => {
    if (state.layerConfirm) {
      const { ref } = usePdv();
      pageView.send(isCheckout ? 'ClearingCart' : 'ClearingMiniCart', {
        pdvRef: ref
      });
    }
  }, [pageView, state.layerConfirm, isCheckout]);

  const clearUnavailableAndPartialAllSeller = useCallback(
    async (
      unavailbleProducts,
      outOfStock,
      partialStocks409,
      cartStoreParam
    ): Promise<unknown | CartType | null> => {
      if (!unavailbleProducts?.length && !outOfStock?.length) {
        return null;
      }
      let updateArray: Array<UpdateCartQtyElt> = [];
      cartStoreParam.subCarts.forEach((subCart: any) => {
        const updateArraySubCart = unavailbleProducts.map(
          (item: { product: any }) => ({
            product: item.product,
            quantity: 0,
            sellerId: subCart.seller.sellerId
          })
        );

        updateArray = [...updateArray, ...updateArraySubCart];
        const updatePartialStocks = outOfStock
          ?.map(({ product }: any) => {
            const partialProduct = partialStocks409.find(
              (part: PartialItem) =>
                part.itemId === product.id &&
                part.sellerId === subCart.seller.sellerId
            );
            if (partialProduct) {
              return {
                product,
                quantity: partialProduct?.availableStock || 0,
                sellerId: subCart.seller.sellerId
              };
            }
            return null;
          })
          ?.filter((item: any) => !!item);
        if (updatePartialStocks?.length) {
          updateArray = [...updateArray, ...updatePartialStocks];
        }
      });
      if (!updateArray?.length) return null;
      let newCart: unknown | CartType | null;

      try {
        newCart = (await dispatch(updateCartQuantity(updateArray, true))) as
          | unknown
          | CartType
          | null;
      } catch (error: any) {
        throw new Error(error);
      }

      return newCart;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const clearUnavailableBySeller = useCallback(
    async (
      category: ProductByCategorieType,
      sellerId: string
    ): Promise<unknown | CartType | null> => {
      if (!category.products) return null;

      const updateArray: Array<UpdateCartQtyElt> = category.products.map(
        (item) => ({
          product: item.product,
          quantity: 0,
          sellerId
        })
      );
      if (!updateArray?.length) return null;
      let newCart: unknown | CartType | null;

      try {
        newCart = (await dispatch(updateCartQuantity(updateArray, true))) as
          | unknown
          | CartType
          | null;

        setState((oldState) => ({
          ...oldState,
          unavailbleCategory: null,
          unavailbleProducts: oldState.unavailbleProducts
            ? oldState.unavailbleProducts.filter((unavailable) =>
                updateArray
                  .map((pr) => pr.product.id)
                  .includes(unavailable.product.id)
              )
            : null
        }));
      } catch (error: any) {
        throw new Error(error);
      }

      return newCart;
    },
    [dispatch]
  );

  useDeepCompareEffect(() => {
    if (state.slotsRetrieved) {
      setState((oldState) => ({
        ...oldState,
        slotsRetrieved: false
      }));
      let url = '/orders/cart';
      if (isCheckout && Object.keys(cartStore.slots.list).length) {
        url = '/orders/slots';
      } else if (
        cartStore?.mkpPackages &&
        Object.keys(cartStore?.mkpPackages).length
      ) {
        url = '/orders/delivery';
      }
      router.push(url);
    }
  }, [state.slotsRetrieved, isCheckout, cartStore.slots.list]);

  const getSlots = useCallback(
    async (cart) => {
      const pdvRef = getPdvRef();

      try {
        const {
          data,
          vouchersCodeActive,
          discountCodeActive,
          mkpPackages,
          metaData,
          defaultPayments
        } = await slotsApi.getAll(cart, pdvRef, user?.id);

        dispatch({
          type: ADD_SLOTS,
          payload: {
            data,
            vouchersCodeActive,
            discountCodeActive,
            mkpPackages,
            metaData,
            defaultPayments
          }
        });
        setState((oldState) => ({
          ...oldState,
          slotsRetrieved: true
        }));
      } catch (error: any) {
        if (error.response?.status === 409) {
          const { metaData } = error.response.data || {};
          if (
            metaData?.unsynchronized?.clientItems?.length ||
            metaData?.unsynchronized?.serverItems?.length ||
            metaData?.unsynchronized?.clientPrice ||
            metaData?.unsynchronized?.serverPrice
          ) {
            setState((s) => ({
              ...s,
              modal: {
                isActive: true,
                title: t('cart.error.basket.title'),
                text: t('cart.error.basket.text'),
                button: t('cart.error.basket.button')
              },
              isSlotsLoading: false,
              isLoading: false
            }));
          } else if (
            metaData?.invalid?.stock?.length ||
            metaData?.invalid?.indisponible?.length ||
            metaData?.invalid?.avantage?.length ||
            metaData?.invalid?.stocks?.length ||
            metaData?.invalid?.unavailables?.length ||
            metaData?.invalid?.advantages?.length ||
            metaData?.invalid?.partialStocks?.length
          ) {
            const getDeleveriesItems409 = (
              type: 'advantages' | 'stocks' | 'unavailables' | 'partialStocks'
            ) => {
              const products: Array<CartItemType> = [];
              cartStore.subCarts.forEach((subCart) => {
                metaData.invalid?.[type]?.forEach(
                  (metaItem: { itemId: string }) => {
                    const product = Object.values(subCart?.items || {})?.find(
                      (item) => item.product.id === metaItem.itemId
                    );
                    if (product) {
                      products.push(product);
                    }
                  }
                );
              });
              return products;
            };
            const getPartialItems409 = (type: 'partialStocks') => {
              const products409: Array<any> = [];
              metaData.invalid?.[type]?.forEach((metaItem: PartialItem) => {
                products409.push(metaItem);
              });
              return products409;
            };
            const unavailblePromotion = getDeleveriesItems409('advantages');
            const outOfStock = getDeleveriesItems409('partialStocks');
            const partialStocks409 = getPartialItems409('partialStocks');
            const indispo = getDeleveriesItems409('unavailables');
            const stock = getDeleveriesItems409('stocks');

            const unavailbleProducts =
              indispo || stock ? [...indispo, ...stock] : null;
            setState((oldState) => ({
              ...oldState,
              unavailblePromotion,
              outOfStock,
              unavailbleProducts,
              partialStocks409,
              handle409: true,
              clearUnavailables: true,
              isLoading: false
            }));
          } else if (
            metaData.invalid?.volume?.length ||
            metaData.invalid?.deliveryMethods?.length ||
            metaData.invalid?.services?.length ||
            metaData.invalid?.salesDepartments?.length
          ) {
            const {
              data,
              vouchersCodeActive,
              discountCodeActive,
              mkpPackages,
              metaData: metaDataTransformed,
              defaultPayments
            } = error.errorTransform;
            dispatch({
              type: ADD_SLOTS,
              payload: {
                data,
                vouchersCodeActive,
                discountCodeActive,
                mkpPackages,
                metaData: metaDataTransformed,
                defaultPayments
              }
            });
            setState((oldState) => ({
              ...oldState,
              slotsRetrieved: true
            }));
          }
        } else {
          const { modalType, dataText, dataTitle } = Errors(
            error?.response?.data?.errors?.[0]?.code,
            error?.response?.data?.errors?.[0]?.message
          );
          setState((s) => ({
            ...s,
            modal: {
              isActive: true,
              title: t(`cart.error.${modalType}.title`, {
                '%data%': dataTitle
              }),
              text: t(`cart.error.${modalType}.text`, { '%data%': dataText }),
              button: t(`cart.error.${modalType}.button`)
            },
            isLoading: false
          }));
        }
      }
    },
    [cartStore.subCarts, dispatch, user?.id]
  );

  const isThereMkp = cartStore.subCarts.some(
    (subCart) => subCart.type === 'MKP'
  );

  const isTherePDV = cartStore.subCarts.find((s) => s.type === 'PDV');

  const isOnlyMkpCart = isThereMkp && !isTherePDV;

  const isEmerchEnabled = getConfigValue(
    'IS_EMERCH_ENABLED',
    false
  ).toBoolean();

  const checkRecommendations = useCallback(async () => {
    if (!isOnlyMkpCart && isEmerchEnabled) {
      try {
        setState((s) => ({
          ...s,
          isLoading: true
        }));
        const response = await getRecommendationProducts();
        if (!response.products.length) {
          onRecoCancel();
        } else {
          setState((s) => ({
            ...s,
            showRecoProducts: true,
            recoProducts: response.products
          }));
        }
      } catch (error) {
        setState((s) => ({
          ...s,
          isLoading: false
        }));
      }
    } else window.location.href = '/orders/cart';
  }, [isOnlyMkpCart, isEmerchEnabled]);

  const completeOrder = useCallback(
    async (e?: MouseEvent<HTMLAnchorElement>) => {
      if (!isCheckout) {
        checkRecommendations();
        return;
      }
      event.send('checkout', { type: 'validateCart' });
      state.userProductsSubstituable &&
        state.productsSubstituable &&
        event.send('checkout', {
          type: 'acceptSubstitution',
          substitutedProducts: `${state.userProductsSubstituable}::${state.productsSubstituable}`
        });

      e?.preventDefault();

      if (!user) {
        window.location.href = '/connection?redirect=/orders/cart';
        return;
      }

      if (
        state.unavailbleProducts?.length ||
        cartStore.hasAlcoholProduct ||
        state.unavailblePromotion?.length ||
        state.outOfStock?.length
      ) {
        setState((oldState) => ({
          ...oldState,
          openModal: true
        }));
        return;
      }

      setState((oldState) => ({
        ...oldState,
        isLoading: true
      }));

      await getSlots(cartStore);
    },
    [
      cartStore,
      getSlots,
      isCheckout,
      state.outOfStock?.length,
      state.unavailbleProducts?.length,
      state.unavailblePromotion?.length,
      state.userProductsSubstituable,
      state.productsSubstituable,
      event,
      user,
      checkRecommendations
    ]
  );

  const onRecoCancel = () => {
    window.location.href = '/orders/cart';
  };

  const onRecoValidate = () => {
    window.location.href = '/orders/cart';
  };

  const allowSubstitution = useCallback(
    async (authorization: boolean) => {
      try {
        await dispatch(updateAuthorizeCart(authorization, true));
        !authorization &&
          event.send('checkout', {
            type: 'substractingProducts',
            basketId: cartStore.basketId
              ? cartStore.basketId
              : 'panier non authentifié'
          });
      } catch (error: any) {
        // Todo: handle user error display if unable to allow substitution cart
      }
    },
    [dispatch, event, cartStore.basketId]
  );

  const [fidelityCard, setIsFidelityCard] = useState('');

  useEffect(() => {
    (async () => {
      if (user?.id) {
        const { fidelityCardNumber } = await UserInformationApi.getInformations(
          user.id
        );
        fidelityCardNumber && setIsFidelityCard(fidelityCardNumber);
      }
    })();
  }, [user?.id]);

  useEffect(() => {
    if (!cartStoreHydrated || !categoriesHydrated) return;
    const { ref } = usePdv();
    const pdvMainCategories = categories[ref] ?? {};
    const pdvCart = cartStore.subCarts.find((sub) => sub.type === 'PDV');
    const getMetaItems = (
      type: 'advantages' | 'stocks' | 'unavailables' | 'partialStocks'
    ) => {
      const products: Array<CartItemType> = [];
      cartStore.subCarts.forEach((subCart) => {
        subCart?.meta?.invalidItems?.[type]?.forEach((itemId: string) => {
          const product = Object.values(subCart?.items || {})?.find(
            (item) => String(item.product.id) === itemId
          );
          if (product) {
            products.push(product);
          }
        });
      });
      return products;
    };

    const getPartialItems = (type: 'partialStocks409') => {
      const products409: Array<PartialItem> = [];
      cartStore.subCarts.forEach((subCart) => {
        subCart.meta.invalidItems?.[type]?.map(
          ({ itemId, availableStock }: PartialItem) =>
            products409.push({
              itemId,
              availableStock,
              sellerId: subCart.seller.sellerId
            })
        );
      });
      return products409;
    };

    const unavailblePromotion = getMetaItems('advantages');
    const outOfStock = getMetaItems('partialStocks');
    const indispo = getMetaItems('unavailables');
    const stock = getMetaItems('stocks');
    const partialStocks409 = getPartialItems('partialStocks409');
    const unavailbleProducts = indispo || stock ? [...indispo, ...stock] : null;

    const fullCategories = cartStore.subCarts.reduce((result, sub) => {
      let fullCat: Array<ProductByCategorieType> = [
        ...(pdvMainCategories?.data ?? []),
        {
          key: 'other',
          title: t('common.other'),
          hasAlcohol: null,
          picto: null
        },
        {
          key: 'unavailable',
          title: t('cart.unavailables.products'),
          hasAlcohol: null,
          picto: null
        }
      ];
      Object.keys(sub.items).forEach((id: string) => {
        const element = sub.items[id];
        const { universId } = element.product;
        let key =
          fullCat.filter((c) => c.key === String(universId)).length > 0
            ? String(universId)
            : 'other';
        if (
          !element.product.maxQty &&
          !outOfStock?.some((item) => item.product.ean === element.product.ean)
        ) {
          key = 'unavailable';
        }

        fullCat = fullCat.map((category) => {
          if (key === category.key) {
            const r = {
              ...category,
              products: [...(category.products ?? []), element]
            };
            return {
              ...r,
              qty: r.products.length
            };
          }
          return category;
        });
      });
      return {
        ...result,
        [sub.seller.sellerId]: fullCat
      };
    }, {});

    const immediateDiscount = String(
      cartStore.immediateDiscount?.toFixed(2)
    ).replace('.', ',');

    const allowSubstituable = pdvCart
      ? Object.keys(pdvCart.items).reduce((result, current) => {
          return result || pdvCart.items[current].product.allowSubstituable;
        }, false)
      : false;

    setState((oldState) => ({
      ...oldState,
      allowSubstituable,
      unavailblePromotion: unavailblePromotion || null,
      outOfStock: outOfStock || null,
      partialStocks409: partialStocks409 || null,
      unavailbleProducts: unavailbleProducts || null,
      fullCategories,
      isDataLoading: false,
      totalDiscount: immediateDiscount
    }));
  }, [
    cartStoreHydrated,
    categoriesHydrated,
    categories,
    cartStore.subCarts,
    isCheckout,
    user?.id,
    fidelityCard,
    cartStore.immediateDiscount
  ]);

  useEffect(() => {
    if (cartStoreHydrated) {
      setState((oldState) => ({
        ...oldState,
        isDataLoading: false
      }));
    }
  }, [cartStoreHydrated]);

  useEffect(() => {
    (async () => {
      if (state.isLoading && Object.keys(cartStore.slots.list).length) {
        if (persistor) {
          await persistor.flush();
        }
      }
    })();
  }, [
    cartStore.slots.list,
    cartStore.subCarts,
    state.isSlotsLoading,
    cartStore.mkpPackages,
    state.isLoading
  ]);

  useEffect(() => {
    if (!state.fullCategories) return;

    const unavailbleCategory = Object.keys(state.fullCategories).reduce(
      (
        result: {
          [key: string]: ProductByCategorieType | null;
        } | null,
        sellerId: string
      ): {
        [key: string]: ProductByCategorieType | null;
      } | null => {
        if (!state.fullCategories) {
          return result;
        }

        return {
          ...result,
          [sellerId]:
            state.fullCategories[sellerId].find(
              (category) => category.key === 'unavailable'
            ) || null
        };
      },
      null
    );
    setState((oldState) => ({
      ...oldState,
      unavailbleCategory
    }));
  }, [state.fullCategories]);

  useEffect(() => {
    if (!state.handle409) return;

    completeOrder();
    setState((oldState) => ({
      ...oldState,
      handle409: false
    }));
  }, [completeOrder, state.handle409]);

  const confirmDelete = useCallback(async () => {
    const subCart = cartStore.subCarts.find(
      (sc) => sc.seller.sellerId === state.layerConfirmSellerId
    );
    const products = Object.values(subCart?.items || {})?.map((item) => item);

    event.send('deleteCart', {
      sellerName: state.layerConfirmSellerName,
      products,
      route: router.asPath,
      isCheckout
    });

    await dispatch(deleteCart(state.layerConfirmSellerId));

    setState((oldState) => ({
      ...oldState,
      layerConfirm: false,
      layerConfirmSellerId: '',
      layerConfirmSellerName: ''
    }));
  }, [
    cartStore.subCarts,
    dispatch,
    event,
    state.layerConfirmSellerId,
    state.layerConfirmSellerName,
    router?.asPath,
    isCheckout
  ]);
  const isThereItemsInCart = cartStore.qty > 0;
  const userProductsSubstituable = state?.userProductsSubstituable || 0;
  const deleteSubCartConfirm = isCheckout ? true : !state.layerConfirm;

  return (
    <LoadableContent loading={state.isDataLoading}>
      <>
        {isCheckout && (
          <>
            <CartModal
              outOfStock={state.outOfStock}
              unavailbleProducts={state.unavailbleProducts}
              unavailblePromotion={state.unavailblePromotion}
              hasAlcoholProduct={
                cartStore.hasAlcoholProduct && !state.clearUnavailables
              }
              isLoading={state.isLoading}
              open={state.openModal}
              close={() => {
                setState((oldState) => ({
                  ...oldState,
                  openModal: false
                }));
              }}
              validate={async () => {
                setState((oldState) => ({
                  ...oldState,
                  isLoading: true
                }));
                const cart: unknown | CartType =
                  (await clearUnavailableAndPartialAllSeller(
                    state.unavailbleProducts,
                    state.outOfStock,
                    state.partialStocks409,
                    cartStore
                  )) || cartStore;
                setState((oldState) => ({
                  ...oldState,
                  openModal: false
                }));
                await getSlots(cart);
                setState((oldState) => ({
                  ...oldState,
                  unavailbleCategory: null,
                  unavailbleProducts: null,
                  outOfStock: null,
                  partialStocks409: null
                }));
              }}
            />
            <Modal
              className="commonErrorModal"
              confirmBtn={{
                label: state.modal.button || '',
                onClick: async () => {
                  await dispatch(getCart(true));
                  setState((s) => ({
                    ...s,
                    modal: { ...s.modal, isActive: !s.modal.isActive }
                  }));
                }
              }}
              open={state.modal.isActive}
              onClose={() => {
                setState((s) => ({
                  ...s,
                  modal: { ...s.modal, isActive: !s.modal.isActive }
                }));
              }}
            >
              <svg width={128} className="commonErrorModal__picto">
                <use xlinkHref={`${CartSprite}#warning`} />
              </svg>
              <strong className="commonErrorModal__title">
                {state.modal.title}
              </strong>
              <p className="commonErrorModal__text">{state.modal.text}</p>
            </Modal>
          </>
        )}
        <div
          className={classnames({
            cart: true,
            'cart--checkout': isCheckout
          })}
        >
          {((cartStore.total > 0 && cartStore.subCarts.length > 0) ||
            isThereItemsInCart) && (
            <>
              <div className="cart__content">
                {isCheckout && (
                  <a
                    className="cart__back"
                    href="/home"
                    id="cartActionsContinue"
                  >
                    <svg className="cart__back__picto" width="10" height="10">
                      <use xlinkHref={`${CommonSprite}#arrow-left`} />
                    </svg>
                    {t('miniCart.info.actions.continue')}
                  </a>
                )}
                <div className="cart__header">
                  <span>
                    {t(
                      cartStore.subCarts.length > 1
                        ? 'cart.heading.carts'
                        : 'cart.heading.cart'
                    )}
                    {isCheckout && (
                      <span className="cart__header-nbArticles">
                        {t(
                          `cart.numberOfArticle${cartStore.qty > 1 ? 's' : ''}`,
                          {
                            '%qty%': cartStore.qty
                          }
                        )}
                      </span>
                    )}
                  </span>
                </div>
                <div className="cart__products">
                  {deleteSubCartConfirm &&
                    cartStore.subCarts &&
                    cartStore.subCarts.map((subCart, index) => {
                      const subCartKey = `subCart-${index}`;
                      return (
                        <React.Fragment key={subCartKey}>
                          <div className="cart__heading">
                            <Dropdown
                              list={[
                                {
                                  label: t('cart.delete'),
                                  id: 'delete',
                                  action: () => {
                                    setState((oldState) => ({
                                      ...oldState,
                                      layerConfirm: true,
                                      layerConfirmSellerId:
                                        subCart.seller.sellerId,
                                      layerConfirmSellerName:
                                        subCart.seller.sellerName
                                    }));
                                  }
                                }
                              ]}
                              className="cart__more"
                              size="140px"
                            >
                              <svg
                                className="cart__more"
                                id="cartMorePict"
                                width={20}
                                height={20}
                                onClick={() => {
                                  event.send('checkout', {
                                    type: 'openChoiceDeleteCart',
                                    subCartType: subCart.type
                                  });
                                }}
                              >
                                <use xlinkHref={`${CartSprite}#more`} />
                              </svg>
                            </Dropdown>
                            <div className="cart__infos">
                              <div className="cart__infosCart">
                                <strong className="cart__infosTitle">
                                  {t('cart.info.myCart', {
                                    '%sellerName%': subCart.seller.sellerName
                                  })}
                                </strong>
                                <span className="cart__infosPrice">
                                  {t('cart.info.price', {
                                    '%price%': String(
                                      subCart.total.toFixed(2)
                                    ).replace('.', ',')
                                  })}
                                </span>
                              </div>
                              <div className="cart__infosCartsNumber-wrapper">
                                {cartStore.subCarts.length > 1 && (
                                  <span className="cart__infosCartsNumber">
                                    {`${t('common.cart')} : ${index + 1}/${
                                      cartStore.subCarts.length
                                    }`}
                                  </span>
                                )}
                                {cartStore.subCarts.length && (
                                  <span className="cart__infosProducts">
                                    {t(
                                      subCart.qty > 1
                                        ? 'cart.info.products'
                                        : 'cart.info.product',
                                      { '%number%': subCart.qty }
                                    )}
                                  </span>
                                )}
                              </div>
                            </div>
                            <Discount
                              deferredDiscount={
                                subCart.valorisation.deferredDiscount
                              }
                              immediateDiscount={
                                subCart.valorisation.immediateDiscount
                              }
                              collectors={subCart.valorisation.collectors}
                              type={isCheckout ? 'checkout' : 'minicart'}
                              subCartType={subCart.type}
                            />
                          </div>
                          {isCheckout &&
                            state.allowSubstituable &&
                            subCart.type === 'PDV' && (
                              <>
                                <BlockSubstitution />
                                <div className="cart__authReplacement">
                                  <Checkbox
                                    name="authReplacement"
                                    checked={subCart.acceptSubstitution}
                                    onChange={allowSubstitution}
                                    id="authReplacement"
                                    label={`${t('cart.authReplacement')} ${
                                      userProductsSubstituable > 1
                                        ? t(
                                            'cart.authReplacement.selectedProducts',
                                            {
                                              '%qty%': `${userProductsSubstituable}`
                                            }
                                          )
                                        : t(
                                            'cart.authReplacement.selectedProduct',
                                            {
                                              '%qty%': `${userProductsSubstituable}`
                                            }
                                          )
                                    }`}
                                  />
                                </div>
                              </>
                            )}
                          {state.fullCategories && (
                            <ProductByCategorie
                              clearUnavailable={clearUnavailableBySeller}
                              isCheckout={isCheckout}
                              categories={
                                state.fullCategories[subCart.seller.sellerId]
                              }
                              sellerId={subCart.seller.sellerId}
                              redirectOnProductClick={redirectOnProductClick}
                            />
                          )}
                        </React.Fragment>
                      );
                    })}
                  {state.layerConfirm && isCheckout && (
                    <div className="cart__infoCart">
                      <Modal
                        closable={true}
                        onClose={() => {
                          setState((oldState) => ({
                            ...oldState,
                            layerConfirm: false,
                            layerConfirmSellerId: '',
                            layerConfirmSellerName: ''
                          }));
                        }}
                        confirmBtn={{
                          label: t('common.delete'),
                          onClick: confirmDelete
                        }}
                        cancelBtn={{
                          label: t('common.cancel'),
                          onClick: () => {
                            setState((oldState) => ({
                              ...oldState,
                              layerConfirm: false,
                              layerConfirmSellerId: '',
                              layerConfirmSellerName: ''
                            }));
                          }
                        }}
                        open={state.layerConfirm}
                      >
                        <div className="deleteAllCarts__modal">
                          <svg width="150" height="150">
                            <use xlinkHref={`${CartSprite}#trash`} />
                          </svg>
                          <strong className="cart__infoCart__title">
                            {t('cart.info.deleteConfirm', {
                              '%sellerName%': state.layerConfirmSellerName
                            })}
                          </strong>
                          <p className="cart__infoCart__subtitle">
                            {t('cart.info.deleteAll')}
                          </p>
                        </div>
                      </Modal>
                    </div>
                  )}
                  {state.layerConfirm && !isCheckout && (
                    <div className="cart__infoCart">
                      <div className="cart__infoCart">
                        <svg width="150" height="150">
                          <use xlinkHref={`${CartSprite}#trash`} />
                        </svg>
                        <strong className="cart__infoCart__title">
                          {t('cart.info.deleteConfirm', {
                            '%sellerName%': state.layerConfirmSellerName
                          })}
                        </strong>
                        <p>{t('cart.info.deleteAll')}</p>
                        <div className="cart__infoCart__actions">
                          <Button
                            color="secondary"
                            onClick={() => {
                              setState((oldState) => ({
                                ...oldState,
                                layerConfirm: false,
                                layerConfirmSellerId: '',
                                layerConfirmSellerName: ''
                              }));
                            }}
                            label={t('common.cancel')}
                          />
                          <Button
                            onClick={confirmDelete}
                            label={t('common.delete')}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {isCheckout && !state.layerConfirm && (
                  <GamesContest
                    itmCart={
                      cartStore.subCarts.find(
                        (subCart) => subCart.seller.sellerId === 'ITM'
                      ) || null
                    }
                    isAccepted={cartStore.gamesContest?.isAccepted || false}
                  />
                )}
                {isCheckout && <CartHelp />}
              </div>
              {deleteSubCartConfirm && (
                <div className="cart__footer">
                  {isCheckout && <div className="cart__footer__empty" />}
                  <BottomFooter
                    isCheckout={isCheckout}
                    cart={cartStore}
                    ctaLabel={
                      isCheckout ? 'cart.footer.step' : 'cart.footer.seeCart'
                    }
                    totalDiscount={state.totalDiscount}
                    onClick={async (e) => {
                      if (!state.isLoading) completeOrder(e);
                    }}
                    isLoading={state.isLoading}
                    disabledButton={isDisabled}
                    showPelInfo
                  />
                </div>
              )}
            </>
          )}

          {((cartStore.total === 0 && !isThereItemsInCart) ||
            cartStore.subCarts.length === 0) && (
            <div className="cart__infoCart">
              <CartSvg width="150" height="150" />
              <strong className="cart__infoCart__title">
                {t('miniCart.info.empty')}
              </strong>
              <p className="cart__infoCart__subtitle">
                {t('miniCart.info.back')}
              </p>
              <div className="cart__infoCart__actions">
                <Link
                  button
                  href="/home"
                  className="cart__infoCart__link"
                  onClick={(e) => {
                    if (!isCheckout) {
                      e.preventDefault();
                      dispatch({ type: TOGGLE_MINICART_OPENED });
                    }
                  }}
                >
                  {t('miniCart.info.actions.take')}
                </Link>
              </div>
            </div>
          )}
          {cartStore.subCarts.length > 0 && !state.layerConfirm && (
            <BottomFooter
              isCheckout={isCheckout}
              isMobile={true}
              cart={cartStore}
              totalDiscount={state.totalDiscount}
              onClick={async (e) => {
                if (!state.isLoading) completeOrder(e);
              }}
              isLoading={state.isLoading}
              disabledButton={isDisabled}
              showPelInfo
            />
          )}
        </div>
        {state.showRecoProducts && !!state.recoProducts?.length && (
          <RecommendationProductPopin
            productList={state.recoProducts}
            setIsOpen={(value) => {
              setState((s) => ({
                ...s,
                showRecoProducts: value,
                recoProducts: []
              }));
            }}
            title={t('bottomProductsLayer.reco.title')}
            information={t('bottomProductsLayer.reco.informations')}
            isOpen
            hasFooter
            onCancel={onRecoCancel}
            onValidate={onRecoValidate}
          />
        )}
      </>
    </LoadableContent>
  );
};

export default Cart;
