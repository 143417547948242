export enum OrderStatusMapping {
  'VALIDEE' = 'validated',
  'REFUSEE' = 'refused',
  'EN_PREPARATION' = 'preparing',
  'PREPAREE' = 'prepared',
  'EN_LIVRAISON' = 'delivering',
  'LIVREE' = 'delivered',
  'ANNULEE' = 'canceled',
  'RETIREE' = 'withdrawal'
}

export enum OrderMkpStatusMapping {
  'PENDING' = 'recorded',
  'VALIDATED' = 'validated',
  'PREPARATION_IN_PROGRESS' = 'preparing',
  'PREPARED' = 'prepared',
  'DELIVERY_IN_PROGRESS' = 'delivering',
  'DELIVERED' = 'delivered',
  'REFUSED' = 'refused',
  'CANCELED' = 'canceled',
  'CLOSED' = 'closed',
  'REFUNDED' = 'refunded',
  'INCIDENT_OPEN' = 'incident',
  'ABORTED' = 'aborted',
  'PENDING_DEBIT' = 'recorded',
  'PENDING_DEBIT_PAYMENT' = 'recorded',
  'CONTACT_STORE' = 'contactStore',
  'CREATION_IN_PROGRESS' = 'creating',
  'ATTENTE_VALIDATION' = 'pending'
}

export enum DeliveryTypesMapping {
  'DOMICILE' = 'delivery',
  'DRIVEPIETON' = 'drivepieton',
  'DRIVE' = 'drive',
  'DRIVELIGHT' = 'driveLight',
  'DRIVESOLO' = 'driveSolo',
  'DRIVE24' = 'drive24'
}

export enum PaymentModesMapping {
  'AUCUN' = 'none',
  'CHEQUE' = 'bankCheck',
  'CB' = 'bankCard',
  'BON_SOGEC' = 'sogecVoucher',
  'BON_ACHAT' = 'voucher',
  'FID' = 'fidelity',
  'BRI' = 'immediateDiscountVoucher',
  'CD_REDUCTION' = 'cdReduction',
  'E_VIREMENT' = 'transfer'
}

export enum PaymentTypesMapping {
  'BORNE' = 'terminal',
  'EN_LIGNE' = 'online',
  'RECEPTION' = 'reception',
  'LIVRAISON' = 'delivery'
}

export const DELIVERY_TYPE_STORE_PICK_UP = [
  'drive',
  'drive24',
  'drivepieton',
  'driveSolo',
  'driveLight'
];
export const MKP_DELIVERY_TYPE_STORE_PICK_UP = ['RETPDV'];
export const LIST_STATUS_KO = ['REFUSED', 'CANCELED', 'SENT_BO_FAILED'];
