import React from 'react';
import Layout from '@/components/organisms/layout';
import ContentChangeEmailConf from './content';

export type ChangeEmailConfirmationProps = {
  pageView: string;
};

const ChangeEmailConfirmation = ({
  pageView
}: ChangeEmailConfirmationProps) => {
  const contentPart = <ContentChangeEmailConf />;

  return (
    <Layout
      className="forgottenPassword"
      content={contentPart}
      pageView={pageView}
    />
  );
};

export default ChangeEmailConfirmation;
