import React from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import { useRouter } from 'next/router';
import { withESI, ESIOptions } from '@/lib/esi';
import Layout from '@/components/organisms/layout';
import Header from '@/components/organisms/header';
import ProductList from '@/components/organisms/productListESI';

export type SearchProps = {
  footer: ContentfulContent;
};

const Search = ({ footer }: SearchProps) => {
  const router = useRouter();
  const query = { ...((router?.query ?? {}) as { [key: string]: any }) };

  const keyword = query['keyword'] ?? '';
  const baseUrl = `/search/${keyword}`;
  delete query['keyword'];

  const headerPart = <Header />;

  const ProductListESI = withESI(ProductList, {
    id: 'search-content',
    type: 'organisms',
    name: 'productList'
  });

  const content = (
    <>
      <ProductListESI
        esi={ESIOptions()}
        type="search"
        keyword={keyword}
        baseUrl={baseUrl}
        query={query}
      />
    </>
  );

  return (
    <Layout
      className="searchPage"
      header={headerPart}
      content={content}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
    />
  );
};

export default Search;
