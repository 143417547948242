import { LinkProps } from '@/components/atoms/link';
import { Component, NormalizeParams } from '..';

const normalize = ({ data, extras }: NormalizeParams): ComponentLinkProps => {
  return {
    type: 'atoms',
    name: 'link',
    props: {
      label: data.text || '',
      href: data.href,
      button: extras?.id === 'landingPage' || false
    }
  };
};

export default normalize;

export interface ComponentLinkProps extends Component {
  props: LinkProps;
}
