import React from 'react';
import t from '@/lib/i18n';
import './style.scss';

type WithResultsProps = {
  keyword: string;
  children: JSX.Element;
};

const WithResults = ({ keyword, children }: WithResultsProps) => (
  <div className="container container--xl">
    <p className="searchPage__title">
      {t('search.title', { '%keyword%': keyword })}
    </p>
    {children}
  </div>
);

export default WithResults;
