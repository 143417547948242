import React, { useState, useEffect } from 'react';
import { withESI, ESIOptions } from '@/lib/esi';
import ProductSlider from '@/components/organisms/productSliderESI';
import topCategoriesTiles from '@/components/organisms/topCategoriesTilesESI';
import CmsApi, { SuggestionsContentProps } from '@/lib/api/cms';

export type SuggestionsProps = {
  essentials: SuggestionsContentProps;
  recommendations: SuggestionsContentProps;
};

const Content = () => {
  const [suggestions, setSuggestions] = useState<SuggestionsProps | null>(null);

  useEffect(() => {
    (async () => {
      const data = await CmsApi.getTemplatesNoResults();
      setSuggestions(data);
    })();
  }, []);

  const CategoriesESI = withESI(topCategoriesTiles, {
    id: 'allUnivers-topCategoriesTiles',
    type: 'organisms',
    name: 'topCategoriesTiles'
  });

  const SliderEssentialsESI = withESI(ProductSlider, {
    id: 'allUniversproducts-slider-essentials',
    type: 'organisms',
    name: 'productSlider'
  });

  const SliderRecommendationsESI = withESI(ProductSlider, {
    id: 'allUnivers-products-slider-recommendations',
    type: 'organisms',
    name: 'productSlider'
  });

  return (
    <>
      <CategoriesESI esi={ESIOptions()} />
      {suggestions && (
        <>
          <SliderEssentialsESI
            esi={ESIOptions()}
            shopId={suggestions.essentials?.shopId}
            title={suggestions.essentials?.title}
            buttonLabel={suggestions.essentials?.buttonLabel}
          />
          <SliderRecommendationsESI
            esi={ESIOptions()}
            shopId={suggestions.recommendations?.shopId}
            title={suggestions.recommendations?.title}
            buttonLabel={suggestions.recommendations?.buttonLabel}
          />
        </>
      )}
    </>
  );
};

export default Content;
