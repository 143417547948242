import React from 'react';
import createAxios from '@/lib/axios';
import logger from '@/lib/logger/base';
import getConfigValue from '@/lib/config';
import { withESI, ESIOptions } from '@/lib/esi';
import path from 'path';
import Date from '@/lib/utils/date';

const normalizers: {
  [key: string]: (params: NormalizeParams) => any;
} = {};

const normalizersRequire = require.context('./normalizers', true, /\w+\.ts$/);
normalizersRequire.keys().forEach((fileName) => {
  const normalizer: any = normalizersRequire(fileName);
  const contentType = path.basename(fileName, '.ts');

  normalizers[contentType] = normalizer.default;
});

const componentsRequire = import.meta.webpackContext('../../components', {
  recursive: true,
  regExp: /(?:atoms|molecules|organisms|templates)+\/.*\/index\.tsx$/
});

type AvailableComponent = {
  path: string;
  object: any;
};

const availableComponents: Array<AvailableComponent> = [];

componentsRequire.keys().forEach((fileName) => {
  const component = componentsRequire(fileName);
  availableComponents.push({
    path: fileName,
    object: component
  });
});

const axios = createAxios(
  {
    headers: {
      'x-service-name': 'contentful'
    }
  },
  []
);

export const fetchContent = async ({
  contentType,
  id,
  preview = false,
  context
}: {
  contentType: string;
  id?: string | null;
  preview?: boolean;
  context?: NormalizeContext;
}): Promise<Content | null> => {
  const suffixe: string = preview ? 'PREVIEW' : 'DELIVERY';

  const host = getConfigValue(`CONTENTFUL_HOST_${suffixe}`)?.toString();
  const accessToken = getConfigValue(`CONTENTFUL_TOKEN_${suffixe}`)?.toString();
  const environment = getConfigValue('CONTENTFUL_ENVIRONMENT')?.toString();
  const space = getConfigValue('CONTENTFUL_SPACE')?.toString();
  const timeout = getConfigValue('CONTENTFUL_TIMEOUT')?.toNumber();

  const url = `${host}/spaces/${space}/environments/${environment}/entries`;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Accept-Encoding': 'gzip'
  };

  const query: { [key: string]: any } = {
    content_type: contentType,
    include: 10
  };

  if (id) {
    query['fields.id'] = id;
  }

  const queryString = Object.keys(query)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
    })
    .join('&');

  try {
    let { data } = await axios.get(`${url}?${queryString}`, {
      headers,
      timeout
    });

    if (data.items.length === 0) {
      return null;
    }

    const includes = buildIncludesMap(data.includes);
    const item = data.items.shift();
    data = null;
    const isFreeTemplate = contentType === 'templateContenuLibre';

    return normalize({
      data: item,
      includes,
      context,
      extras: { id, isFreeTemplate }
    });
  } catch (error: any) {
    logger.error({
      message: 'Unable to fetch contentful data',
      error,
      context: {
        space,
        environment,
        query
      }
    });
  }

  return null;
};

type IncludesMap = { [key: string]: any };

const buildIncludesMap = (includes: any): IncludesMap => {
  const map: IncludesMap = {};

  for (const entry of includes.Entry) {
    map[entry.sys.id] = entry;
  }

  if (includes.Asset) {
    for (const asset of includes.Asset) {
      map[asset.sys.id] = asset;
    }
  }

  return map;
};

export const normalize = ({
  data,
  includes,
  context,
  extras
}: NormalizeParams): any => {
  if (typeof data?.sys?.contentType === 'undefined') {
    return null;
  }
  const { fields } = data;

  const contentType = data.sys.contentType.sys.id || null;

  if (normalizers[contentType]) {
    if (typeof fields.affichage !== 'undefined' && fields.affichage === false) {
      return null;
    }

    if (
      typeof fields.startDateTime !== 'undefined' &&
      typeof fields.endDateTime !== 'undefined'
    ) {
      const now = Date();
      const startDate = Date(fields.startDateTime);
      const endDate = Date(fields.endDateTime);

      if (startDate > now || now >= endDate) {
        return null;
      }
    }

    return normalizers[contentType]({
      data: fields,
      includes,
      context,
      extras
    });
  }

  return null;
};

export const RendererComponents = ({
  components
}: {
  components: Array<Component>;
}) => {
  const elements: Array<JSX.Element> = [];

  components?.forEach((componentToRender: Component) => {
    const element = RendererComponent({ component: componentToRender });

    if (element) {
      elements.push(element);
    }
  });

  return React.createElement(React.Fragment, {}, ...elements);
};

export const RendererComponent = ({
  component
}: {
  component: Component;
}): JSX.Element | null => {
  const componentPath = `./${component.type}/${component.name}/index.tsx`;

  const element = availableComponents.find((elt) => {
    return elt.path === componentPath;
  });

  if (!element) {
    return null;
  }

  let reactComponent = element.object.default;

  if (component.esi?.enabled) {
    reactComponent = withESI(reactComponent, {
      id: component.esi.id,
      type: component.type,
      name: component.name
    });

    /* eslint-disable no-param-reassign */
    component.props.esi = ESIOptions();
  }

  return React.createElement(reactComponent, component.props);
};

export type NormalizeContext = { [key: string]: any };

export type NormalizeParams = {
  data: any;
  includes: any;
  context?: NormalizeContext;
  extras?: ExtrasNormalizeParams;
};

// used to centralize all specifics use case for each normaliser
export type ExtrasNormalizeParams = {
  id?: string | null;
  isFreeTemplate?: boolean;
};

export interface Content {
  meta?: Meta;
  components: Array<Component>;
}

export interface Component {
  type: string;
  name: string;
  props: any;
  esi?: {
    id: string;
    enabled: boolean;
  };
}

export interface Meta {
  title?: string | null;
  description?: string | null;
}
