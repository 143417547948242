import React from 'react';
import Layout from '@/components/organisms/layout';
import SavBanner from '@/components/molecules/savBanner';
import FormContactUs from '@/components/templates/contactUs/form';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Header from '@/components/organisms/header';
import { ThemesProps } from '@/components/organisms/savTilesGrid';

export type SavMailProps = {
  footer: ContentfulContent;
  themes: Array<ThemesProps>;
  pageView: string;
};

const SavMail = ({ footer, themes, pageView }: SavMailProps) => {
  const headerPart = <Header />;

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  const content = (
    <>
      <SavBanner withSubBanner subBanner={{ href: '/help-and-contact/vas' }} />
      <FormContactUs slug="Mon-compte-Intermarche" />
    </>
  );

  return (
    <Layout
      header={headerPart}
      footer={footerPart}
      content={content}
      pageView={pageView}
    />
  );
};

export default SavMail;
