import { ContentSectionProps } from '@/components/molecules/contentSection';
import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): ComponentImageProps => {
  const { title = '', text = '' } = data;

  return {
    type: 'molecules',
    name: 'contentSection',
    props: {
      title,
      content: text,
      className: 'legalsCms'
    }
  };
};

export default normalize;

export interface ComponentImageProps extends Component {
  props: ContentSectionProps;
}
