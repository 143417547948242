import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import CartSprite from '@/assets/images/sprites/cart.svg';
import t from '@/lib/i18n';
import { CollectorItemType } from '@/store/cart/types';
import Image from '@/components/atoms/image';
import Slider from '@/components/molecules/slider';
import CommonSprite from '@/assets/images/sprites/common.svg';
import './style.scss';
import Carousel from 'react-multi-carousel';

export type CartDiscountProps = {
  deferredDiscount: number;
  immediateDiscount: number;
  collectors?: Array<CollectorItemType>;
  type?: 'basic' | 'checkout' | 'minicart';
  subCartType?: 'PDV' | 'MKP';
};

type StateTypeProps = {
  currentSlide: number;
  totalItems: number;
  slidesToShow: number;
};

const CartDiscount = ({
  deferredDiscount,
  immediateDiscount,
  collectors,
  type = 'basic',
  subCartType = 'PDV'
}: CartDiscountProps) => {
  const refSlider = useRef<Carousel>();

  const [state, setState] = useState<StateTypeProps>({
    currentSlide: 0,
    totalItems: 0,
    slidesToShow: 0
  });
  const content = [];

  if (subCartType === 'PDV') {
    content.push(
      <div
        className="cartDiscount__part cartDiscount__part--advantage"
        key="cartDiscount__part--advantage"
      >
        <div className="cartDiscount__wrapper">
          <div className="cartDiscount__pictoAndprice">
            <svg className="cartDiscount__picto" width="30px" height="30px">
              <use xlinkHref={`${CartSprite}#fidelite`} />
            </svg>
            <span className="cartDiscount__price">
              {deferredDiscount
                ? String(deferredDiscount.toFixed(2)).replace('.', ',')
                : '0'}
              &nbsp;&euro;
            </span>
          </div>
          <div>
            <span className="cartDiscount__label">
              {t('cart.discount.advantage')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  /*  content.push(
    <div
      className="cartDiscount__part cartDiscount__part--saving"
      key="cartDiscount__part--saving"
    >
      <div className="cartDiscount__wrapper">
        <div className="cartDiscount__pictoAndprice">
          <svg className="cartDiscount__picto" width="25px" height="30px">
            <use xlinkHref={`${CartSprite}#wallet`} />
          </svg>
          <span className="cartDiscount__price">
            {immediateDiscount
              ? String(immediateDiscount.toFixed(2)).replace('.', ',')
              : '0'}
            &nbsp;&euro;
          </span>
        </div>
        <div>
          <span className="cartDiscount__label">
            {t('cart.discount.saved')}
          </span>
        </div>
      </div>
    </div>
  ); */

  collectors?.forEach((collector: CollectorItemType) =>
    content.push(
      <div
        className="cartDiscount__part cartDiscount__part--collector"
        key={`cartDiscount__part--collector_${collector.id}`}
      >
        <div className="cartDiscount__wrapper">
          <div className="cartDiscount__pictoAndprice">
            <Image
              src={collector.picto}
              width={30}
              alt={`picto_${collector.id}`}
            />
            <span className="cartDiscount__price"> + {collector.quantity}</span>
          </div>
          <div>
            <span className="cartDiscount__label">{collector.unit}</span>
          </div>
        </div>
      </div>
    )
  );

  const nbItemsToDisplay = Math.min(
    type !== 'minicart' ? 3 : 2,
    content.length
  );

  const responsive = {
    desktop: {
      breakpoint: { max: 4094, min: 1024 },
      items: nbItemsToDisplay
    },
    tablet: {
      breakpoint: { max: 1023, min: 465 },
      items: nbItemsToDisplay
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: Math.min(2, content.length)
    }
  };

  useEffect(() => {
    setState({
      currentSlide: refSlider.current?.state.currentSlide || 0,
      totalItems: refSlider.current?.state.totalItems || 0,
      slidesToShow: refSlider.current?.state.slidesToShow || 0
    });
  }, [refSlider.current?.state.domLoaded, content.length]);

  return (
    <div
      className={classnames({
        cartDiscount: true,
        [`cartDiscount--checkout3`]:
          nbItemsToDisplay === 3 && type === 'checkout',
        [`cartDiscount--checkout2`]:
          nbItemsToDisplay === 2 && type === 'checkout',
        [`cartDiscount--minicart`]: type === 'minicart'
      })}
    >
      <div className="cartDiscount__slider-button-group">
        {state?.currentSlide > 0 && (
          <svg
            className="cartDiscount__arrow-svg"
            onClick={() => refSlider.current?.previous(0)}
          >
            <use xlinkHref={`${CommonSprite}#arrow-left`} />
          </svg>
        )}
      </div>

      <Slider
        ref={refSlider}
        type="basic"
        swipeable={true}
        showDots={false}
        responsive={responsive}
        slidesToSlide={1}
        arrows={false}
        containerClass="cartDiscount__slider-container"
        afterChange={() => {
          setState((oldState) => {
            return {
              ...oldState,
              currentSlide: refSlider.current?.state.currentSlide || 0
            };
          });
        }}
      >
        {content}
      </Slider>

      <div className="cartDiscount__slider-button-group">
        {state?.currentSlide + state.slidesToShow < state?.totalItems && (
          <svg
            className="cartDiscount__arrow-svg"
            onClick={() => refSlider.current?.next(0)}
          >
            <use xlinkHref={`${CommonSprite}#arrow-right`} />
          </svg>
        )}
      </div>
    </div>
  );
};

export default CartDiscount;
