import React from 'react';
import Modal from '@/components/organisms/modal';
import Link from '@/components/atoms/link';
import t from '@/lib/i18n';
import './style.scss';

type ModalConsentSesProps = {
  validate: () => void;
  closeModal: () => void;
  open: boolean;
};

export const ModalConsentSes = ({
  open,
  closeModal,
  validate
}: ModalConsentSesProps) => (
  <Modal
    open={open}
    closable={false}
    pageView="ModalConsentSes"
    className="prefSESModal"
    scrollable={false}
    confirmBtn={{
      label: t('myAccount.smartConso.modalSes.accept'),
      onClick: validate,
      isLink: true
    }}
    cancelBtn={{
      label: t('myAccount.smartConso.modalSes.refuse'),
      onClick: closeModal,
      isLink: true
    }}
  >
    <p className="prefSESModal__content">
      {t('myAccount.smartConso.modalSes.p1')}
    </p>
    <span className="prefSESModal__content">
      {t('myAccount.smartConso.modalSes.p2')}
    </span>
    <Link
      className="prefSESModal__content-link"
      target="_blank"
      href="/fidelity-advantages/tcu"
      label={t('myAccount.smartConso.modalSes.p3')}
    />
    <span className="prefSESModal__content">
      {t('myAccount.smartConso.modalSes.p4')}
    </span>
    <Link
      className="prefSESModal__content-link"
      target="_blank"
      href="/legal-informations/personal-data-and-cookies"
      label={t('myAccount.smartConso.modalSes.p5')}
    />
  </Modal>
);

export default ModalConsentSes;
