import React from 'react';
import { RendererComponents, Component } from '@/lib/contentful';
import classnames from 'classnames';
import './style.scss';

export type CollectionGridProps = {
  components: Array<Component>;
  columnNumber: number;
};

const CollectionGrid = ({ components, columnNumber }: CollectionGridProps) => {
  return (
    <div
      className={classnames(
        'collectionGrid',
        `collectionGrid--${columnNumber}`
      )}
    >
      <RendererComponents components={components} />
    </div>
  );
};

export default CollectionGrid;
