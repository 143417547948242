import classNames from 'classnames';
import React from 'react';
import t from '@/lib/i18n';
import ScoreInit from '../scoreInit';
import './style.scss';

type ScoreInfoProps = {
  displayBlockAvoid: boolean;
};

const ScoreModel = [
  {
    min: 80,
    max: 100,
    value: 'excellentMatch'
  },
  {
    min: 60,
    max: 79,
    value: 'goodMatch'
  },
  {
    min: 40,
    max: 59,
    value: 'okayMatch'
  },
  {
    min: 20,
    max: 39,
    value: 'weakMatch'
  },
  {
    min: 0,
    max: 19,
    value: 'badMatch'
  }
];

const BlockAvoid = () => {
  return (
    <div className="scoreInfo blockAvoid">
      <ScoreInit rank="avoid" />
      <div className="scoreInfo-text">=</div>
      <div className="scoreInfo-text">{t(`pdp.nutrition.scoreBar.avoid`)}</div>
    </div>
  );
};

export const ScoreInfo = ({ displayBlockAvoid }: ScoreInfoProps) => {
  return (
    <div className="scoreInfoWrapper">
      {ScoreModel.map((score) => (
        <div
          className={classNames('scoreInfo', `scoreInfo--${score.value}`)}
          key={score.min}
        >
          <ScoreInit scoreInit={score.min} rank={score.value} />
          <div className="scoreInfo-text">{t('pdp.nutrition.scoreBar.to')}</div>
          <ScoreInit scoreInit={score.max} rank={score.value} />
          <div className="scoreInfo-text">=</div>
          <div className="scoreInfo-text">
            {t(`pdp.nutrition.scoreBar.${score.value}`)}
          </div>
        </div>
      ))}
      {displayBlockAvoid && <BlockAvoid />}
    </div>
  );
};
