import React, { useState } from 'react';
import classnames from 'classnames';
import './style.scss';
import useClickOut from '@/lib/hooks/useClickOut';

export type DropdownProps = {
  className?: string;
  dropDownId?: string;
  children: JSX.Element;
  position?: {
    top?: string;
    left?: string;
    bottom?: string;
    right?: string;
  };
  size?: string;
  list: Array<{
    id: string | number;
    label: string;
    action: () => void;
  }>;
};

const Dropdown = ({
  className,
  children,
  dropDownId,
  position = { top: '0', right: '100%' },
  size = 'auto',
  list
}: DropdownProps) => {
  const [visible, setVisible] = useState(false);
  const [clickOutRef, clickOutHandler] = useClickOut();

  clickOutHandler(() => {
    if (visible) {
      setVisible(false);
    }
  });

  return (
    <div
      ref={clickOutRef}
      onClick={() => {
        setVisible((s) => !s);
      }}
      className={classnames('dropdown', className)}
      id={dropDownId}
    >
      {children}
      {visible && (
        <ul
          className="dropdown__layer"
          style={{
            width: size,
            top: position?.top || 'auto',
            right: position?.right || 'auto',
            left: position?.left || 'auto',
            bottom: position?.bottom || 'auto'
          }}
        >
          {list.map(({ id, label, action }) => {
            const btnId = className?.includes('cart__more')
              ? 'btn-deleteCart'
              : '';
            return (
              <li key={id}>
                <button
                  onClick={action}
                  className="dropdown__button"
                  id={btnId}
                >
                  {label}
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
