import React, { useEffect, useState } from 'react';
import Header from '@/components/organisms/header';
import { useRouter } from 'next/router';
import Layout from '@/components/organisms/layout';
import AccountApi from '@/lib/api/account';
import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import LoaderIcon from '@/components/atoms/loader';
import CommonSprite from '@/assets/images/sprites/common.svg';
import './style.scss';
import useEvent from '@/lib/hooks/useEvent';

const ACCOUNT_CREATION_STATUS = {
  initial: 'initial',
  valid: 'valid',
  nonvalid: 'nonvalid'
};

export type AccountActivationProps = {
  pageError: Component;
  pageValidation: Component;
};

const AccountActivation = ({
  pageError,
  pageValidation
}: AccountActivationProps) => {
  const UID: string = (useRouter().query?.UID as string) || '';
  const [resApi, setResApi] = useState(ACCOUNT_CREATION_STATUS.initial);
  const event = useEvent();

  useEffect(() => {
    (async () => {
      const resultApi = await AccountApi.acountActivation(UID);
      setResApi(
        resultApi
          ? ACCOUNT_CREATION_STATUS.valid
          : ACCOUNT_CREATION_STATUS.nonvalid
      );
      resultApi
        ? event.send('api', { type: 'success', name: 'activation' })
        : event.send('api', { type: 'error', name: 'activation' });
    })();
  }, [UID, event]);

  const headerPart = <Header />;

  const content = (
    <div className="acountActivation">
      {ACCOUNT_CREATION_STATUS.initial === resApi && (
        <>
          <svg width="200" className="fill--white">
            <use xlinkHref={`${CommonSprite}#logo`} />
          </svg>
          <LoaderIcon />
        </>
      )}
      {ACCOUNT_CREATION_STATUS.nonvalid === resApi && (
        <ContentfulRenderComponents components={[pageError]} />
      )}
      {ACCOUNT_CREATION_STATUS.valid === resApi && (
        <ContentfulRenderComponents components={[pageValidation]} />
      )}
    </div>
  );

  return (
    <Layout
      header={headerPart}
      content={content}
      footer={null}
      pageView="accountActivation"
    />
  );
};

export default AccountActivation;
