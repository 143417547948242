import Header from '@/components/organisms/header';
import Layout from '@/components/organisms/layout';
import getConfigValue from '@/lib/config';
import {
  Content as ContentfulContent,
  RendererComponents as ContentfulRenderComponents
} from '@/lib/contentful';
import usePageView from '@/lib/hooks/usePageView';
import loadScript from '@/lib/utils/loadScript';
import Head from 'next/head';
import React, { useEffect } from 'react';

export type FlyMenuProps = {
  footer: ContentfulContent | null;
  paramBaseUrl: boolean;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace, @typescript-eslint/no-unused-vars
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface IntrinsicElements {
      'intermarche-recipes': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const FlyMenu = ({ footer, paramBaseUrl }: FlyMenuProps) => {
  const pageView = usePageView();

  const headerPart = <Header />;
  const contentPart = <intermarche-recipes />;
  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  const flyMenuScriptUrl = getConfigValue('FLYMENU_SCRIPT_URL', '').toString();
  const flyMenuStyleUrl = getConfigValue('FLYMENU_STYLE_URL', '').toString();
  const baseUrl = paramBaseUrl ? '/home/receipts' : '/receipts';

  pageView.send('FlyMenu');

  useEffect(() => {
    loadScript(flyMenuScriptUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="fly-menu">
      <Head>
        {flyMenuScriptUrl && (
          <link
            crossOrigin="anonymous"
            rel="dns-prefetch"
            href={new URL(flyMenuScriptUrl).origin}
          />
        )}
        {flyMenuStyleUrl && <link rel="stylesheet" href={flyMenuStyleUrl} />}
        {flyMenuScriptUrl && (
          <link rel="preload" href={flyMenuScriptUrl} as="script" />
        )}
        <base href={baseUrl} key="baseUrl" />
      </Head>
      <Layout header={headerPart} content={contentPart} footer={footerPart} />
    </div>
  );
};

export default FlyMenu;
