import CommonSprite from '@/assets/images/sprites/common.svg';
import Link from '@/components/atoms/link';
import t from '@/lib/i18n';
import {
  Filter as FilterModel,
  FilterValue,
  Filters as FiltersModel,
  FiltersType
} from '@/lib/model/productList';
import groupBy from 'lodash/groupBy';
import React, { useCallback, useEffect, useState } from 'react';
import Filter from './filter';
import Sticky from './sticky';
import './style.scss';

type VerticalFiltersProps = {
  initialFilters: FiltersModel;
  activableFilters: FiltersModel;
  checkedFilters: FiltersType;
  applyFilters: (filters: FiltersModel) => void;
  clearFilters: () => void;
  isDesktop: boolean;
  clickOutRef: any;
  close: () => void;
  totalNbProducts: number;
};
const FILTER_ORDER = ['categories', 'promotions', 'preferences', 'marques'];

const VerticalFilters = React.memo(
  ({
    activableFilters,
    applyFilters,
    clearFilters,
    isDesktop,
    clickOutRef,
    close,
    initialFilters,
    checkedFilters,
    totalNbProducts
  }: VerticalFiltersProps) => {
    const [defaultFilters, setDefaultFilters] = useState<FiltersModel>([]);

    const getDefaultFilters = useCallback(() => {
      const f: FiltersModel = [];
      const groupedFilters = groupBy(initialFilters, 'type');

      FILTER_ORDER.forEach((type) => {
        if (groupedFilters[type]?.[0]) {
          f.push({
            type,
            values: groupedFilters[type]?.[0].values
          });
        }
      });
      // add the rest of filters
      for (const type in groupedFilters) {
        if (groupedFilters[type]?.[0] && !FILTER_ORDER.includes(type)) {
          f.push({
            type,
            values: groupedFilters[type]?.[0].values
          });
        }
      }

      return f;
    }, [initialFilters]);

    const getActiveFilters = useCallback(
      (filters: FiltersModel) => {
        if (!activableFilters || !Object.keys(activableFilters).length)
          return filters;
        // uncheck and disable all options by default
        const copyDefaultFilters = filters.map((f) => ({
          ...f,
          values: f.values.map((val) => ({
            ...val,
            active: false,
            disabled: true
          }))
        }));

        // enable only filters return from api
        activableFilters.forEach((fil) => {
          const old = copyDefaultFilters.find((def) => def.type === fil.type);
          fil.values.forEach((fv) => {
            const oldV = old?.values.find((ov) => ov.id === fv.id);
            if (oldV) {
              oldV.disabled = false;
              oldV.countProducts = fv.countProducts;
            }
          });
        });
        // check filters return from api
        checkedFilters.forEach((fil) => {
          const old = copyDefaultFilters.find((def) => def.type === fil.type);
          fil.values?.forEach((fv) => {
            const oldV = old?.values.find(
              (ov) => ov.id.toString() === fv.toString()
            );
            if (oldV && !oldV.disabled) {
              oldV.active = true;
            }
          });
        });

        return copyDefaultFilters;
      },
      [activableFilters, checkedFilters]
    );

    const onFilterChecked = (
      filterName: string,
      option: FilterValue,
      checked?: boolean
    ) => {
      const copyDefaultFilters = [...defaultFilters];
      const currentFilterOptions = copyDefaultFilters.find(
        (c) => c.type === filterName
      );

      const exist = currentFilterOptions?.values?.find(
        (f) => f.id.toString() === option.id.toString()
      );
      if (exist) {
        exist.active = checked;
        setDefaultFilters(copyDefaultFilters);
        applyFilters(copyDefaultFilters);
      }
    };

    useEffect(() => {
      const f: FiltersModel = getActiveFilters(getDefaultFilters());
      setDefaultFilters(f);
    }, [getActiveFilters, getDefaultFilters]);

    const resetFilters = () => {
      clearFilters();
    };

    return (
      <div className="vertical-filters">
        <div className="vertical-filters__container">
          <div className="vertical-filters__content" ref={clickOutRef}>
            <div className="vertical-filters__content__filters">
              {!isDesktop && (
                <div className="vertical-filters__header">
                  <Link className="vertical-filters__arrow" onClick={close}>
                    <svg width="16" height="16">
                      <use xlinkHref={`${CommonSprite}#arrow-long`} />
                    </svg>
                  </Link>
                  <div className="vertical-filters__title">
                    {t('productList.filters.title')}
                  </div>
                </div>
              )}

              <div className="vertical-filters__sections">
                {defaultFilters.map((filter: FilterModel) => {
                  const key = `filter-${filter.type}-${filter.values?.length}`;
                  return (
                    <Filter
                      key={key}
                      options={filter.values}
                      name={filter.type}
                      onFilterChecked={onFilterChecked}
                      label={
                        <div>{t(`productList.filters.${filter.type}`)}</div>
                      }
                    />
                  );
                })}
              </div>
            </div>
            <div className="vertical-filters__content__sticky">
              <Sticky
                clearFilters={resetFilters}
                close={close}
                data={defaultFilters}
                totalNbProducts={totalNbProducts}
              />
            </div>
          </div>
          <div className="vertical-filters__overlay" />
        </div>
      </div>
    );
  }
);

export default VerticalFilters;
