import React, { useEffect, useState } from 'react';
import './style.scss';
import Product from '@/components/organisms/product';
import ProductApi from '@/lib/api/product';
import ProductModel from '@/lib/model/product';
import Slider from '@/components/molecules/slider';
import Link from '@/components/atoms/link';
import usePdv from '@/lib/hooks/usePdv';
import useShallowEqualSelector from '@/lib/utils/useShallowEqualSelector';
import { selectUserId } from '@/store/auth/authSelectors';

export type ProductSliderProps = {
  title?: string | null;
  buttonLabel?: string;
  shopId?: string;
  products?: Array<ProductModel>;
  showcase?: boolean;
  isProducteursDIci?: boolean;
};

const ProductSlider = ({
  title,
  buttonLabel,
  shopId,
  products = [],
  showcase = false,
  isProducteursDIci
}: ProductSliderProps) => {
  if (
    (products.length < 8 && !showcase && !isProducteursDIci) ||
    (products.length < 1 && isProducteursDIci)
  ) {
    return null;
  }

  const [productsData, setProductsData] = useState<Array<ProductModel>>(
    products || []
  );

  const userId = useShallowEqualSelector(selectUserId);

  useEffect(() => {
    (async () => {
      const { ref } = usePdv();

      if (shopId) {
        const data =
          (await ProductApi.suggestionForShop(ref, shopId, 16)) || [];
        setProductsData(data);
      }
    })();
  }, [userId, shopId]);

  return (
    <div className="productSlider">
      <div className="container">
        {title && (
          <div
            className="productSlider__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <Slider
          className="productSlider__products"
          slidesToSlide={4}
          renderDotsOutside={true}
          type="secondary"
          arrows={true}
        >
          {productsData.map((product, index) => {
            const key = `slider-product-${index}`;
            return <Product key={key} data={product} listPosition={index} />;
          })}
        </Slider>
        {shopId && (
          <Link
            className="productSlider__link"
            button
            label={buttonLabel}
            href={`/shop/${shopId}`}
          />
        )}
      </div>
    </div>
  );
};

export default ProductSlider;
