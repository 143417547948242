import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import './style.scss';

export type Option = {
  id: string;
  label?: string;
  content?: JSX.Element | string | null;
  value: string | number;
};

export type FormRadioProps = {
  className?: string;
  id?: string;
  name?: string;
  label?: string;
  value?: string | number;
  itemsType?: 'inline' | 'block';
  type?: 'inline' | 'block';
  options: Array<Option>;
  onChange?: (value: string | number) => void;
  variation?: 'primary' | 'secondary';
  errorMessage?: string;
  disabled?: boolean;
  onFocusContent?: boolean;
};

const FormRadio = React.forwardRef(
  (
    {
      className,
      variation = 'primary',
      type = 'inline',
      id,
      name,
      label,
      value,
      options,
      itemsType = 'inline',
      onChange,
      errorMessage,
      disabled,
      onFocusContent
    }: FormRadioProps,
    ref: any
  ) => {
    const [state, setState] = useState({
      checked: value
    });

    const updateValue = (newValue: string | number) => {
      if (onChange) onChange(newValue);
    };

    useEffect(() => {
      setState({
        checked: value
      });
    }, [value]);

    return (
      <div
        className={classnames(
          className,
          'formRadio',
          `formRadio--${variation}`,
          `formRadio--${type}`,
          {
            'formRadio--disabled': disabled
          }
        )}
      >
        {label && (
          <label className="formRadio__label" htmlFor={id}>
            {label}
          </label>
        )}
        <div
          className={classnames(
            'formRadio__items',
            `formRadio__items--${itemsType}`
          )}
        >
          {options.map((option, index) => {
            const key = `formRadioOption-${name}-${option.id}-${index}`;

            return (
              <div key={key} className="formRadio__item">
                <input
                  disabled={disabled}
                  ref={ref}
                  className="formRadio__input"
                  key={key}
                  type="radio"
                  value={option.value}
                  name={name}
                  id={key}
                  checked={option.value === state.checked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setState({
                        checked: option.value
                      });
                      updateValue(option.value);
                    }
                  }}
                />
                <label
                  className="formRadio__itemLabel"
                  htmlFor={key}
                  id={`${key}-label`}
                >
                  {option.label}
                </label>
                {option.content && (
                  <div
                    className="formRadio__content"
                    onClick={() => {
                      setState({
                        checked: option.value
                      });
                      updateValue(option.value);
                    }}
                  >
                    {onFocusContent
                      ? option.value === state.checked && option.content
                      : option.content}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {errorMessage && (
          <div className="formRadio__errorMessage">{errorMessage}</div>
        )}
      </div>
    );
  }
);

export default FormRadio;
