import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import { CartType, SlotsType } from '@/store/cart/types';
import { NormalizerInterface } from './normalizer';
import NormalizerLegacyVersion from './normalizer/normalizerLegacyVersion';
import NormalizerNewVersion from './normalizer/normalizerNewVersion';

const normalizers: { [key: string]: NormalizerInterface } = {
  legacyVersion: NormalizerLegacyVersion,
  newVersion: NormalizerNewVersion
};

class SlotsApi {
  axios: AxiosInstance;

  apiVersion: string;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);

    this.apiVersion = 'newVersion';
  }

  async getAll(cart: CartType, pdv: string, rcId?: string): Promise<SlotsType> {
    let response: any = null;

    try {
      response = await this.axios.post(
        `/tunnelachat/v1/tunnels/${rcId}/deliveries?externalLad=true`,
        normalizers[this.apiVersion].normalize(cart, pdv)
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch slots',
        error
      });
      const errorTransform =
        error.response?.status === 409
          ? normalizers[this.apiVersion].denormalize(error.response.data, cart)
          : null;
      const thrown = {
        errorTransform,
        ...error
      };
      throw thrown;
    }

    return normalizers[this.apiVersion].denormalize(response.data, cart);
  }
}

export default new SlotsApi();
