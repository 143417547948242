import React from 'react';
import { Request, Response } from 'express';
import { Provider } from 'react-redux';
import { store } from '@/store';
import usePdv from '@/lib/hooks/usePdv';
import { fetchContent } from '@/lib/contentful';
import HomepageContent, { ContentProps } from '../content';

type InitialProps = {
  props: ContentProps;
  req: Request;
  res: Response;
};

const ContentESI = (props: ContentProps) => {
  return (
    <Provider store={store}>
      <HomepageContent {...props} />
    </Provider>
  );
};

ContentESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref, isEcommerce } = usePdv(req);
  const preview = req?.cookies?.__next_preview_data;

  let content: ContentProps | null = null;

  if (ref) {
    content = (await fetchContent({
      contentType: 'homePdvPage',
      preview,
      context: {
        isEcommerce
      }
    })) as ContentProps;
  }

  return new Promise((resolve) => {
    if (res) {
      res.set('Cache-Control', 's-maxage=10800, max-age=10800');
      res.set('Surrogate-Control', 'content="ESI/1.0"');
    }

    resolve({
      ...props,
      ...content
    });
  });
};

export default ContentESI;
