import Layout from '@/components/organisms/layout';
import {
  Content as ContentfulContent,
  RendererComponents as ContentfulRenderComponents
} from '@/lib/contentful';
import { SurveyState } from '@/store/survey/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { finishSurveyStepsAction } from '@/store/survey/actions';
import {
  selectSurveyTranslationState,
  SurveyState as SurveyStateEnum
} from '@/store/survey/surveySelectors';
import usePageView from '@/lib/hooks/usePageView';
import SmartconsoLayout from './layout';
import Landing from './content/landing';
import Results from './content/results';
import Survey from './content/steps';

export type SmartconsoFormType = {
  footer: ContentfulContent;
};

enum FormState {
  LANDING = 'landing',
  SURVEY = 'survey',
  RESULT = 'result'
}

const Step: React.FC = () => {
  const dispatch = useDispatch();
  const finishSurveySteps = finishSurveyStepsAction(dispatch);
  const isPostReinit = useSelector(
    ({ survey }: { survey: SurveyState }) => survey.postReinit
  );
  const surveyState = useSelector(selectSurveyTranslationState);
  const pageView = usePageView();
  const [displaySurvey, setDisplaySurvey] = useState<FormState>(
    FormState.LANDING
  );

  useEffect(() => {
    if (isPostReinit) {
      setDisplaySurvey(FormState.LANDING);
    }
  }, [isPostReinit]);

  const goToSurvey = () => {
    setDisplaySurvey(FormState.SURVEY);
  };
  const goToResult = () => {
    setDisplaySurvey(FormState.RESULT);
    finishSurveySteps();
  };

  useEffect(() => {
    if (displaySurvey !== FormState.LANDING) {
      return;
    }
    switch (surveyState) {
      case SurveyStateEnum.CONTINUE:
        pageView.send('smartConso', {
          vPath: '/compte/conso_mieux/questionnaire_continuer'
        });

        break;
      case SurveyStateEnum.MODIFY:
        pageView.send('smartConso', {
          vPath: '/compte/conso_mieux/questionnaire_modifier'
        });
        break;
      case SurveyStateEnum.START:
        pageView.send('smartConso', {
          vPath: '/compte/conso_mieux/questionnaire_commencer'
        });
        break;

      default:
        pageView.send('smartConso', {});
        break;
    }
  }, [pageView, surveyState, displaySurvey]);

  switch (displaySurvey) {
    case FormState.LANDING:
      return (
        <Landing moveToNextStep={goToSurvey} isPostReinit={isPostReinit} />
      );
    case FormState.RESULT:
      return <Results />;
    case FormState.SURVEY:
    default:
      return <Survey moveToNextStep={goToResult} />;
  }
};

const SmartconsoForm = ({ footer }: SmartconsoFormType) => {
  return (
    <Layout
      className="smartconso-form"
      content={
        <div className="smartconso-form__content">
          <SmartconsoLayout>
            <Step />
          </SmartconsoLayout>
        </div>
      }
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
    />
  );
};

export default SmartconsoForm;
