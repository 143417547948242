import Button from '@/components/atoms/button';
import React, { useState } from 'react';
import './style.scss';
import classnames from 'classnames';

enum BtnPosition {
  TOP = 'top',
  BOTTOM = 'bottom'
}

type ShowHideListProps = {
  min?: number;
  max?: number;
  items: Array<any>;
  itemRenderer?: (item: any, key: string) => JSX.Element;
  btnClass?: string;
  listClass?: string;
  componentClass?: string;
  btnPosition?: BtnPosition;
  btnShowContent?: string | JSX.Element;
  btnHideContent?: string | JSX.Element;
  showAll?: boolean;
};

const defaultItemRenderer = (item: any, key: string) => {
  return <div key={key}>{item.toString()}</div>;
};

const ShowHideList = ({
  min = 1,
  max,
  items,
  itemRenderer,
  btnClass,
  listClass,
  componentClass,
  btnPosition = BtnPosition.BOTTOM,
  showAll = false,
  btnShowContent,
  btnHideContent
}: ShowHideListProps) => {
  const maxItems = max || items.length;
  const [state, setstate] = useState({
    showAll,
    items: showAll ? items.slice(0, maxItems) : items.slice(0, min)
  });

  const renderItem = itemRenderer || defaultItemRenderer;

  const showHide = () => {
    setstate((s) => {
      return {
        ...s,
        showAll: !s.showAll,
        items: s.showAll ? items.slice(0, min) : items.slice(0, maxItems)
      };
    });
  };

  const button = (
    <Button
      className={classnames('showHideList__btn', btnClass)}
      onClick={showHide}
    >
      <>
        {state.showAll && (btnHideContent || 'hide')}
        {!state.showAll && (btnShowContent || 'show')}
      </>
    </Button>
  );
  return (
    <div
      className={classnames(
        'showHideList',
        `showHideList--${btnPosition}`,
        componentClass
      )}
    >
      <div
        className={classnames('showHideList__wrapper', listClass, {
          'showHideList__wrapper--max': state.showAll,
          'showHideList__wrapper--min': !state.showAll
        })}
      >
        {state.items.map((item: any, index: number) => {
          const key = `item-${item.id || index}}`;
          return (
            <div className="showHideList__wrapper__items-i" key={key}>
              {renderItem(item, key)}
            </div>
          );
        })}
      </div>
      {maxItems > min && button}
    </div>
  );
};

export default ShowHideList;
