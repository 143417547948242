import genericPageNormalize from '../genericPage';
import { Content, NormalizeParams } from '..';

const normalize = ({
  data,
  includes,
  extras
}: NormalizeParams): LandingPage => {
  return genericPageNormalize({
    data,
    includes,
    extras: {
      ...extras,
      id: 'landingPage'
    }
  });
};

export default normalize;

type LandingPage = Content;
