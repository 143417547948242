import React from 'react';
import './style.scss';
import classnames from 'classnames';
import Cleave from 'cleave.js/react';

export type FormFidelityCardProps = {
  id?: string;
  className?: string;
  placeholder?: string;
  label?: string;
  name?: string;
  value?: string;
  onChange?: (value: string | null) => void;
  errorMessage?: string;
  message?: string;
};

const FormFidelityCard = ({
  id,
  className,
  placeholder,
  label,
  name,
  value,
  onChange,
  errorMessage,
  message
}: FormFidelityCardProps) => {
  return (
    <div
      className={classnames('formFidelityCard', className, {
        'formFidelityCard--hasError': !!errorMessage
      })}
    >
      {label && (
        <label htmlFor={id} className="formFidelityCard__label">
          {label}
        </label>
      )}
      <div className="formFidelityCard__inputs">
        <Cleave
          id={id}
          name={name}
          placeholder={placeholder}
          className="formFidelityCard__input"
          value={value ? value.split('').join(' / ') : undefined}
          options={{
            delimiter: ' / ',
            blocks: [4, 3, 4, 4, 4]
          }}
          onChange={(e) => {
            if (!onChange) {
              return;
            }

            const newValue = e.target.value;

            if (!newValue.length) {
              onChange(null);
            } else {
              onChange(newValue.split(' / ').join(''));
            }
          }}
        />
      </div>
      {errorMessage && (
        <div
          className="formFidelityCard__errorMessage"
          dangerouslySetInnerHTML={{ __html: errorMessage }}
        />
      )}
      {!errorMessage && message && (
        <div className="formFidelityCard__message">{message}</div>
      )}
    </div>
  );
};

export default FormFidelityCard;
