import React from 'react';
import './style.scss';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';

export type TagProps = {
  label: string;
  className?: string;
  close: () => void;
};

const Tag = ({ label, className, close }: TagProps) => {
  return (
    <div className={classnames(['tag', className])}>
      {label}
      <span onClick={close} className="tag__cross">
        <svg className="tag__cross__icon" height="8" width="8">
          <use xlinkHref={`${CommonSprite}#cross-light`} />
        </svg>
      </span>
    </div>
  );
};

export default Tag;
