import { Component, NormalizeParams } from '..';

export type HeadingMarketingProps = {
  title: string;
  textColor: string;
  background: string;
};

const normalize = ({
  data,
  includes
}: NormalizeParams): ComponentHeadingMarketingProps => {
  const backgroundImageFile =
    includes[data.backgroundImage?.sys?.id]?.fields?.file;
  const title = data.title || '';
  const textColor = data.textColor || '';
  const background = backgroundImageFile?.url || '';

  return {
    type: 'molecules',
    name: 'headingMarketing',
    props: {
      title,
      textColor,
      background
    }
  };
};

export default normalize;

export interface ComponentHeadingMarketingProps extends Component {
  props: HeadingMarketingProps;
}
