import React, { memo, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import CartSprite from '@/assets/images/sprites/cart.svg';
import { Offer } from '@/lib/model/productTypes';
import t from '@/lib/i18n';

type ProductStateProps = {
  currentOffer?: Offer;
};

const ProductState = ({ currentOffer }: ProductStateProps) => {
  const [toolTipIsLoaded, setToolTipIsLoaded] = useState(false);
  if (!currentOffer || !currentOffer?.state) {
    return null;
  }

  useEffect(() => {
    setToolTipIsLoaded((o) => !o);
  }, []);

  return (
    <div className="productDetail__state">
      {t('pdp.informations.state.label')}
      <div
        className="productDetail__state-label"
        data-tip
        data-for="productState"
        data-event-off="mouseleave"
        data-event="mouseenter click"
      >
        <span>{t(`pdp.informations.state.${currentOffer.state}`)} </span>
        <svg className="productDetail__state-icon" width={15} height={15}>
          <use xlinkHref={`${CartSprite}#info`} />
        </svg>
      </div>
      {toolTipIsLoaded && (
        <ReactTooltip
          id="productState"
          effect="solid"
          type="dark"
          textColor="white"
          className="productDetail__state-tooltip"
        >
          {t(`pdp.informations.state.${currentOffer.state}.tooltip`)}
        </ReactTooltip>
      )}
    </div>
  );
};

export default memo(ProductState);
