import React, { useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import Button from '@/components/atoms/button';
import { ReactComponent as ShoppingList } from '@/assets/images/icons/shoppingList.svg';
import ShoppingListApi from '@/lib/api/shoppingList';
import t from '@/lib/i18n';
import { useDispatch, useSelector } from 'react-redux';
import useClickOut from '@/lib/hooks/useClickOut';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { ListType } from '@/store/list/listReducer';
import FormInput from '@/components/atoms/formInput';
import { UPDATE_LIST } from '@/store/list/listActions';
import { AuthStateType } from '@/store/auth/authReducer';
import { getPdvRef } from '@/lib/hooks/usePdv';
import ProductModel from '@/lib/model/product';
import useEvent from '@/lib/hooks/useEvent';

export type LayerListProps = {
  close: () => void;
  dataProduct: ProductModel;
};

type ShoppingListType = ListType & {
  newIsSelected: boolean;
  newList: string | null;
  isAdding: boolean;
  isSaving: boolean;
};

const LayerList = ({ close, dataProduct }: LayerListProps) => {
  const pdvRef = getPdvRef();
  const [shoppingList, setShoppingList] = useState<ShoppingListType>({
    list: [],
    selected: null,
    newIsSelected: false,
    newList: null,
    isAdding: false,
    isSaving: false
  });
  const event = useEvent();
  const [clickOutRef, clickOutHandler] = useClickOut();
  const dispatch = useDispatch();
  const { userId, listStore } = useSelector(
    ({ auth, list }: { auth: AuthStateType; list: ListType }) => {
      return {
        userId: auth.user?.id,
        listStore: list
      };
    }
  );

  clickOutHandler(() => {
    close();
  });

  const addItemList = async (value: string) => {
    if (!userId) return;
    setShoppingList((sh) => ({ ...sh, newList: value, isAdding: true }));
    await ShoppingListApi.create(userId, value);
    const list = await ShoppingListApi.list(userId);
    const selected = parseInt(
      String(list.find((item) => item.name === value)?.id),
      10
    );
    setShoppingList((sh) => ({
      ...sh,
      newList: value,
      newIsSelected: !sh.newIsSelected,
      isAdding: false
    }));
    dispatch({
      type: UPDATE_LIST,
      payload: {
        list,
        selected
      }
    });
  };

  useEffect(() => {
    if (!listStore.list.length) return;
    setShoppingList((sh) => ({
      ...sh,
      list: listStore.list,
      selected: listStore.list[0].id,
      isAdding: false
    }));
  }, [listStore]);

  const saveList = async () => {
    if (!shoppingList.selected || !userId) return;
    setShoppingList((sh) => ({ ...sh, isSaving: true }));
    await ShoppingListApi.addProducts(userId, shoppingList.selected, pdvRef, [
      dataProduct
    ]);
    event.send('engagement', {
      type: 'addToShoppingList',
      productId: dataProduct.id
    });
    setShoppingList((sh) => ({ ...sh, isSaving: false }));
    close();
  };

  return (
    <div className="connectionList" ref={clickOutRef}>
      {!userId && (
        <>
          <ShoppingList className="connectionList__svgList" />
          {t('product.needConnect')}
          <button onClick={close} className="connectionList__cancelList">
            {t('common.cancel')}
          </button>
        </>
      )}
      {userId && (
        <>
          <div className="connectionList__content">
            {shoppingList.list.length > 0 &&
              shoppingList.list.map((item) => {
                return (
                  <div
                    onClick={() => {
                      setShoppingList((sh) => ({ ...sh, selected: item.id }));
                    }}
                    className="connectionList__item"
                    key={item.id}
                  >
                    <span
                      className={classnames('connectionList__check', {
                        'connectionList__check--selected':
                          shoppingList.selected === item.id
                      })}
                    >
                      <svg
                        width="22"
                        height="22"
                        className="connectionList__checkPicto"
                      >
                        <use xlinkHref={`${CommonSprite}#checkBold`} />
                      </svg>
                    </span>
                    {item.name}
                  </div>
                );
              })}
          </div>
          <div className="connectionList__item connectionList__add">
            {!shoppingList.newIsSelected && (
              <>
                <span
                  className="connectionList__new"
                  onClick={() => {
                    setShoppingList((sh) => ({ ...sh, newIsSelected: true }));
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    className="connectionList__addPicto"
                  >
                    <use xlinkHref={`${CommonSprite}#plus`} />
                  </svg>
                  {t('product.list.new')}
                </span>
              </>
            )}
            {shoppingList.newIsSelected && (
              <>
                <FormInput
                  isLoading={shoppingList.isAdding}
                  sendValue={addItemList}
                  buttonLabel={t('common.add')}
                  className="connectionList__input"
                />
              </>
            )}
          </div>
          <div className="connectionList__actions">
            <Button loading={shoppingList.isSaving} onClick={saveList}>
              {t('common.saved')}
            </Button>
            <Button isLink={true} onClick={close}>
              {t('common.cancel')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default LayerList;
