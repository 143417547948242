import React from 'react';
import './styles.scss';
import Link from '@/components/atoms/link';

type MyAccountTileProps = {
  icon: JSX.Element;
  title: string;
  description?: string;
  link: {
    label: string;
    href: string;
  };
  onClick?: () => void;
};

const MyAccountTileFid = ({
  icon,
  title,
  description,
  link,
  onClick
}: MyAccountTileProps) => {
  return (
    <Link className="myAccountTileFid" href={link.href} onClick={onClick}>
      <>
        <div className="myAccountTileFid__icon">{icon}</div>
        <div
          className="myAccountTileFid__title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {description && (
          <div className="myAccountTileFid__description">{description}</div>
        )}
      </>
    </Link>
  );
};

export default MyAccountTileFid;
