import React from 'react';
import t from '@/lib/i18n';
import { Content as ContentfulContent } from '@/lib/contentful';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import MyAccountTiles from '@/components/organisms/myAccount/tiles';
import MyAccountTile from '@/components/organisms/myAccount/tile';

export type UserInfoProps = {
  footer: ContentfulContent;
  pageView: string;
};

const UserInfo = ({ footer, pageView }: UserInfoProps) => {
  const contentPart = (
    <MyAccountTiles>
      <MyAccountTile
        icon={
          <svg width="49" height="33">
            <use xlinkHref={`${MyAccountSprite}#user`} />
          </svg>
        }
        title={t('myAccount.userInfo.tiles.details.title')}
        description={t('myAccount.userInfo.tiles.details.description')}
        link={{
          label: t('myAccount.userInfo.tiles.details.link'),
          href: '/account-management/my-info/my-details'
        }}
      />
      <MyAccountTile
        icon={
          <svg width="49" height="33">
            <use xlinkHref={`${MyAccountSprite}#store`} />
          </svg>
        }
        title={t('myAccount.userInfo.tiles.myStores.title')}
        description={t('myAccount.userInfo.tiles.myStores.description')}
        link={{
          label: t('myAccount.userInfo.tiles.myStores.link'),
          href: '/account-management/my-info/my-stores'
        }}
      />
      <MyAccountTile
        icon={
          <svg width="26" height="35">
            <use xlinkHref={`${MyAccountSprite}#pin`} />
          </svg>
        }
        title={t('myAccount.userInfo.tiles.myAddresses.title')}
        description={t('myAccount.userInfo.tiles.myAddresses.description')}
        link={{
          label: t('myAccount.userInfo.tiles.myAddresses.link'),
          href: '/account-management/my-info/my-adresses'
        }}
      />
      <MyAccountTile
        icon={
          <svg width="49" height="33">
            <use xlinkHref={`${MyAccountSprite}#filters`} />
          </svg>
        }
        title={t('myAccount.userInfo.tiles.myPersonalData.title')}
        link={{
          label: t('myAccount.userInfo.tiles.myPersonalData.link'),
          href: '/account-management/my-info/my-personal-data'
        }}
      />
      <MyAccountTile
        icon={
          <svg width="36" height="28">
            <use xlinkHref={`${MyAccountSprite}#press`} />
          </svg>
        }
        title={t('myAccount.userInfo.tiles.myNewsletters.title')}
        link={{
          label: t('myAccount.userInfo.tiles.myNewsletters.link'),
          href: '/account-management/my-info/my-newsletters'
        }}
      />
    </MyAccountTiles>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.userInfo.long')}
      enabledId="userInfo"
      content={contentPart}
      pageView={pageView}
      footer={footer}
    />
  );
};

export default UserInfo;
