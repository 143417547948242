import t from '@/lib/i18n';

/* eslint max-classes-per-file: off */

export interface IException {
  getTrad(p?: string): string;
}

export class UnknownRecipientException extends Error implements IException {
  tradId = 'myAccount.fidelity.myStickers.share.error.unknownDest';

  constructor(message = 'Unknown recipient exception.') {
    super(message);
  }

  getTrad(param?: string) {
    return t(this.tradId, {
      '%card%': param
    });
  }
}
export class InvalidRecipientException extends Error implements IException {
  tradId = 'myAccount.fidelity.myStickers.share.error.invalidCard';

  constructor(message = 'Invalid recipient exception.') {
    super(message);
  }

  getTrad(param?: string) {
    return t(this.tradId, {
      '%card%': param
    });
  }
}
export class QuantityExceededException extends Error implements IException {
  tradId = 'myAccount.fidelity.myStickers.share.error.qtyExceeded';

  constructor(message = 'Sticker quantity exceeded exception.') {
    super(message);
  }

  getTrad() {
    return t(this.tradId);
  }
}
export class BadRequestException extends Error implements IException {
  tradId = 'myAccount.fidelity.myStickers.share.error.badRequest';

  constructor(message = 'Bad request exception.') {
    super(message);
  }

  getTrad() {
    return t(this.tradId);
  }
}
