import React, { useEffect, useState } from 'react';
import PdpSprite from '@/assets/images/sprites/pdp.svg';
import t from '@/lib/i18n';
import classnames from 'classnames';

export type LabelsProps = {
  repairIndex: { id: string; value: number };
  energyClass: string;
  anchorLegalLabel: string | undefined;
};
type StateLabelType = string | undefined;

const LegalLabels = ({
  repairIndex,
  energyClass,
  anchorLegalLabel
}: LabelsProps) => {
  const [label, setLabel] = useState<StateLabelType>(undefined);
  const onClickLabel = (flag: string) => {
    setLabel(flag);
  };

  useEffect(() => {
    if (energyClass) {
      setLabel('energyClass');
    } else if (repairIndex) {
      setLabel('repairIndex');
    }
  }, [repairIndex, energyClass]);

  useEffect(() => {
    if (anchorLegalLabel) {
      setLabel(anchorLegalLabel);
    }
  }, [anchorLegalLabel]);

  return (
    <section
      id="toKnowMore"
      className="productDetail__section productDetail__composition labelSection"
    >
      <div className="container container--md">
        <h2 className="productDetail__section_title">
          {t('pdp.informations.toKnowMore.title')}
        </h2>
        <div className="labelDescription">
          <div className="productDetail__labels">
            <div className="nutritionSection__content__labels">
              {energyClass && (
                <div
                  className={classnames(
                    [
                      'nutritionSection__content__labels__item',
                      'nutritionSection__content__labels__item--energyClass'
                    ],
                    {
                      'nutritionSection__content__labels__item--selected':
                        label === 'energyClass'
                    }
                  )}
                  key="energyClass"
                  onClick={() => onClickLabel('energyClass')}
                >
                  <svg width="90px" height="100%">
                    <use
                      xlinkHref={`${PdpSprite}#energyClass${energyClass.replaceAll(
                        '+',
                        ''
                      )}`}
                    />
                  </svg>
                </div>
              )}
              {repairIndex && (
                <div
                  className={classnames(
                    [
                      'nutritionSection__content__labels__item',
                      'nutritionSection__content__labels__item--repairIndex'
                    ],
                    {
                      'nutritionSection__content__labels__item--selected':
                        label === 'repairIndex'
                    }
                  )}
                  key="repairIndex"
                  onClick={() => onClickLabel('repairIndex')}
                >
                  <div className="productDetail__repairIndex__value">
                    {String(repairIndex.value).replace('.', ',')}
                  </div>
                  <svg width="92px" height="45px">
                    <use
                      xlinkHref={`${PdpSprite}#repairIndex-${repairIndex.id}`}
                    />
                  </svg>
                  <span className="productDetail__repairIndex__ten">
                    {t('product.ten')}
                  </span>
                  <span className="nutritionSection__content__labels__item__indiceReparabilite">
                    {t('product.repairabilityIndex')}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="productDetail__description">
            <div className="productDetail__description__title">
              <div>
                {label === 'energyClass' &&
                  t('pdp.informations.energyClass.title')}
              </div>
              <div>
                {label === 'repairIndex' &&
                  t('pdp.informations.repairIndex.title')}
              </div>
            </div>
            {label === 'energyClass' && (
              <div
                dangerouslySetInnerHTML={{
                  __html: t('pdp.informations.energyClass.desc')
                }}
              />
            )}
            <div>
              {label === 'repairIndex' &&
                t('pdp.informations.repairIndex.desc')}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LegalLabels;
