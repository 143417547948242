import React from 'react';
import Layout from '@/components/organisms/layout';
import ContentForgottenPassword from './content';

type ForgottenPasswordProps = {
  pageView?: string;
};

const ForgottenPassword = ({ pageView }: ForgottenPasswordProps) => {
  const contentPart = <ContentForgottenPassword />;

  return (
    <Layout
      className="forgottenPassword"
      content={contentPart}
      pageView={pageView}
    />
  );
};

export default ForgottenPassword;
