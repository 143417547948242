import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { findCategory } from '@/lib/utils/category';
import { getPdvRef } from '@/lib/hooks/usePdv';
import { NavStateType } from '@/store/nav/navReducer';
import { FamilyType } from '@/lib/api/product';
import ProductModel from '@/lib/model/product';
import Button from '@/components/atoms/button';
import Link from '@/components/atoms/link';
import { ReactComponent as CrossIcon } from '@/assets/images/icons/cross.svg';
import Product from '@/components/organisms/product';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_SEARCH_HISTORY,
  CLEAN_SEARCH_HISTORY,
  REMOVE_SEARCH_HISTORY
} from '@/store/common/commonActions';
import { setSelectedProduct } from '@/store/common/actions/common';
import { SearchResultsType } from './type';
import SearchHistoryModal from './history';

type SearchLayerProps = {
  id?: string;
  className: string;
  clickOutRef: any;
  searchResults: SearchResultsType;
  event: any;
  redirectToResults: () => void;
  handleSearch: (handleEvent: any) => void;
  keyword: string;
  clearKeyword: () => void;
  setIsOpen: (value: boolean) => void;
  resultRetrieved: boolean;
};

const SearchModal = ({
  id,
  className,
  clickOutRef,
  searchResults,
  event,
  redirectToResults,
  handleSearch,
  keyword,
  clearKeyword,
  setIsOpen,
  resultRetrieved
}: SearchLayerProps) => {
  const dispatch = useDispatch();
  const pdvRef = getPdvRef();
  const categories = useSelector(
    ({ nav }: { nav: NavStateType }) => nav.mainCategories[pdvRef]?.data
  );
  const highlightKeyword = /^\s+|\s+$/g;
  const highlightSuggestion = (result: string) => {
    return (
      <span className="search__result__highlight">
        {result.replace(highlightKeyword, '')}
      </span>
    );
  };

  const getCategoryFamily = async (idFamily: number) => {
    const categoryLink =
      findCategory(idFamily?.toString(), categories)?.link ?? '';
    if (categoryLink) {
      window.location.href = categoryLink;
    }
  };

  const highlightFamilyResult = new RegExp(/(.*)\s*>\s*(.*)/gi);
  const highlightFamily = (result: string) => {
    return result.replace(
      highlightFamilyResult,
      '<span>$1</span> &gt; <span class="search__result__highlight">$2</span>'
    );
  };

  const getListFamilies = (nb: number, list: Array<FamilyType>) => {
    return list.slice(0, nb);
  };

  const getListProducts = (nb: number, list: Array<ProductModel>) => {
    return list.slice(0, nb);
  };

  const deleteHistory = (index: number) => {
    dispatch({ type: REMOVE_SEARCH_HISTORY, payload: { index } });
  };
  const cleanHistory = () => {
    dispatch({ type: CLEAN_SEARCH_HISTORY });
  };

  return (
    <div id={id} className={`search-modal ${className}`}>
      <div className="search-modal__results" ref={clickOutRef}>
        <div className="search-modal__header">
          <div className="search-modal__header__content">
            <CrossIcon
              className="search-modal__close fill--black"
              height="22"
              width="22"
              onClick={() => {
                clearKeyword();
                setIsOpen(false);
              }}
            />
            <div className="search-modal__header__input">
              <input
                type="text"
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    dispatch({
                      type: ADD_SEARCH_HISTORY,
                      payload: {
                        value: keyword
                      }
                    });
                    redirectToResults();
                  }
                }}
                placeholder={t('header.search.placeholder')}
                aria-label={t('header.search.placeholder')}
                onChange={handleSearch}
                value={keyword}
              />
              {!!keyword && (
                <CrossIcon
                  className="search-modal__close fill--black"
                  height="12"
                  width="12"
                  onClick={clearKeyword}
                />
              )}
            </div>
          </div>
        </div>
        {keyword?.length > 2 &&
          resultRetrieved &&
          !searchResults.suggestions.length &&
          !searchResults.families.length &&
          !searchResults.products.length && (
            <div className="search-modal__no-result">
              <div>
                <svg className="noSearchIcon">
                  <use xlinkHref={`${CommonSprite}#search-no-results`} />
                </svg>
              </div>
              <p
                className="title"
                dangerouslySetInnerHTML={{
                  __html: t('search.isEmpty', { '%keyword%': keyword })
                }}
              />
            </div>
          )}
        <div className="search-modal__results__content">
          {!!keyword && resultRetrieved && (
            <>
              <div className="search-modal__results__left">
                {!!searchResults.suggestions.length && (
                  <div className="search-modal__results__suggestions">
                    <p className="search-modal__results__title margin-right">
                      {t('header.search.suggestions')}
                    </p>
                    <div className="search-modal__results__list">
                      {searchResults.suggestions.map((result, index) => {
                        const key = `result-${index}`;
                        return (
                          <Link
                            href={`/search/${encodeURI(result)}`}
                            className="search__result border"
                            key={key}
                            onClick={() => {
                              event.send('search', {
                                type: 'autocomplete',
                                keyword: result
                              });
                            }}
                          >
                            <span>{highlightSuggestion(result)}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                )}

                {!!searchResults.families.length && (
                  <div className="search-modal__results__families">
                    <p className="search-modal__results__title margin-right">
                      {t('header.search.families')}
                    </p>
                    <div className="search-modal__results__list responsive">
                      {getListFamilies(3, searchResults.families).map(
                        (result, index) => {
                          const key = `result-${index}`;
                          return (
                            <Link
                              className="search__result border"
                              key={key}
                              onClick={async () => {
                                await getCategoryFamily(result.id);
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: highlightFamily(result.label)
                                }}
                              />
                            </Link>
                          );
                        }
                      )}
                    </div>
                    <div className="search-modal__results__list desktop">
                      {getListFamilies(4, searchResults.families).map(
                        (result, index) => {
                          const key = `result-${index}`;
                          return (
                            <Link
                              className="search__result border"
                              key={key}
                              onClick={async () => {
                                await getCategoryFamily(result.id);
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: highlightFamily(result.label)
                                }}
                              />
                            </Link>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>

              {!!searchResults.products.length && (
                <div className="search-modal__results__products">
                  <p className="search-modal__results__title margin-left border">
                    {`${t('header.search.products')}"${keyword}"`}
                  </p>
                  <div className="search-modal__results__list border responsive">
                    {getListProducts(2, searchResults.products).map(
                      (result, index) => {
                        const key = `result-${index}`;
                        const bestOfferProduct = result.bestOffer;
                        return (
                          <Product
                            key={key}
                            productClassName={result.informations.title}
                            className="search__result"
                            data={result}
                            isSearch
                            sellerId={bestOfferProduct?.seller.id}
                            initScoreClassName="search__result__initScore"
                            keyword={keyword}
                            position={index + 1}
                            isHorizontalLabel
                            beforeRedirect={() => {
                              dispatch(setSelectedProduct(result));
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                  <div className="search-modal__results__list border desktop">
                    <>
                      {getListProducts(4, searchResults.products).map(
                        (result, index) => {
                          const key = `result-${index}`;
                          const bestOfferProduct = result.bestOffer;
                          return (
                            <Product
                              key={key}
                              productClassName={result.informations.title}
                              className="search__result"
                              data={result}
                              isSearch
                              sellerId={bestOfferProduct?.seller.id}
                              initScoreClassName="search__result__initScore desktop"
                              keyword={keyword}
                              position={index + 1}
                              isHorizontalLabel
                              beforeRedirect={() => {
                                dispatch(setSelectedProduct(result));
                              }}
                            />
                          );
                        }
                      )}
                    </>
                  </div>
                  <div className="search-modal__results__footer border">
                    <Button
                      className="search-modal__results__button"
                      id="search-modal-button"
                      label={t('header.search.results.allproducts')}
                      color="secondary"
                      onClick={redirectToResults}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {!keyword && (
            <SearchHistoryModal
              deleteHistory={deleteHistory}
              cleanHistory={cleanHistory}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
