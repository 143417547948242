import React from 'react';
import FormInput from '@/components/atoms/formInput';
import FormTextArea from '@/components/atoms/formTextArea';
import FormFile from '@/components/atoms/formFile';
import { FieldErrors } from 'react-hook-form';
import t from '@/lib/i18n';
import './style.scss';
import OrderTypeSelector from '../orderTypeSelector';
import { FieldFormProps, OrderTypeProps } from '../../formsTemplate';

import StoreAutocompleteInput from '../storeAutocompleteInput';

export type RenderFieldProps = {
  field: FieldFormProps;
  register: any;
  errors: FieldErrors;
  isMandatoryField: boolean;
  setValue: any;
  orderTypeSelected: OrderTypeProps | null;
  setOrderType: (orderType: OrderTypeProps) => void;
};

const RenderField = ({
  field,
  register,
  errors,
  isMandatoryField,
  setValue,
  orderTypeSelected,
  setOrderType
}: RenderFieldProps) => {
  if (field.type === 'store') {
    return (
      <StoreAutocompleteInput
        field={field}
        register={register}
        setValue={setValue}
        errors={errors}
        isMandatoryField={isMandatoryField}
      />
    );
  }
  switch (field.tag) {
    case 'input':
      return (
        <>
          <label htmlFor={field.name} className="renderField__label">
            {`${field.label && t(field.label)}${isMandatoryField ? '*' : ''}`}
          </label>
          <FormInput
            className="renderField__inputWrapper"
            name={field.name}
            id={field.name}
            type={field.type}
            errorMessage={t(errors[field.name]?.message)}
            ref={register}
            maxLength={field.maxLength}
          />
        </>
      );
    case 'inputFile':
      return (
        <FormFile
          className="renderField__inputWrapper"
          id={field.name}
          name={field.name}
          errorMessage={t(errors[field.name]?.message)}
          label={field.label ? t(field.label) : ''}
          ref={register}
          clearValue={() => setValue(field.name, '')}
        />
      );
    case 'textarea':
      return (
        <>
          <label htmlFor={field.name} className="renderField__label">
            {`${field.label && t(field.label)}${isMandatoryField && '*'}`}
          </label>
          <FormTextArea
            id={field.name}
            name={field.name}
            className="renderField__textAreaWrap"
            errorMessage={t(errors[field.name]?.message)}
            ref={register}
          />
        </>
      );
    case 'ordersType':
      return (
        <OrderTypeSelector
          field={field}
          setOrderType={setOrderType}
          orderTypeSelected={orderTypeSelected}
          ref={register}
          errors={errors}
        />
      );
    default:
      return null;
  }
};

export default RenderField;
