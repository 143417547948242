import React from 'react';
import { StoreInfo } from '@/lib/api/pdv/types';
// import CommonSprite from '@/assets/images/sprites/common.svg';
import crossSprite from '@/assets/images/icons/cross.svg';

type SelectedPdvProps = {
  store: StoreInfo;
  onDelete: () => void;
};

const SelectedPdv = ({ store, onDelete }: SelectedPdvProps) => {
  return (
    <div className="storeAutocompleteInput__selected">
      <div className="storeAutocompleteInput__contentInfo">
        <p className="storeAutocompleteInput__contentInfo__contentTitle">
          {`${store.name} ${store.townLabel}`}
        </p>
        <p>{`${store.address}, ${store.zipCode} ${store.townLabel}`}</p>
      </div>
      <button onClick={onDelete}>
        <img src={crossSprite} height="12" width="12" alt="cross" />
      </button>
    </div>
  );
};

export default SelectedPdv;
