import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';
import './style.scss';
import CartSprite from '@/assets/images/sprites/cart.svg';
import t from '@/lib/i18n';
import Loader from '@/components/atoms/loader';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { getPdvRef } from '@/lib/hooks/usePdv';
import pdvApi from '@/lib/api/pdv';
import {
  Address,
  Addresses,
  BillingAddress,
  DeliveryAddress
} from '@/lib/api/address/types';
import { StateType as CartStateType } from '@/store/cart/types';
import slotsApi from '@/lib/api/slots';
import { ADD_SLOTS } from '@/store/cart/cartActions';
import ModifyAddresses from '@/components/organisms/myAccount/addresses';
import { Informations as UserInformations } from '@/lib/api/userInformation/types';
import useEvent from '@/lib/hooks/useEvent';
import { DELIVERY_TYPE_STORE_PICK_UP } from '@/lib/api/order/mappings';
import CreateAddress from '../../myAccount/addresses/create';
import Edit from '../../myAccount/addresses/edit';

type DefaultAddress = {
  delivery?: Address;
  billing?: Address;
  pdv:
    | {
        address1: string;
        address2: string;
        latitude: number;
        longitude: number;
        city: string;
        zipCode: string;
      }
    | undefined;
};

type Layer = {
  isActive: boolean;
  mode: string | null;
};

const CartAddresses = ({
  addressesList,
  deliveryType,
  modifyIsEnable = true,
  changeAddress = false,
  userInfo,
  creationIfEmpty = false,
  onListChanged,
  isPayment = false,
  isMKPDelivery = false,
  onCloseCallback = () => {}
}: {
  addressesList: Addresses | null;
  deliveryType: string;
  modifyIsEnable?: boolean;
  changeAddress?: boolean;
  creationIfEmpty?: boolean;
  userInfo?: UserInformations;
  onListChanged?: () => void | Promise<void>;
  onCloseCallback?: () => void;
  isPayment?: boolean;
  isMKPDelivery?: boolean;
}) => {
  const [addresses, setAddresses] = useState<DefaultAddress | null>(null);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const event = useEvent();
  // TODO :
  // const { userId, cartStore } = useShallowEqualSelector(({ auth, cart }) => {
  //   return {
  //     userId: auth.user?.id,
  //     cartStore: cart
  //   };
  // }
  const { userId, cartStore } = useSelector(
    ({ auth, cart }: { auth: AuthStateType; cart: CartStateType }) => {
      return {
        userId: auth.user?.id,
        cartStore: cart
      };
    }
  );
  const [layer, setLayer] = useState<Layer | null>({
    isActive: false,
    mode: null
  });

  const updateSlots = useCallback(
    async (cart) => {
      const pdvRef = getPdvRef();

      try {
        const {
          data,
          vouchersCodeActive,
          discountCodeActive,
          mkpPackages,
          defaultPayments
        } = await slotsApi.getAll(cart, pdvRef, userId);

        dispatch({
          type: ADD_SLOTS,
          payload: {
            data,
            vouchersCodeActive,
            discountCodeActive,
            mkpPackages,
            defaultPayments
          }
        });
      } catch (error: any) {
        window.location.href = '/orders/cart';
      }
    },
    [dispatch, userId]
  );

  const syncCartAddresses = useCallback(
    async (newAddress) => {
      if (newAddress?.type === 'billing') {
        setAddresses((elem) => ({
          ...elem,
          billing: newAddress,
          pdv: addresses?.pdv
        }));
      } else {
        setAddresses((elem) => ({
          ...elem,
          delivery: newAddress,
          pdv: addresses?.pdv
        }));
      }
      !isMKPDelivery && (await updateSlots(cartStore));
      onCloseCallback();
      setLayer({
        isActive: false,
        mode: null
      });
      if (isPayment) {
        const isTherePDV = cartStore.subCarts.find((s) => s.type === 'PDV');

        window.location.href = isTherePDV
          ? '/orders/slots'
          : '/orders/delivery';
      }
    },
    [
      addresses?.pdv,
      cartStore,
      updateSlots,
      isPayment,
      onCloseCallback,
      isMKPDelivery
    ]
  );

  const onCancel = useCallback(() => {
    onCloseCallback();
    setLayer({
      isActive: false,
      mode: null
    });
  }, [onCloseCallback]);

  useEffect(() => {
    (async () => {
      const pdvRef = getPdvRef();
      try {
        const pdvAddress = await pdvApi.getPdvInfo(pdvRef);

        if (!addressesList) return;

        setAddresses((a) => ({
          pdv: pdvAddress?.address,
          delivery: addressesList.delivery.find(
            (item: Address) => item.isDefault
          ),
          billing: addressesList.billing.find((item: Address) => item.isDefault)
        }));
      } catch (error: any) {
        // Todo: handle user error display if not pdv info
      }
    })();
  }, [addressesList]);
  useEffect(() => {
    if (!layer?.isActive) {
      document.documentElement.style.overflow = 'visible';
    } else {
      document.documentElement.style.overflow = 'hidden';
    }
    if (!layer?.isActive && changeAddress) {
      setLayer({
        isActive: true,
        mode: 'multi'
      });
    }
  }, [changeAddress, layer]);
  return (
    <>
      {layer?.isActive && (
        <div className="modifyLayer">
          <div className="modifyLayer__container">
            <button
              onClick={() => {
                onCloseCallback();
                setLayer({
                  isActive: false,
                  mode: null
                });
              }}
              className="modifyLayer__back"
            >
              <svg className="modifyLayer__picto" width="15" height="15">
                <use xlinkHref={`${CommonSprite}#big-arrow-left`} />
              </svg>
              {t('cart.addresses.back')}
            </button>

            {creationIfEmpty &&
              !addressesList?.delivery?.length &&
              layer?.mode !== 'billing' &&
              userInfo && (
                <CreateAddress
                  isMKPDelivery={isMKPDelivery}
                  userInfo={userInfo}
                  pageView="slotAddAddress"
                  onCancel={() => {
                    onCloseCallback();
                    if (onListChanged) {
                      onListChanged();
                    }
                    setLayer({
                      isActive: false,
                      mode: null
                    });
                  }}
                  type="delivery"
                />
              )}

            {(!creationIfEmpty || !!addressesList?.delivery?.length) &&
              layer?.mode === 'multi' && (
                <ModifyAddresses
                  validate={(element, billing) => {
                    const newAddress: Address = {
                      id: element.id,
                      name: element.name,
                      isDefault: element.isDefault,
                      lastName: element.lastName,
                      firstName: element.firstName,
                      mobilePhone: element.mobilePhone,
                      phone: element.phone,
                      streetName: element.streetName,
                      additionalStreetName: element.additionalStreetName,
                      zipCode: element.zipCode,
                      city: element.city,
                      country: element.country
                    };
                    setAddresses((elem) => ({
                      ...elem,
                      delivery: newAddress,
                      billing: billing || elem?.billing,
                      pdv: addresses?.pdv
                    }));
                    onCancel();
                  }}
                  isSelectable={true}
                  pageViewCreate="slotAddAddress"
                  pageViewModify="slotModifyAddress"
                />
              )}
            {userId && (
              <div ref={formRef}>
                {layer?.mode === 'billing' &&
                  addresses?.billing &&
                  !!addressesList?.billing?.length && (
                    <Edit
                      userId={userId}
                      address={
                        {
                          ...addresses.billing,
                          type: 'billing'
                        } as BillingAddress
                      }
                      onCancel={onCancel}
                      callback={syncCartAddresses}
                    />
                  )}
                {layer?.mode === 'delivery' &&
                  addresses?.delivery &&
                  !!addressesList?.delivery?.length && (
                    <Edit
                      userId={userId}
                      address={
                        {
                          ...addresses.delivery,
                          type: 'delivery'
                        } as DeliveryAddress
                      }
                      onCancel={onCancel}
                      callback={syncCartAddresses}
                      pageView="slotModifyAddress"
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      )}
      {!addresses && <Loader size="medium" />}
      {addresses && (
        <div className="cartAddresses">
          {DELIVERY_TYPE_STORE_PICK_UP.includes(deliveryType) &&
            addresses?.pdv && (
              <div className="cartInfo">
                <svg width="25" height="25">
                  <use xlinkHref={`${CommonSprite}#store`} />
                </svg>
                <div className="cartInfo__content">
                  <div className="cartInfo__title">
                    {t('cart.slot.address')}
                  </div>
                  <div className="cartInfo__item">
                    {t('common.name')} {addresses.pdv?.city}
                  </div>
                  <div className="cartInfo__item">
                    {addresses.pdv?.address1}
                  </div>
                  {addresses.pdv?.address2 && (
                    <div className="cartInfo__item">
                      {addresses.pdv?.address2}
                    </div>
                  )}
                  <div className="cartInfo__item">
                    {addresses.pdv?.zipCode} {addresses.pdv?.city}
                  </div>
                </div>
              </div>
            )}

          {(deliveryType === 'delivery' ||
            (cartStore.subCarts.some((subCart) => subCart.type === 'MKP') &&
              (isPayment || isMKPDelivery))) &&
            addresses?.delivery && (
              <div className="cartInfo">
                <svg className="cartInfo__picto" height="25" width="25">
                  <use xlinkHref={`${CartSprite}#delivery`} />
                </svg>
                <div className="cartInfo__content">
                  <div className="cartInfo__title">
                    {t('cart.info.myAddresses.delivery')}
                  </div>
                  <div className="cartInfo__item">
                    {addresses.delivery?.lastName}{' '}
                    {addresses.delivery?.firstName}
                  </div>
                  <div className="cartInfo__item">
                    {addresses.delivery?.streetName}
                  </div>
                  {addresses.delivery?.additionalStreetName && (
                    <div className="cartInfo__item">
                      {addresses.delivery.additionalStreetName}
                    </div>
                  )}
                  <div className="cartInfo__item">
                    {addresses.delivery?.zipCode} {addresses.delivery?.city}
                  </div>
                </div>
                {modifyIsEnable && (
                  <button
                    onClick={() => {
                      setLayer({
                        isActive: true,
                        mode: 'delivery'
                      });
                      event.send('checkout', {
                        type: 'modifyAddress',
                        mode: 'livraison'
                      });
                    }}
                    type="button"
                    className="cartInfo__modify"
                    id="btn-cartInfoModifyAddress"
                  >
                    {t('cart.slot.modify')}
                  </button>
                )}
              </div>
            )}

          {addresses?.billing && (
            <div className="cartInfo">
              <svg className="cartInfo__picto" width="25" height="25">
                <use xlinkHref={`${CartSprite}#file`} />
              </svg>
              <div className="cartInfo__content">
                <div className="cartInfo__title">
                  {t('myAccount.myAddresses.billing.title')}
                </div>
                <div className="cartInfo__item">
                  {addresses.billing?.lastName} {addresses.billing?.firstName}
                </div>
                <div className="cartInfo__item">
                  {addresses.billing?.streetName}
                </div>
                {addresses.billing?.additionalStreetName && (
                  <div className="cartInfo__item">
                    {addresses.billing.additionalStreetName}
                  </div>
                )}
                <div className="cartInfo__item">
                  {addresses.billing?.zipCode} {addresses.billing?.city}
                </div>
              </div>
              {modifyIsEnable && (
                <button
                  onClick={() => {
                    setLayer({
                      isActive: true,
                      mode: 'billing'
                    });
                    event.send('checkout', {
                      type: 'modifyAddress',
                      mode: 'facturation'
                    });
                  }}
                  type="button"
                  className="cartInfo__modify"
                  id="btn-cartInfoModifyAddress"
                >
                  {t('cart.slot.modify')}
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CartAddresses;
