import React, { useEffect, memo } from 'react';
import { Content as ContentfulContent } from '@/lib/contentful';
import { useSelector } from 'react-redux';
import usePageView from '@/lib/hooks/usePageView';
import usePdv from '@/lib/hooks/usePdv';
import { CardCreationStateType, STATUS } from '@/store/cardCreation/reducer';
import CGUStep from './cguStep';
import InProgressStep from './inProgressStep';
import GenericStep from './genericStep';

import './style.scss';

export type StepsProps = {
  content: ContentfulContent;
};

const Steps = ({ content }: StepsProps) => {
  const pageView = usePageView();

  const { status } = useSelector(
    ({ cardCreation }: { cardCreation: CardCreationStateType }) => {
      return {
        status: cardCreation.status
      };
    }
  );

  useEffect(() => {
    const { ref } = usePdv();
    pageView.send(`FidelityCreation_${status}`, {
      pdvRef: ref
    });
  }, [pageView, status]);

  const stepsView = {
    [STATUS.CGU]: <CGUStep content={content} />,
    [STATUS.IN_PROGRESS]: <InProgressStep />,
    [STATUS.SUCCESS]: <GenericStep screenId="ecranCarteOk" />,
    [STATUS.ERROR]: <GenericStep screenId="ecranCarteErreur" />,
    [STATUS.TIMEOUT]: <GenericStep screenId="ecranCreationEnCoursTimeout" />,
    [STATUS.USER_IS_PRO]: <GenericStep screenId="ecranProNonAutorise" />,
    [STATUS.MISSING_USER_INFOS]: (
      <GenericStep screenId="ecranInformationsInvalides" />
    )
  };

  return <div className="steps">{stepsView[status]}</div>;
};

export default memo(Steps);
