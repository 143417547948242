import Title from '@/components/atoms/title';
import React, { memo } from 'react';
import './style.scss';

export type HeadingProps = {
  title: string;
  subTitle?: string;
};

const Heading = ({ title, subTitle }: HeadingProps) => (
  <div className="heading">
    <div className="heading__container">
      <div className="heading__img" />
    </div>
    <div className="heading__text">
      <Title tag="h1" className="heading__title" label={title} />
      {subTitle && (
        <Title tag="h2" className="heading__title" label={subTitle} />
      )}
    </div>
  </div>
);

export default memo(Heading);
