import CartSprite from '@/assets/images/sprites/cart.svg';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Link from '@/components/atoms/link';
import PreferencesApi from '@/lib/api/preferences';
import t from '@/lib/i18n';
import logger from '@/lib/logger/base';
import { selectUser } from '@/store/auth/authSelectors';
import { passSurveyFirstStepsAction } from '@/store/survey/actions';
import {
  selectHasFilledMandatoriesQuestions,
  selectSurvey,
  selectHasFinishedSurvey
} from '@/store/survey/surveySelectors';
import classnames from 'classnames';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ConfirmBackModal from '../../content/Modal/modalGeneric';
import Legals from '../legals';
import './style.scss';

type NavigationProps = {
  classname?: string;
  title?: string;
  subTitle?: string;
};

const Navigation = ({ classname, title, subTitle }: NavigationProps) => {
  const dispatch = useDispatch();
  const validateFirstStep = passSurveyFirstStepsAction(dispatch);
  const user = useSelector(selectUser, shallowEqual);
  const survey = useSelector(selectSurvey, shallowEqual);
  const hasFilledMandatoriesQuestions = useSelector(
    selectHasFilledMandatoriesQuestions
  );
  const hasFinishedSurvey = useSelector(selectHasFinishedSurvey);

  const [open, setOpen] = useState(false);
  const [confirmInProgress, setConfirmInProgress] = useState(false);

  const onBackClick = () => setOpen(true);
  const onRefuse = () => setOpen(false);
  const onConfirm = async () => {
    setConfirmInProgress(true);
    try {
      // solve the edge case when the user come back just after the third question
      if (survey.responses.length >= 3) {
        validateFirstStep();
      }
      if (hasFilledMandatoriesQuestions && user?.id) {
        await PreferencesApi.update(user.id, survey);
      }
    } catch (error: any) {
      logger.error({
        message: 'Unable to save the survey responses',
        error,
        context: {
          survey
        }
      });
    } finally {
      const isSimpleUrl = !document.location.href.endsWith('#');
      const returnBack = isSimpleUrl ? 1 : 2;
      if (document.referrer) {
        const { hostname } = new URL(document.referrer);
        if (
          document.referrer.split(hostname)[1].includes('connexion?redirect=')
        ) {
          window.history.go(-(returnBack + 1));
        } else if (document.referrer.endsWith('smart-conso')) {
          window.history.go(-3);
        } else {
          window.history.go(-returnBack);
        }
      } else {
        window.history.go(-returnBack);
      }
    }
  };
  const onBackClickFromFinish = () => {
    window.location.href = '/account-management/smart-conso';
  };
  return (
    <div className={classnames(classname, 'navigation-header')}>
      <a
        className="navigation-header__back"
        target="_blank"
        onClick={hasFinishedSurvey ? onBackClickFromFinish : onBackClick}
      >
        <svg className="fill--white" height="20px" width="20px">
          <use xlinkHref={`${CommonSprite}#arrow-left`} />
        </svg>
        <div className="link-label left">{t('smartconso.header.back')}</div>
      </a>
      <div className="navigation-header__titles">
        <h1 className="navigation-header__titles__title">{title}</h1>
        <h2 className="navigation-header__titles__subTitle">{subTitle}</h2>
      </div>
      <Link
        target="_blank"
        href="/sign/top-deals/FAQ-intermarche#PngxgVWm1ljrexDXssAfv"
        className="navigation-header__info"
      >
        <div className="link-label right">{t('smartconso.header.info')}</div>
        <svg className="fill--white" height="20px" width="20px">
          <use xlinkHref={`${CartSprite}#info`} />
        </svg>
      </Link>
      <Legals classname="legals-desktop" />
      <ConfirmBackModal
        open={open}
        closeModal={onRefuse}
        closeLabel={t('smartconso.backModal.cancel')}
        body={
          <p className="genericModal__content">
            {hasFilledMandatoriesQuestions
              ? t('smartconso.backModal.body.info')
              : t('smartconso.backModal.body.warning')}
          </p>
        }
        validate={onConfirm}
        validateLabel={t('smartconso.backModal.confirm')}
        validateLoading={confirmInProgress}
        title={t('smartconso.backModal.title')}
        pageView="ModalConfirmBack"
      />
    </div>
  );
};

export default Navigation;
