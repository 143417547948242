import React from 'react';
import './style.scss';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { store } from '@/store';
import Link from '@/components/atoms/link';
import { useRouter } from 'next/router';
import { Provider, useSelector } from 'react-redux';
import t from '@/lib/i18n';
import getConfigValue from '@/lib/config';

const IS_FIDELITY_ENABLED = getConfigValue(
  'IS_FIDELITY_ENABLED',
  false
).toBoolean();

type StepsProps = {
  label: string;
  value: number;
  href: string;
  id: string;
};

export type StepperProps = {
  steps: StepsProps[];
  current: number;
};

const Stepper = ({ steps, current }: StepperProps) => {
  const router = useRouter();
  return (
    <div className="inscriptionStepper">
      {steps.map((step, index) => {
        return (
          <div
            onClick={() => {
              step.value < current &&
                router.push(step.href, undefined, { shallow: true });
            }}
            key={step.label}
            className={classnames('inscriptionStepper__step', {
              'inscriptionStepper__step--current': step.value === current
            })}
          >
            <span
              className={classnames('inscriptionStepper__step__number', {
                'inscriptionStepper__step--validated': step.value < current
              })}
            >
              {step.value >= current ? (
                index + 1
              ) : (
                <svg width="15" className="fill--green">
                  <use xlinkHref={`${CommonSprite}#check`} />
                </svg>
              )}
            </span>
            <span className="inscriptionStepper__step__label">
              {step.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const Header = ({ step }: { step: string }) => {
  const { register: user } = useSelector(({ register }: any) => {
    return {
      register
    };
  });
  let steps;
  const baseSteps = [
    {
      id: 'identifiants',
      label: t('myAccount.creation.step1'),
      value: 1,
      href: '/my-account/create-my-account'
    },
    {
      id: 'informations',
      label: t('myAccount.creation.step2'),
      value: 2,
      href: '/my-account/create-my-account/identity'
    },
    {
      id: 'contact',
      label: t('myAccount.creation.step3'),
      value: 3,
      href: '/my-account/create-my-account/address'
    }
  ];

  if (user.isPro || user.hasFidelityCard || !IS_FIDELITY_ENABLED) {
    steps = [
      ...baseSteps,
      {
        id: 'confirm',
        label: t('myAccount.creation.step5'),
        value: 4,
        href: '/my-account/create-my-account/confirmation'
      }
    ];
  } else {
    steps = [
      ...baseSteps,
      {
        id: 'fidelite',
        label: t('myAccount.creation.step4'),
        value: 4,
        href: '/my-account/create-my-account/fidelity'
      },
      {
        id: 'confirm',
        label: t('myAccount.creation.step5'),
        value: 5,
        href: '/my-account/create-my-account/confirmation'
      }
    ];
  }

  const current = steps?.find((s) => s.id === step)?.value;

  return (
    <Provider store={store}>
      <div className="inscriptionHeader">
        <div className="container inscriptionHeader__content">
          <Link href="/home" className="inscriptionHeader__logo">
            <svg height="28" width="140" className="fill--white">
              <use xlinkHref={`${CommonSprite}#logo`} />
            </svg>
          </Link>
          {current && steps && <Stepper steps={steps} current={current} />}
        </div>
      </div>
    </Provider>
  );
};

export default Header;
