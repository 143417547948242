import React from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Component
} from '@/lib/contentful';
import './style.scss';

export type LandingPageProps = {
  headerImage: Component;
  heading: Component;
  componentsLeft?: Array<Component>;
  componentsRight?: Array<Component>;
};

const LandingPage = ({
  headerImage,
  heading,
  componentsLeft,
  componentsRight
}: LandingPageProps) => {
  return (
    <div className="landingPage">
      {headerImage && (
        <div className="landingPage__header">
          <ContentfulRenderComponents components={[headerImage]} />
        </div>
      )}
      <div className="landing-page-container">
        {heading && <ContentfulRenderComponents components={[heading]} />}
        <div className="landingPage__columns">
          <div className="landingPage__columns__left">
            {componentsLeft && (
              <ContentfulRenderComponents components={componentsLeft} />
            )}
          </div>
          <div className="landingPage__columns__right">
            {componentsRight && (
              <ContentfulRenderComponents components={componentsRight} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
