import { PartenersDatasProps } from '@/lib/thirdPartyApi';

export const lazyAddHighCo = (
  setPartnersDatas: any,
  tileHghCoRef: any
) => () => {
  /* eslint-disable no-console */
  console.info('*** addHighCo ***');

  setPartnersDatas((partnesDatasTemp: PartenersDatasProps) => ({
    ...partnesDatasTemp,
    isHighcoExist: true
  }));
  return new Promise((resolve) => {
    resolve([tileHghCoRef]);
  });
};
