import { ProductSliderWideItem } from '@/components/organisms/productSliderWide';
import { Component, normalize as BaseNormalize, NormalizeParams } from '..';

const normalize = ({ data, includes, extras }: NormalizeParams) => {
  const { data: items = [] } = data;
  const itemsList: Array<ProductSliderWideItem> = [];

  items.forEach((element: any) => {
    const itemData = includes[element.sys.id] || null;
    let image = null;

    if (itemData.fields.image) {
      const imageData = includes[itemData.fields.image.sys.id] || null;
      image = {
        props: {
          id: imageData.fields.title || null,
          alt: imageData.fields.description || null,
          src: imageData.fields.file.url || null
        }
      };
    }

    const dataComponentContent = (BaseNormalize({
      data: itemData || null,
      includes,
      extras
    }) as unknown) as Component;

    const itemsContent = {
      image,
      dataComponent: {
        props: {
          title: dataComponentContent.props.title || '',
          caption: dataComponentContent.props.caption || ''
        }
      }
    };
    itemsList.push(itemsContent);
  });

  return {
    type: 'organisms',
    name: 'productSliderWide',
    props: {
      itemsList
    }
  };
};

export default normalize;
