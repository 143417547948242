import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import './style.scss';
import t from '@/lib/i18n';
import { AuthStateType } from '@/store/auth/authReducer';
import shoppingListApi, { List } from '@/lib/api/shoppingList';
import Link from '@/components/atoms/link';
import CommonSprite from '@/assets/images/sprites/common.svg';
import LoadableContent from '@/components/atoms/loadableContent';
import CreateShoppingList from '@/components/organisms/myAccount/createShoppingList';
import usePdv from '@/lib/hooks/usePdv';

type StateType = {
  isLoading: boolean;
  lists: Array<List>;
  isEcommerce: boolean;
};

const ShoppingList = () => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [state, setState] = useState<StateType>({
    isLoading: true,
    lists: [],
    isEcommerce: true
  });

  const load = useCallback(async () => {
    if (!userId) return;

    let newLists: Array<List>;

    try {
      newLists = await shoppingListApi.list(userId);
    } catch (error: any) {
      newLists = [];
    }

    setState((oldState) => ({
      ...oldState,
      isLoading: false,
      lists: newLists
    }));
  }, [userId]);

  useEffect(() => {
    const { isEcommerce } = usePdv();

    setState((oldState) => ({
      ...oldState,
      isLoading: isEcommerce,
      isEcommerce
    }));

    if (!isEcommerce) return;

    (async () => {
      await load();
    })();
  }, [userId, load]);

  return (
    <LoadableContent
      loading={state.isLoading}
      className={classnames('myAccountDashboard__shoppingList', {
        myAccountDashboard__container: state.isEcommerce,
        'myAccountDashboard__shoppingList--empty': state.lists.length === 0
      })}
    >
      <>
        {state.isEcommerce && state.lists.length > 0 && (
          <>
            <div className="myAccountDashboard__shoppingList_title">
              {t('myAccount.dashboard.lists.title')}
            </div>
            <ul className="myAccountDashboard__shoppingList_items">
              <li className="myAccountDashboard__shoppingList_item">
                <div className="myAccountDashboard__shoppingList_left">
                  <div className="myAccountDashboard__shoppingList_name">
                    {state.lists[0].name}
                  </div>
                  <div className="myAccountDashboard__shoppingList_count">
                    {t('myAccount.dashboard.lists.item.count', {
                      '%count%': state.lists[0].count
                    })}
                  </div>
                </div>

                <div className="myAccountDashboard__shoppingList_right">
                  <Link
                    id="listOrders"
                    label={t('myAccount.dashboard.lists.item.link')}
                    href={`/account-management/my-lists/my-shopping-lists/detail/${state.lists[0].id}`}
                  />
                </div>
              </li>
            </ul>
            {state.lists.length > 1 && (
              <Link
                className="myAccountDashboard__shoppingList_link"
                href="/account-management/my-lists/my-shopping-lists"
              >
                {t('myAccount.dashboard.lists.all')}
              </Link>
            )}
          </>
        )}
        {state.isEcommerce && state.lists.length === 0 && (
          <>
            <svg
              className="myAccountDashboard__shoppingList_emptyIcon"
              height="40"
              width="34"
            >
              <use xlinkHref={`${CommonSprite}#add-to-list`} />
            </svg>
            <div className="myAccountDashboard__shoppingList_emptyTitle">
              {t('myAccount.dashboard.lists.empty.title')}
            </div>
            <CreateShoppingList
              type="link"
              className="myAccountDashboard__shoppingList_emptyButton"
              ctaLabel={t('myAccount.dashboard.lists.empty.button')}
              onCreated={async () => {
                await load();
              }}
            />
          </>
        )}
      </>
    </LoadableContent>
  );
};

export default ShoppingList;
