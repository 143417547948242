import t from '@/lib/i18n';
import FidelitySprite from '@/assets/images/sprites/carteFidelite.svg';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';

type PresentationDataProps = {
  title: string;
  headings: {
    small: string;
    big?: string;
  };
  list: Array<PresentationDataListItemProps>;
};

export type PresentationDataListItemProps = {
  image?: string;
  title?: string;
  subTitle?: string;
  index: number;
};

const PRESENTATION_DATA_PDV_ACTIVATED: PresentationDataProps = {
  title: t(
    'myAccount.fidelity.myStickers.presentation.shopEnabled.expandVignettesTitle'
  ),
  headings: {
    small: t(
      'myAccount.fidelity.myStickers.presentation.shopEnabled.headings.expandVignettesSmall'
    ),
    big: t(
      'myAccount.fidelity.myStickers.presentation.shopEnabled.headings.big'
    )
  },
  list: [
    {
      image: `${FidelitySprite}#icons-fid-carte-de-fid`,
      title: '1 -',
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopEnabled.list.expandVignettesSubTitle1'
      ),
      index: 1
    },
    {
      image: `${MyAccountSprite}#store`,
      title: '2 -',
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopEnabled.list.expandVignettesSubTitle2'
      ),
      index: 2
    },
    {
      image: `${FidelitySprite}#icons-fid-collecteur-de-vignette`,
      title: '3 -',
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopEnabled.list.expandVignettesSubTitle3'
      ),
      index: 3
    },
    {
      image: `${FidelitySprite}#icons-fid-collecteur-de-vignette-digital`,
      title: '4 -',
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopEnabled.list.expandVignettesSubTitle4'
      ),
      index: 4
    },
    {
      title: t(
        'myAccount.fidelity.myStickers.presentation.shopEnabled.list.expandVignettesTitle5'
      ),
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopEnabled.list.expandVignettesSubTitle5'
      ),
      index: 5
    }
  ]
};

const PRESENTATION_DATA_PDV_NOT_ACTIVATED: PresentationDataProps = {
  title: t('myAccount.fidelity.myStickers.presentation.shopDisabled.title'),
  headings: {
    small: t(
      'myAccount.fidelity.myStickers.presentation.shopDisabled.headings.expandVignettesSmall'
    )
  },
  list: [
    {
      image: `${FidelitySprite}#icons-fid-no-vignette`,
      title: '1-',
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopDisabled.list.expandVignettesSubTitle1'
      ),
      index: 1
    },
    {
      image: `${FidelitySprite}#icons-fid-collecteur-de-vignette`,
      title: '2-',
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopDisabled.list.subTitle2'
      ),
      index: 2
    },
    {
      image: `${MyAccountSprite}#store`,
      title: '3-',
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopDisabled.list.expandVignettesSubTitle3'
      ),
      index: 3
    },
    {
      title: '4-',
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopDisabled.list.subTitle4'
      ),
      index: 4
    },
    {
      title: t(
        'myAccount.fidelity.myStickers.presentation.shopDisabled.list.expandVignettesTitle5'
      ),
      subTitle: t(
        'myAccount.fidelity.myStickers.presentation.shopDisabled.list.expandVignettesSubTitle5'
      ),
      index: 5
    }
  ]
};

const data = {
  PRESENTATION_DATA_PDV_ACTIVATED,
  PRESENTATION_DATA_PDV_NOT_ACTIVATED
};

export default data;
