import React, { useRef, MouseEvent } from 'react';
import './style.scss';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';
import { useSelector, useDispatch } from 'react-redux';
import { TOGGLE_MINICART_OPENED } from '@/store/cart/cartActions';
import { StateType } from '@/store/cart/types';
import Cart from '@/components/organisms/checkout/cart';

export type MiniCartProps = {
  isActive: boolean;
  toggleActive: () => void;
};

const MiniCart = ({ isActive = false, toggleActive }: MiniCartProps) => {
  const miniCartRef = useRef<HTMLDivElement>(null);

  const handleOutsideContent = (event: MouseEvent) => {
    if (miniCartRef.current === event.target) {
      toggleActive();
    }
  };

  return (
    <div
      onClick={handleOutsideContent}
      ref={miniCartRef}
      className={classnames({
        miniCart: true,
        'miniCart--isActive': isActive
      })}
    >
      <div className="miniCart__content">
        {isActive && (
          <>
            <svg
              className="miniCart__cross"
              id="minicart__close"
              onClick={() => {
                toggleActive();
              }}
              width="18px"
              height="18px"
            >
              <use xlinkHref={`${CommonSprite}#cross-light`} />
            </svg>
            <Cart />
          </>
        )}
      </div>
    </div>
  );
};

const MiniCartContainer = () => {
  const { IsActive } = useSelector(({ cart }: { cart: StateType }) => {
    return {
      IsActive: cart.isMiniCartOpened
    };
  });
  const dispatch = useDispatch();
  const toggleActive = () => {
    dispatch({ type: TOGGLE_MINICART_OPENED });
  };

  return <MiniCart isActive={IsActive} toggleActive={toggleActive} />;
};

export default MiniCartContainer;
