import React from 'react';
import Title from '@/components/atoms/title';
import Paragraph from '@/components/atoms/paragraph';
import t from '@/lib/i18n';
import Link from '@/components/atoms/link';
import './style.scss';

const RenewPasswordError = () => (
  <>
    <Title
      tag="h2"
      label={t('renew.password.errorTitle')}
      className="renewPasswordError__title"
    />
    <Paragraph
      label={t('renew.password.errorText1')}
      className="renewPasswordError__text"
    />
    <Paragraph
      label={t('renew.password.errorText2')}
      className="renewPasswordError__text"
    />
    <div className="renewPasswordError__link__resendEmail">
      <Link
        href="/forgotten-password"
        label={t('renew.password.errorResendEmail')}
        className="renewPasswordError__link"
      />
    </div>
    <div className="renewPasswordError__link__home">
      <Link
        href="/home"
        label={t('renew.password.errorBackHome')}
        className="renewPasswordError__link"
      />
    </div>
  </>
);

export default RenewPasswordError;
