import React, { useState } from 'react';
import Carousel, { DotProps, ButtonGroupProps } from 'react-multi-carousel';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Image } from '@/lib/model/productTypes';
import Button from '@/components/atoms/button';
import ImageComponent from '@/components/atoms/image';
import CommonSprite from '@/assets/images/sprites/common.svg';

import './style.scss';

type ProductSliderProps = {
  images: Image[];
};

const responsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 }
};

const ProductSlider = ({ images }: ProductSliderProps) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const [customDotsImages, setCustomDotsImages] = useState(images);
  const [step, setStep] = useState(0);

  const CustomDot = ({ index, onClick, active, carouselState }: DotProps) => {
    const customDots = customDotsImages.map((image) => {
      return (
        <div
          key={index}
          style={{ backgroundImage: `url(${image.src})` }}
          className={classNames('productCarousel__customDots__img', {
            'productCarousel__customDots__img--active':
              carouselState &&
              (index || index === 0) &&
              carouselState.currentSlide === index
          })}
        />
      );
    });

    return (
      <div
        onClick={() => {
          if (onClick) onClick();
        }}
        style={{ display: 'inherit' }}
        className="productCarousel__customDots"
        id={`${index}`}
      >
        {(index || index === 0) &&
          React.Children.toArray(customDots)[index - step]}
      </div>
    );
  };

  const CustomButtonGroup = ({
    next,
    previous,
    carouselState
  }: ButtonGroupProps) => {
    return (
      <div className="productCarousel__customButtonGroup">
        <Button
          onClick={previous}
          color="secondary"
          disabled={carouselState?.currentSlide === 0}
          className="productCarousel__customButtonGroup-btnPrev"
        >
          <svg height="16" width="16">
            <use xlinkHref={`${CommonSprite}#arrow-up`} />
          </svg>
        </Button>

        <Button
          onClick={() => {
            next && next();
          }}
          color="secondary"
          className="productCarousel__customButtonGroup-btnNext"
          disabled={carouselState?.currentSlide === images.length - 1}
        >
          <svg height="16" width="16">
            <use xlinkHref={`${CommonSprite}#arrow-down`} />
          </svg>
        </Button>
      </div>
    );
  };
  return (
    <div className="productCarousel">
      <Carousel
        responsive={responsive}
        centerMode={false}
        focusOnSelect={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        slidesToSlide={1}
        customDot={!isTabletOrMobile ? <CustomDot /> : null}
        arrows={false}
        showDots={images.length > 1}
        customButtonGroup={
          !isTabletOrMobile && images.length > 3 ? <CustomButtonGroup /> : null
        }
        beforeChange={(nextSlide, state) => {
          if (nextSlide % 3 === 0 && nextSlide > state.currentSlide) {
            setStep(nextSlide);
            setCustomDotsImages(images.slice(nextSlide));
          }
          if (state.currentSlide % 3 === 0 && nextSlide < state.currentSlide) {
            setCustomDotsImages(images.slice(state.currentSlide - 3));
            setStep(step - 3);
          }
        }}
      >
        {images.map((img) => {
          return (
            <ImageComponent
              src={img.src}
              alt={img.alt}
              key={img.src}
              width="auto"
              height={250}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

export default ProductSlider;
