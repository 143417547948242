import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import {
  BadRequestException,
  UnknownRecipientException,
  InvalidRecipientException,
  QuantityExceededException
} from './exceptions';
import { Operation, ShareRequest } from './types';

class StickersApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  getOperations = async (): Promise<Operation[] | null> => {
    try {
      const response = await this.axios.get(
        '/cartefidelite/v1/operations?type=STICKER'
      );
      const operations: Array<Operation> = [];
      const data = response?.data ?? [];

      data?.operations.forEach((elt: any) => {
        operations.push({
          ...elt,
          links: {
            modality: elt.links ? elt.links[0]?.url : '',
            landing: elt.links ? elt.links[1]?.url : ''
          }
        });
      });

      return operations;
    } catch (error: any) {
      logger.error({
        message: 'Unable to retrieve stickers operations',
        error
      });
    }

    return null;
  };

  share = async (params: ShareRequest) => {
    let response = null;
    try {
      const data = {
        header: {
          loyaltyCode: 'IP',
          language: 'PT',
          channel: 'WEB'
        },
        body: {
          senderCardNumber: params.senderCard,
          recipientCardNumber: params.recipientCard,
          stickersQty: params.quantity.toString(),
          recipientOptIn: params.consent
        }
      };
      response = await this.axios.post(
        `/cartefidelite/v1/operations/${params.code}/share`,
        data
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to share stickers',
        error
      });

      response = error?.response;

      // TODO: Rework error handling
      if (response?.status > 204 && response?.data?.errors) {
        response?.data?.errors.forEach((err: any) => {
          switch (err.code) {
            case 'RECIPIENT_CARD_UNKNOWN':
              throw new UnknownRecipientException();
            case 'RECIPIENT_CARD_INVALID':
              throw new InvalidRecipientException();
            case 'STICKER_QTY_EXCEEDED':
              throw new QuantityExceededException();
            default:
              throw new BadRequestException();
          }
        });
      }
      throw new BadRequestException();
    }
    return response;
  };
}

export default new StickersApi();
