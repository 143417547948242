import { FooterSecondaryProps } from '@/components/organisms/footerSecondary';
import { normalize as BaseNormalize, NormalizeParams } from '..';

const normalize = ({ data, includes }: NormalizeParams) => {
  const props: FooterSecondaryProps = {
    links: []
  };

  if (data.secondaryLinks) {
    data.secondaryLinks.forEach((item: any) => {
      const itemData = includes[item.sys.id];

      if (itemData?.sys?.contentType?.sys?.id === 'ctaTypeLink') {
        const elt = BaseNormalize({ data: itemData, includes });

        if (elt) {
          props.links.push(elt);
        }
      }
    });
  }

  return {
    type: 'organisms',
    name: 'footerSecondary',
    props
  };
};

export default normalize;
