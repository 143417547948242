import React from 'react';
import CategoryModel from '@/lib/model/category';
import { ReactComponent as AlcoholBanner } from '@/assets/images/icons/alcohol-banner.svg';
import classNames from 'classnames';

type CategoryHasAlcoholBannerProps = {
  category: CategoryModel | null;
  className?: string;
};

const CategoryHasAlcoholBanner = ({
  category,
  className
}: CategoryHasAlcoholBannerProps) => {
  if (!category) return null;
  if (!category.hasAlcohol) return null;
  return (
    <div className={classNames('CategoryHasAlcoholBanner', className)}>
      <AlcoholBanner width={200} />
    </div>
  );
};

export default CategoryHasAlcoholBanner;
