import Image from '@/components/atoms/image';
import classnames from 'classnames';
import React from 'react';
import FrancoScore from '../francoScore';

type ProductFlagsType = {
  isHorizontalFlag: boolean;
  flags: string[];
  francoScore?: number;
};

const ProductFlags = ({
  isHorizontalFlag,
  flags,
  francoScore
}: ProductFlagsType) => (
  <div
    className={classnames('product__flags__container', {
      'product__flags__container--horizontalLabel': isHorizontalFlag
    })}
  >
    {flags?.slice(0, 3)?.map((flag) => {
      return (
        <Image
          key={flag}
          className="product__flags__flag"
          alt="flag"
          width={32}
          height={32}
          src={flag}
        />
      );
    })}
    {!isHorizontalFlag && <FrancoScore score={francoScore} />}
  </div>
);

export default ProductFlags;
