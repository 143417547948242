import React from 'react';

import Layout from '@/components/organisms/layout';
import {
  Content as ContentfulContent,
  RendererComponents as ContentfulRenderComponents
} from '@/lib/contentful';
import Header from '@/components/organisms/header';
import t from '@/lib/i18n';
import { useSelector } from 'react-redux';
import ConfirmationComponent from '../validation/content';

export type InscriptionProps = {
  footer: ContentfulContent;
};

const Confirmation: React.FunctionComponent<InscriptionProps> = ({
  footer
}) => {
  const { register: local } = useSelector(({ register }: any) => {
    return {
      register
    };
  });
  const headerPart = <Header />;
  const contentPart = (
    <ConfirmationComponent
      title={t('register.confirmation.title')}
      subtitle={t('register.confirmation.subtitle')}
      message={t('register.confirmation.message')}
      cta={t('register.confirmation.cta')}
      description={t('register.confirmation.description', {
        '%mail%': local.email ? local.email : ''
      })}
    />
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout
      className="create-my-account"
      header={headerPart}
      content={contentPart}
      footer={footerPart}
      pageView="accountCreationConfirmation"
    />
  );
};

export default Confirmation;
