import React from 'react';
import classnames from 'classnames';
import './style.scss';

export type ParagraphProps = {
  label?: string;
  children?: string;
  className?: string;
};

const Paragraph = ({ label, children, className }: ParagraphProps) => (
  <p className={classnames('paragraph', className)}>{label || children}</p>
);

export default Paragraph;
