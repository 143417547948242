import React from 'react';
import './style.scss';
import Layout from '@/components/organisms/layout';
import CommonSprite from '@/assets/images/sprites/common.svg';
import t from '@/lib/i18n';
import Link from '@/components/atoms/link';

export type ErrorProps = {
  statusCode: number;
};

const Error = ({ statusCode }: ErrorProps) => {
  const contentPart = (
    <div className="error">
      <div className="error__container">
        <svg className="error__logo" height="50" width="280">
          <use xlinkHref={`${CommonSprite}#logo`} />
        </svg>
        <div className="error__code">{statusCode}</div>
        <div
          className="error__message"
          dangerouslySetInnerHTML={{
            __html: t(`error.${statusCode}.message`)
          }}
        />
        <Link
          className="error__goHome"
          label={t('error.backToHome')}
          href="/home"
          button={true}
        />
      </div>
    </div>
  );

  return <Layout content={contentPart} />;
};

export default Error;
