import { useEffect, useState } from 'react';
import { AuthStateType } from '@/store/auth/authReducer';
import UserInformationApi from '@/lib/api/userInformation';
import logger from '@/lib/logger/base';
import useShallowEqualSelector from '../utils/useShallowEqualSelector';

export const useIsPro = () => {
  const [isPro, setIsPro] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const user = useShallowEqualSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user
  );

  useEffect(() => {
    (async () => {
      if (!user?.id) {
        setIsPro(false);
        return;
      }
      setLoading(true);
      try {
        const userInfo = await UserInformationApi.getInformations(user?.id);
        setIsPro(userInfo.isPro);
        setLoading(false);
      } catch (error: any) {
        logger.error({
          message: 'Unable to get user informations',
          error
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [user?.id]);
  return [isPro, loading];
};
