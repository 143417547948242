import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import Date from '@/lib/utils/date';
import ProductModel from '@/lib/model/product';
import { parseToPrice, Price } from '@/lib/model/price';
import lodash from 'lodash';
import { AllowedShippingTypes } from '@/store/cart/types';
import productApi from '@/lib/api/product';
import ProductList from '@/lib/model/productList';
import { AddressApi } from '../address';
import { BillingAddress, DeliveryAddress } from '../address/types';
import {
  OrderStatusMapping,
  DeliveryTypesMapping,
  PaymentModesMapping,
  PaymentTypesMapping,
  OrderMkpStatusMapping,
  DELIVERY_TYPE_STORE_PICK_UP,
  MKP_DELIVERY_TYPE_STORE_PICK_UP,
  LIST_STATUS_KO
} from './mappings';
import {
  Order,
  OrderStatus,
  PaymentType,
  PaymentMode,
  DeliveryType,
  OrderDetail,
  OrderItem,
  MkpOrder,
  ConfirmationOrder,
  SellerOrder
} from './types';

type MkpOrderItem = {
  id: string;
  price: number;
  quantity: number;
};
class OrderApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi', 'oauth']);
  }

  count = async (userId: string) => {
    let response;

    try {
      response = await this.axios.get(
        `/commandes/v1/consommateurs/${userId}/commandes`,
        {
          params: {
            enCours: true,
            historique: true
          }
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch orders for count',
        error
      });

      throw error;
    }

    return response.data.commandes.length;
  };

  list = async (
    userId: string,
    request: {
      count?: number;
      year?: number;
      countMonths?: number;
      inProgress?: boolean;
      passed?: boolean;
    }
  ): Promise<Array<Order>> => {
    let response;
    const orders: Array<Order> = [];

    try {
      response = await this.axios.get(
        `/commandes/v1/consommateurs/${userId}/commandes`,
        {
          params: {
            enCours: request.inProgress,
            historique: request.passed,
            nombre: request.count,
            annee: request.year,
            nbMois: request.countMonths,
            withPdv: true
          }
        }
      );

      const ordersData = response.data.commandes ?? [];

      ordersData.forEach((orderData: any) => {
        orders.push(OrderApi.parseOrder(orderData));
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch orders',
        error
      });

      throw error;
    }

    return orders;
  };

  mkpList = async (userId: string): Promise<Array<MkpOrder>> => {
    let response;
    const orders: Array<MkpOrder> = [];

    try {
      response = await this.axios.post(
        `/commandes/v1/customers/${userId}/orders/fetch`,
        {
          catalogs: ['MKP'],
          page: 1,
          size: 20
        }
      );

      const ordersData = response.data.subOrders ?? [];

      ordersData.forEach((orderData: any) => {
        const status = orderData.status as keyof typeof OrderMkpStatusMapping;
        orders.push({
          ...orderData,
          mkpStatus: orderData.status,
          status: OrderMkpStatusMapping[status] as OrderStatus
        });
      });
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch MKP orders',
        error
      });

      throw error;
    }

    return orders;
  };

  read = async (
    userId: string,
    orderId: string,
    pdvRef: string
  ): Promise<OrderDetail> => {
    let response;

    try {
      response = await this.axios.get(
        `/commandes/v1/consommateurs/${userId}/commandes/${orderId}`,
        {
          params: {
            numeroPDV: pdvRef,
            withPdv: true
          }
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch order',
        error
      });

      throw error;
    }
    return OrderApi.parseOrderDetail(response.data);
  };

  readMkp = async (userId: string, orderId: string): Promise<MkpOrder> => {
    let response;

    try {
      response = await this.axios.post(
        `/commandes/v1/customers/${userId}/orders/fetch`,
        {
          catalogs: ['MKP'],
          page: 1,
          size: 20
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch order',
        error
      });

      throw error;
    }

    const order = response.data.subOrders.filter(
      ({ id }: any) => id === orderId
    )[0];

    return OrderApi.parseMkpOrderDetail(order);
  };

  getConfirmationOrder = async ({
    userId,
    orderId,
    pdvRef
  }: {
    userId: string;
    orderId: string;
    pdvRef: string;
  }): Promise<ConfirmationOrder> => {
    let response;

    try {
      response = await this.axios.get(
        `/commandes/v1/customers/${userId}/orders/${orderId}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch order',
        error
      });

      throw error;
    }

    const { data } = response || {};
    const allOrders: Array<OrderDetail> = [];

    for (const order of data.orders) {
      if (order) {
        const { catalog, items } = order;
        let productList: Array<ProductModel> = [];

        switch (catalog) {
          case 'PDV': {
            const productIds: Array<number> = items.map(
              ({ id }: { id: string }) => parseInt(id, 10)
            );
            /* eslint-disable no-await-in-loop */
            const { products }: ProductList = await productApi.findByIds(
              pdvRef,
              productIds
            );
            productList = products;

            break;
          }
          case 'MKP': {
            const productIds: Array<{
              id: string;
              parentId: string;
            }> = items.map(
              ({
                id,
                itemParentId: parentId
              }: {
                id: string;
                itemParentId: string;
              }) => ({ id, parentId })
            );
            /* eslint-disable no-await-in-loop */
            const { products }: ProductList = await productApi.findByIdsMkp(
              pdvRef,
              productIds
            );
            productList = products;

            break;
          }
          default:
            break;
        }

        const productsWithQty = productList.map((product: any, index) => {
          const { quantity } = items[index];
          const productWithQty = Object.assign(product, { quantity });

          return productWithQty;
        });

        order.items = productsWithQty;

        const orderNormalized = OrderApi.normalizeToLegacy(data, order);
        const parsedConfirmOrderDetail = OrderApi.parseConfirmOrderDetail(
          orderNormalized
        );
        allOrders.push(parsedConfirmOrderDetail);
      }
    }
    const ordersBySeller: Array<SellerOrder> = lodash(allOrders)
      .groupBy('seller.id')
      .map((value) => ({
        seller: value[0].seller,
        ordersList: value,
        hasStatusKO: value.some((order) => order.statusIsKO)
      }))
      .sortBy((order) => (order.seller.id === 'ITM' ? 0 : 1))
      .value();

    const confirmationDeliveriesType = OrderApi.confirmationDeliveriesType(
      allOrders
    );
    const confirmation: ConfirmationOrder = {
      id: data.id || data.numeroCommande,
      total: parseToPrice(data.amount || data.totalCommande),
      creditFid: parseToPrice(
        data.valuations?.discountAmount || data.creditFid
      ),
      debitFid: parseToPrice(data.valuations?.loyaltyAmount || data.debitFid),
      totalVoucher: parseToPrice(
        data.orders.reduce(
          (acc: number, current: any) =>
            acc + (current.voucherAmount || current.totalBonsAchat),
          0
        )
      ),
      totalDiscountCode: parseToPrice(
        data.orders.reduce(
          (acc: number, current: any) =>
            acc + (current.discountCodeAmount || current.totalCodeReduction),
          0
        )
      ),
      orders: ordersBySeller,
      isDrive: confirmationDeliveriesType.isDrive,
      isDelivery: confirmationDeliveriesType.isDelivery,
      billingAddress: data.billingAddress
        ? (AddressApi.parseNewVersionAddress(
            'billing',
            data.billingAddress
          ) as BillingAddress)
        : undefined,
      shippingAddress: data.shippingAddress
        ? (AddressApi.parseNewVersionAddress(
            'delivery',
            data.shippingAddress
          ) as DeliveryAddress)
        : undefined
    };
    return confirmation;
  };

  delete = async (userId: string, orderId: string) => {
    try {
      await this.axios.delete(
        `/commandes/v1/consommateurs/${userId}/commandes/${orderId}`
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to cancel order',
        error
      });

      throw error;
    }
  };

  cancelMkp = async (
    userId: string,
    orderId: string,
    items: MkpOrderItem[]
  ) => {
    try {
      await this.axios.put(
        `/commandes/v1/customers/${userId}/sub_orders/${orderId}/cancel`,
        {
          items
        }
      );
    } catch (error: any) {
      logger.error({
        message: 'Unable to cancel the partner order',
        error
      });

      throw error;
    }
  };

  static parseMkpOrderDetail = (orderData: any) => {
    const orderItems: any[] = [];
    orderData.items.forEach((item: any) => {
      orderItems.push({
        id: item?.id,
        title: item.label,
        brand: item.brandName,
        image: { src: item.picture, alt: item.label },
        url: `/product/${item.label.split(' ').join('-')}/${item.itemParentId}`,
        status: item.status,
        quantity: item.quantity,
        price: item.price,
        initialPrice: item.initialPrice,
        total: item.amount,
        advantages: item.advantages
      });
    });

    const totalDiscount = orderItems.reduce(
      (discount, { initialPrice, price, quantity }) =>
        discount + quantity * (initialPrice - price),
      0
    );

    const orderDetail = {
      ...orderData,
      status:
        OrderMkpStatusMapping[
          orderData.status as keyof typeof OrderMkpStatusMapping
        ],
      mkpStatus: orderData.status,
      items: orderItems,
      totalDiscount
    };

    return orderDetail;
  };

  static parseOrderDetail = (orderData: any): OrderDetail => {
    const order = OrderApi.parseOrder(orderData);

    const orderDetail: OrderDetail = {
      ...order,
      billingAdress:
        orderData.adresseFacturation &&
        (AddressApi.parseAddress(
          'billing',
          orderData.adresseFacturation
        ) as BillingAddress),
      items: orderData.articles.map(
        (article: any): OrderItem => {
          const product = new ProductModel(article.infoProduit);
          let image;
          const prices: {
            unitPrice?: Price;
            productPrice?: Price;
          } = {};

          if (article.image) {
            image = {
              alt: article.libelle,
              src: article.image
            };
          }
          if (article.prix) {
            const prix = article.infoProduit.prix.toFixed(2);

            prices.productPrice = {
              currency: '€',
              decimal: prix.split('.')[1],
              value: prix,
              integer: prix.split('.')[0]
            };
          }

          if (article.unitPrice && product.prices.unitPrice) {
            const unitPrice = article.unitPrice.toFixed(2);

            prices.unitPrice = {
              currency: product.prices.unitPrice.currency,
              decimal: unitPrice.split('.')[1],
              value: unitPrice,
              integer: unitPrice.split('.')[0]
            };
          }

          return {
            title: article.libelle,
            brand: article.marque,
            image,
            prices,
            product,
            quantity: article.quantite,
            total: article.montant,
            comment: article.commentaireClient,
            allowSubstition: article.accepteSubstitution
          };
        }
      )
    };

    return orderDetail;
  };

  static parseConfirmOrderDetail = (orderData: any): OrderDetail => {
    const order = OrderApi.parseOrder(orderData);
    const orderDetail: OrderDetail = {
      ...order,
      billingAdress:
        orderData.adresseFacturation &&
        (AddressApi.parseAddress(
          'billing',
          orderData.adresseFacturation
        ) as BillingAddress),
      confirmItems: orderData.articles.map((article: any) => {
        return {
          id: article.id,
          ean: article.ean,
          name: article.informations.title,
          brand: article.informations.brand,
          quantity: article.quantity,
          allowSubstituable: article.allowSubstituable,
          price: article.prices.productPrice?.value?.toString(),
          type: article.type === 'PDV' ? 'classique' : 'marketplace',
          universId: article.universId,
          famillyId: article.famillyId,
          departmentId: article.departmentId,
          seller: order.seller,
          promoType: article.promotions?.[0]?.['type'] ?? null,
          offersNumber: article.offers?.length?.toString()
        };
      }),
      items: []
    };
    return orderDetail;
  };

  static parseOrder = (orderData: any): Order => {
    const slotData = orderData.creneauSouhaite;
    const slotBegin = `${slotData.date} ${slotData.heureDebut}`;
    const slotEnd = `${slotData.date} ${slotData.heureFin}`;
    const slotDate = `${slotData.date}`;

    const statutCommande = orderData.statutCommande as keyof typeof OrderStatusMapping;
    const typeLivraison = orderData.typeLivraison as keyof typeof DeliveryTypesMapping;
    const typeDePaiement = orderData.typeDePaiement as keyof typeof PaymentTypesMapping;
    const moyenDePaiement = orderData.moyenDePaiement as keyof typeof PaymentModesMapping;

    const order: Order = {
      id: orderData.numeroCommande,
      backOfficeOrderId: orderData?.backOfficeOrderId ?? null,
      pdv: {
        ref: orderData.identifiantPdv,
        name: orderData.nomMagasin,
        format:
          orderData.pdv?.vocation?.charAt(0)?.toUpperCase() +
          orderData.pdv?.vocation?.slice(1)?.toLowerCase(),
        address: orderData.pdv?.adresse1,
        zipCode: orderData.pdv?.codePostal,
        city: orderData.pdv?.commune
      },
      isEditableOrCancelable: orderData.estModifiableOuAnnulable,
      fidelityNumber: orderData.numeroCarteFid,
      slot: {
        begin: Date(slotBegin, 'YYYY-MM-DD HH:mm'),
        end: Date(slotEnd, 'YYYY-MM-DD HH:mm'),
        date: Date(slotDate)
      },
      status: OrderStatusMapping[statutCommande] as OrderStatus,
      deliveryType: DeliveryTypesMapping[typeLivraison] as DeliveryType,
      paymentType: PaymentTypesMapping[typeDePaiement] as PaymentType,
      paymentMode: PaymentModesMapping[moyenDePaiement] as PaymentMode,
      countItems: orderData.nbProduits,
      paidProductsQuantity: orderData.paidProductsQuantity,
      billingUrl: orderData.urlFacture,
      createdAt: Date(orderData.dateCreation, 'YYYY-MM-DD HH:mm'),
      currency: orderData.devise,
      preparationCost: parseToPrice(orderData.preparationCost),
      deliveryCost: parseToPrice(orderData.deliveryCost),
      deliveryExtraCost: parseToPrice(orderData.deliveryExtraCost),
      volumeExtraCost: parseToPrice(orderData.volumeExtraCost),
      housingExtraCost: parseToPrice(orderData.housingExtraCost),
      creditFid: parseToPrice(orderData.creditFid),
      debitFid: parseToPrice(orderData.debitFid),
      total: parseToPrice(orderData.totalCommande - (orderData?.totalBri ?? 0)),
      totalLocker: parseToPrice(orderData.totalConsigne),
      totalDiscount: parseToPrice(orderData.totalBri),
      totalVoucher: parseToPrice(orderData.totalBonsAchat),
      totalDiscountCode: parseToPrice(orderData.totalCodeReduction),
      statusIsKO: LIST_STATUS_KO.includes(orderData.statutCommande),
      type: orderData.catalog || 'PDV',
      seller: {
        id: orderData.seller?.id || 'ITM',
        name: orderData.seller?.name || 'Intermarché'
      },
      selectedShippingType: orderData.selectedShippingType as AllowedShippingTypes
    };

    if (orderData.dateAnnulation) {
      order.canceledDate = Date(orderData.dateAnnulation, 'YYYY-MM-DD HH:mm');
    }

    if (orderData.adresseLivraison) {
      order.deliveryAddress = AddressApi.parseAddress(
        'delivery',
        orderData.adresseLivraison
      ) as DeliveryAddress;
    }

    return order;
  };

  static confirmationDeliveriesType = (orders: Array<OrderDetail>) => {
    return {
      isDrive: !!orders?.find(
        (order) =>
          (order.seller.id === 'ITM' &&
            DELIVERY_TYPE_STORE_PICK_UP.includes(order.deliveryType)) ||
          (order.seller.id !== 'ITM' &&
            MKP_DELIVERY_TYPE_STORE_PICK_UP.includes(
              order.selectedShippingType
            ))
      ),
      isDelivery: !!orders?.find(
        (order) =>
          (order.seller.id === 'ITM' &&
            !DELIVERY_TYPE_STORE_PICK_UP.includes(order.deliveryType)) ||
          (order.seller.id !== 'ITM' &&
            !MKP_DELIVERY_TYPE_STORE_PICK_UP.includes(
              order.selectedShippingType
            ))
      )
    };
  };

  static normalizeToLegacy = (allData: any, order: any) => {
    const newOrder = {
      numeroCommande: order.id,
      backOfficeOrderId: order?.backOfficeOrderId ?? null,
      statutCommande: order.status,
      identifiantPdv: order.storeInfos?.storeId,
      nomMagasin: order.storeInfos?.modelLabel,
      typeLivraison: order.deliveryType,
      selectedShippingType: order.selectedShippingType,
      type: order.catalog,
      amount: order.amount,
      catalog: order.catalog,
      pdv: order.storeInfos
        ? {
            vocation: order.storeInfos.modelLabel,
            adresse1: order.storeInfos.address,
            codePostal: order.storeInfos.zipCode,
            commune: order.storeInfos.town
          }
        : null,
      creneauSouhaite: {
        date: Date(order.deliveryDate).format('YYYY-MM-DD'),
        heureDebut: order.deliveryTimeSlotStartDateTime,
        heureFin: order.deliveryTimeSlotEndDateTime
      },
      nbProduits: order.itemsNumber ?? order.items?.length,
      dateCreation: allData.createdDate,
      deliveryCost:
        order.catalog === 'MKP'
          ? order.selectedShippingPrice
          : order.deliveryCost,
      volumeExtraCost: order.volumeExtraCost,
      housingExtraCost: order.housingExtraCost,
      creditFid: order.valuations?.discountAmount,
      debitFid: order.valuations?.loyaltyAmount,
      totalCommande: order.amount,
      totalBri: order.discountCodeAmount,
      totalBonsAchat: order.voucherAmount,
      totalCodeReduction: order.discountCodeAmount,
      seller: order.seller,
      articles: order.items
    };
    return newOrder;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OrderApi();
