import { ReactComponent as ArrowIcon } from '@/assets/images/icons/arrow-down.svg';
import Modal from '@/components/organisms/modal';
import ProductApi from '@/lib/api/product';
import t from '@/lib/i18n';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import Title from '../title';
import './style.scss';

const formatAdditivesTab = (additives: any) => {
  const high: any = [];
  const moderate: any = [];
  const low: any = [];
  const safe: any = [];

  additives.forEach((value: any) => {
    switch (value.level.key) {
      case 'high':
        high.push(value);
        break;
      case 'moderate':
        moderate.push(value);
        break;
      case 'low':
        low.push(value);
        break;
      case 'safe':
        safe.push(value);
        break;
    }
  });
  return {
    high,
    moderate,
    low,
    safe,
    additives: [...high, ...moderate, ...low, ...safe]
  };
};

const AdditivCell = ({ additives, level }: any) => {
  const [show, setShow] = useState(false);

  return (
    <div className="additivCell">
      <div className="additivCell__header">
        <div className="additivCell__infos">
          <div className={classNames('additivCell__number', `level--${level}`)}>
            {additives.length}
          </div>
          <div className="additivCell__title">
            {t(`pdp.nutrition.additiv.${level}`)}
          </div>
        </div>
        {!!additives.length && (
          <div
            className={classNames({
              arrowIcon: true,
              'arrowIcon--open': show
            })}
            onClick={() => setShow(!show)}
          >
            <ArrowIcon width={15} height={15} fill="#000000" />
          </div>
        )}
      </div>
      {show && (
        <div className="additivCell__content">
          {additives
            .map((additive: any) => {
              return `${additive.name.display} (${additive.name.code})`;
            })
            .join(', ')}
        </div>
      )}
    </div>
  );
};

const AdditivesModalInfo = ({ additives }: any) => {
  const [state, setState] = useState({
    isOpen: false,
    infos: [],
    show: false,
    tab: null
  });

  useDeepCompareEffect(() => {
    (async () => {
      const data = await ProductApi.getAdditivesInfos(additives);
      if (data.results) {
        setState((s) => ({
          ...s,
          infos: data?.results,
          isHydrated: true
        }));
      }
    })();
  }, [additives]);

  return (
    <>
      <div
        className="additivBtn"
        onClick={() =>
          setState((s) => ({
            ...s,
            isOpen: !state.isOpen
          }))
        }
      >
        {t('gamesContest.seeMore')}
      </div>
      <Modal
        closable={true}
        open={state.isOpen}
        pageView="Additifs"
        onClose={() =>
          setState((s) => ({
            ...s,
            isOpen: !state.isOpen
          }))
        }
      >
        <>
          <Title tag="h3" label={t('pdp.nutrition.additiv.title')} />
          {!!state.infos.length &&
            formatAdditivesTab(state.infos).additives?.map(
              (adt: any, key: any) => (
                <div key={`key--${adt.level.key}`} className="additivCell">
                  <div className="additivCell__header">
                    <div className="additivCell__infos">
                      <div
                        className={classNames(
                          'additivCell__number',
                          `level--${adt.level.key}`
                        )}
                      />
                      <div className="additivCell__title">
                        {`${adt.name.display} (${adt.name.code})`}
                      </div>
                    </div>
                    <div
                      className={classNames({
                        arrowIcon: true,
                        'arrowIcon--open': key === state.tab
                      })}
                      onClick={() =>
                        setState((s) => ({
                          ...s,
                          tab: s.tab === key ? null : key,
                          show: false
                        }))
                      }
                    >
                      <ArrowIcon width={15} height={15} fill="#000000" />
                    </div>
                  </div>
                  {state.tab === key && (
                    <div className="additivCell__content">
                      {t(`pdp.nutrition.additiv.${adt.level.key}`)}
                      <p>{adt.name.description}</p>
                      <div className="additivCell__content__infos">
                        <div className="sources__title">
                          {t('pdp.nutrition.conservator')}
                        </div>
                        <div>{adt.type.description}</div>
                      </div>
                      {adt.sources && (
                        <div>
                          <div
                            className="sourceSelector"
                            onClick={() =>
                              setState((s) => ({
                                ...s,
                                show: !state.show
                              }))
                            }
                          >
                            <div className="sourceSelector__title">
                              {t('pdp.nutrition.sources')}
                            </div>
                            <div
                              className={classNames({
                                arrowIcon: true,
                                'arrowIcon--open': state.show
                              })}
                            >
                              <ArrowIcon
                                width={10}
                                height={10}
                                fill="#000000"
                              />
                            </div>
                          </div>
                          {state.show &&
                            adt.sources.map((source: any) => (
                              <div
                                key={`key--${source.year}`}
                                className="sources"
                              >
                                <div className="sources__title">
                                  {source.title}
                                </div>
                                <div className="sources__author">
                                  {source.author}
                                </div>
                                <div className="sources__publisher">
                                  {source.publisher}
                                </div>
                                <div className="sources__date">
                                  {source.year}
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )
            )}
        </>
      </Modal>
    </>
  );
};

const Additives = ({ additives, isCustom }: any) => {
  const additivTabs = formatAdditivesTab(additives);
  const additivInfos = additives.map((additive: any) => additive.name.key);

  return (
    <div>
      <div
        className={classNames('additivHead', `${!isCustom && '--not-custom'}`)}
      >
        <p
          className={classNames(
            'additivTitle',
            `${!isCustom && '--not-custom'}`
          )}
        >
          {t('pdp.nutrition.additiv.title')}
        </p>
        {!!additives.length && <AdditivesModalInfo additives={additivInfos} />}
      </div>
      <div
        className={classNames(
          'additivCell__container',
          `${isCustom && '--custom'}`
        )}
      >
        {!!additivTabs.high.length && (
          <AdditivCell additives={additivTabs.high} level="high" />
        )}
        {!!additivTabs.moderate.length && (
          <AdditivCell additives={additivTabs.moderate} level="moderate" />
        )}
        {!!additivTabs.low.length && (
          <AdditivCell additives={additivTabs.low} level="low" />
        )}
        {!!additivTabs.safe.length && (
          <AdditivCell additives={additivTabs.safe} level="safe" />
        )}
        {!additives.length && (
          <AdditivCell additives={additives} level="noAdditives" />
        )}
      </div>
    </div>
  );
};

export default Additives;
