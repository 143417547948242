import React from 'react';
import './ListNavPromotionsStyle.scss';
import Link from '@/components/atoms/link';
import NavPromotion from '@/lib/model/navPromotion';

type ListNavPromotionsProps = {
  navPromotions: NavPromotion[];
};

const ListNavPromotions = ({ navPromotions }: ListNavPromotionsProps) => {
  if (!navPromotions || navPromotions.length === 0) return null;
  return (
    <ul className="ListNavPromotions">
      {navPromotions.map((navPromotion) => (
        <li className="ListNavPromotions__item" key={navPromotion.id}>
          <Link
            className="ListNavPromotions__link"
            style={{
              backgroundImage: `url(${navPromotion.images?.principale})`
            }}
            href={`/shop/${navPromotion.id}`}
          >
            <span className="ListNavPromotions__title">
              {navPromotion.titre}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default ListNavPromotions;
