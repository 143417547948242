import { GlobalState } from '..';
import surveyData from '../../components/templates/smartconso/content/steps/survey.json';

const QUESTIONS_NUMBER = surveyData.questions.length;
export enum SurveyState {
  CONTINUE = 'CONTINUE',
  MODIFY = 'MODIFY',
  START = 'START'
}
export const selectSurvey = ({ survey }: GlobalState) => survey;

export const selectHasStartedSurvey = ({ survey }: GlobalState) =>
  survey.responses.length > 0;

export const selectHasFilledMandatoriesQuestions = ({ survey }: GlobalState) =>
  survey.hasPassedFirstStep;

export const selectHasCompletedSurvey = ({ survey }: GlobalState) =>
  survey.responses.length === QUESTIONS_NUMBER;

export const selectHasFilledAllAnswers = (state: GlobalState) => {
  const responsesCount = selectGetFilledAnswers(state).length;
  return responsesCount === QUESTIONS_NUMBER;
};

export const selectGetFilledAnswers = ({ survey }: GlobalState) =>
  survey.responses.filter((res) => res.responseValue || res.selectedChoices);

export const selectHasFinishedSurvey = ({ survey }: GlobalState) =>
  survey.finishAllSteps;

export const selectSurveyTranslationLabel = (state: GlobalState): string => {
  const responsesCount = selectGetFilledAnswers(state).length;
  if (responsesCount > 0 && responsesCount < 15) {
    return 'smartconso.landing.button.continue';
  }
  if (responsesCount === 15) {
    return 'smartconso.landing.button.modify';
  }
  return 'smartconso.landing.button.start';
};

export const selectSurveyTranslationState = (
  state: GlobalState
): SurveyState => {
  const responsesCount = selectGetFilledAnswers(state).length;
  if (responsesCount > 0 && responsesCount < 15) {
    return SurveyState.CONTINUE;
  }
  if (responsesCount === 15) {
    return SurveyState.MODIFY;
  }
  return SurveyState.START;
};
