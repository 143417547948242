import React, { useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import CommonSprite from '@/assets/images/sprites/common.svg';

export type CheckboxProps = {
  name: string;
  label: string;
  id: string;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
};

const Checkbox = React.forwardRef(
  (
    {
      disabled = false,
      name,
      label,
      id,
      className,
      checked = false,
      onChange
    }: CheckboxProps,
    ref: any
  ) => {
    const [check, setCheck] = useState(checked);

    useEffect(() => {
      setCheck(checked);
    }, [checked]);

    return (
      <div
        onClick={() => {
          if (disabled) {
            return;
          }
          setCheck(!check);
          if (onChange) {
            onChange(!check);
          }
        }}
        className={classnames(['checkbox', className], {
          'checkbox--isChecked': check,
          'checkbox--disabled': disabled,
          'checkbox--enabled': !disabled
        })}
        id={id}
      >
        <svg className="checkbox__icon" width="20" height="20">
          <use xlinkHref={`${CommonSprite}#checkBold`} />
        </svg>
        <input
          className="checkbox__input"
          disabled={disabled}
          type="checkbox"
          name={name}
          defaultChecked={check}
          onChange={(e) => setCheck(e.target.checked)}
          ref={ref}
        />
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </div>
    );
  }
);

export default Checkbox;
