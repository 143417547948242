import React from 'react';
import './style.scss';
import Modal from '@/components/organisms/modal';
import t from '@/lib/i18n';
import CartSprite from '@/assets/images/sprites/cart.svg';
import { AvailablePaymentsType } from '@/store/cart/types';

export type PaymentFallbackModalProps = {
  open: boolean;
  onClose: () => void;
  handleFallbackPayment: (paymentType: string) => void;
  availablePayments: AvailablePaymentsType;
};

const PaymentFallbackModal = ({
  open,
  onClose,
  handleFallbackPayment,
  availablePayments
}: PaymentFallbackModalProps) => {
  const paymentTypes = Object.keys(availablePayments);

  return (
    <Modal
      className="paymentFallbackErrorModal"
      cancelBtn={
        paymentTypes.includes('EN_LIGNE')
          ? {
              label: t('cart.payment.validation.fallback.reception.button'),
              onClick: () => {
                handleFallbackPayment('EN_LIGNE');
              },
              isLink: true
            }
          : undefined
      }
      confirmBtn={
        paymentTypes.includes('RECEPTION')
          ? {
              label: t('cart.payment.validation.fallback.pel.button'),
              onClick: () => {
                handleFallbackPayment('RECEPTION');
              }
            }
          : undefined
      }
      open={open}
      onClose={onClose}
    >
      <svg width={128} className="commonErrorModal__picto">
        <use xlinkHref={`${CartSprite}#warning`} />
      </svg>
      <strong className="commonErrorModal__title">
        {t('cart.payment.validation.fallback.title')}
      </strong>
      <p className="commonErrorModal__text">
        {t('cart.payment.validation.fallback.text')}
      </p>
    </Modal>
  );
};

export default PaymentFallbackModal;
