import React, { useEffect } from 'react';
import getConfigValue from '@/lib/config';
import Header from '@/components/organisms/header';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Layout from '@/components/organisms/layout';
import Head from 'next/head';
import loadScript from '@/lib/utils/loadScript';

export type CataloguesProps = {
  footer: ContentfulContent;
  paramBaseUrl: boolean;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace, @typescript-eslint/no-unused-vars
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface IntrinsicElements {
      'flb-root': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const Catalogues = ({ footer, paramBaseUrl }: CataloguesProps) => {
  const headerPart = <Header />;
  const contentPart = <flb-root />;
  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  const cacomScriptUrl = getConfigValue('CACOM_SCRIPT_URL', '').toString();
  const cacomStyleUrl = getConfigValue('CACOM_STYLE_URL', '').toString();
  const baseUrl = paramBaseUrl ? '/home/catalogs' : '/catalogs';

  useEffect(() => {
    loadScript(cacomScriptUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        {cacomScriptUrl && (
          <link
            crossOrigin="anonymous"
            rel="dns-prefetch"
            href={new URL(cacomScriptUrl).origin}
          />
        )}
        {cacomStyleUrl && <link rel="stylesheet" href={cacomStyleUrl} />}
        {cacomScriptUrl && (
          <link rel="preload" href={cacomScriptUrl} as="script" />
        )}
        <base href={baseUrl} key="baseUrl" />
      </Head>
      <Layout header={headerPart} content={contentPart} footer={footerPart} />
    </>
  );
};

export default Catalogues;
