import React from 'react';
import t from '@/lib/i18n';

type MyVisitMessagesProps = {
  countVisits?: number;
};

const MyVisitMessages = ({ countVisits }: MyVisitMessagesProps) => {
  if (countVisits === undefined) {
    return null;
  }

  return (
    <div className="myAccountDashboard__fidelity_visiteContent">
      {countVisits === 0 && (
        <span
          dangerouslySetInnerHTML={{
            __html: t(`myAccount.dashboard.fidelity.visiteContent.level-zero`)
          }}
        />
      )}
      {countVisits === 1 && (
        <span
          dangerouslySetInnerHTML={{
            __html: t(`myAccount.dashboard.fidelity.visiteContent.level-one`, {
              '%count%': 4 - countVisits
            })
          }}
        />
      )}
      {countVisits === 2 && (
        <span
          dangerouslySetInnerHTML={{
            __html: t(`myAccount.dashboard.fidelity.visiteContent.level-two`, {
              '%count%': 4 - countVisits
            })
          }}
        />
      )}
      {countVisits >= 3 && (
        <span
          dangerouslySetInnerHTML={{
            __html: t(`myAccount.dashboard.fidelity.visiteContent.level-max`)
          }}
        />
      )}
    </div>
  );
};

export default MyVisitMessages;
