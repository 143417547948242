import { useMediaQuery } from 'react-responsive';

const breakpoint = {
  mobile: '(max-width: 767px)',
  tabletPortrait: '(min-width: 768px)',
  tabletLandscape: '(min-width: 1024px)',
  desktop: '(min-width: 1280px)',
  desktopLarge: '(min-width: 1440px)'
};

export const mediaQueries = {
  mobile: { query: breakpoint.mobile },
  tabletPortrait: { query: breakpoint.tabletPortrait },
  tabletLandscape: { query: breakpoint.tabletLandscape },
  desktop: { query: breakpoint.desktop },
  desktopLarge: { query: breakpoint.desktopLarge }
};

export const useMobileMediaQuery = () => useMediaQuery(mediaQueries.mobile);

export const useTabletPortraitMediaQuery = () =>
  useMediaQuery(mediaQueries.tabletPortrait);

export const useTabletLandscapeMediaQuery = () =>
  useMediaQuery(mediaQueries.tabletLandscape);

export const useDesktopMediaQuery = () => useMediaQuery(mediaQueries.desktop);

export const useDesktopLargeMediaQuery = () =>
  useMediaQuery(mediaQueries.desktopLarge);
