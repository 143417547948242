import Product from '@/components/organisms/product';
import { useMobileMediaQuery } from '@/lib/hooks/useBreakpointMediaQuery';
import ProductModel from '@/lib/model/product';
import { PartenersDatasProps } from '@/lib/thirdPartyApi';
import {
  setLastEanOrSku,
  setSelectedProduct
} from '@/store/common/actions/common';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CriteoTiles from '../criteoTiles';
import HighCoTile from '../highCoTiles';
import LuckyCartCovers from '../luckyCartCovers';

export type ProductGridProps = {
  products: Array<ProductModel>;
  firstPage: number;
  perPage: number;
  partnersDatas: PartenersDatasProps;
  isPromoPage: boolean;
};

const pushPageInHistory = (page: number) => {
  const params = new URLSearchParams(window.location.search);

  if (params.get('page') === page.toString()) {
    return;
  }

  params.set('page', page.toString());

  const url = `${window.location.pathname}?${params.toString()}`;

  window.history.pushState({}, '', url);
};

const ProductGrid = React.memo(
  ({
    firstPage,
    perPage,
    products = [],
    partnersDatas,
    isPromoPage
  }: ProductGridProps) => {
    const dispatch = useDispatch();

    const [isMobile, setIsMobile] = useState(false);

    const mobileMediaQuary = useMobileMediaQuery();

    useEffect(() => {
      setIsMobile(mobileMediaQuary);
    }, [mobileMediaQuary]);

    return (
      <div
        className={classnames(
          'productList__grid',
          'productList__orderProducts'
        )}
      >
        {products.map((product: ProductModel, index: number) => {
          const currentIndex = (firstPage - 1) * perPage + index;
          const bestOfferProduct = product.bestOffer;
          const page = Math.floor(currentIndex / perPage) + 1;

          return (
            <Product
              key={product.id}
              className={classnames(
                'productList__grid__item',
                'productList__orderItem',
                { 'productList__grid__item--mobile': isMobile }
              )}
              data-page={page}
              beforeRedirect={() => {
                dispatch(
                  setLastEanOrSku(
                    product.type === 'MKP' ? product.id : product.ean
                  )
                );
                dispatch(setSelectedProduct(product));
                pushPageInHistory(page);
              }}
              data={product}
              forceMobileDisplay={isMobile}
              sellerId={bestOfferProduct?.seller.id}
              listPosition={index}
            />
          );
        })}
        <LuckyCartCovers
          partnersDatas={partnersDatas}
          isPromoPage={isPromoPage}
          productGridCount={products.length}
        />
        {!isPromoPage && (
          <>
            <HighCoTile
              partnersDatas={partnersDatas}
              productGridCount={products.length}
            />
            <CriteoTiles
              partnersDatas={partnersDatas}
              productGridCount={products.length}
            />
          </>
        )}
      </div>
    );
  }
);

export default ProductGrid;
