import FormInput from '@/components/atoms/formInput';
import React, { useCallback, useState } from 'react';
import t from '@/lib/i18n';

import { isValidNotPTZipCode, isValidZipCode } from '@/lib/utils/stringUtils';
import citiesApi from '@/lib/api/address/cities';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useIsomorphicLayoutEffect } from 'react-use';

type CityWithZipCodeProps = {
  zipCodeClassName: string;
  oldCity: string;
  onCitiesLoaded: (newCity: string) => void;
  cityClassName: string;
  errors: FieldErrors;
  control: Control<any>;
  getValues: () => { zipCode: string; country: string };
  register: (data: any) => any;
  trigger: (data: any) => any;
};

type StateType = {
  cities: Array<{ label: string; value: string }>;
  // zip:string
};

const CityWithZipCode = ({
  cityClassName,
  zipCodeClassName,
  getValues,
  register,
  oldCity,
  errors,
  control,
  onCitiesLoaded,
  trigger
}: CityWithZipCodeProps) => {
  const isPortugal = getValues().country === 'PRT';

  const getZipCodeErrorMessage = (): string => {
    return isPortugal
      ? t('form.error.isValidZipCode')
      : t('form.error.isValidNotPTZipCode');
  };

  const [, setState] = useState<StateType>({
    cities: []
    // zip:''
  });

  const loadCities = useCallback(
    async (zip) => {
      let newCities: Array<string> = [];

      if (zip.length >= 5) {
        try {
          newCities = await citiesApi.getCities(zip);
          if (newCities.length) {
            const newInitialCity = newCities.includes(oldCity)
              ? oldCity
              : newCities[0];
            onCitiesLoaded(newInitialCity);
          }
        } catch (error: any) {
          // Todo: handle user error display if unable to fetch cities
        }
      }

      setState((oldState) => {
        return {
          ...oldState,
          cities: newCities.map((newCity) => {
            return {
              label: newCity,
              value: newCity
            };
          })
        };
      });
    },
    [oldCity, onCitiesLoaded]
  );

  useIsomorphicLayoutEffect(() => {
    const { zipCode: zip } = getValues();
    if (zip?.length >= 5 && isPortugal) {
      loadCities(zip);
    }
  }, [isPortugal]);

  return (
    <>
      <div className="myAddressForm__inputGroup">
        <FormInput
          id="zipCode"
          name="zipCode"
          className={zipCodeClassName}
          autoComplete="postal-code"
          label={`${t('address.label.zipCode')} *`}
          maxLength={10}
          ref={register({
            required: true,
            validate: {
              isValidZipCode: (value: string) => {
                return getValues().country === 'PRT'
                  ? isValidZipCode.test(value)
                  : isValidNotPTZipCode.test(value);
              }
            }
          })}
          onChange={(e) => {
            trigger(['zipCode']);

            if (e.target.value?.length >= 5 && isPortugal) {
              const zip = e.target.value;
              if (zip[4] !== '-') {
                const firstFour = zip.substring(0, 4);
                const last = zip.substring(4, zip.length);
                const d = `${firstFour}-${last}`;
                e.target.value = d;
              }
              loadCities(e.target.value);
            }
          }}
          errorMessage={errors.zipCode ? getZipCodeErrorMessage() : ''}
        />
      </div>
      <div className="myAddressForm__inputGroup myAddressForm__city">
        <Controller
          control={control}
          name="city"
          rules={{ required: true }}
          render={({ onChange, value, name, ref }) => (
            <FormInput
              id={name}
              name={name}
              className={cityClassName}
              type="text"
              value={value}
              label={`${t('register.city.label')} *`}
              onChange={(newValue) => {
                onChange(newValue);
                trigger(['city']);
              }}
              ref={ref}
              errorMessage={
                errors.city ? t(`form.error.${errors.city.type}`) : ''
              }
            />
          )}
        />
      </div>
    </>
  );
};

export default CityWithZipCode;
