import { ReactComponent as ArrowRight } from '@/assets/images/icons/arrow-right.svg';
import CartSprite from '@/assets/images/sprites/cart.svg';
import Checkbox from '@/components/atoms/formCheckbox';
import Loader from '@/components/atoms/loader';
import Modal from '@/components/organisms/modal';
import t from '@/lib/i18n';
import DiscountCode from '@/lib/model/discountCode';
import { getTotalCart, isValidCartAmount } from '@/lib/utils/cart';
import { CartType } from '@/store/cart/types';
import classnames from 'classnames';
import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import './style.scss';

type BottomFooterProps = {
  isCheckout?: boolean;
  isMobile?: boolean;
  isDelivery?: boolean;
  cart: CartType;
  deliveryCost?: number;
  totalDiscount: string;
  isPayment?: boolean;
  href?: string;
  isLoading?: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  disabledButton?: boolean;
  ctaLabel?: string;
  discountCode?: DiscountCode | null;
  btnValidationLabel?: string;
  showPelInfo?: boolean;
};

const BottomFooter = ({
  cart,
  deliveryCost,
  totalDiscount,
  isPayment,
  href,
  isLoading,
  onClick,
  disabledButton,
  isMobile = false,
  isCheckout = true,
  discountCode = null,
  ctaLabel,
  btnValidationLabel,
  isDelivery = false,
  showPelInfo = false
}: BottomFooterProps) => {
  const [isCgvChecked, setIsCgvChecked] = useState(false);
  const cannotValidate =
    !isValidCartAmount(cart, discountCode) || (isPayment && !isCgvChecked);
  const [pelInfoDisplay, setPelInfoDisplay] = useState(showPelInfo);
  const [cgvModalOpen, setCgvModalOpen] = useState({
    desktop: false,
    responsive: false
  });
  const isThereMkp = cart.subCarts.some((s) => s.type === 'MKP');
  const isTherePdv = cart.subCarts.some((s) => s.type === 'PDV');

  const isPdvOnly = isTherePdv && !isThereMkp;
  const isMkpOnly = !isTherePdv && isThereMkp;

  const getCgvLabel = () => {
    if (isPdvOnly) return t('cart.payment.cgvPDV');
    if (isMkpOnly) return t('cart.payment.cgvMKP');
    return t('cart.payment.cgv');
  };

  const closeCgvModal = () => {
    setCgvModalOpen({
      desktop: false,
      responsive: false
    });
  };

  let btnLabel: string | ReactElement | undefined = btnValidationLabel;

  if (!btnValidationLabel) {
    if (isLoading) {
      btnLabel = <Loader size="small" />;
    } else if (!isPayment && !isDelivery) {
      btnLabel = !isMobile ? t(`${ctaLabel}`) : t('cart.footer.step');
    } else if (isPayment && !isDelivery) {
      btnLabel = !isMobile ? t('cart.footer.order') : t('cart.footer.step');
    } else if (!isPayment && isDelivery) {
      btnLabel = ctaLabel;
    }
  }

  const handleFinalizeOrder = (event: MouseEvent<HTMLAnchorElement>) => {
    if (cannotValidate || disabledButton || isLoading) event.preventDefault();
    if (!cannotValidate && !disabledButton) onClick?.(event);
  };

  useEffect(() => {
    if (isThereMkp && isTherePdv) {
      const linkResponsive = document.querySelector(
        '.cartBottom__isResponsive a.cart_cgv_link'
      );
      const linkDesktop = document.querySelector(
        '.cartBottom__isDesktop a.cart_cgv_link'
      );

      linkResponsive?.addEventListener('click', (e) => {
        e.preventDefault();
        setCgvModalOpen({ desktop: false, responsive: true });
      });
      linkDesktop?.addEventListener('click', (e) => {
        e.preventDefault();
        setCgvModalOpen({ desktop: true, responsive: false });
      });
    }
  }, [isThereMkp, isTherePdv]);
  return (
    <>
      <div
        className={classnames('cartBottom', {
          'cartBottom--isCheckout': isCheckout,
          cartBottom__isResponsive: isMobile,
          cartBottom__isDesktop: !isMobile,
          cartBottom__disabled: disabledButton || cannotValidate
        })}
      >
        {pelInfoDisplay && isThereMkp && (
          <div className="cart__footer__message">
            <svg className="cart__footer__picto" width={20} height={20}>
              <use xlinkHref={`${CartSprite}#info`} />
            </svg>
            {t('miniCart.info.onlyPel')}
            {isCheckout && (
              <div
                className="cart__footer__close"
                onClick={() => isCheckout && setPelInfoDisplay(false)}
              >
                X
              </div>
            )}
          </div>
        )}
        <div className="cartBottom__wrapper">
          {isPayment && (
            <div className="cart__check">
              <Checkbox
                onChange={(e) => setIsCgvChecked(e)}
                id="cgv"
                name="cgv"
                checked={isCgvChecked}
                label={getCgvLabel()}
              />
            </div>
          )}
          <div className="cartBottom__info">
            <div className="cartBottom__total">
              <span>{t('cart.price.total')}</span>
              <span id="cartPriceTotal">
                {t('cart.info.price', {
                  '%price%': getTotalCart(cart, discountCode, deliveryCost)
                    .toFixed(2)
                    .replace('.', ',')
                })}
              </span>
            </div>
            <div className="cartBottom__sub">
              <span>
                {t('cart.info.totalProducts', {
                  '%number%': cart.qty
                })}
              </span>
              <span>
                {t('cart.info.totalSavings', {
                  '%number%': totalDiscount
                })}
              </span>
            </div>
          </div>
          <a
            href={href}
            onClick={handleFinalizeOrder}
            className="cartBottom__link"
            id={!isMobile ? 'cartBottomLinkValidate' : 'cartBottomLinkStep'}
          >
            {btnLabel}
          </a>
        </div>
      </div>
      <Modal
        className="cgv_modal"
        open={isMobile ? cgvModalOpen.responsive : cgvModalOpen.desktop}
        scrollable={false}
        onClose={closeCgvModal}
      >
        <>
          <h3 className="cgv_modal_title">
            {t('cart.payment.modal.cgv.title')}
          </h3>
          <div className="cgv_modal_content">
            <div className="cgv_modal_content_link">
              <a target="_blank" rel="noopener noreferrer" href="/stores/tos">
                {t('cart.payment.modal.cgvPDV')}
              </a>
              <ArrowRight className="cgv_modal_content_link_icon" />
            </div>
            <div className="cgv_modal_content_link">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/legal-informations/marketplace-terms-and-conditions"
              >
                {t('cart.payment.modal.cgvMKP')}
              </a>
              <ArrowRight
                className="cgv_modal_content_link_icon"
                width="14"
                height="14"
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default BottomFooter;
