import usePageView from '@/lib/hooks/usePageView';
import { useEffect } from 'react';

type SearchWithoutResultsPageViewSenderProps = {
  type: string;
  keyword: string;
  suggestions: any;
  baseUrl: string;
};

const SearchWithoutResultsPageViewSender = ({
  type,
  keyword,
  suggestions,
  baseUrl
}: SearchWithoutResultsPageViewSenderProps) => {
  const pageView = usePageView();

  useEffect(() => {
    if (type === 'search' && keyword && suggestions) {
      pageView.send(type, {
        list: { meta: { page: 0, total: 0 } },
        baseUrl,
        type,
        keyword,
        filters: []
      });
    }
  }, [type, keyword, suggestions, pageView, baseUrl]);

  return null;
};

export default SearchWithoutResultsPageViewSender;
