import React, { memo } from 'react';
import Title from '@/components/atoms/title';
import classnames from 'classnames';
import './style.scss';
import DATA, { PresentationDataListItemProps } from './data';

export type StickersPresentationProps = {
  pdvIsActivated: boolean;
};

const StickersPresentation = ({
  pdvIsActivated
}: StickersPresentationProps) => {
  const PRESENTATION_DATA = pdvIsActivated
    ? DATA.PRESENTATION_DATA_PDV_ACTIVATED
    : DATA.PRESENTATION_DATA_PDV_NOT_ACTIVATED;

  return (
    <div className="stickersPresentation">
      <Title
        label={PRESENTATION_DATA.title}
        tag="h1"
        className="stickersPresentation__title"
      />
      <div className="stickersPresentation__headings">
        <span>{PRESENTATION_DATA.headings.small}</span>
        {PRESENTATION_DATA.headings.big && (
          <div className="stickersPresentation__bloc_title">
            <span>{PRESENTATION_DATA.headings.big}</span>
          </div>
        )}
      </div>
      {PRESENTATION_DATA.list.map(
        ({ image, title, subTitle, index }: PresentationDataListItemProps) => (
          <div
            className="stickersPresentation__bloc_item"
            key={`sticker-presentation-${index}`}
          >
            {image && (
              <div className="stickersPresentation__image">
                <svg height="50" width="80">
                  <use xlinkHref={image} />
                </svg>
              </div>
            )}
            <div
              className={classnames('stickersPresentation__text', {
                'stickersPresentation__text--emptyImage': image === undefined
              })}
            >
              <span className="stickersPresentation__bloc_title">{title} </span>
              <span className="stickersPresentation__bloc_subTitle">
                {subTitle}
              </span>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default memo(StickersPresentation);
