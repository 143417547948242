import React from 'react';
import { CartType } from '@/store/cart/types';
import Accordion from '@/components/molecules/accordion';
import t from '@/lib/i18n';
import MkpInfos from './mkpInfos';
import './style.scss';
import ItmInfos from './itmInfos';

type DeliveriesInfosProps = {
  cart: CartType;
  currentSellerId?: string;
  isSlots?: boolean;
};

const DeliveriesInfos = ({
  cart,
  currentSellerId,
  isSlots
}: DeliveriesInfosProps) => {
  const subCartItm = cart.subCarts.find(
    (subCart) => subCart.seller.sellerId === 'ITM'
  );
  if (!cart.mkpPackages && !subCartItm) return null;
  const mkpSellerIdWithProvider = Object.keys(
    cart.mkpPackages || {}
  ).filter((sellerIdKey) =>
    Object.keys(cart.mkpPackages?.[sellerIdKey].packages || {}).find(
      (packageId) =>
        cart.mkpPackages?.[sellerIdKey].packages[packageId].selectedProvider
    )
  );

  return (
    <>
      {subCartItm && (
        <Accordion
          clickArrowToToogle
          openIndex={currentSellerId || (isSlots ? 'ITM' : undefined)}
          className="deliveriesInfos__accordion"
          itemsList={[
            {
              id: subCartItm.seller.sellerId,
              title: t('cart.info.delivery', {
                '%sellerName%': subCartItm.seller.sellerName
              }),
              htmlBody: (
                <ItmInfos
                  cart={cart}
                  currentSellerId={
                    currentSellerId || (isSlots ? 'ITM' : undefined)
                  }
                />
              ),
              classNameItem: 'deliveriesInfos__accordion__item',
              classNameTitle: 'deliveriesInfos__accordion__title'
            }
          ]}
        />
      )}
      {mkpSellerIdWithProvider.map((sellerId) => {
        return (
          <div key={sellerId}>
            <Accordion
              clickArrowToToogle
              openIndex={currentSellerId}
              className="deliveriesInfos__accordion"
              updateIndexOnChange
              itemsList={[
                {
                  id: sellerId,
                  title: t('cart.info.delivery', {
                    '%sellerName%':
                      cart.mkpPackages?.[sellerId].seller.sellerName
                  }),
                  htmlBody: (
                    <MkpInfos
                      cart={cart}
                      sellerId={sellerId}
                      currentSellerId={currentSellerId}
                    />
                  ),
                  classNameItem: 'deliveriesInfos__accordion__item',
                  classNameTitle: 'deliveriesInfos__accordion__title'
                }
              ]}
            />
          </div>
        );
      })}
    </>
  );
};

export default DeliveriesInfos;
