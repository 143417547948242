import React from 'react';
import RegisterSprite from '@/assets/images/sprites/register.svg';
import './style.scss';
import Link from '@/components/atoms/link';

type ValidationProps = {
  title: string;
  subtitle: string;
  description: string;
  message: string;
  cta: string;
};
const Validation = ({
  title,
  subtitle,
  description,
  message,
  cta
}: ValidationProps) => {
  return (
    <div className="validationWrapper">
      <div className="validationStep">
        <div className="title">{title}</div>
        <div className="box">
          <div className="icon">
            <svg height="150" width="150">
              <use xlinkHref={`${RegisterSprite}#confirmation`} />
            </svg>
          </div>
          <div className="card">
            <div className="card__title">{subtitle}</div>
            <div
              className="card__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
        <div className="subtitle">{message}</div>
        <div className="footer">
          <div className="footer__btn">
            <Link label={cta} href="/home" button id="startShopping" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Validation;
