import React from 'react';
import Layout from '@/components/organisms/layout';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Header from '@/components/organisms/header';
import Steps from './steps';

export type CardCreationJourneyProps = {
  footer: ContentfulContent;
  content: ContentfulContent;
};

const CardCreationJourney = ({ footer, content }: CardCreationJourneyProps) => {
  const headerPart = <Header />;
  const contentPart = <Steps content={content} />;

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout
      className="cardCreationJourney"
      header={headerPart}
      content={contentPart}
      footer={footerPart}
    />
  );
};

export default CardCreationJourney;
