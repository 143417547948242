import SmartConsoSprite from '@/assets/images/sprites/smartconso.svg';
import FormInput from '@/components/atoms/formInput';
import t from '@/lib/i18n';
import {
  UpdateSurveyAction,
  UPDATE_SURVEY_ANSWER
} from '@/store/survey/actions';
import classNames from 'classnames';
import _debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import StepHeader from '../stepHeader';
import { SharedProps } from '../types';
import './style.scss';

const DateForm: React.FC<SharedProps> = (props) => {
  const { register } = useForm({
    shouldUnregister: false,
    mode: 'onChange'
  });
  const { currentResponse, dispatch, questionId } = props;
  const defaultValue = currentResponse?.responseValue || '';
  const dateLimitMin = 1900;
  const dateLimitMax = new Date().getUTCFullYear() - 18;

  const [errorMessage, setErrorMessage] = useState('');

  const onChange: React.ChangeEventHandler<HTMLInputElement> = _debounce(
    (event) => {
      const date = Number(event.target.value);
      const updateValue = (newValue?: string) => {
        dispatch({
          type: UPDATE_SURVEY_ANSWER,
          payload: {
            response: {
              questionId,
              responseValue: newValue
            }
          }
        } as UpdateSurveyAction);
      };
      if (!date || date < dateLimitMin || date > new Date().getUTCFullYear()) {
        updateValue(undefined);
        setErrorMessage(t('register.form.year.error'));
      } else if (date > dateLimitMax) {
        updateValue(undefined);
        setErrorMessage(t('register.form.year.errorNotInAge'));
      } else {
        updateValue(date.toString());
        setErrorMessage('');
      }
    },
    300
  );

  return (
    <form className="smartconso-date">
      <StepHeader {...props} />
      <div
        className={classNames('formDate smartconso-date__container', {
          'formDate--hasError': !!errorMessage
        })}
      >
        <div className="smartconso-date__svg">
          <svg width="24px" height="24px">
            <use xlinkHref={`${SmartConsoSprite}#calendar`} />
          </svg>
        </div>
        <FormInput
          className="smartconso-date__input"
          type="text"
          id="formDateInputsYear"
          placeholder={t('smartconsoForm.dateInput.placeholder')}
          name="date"
          maxLength={4}
          value={defaultValue}
          onChange={onChange}
          ref={register({
            required: true
          })}
          errorMessage={errorMessage}
        />
      </div>
    </form>
  );
};

export default DateForm;
