import { ReactComponent as CheckIcon } from '@/assets/images/icons/check.svg';
import MyAccountSprite from '@/assets/images/sprites/myAccount.svg';
import Button from '@/components/atoms/button';
import Link from '@/components/atoms/link';
import LoadableContent from '@/components/atoms/loadableContent';
import Modal from '@/components/organisms/modal';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import OrderStepper from '@/components/organisms/myAccount/orderStepper';
import { SimpleFullMainNav } from '@/lib/api/categories';
import orderApi from '@/lib/api/order';
import {
  OrderDetail as OrderDetailType,
  OrderItem
} from '@/lib/api/order/types';
import { Content as ContentfulContent } from '@/lib/contentful';
import useEvent from '@/lib/hooks/useEvent';
import usePdv from '@/lib/hooks/usePdv';
import t from '@/lib/i18n';
import { notification } from '@/lib/notification';
import { fixDecimalCost } from '@/lib/utils/stringUtils';
import { updateCartQuantity } from '@/store/cart/actions/cart';
import { SubCartType } from '@/store/cart/types';
import useShallowEqualSelector from '@/lib/utils/useShallowEqualSelector';

import React, { useCallback, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import CancelOrder from '../cancel';
import Item from './item';
import './style.scss';

export type OrderDetailProps = {
  pdvRef: string;
  orderId: string;
  footer: ContentfulContent;
  pageView: string;
};

type StateType = {
  isLoading: boolean;
  addToCartLoading: boolean;
  itemsByCategories: Array<ProductsByCategoryType>;
  order: OrderDetailType | null;
  isOpen: boolean;
  availableProducts: number | null;
  filteredOrderPrice: string | null;
};

export type ProductsByCategoryType = SimpleFullMainNav & {
  items?: Array<OrderItem>;
};

const OrderDetail = ({
  orderId,
  pdvRef,
  footer,
  pageView
}: OrderDetailProps) => {
  const { userId, categories, cartStore } = useShallowEqualSelector(
    ({ auth, nav, cart }) => {
      return {
        userId: auth.user?.id,
        categories: nav.mainCategories,
        cartStore: cart
      };
    }
  );
  if (!userId) return null;
  const dispatch = useDispatch();
  const [state, setState] = useState<StateType>({
    isLoading: true,
    addToCartLoading: false,
    itemsByCategories: [],
    order: null,
    isOpen: false,
    availableProducts: null,
    filteredOrderPrice: null
  });

  const load = useCallback(async () => {
    setState((oldState) => ({
      ...oldState,
      isLoading: true
    }));

    const { ref } = usePdv();

    if (!ref) return;

    const pdvMainCategories = categories[ref] ?? null;

    if (!pdvMainCategories) return;

    let order: OrderDetailType | null = null;

    let itemsByCategories: Array<ProductsByCategoryType> = [
      ...pdvMainCategories?.data,
      {
        key: 'other',
        title: t('common.other'),
        picto: null
      }
    ];

    try {
      order = await orderApi.read(userId, orderId, pdvRef);
    } catch (error: any) {
      notification.error(t('notification.erreur'));
    }

    order?.items.forEach((item) => {
      const key =
        itemsByCategories.filter(
          (c) => c.key === String(item.product.universId)
        ).length > 0
          ? String(item.product.universId)
          : 'other';

      itemsByCategories = itemsByCategories.map((cat) => {
        const newCat = {
          ...cat
        };

        if (newCat.key === key) {
          newCat.items = [...(newCat?.items ?? []), item];
        }

        return newCat;
      });
    });

    const itmCart = cartStore.subCarts.find(
      (subCart: SubCartType) => subCart.seller.sellerId === 'ITM'
    );

    const items = order?.items.map((item) => {
      const quantity = itmCart?.items[item.product.id]?.qty
        ? itmCart?.items[item.product.id].qty + item.quantity
        : item.quantity;
      return {
        product: item.product,
        quantity,
        total: item.total,
        sellerId: 'ITM'
      };
    });

    const itemsAvailable = items?.filter(
      (item) => !!item.product.maxQty && item.product.available
    );

    const filteredPrice =
      itemsAvailable && itemsAvailable.length > 0
        ? fixDecimalCost(
            itemsAvailable
              ?.map(({ total }) => total)
              .reduce((acc, product) => {
                return acc + product;
              })
          )
        : null;

    setState((oldState) => ({
      ...oldState,
      isLoading: false,
      order,
      itemsByCategories: itemsByCategories.filter(
        (itemByCategory) => !!itemByCategory.items?.length
      ),
      filteredOrderPrice: filteredPrice,
      availableProducts:
        itemsAvailable && itemsAvailable.length > 0
          ? itemsAvailable.length
          : null
    }));
  }, [userId, categories, cartStore.subCarts, orderId, pdvRef]);

  const event = useEvent();

  const reorder = async () => {
    if (!state.order) return;

    setState((oldState) => ({
      ...oldState,
      addToCartLoading: true
    }));

    const itmCart = cartStore.subCarts.find(
      (subCart: SubCartType) => subCart.seller.sellerId === 'ITM'
    );

    const items = state.order.items.map((item) => {
      const quantity = itmCart?.items[item.product.id]?.qty
        ? itmCart?.items[item.product.id].qty + item.quantity
        : item.quantity;
      return {
        product: item.product,
        quantity,
        sellerId: 'ITM'
      };
    });

    try {
      await dispatch(updateCartQuantity(items));
      event.send('api', {
        name: 'reorder',
        type: 'success'
      });
    } catch (error: any) {
      event.send('api', {
        name: 'reorder',
        type: 'error'
      });
    }

    setState((oldState) => ({
      ...oldState,
      addToCartLoading: false
    }));
  };

  useDeepCompareEffect(() => {
    (async () => {
      await load();
    })();
  }, [userId, categories, orderId, pdvRef]);

  const contentPart = (
    <LoadableContent className="myAccountOrder" loading={state.isLoading}>
      <>
        {state.order && (
          <>
            <div className="myAccountOrder__status">
              {['withdrawal', 'delivered'].includes(state.order.status) && (
                <>
                  <CheckIcon
                    className="myAccountOrder__picto-validated"
                    height={25}
                    width={25}
                  />
                  <div className="myAccountOrder__deliveredAt">
                    {t(
                      `myAccount.order.${
                        state.order.deliveryType === 'delivery'
                          ? 'deliveredAt'
                          : 'withdrawalAt'
                      }`,
                      {
                        '%date%': state.order.slot.begin.format('DD/MM/YYYY')
                      }
                    )}
                  </div>
                  <div className="myAccountOrder__validatedAt">
                    {t('myAccount.order.validatedAt', {
                      '%date%': state.order.createdAt.format('DD/MM/YYYY')
                    })}
                  </div>
                </>
              )}
              {!['withdrawal', 'delivered'].includes(state.order.status) && (
                <OrderStepper order={state.order} />
              )}
            </div>
            <div className="myAccountOrder__row">
              <div className="myAccountOrder__address">
                <svg height="34" width="34">
                  <use xlinkHref={`${MyAccountSprite}#store`} />
                </svg>
                {state.order.deliveryType !== 'delivery' && (
                  <>
                    <div className="myAccountOrder__title">
                      {t('myAccount.order.storeMode')}
                    </div>
                    <div>
                      {state.order.pdv.format} {state.order.pdv.city}
                      <br />
                      {state.order.pdv.address} <br />
                      {state.order.pdv.zipCode} {state.order.pdv.city}
                    </div>
                  </>
                )}
                {state.order.deliveryType === 'delivery' &&
                  state.order.deliveryAddress && (
                    <>
                      <div className="myAccountOrder__title">
                        {t('myAccount.order.deliveryMode')}
                      </div>
                      <div>
                        {state.order.deliveryAddress.streetName} <br />
                        {state.order.deliveryAddress.additionalStreetName && (
                          <>
                            {state.order.deliveryAddress.additionalStreetName}{' '}
                            <br />
                          </>
                        )}
                        {state.order.deliveryAddress.zipCode}{' '}
                        {state.order.deliveryAddress.city}
                      </div>
                    </>
                  )}
              </div>
              <div className="myAccountOrder__mode">
                <svg height="34" width="34">
                  <use xlinkHref={`${MyAccountSprite}#logistic`} />
                </svg>

                <div className="myAccountOrder__title">
                  {t(`myAccount.order.withdrawalMode`)}
                </div>
                <div className="myAccountOrder__deliveryMode">
                  {t(
                    `myAccount.order.${
                      state.order.deliveryType === 'delivery'
                        ? 'deliveredAt'
                        : 'withdrawalAt'
                    }.full`,
                    {
                      '%date%': state.order.slot.begin.format('DD/MM/YYYY'),
                      '%hours%': state.order.slot.begin.format('HH'),
                      '%minutes%': state.order.slot.begin.format('mm')
                    }
                  )}
                </div>
              </div>
              <div className="myAccountOrder__billing">
                <svg height="34" width="34">
                  <use xlinkHref={`${MyAccountSprite}#euro`} />
                </svg>
                <div className="myAccountOrder__title">
                  {t('myAccount.order.paymentMode')}
                </div>
                <div>
                  {t(`myAccount.order.paymentType.${state.order.paymentType}`)}
                </div>
                {!!state.order.billingUrl && (
                  <Link href={state.order.billingUrl} target="_blank">
                    <>
                      {t('myAccount.order.downloadBilling')}
                      <svg
                        className="myAccountOrder__picto-download"
                        width="30"
                        height="20"
                      >
                        <use xlinkHref={`${MyAccountSprite}#download`} />
                      </svg>
                    </>
                  </Link>
                )}
              </div>
            </div>
            <div className="myAccountOrder__content">
              <div className="myAccountOrder__items">
                {state.itemsByCategories.map(
                  (itemsByCategory: ProductsByCategoryType) => {
                    const itemsByCategoryKey = `order-category-${itemsByCategory.key}`;

                    if (!itemsByCategory.items?.length) return null;

                    return (
                      <div
                        className="myAccountOrder__category"
                        key={itemsByCategoryKey}
                      >
                        <div className="myAccountOrder__category_name">
                          {itemsByCategory.picto && (
                            <img
                              className="myAccountOrder__category_picto"
                              src={itemsByCategory.picto}
                              alt=""
                              width={25}
                              height={25}
                            />
                          )}
                          {itemsByCategory.title}&nbsp;
                          {t(
                            `myAccount.lists.numberOfArticle${
                              itemsByCategory.items.length > 1 ? 's' : ''
                            }`,
                            {
                              '%qty%': itemsByCategory.items.length
                            }
                          )}
                        </div>
                        {itemsByCategory.items.map((item: OrderItem) => {
                          const productKey = `order-item-${item.product.ean}`;

                          return (
                            <div
                              key={productKey}
                              className="myAccountOrder__item"
                            >
                              <Item data={item} />
                            </div>
                          );
                        })}
                      </div>
                    );
                  }
                )}
              </div>
              <div className="myAccountOrder__recap">
                <div className="myAccountOrder__total">
                  <div className="myAccountOrder__total_label">
                    {t('myAccount.order.total')}
                  </div>
                  <div className="myAccountOrder__total_amount">
                    {fixDecimalCost(state.order.total.value)}
                    &euro;
                  </div>
                </div>
                {!!state.order.deliveryCost?.value && (
                  <div className="myAccountOrder__fees">
                    <div className="myAccountOrder__fees_label">
                      {t('myAccount.order.fees')}
                    </div>
                    <div className="myAccountOrder__fees_dots" />
                    <div>
                      + {fixDecimalCost(state.order.deliveryCost.value)}
                      &euro;
                    </div>
                  </div>
                )}
                {!!state.order.totalDiscount?.value && (
                  <div className="myAccountOrder__discount">
                    <div className="myAccountOrder__discount_label">
                      {t('myAccount.order.discount')}
                    </div>
                    <div className="myAccountOrder__discount_amount">
                      {fixDecimalCost(state.order.totalDiscount.value)}
                      &euro;
                    </div>
                  </div>
                )}
                {!!state.order.creditFid?.value && (
                  <div className="myAccountOrder__fidelity">
                    <div className="myAccountOrder__fidelity_label">
                      {t('myAccount.order.fidelity')}
                    </div>
                    <div className="myAccountOrder__fidelity_amount">
                      {fixDecimalCost(state.order.creditFid.value)}
                      &euro;
                    </div>
                  </div>
                )}
                {state.order.status === 'validated' && (
                  <CancelOrder
                    className="myAccountOrder__cancel"
                    orderId={state.order.id}
                    onDeleted={() => load()}
                    deleteOrder={async () => {
                      await orderApi.delete(userId, orderId);
                    }}
                  />
                )}
                {!['validated', 'preparing', 'prepared', 'delivering'].includes(
                  state.order.status
                ) &&
                  dayjs().diff(state.order.createdAt, 'months') <= 6 && (
                    <Button
                      className="myAccountOrder__addToCart"
                      onClick={() => setState((s) => ({ ...s, isOpen: true }))}
                    >
                      {t('myAccount.order.addToCart')}
                    </Button>
                  )}
              </div>
              <Modal
                pageView="ReOrder"
                confirmBtn={{
                  label: t('common.validate'),
                  loading: state.addToCartLoading,
                  onClick: async () => {
                    await reorder();
                    setState((s) => ({
                      ...s,
                      isOpen: false,
                      isLoading: false
                    }));
                  }
                }}
                cancelBtn={{
                  label: t('common.cancel'),
                  onClick: () => {
                    setState((s) => ({
                      ...s,
                      isOpen: false,
                      isLoading: false
                    }));
                  }
                }}
                onClose={() =>
                  setState((s) => ({
                    ...s,
                    isOpen: false
                  }))
                }
                open={state.isOpen}
              >
                <div className="reorderModalContent">
                  {state.availableProducts ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'myAccount.lists.shoppingList.reorder.message',
                          {
                            '%newOrder%': state.availableProducts,
                            '%previousOrder%': state.order.items.length,
                            '%price%': state.filteredOrderPrice
                          }
                        )
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'myAccount.lists.shoppingList.reorder.message',
                          {
                            '%newOrder%': state.order.items.length,
                            '%previousOrder%': state.order.items.length,
                            '%price%': state.order.total.value
                          }
                        )
                      }}
                    />
                  )}
                </div>
              </Modal>
            </div>
          </>
        )}
      </>
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      title={
        state.order
          ? t('myAccount.order.title', { '%orderId%': state.order.id })
          : ''
      }
      enabledId="orders"
      content={contentPart}
      footer={footer}
      backTo={{
        label: t('myAccount.order.backToCommands'),
        href: '/account-management/my-groceries'
      }}
      pageView={pageView}
    />
  );
};

export default OrderDetail;
