import React from 'react';
import './style.scss';
import Layout from '@/components/organisms/layout';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Header from '@/components/organisms/header';
import Title from '@/components/atoms/title';
import Link from '@/components/atoms/link';
import t from '@/lib/i18n';

export type ConfirmationProps = {
  footer: ContentfulContent;
};

const Confirmation = ({ footer }: ConfirmationProps) => {
  const headerPart = <Header />;

  const contentPart = (
    <>
      <Title
        tag="h2"
        label={t('formContactUs.contact.confirmation')}
        className="confirmationContactUs__title"
      />
      <div className="confirmationContactUs__link">
        <Link href="/home" label={t('forgottenPassword.goBack.label')} button />
      </div>
    </>
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout
      className="confirmationContactUs"
      header={headerPart}
      content={contentPart}
      footer={footerPart}
    />
  );
};

export default Confirmation;
