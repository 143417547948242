import React, { Key } from 'react';
import './style.scss';
import Slider from '@/components/molecules/slider';
import { SliderType } from '@/components/molecules/slider/types';
import Image from '@/components/atoms/image';
import Tile from '@/components/atoms/tile';

export type Slide = {
  title: string;
  image: string;
  type: string;
  categoryId?: number | null;
  allCategories: boolean;
};

export type CategorySliderProps = {
  className: string;
  slides: Array<Slide>;
};

const SliderConfig = {
  type: 'category' as SliderType,
  showDots: false,
  deviceType: 'desktop-xlarge',
  slidesToSlide: 1,
  responsive: {
    'desktop-xlarge': {
      breakpoint: { max: 7000, min: 1280 },
      items: 7
    },
    'desktop-large': {
      breakpoint: { max: 1280, min: 1024 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 1024, min: 880 },
      items: 7
    },
    'tablet-large': {
      breakpoint: { max: 880, min: 620 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 620, min: 460 },
      items: 3
    },
    'mobile-large': {
      breakpoint: { max: 460, min: 320 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 320, min: 0 },
      items: 1
    }
  }
};

const CategorySlider = ({ className, slides = [] }: CategorySliderProps) => {
  return (
    <Slider className={className} {...SliderConfig}>
      {slides.map((slide: Slide, index: Key) => {
        const key = `slide-${index}`;
        const { image, title, categoryId } = slide;
        let url = '/#';

        switch (slide.type) {
          case 'promotions':
            url = '/shop/2119';
            break;
          case 'univers':
            url = `/univers/${categoryId}`;
            break;
          case 'tous les rayons':
            url = `/univers`;
            break;
        }

        return (
          <Tile
            key={key}
            isTextFirst
            className="categorySlider__slide"
            imageContent={
              (image && (
                <Image src={image} alt="" width={65} height={65} lazy={false} />
              )) ||
              null
            }
            textContent={
              <strong
                className="categorySlider__title"
                dangerouslySetInnerHTML={{
                  __html: title
                }}
              />
            }
            linkAttributes={{
              href: url,
              className: 'categorySlider__link'
            }}
          />
        );
      })}
    </Slider>
  );
};

export default CategorySlider;
