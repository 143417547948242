import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import t from '@/lib/i18n';
import { AuthStateType } from '@/store/auth/authReducer';
import Button from '@/components/atoms/button';
import UserInformationApi from '@/lib/api/userInformation';
import NewsletterApi from '@/lib/api/newsletter';
import useEvent from '@/lib/hooks/useEvent';
import EmailOptinModal from '../emailOptinModal';

export type NewsletterProps = {
  code: string;
  translateKey: string;
  icon: JSX.Element;
  subscribed: boolean;
  disabled: boolean;
};

const Newsletter = ({
  code,
  translateKey,
  icon,
  subscribed,
  disabled
}: NewsletterProps) => {
  const [state, setState] = useState({
    hasSubscribed: subscribed,
    switching: false,
    hasEmailOptinIssue: false
  });
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const event = useEvent();

  const toggleNewsletter = async () => {
    if (!userId) {
      return;
    }

    setState({
      ...state,
      switching: true
    });

    let emailOptinIssue = false;
    let subscribedValue = state.hasSubscribed;

    try {
      const userInformation = await UserInformationApi.getInformations(userId);

      if (!userInformation.optIn.itmEmail) {
        emailOptinIssue = true;
      } else {
        subscribedValue = !state.hasSubscribed;
        await NewsletterApi.update(userId, code, subscribedValue);
        event.send('account', {
          type: subscribedValue ? 'subscribeNews' : 'unsubscribeNews',
          newsName: translateKey
        });
      }
    } catch (error: any) {
      // Todo: handle user error display unable to toggle to newsletter status
    }

    setState({
      ...state,
      switching: emailOptinIssue,
      hasSubscribed: subscribedValue,
      hasEmailOptinIssue: emailOptinIssue
    });
  };

  return (
    <>
      <div key={translateKey} className="myNewsletters__bloc">
        <div className="myNewsletters__bloc_heading">
          {icon}
          <div className="myNewsletters__bloc_title">
            {t(`myAccount.myNewsletters.${translateKey}.title`)}
          </div>
        </div>
        <div className="myNewsletters__bloc_message">
          {t(`myAccount.myNewsletters.${translateKey}.message`)}
        </div>
        <Button
          className={classnames('myNewsletters__bloc_btn', {
            'myNewsletters__bloc_btn--active': state.hasSubscribed
          })}
          loading={state.switching}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            toggleNewsletter();
          }}
        >
          <>
            {state.hasSubscribed && (
              <svg
                width="20"
                height="20"
                className="myNewsletters__bloc_iconCheck"
                viewBox="0 0 24 24"
              >
                <polyline points="20 6 9 17 4 12" />
              </svg>
            )}
            {t('myAccount.myNewsletters.link')}
          </>
        </Button>
      </div>
      <EmailOptinModal
        open={state.hasEmailOptinIssue}
        onSubscribed={() => {
          toggleNewsletter();

          setState({
            ...state,
            hasEmailOptinIssue: false
          });
        }}
        onClose={() => {
          setState({
            ...state,
            switching: false,
            hasEmailOptinIssue: false
          });
        }}
      />
    </>
  );
};

export default Newsletter;
