import {
  HeadingBrandProps,
  HeadingBrandImageProps
} from '@/components/molecules/headingBrand';
import { Component, NormalizeParams } from '..';

const normalize = ({
  data,
  includes
}: NormalizeParams): ComponentTitleProps => {
  const { title, bgImage, brandImage, description } = data;

  const bgImageData = includes[bgImage.sys.id];
  const brandImageData = includes[brandImage?.sys?.id];

  const bgImageTemp: HeadingBrandImageProps = {
    title: bgImageData?.fields?.title,
    fileUrl: bgImageData?.fields?.file?.url,
    filewidth: bgImageData?.fields?.file?.details?.image?.width,
    fileHeight: bgImageData?.fields?.file?.details?.image?.height
  };

  const brandImageTemp: HeadingBrandImageProps = {
    title: brandImageData?.fields?.title || '',
    fileUrl: brandImageData?.fields?.file?.url || '',
    filewidth: brandImageData?.fields?.file?.details?.image?.width || '',
    fileHeight: brandImageData?.fields?.file?.details?.image?.height || ''
  };
  return {
    type: 'molecules',
    name: 'headingBrand',
    props: {
      title,
      backgroundImage: bgImageTemp,
      brandImage: brandImageTemp,
      description
    }
  };
};

export default normalize;

export interface ComponentTitleProps extends Component {
  props: HeadingBrandProps;
}
