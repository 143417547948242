import React from 'react';
import './style.scss';
import classnames from 'classnames';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Header from '@/components/organisms/header';
import Layout from '@/components/organisms/layout';
import CategoriesModel from '@/lib/model/categories';
import CategoryModel from '@/lib/model/category';
import Title from '@/components/atoms/title';
import t from '@/lib/i18n';
import Univer from './univer';

export type DriveCatalogProps = {
  footer: ContentfulContent;
  categories: CategoriesModel;
  pageView: string;
};

const DriveCatalog = ({ footer, categories, pageView }: DriveCatalogProps) => {
  const headerPart = <Header />;

  const contentPart = (
    <nav className={classnames('driveCatalog', 'container', 'container--xl')}>
      <div className="driveCatalog__bloc">
        <Title label={t('seo.catalog.title')} tag="h1" />
      </div>
      {categories.tree
        .filter((univer) => univer.children?.length)
        .map((univer: CategoryModel) => (
          <div className="driveCatalog__bloc" key={univer.id}>
            <Univer univer={univer} />
          </div>
        ))}
    </nav>
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout
      header={headerPart}
      content={contentPart}
      footer={footerPart}
      pageView={pageView}
    />
  );
};

export default DriveCatalog;
