import React, { useState } from 'react';
import Tile from '@/components/atoms/tile';
import Modal from '@/components/organisms/modal';
import Paragraph from '@/components/atoms/paragraph';
import Button from '@/components/atoms/button';
import CgvFaqSprite from '@/assets/images/sprites/sav.svg';
import './style.scss';
import t from '@/lib/i18n';

export type SavBlocContactProps = {
  infos: InfosProps;
};

export type InfosProps = {
  label?: string;
  icon: string;
  popinContent?: JSX.Element;
  button?: JSX.Element;
};

const SavBlocContact = ({ infos }: SavBlocContactProps) => {
  const [isOpenPopin, setIsOpenPopin] = useState(false);
  const { label, icon, popinContent, button } = infos || {};

  return (
    <>
      {popinContent && (
        <Modal
          open={isOpenPopin}
          onClose={() => setIsOpenPopin(false)}
          className="blockContact__popin"
        >
          {popinContent}
        </Modal>
      )}
      <Tile
        className="blockContact"
        imageContent={
          <svg width="39" height="39">
            <use xlinkHref={`${CgvFaqSprite}#${icon}`} />
          </svg>
        }
        textContent={
          <>
            <Paragraph label={label} className="blockContact__text" />
            {button || (
              <Button
                onClick={() => setIsOpenPopin(true)}
                label={t('sav.display')}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default SavBlocContact;
