import React, {
  forwardRef,
  useCallback,
  useState,
  useImperativeHandle,
  ForwardRefRenderFunction
} from 'react';
import './style.scss';
import t from '@/lib/i18n';
import Button from '@/components/atoms/button';
import Link from '@/components/atoms/link';
import Modal from '@/components/organisms/modal';
import StoreLocator, {
  StoreLocatorProps
} from '@/components/organisms/storeLocator';
import { useSelector } from 'react-redux';
import { StateType as CartStateType } from '@/store/cart/types';
import Pdv from '@/lib/model/pdv';
import { setPdvCookie } from '@/lib/utils/pdv';
import usePdv from '@/lib/hooks/usePdv';
import useEvent from '@/lib/hooks/useEvent';

export type ChooseStoreProps = {
  className?: string;
  title?: string;
  content?: string;
  ctaLabel?: JSX.Element | string;
  storeLocator?: StoreLocatorProps;
  forcePdv?: {
    pdv: Pdv;
    url?: string | null;
  };
  type?: 'button' | 'link';
  children?: JSX.Element;
};

type ChooseStoreHandle = {
  storeLocatorClose: () => void;
};

const ChooseStore: ForwardRefRenderFunction<
  ChooseStoreHandle,
  ChooseStoreProps
> = (
  {
    className,
    ctaLabel,
    title,
    content,
    storeLocator,
    forcePdv,
    type = 'button',
    children
  },
  ref
) => {
  const [isStoreLocatorOpened, setStoreLocatorOpened] = useState(false);
  const [isAlertModalOpened, setAlertModalOpened] = useState(false);
  // TODO : const {qty} = useShalloEqualSelector(useCart);
  const { qtyInCart } = useSelector(({ cart }: { cart: CartStateType }) => {
    return {
      qtyInCart: cart.qty
    };
  });
  const event = useEvent();

  useImperativeHandle(ref, () => ({
    storeLocatorClose
  }));

  const onClick = useCallback(() => {
    const { isEcommerce } = usePdv();

    if (forcePdv || (isEcommerce && !!qtyInCart)) {
      setAlertModalOpened(true);
    } else {
      setStoreLocatorOpened(true);
    }
  }, [qtyInCart, forcePdv]);

  const storeLocatorClose = () => setStoreLocatorOpened(false);

  let labelContent = ctaLabel;

  if (children) {
    labelContent = children;
  }

  const storeLocatorProps = storeLocator ?? {
    withDelivery: true,
    withDrive: true,
    withInStore: true
  };

  return (
    <>
      {type === 'button' && (
        <Button className={className} onClick={() => onClick()}>
          {labelContent}
        </Button>
      )}
      {type === 'link' && (
        <Link
          id="headerChooseStore"
          className={className}
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          {labelContent}
        </Link>
      )}
      <Modal
        className="chooseStore__alert"
        pageView="ChangeStore"
        confirmBtn={{
          label: t('choose-store.alert.confirm'),
          onClick: () => {
            setAlertModalOpened(false);
            if (storeLocator) {
              setStoreLocatorOpened(true);
            } else if (forcePdv) {
              event.send('account', {
                type: 'changeStore',
                storeId: forcePdv?.pdv.ref
              });
              setPdvCookie(forcePdv.pdv);

              window.location.href = forcePdv?.url ?? '/home';
            }
          }
        }}
        cancelBtn={{
          label: t('choose-store.alert.cancel'),
          onClick: () => {
            setAlertModalOpened(false);
          }
        }}
        open={isAlertModalOpened}
        onClose={() => {
          setAlertModalOpened(false);
        }}
        scrollable={false}
      >
        <>
          <h1>{t('choose-store.alert.title')}</h1>
          <p>{t('choose-store.alert.content')}</p>
        </>
      </Modal>
      <Modal
        className="chooseStore__modal"
        open={isStoreLocatorOpened}
        onClose={() => {
          setStoreLocatorOpened(false);
        }}
        pageView="storeLocator"
        scrollable={false}
      >
        <>
          {title && <h1>{title}</h1>}
          {content && <p>{content}</p>}
          <StoreLocator {...storeLocatorProps} />
        </>
      </Modal>
    </>
  );
};

export default forwardRef(ChooseStore);
