import React from 'react';
import './style.scss';
import Video, { VideoProps } from '@/components/atoms/video';
import StoreLocator from '@/components/organisms/storeLocator';
import { ReactComponent as Logo } from '@/assets/images/icons/logo-white.svg';

export type HeaderInteractiveProps = {
  title?: string;
  video?: VideoProps;
};

const HeaderInteractive = (props: HeaderInteractiveProps) => {
  return (
    <div className="headerInteractive">
      {props.video && (
        <div className="headerInteractive__video">
          <Video {...props.video} />
        </div>
      )}
      <div className="headerInteractive__container">
        <header className="headerInteractive__header">
          <Logo className="headerInteractive__logo fill--white" />
        </header>
        <div className="headerInteractive__content">
          <div
            className="headerInteractive__title"
            dangerouslySetInnerHTML={{
              __html: props.title || ''
            }}
          />
          <StoreLocator />
        </div>
      </div>
    </div>
  );
};

export default HeaderInteractive;
