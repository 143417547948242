import React from 'react';
import PdvApi from '@/lib/api/pdv';
import { Request, Response } from 'express';
import PdvModel from '@/lib/model/pdv';
import usePdv from '@/lib/hooks/usePdv';

type InitialProps = {
  req: Request;
  res: Response;
};

const PdvESI = ({ pdv }: { pdv?: any | null }) => {
  return (
    <>
      {!pdv && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `const ANALYTICS_PDV = {}`
          }}
        />
      )}
      {!!pdv && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `const ANALYTICS_PDV = ${JSON.stringify({
              store_area: pdv.area,
              store_area_segmentation: '',
              store_postalcode: pdv.address?.zipCode ?? '',
              store_city: pdv.address?.city ?? '',
              store_itm_region: pdv.address?.region ?? '',
              store_critizr_ecommerce_score: '',
              store_critizr_global_score: '',
              store_ecommerce_status: pdv.isEcommerce ? 1 : 0,
              store_id_itm: pdv.ref,
              store_id_ntf: pdv.idNetfective.toString(),
              store_minimum_order_values: '',
              store_name: pdv.name,
              store_next_slots: '',
              store_online_payment_services: '',
              store_order_services: pdv.types.join('::'),
              store_performance_score: '',
              store_petrol_station: pdv.hasPetrolStation ? 1 : 0,
              store_vocation: pdv.format
            })}`
          }}
        />
      )}
    </>
  );
};

PdvESI.getInitialProps = async ({ req, res }: InitialProps) => {
  const { ref, isVirtual } = usePdv(req);

  let pdv: PdvModel | null = null;

  if (ref && !isVirtual) {
    try {
      pdv = await PdvApi.getPdvInfo(ref);
    } catch (error: any) {
      pdv = null;
    }
  }

  return new Promise((resolve) => {
    if (res) {
      // 3 hours of cache
      res.set('Cache-Control', 's-maxage=10800, max-age=10800');
    }

    let data = null;

    if (pdv) {
      data = {
        name: pdv.name,
        ref: pdv.ref,
        idNetfective: pdv.idNetfective,
        area: pdv.area,
        address: pdv.address,
        isEcommerce: pdv.isEcommerce(),
        hasPetrolStation: pdv.hasService('ess') || pdv.hasService('ess24'),
        format: pdv.format,
        types: pdv.rawTypes.map((type: string) => type.toLowerCase())
      };
    }

    resolve({
      pdv: data
    });
  });
};

export default PdvESI;
