import { BlockProps } from '../../../components/organisms/block';
import { Component, normalize as BaseNormalize, NormalizeParams } from '..';

type BlockElement = 'title' | 'titleSecondary' | 'description';
type HeightAttrs = 'minHeight' | 'maxHeight';
type AlignType = 'horizontalAlign' | 'verticalAlign';

const normalize = ({
  data,
  includes,
  extras
}: NormalizeParams): BlockComponent | any => {
  let blockTypeName = null;

  if (data.blockType) {
    blockTypeName = data.blockType.toLowerCase();
    blockTypeName = blockTypeName.replace(new RegExp('é|è|ê|É|È|Ê', 'g'), 'e');
  }

  const props: BlockProps = {
    type: '',
    title: null,
    titleSecondary: null,
    description: null,
    minHeight: null,
    maxHeight: null,
    cmsComponents: [],
    loginRequired: data.loggedInCondition?.toLowerCase() === 'connecté',
    startDateTime: null,
    endDateTime: null,
    eShopConditional: data.eShopConditional || null
  };

  switch (blockTypeName) {
    case 'infos moitie gauche':
      props.type = 'left';
      break;
    case 'infos moitie droite':
      props.type = 'right';
      break;
    default:
      props.type = 'basic';
      break;
  }

  ['title', 'titleSecondary', 'description'].forEach((elt: string) => {
    const key = elt as BlockElement;

    props[key] = data[elt] || null;

    // @TODO clean this via contribution
    if (
      props[key] === "Tous vos services au même endroit, c'est plus pratique !"
    ) {
      props[key] =
        "Tous vos services au même<br/> endroit, c'est plus pratique !";
    }
  });

  ['minHeight', 'maxHeight'].forEach((elt: string) => {
    const key = elt as HeightAttrs;

    props[key] = data[key] || null ? `${data[key]}px` : null;
  });

  ['horizontalAlign', 'verticalAlign'].forEach((elt) => {
    const key = elt as AlignType;

    switch (data[elt]) {
      case 'Centre':
      case 'milieu':
        props[key] = 'center';
        break;
      case 'Gauche':
        props[key] = 'left';
        break;
      case 'Droite':
        props[key] = 'right';
        break;
    }
  });

  if (data.startDateTime) {
    props.startDateTime = data.startDateTime;
  } else if (data.endDateTime) {
    props.endDateTime = data.endDateTime;
  }

  if (data.backgroundImage) {
    const backgroundImageData =
      includes[data.backgroundImage.sys.id] || undefined;

    if (backgroundImageData) {
      const backgroundImageFileData =
        includes[backgroundImageData.fields.image.sys.id] || null;

      props.background = {
        repeat: backgroundImageData.fields.repeat || false,
        size: backgroundImageData.fields.size || null,
        image: `${backgroundImageFileData.fields.file.url}?fm=webp` || null
      };

      const position = backgroundImageData.fields.position || null;

      if (position === 'Centre + Centre') {
        props.background = {
          ...props.background,
          position: 'center center'
        };
      }
    }
  }

  if (data.backgroundColor) {
    props.background = {
      ...props.background,
      color: data.backgroundColor || null
    };
  }

  if (data.cta) {
    const ctaData = includes[data.cta.sys.id] || null;

    if (
      extras?.id === 'pageProxy' ||
      extras?.id === 'landingPage' ||
      extras?.id === 'accountActivationFailure' ||
      extras?.id === 'accountActivationSuccess' ||
      extras?.id === 'ma-recette-du-bonheur' ||
      extras?.id === 'brandPage'
    ) {
      const elt = BaseNormalize({
        data: ctaData,
        includes,
        extras
      });
      if (elt) {
        props.ctaComponent = elt;
      }
    } else if (
      ctaData.fields.pdvNoDrive ||
      ctaData.fields.pdvDrive ||
      ctaData.fields.noPdv
    ) {
      let pdvNoDriveData;
      let pdvDriveData;
      let noPdvData;

      if (ctaData.fields.pdvNoDrive) {
        pdvNoDriveData = includes[ctaData.fields.pdvNoDrive.sys.id] || null;
      }
      if (ctaData.fields.pdvDrive) {
        pdvDriveData = includes[ctaData.fields.pdvDrive.sys.id] || null;
      }
      if (ctaData.fields.noPdv) {
        noPdvData = includes[ctaData.fields.noPdv.sys.id] || null;
      }

      if (
        pdvNoDriveData &&
        typeof pdvNoDriveData.fields.miniStoreType !== 'undefined'
      ) {
        let { miniStoreType } = pdvNoDriveData.fields;
        miniStoreType = miniStoreType ?? 'tout mode de retrait';
        miniStoreType = miniStoreType
          .toLowerCase()
          .split(',')
          .map((elt: string) => {
            return elt.trim();
          });

        const allStoreTypes = miniStoreType.includes('tout mode de retrait');

        let redirectToRoute: string | null = null;

        switch (pdvNoDriveData.fields.redirect) {
          case 'Accueil magasin':
            redirectToRoute = 'homepage';
            break;
          case 'Fiche magasin':
            redirectToRoute = 'storePage';
            break;
        }

        props.cta = {
          type: 'chooseStore',
          props: {
            title: pdvNoDriveData.fields.popinTitle || '',
            content: pdvNoDriveData.fields.popinText || '',
            ctaLabel: pdvNoDriveData.fields.text || '',
            storeLocator: {
              withInStore: allStoreTypes,
              withDrive:
                allStoreTypes || miniStoreType.includes('retrait drive'),
              withDelivery:
                allStoreTypes || miniStoreType.includes('livraison à domicile'),
              redirect: {
                url: redirectToRoute
              }
            }
          }
        };
      } else if (pdvNoDriveData || pdvDriveData || noPdvData) {
        let pdvNoDriveProps;
        let pdvDriveProps;
        let noPdvProps;

        if (pdvNoDriveData) {
          pdvNoDriveProps = {
            type: 'link',
            props: {
              label: pdvNoDriveData.fields.text || '',
              href: pdvNoDriveData.fields.href || '',
              button: true
            }
          };
        }
        if (pdvDriveData) {
          pdvDriveProps = {
            type: 'link',
            props: {
              label: pdvDriveData.fields.text || '',
              href: pdvDriveData.fields.href || '',
              button: true
            }
          };
        }
        if (noPdvData) {
          noPdvProps = {
            type: 'link',
            props: {
              label: noPdvData.fields.text || '',
              href: noPdvData.fields.href || '',
              button: true
            }
          };
        }
        props.conditionedCta = {
          pdvNoDrive: pdvNoDriveProps ?? null,
          pdvDrive: pdvDriveProps ?? null,
          noPdv: noPdvProps ?? null
        };
      }
    } else {
      props.cta = {
        type: 'link',
        props: {
          button: true,
          label: ctaData.fields.text || '',
          href: ctaData.fields.href || ''
        }
      };
    }
  }

  if (
    (extras?.id === 'bonsPlans' ||
      extras?.id === 'landingPage' ||
      extras?.id === 'pageProxy' ||
      extras?.id === 'services' ||
      extras?.id === 'accountActivationFailure' ||
      extras?.id === 'ma-recette-du-bonheur' ||
      extras?.id === 'accountActivationSuccess' ||
      extras?.id === 'brandPage' ||
      extras?.isFreeTemplate) &&
    data.content &&
    Array.isArray(data.content) &&
    props.type === 'basic'
  ) {
    data.content.forEach((element: any) => {
      const dataContent = includes[element.sys.id] || null;
      if (dataContent) {
        const { id } = dataContent.sys?.contentType?.sys || {};
        let elt: any = null;
        if (id === 'c-summary') {
          elt = {
            type: 'summaryAtEnd',
            dataContent
          };
        } else {
          elt = BaseNormalize({
            data: dataContent,
            includes,
            extras
          });
        }
        if (elt) {
          props.cmsComponents?.push(elt);
        }
      }
    });
  } else if (data.content) {
    const dataContent = includes[data.content[0].sys.id] || null;

    if (dataContent) {
      const type = dataContent.sys.contentType.sys.id;
      const contentData = dataContent.fields;

      if (type === 'c-image') {
        props.content = {
          type: 'images',
          images: []
        };
        const contentImageData = includes[contentData.image.sys.id] || null;

        props.content.images.push({
          url: `${contentImageData.fields.file.url}?fm=webp`,
          width: contentData.imgWidth || null,
          maxHeight:
            contentImageData.fields.maxHeight ??
            `${contentImageData.fields.maxHeight}px`
        });
      } else if (type === 'c-carousel-produits') {
        return BaseNormalize({ data: dataContent, includes });
      } else if (contentData.componentTemplate) {
        if (contentData.componentTemplate === 'Chiffre clé') {
          props.content = {
            type: 'keyNumber',
            images: []
          };

          contentData.components.forEach((elt: any) => {
            const eltData = includes[elt.sys.id] || null;
            const eltImageData = includes[eltData.fields.image.sys.id] || null;
            const eltFileData =
              includes[eltImageData.fields.image.sys.id] || null;

            props.content?.images.push({
              title: eltData.fields.title,
              titleSecondary: eltData.fields.titleSecondary,
              url: `${eltFileData.fields.file.url}?fm=webp`
            });
          });
        } else if (contentData.componentTemplate === 'Standard') {
          props.content = {
            type: 'images',
            images: []
          };

          contentData.components.forEach((elt: any) => {
            const eltData = includes[elt.sys.id] || null;
            if (eltData) {
              const eltImageData =
                includes[eltData.fields.image.sys.id] || null;

              if (eltImageData) {
                const eltFileData =
                  includes[eltImageData.fields.image.sys.id] || null;

                props.content?.images.push({
                  title: eltData.fields.title || null,
                  url: `${eltFileData.fields.file.url}?fm=webp`,
                  link: {
                    title: eltImageData.fields.title ?? '',
                    href: eltImageData.fields.href,
                    target: eltImageData.fields.target || '_self'
                  }
                });
              }
            }
          });
        }
      }
    }
  }

  return {
    type: 'organisms',
    name: 'block',
    props
  };
};

export default normalize;

interface BlockComponent extends Component {
  props: BlockProps;
}
