import React from 'react';
import CategoryModel from '@/lib/model/category';
import Title from '@/components/atoms/title';
import Category from '../category';
import './style.scss';

export type UniverProps = {
  univer: CategoryModel;
};

const Univer = ({ univer }: UniverProps) => {
  return (
    <div className="univer">
      <Title tag="h2" label={univer.title} className="univer__title" />
      {univer.children
        .filter((category) => category.children?.length)
        .map((category) => (
          <ul key={category.id} className="category">
            <Category category={category} />
          </ul>
        ))}
    </div>
  );
};

export default Univer;
