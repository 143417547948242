import React, { memo } from 'react';
import CartSprite from '@/assets/images/sprites/cart.svg';
import { Offer, OfferDeliveryMode } from '@/lib/model/productTypes';
import t from '@/lib/i18n';
import Accordion from '@/components/molecules/accordion';

type ShippingModesProps = {
  currentOffer?: Offer;
};

const ShippingModes = ({ currentOffer }: ShippingModesProps) => {
  if (!currentOffer || !currentOffer?.delivery?.modes?.length) {
    return null;
  }

  const content = (
    <div className="productDetail__extra__shipping-list">
      {currentOffer.delivery.modes.map((mode: OfferDeliveryMode) => {
        return (
          <div className="productDetail__extra__shipping-item" key={mode.code}>
            <span>{mode.label}</span>

            {mode.code === currentOffer.delivery?.shippingMode && (
              <div className="productDetail__extra__shipping-price">
                {currentOffer.delivery.shippingPrice.value === 0 &&
                  t('cart.slot.fees.free')}
                {currentOffer.delivery.shippingPrice.value !== 0 &&
                  `+ ${currentOffer.delivery.shippingPrice.concatenated}`}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="productDetail__extra__shipping">
      <Accordion
        customArrowActive={
          <span className="productDetail__extra__shipping-arrow">
            {t('pdp.informations.availableOffers.shipping.hide')}
          </span>
        }
        customArrowInactive={
          <span className="productDetail__extra__shipping-arrow">
            {t('pdp.informations.availableOffers.shipping.show')}
          </span>
        }
        itemsList={[
          {
            title: (
              <div className="productDetail__extra__shipping-title">
                <svg
                  className="productDetail__extra__shipping-icon"
                  width="25"
                  height="25"
                >
                  <use xlinkHref={`${CartSprite}#delivery`} />
                </svg>
                {`${t('pdp.informations.extra.shippingModes')} (${
                  currentOffer.delivery.modes.length
                })`}
              </div>
            ),
            htmlBody: content,
            id: '0'
          }
        ]}
      />
    </div>
  );
};

export default memo(ShippingModes);
