import React, { ChangeEvent, useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as AddFiles } from '@/assets/images/icons/addFile.svg';
import { ReactComponent as CrossIcon } from '@/assets/images/icons/cross.svg';
import t from '@/lib/i18n';
import './style.scss';

export type FormFileProps = {
  id: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement> | null) => void;
  errorMessage?: string;
  name: string;
  acceptListFileInput?: string;
  acceptListFileLabel?: string;
  supportedExtensions?: Array<string>;
  maxSizeFile?: number;
  label: string;
  value?: string;
  clearValue: () => void;
  size?: 'small' | 'medium' | 'large';
};

const FormFile = React.forwardRef(
  (
    {
      id,
      className,
      onChange = (e: ChangeEvent<HTMLInputElement> | null) => null,
      errorMessage,
      name,
      acceptListFileInput = 'application/pdf, .png, .jpg, .txt',
      acceptListFileLabel = '.pdf, .png, .jpg, .txt, .webp, .heic',
      supportedExtensions = ['pdf', 'png', 'jpg', 'txt'],
      maxSizeFile = 2000000,
      label,
      value,
      clearValue,
      size = 'small'
    }: FormFileProps,
    ref: any
  ) => {
    const [fileName, setFileName] = useState('');
    const [errorMessageFile, setErrorMessageFile] = useState('');
    const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
      let inError = false;
      const path = e.target.value.split('.');
      const extension = `${path[path.length - 1]}`;
      const sizeFile = e.target.files?.[0].size;
      if (sizeFile && sizeFile > maxSizeFile) {
        inError = true;
        setErrorMessageFile(
          t('form.inputFiles.error.maxSize', {
            '%size%': `${maxSizeFile / 1000000} Mo`
          })
        );
      } else if (!supportedExtensions.includes(extension.toLowerCase())) {
        inError = true;
        setErrorMessageFile(t('form.inputFiles.error.extention'));
      } else {
        setErrorMessageFile('');
        setFileName(e.target.files?.[0].name || '');
        onChange(e);
      }

      if (inError) {
        clearValue();
      }
    };

    const removeFile = () => {
      setErrorMessageFile('');
      setFileName('');
      clearValue();
    };

    return (
      <div className={classnames('formInputFile', className)}>
        <div
          className={classnames(
            className,
            'formInputFile__wrapper',
            `input--${size}`
          )}
        >
          <input
            name={name}
            className="formInputFile__wrapper__input"
            id={id}
            type="file"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeFile(e)}
            accept={acceptListFileInput}
            value={value}
            ref={ref}
          />
          <label htmlFor={id} className="formInputFile__wrapper__label">
            <AddFiles width="38px" height="38px" />
            <span className="formInputFile__wrapper__label__text">
              {fileName || label}
            </span>
          </label>
          {fileName && (
            <CrossIcon
              className="formInputFile__wrapper__remove"
              height="10"
              width="10"
              onClick={() => removeFile()}
            />
          )}
        </div>
        <div className="formInputFile__acceptFile">
          {t('form.inputFiles.filesAcceptedList')}
        </div>
        <div className="formInputFile__acceptFile">{acceptListFileLabel}</div>
        {errorMessage && (
          <div className="formInputFile__errorMessage">{errorMessage}</div>
        )}
        {errorMessageFile && (
          <div className="formInputFile__errorMessage">{errorMessageFile}</div>
        )}
      </div>
    );
  }
);

export default FormFile;
