import React from 'react';
import { OrderDetail, SellerOrder } from '@/lib/api/order/types';
import classnames from 'classnames';
import CartSprite from '@/assets/images/sprites/cart.svg';
import CommonSprite from '@/assets/images/sprites/common.svg';
import t from '@/lib/i18n';
import { SlotsCartType } from '@/store/cart/types';
import Providers from '../providers';

type OrderSummaryProps = {
  selOrder: SellerOrder;
  slots: SlotsCartType;
};

const getDeliveryDate = (date: string | undefined): string | null => {
  if (date !== undefined) {
    return date.charAt(0).toUpperCase() + date.slice(1);
  }
  return null;
};

interface ExtraCostNotFreeProps {
  cost?: number;
  label: string;
}

const ExtraCostNotFree: React.FC<ExtraCostNotFreeProps> = ({ cost, label }) => {
  if (!cost || cost <= 0) {
    return null;
  }
  return (
    <tr className="cartSummary__list__line">
      <td />
      <td className="cartSummary__list__item">{label}</td>
      <td className="cartSummary__list__item cartSummary__list__highlight">
        {t('cart.summary.add.price', {
          '%price%': String(cost.toFixed(2)).replace('.', ',')
        })}
      </td>
    </tr>
  );
};

const OrderSummary = ({ selOrder, slots }: OrderSummaryProps) => {
  return (
    <div className="cartSummary__order">
      <div className="cart__infos">
        <strong className="cart__infos__title">
          {t('cart.info.myCart', {
            '%sellerName%': selOrder.seller.name
          })}
        </strong>
      </div>
      <section className="cartSummary__section">
        {selOrder.ordersList.map((order: OrderDetail) => (
          <table
            className={classnames('cartSummary__list', {
              'cartSummary__list--disabled': order.statusIsKO
            })}
            key={order.id}
          >
            <colgroup>
              <col />
              <col style={{ width: '80%' }} />
              <col style={{ width: '20%' }} />
            </colgroup>
            <tbody>
              <tr className="cartSummary__list__line">
                <td className="cartSummary__list__item">
                  <svg width="25" height="25">
                    <use xlinkHref={`${CartSprite}#file`} />
                  </svg>
                </td>
                <td
                  className="cartSummary__list__item cartSummary__list__highlight"
                  id="cartSummaryNumber"
                >
                  {t('cart.summary.number', {
                    '%number%': order.id || order.backOfficeOrderId
                  })}
                </td>
                <td />
              </tr>
              <tr className="cartSummary__list__line">
                <td className="cartSummary__list__item">
                  <svg width="25" height="25">
                    <use xlinkHref={`${CommonSprite}#cart`} />
                  </svg>
                </td>
                <td className="cartSummary__list__item">
                  {t('cart.summary.products', {
                    '%qty%': order?.countItems
                  })}
                </td>
                <td
                  className="cartSummary__list__item cartSummary__list__price"
                  id="cartSummaryTotalPrice"
                >
                  {t('cart.summary.add.price', {
                    '%price%': String(order?.total.value.toFixed(2)).replace(
                      '.',
                      ','
                    )
                  })}
                </td>
              </tr>
              {!!order?.totalDiscountCode &&
                order?.totalDiscountCode.value > 0 && (
                  <tr className="cartSummary__list__line">
                    <td />
                    <td className="cartSummary__list__item">
                      {t('cart.summary.discount')}
                    </td>
                    <td className="cartSummary__list__item cartSummary__list__discount">
                      {`+ ${t('cart.summary.add.price', {
                        '%price%': String(
                          order?.totalDiscountCode?.value.toFixed(2)
                        ).replace('.', ',')
                      })}`}
                    </td>
                  </tr>
                )}
              {!!order?.totalVoucher && order?.totalVoucher.value > 0 && (
                <tr className="cartSummary__list__line">
                  <td />
                  <td className="cartSummary__list__item">
                    {t('cart.voucher')}
                  </td>
                  <td className="cartSummary__list__item cartSummary__list__discount">
                    {`+ ${t('cart.summary.add.price', {
                      '%price%': String(
                        order?.totalVoucher?.value.toFixed(2)
                      ).replace('.', ',')
                    })}`}
                  </td>
                </tr>
              )}
              <tr className="cartSummary__list__line">
                <td>
                  <svg width="25" height="25">
                    <use xlinkHref={`${CartSprite}#${order?.deliveryType}`} />
                  </svg>
                </td>
                {order.type === 'PDV' && (
                  <td className="cartSummary__list__item cartSummary__list__highlight">
                    {order?.deliveryType !== 'delivery'
                      ? t('cart.slot.address')
                      : t('cart.slot.delivery')}
                  </td>
                )}
                {order.type !== 'PDV' && (
                  <td className="cartSummary__list__item cartSummary__list__highlight">
                    {t(`mkp.deliveryService.${order.selectedShippingType}`)}
                  </td>
                )}
                <td className="cartSummary__list__item cartSummary__list__discount">
                  {selOrder.seller.id === 'ITM' &&
                    slots.selectedSlot.deliveryType && (
                      <div className="cartSummary__providerLogo">
                        <Providers slots={slots} isConfirmPage />
                      </div>
                    )}
                </td>
              </tr>
              <tr className="cartSummary__list__line">
                <td />
                {selOrder.seller.id !== 'ITM' && order?.slot?.date?.isValid() && (
                  <td className="cartSummary__list__item cartSummary__list__highlight">
                    {t('mkp.deliveryService.deliverFrom')}{' '}
                    {getDeliveryDate(order?.slot.date.format('dddd DD MMMM'))}.
                  </td>
                )}
                {selOrder.seller.id === 'ITM' &&
                  order?.slot.date?.isValid() &&
                  order?.slot.begin?.isValid() &&
                  order?.slot.end?.isValid() && (
                    <td className="cartSummary__list__item cartSummary__list__highlight">
                      {getDeliveryDate(order?.slot.date.format('dddd DD MMMM'))}
                      . &nbsp; &nbsp; &nbsp;
                      {order?.slot.begin.format('HH:mm')} -{' '}
                      {order?.slot.end.format('HH:mm')}
                    </td>
                  )}
                <td className="cartSummary__list__item cartSummary__list__discount" />
              </tr>
              {!!order?.deliveryCost && (
                <tr className="cartSummary__list__line">
                  <td />
                  <td className="cartSummary__list__item">
                    {t('cart.summary.fees.delivery')}
                  </td>
                  <td
                    className={classnames(
                      'cartSummary__list__item cartSummary__list__highlight',
                      {
                        cartSummary__list__discount:
                          order.deliveryCost.value === 0
                      }
                    )}
                  >
                    {order.deliveryCost.value > 0 &&
                      t('cart.summary.add.price', {
                        '%price%': String(
                          order.deliveryCost.value.toFixed(2)
                        ).replace('.', ',')
                      })}
                    {order.deliveryCost.value === 0 && t('cart.slot.fees.free')}
                  </td>
                </tr>
              )}
              <ExtraCostNotFree
                cost={order?.housingExtraCost?.value}
                label={t('cart.summary.fees.floor')}
              />
              <ExtraCostNotFree
                cost={order?.volumeExtraCost?.value}
                label={t('cart.summary.fees.liquid')}
              />
            </tbody>
          </table>
        ))}
      </section>
    </div>
  );
};

export default OrderSummary;
