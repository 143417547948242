import React from 'react';
import t from '@/lib/i18n';
import { Content as ContentfulContent } from '@/lib/contentful';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import UserAddresses from '@/components/organisms/myAccount/addresses';

export type MyAddressesProps = {
  footer: ContentfulContent;
  pageView: string;
};

const MyAddresses = ({ footer, pageView }: MyAddressesProps) => {
  return (
    <MyAccountLayout
      title={t('myAccount.menu.userInfo.myAddresses')}
      enabledId="userInfo"
      content={
        <UserAddresses
          pageViewCreate="myAccountAddAddress"
          pageViewModify="myAccountModifyAddress"
          isSelectable
        />
      }
      footer={footer}
      pageView={pageView}
      backTo={{
        label: t('myAccount.menu.userInfo.long'),
        href: '/account-management/my-info'
      }}
    />
  );
};

export default MyAddresses;
