import React, { useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import './style.scss';
import { withESI, ESIOptions } from '@/lib/esi';
import MiniCartContainer from '@/components/organisms/miniCart';
import usePdv from '@/lib/hooks/usePdv';
import Search from './search';
import Navigation from './navigation';
import DescubraContainer from './navigation/container';
import StoreInfo from './storeInfo';
import Categories from './categoriesESI';
import User from './user';
import Cart from './cart';
import List from './list';

const Header = () => {
  const [isPdvEcommerce, setIsPdvEcommerce] = useState(true);

  const CategoriesESI = withESI(Categories, {
    id: 'header-categories',
    type: 'organisms',
    name: 'header/categories'
  });

  useIsomorphicLayoutEffect(() => {
    const { isEcommerce } = usePdv();
    setIsPdvEcommerce(isEcommerce);
  }, []);

  return (
    <div className="header">
      <div className="header__content">
        <StoreInfo className="header__store" />
        <Navigation className="header__navigation" />
        <DescubraContainer />
        {isPdvEcommerce && (
          <>
            <Search id="input-searchBoxNavBar" className="header__search" />
            <List className="header__list" />
          </>
        )}
        <User className="header__user" />
        <Cart className="header__cart" />
        <MiniCartContainer />
      </div>
      <CategoriesESI esi={ESIOptions()} />
    </div>
  );
};

export default Header;
