import React from 'react';
import Title from '@/components/atoms/title';
import classnames from 'classnames';
import './style.scss';

export type ContentSectionProps = {
  title?: string;
  content: string;
  id?: string;
  className?: string;
};

const ContentSection = ({
  title,
  content,
  id,
  className
}: ContentSectionProps) => (
  <div className={classnames('contentSection', className)} id={id}>
    {title && (
      <Title tag="h3" className="contentSection__title">
        <div
          className="contentSection__content"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Title>
    )}
    <div
      className="contentSection__content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </div>
);

export default ContentSection;
