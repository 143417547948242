import { NormalizeParams, Content } from '..';

import genericPageNormalize from '../genericPage';

const normalize = ({ data, includes, extras }: NormalizeParams): Content => {
  return genericPageNormalize({
    data,
    includes,
    extras: {
      ...extras,
      id: 'pageProxy'
    }
  });
};

export default normalize;
