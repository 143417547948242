import { LinkProps } from '@/components/atoms/link';
import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): ComponentImageProps => {
  return {
    type: 'atoms',
    name: 'link',
    props: {
      button: true,
      href: data.returnURL || '',
      label: data.text || ''
    }
  };
};

export default normalize;

export interface ComponentImageProps extends Component {
  props: LinkProps;
}
