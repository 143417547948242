import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import CartHelp from '@/components/organisms/checkout/help';
import {
  StateType,
  SubCartType,
  ProviderType,
  ServiceProvidersType
} from '@/store/cart/types';
import SideBarSummary from '@/components/organisms/checkout/sideBarSummary';
import { SLOT_TYPE_MAPPING } from '@/lib/model/slot';
import CarrierCostExplanation from '@/components/organisms/checkout/carrierCostExplanation';
import useEvent from '@/lib/hooks/useEvent';
import DeliveryHeader from '@/components/organisms/checkout/delivery/DeliveryHeader';
import CarrierChoose from '@/components/organisms/checkout/delivery/carrierChoose';
import { useDispatch } from 'react-redux';
import {
  isSubCartProviderSelected,
  findNextCartToHandle
} from '@/lib/utils/providerSelect';
import { CONFIRM_PROVIDER, SELECT_PROVIDER } from '@/store/cart/cartActions';
import usePageView from '@/lib/hooks/usePageView';
import t from '@/lib/i18n';
import { getTransportDeliveryCost } from '@/lib/utils/cart';
import { persistor } from '@/store';
import BottomFooter from '../sideBarSummary/bottomFooter';

export type StepTwoProps = {
  cart: StateType;
  userId: string;
};

type NextStepType = {
  escapeCurrentSeller: boolean;
  cart: StateType;
  sellerId: string | null;
};

const setNextSteps = ({
  escapeCurrentSeller,
  cart: cartTemp,
  sellerId: sellerIdTemp
}: NextStepType): void => {
  const nextSubCartToHandle = findNextCartToHandle(
    escapeCurrentSeller,
    cartTemp,
    sellerIdTemp
  );
  setTimeout(() => {
    if (nextSubCartToHandle) {
      window.location.href = `/orders/delivery#${nextSubCartToHandle.seller.sellerId}`;
    } else {
      window.location.href = cartTemp.subCarts?.length
        ? '/orders/payment'
        : '/orders/cart';
    }
  }, 0);
};

const Delivery = ({ cart, userId }: StepTwoProps) => {
  const dispatch = useDispatch();
  const event = useEvent();
  const pv = usePageView();
  const getCurrentSeller = () => window.location.hash.replace('#', '');
  const [sellerId, setSellerId] = useState<string>(getCurrentSeller());
  const [buttonMobileDisabled, setButtonMobileDisabled] = useState(false);

  const selectProvider = ({
    selectedProvider,
    packageId
  }: {
    selectedProvider: ProviderType | ServiceProvidersType | null;
    packageId?: string;
  }) => {
    dispatch({
      type: SELECT_PROVIDER,
      payload: {
        sellerId,
        selectedProvider,
        packageId
      }
    });
  };
  const subCartToHandle = cart.subCarts.find(
    (subCart: SubCartType) => subCart.seller.sellerId === sellerId
  );

  const immediateDiscount = String(cart.immediateDiscount.toFixed(2)).replace(
    '.',
    ','
  );

  const confirmSubCart = () => {
    if (sellerId !== 'ITM') {
      event.send('checkout', {
        type: 'selectProvider',
        seller: subCartToHandle?.seller
      });
      const mkpPackages = cart.mkpPackages?.[sellerId]?.packages || {};
      Object.values(mkpPackages)?.forEach((pac) => {
        event.send('checkout', {
          type: 'selectMkpDelivery',
          option: t(`mkp.deliveryService.gtm.${pac.selectedProvider?.id}`)
        });
      });
    }

    dispatch({
      type: CONFIRM_PROVIDER,
      payload: {
        sellerId
      }
    });
    setNextSteps({
      escapeCurrentSeller: true,
      cart,
      sellerId
    });
  };

  useEffect(() => {
    (async () => {
      if (cart.isHydrated && cart.subCarts.length && persistor) {
        await persistor.flush();
      }
    })();
  }, [cart.subCarts, cart.isHydrated]);

  useEffect(() => {
    if (cart.isHydrated && !subCartToHandle) {
      setNextSteps({
        escapeCurrentSeller: false,
        cart,
        sellerId
      });
    }
  }, [cart, subCartToHandle, sellerId]);

  useEffect(() => {
    const refreshPageSellerChange = () => {
      const sellerIdTemp = getCurrentSeller();
      if (sellerIdTemp !== sellerId) {
        setSellerId(sellerIdTemp);
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };
    window.addEventListener('hashchange', refreshPageSellerChange);
    return () =>
      window.removeEventListener('hashchange', refreshPageSellerChange);
  }, [sellerId]);

  const deliveryList: Array<
    ServiceProvidersType & {
      deliveryCost: number;
    }
  > =
    cart.slots.selectedSlot.slot?.providers?.map((provider) => {
      return {
        ...(cart.slots.list.delivery.serviceProviders.find(
          (service) => service.id === provider.id
        ) as ServiceProvidersType),
        deliveryCost: provider.deliveryCost.totalCost
      };
    }) || [];
  const deliveryPartners = deliveryList.map(({ desktopTitle }) => desktopTitle);
  const { current: deliveryPartnersRef } = useRef(deliveryPartners);

  useEffect(() => {
    if (sellerId === 'ITM') {
      // only for itm lad
      event.send('checkout', {
        type: 'deliveryPartnersOpt',
        provider: `${deliveryPartnersRef.length}::${deliveryPartnersRef.join(
          '::'
        )}`
      });
      pv.send('livraison');
    } else if (sellerId) {
      pv.send('partnerDelivery');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryPartnersRef, event, sellerId]);

  const { current: cartRef } = useRef(cart);

  useEffect(() => {
    if (sellerId) {
      const subCart = cartRef.subCarts.find(
        (sub) => sub.seller.sellerId === sellerId
      );
      const products = Object.values(subCart?.items || {})?.map((item) => item);
      if (sellerId === 'ITM') {
        const deliveryPartner = cartRef.slots.list.delivery?.serviceProviders
          .filter(({ id }) => id === cartRef.slots.selectedProvider?.id)
          .map(({ desktopTitle }) => desktopTitle)
          .toString();
        !!products.length &&
          event.send('checkoutEcommerce', {
            step: '3',
            path: `/orders/delivery#${sellerId}`,
            items: products,
            options: cartRef.slots.selectedSlot,
            deliveryPartner
          });
      } else {
        !!products.length &&
          event.send('checkoutEcommerce', {
            step: '3bis',
            path: `/orders/delivery#${sellerId}`,
            items: products,
            options: cartRef.slots.selectedSlot
          });
      }
    }
  }, [event, cartRef, sellerId]);

  return !cart.subCarts.length || !subCartToHandle ? null : (
    <div className="cart cart--checkout">
      <div className="cart__content">
        <DeliveryHeader subCart={subCartToHandle} cart={cart} />
        <CarrierChoose
          cart={cart}
          subCartId={subCartToHandle.seller.sellerId}
          selectProvider={selectProvider}
          seller={subCartToHandle.seller}
        />
        {sellerId === 'ITM' && (
          <CarrierCostExplanation
            data={cart.slots.list[SLOT_TYPE_MAPPING.DOMICILE]}
            classNameContent="deliveryPage__howCostCalculated__infos"
            classNameAccordion="deliveryPage__howCostCalculated__accordion"
            withAccordion
          />
        )}
        <CartHelp />
      </div>
      <SideBarSummary
        isDisabled={(isDisabled) => {
          setButtonMobileDisabled(isDisabled);
        }}
        disabled={!sellerId || !isSubCartProviderSelected(sellerId, cart)}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          confirmSubCart();
        }}
        btnValidationLabel={
          !findNextCartToHandle(true, cart, sellerId)
            ? t('cart.footer.step')
            : t('cart.footer.next')
        }
        cart={cart}
        userId={userId}
        totalDiscount={immediateDiscount}
        sellerId={sellerId}
        isDeliveryPage
      />

      <BottomFooter
        cart={cart}
        ctaLabel={
          !findNextCartToHandle(true, cart, sellerId)
            ? t('cart.footer.step')
            : t('cart.footer.next')
        }
        isMobile={true}
        deliveryCost={getTransportDeliveryCost(cart)}
        totalDiscount={immediateDiscount}
        onClick={(e) => {
          e.preventDefault();
          confirmSubCart();
        }}
        disabledButton={buttonMobileDisabled}
        isDelivery
      />
    </div>
  );
};

export default Delivery;
