import React, { useEffect } from 'react';
import Layout from '@/components/organisms/layout';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SET_CGU } from '@/store/cardCreation/actions';
import Header from '@/components/organisms/header';
import { CardCreationStateType } from '@/store/cardCreation/reducer';
import FidelitySprite from '@/assets/images/sprites/register.svg';
import t from '@/lib/i18n';
import Message from '@/components/molecules/message';
import './style.scss';
import { useIsPro } from '@/lib/hooks/useIsPro';

export type AvantageFideliteProps = {
  footer: ContentfulContent;
  content: ContentfulContent;
  pageView?: string;
  pageviewData?: Array<string> | null;
  cgu?: ContentfulContent;
  slug?: string;
};

const AvantageFidelite = ({
  footer,
  content,
  cgu,
  pageView,
  pageviewData
}: AvantageFideliteProps) => {
  const [isPro, loading] = useIsPro();
  const { isHydrated } = useSelector(
    ({ cardCreation }: { cardCreation: CardCreationStateType }) => ({
      isHydrated: cardCreation.isHydrated
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const headerPart = <Header />;

  useEffect(() => {
    if (cgu && isHydrated) {
      dispatch({ type: SET_CGU, payload: { cgu } });
    }
  }, [dispatch, cgu, isHydrated]);

  // eslint-disable-next-line no-nested-ternary
  const contentPart = loading ? (
    <></>
  ) : isPro ? (
    <Message
      image={`${FidelitySprite}#fidelity2`}
      title={t('fidelityCard.isPro.title')}
      desc={t('fidelityCard.isPro.desc')}
    />
  ) : (
    <ContentfulRenderComponents components={content?.components} />
  );

  const footerPart = footer && (
    <ContentfulRenderComponents components={footer.components} />
  );

  return (
    <Layout
      className="avantageFidelite"
      header={headerPart}
      content={contentPart}
      footer={footerPart}
      pageView={pageView}
      pageViewData={pageviewData}
    />
  );
};

export default AvantageFidelite;
