import { ContentImageProps } from '@/components/molecules/contentImage';
import { Component, normalize as BaseNormalize, NormalizeParams } from '..';
import ctaNormalize from './cta';

const normalize = ({
  data,
  includes
}: NormalizeParams): ComponentTitleProps => {
  let image: Component | null = null;
  const { text = '', title = '', image: imageTemp, textLink, hrefLink } = data;

  const imageData = imageTemp ? includes[imageTemp.sys.id] : null;

  if (imageData) {
    image = BaseNormalize({ data: imageData, includes });
  }

  const props: ContentImageProps = {
    text,
    title,
    image,
    cta: null,
    href: null
  };
  if (textLink && hrefLink) {
    props.cta = ctaNormalize({
      data: { href: hrefLink, text: textLink, button: true },
      includes
    });
  }

  if (imageData?.fields?.href) {
    props.href = imageData?.fields?.href;
  }
  return {
    type: 'molecules',
    name: 'contentImage',
    props
  };
};

export default normalize;

export interface ComponentTitleProps extends Component {
  props: ContentImageProps;
}
