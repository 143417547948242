import React from 'react';
import Title from '@/components/atoms/title';
import './style.scss';

export type HeadingProps = {
  title: string;
  subhead: string;
  description: string;
};

const Heading = ({ title, subhead, description }: HeadingProps) => {
  return (
    <>
      <div className="heading__subHead">{subhead}</div>
      <Title label={title} tag="h1" />
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </>
  );
};

export default Heading;
