import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import ChooseStore from '@/components/organisms/chooseStore';

type JumbotronProps = {
  storeName: string;
  message?: string;
  format: string;
  storeMagasin?: any;
};

const Jumbotron = ({
  storeName,
  format,
  storeMagasin,
  message
}: JumbotronProps) => {
  const localFormat = format?.toLocaleLowerCase() || 'hyper';
  const contentfulData = storeMagasin[localFormat];

  let style = {};

  if (contentfulData) {
    style = {
      ...style,
      backgroundImage: `url(${contentfulData.image.file.url})`
    };
  }

  return (
    <div className="jumbotron">
      <div className="jumbotron__cover">
        <div className="jumbotron__cover__image" style={style}>
          <div className="jumbotron__presentation">
            <h1 className="jumbotron__title">
              {`${t('common.name')}`} <span>{storeName}</span>
            </h1>
            <div className="jumbotron__content">
              {message && (
                <>
                  <h2 className="jumbotron__h2">{t('pdv.message.intro')}</h2>
                  <div
                    className="jumbotron__p"
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                </>
              )}
              {!message && contentfulData && (
                <div
                  dangerouslySetInnerHTML={{ __html: contentfulData.content }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ChooseStore
        type="button"
        className="jumbotron__chooseStore"
        title={t('header.storeInfo.chooseStore.title')}
        content={t('header.storeInfo.chooseStore.content')}
        ctaLabel={t('header.storeInfo.chooseStore.ctaLabel')}
        storeLocator={{
          withInStore: true,
          withDrive: true,
          withDelivery: true
        }}
      />
    </div>
  );
};

export default React.memo(Jumbotron);
