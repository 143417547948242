import {
  Content,
  normalize as BaseNormalize,
  NormalizeParams,
  Component
} from '..';

const normalize = ({ data, includes, context }: NormalizeParams): Footer => {
  const components: Array<Component> = [];
  const itemKeys = ['footerSecondary'];

  if (!context?.simplified) {
    itemKeys.unshift('footerPrimary');
  }

  itemKeys.forEach((itemKey) => {
    const itemData = includes[data[itemKey].sys.id] || null;

    if (itemData) {
      const elt = BaseNormalize({ data: itemData, includes });

      if (elt) {
        components.push(elt);
      }
    }
  });

  return {
    components
  };
};

export default normalize;

export type Footer = Content;
