import { CollectionGridProps } from '@/components/molecules/collectionGrid';
import {
  Component,
  NormalizeParams,
  normalize as BaseNormalize
} from '@/lib/contentful';

const normalize = ({
  data,
  includes,
  extras
}: NormalizeParams): ComponentColumnProps => {
  const list = ['firstColumn', 'secondColumn', 'thirdColumn', 'fourthColumn'];

  const components: Array<Component> = [];

  list.forEach((columnName) => {
    const column = data[columnName];
    if (column) {
      column.forEach((item: any) => {
        const id = item.sys?.id;
        const itemData = includes[id];
        if (itemData) {
          const dataToPass = { ...itemData, id };
          const baseElement = BaseNormalize({
            data: dataToPass,
            includes,
            extras
          });
          if (baseElement) {
            components.push(baseElement);
          }
        }
      });
    }
  });

  return {
    type: 'molecules',
    name: 'collectionGrid',
    props: {
      components,
      columnNumber: components.length
    }
  };
};

export default normalize;

export interface ComponentColumnProps extends Component {
  props: CollectionGridProps;
}
