import React, { ChangeEvent } from 'react';
import classnames from 'classnames';
import './style.scss';

export type FormSelectProps = {
  id?: string;
  name?: string;
  label?: string;
  className?: string;
  value?: string;
  options: Array<Option>;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  errorMessage?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  autoComplete?: 'off' | 'on' | string;
};

export type Option = {
  value: string | number;
  label: string;
};

const FormSelect = React.forwardRef(
  (
    {
      id,
      name,
      label,
      className,
      value,
      options,
      onChange = (e: ChangeEvent<HTMLSelectElement>) => null,
      errorMessage,
      size = 'small',
      disabled = false,
      autoComplete
    }: FormSelectProps,
    ref: any
  ) => {
    return (
      <div
        className={classnames(className, 'formSelect', `formSelect--${size}`, {
          'formSelect--hasError': !!errorMessage
        })}
      >
        {label && (
          <label className="formSelect__label" htmlFor={id}>
            {label}
          </label>
        )}
        <select
          className="formSelect__select"
          name={name}
          id={id}
          disabled={disabled}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e)}
          ref={ref}
          value={value}
          autoComplete={autoComplete}
        >
          {options.map((elt: Option, index) => {
            const key = `formRadioOption-${name}-${index}`;
            return (
              <option value={elt.value} key={key}>
                {elt.label}
              </option>
            );
          })}
        </select>
        {errorMessage && (
          <div className="formSelect__errorMessage">{errorMessage}</div>
        )}
      </div>
    );
  }
);

export default FormSelect;
