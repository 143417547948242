import { StateType, SubCartType } from '@/store/cart/types';
import Date from '@/lib/utils/date';

export const isSubCartFinished = (sellerId: string, cartState: StateType) => {
  if (sellerId === 'ITM') {
    return !!cartState.slots.selectedProvider?.isConfirmed;
  }

  const packagesList = cartState.mkpPackages?.[sellerId]?.packages;
  if (!Object.keys(packagesList || {}).length) return false;
  const packageNotFinished = Object.keys(packagesList || {}).find(
    (key) => !cartState.mkpPackages?.[sellerId]?.packages?.[key]?.isConfirmed
  );
  return !packageNotFinished;
};

export const isSubCartProviderSelected = (
  sellerId: string,
  cartState: StateType
) => {
  if (sellerId === 'ITM') {
    return !!cartState.slots.selectedProvider?.id;
  }
  const packagesList = cartState.mkpPackages?.[sellerId]?.packages;
  const packageWithoutProvider = Object.keys(packagesList || {}).find(
    (key) =>
      !cartState.mkpPackages?.[sellerId]?.packages?.[key]?.selectedProvider
  );
  return !packageWithoutProvider;
};

export const findNextCartToHandle = (
  escapeCurrentSeller: boolean,
  cart: StateType,
  sellerId: string | null
): SubCartType | null => {
  const isThereDeliveryItm =
    cart.slots.selectedSlot.slot?.providers &&
    cart.slots.selectedSlot.slot.providers?.length > 1 &&
    cart.slots.selectedSlot.deliveryType === 'delivery';
  const sellerIndex = cart.subCarts.findIndex((subcart) => {
    return subcart.seller.sellerId === sellerId;
  });
  const orderedSubCarts = cart.subCarts.sort((a, b) => {
    if (a.seller.sellerId === 'ITM') return -1;
    return Number(a.seller.sellerId) - Number(b.seller.sellerId);
  });
  const find = orderedSubCarts.find((subcart, index) => {
    if (sellerId && escapeCurrentSeller && subcart.seller.sellerId === sellerId)
      return false;
    if (subcart.seller.sellerId === 'ITM' && !isThereDeliveryItm) return false;
    if (sellerIndex < index) return true;
    return !isSubCartFinished(subcart.seller.sellerId, cart);
  });
  return find || null;
};

export const formattedLeadTimeToShip = (totalDeliveryTime: number) => {
  const deliveryDate = Date().add(totalDeliveryTime, 'day');
  return deliveryDate.format('dddd D MMMM');
};
