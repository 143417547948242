import { BodyMovinProps } from '@/components/templates/avantageFidelite/bodyMovin';

import { Component, NormalizeParams } from '..';

const normalize = ({ data, includes }: NormalizeParams): ComponentProps => {
  const { jsonData } = data;

  const fields = includes[jsonData?.sys?.id]?.fields || null;

  return {
    type: 'templates/avantageFidelite',
    name: 'bodyMovin',
    props: {
      jsonUrl: fields?.file?.url || ''
    }
  };
};

export default normalize;

export interface ComponentProps extends Component {
  props: BodyMovinProps;
}
