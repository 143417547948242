import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from '@/store';
import './style.scss';
import classnames from 'classnames';
import { Request, Response } from 'express';
import CategoriesApi from '@/lib/api/categories';
import CategoryModel from '@/lib/model/category';
import Link from '@/components/atoms/link';
import PlpBreadcrumb from '@/components/templates/plp/breadcrumb';
import usePdv from '@/lib/hooks/usePdv';
import { useIsomorphicLayoutEffect } from 'react-use';
import { VIRTUAL_PDV_REF } from '@/lib/utils/pdv';
import { useRouter } from 'next/router';
import t from '@/lib/i18n';

type InitialProps = {
  props: HeadingProps;
  req: Request;
  res: Response;
};

type BreadcrumbItem = {
  label: string;
  link: string;
};

type HeadingProps = {
  categories?: Array<CategoryModel>;
  breadcrumbItems?: Array<BreadcrumbItem>;
  categoryId?: string;
  slug: Array<string>;
};

const HeadingESI = ({
  slug,
  categoryId,
  categories,
  breadcrumbItems
}: HeadingProps) => {
  const breadcrumbItemsData = [{ label: t('common.home'), link: '/' }];
  const [categoriesIsVisible, setCategoriesIsVisible] = useState(true);
  const [categoryIdFromUrl, setCategoryIdFromUrl] = useState(categoryId);
  breadcrumbItems?.forEach((breadcrumbItem: BreadcrumbItem) => {
    breadcrumbItemsData.push({
      label: breadcrumbItem.label,
      link: breadcrumbItem.link
    });
  });

  const router = useRouter();

  const getTitle = (): string => {
    const url = router.asPath.split('/');
    const category = url[url.length - 3].replace(/-/g, ' ');
    const subCategory = url[url.length - 2].replace(/-/g, ' ');
    const title = `${category} - ${subCategory}`;
    return title;
  };

  const [pdvRef, setPdvRef] = useState('');
  useIsomorphicLayoutEffect(() => {
    const { ref } = usePdv();
    setPdvRef(ref);
  }, []);
  const virtualPdvRef = VIRTUAL_PDV_REF;

  useEffect(() => {
    setCategoryIdFromUrl(router?.query?.categories?.toString() || categoryId);
  }, [categoryId, router?.query?.categories]);

  return (
    <Provider store={store}>
      <PlpBreadcrumb
        handleSlected={() => {
          setCategoriesIsVisible(false);
        }}
        items={breadcrumbItemsData}
        categoriesIsVisible={categoriesIsVisible}
      />
      {categoriesIsVisible && (
        <div className="categoriesNavigation">
          {categories &&
            categories.map((category) => {
              const key = `category-${category.id}`;
              return (
                <Link
                  key={key}
                  className={classnames('categoriesNavigation__item', {
                    categoriesNavigation__current:
                      categoryIdFromUrl === category.id.toString()
                  })}
                  href={category.link}
                  label={category.title}
                />
              );
            })}
        </div>
      )}
      {pdvRef.toString() === virtualPdvRef && (
        <h1 className="catalogueSeoTitle">{getTitle()}</h1>
      )}
    </Provider>
  );
};

const build = (
  categories: Array<CategoryModel>,
  slug: Array<string>,
  breadcrumbItems: Array<BreadcrumbItem>
): [Array<CategoryModel>, Array<BreadcrumbItem>] => {
  const slugData = [...slug];
  const slugElt = slugData.shift();

  if (!categories.length) {
    return [[], []];
  }

  let categoriesData: Array<CategoryModel> = [];
  let breadcrumbItemsData: Array<BreadcrumbItem> = [...breadcrumbItems];

  for (const category of categories) {
    if (category.slug === slugElt) {
      breadcrumbItemsData.push({ label: category.title, link: category.link });

      if (slugData.length && category.children.length && category.level < 3) {
        [categoriesData, breadcrumbItemsData] = build(
          category.children,
          slugData,
          breadcrumbItemsData
        );
      } else {
        categoriesData = category.children;
      }
      break;
    }
  }

  return [categoriesData, breadcrumbItemsData];
};

HeadingESI.getInitialProps = async ({ props, req, res }: InitialProps) => {
  const { ref } = usePdv(req);

  let categories: Array<CategoryModel> = [];
  let breadcrumbItems: Array<BreadcrumbItem> = [];

  const slug = [...props.slug];
  const categoryId = slug.pop();

  if (ref) {
    const data = await CategoriesApi.getNavByPdv(ref);

    [categories, breadcrumbItems] = build(data?.tree ?? [], slug, []);
  }

  return new Promise((resolve) => {
    if (res) {
      // 3 hours of cache
      res.set('Cache-Control', 's-maxage=10800, max-age=10800');
    }

    resolve({
      ...props,
      categoryId,
      categories,
      breadcrumbItems
    });
  });
};

export default HeadingESI;
