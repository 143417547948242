import Link from '@/components/atoms/link';
import t from '@/lib/i18n';
import React from 'react';

export const AlmostDoneForm = () => (
  <div className="result-form">
    <h3 className="result-form__header">
      {t('smartconso.survey.almostDone.header')}
    </h3>
    <h3 className="result-form__title">
      {t('smartconso.survey.almostDone.title')}
    </h3>
    <p className="result-form__description">
      {t('smartconso.survey.almostDone.description')}
    </p>
    <div className="result-form__footer">
      <Link
        className="result-form__button result-form__button-validate"
        href="/home"
        label={t('smartconso.survey.almostDone.confirm')}
        button
      />
      <Link
        className="result-form__button result-form__button-exit"
        href="/account-management/smart-conso"
        label={t('smartconso.survey.almostDone.exit')}
        button
      />
    </div>
  </div>
);
