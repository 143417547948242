import React, { useState } from 'react';
import '../style.scss';
import voucher from '@/assets/images/icons/voucher.svg';
import FormInput from '@/components/atoms/formInput';
import t from '@/lib/i18n';
import { useDispatch } from 'react-redux';
import { PaymentStateType, StateType } from '@/store/cart/types';
import Loader from '@/components/atoms/loader';
import { UPDATE_SELECTED_VOUCHER } from '@/store/cart/cartActions';
import DiscountCode from '@/lib/model/discountCode';
import { isValidVoucher } from '@/lib/utils/cart';
import useEvent from '@/lib/hooks/useEvent';

export type VoucherProps = {
  cart: StateType;
  paymentState: PaymentStateType;
  discountCode: DiscountCode | null;
};

const Voucher = ({ paymentState, cart, discountCode }: VoucherProps) => {
  const dispatch = useDispatch();
  const event = useEvent();
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      <section className="payment__section payment__voucher">
        {!paymentState.voucher.isLoading && (
          <>
            <FormInput
              picto={voucher}
              validationItem={true}
              placeholder={t('cart.payment.codes.voucher.placeholder')}
              value={t('cart.payment.voucher.value', {
                '%amount%': paymentState.voucher.data?.amount.value
              })}
              id="vouchers"
              name="vouchers"
              label={t('cart.payment.codes.voucher.label')}
              sendValue={() => {
                event.send('checkout', {
                  type: 'voucher',
                  voucher: paymentState.voucher.data?.amount.value
                });
                if (
                  !cart.selectedVoucher &&
                  !isValidVoucher(
                    cart,
                    discountCode,
                    paymentState.voucher.data?.amount.value || 0
                  )
                ) {
                  if (errorMessage) {
                    setErrorMessage('');
                  } else {
                    setErrorMessage(t('cart.payment.voucher.errorAmount'));
                  }
                } else {
                  setErrorMessage('');
                  dispatch({
                    type: UPDATE_SELECTED_VOUCHER,
                    payload: cart.selectedVoucher
                      ? 0
                      : paymentState.voucher.data?.amount.value || 0
                  });
                }
              }}
              errorMessage={errorMessage}
            />
            <span className="payment__voucher__info">
              {t('cart.payment.voucher.info')}
              <span className="payment__voucher__highlight">
                {paymentState.voucher.data?.endDate.format('D/MM/YYYY')}
              </span>
            </span>
          </>
        )}
        {paymentState.voucher.isLoading && <Loader size="medium" />}
      </section>
    </>
  );
};

export default Voucher;
