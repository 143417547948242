import React, { useEffect } from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import Layout from '@/components/organisms/layout';
import ValidationOrganism from '@/components/organisms/checkout/validation';
import { shallowEqual, useSelector } from 'react-redux';
import { StateType as CartStateType } from '@/store/cart/types';
import { AuthStateType } from '@/store/auth/authReducer';
import Header from '../header';
import './style.scss';

export type CartProps = {
  footer: ContentfulContent;
};

const ValidationTemplate = ({ footer }: CartProps) => {
  const { cartStore, user } = useSelector(
    ({ cart, auth }: { cart: CartStateType; auth: AuthStateType }) => {
      return {
        cartStore: cart,
        user: auth.user
      };
    },
    shallowEqual
  );

  useEffect(() => {
    if (!user) {
      window.location.href = `/connection?redirect=${window.location.pathname}${window.location.search}`;
    }
  }, [user]);

  const headerPart = <Header cart={cartStore} step="payment" />;
  const content = (
    <div className="container">
      <ValidationOrganism />
    </div>
  );

  return (
    <Layout
      className="validationTemplate"
      header={headerPart}
      content={content}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
    />
  );
};

export default ValidationTemplate;
