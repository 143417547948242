import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { ReactComponent as MkpEXP } from '@/assets/images/icons/mkpEXP.svg';
import { ReactComponent as MkpSTD } from '@/assets/images/icons/mkpSTD.svg';
import { ReactComponent as MkpRETPDV } from '@/assets/images/icons/mkpRETPDV.svg';
import { ReactComponent as MkpPDOMORETG } from '@/assets/images/icons/mkpPDOMORETG.svg';
import { MkpShippingIds } from '@/store/cart/types';

export type Time = {
  hour: number;
  minute: number;
};

export type SlotSchedule = {
  begin: Time;
  end: Time;
};

export const idMkpDeliveryType: Array<MkpShippingIds> = [
  'EXP',
  'STD',
  'RETPDV',
  'PDOM',
  'ETG'
];

export const getMkpDeliveryServiceSvg = ({
  id,
  width = '90',
  height = '90'
}: {
  id: string;
  width?: string;
  height?: string;
}): JSX.Element | null => {
  switch (id) {
    case 'EXP':
      return <MkpEXP width={width} height={height} />;
    case 'STD':
      return <MkpSTD width={width} height={height} />;
    case 'RETPDV':
      return <MkpRETPDV width={width} height={height} />;
    case 'PDOM':
    case 'ETG':
      return <MkpPDOMORETG width={width} height={height} />;
    default:
      return null;
  }
};

export const SLOT_LIST = ['drive', 'drivepieton', 'drive24', 'delivery'];
export const NEED_BAG_ELIGIBLE = ['DRIVE', 'DRIVELIGHT', 'DRIVESOLO'];

export const DELIVERY_TYPE_MAPPING_INVERSE: { [key: string]: string } = {
  delivery: 'DOMICILE',
  drivepieton: 'DRIVEPIETON',
  drive24: 'DRIVE24',
  drive: 'DRIVE',
  driveLight: 'DRIVELIGHT',
  driveSolo: 'DRIVESOLO'
};

export const SLOT_TYPE_MAPPING: { [key: string]: string } = {
  DOMICILE: 'delivery',
  DRIVELIGHT: 'driveLight',
  DRIVEPIETON: 'drivepieton',
  DRIVE24: 'drive24',
  DRIVE: 'drive',
  CONSIGNE: 'drive24',
  PIETON: 'storePickUp',
  LIVRAISON: 'homeDelivery',
  DRIVESOLO: 'driveSolo'
};

class Slot {
  type?: string;

  statusService: string;

  fees: number;

  freeFees: boolean;

  id: number;

  period: string;

  status: string;

  date: Dayjs;

  time?: SlotSchedule;

  constructor(data: any) {
    this.computeSlotType(data.typeLivraison);
    this.computeTime(data.creneau.heureDebut, data.creneau.heureFin);

    this.statusService = data.etatService;
    this.id = data.creneau.idCreneau;
    this.period = data.creneau.typePeriode;
    this.status = data.creneau.statut;
    this.freeFees = data.creneau.fraisOfferts;
    this.fees = data.creneau.frais;
    this.date = dayjs(data.date);
  }

  computeSlotType(type: string) {
    this.type = SLOT_TYPE_MAPPING[type];
  }

  computeTime(start: string, end: string) {
    const startHour = start.split(':')[0];
    const startMinutes = start.split(':')[1];
    const endHour = end.split(':')[0];
    const endMinutes = end.split(':')[1];

    this.time = {
      begin: {
        hour: parseInt(startHour, 10),
        minute: parseInt(startMinutes, 10)
      },
      end: {
        hour: parseInt(endHour, 10),
        minute: parseInt(endMinutes, 10)
      }
    };
  }
}

export default Slot;
