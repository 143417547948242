import SmartConsoSprite from '@/assets/images/sprites/smartconso.svg';
import Link from '@/components/atoms/link';
import t from '@/lib/i18n';
import { actionCleanSurvey } from '@/store/survey/surveyReducer';
import { CleanSurveyAction } from '@/store/survey/actions';
import { SurveyState } from '@/store/survey/types';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import preferencesAPI from '@/lib/api/preferences';
import userInformationApi from '@/lib/api/userInformation';
import { AuthStateType } from '@/store/auth/authReducer';
import logger from '@/lib/logger/base';
import useEvent from '@/lib/hooks/useEvent';
import { selectHasFilledMandatoriesQuestions } from '@/store/survey/surveySelectors';
import ModalReinit from '../../content/Modal/modalReinit';
import './style.scss';
import ModalError from '../../content/Modal/ModalError';

type LegalsProps = {
  classname?: string;
};

const Legals = ({ classname }: LegalsProps) => {
  const dispatch: React.Dispatch<CleanSurveyAction> = useDispatch();
  const cleanStore = actionCleanSurvey(dispatch);
  const state = useSelector(({ survey }: { survey: SurveyState }) => survey);
  const [openReinitModal, setOpenReinitModal] = useState(false);
  const [openeErrorModal, setOpenErrorModal] = useState(false);
  // TODO :   const userId = useShallowEqualSelector(selectUserId);
  const { userId } = useSelector(({ auth }: { auth: AuthStateType }) => {
    return {
      userId: auth.user?.id
    };
  });
  const hasFilledMandatoriesQuestions = useSelector(
    selectHasFilledMandatoriesQuestions
  );
  const event = useEvent();

  const reinitUserData = async () => {
    setOpenReinitModal(false);

    event.send('smartconso', {
      type: 'deleteQues',
      label: t('myAccount.smartConso.modalReinit.confirm')
    });

    try {
      if (userId) {
        await userInformationApi.updatePersonalData(userId, {
          optIn: { preferences: false }
        });
        await preferencesAPI.delete(userId);
      }
      cleanStore({ withMessage: true });
    } catch (error: any) {
      setOpenErrorModal(true);
      logger.error({
        message: 'Unable to delete the survey responses',
        error,
        context: {
          state
        }
      });
    }
  };

  const closeReinitModal = () => {
    event.send('smartconso', {
      type: 'deleteQues',
      label: t('myAccount.smartConso.modalReinit.cancel')
    });

    setOpenReinitModal(false);
  };

  return (
    <div className={classnames('legals', classname)}>
      <div className="legals__links">
        <Link
          className="legals__link userLegals"
          target="_blank"
          href="/legal-informations/legal-notices"
          label={t('smartconso.footer.userLegals')}
        />
        <svg className="legals__link__svg" width="16px" height="16px">
          <use xlinkHref={`${SmartConsoSprite}#redirect`} />
        </svg>
      </div>
      <div className="legals__links">
        <Link
          className="legals__link personalData"
          target="_blank"
          href="/legal-informations/personal-data-and-cookies"
          label={t('smartconso.footer.personalData')}
        />
        <svg className="legals__link__svg" width="16px" height="16px">
          <use xlinkHref={`${SmartConsoSprite}#redirect`} />
        </svg>
      </div>
      {hasFilledMandatoriesQuestions && (
        <div className="legals__links">
          <Link
            className="legals__link reset"
            onClick={() => setOpenReinitModal(true)}
            label={t('smartconso.footer.reset')}
          />
        </div>
      )}
      <ModalReinit
        open={openReinitModal}
        validate={reinitUserData}
        closeModal={closeReinitModal}
      />
      <ModalError
        open={openeErrorModal}
        closeModal={() => setOpenErrorModal(false)}
        validate={() => setOpenErrorModal(false)}
        errorMessage={t('modal.modalError.error')}
      />
    </div>
  );
};

export default Legals;
