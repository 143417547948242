import React from 'react';
import t from '@/lib/i18n';
import useEvent from '@/lib/hooks/useEvent';
import StarGradientSVG from './starGradientSVG';
import './style.scss';

export type RatingScoreProps = {
  score?: number;
  maxScore?: number;
  sellerUrl?: string;
  sellerName?: string;
  reviewsCount?: number;
  activeColor?: string;
};

const RatingScore = ({
  score = 0,
  maxScore = 5,
  sellerUrl = '',
  sellerName = '',
  reviewsCount = 0,
  activeColor
}: RatingScoreProps): JSX.Element => {
  const event = useEvent();

  const getStarsRender = () => {
    const starsArray: Array<JSX.Element> = [];
    for (let curIndex = 0; curIndex < Math.floor(maxScore); curIndex += 1) {
      const getGradientRatio = () => {
        if (score - curIndex >= 1) {
          return 100;
        }
        if (score - curIndex <= 0) {
          return 0;
        }
        return (score - curIndex) * 100;
      };

      starsArray.push(
        <StarGradientSVG
          gradientRatio={getGradientRatio()}
          key={curIndex}
          gradientId={curIndex.toString()}
          activeColor={activeColor}
          width="16px"
          height="16px"
        />
      );
    }
    return starsArray;
  };

  const onClickScore = () => {
    window.open(sellerUrl, '_blank');
    event.send('sellerClick', { sellerName });
  };

  return (
    <div className="ratingScore">
      {getStarsRender()}
      <div onClick={onClickScore} className="ratingScore__text">
        ({`${reviewsCount} ${t('common.review')}`})
      </div>
    </div>
  );
};

export default RatingScore;
