import React from 'react';
import {
  RendererComponents as ContentfulRenderComponents,
  Content as ContentfulContent
} from '@/lib/contentful';
import { withESI, ESIOptions } from '@/lib/esi';
import Layout from '@/components/organisms/layout';
import Header from '@/components/organisms/header';
import StoreLocatorLayer from '@/components/organisms/storeLocatorLayer';
import Content from './contentESI';

export type PdpProps = {
  footer: ContentfulContent;
  ean: string;
};

const Pdp = ({ footer, ean }: PdpProps) => {
  const headerPart = <Header />;

  const ContentESI = withESI(Content, {
    id: `pdp-${ean}`,
    type: 'templates/pdp',
    name: 'content'
  });

  const content = (
    <>
      <ContentESI esi={ESIOptions()} ean={ean} />
      <StoreLocatorLayer redirectType="pdp" redirectId={ean} />
    </>
  );

  return (
    <Layout
      header={headerPart}
      content={content}
      footer={
        footer && <ContentfulRenderComponents components={footer.components} />
      }
    />
  );
};

export default Pdp;
