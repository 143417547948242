import { ContentSectionProps } from '@/components/molecules/contentSection';
import { Component, NormalizeParams } from '..';

const normalize = ({ data }: NormalizeParams): ComponentImageProps => {
  return {
    type: 'molecules',
    name: 'contentSection',
    props: {
      content: data.text || '',
      className: 'cmsLabel'
    }
  };
};

export default normalize;

export interface ComponentImageProps extends Component {
  props: ContentSectionProps;
}
