import createAxios from '@/lib/axios';
import { AxiosInstance } from 'axios';
import logger from '@/lib/logger/base';
import { Dayjs } from 'dayjs';
import Date from '@/lib/utils/date';
import { getFidelityCardNumberValidationError } from './exceptions';
import { FidelityInfo } from './types';

type InfoRequest = {
  firstName: string;
  lastName: string;
  fidelityCardNumber?: string;
  birthdayDate: Dayjs;
};

class PublicFidelityApi {
  axios: AxiosInstance;

  constructor() {
    this.axios = createAxios({}, ['redApi']);
  }

  isValidFidelity = async (
    infoRequest: InfoRequest
  ): Promise<FidelityInfo | null> => {
    let resultApi: FidelityInfo | null = null;
    const today = Date();
    const header: any = {};

    header['requestDate'] = today.format('YYYYMMDD');
    header['requestTime'] = today.format('HHmmss');
    header['processType'] = '3';
    header['loyaltyCode'] = 'IP';
    header['lang'] = 'PT';
    header['channel'] = 'WEB';

    try {
      const response = await this.axios.post(
        '/cartefidelite/v1/loyalty_card/infos',
        {
          identClient: {
            firstName: infoRequest.firstName,
            lastName: infoRequest.lastName,
            numFid: infoRequest.fidelityCardNumber,
            dateN: Date(infoRequest.birthdayDate).format('DD/MM/YYYY')
          },
          header
        }
      );
      if (response.status === 200) {
        resultApi = {
          pdv: response?.data.numPdv,
          type: response?.data.typeCarte,
          status: response?.data.statut,
          cardNumber: response?.data.numFid,
          countVisits: 0
        };
      }
    } catch (error: any) {
      logger.error({
        message: 'Unable to fetch user/card informations',
        error
      });
      if (error?.response) {
        const { status } = error?.response;
        const { code } =
          error?.response?.data?.errors[0] || error?.response?.data;
        throw getFidelityCardNumberValidationError(
          status === 500 ? 'TECHNICAL_ERROR' : code
        );
      }
    }
    return resultApi;
  };
}

export default new PublicFidelityApi();
