import React from 'react';
import './style.scss';
import t from '@/lib/i18n';
import { Content as ContentfulContent } from '@/lib/contentful';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import Form from './form';
import DeleteAccount from './deleteAccount';

export type MyPersonalDataProps = {
  footer: ContentfulContent;
  pageView: string;
};

const MyPersonalData = ({ footer, pageView }: MyPersonalDataProps) => {
  const contentPart = (
    <div className="myPersonalData">
      <Form />
      <DeleteAccount />
    </div>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.userInfo.myPersonalData')}
      enabledId="userInfo"
      content={contentPart}
      pageView={pageView}
      footer={footer}
      backTo={{
        label: t('myAccount.menu.userInfo.long'),
        href: '/account-management/my-info'
      }}
    />
  );
};

export default MyPersonalData;
