import React, { useEffect, useState } from 'react';
import './style.scss';
import classnames from 'classnames';
import t from '@/lib/i18n';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as FlashSalePicto } from '@/assets/images/icons/flashSale.svg';
import dayjs from 'dayjs';
import { PromotionType } from '@/lib/model/productTypes';
import utilDate from '@/lib/utils/date';
import { useSelector } from 'react-redux';
import { AuthStateType } from '@/store/auth/authReducer';

export type PromotionProps = {
  className?: string;
  data: PromotionType;
  isCheckout?: boolean;
  isList?: boolean;
};

export type FlashSaleProps = {
  endDate: Date;
};

const FlashSale = ({ endDate }: FlashSaleProps) => {
  const [countDown, setCountDown] = useState('');

  const getTime = () => {
    const total = Date.parse(String(endDate)) - Date.parse(String(utilDate()));
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    setCountDown((l) =>
      t('promotions.flashSale.countDown', {
        '%days%': days,
        '%hours%': hours,
        '%minutes%': minutes,
        '%seconds%': seconds
      })
    );
  };
  useEffect(() => {
    if (countDown === '') {
      getTime();
    }
    const timer = setInterval(() => {
      getTime();
    }, 1000);
    return () => clearInterval(timer);
  });
  return (
    <div
      className={classnames({
        promotion: true,
        'promotion--flashSale': true
      })}
    >
      <div className="promotion__column">
        <FlashSalePicto
          className="promotion__column__picto"
          width={20}
          height={20}
        />
      </div>
      <div
        className="promotion__column"
        dangerouslySetInnerHTML={{
          __html: t('promotions.flashSale.text', { '%countDown%': countDown })
        }}
      />
    </div>
  );
};

const Promotion = ({ className, data }: PromotionProps) => {
  let promotion = data;
  const [state, setState] = useState({
    id: '',
    toolTipIsLoaded: false
  });

  useEffect(() => {
    setState({
      id: Math.random().toString(36).substring(2, 15),
      toolTipIsLoaded: true
    });
  }, []);

  const user = useSelector(({ auth }: { auth: AuthStateType }) => auth.user);

  if (
    promotion.type === 'deferredDiscount' &&
    promotion.unit === 'percentage' &&
    user &&
    user.countVisits >= 4
  ) {
    promotion = { ...promotion, value: 10 };
  }

  return (
    <>
      {promotion.flashSale && promotion.endDate && (
        <div className="promotion__flashSaleContent">
          <FlashSale endDate={promotion.endDate} />
        </div>
      )}
      {promotion.type !== 'quantityDiscount' && (
        <div
          data-tip
          data-for={`promo-${state.id}`}
          className={classnames({
            promotion: true,
            [`promotion--${promotion.type}`]: true,
            'promotion--amount': promotion.unit === 'amount',
            className
          })}
        >
          {promotion.picto && (
            <img
              className="promotion__image"
              src={promotion.picto}
              alt=""
              width={35}
              height={20}
            />
          )}

          <div className="promotion__column">
            {promotion.type === 'offeredDiscount' &&
              t(`promotions.${promotion.type}.value`, {
                '%value%': promotion.value,
                '%quantity%': promotion.quantity,
                '%unit%': promotion.unit === 'percentage' ? '%' : '€'
              })}
            {promotion.type === 'collectorPartner' &&
              promotion.value &&
              t('promotions.value', {
                '%value%': `+${promotion.value}`,
                '%unit%': ''
              })}
            {!['collectorPartner', 'offeredDiscount'].includes(
              promotion.type
            ) &&
              t('promotions.value', {
                '%value%': promotion.value,
                '%quantity%': promotion.quantity,
                '%unit%': promotion.unit === 'percentage' ? '%' : '€'
              })}
          </div>
          <div
            className="promotion__column"
            dangerouslySetInnerHTML={{
              __html: t(`promotions.${promotion.type}.text`, {
                '%quantity%': promotion.quantity || '',
                '%unit%': promotion.unit || ''
              })
            }}
          />

          {state.toolTipIsLoaded &&
            !['collectorPartner'].includes(promotion.type) && (
              <ReactTooltip place="top" id={`promo-${state.id}`} effect="float">
                <span
                  className="promotion__tip"
                  dangerouslySetInnerHTML={{
                    __html: t(`promotions.${promotion.type}.tip`, {
                      '%value%': promotion.value,
                      '%quantity%': promotion.quantity,
                      '%unit%': promotion.unit === 'percentage' ? '%' : '€',
                      '%endDate%': dayjs(promotion.endDate).format('DD/MM/YYYY')
                    })
                  }}
                />
              </ReactTooltip>
            )}
        </div>
      )}
    </>
  );
};

export default Promotion;
