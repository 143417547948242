import Image from '@/components/atoms/image';
import React, { memo } from 'react';
import FidelitySprite from '@/assets/images/sprites/carteFidelite.svg';
import t from '@/lib/i18n';
import { Operation as OperationItemProps } from '@/lib/api/stickers/types';

import './style.scss';
import { OperationBalance } from '@/lib/api/fidelity/types';
import Vignette from './vignette';
import FooterLinks from './footerLinks';

export type OperationProps = {
  operation: OperationItemProps;
  operationBalance?: OperationBalance;
  pdvIsActivated: boolean;
  cardNumber?: string;
};

const getVignetteCountMessage = ({
  status,
  earnCountDown,
  burnCountDown
}: OperationItemProps) => {
  switch (status) {
    case 'EARN':
      return {
        label: 'myAccount.fidelity.myStickers.operations.countDownEarn',
        cpt: earnCountDown,
        image: `${FidelitySprite}#operation-clock-green`
      };
    case 'END_OF_EARN':
      return {
        label: 'myAccount.fidelity.myStickers.operations.countDownEarnEnd',
        cpt: earnCountDown,
        image: `${FidelitySprite}#operation-clock-orange`
      };
    case 'BURN_ONLY':
      return {
        label: 'myAccount.fidelity.myStickers.operations.burnOnly',
        cpt: burnCountDown,
        image: `${FidelitySprite}#operation-clock-red`
      };

    default:
      return { label: '', image: '', cpt: 0 };
  }
};

const Operation = ({
  operation,
  operationBalance,
  pdvIsActivated,
  cardNumber
}: OperationProps) => {
  const vignetteCountMessage = getVignetteCountMessage(operation);
  return (
    <div className="operation">
      <div className="operation__header">
        <Image
          src={operation.logo}
          alt={operation.code}
          className="operation__header_logo"
        />
        <div className="operation__header_date">
          {t('myAccount.fidelity.myStickers.operations.fromTo', {
            '%start%': operation.startDate,
            '%end%': operation.earnEndDate
          })}
        </div>
      </div>
      <div className="operation__body">
        <div className="operation__body_text">
          <div className="operation__body_text--title">
            <span>{operation.title}</span>
          </div>
          <div className="operation__body_text--desc">
            {operation.description}
          </div>
        </div>
        <div className="operation__body_blocs">
          <Vignette
            cpt={operationBalance?.balance}
            image={`${FidelitySprite}#icons-fid-vignette`}
            label={t('myAccount.fidelity.myStickers.operations.solde', {
              '%s%': operationBalance && operationBalance.balance > 1 ? 's' : ''
            })}
            pdvIsActivated={Number(pdvIsActivated)}
            code={operation.code}
          />
          <Vignette
            cpt={vignetteCountMessage.cpt}
            image={vignetteCountMessage.image}
            label={t(vignetteCountMessage.label, {
              '%s%': vignetteCountMessage.cpt > 1 ? 's' : ''
            })}
            code={operation.code}
          />
        </div>
      </div>
      <FooterLinks
        operation={operation}
        cardNumber={cardNumber}
        balance={operationBalance?.balance ?? 0}
      />
    </div>
  );
};

export default memo(Operation);
