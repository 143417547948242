import ProductApi, { Filters as ApiFilters } from '@/lib/api/product';
import ProductListModel, {
  FILTER_BRANDS,
  FILTER_PREFERENCES,
  FILTER_PROMOTIONS,
  FILTER_CATEGORY,
  API_FILTER_BRANDS,
  API_FILTER_PREFERENCES,
  API_FILTER_PROMOTIONS,
  API_FILTER_CATEGORIES,
  FiltersType,
  Sort,
  FilterType
} from '@/lib/model/productList';

const PER_PAGE = 40;
const PER_PAGE_PROMO = 100;

const getApiFilterType = (type: string): string => {
  let apiType = '';

  switch (type) {
    case FILTER_BRANDS:
      apiType = API_FILTER_BRANDS;
      break;
    case FILTER_PREFERENCES:
      apiType = API_FILTER_PREFERENCES;
      break;
    case FILTER_PROMOTIONS:
      apiType = API_FILTER_PROMOTIONS;
      break;
    case FILTER_CATEGORY:
      apiType = API_FILTER_CATEGORIES;
      break;
  }

  return apiType;
};

const fetchData = async (
  pdvRef: string,
  type: string,
  page: number,
  filters: FiltersType,
  sort: Sort,
  categoryId?: string,
  keyword?: string,
  shopId?: string,
  headingId?: number
): Promise<ProductListModel> => {
  let list = new ProductListModel([]);
  const apiFilters: ApiFilters = [];
  const perPage =
    shopId === 'PROMOTIONS' || shopId === '2119' ? PER_PAGE_PROMO : PER_PAGE;

  filters.forEach((filter: FilterType) => {
    const apiType: string = getApiFilterType(filter.type.toString());

    if (apiType) {
      filter.values.forEach((id: string) => {
        apiFilters.push({
          type: apiType,
          id
        });
      });
    }
  });

  if (type === 'category') {
    list = await ProductApi.findByCategory(
      pdvRef,
      categoryId ?? '',
      page,
      perPage,
      apiFilters,
      sort.type,
      sort.direction
    );
  } else if (type === 'search') {
    list = await ProductApi.findByKeyword(
      pdvRef,
      keyword ?? '',
      page,
      perPage,
      apiFilters,
      sort.type,
      sort.direction
    );
  } else if (type === 'shop') {
    list = await ProductApi.findByShop(
      pdvRef,
      shopId ?? '',
      page,
      perPage,
      apiFilters,
      sort.type,
      sort.direction
    );
  } else if (type === 'heading') {
    list = await ProductApi.findByHeading(
      pdvRef,
      shopId ?? '',
      headingId,
      page,
      perPage,
      apiFilters,
      sort.type,
      sort.direction
    );
  }

  return list;
};

export default fetchData;
