import React, { useEffect, useState } from 'react';
import './style.scss';
import t from '@/lib/i18n';
import { AuthStateType } from '@/store/auth/authReducer';
import { Content as ContentfulContent } from '@/lib/contentful';
import { Informations as UserInfo } from '@/lib/api/userInformation/types';
import userInformationApi from '@/lib/api/userInformation';
import Modal from '@/components/organisms/modal';
import MyAccountLayout from '@/components/organisms/myAccount/layout';
import LoadableContent from '@/components/atoms/loadableContent';
import MyAccount from '@/assets/images/sprites/myAccount.svg';
import { useSelector } from 'react-redux';
import UpdateProfile from './updateProfile';
import ChangeEmail from './changeEmail';
import ChangePassword from './changePassword';

export type MyDetailsProps = {
  footer: ContentfulContent;
  pageView: string;
};

type StateType = {
  isLoading: boolean;
  userInfo?: UserInfo;
};

const MyDetails = ({ footer, pageView }: MyDetailsProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [state, setState] = useState<StateType>({
    isLoading: true,
    userInfo: undefined
  });

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }

      const userInfo = await userInformationApi.getInformations(userId);
      setState({
        isLoading: false,
        userInfo
      });

      setIsOpenModal(!!userInfo?.fidelityCardNumber);
    })();
  }, [userId]);

  const contentPart = (
    <LoadableContent className="myDetails" loading={state.isLoading}>
      {state.userInfo && (
        <>
          <Modal
            className="deleteAllCarts__modal"
            closable={true}
            open={isOpenModal}
            pageView={pageView}
            onClose={() => setIsOpenModal(false)}
            confirmBtn={{
              label: t('common.validate'),
              onClick: () => setIsOpenModal(false)
            }}
          >
            <svg width={128} className="commonErrorModal__picto">
              <use xlinkHref={`${MyAccount}#userConfiguration`} />
            </svg>
            <br />
            <strong className="commonErrorModal__title">
              {t('myAccount.popin.userInfo.title')}
            </strong>
            <p className="commonErrorModal__text">
              {t('myAccount.popin.userInfo.content')}
            </p>
          </Modal>
          <div className="myDetails__container">
            <UpdateProfile userInfo={state.userInfo} />
          </div>
          <div className="myDetails__container">
            <ChangeEmail userInfo={state.userInfo} />
            <ChangePassword userInfo={state.userInfo} />
          </div>
        </>
      )}
    </LoadableContent>
  );

  return (
    <MyAccountLayout
      title={t('myAccount.menu.userInfo.details')}
      enabledId="userInfo"
      content={contentPart}
      footer={footer}
      pageView={pageView}
      backTo={{
        label: t('myAccount.menu.userInfo.long'),
        href: '/account-management/my-info'
      }}
    />
  );
};

export default MyDetails;
