import React from 'react';
import CarouselWideEntry from '@/components/molecules/carouselWideEntry';
import Slider from '@/components/molecules/slider';
import './style.scss';

export type ProductSliderWideItem = {
  dataComponent: {
    props: {
      caption: string;
      title: string;
    };
  } | null;
  image: {
    props: {
      id: string;
      alt: string;
      src: string;
    };
  } | null;
};

export type ProductSliderWideProps = {
  itemsList: Array<ProductSliderWideItem>;
};

const ProductSliderWide = ({ itemsList }: ProductSliderWideProps) => {
  return (
    <div className="productSliderWide">
      <Slider
        className="productSliderWide__products"
        slidesToSlide={1}
        renderDotsOutside={true}
        arrows={true}
        type="primary"
        infinite={true}
        responsive={{
          desktop: {
            breakpoint: { max: 4096, min: 600 },
            items: 2,
            slidesToSlide: 2
          },
          tablet: {
            breakpoint: { max: 600, min: 480 },
            items: 2,
            slidesToSlide: 2
          },
          mobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1,
            slidesToSlide: 1
          }
        }}
      >
        {itemsList.map((item: ProductSliderWideItem) => {
          const carouselWideEntryProps = {
            id: item.image?.props?.id,
            title: item.dataComponent?.props.title,
            caption: item.dataComponent?.props.caption,
            src: item.image?.props.src,
            alt: item.image?.props.alt
              ? item.image?.props.alt
              : item.image?.props?.id
          };
          return (
            <div
              className="productSliderWide__content"
              key={carouselWideEntryProps.id}
            >
              {carouselWideEntryProps && (
                <CarouselWideEntry data={carouselWideEntryProps} />
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
export default ProductSliderWide;
