import React from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from '@/store';
import { Content as ContentfulContent } from '@/lib/contentful';
import Identifiants from './identifiants';
import Informations from './informations';
import Contact from './contact';
import Fidelity from './fidelity';
import './style.scss';

export type SignUpFormProps = {
  step: string;
  cgu?: ContentfulContent;
  legals?: ContentfulContent;
};

const SignUpForm = ({ step, cgu, legals }: SignUpFormProps) => {
  const dispatch = useDispatch();
  const { register: user } = useSelector(({ register }: any) => {
    return {
      register
    };
  });
  return (
    <Provider store={store}>
      <div className="formSubscribeWrapper">
        {step === 'identifiants' && (
          <Identifiants user={user} dispatch={dispatch} />
        )}
        {step === 'informations' && cgu && (
          <Informations user={user} dispatch={dispatch} cgu={cgu} />
        )}
        {step === 'contact' && cgu && legals && (
          <Contact cgu={cgu} user={user} dispatch={dispatch} legals={legals} />
        )}
        {step === 'fidelite' && cgu && (
          <Fidelity cgu={cgu} user={user} dispatch={dispatch} />
        )}
      </div>
    </Provider>
  );
};

export default SignUpForm;
