import Link from '@/components/atoms/link';
import React, { memo, useEffect, useState } from 'react';
import { ReactComponent as Arrow } from '@/assets/images/icons/arrow-right.svg';
import t from '@/lib/i18n';
import { AuthStateType } from '@/store/auth/authReducer';
import { useSelector } from 'react-redux';
import UserInformationApi from '@/lib/api/userInformation';
import { Informations } from '@/lib/api/userInformation/types';
import LoadableContent from '@/components/atoms/loadableContent';
import './style.scss';

export type CTAFidProps = {
  textNoCard: string;
};

type StateType = {
  userInfo?: Informations;
  isLoading: boolean;
};

const CTAFid = ({ textNoCard }: CTAFidProps) => {
  const userId = useSelector(
    ({ auth }: { auth: AuthStateType }) => auth.user?.id
  );
  const [state, setState] = useState<StateType>({
    userInfo: undefined,
    isLoading: false
  });

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }
      setState({ isLoading: true });
      const userInfo = await UserInformationApi.getInformations(userId);

      setState(() => {
        return {
          userInfo,
          isLoading: false
        };
      });
    })();
  }, [userId]);

  const hasFid = !!(state.userInfo && state.userInfo.fidelityCardNumber);

  return (
    <LoadableContent loading={state.isLoading} className="ctaFid">
      <div className="ctaFid__linksContainer">
        <div className="ctaFid__link">
          <Link href="#" label={textNoCard} button />
        </div>
        {!hasFid && (
          <div className="ctaFid__link ctaFid__link--withIcon">
            <Arrow width="11" />
            <Link href="#" label={t('fidelityCard.ctaFid.haveCart')} />
          </div>
        )}
      </div>
    </LoadableContent>
  );
};

export default memo(CTAFid);
