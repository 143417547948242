/* eslint-disable import/no-cycle */

import CartSprite from '@/assets/images/sprites/cart.svg';
import CommonSprite from '@/assets/images/sprites/common.svg';
import Button from '@/components/atoms/button';
import Checkbox from '@/components/atoms/formCheckbox';
import Highlight from '@/components/atoms/highlight';
import Image from '@/components/atoms/image';
import Link from '@/components/atoms/link';
import Promotion from '@/components/atoms/promotion';
import QuantityDiscount from '@/components/atoms/quantityDiscount';
import ScoreInit from '@/components/atoms/scoreInit';
import AddToCart from '@/components/molecules/addCartButton';
import SoldByName from '@/components/organisms/product/soldByName';
import getConfigValue from '@/lib/config';
import useClickOut from '@/lib/hooks/useClickOut';
import { useDidomi } from '@/lib/hooks/useDidomi';
import useEvent from '@/lib/hooks/useEvent';
import t from '@/lib/i18n';
import ProductModel from '@/lib/model/product';
import {
  PromotionLabelType,
  PromotionType,
  SmartConso
} from '@/lib/model/productTypes';
import { AuthStateType } from '@/store/auth/authReducer';
import {
  updateAuthorizeProduct,
  updateCartQuantity,
  updateCommentProduct
} from '@/store/cart/actions/cart';
import { StateType as CartStateType } from '@/store/cart/types';
import { selectHasFilledMandatoriesQuestions } from '@/store/survey/surveySelectors';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RecommendationProduct from '../recommendation';
import FrancoScore from './francoScore';
import LayerList from './layerList';
import ProductFlags from './productFLags';
import './style.scss';

/* eslint-enable import/no-cycle */
export type ProductType = {
  isSmall?: boolean;
  isCheckout?: boolean;
  isList?: boolean;
  isSearch?: boolean;
  data: ProductModel;
  className?: string;
  onClick?: (event: MouseEvent<any>) => void;
  sellerId?: string;
  disableMode?: boolean;
  beforeRedirect?: () => void;
  redirectOnProductClick?: boolean;
  imgClassName?: string;
  textClassName?: string;
  productClassName?: string;
  footerClassName?: string;
  showPriceDisableMode?: boolean;
  display?: boolean;
  isShowCase?: boolean;
  listPosition?: number;
  isToReplace?: boolean;
  isCreateContentProfile?: boolean;
  initScoreClassName?: string;
  keyword?: string;
  position?: number;
  isHorizontalLabel?: boolean;
  forceMobileDisplay?: boolean;
  disableAddToCart?: boolean;
};

const MAX_NB_CARACT_COMMENT = 50;

const ListPromotions = ({
  promotions,
  hasQuantity
}: {
  promotions: Array<PromotionType>;
  hasQuantity: boolean;
}) => {
  const findPromoType = (promoType: PromotionLabelType) =>
    promotions.find((promo) => promo.type === promoType);
  // Regle metier tant qu'on a collectorPartner on l'affiche
  const collectorPartner = findPromoType('collectorPartner');
  const flash = promotions.find((promo) => promo.flashSale);
  if (hasQuantity) {
    return (
      <>
        {!!collectorPartner?.value && <Promotion data={collectorPartner} />}
        {flash && <Promotion data={flash} />}
      </>
    );
  }
  const data =
    findPromoType('offeredDiscount') ||
    findPromoType('immediateDiscount') ||
    flash ||
    findPromoType('deferredDiscount'); // Garder ordonancement plz
  if (data) {
    return (
      <>
        {!!collectorPartner?.value && <Promotion data={collectorPartner} />}
        <Promotion data={data} />
      </>
    );
  }
  return collectorPartner?.value ? <Promotion data={collectorPartner} /> : null;
};

const Product = ({
  isSmall = false,
  isList = false,
  isHorizontalLabel,
  isSearch = false,
  data,
  className,
  isCheckout = false,
  sellerId = 'ITM',
  disableMode = false,
  redirectOnProductClick = true,
  beforeRedirect,
  imgClassName,
  textClassName,
  productClassName,
  footerClassName,
  showPriceDisableMode = false,
  display = true,
  isShowCase = false,
  listPosition,
  isToReplace = false,
  isCreateContentProfile = true,
  initScoreClassName,
  keyword = undefined,
  position = 0,
  forceMobileDisplay = false,
  disableAddToCart = false
}: ProductType) => {
  const [listLayer, setListLayer] = useState(false);
  const inputComment = useRef<HTMLInputElement>(null);
  const { data: isSelectPersonalizedContent } = useDidomi(
    'select_personalized_content'
  );

  const hasFilledMandatoriesQuestions = useSelector(
    selectHasFilledMandatoriesQuestions
  );

  const [cartItem, setCartItem] = useState<{
    comment: {
      text: string;
      visible: boolean;
      editing: boolean;
      inProgress: boolean;
    };
    quantityDiscount: PromotionType | null;
    qty: number;
    substitution: boolean;
    total: number;
  }>({
    comment: {
      text: '',
      visible: false,
      editing: false,
      inProgress: false
    },
    quantityDiscount: null,
    qty: 0,
    substitution: false,
    total: 0
  });
  const {
    id,
    informations,
    maxQty,
    allowSubstituable,
    prices,
    url,
    ean,
    type,
    itemParentId,
    offers,
    score: commonScore,
    customScore,
    available
  } = data;

  const [innitScore, setInnitScore] = useState<SmartConso | undefined>(
    commonScore
  );

  const dispatch = useDispatch();
  const { subCarts, userId } = useSelector(
    ({ cart, auth }: { cart: CartStateType; auth: AuthStateType }) => {
      return {
        subCarts: cart.subCarts,
        userId: auth.user?.id
      };
    }
  );

  const IS_SMART_CONSO_LOT2 = getConfigValue(
    'IS_SMART_CONSO_LOT2',
    false
  ).toBoolean();

  let promotions = data?.promotions;
  const [clickOutRef, clickOutHandler] = useClickOut();
  const event = useEvent();
  const router = useRouter();
  let currentOfferMkp;
  if (type === 'MKP') {
    currentOfferMkp = offers?.find((offer) => offer.itemId === id);
    promotions = currentOfferMkp?.promotions || [];
  }

  useEffect(() => {
    const itemCart = subCarts.find((sub) => sub.seller.sellerId === sellerId)
      ?.items[id];
    setCartItem(() => ({
      comment: {
        editing: false,
        text: itemCart?.comment || '',
        visible: Boolean(itemCart?.comment) || false,
        inProgress: false
      },
      qty: itemCart ? itemCart.qty : 0,
      quantityDiscount:
        promotions.find((promo) => promo.type === 'quantityDiscount') || null,
      substitution: itemCart?.acceptSubstitution || false,
      total: itemCart?.total || 0
    }));
  }, [subCarts, ean, type, promotions, id, sellerId]);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(forceMobileDisplay);
  }, [forceMobileDisplay]);

  useEffect(() => {
    if (
      IS_SMART_CONSO_LOT2 &&
      hasFilledMandatoriesQuestions &&
      userId &&
      customScore
    ) {
      setInnitScore(customScore);
    }
    if (!userId) {
      setInnitScore(commonScore);
    }
  }, [
    customScore,
    IS_SMART_CONSO_LOT2,
    userId,
    hasFilledMandatoriesQuestions,
    commonScore
  ]);

  const addToList = () => {
    setListLayer((l) => !l);
  };
  const commentBlockHandler = () => {
    if (!cartItem.comment.text) {
      setCartItem((cm) => ({
        ...cm,
        comment: {
          ...cm.comment,
          visible: !cm.comment.visible
        }
      }));
    }
  };

  const isEmerchEnabled = getConfigValue(
    'IS_EMERCH_ENABLED',
    false
  ).toBoolean();

  const sendComment = async (value: string) => {
    if (!cartItem.comment.inProgress && value) {
      return;
    }
    try {
      await dispatch(updateCommentProduct(value, data, true));
      event.send('checkout', { type: 'productComment', productId: data.id });
    } catch (error: any) {
      throw new Error(error);
    } finally {
      setCartItem((cm) => ({
        ...cm,
        comment: {
          ...cm.comment,
          inProgress: false,
          editing: false,
          visible: true
        }
      }));
    }
  };
  const allowSubstitution = async (authorization: boolean) => {
    try {
      await dispatch(updateAuthorizeProduct(authorization, data, true));
    } catch (error: any) {
      // Todo: handle user error display if unable to allow substitution of a the product
    }
  };

  const removeComment = async () => {
    if (!cartItem.comment.text) return;
    await sendComment('');
  };

  clickOutHandler(() => {
    if (!cartItem.comment.text) {
      setCartItem((cm) => ({
        ...cm,
        comment: {
          ...cm.comment,
          visible: false
        }
      }));
    }
  });

  const displayUnvailableProductLabel =
    (maxQty === 0 || disableMode || !available) &&
    !isToReplace &&
    isEmerchEnabled &&
    isSelectPersonalizedContent;

  const displayHorizontalLabel = isHorizontalLabel || isMobile;

  const content = (
    <>
      {listLayer && (
        <LayerList
          dataProduct={data}
          close={() => {
            setListLayer(false);
          }}
        />
      )}
      {!isSmall && (
        <>
          <div className={classnames('product__flags', initScoreClassName)}>
            {innitScore && (
              <ScoreInit
                scoreInit={innitScore.default}
                rank={innitScore.rating.key}
              />
            )}
            {!displayHorizontalLabel && (
              <ProductFlags
                isHorizontalFlag={false}
                flags={informations.flags}
                francoScore={data.francoScore}
              />
            )}
          </div>
          {type === 'PDV' &&
            data.lot?.type !== 'PICNIC' &&
            !isList &&
            !isSearch &&
            !isMobile && (
              <svg
                className="product__icon fill--red"
                onClick={addToList}
                width="30"
                height="30"
              >
                <use xlinkHref={`${CommonSprite}#add-to-list`} />
              </svg>
            )}
        </>
      )}
      <Link
        disabled={maxQty === 0 || disableMode || !available}
        href={url}
        onClick={(e: MouseEvent<any>) => {
          e.preventDefault();
          if (redirectOnProductClick && !isCheckout) {
            event.send('productClick', {
              product: data,
              index: listPosition,
              path: router.asPath
            });
            if (beforeRedirect) {
              beforeRedirect();
            }
            window.location.href = url;
          }
        }}
        className={classnames({
          product__info: true,
          [`${productClassName}`]: true,
          'product__info--noRedirect': !redirectOnProductClick,
          'product__info--mobile': isMobile
        })}
      >
        <>
          <div
            className={classnames(
              'product__media',
              { 'product__media--checkout': isCheckout },
              imgClassName
            )}
            onClick={() => {
              if (isCheckout) {
                window.location.href = url;
              }
            }}
          >
            {informations.image && informations.image.src && (
              <Image
                className={classnames('product__image', {
                  'product__image--checkout': isCheckout,
                  'no-flag': !innitScore,
                  'product__image--greyedout':
                    !isSelectPersonalizedContent &&
                    (maxQty === 0 || disableMode || !available),
                  'product__image--mobile': isMobile
                })}
                {...informations.image}
                height={175}
                width={200}
              />
            )}
            {informations.packaging && type === 'MKP' && (
              <span className="product__capacity">
                {informations.packaging}
              </span>
            )}
            {!informations.image && (
              <div className="product__media__placeholder" />
            )}
            {(isMobile || isHorizontalLabel) && (
              <div className="product__francoscore--mobile">
                <FrancoScore score={data.francoScore} />
              </div>
            )}
            {!isSmall && !isList && maxQty !== 0 && available && (
              <div
                className={classnames('product__display', {
                  'product__display--mobile': isMobile
                })}
              >
                <ListPromotions
                  promotions={promotions}
                  hasQuantity={!!cartItem.quantityDiscount}
                />
              </div>
            )}
          </div>
          <div
            className={classnames('product__texts', textClassName, {
              'product__texts--mobile': isMobile
            })}
          >
            <div
              className={classnames('product__title', {
                'product__title--checkout': isCheckout
              })}
              onClick={() => {
                if (isCheckout) {
                  window.location.href = url;
                }
              }}
            >
              {informations.brand && (
                <span className="product__brand">{informations.brand} - </span>
              )}
              <h2 className="product__name">{informations.title}</h2>
              {informations.category ? `, cat. ${informations.category}` : ''}
            </div>
            {currentOfferMkp?.seller && (
              <>
                <SoldByName seller={currentOfferMkp.seller} />
                <div className="product__shippingPrice">
                  {!!currentOfferMkp?.delivery?.shippingPrice?.value &&
                    t('pdp.informations.availableOffers.shipping.from', {
                      '%value%':
                        currentOfferMkp?.delivery?.shippingPrice?.concatenated
                    })}
                  {currentOfferMkp?.delivery &&
                    !currentOfferMkp?.delivery?.shippingPrice?.value &&
                    t('pdp.informations.availableOffers.shipping.free')}
                </div>
              </>
            )}
            {informations.packaging && <>{informations.packaging}</>}
            {(maxQty === 0 || disableMode || !available) &&
              isEmerchEnabled &&
              !isSelectPersonalizedContent && (
                <div className="product__unavailable">
                  {t('product.unavailable')}
                </div>
              )}
            {displayHorizontalLabel && (
              <ProductFlags
                isHorizontalFlag
                flags={informations.flags}
                francoScore={data.francoScore}
              />
            )}
            {(isList || isSearch || isMobile) && informations.originPlp && (
              <div className="product__highlight">{informations.originPlp}</div>
            )}
            {isCheckout && maxQty > 0 && !disableMode && type === 'PDV' && (
              <div className="product__actions">
                <div className="product__comment">
                  <svg
                    className="product__comment__icon"
                    width="23"
                    height="19"
                    onClick={commentBlockHandler}
                  >
                    <use
                      xlinkHref={`${CartSprite}#${
                        cartItem.comment.text ? 'commentFilled' : 'comment'
                      }`}
                    />
                  </svg>
                </div>
                |
                {allowSubstituable && (
                  <Checkbox
                    name={ean}
                    className="product__checkbox"
                    checked={cartItem.substitution}
                    onChange={allowSubstitution}
                    id={ean}
                    label={t('product.substitution')}
                  />
                )}
                {!allowSubstituable && (
                  <div className="product__noSubstitution">
                    <svg
                      className="product__noSubstitution__picto"
                      width={20}
                      height={20}
                    >
                      <use xlinkHref={`${CartSprite}#info`} />
                    </svg>
                    {t('product.substitutionNotAllowed')}
                  </div>
                )}
                <div
                  className={classnames(
                    { 'product__field--visible': cartItem.comment.visible },
                    ['product__field']
                  )}
                >
                  <div ref={clickOutRef} className="product__field-line">
                    <input
                      id={`${ean}-comment`}
                      ref={inputComment}
                      className="product__field__input"
                      type="text"
                      value={cartItem.comment.text}
                      onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                          await sendComment(e.currentTarget.value);
                        }
                      }}
                      onFocus={() => {
                        setCartItem((cm) => ({
                          ...cm,
                          comment: {
                            ...cm.comment,
                            editing: true
                          }
                        }));
                      }}
                      maxLength={MAX_NB_CARACT_COMMENT}
                      placeholder={t('cart.info.comment.placeholder')}
                      onChange={(e) => {
                        const target = e.currentTarget;
                        setCartItem((cm) => ({
                          ...cm,
                          comment: {
                            editing: true,
                            text: target.value,
                            visible: true,
                            inProgress: true
                          }
                        }));
                      }}
                    />
                    <div
                      className={classnames(
                        {
                          'product__field__actions--visible':
                            cartItem.comment.editing
                        },
                        'product__field__actions'
                      )}
                    >
                      <svg
                        className="product__field__picto product__picto--send"
                        width="14"
                        height="14"
                        onClick={async () => {
                          await sendComment(cartItem.comment.text);
                        }}
                      >
                        <use xlinkHref={`${CartSprite}#send`} />
                      </svg>
                      <svg
                        className="product__field__picto"
                        width="14"
                        height="14"
                        onClick={async () => {
                          setCartItem((cm) => ({
                            ...cm,
                            comment: {
                              editing: false,
                              text: '',
                              visible: false,
                              inProgress: false
                            }
                          }));
                          await removeComment();
                        }}
                      >
                        <use xlinkHref={`${CommonSprite}#cross-bold`} />
                      </svg>
                    </div>
                  </div>
                  <div className="product__field-line product__field__nbCaract">
                    {cartItem.comment.text.length}/{MAX_NB_CARACT_COMMENT}
                  </div>
                </div>
              </div>
            )}
            {!(isList || isSearch) && informations.originPlp && (
              <div className="product__highlight">{informations.originPlp}</div>
            )}
            {displayUnvailableProductLabel && (
              <div className="product__unavailable">
                {t('product.unavailable')}
              </div>
            )}
          </div>
        </>
      </Link>
      {cartItem.quantityDiscount && !isSmall && !isCheckout && !isList && (
        <QuantityDiscount
          id={data.id}
          qty={cartItem.qty}
          promotion={cartItem.quantityDiscount}
        />
      )}
      {!isSmall &&
        !isList &&
        maxQty !== 0 &&
        available &&
        !promotions.length &&
        informations.highlight && (
          <div className="product__highlight--container">
            <Highlight
              endDate={informations.highlight.endDate}
              id={`highlight-${id}`}
            />
          </div>
        )}
      <footer
        className={classnames({
          product__footer: !footerClassName,
          'product__footer--center': disableMode,
          'product__footer--hasNoPromo': !informations.highlight,
          'product__footer--mobile': isMobile,
          [`${footerClassName}`]: !!footerClassName
        })}
      >
        {(isCheckout || isList) && maxQty > 0 && available && !disableMode && (
          <>
            {!!promotions.length && (
              <div className="product__promotions">
                {cartItem.quantityDiscount && (
                  <QuantityDiscount
                    id={data.id}
                    qty={cartItem.qty}
                    promotion={cartItem.quantityDiscount}
                  />
                )}
                <ListPromotions
                  promotions={promotions}
                  hasQuantity={!!cartItem.quantityDiscount}
                />
              </div>
            )}
            {!promotions.length && informations.highlight && (
              <Highlight
                endDate={informations.highlight.endDate}
                id={`highlight-${id}`}
              />
            )}
          </>
        )}
        <div
          className={classnames('product__footer__pricesWrapper', {
            'product__footer__pricesWrapper--disable': disableMode
          })}
        >
          {maxQty > 0 && !disableMode && available && prices && (
            <>
              <div className="product__prices">
                {prices.crossedOutPrice && (
                  <span className="product__crossedOutPrice">
                    {prices.crossedOutPrice?.integer},
                    {prices.crossedOutPrice?.decimal}
                    {prices.crossedOutPrice?.currency}
                  </span>
                )}
                {prices.productPrice && (
                  <span
                    className={classnames('product__productPrice', {
                      hasPromo:
                        promotions.length ||
                        informations.highlight ||
                        prices.crossedOutPrice
                    })}
                  >
                    {prices.productPrice.integer},{prices.productPrice.decimal}{' '}
                    {prices.productPrice.currency}
                  </span>
                )}
                {prices.unitPrice && (
                  <>
                    {prices.unitPrice.integer},{prices.unitPrice.decimal}{' '}
                    {prices.unitPrice.currency}
                  </>
                )}
              </div>
              <AddToCart
                fullSync={isSmall}
                product={data}
                sellerId={sellerId}
                itemParentId={itemParentId}
                listPosition={listPosition}
                isSmall={isSmall}
                isCreateContentProfile={isCreateContentProfile}
                isSearch={isSearch || isMobile}
                keyword={keyword}
                position={position}
                disabled={disableAddToCart}
              />
              {isCheckout && (
                <span className="product__totalPrice">
                  {t('product.total', {
                    '%price%': String(cartItem.total.toFixed(2)).replace(
                      '.',
                      ','
                    )
                  })}
                </span>
              )}
            </>
          )}
          {(maxQty === 0 || disableMode || !available) && (
            <>
              {!isCheckout && !isEmerchEnabled && (
                <div className="product__unavailable">{t('product.soon')}</div>
              )}

              {isCheckout && (
                <AddToCart
                  disabled={true}
                  fullSync={isSmall}
                  product={data}
                  sellerId={sellerId}
                  itemParentId={itemParentId}
                />
              )}

              {type === 'PDV' &&
                !isToReplace &&
                isEmerchEnabled &&
                isSelectPersonalizedContent && (
                  <RecommendationProduct
                    productModel={data}
                    title={t('bottomProductsLayer.toReplace')}
                    isCheckout={isCheckout}
                  />
                )}

              {showPriceDisableMode && (
                <span className="product__totalPrice product__totalPrice--disable">
                  {t('product.total', {
                    '%price%': String(cartItem.total.toFixed(2)).replace(
                      '.',
                      ','
                    )
                  })}
                </span>
              )}
            </>
          )}
        </div>
        {isCheckout && isSmall && !disableMode && (
          <Button
            className="product__trash"
            id="btn-productTrash"
            onClick={async () => {
              await dispatch(
                updateCartQuantity(
                  [{ product: data, quantity: 0, sellerId, itemParentId }],
                  true
                )
              );
              event.send('removeFromCart', {
                product: data,
                path: router.asPath
              });
            }}
            isLink={true}
          >
            <svg className="fill--black" width="20" height="20">
              <use xlinkHref={`${CommonSprite}#trash`} />
            </svg>
          </Button>
        )}
      </footer>
    </>
  );

  return isShowCase ? (
    <div
      className={classnames({
        [`${className}`]: !!className,
        product: true,
        'product--small': isSmall,
        'product--list': isList,
        'product--noDisplay': !display
      })}
      id={`productEan_${type === 'MKP' ? id : ean}`}
      data-id={id}
    >
      {content}
    </div>
  ) : (
    <div
      className={classnames({
        [`${className}`]: !!className,
        product: true,
        'product--small': isSmall,
        'product--list': isList,
        'product--noDisplay': !display,
        'product--unavailable':
          (maxQty === 0 || disableMode || !available) &&
          ((isEmerchEnabled &&
            (!isSelectPersonalizedContent || type === 'MKP')) ||
            !isEmerchEnabled)
      })}
      id={`productEan_${type === 'MKP' ? id : ean}`}
      data-id={id}
      data-nfproductid={
        (data.privateData && JSON.parse(data.privateData).nfProductId) || ''
      }
      data-name={`${data.informations.brand || ''} - ${
        data.informations.title || ''
      }`}
      data-price={data.prices.productPrice?.value}
    >
      {content}
    </div>
  );
};

export default Product;
